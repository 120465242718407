/* eslint-disable max-lines-per-function */
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useAuthorization from 'hooks/useAuthorization';
import useForm from 'hooks/useForm';
import useLog from 'hooks/useLog';
import { UserRole } from 'models/user';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import replaceCommaIfString from 'utils/replaceCommaIfString';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import { CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';
import {
  ExecuteTradeResultDto, TradeDto, TradeFundingMethod,
  TradeSubmissionDto,
} from '@alpha/fx-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfirmTrade from './ConfirmTrade';
import { initialValues as noDraftValues, TSpotFXRequestForm } from './formData';
import useStyles from './index.styles';
import InputSpotTrade from './InputSpotTrade';
import TradePayments from './TradePayments/Index';

type Props = {
  open: boolean,
  firstPartyFlow: boolean,
  onClose?: () => void | SetStateAction<boolean>,
  tradeDraft?: TradeDto,
  setTradeDraft?: React.Dispatch<React.SetStateAction<TradeDto | undefined>>,
  heading?: string,
  bookedTransfer?: CurrencyAccountTransferDto,
}

export type TSpotDrawState = 'InputTrade' | 'ConfirmTrade' | 'TradePayments';

export type TFxBookingState =
  'loading'
  | 'initiated'
  | 'failed'
  | 'timeout'
  | 'success'
  | 'initiatedBookTrade'
  | 'failedBookTrade'
  | 'successBookTrade';

const CreateSpotDrawer: React.FC<Props> = ({
  open, onClose, tradeDraft, setTradeDraft, heading, bookedTransfer, firstPartyFlow,
}) => {
  const styles = useStyles();
  const { logError } = useLog();
  const sb = useAlphaSnackbar();
  const { authorized: padApprover } = useAuthorization([[UserRole.PAD_APPROVER]]);
  const { authorized: spotBooker } = useAuthorization([[UserRole.SPOT]]);
  const { authorized: spotInputter } = useAuthorization([[UserRole.SPOT_INPUTTER]]);

  const [drawerState, setDrawerState] = useState<TSpotDrawState>(
    'InputTrade',
  );
  const [validatedTrade, setValidatedTrade] = useState<TradeSubmissionDto | TradeDto>();
  const [bookedTrade, setBookedTrade] = useState<ExecuteTradeResultDto>();
  const [maxSpotLimit, setMaxSpotLimit] = useState<number>(1000000);
  const [minSpotLimit, setMinSpotLimit] = useState<number>(1);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onDrawerClose = async (): void => {
    setValidatedTrade(undefined);
    setBookedTrade(undefined);
    setDrawerState('InputTrade');
    try {
      const { sellCurrencyCode } = form.values;
      const { buyCurrencyCode } = form.values;
      const { indicativeRate } = form.values;
      const currencyPair = sellCurrencyCode.concat(buyCurrencyCode);
      if (drawerState === 'InputTrade' && buyCurrencyCode && sellCurrencyCode && indicativeRate && !submitting) {
        setSubmitting(true);
        await FXTradeService.postQuoteNoTradeRequestAsync({
          currencyPair,
          amount: form.values.fixedAmount,
          indicativeRate,
          quoteRate: form.values.quoteRate,
        });
      }
    } catch (error) {
      logError({ action: 'Error calling quote-no-trade endpoint', error });
    } finally {
      if (setTradeDraft) { setTradeDraft(undefined); }
      form.resetForm();
      if (onClose) { onClose(); }
      setSubmitting(false);
    }
  };

  const newValidation = {
    fixedAmount: yup
      .number().transform(replaceCommaIfString)
      .typeError(t('amount_must_be_a_number'))
      .min(1, t('amount_too_small'))
      .required(t('please_enter_a_valid_amount')),
    buyAmount: yup
      .number().transform(replaceCommaIfString)
      .typeError(t('amount_must_be_a_number'))
      .required(t('please_enter_a_valid_amount'))
      .min(1, t('amount_too_small'))
      .positive(t('please_enter_a_positive_amount')),
    sellCurrencyCode: yup
      .string()
      .required(t('please_select_sell_currency')),
    buyCurrencyCode: yup
      .string()
      .required(t('please_select_buy_currency')),
    indicativeRate: yup
      .number().required(t('indicative_rate_not_set')),
    sellAmount: yup
      .number().transform(replaceCommaIfString)
      .required(t('please_enter_a_valid_amount'))
      .typeError(t('amount_must_be_a_number'))
      .test({
        name: 'Min and max spot limit check',
        test() {
          const {
            sellAmount, sellCurrencyCode, buyAmount, buyCurrencyCode,
          } = this.parent;
          if (sellAmount && sellCurrencyCode && buyAmount && buyCurrencyCode && (sellAmount < minSpotLimit)) {
            return this.createError({
              message: t('amount_is_below_the_minimum_spot_limit'),
              path: 'sellAmount',
            });
          }
          if (sellAmount && sellCurrencyCode && buyAmount && buyCurrencyCode && (sellAmount > maxSpotLimit)) {
            return this.createError({
              message: t('amount_is_above_the_maximum_spot_limit'),
              path: 'sellAmount',
            });
          }
          return true;
        },
      })
    ,
  };

  const draftSpotValues: TSpotFXRequestForm = {
    fundingMethod: TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE,
    sellCurrencyCode: tradeDraft?.soldCurrencyCode || '',
    buyCurrencyCode: tradeDraft?.buyCurrencyCode || '',
    fixedCurrencyCode: tradeDraft?.fixedCurrencyCode || '',
    fixedAmount: tradeDraft?.fixedAmount || 0.00,
    sellAmount: ((tradeDraft?.fixedCurrencyCode === tradeDraft?.soldCurrencyCode)
      ? tradeDraft?.fixedAmount : tradeDraft?.soldAmount) || 0.00,
    buyAmount: ((tradeDraft?.fixedCurrencyCode === tradeDraft?.buyCurrencyCode)
      ? tradeDraft?.fixedAmount : tradeDraft?.buyAmount) || 0.00,
    indicativeRate: 0,
    draftInitiated: true,
    valueDate: '',
  };

  const initialValues = tradeDraft
    ? draftSpotValues
    : noDraftValues;

  const form = useForm<TSpotFXRequestForm>(
    initialValues,
    newValidation,
    () => { },
    true,
    false,
  );

  const accountConfigQuery = useQuery('getAccountConfig', () => FXTradeService.getAccountConfigurationWithCcys(
    form.values.sellCurrencyCode,
    form.values.buyCurrencyCode,
  ), {
    enabled: false,
    onError: (e) => {
      sb.trigger(t('could_not_load_account_configurations'), 'error');
      logError({ action: 'Error loading Account Configurations', error: e });
    },
  });

  useEffect(() => {
    if (open && form.values.sellCurrencyCode && form.values.buyCurrencyCode) {
      if (accountConfigQuery) accountConfigQuery.refetch();
    }
  }, [open, form.values.sellCurrencyCode, form.values.buyCurrencyCode]);

  useEffect(() => {
    if (open && accountConfigQuery.data && !accountConfigQuery.data?.spotAllowed) {
      sb.trigger(t('sorry_you_do_not_have_permissions_to_create_a_new_spot'), 'info');
      logError({
        action: 'User has no permission to create new spot',
        detail: {
          reason: 'account configuration not allowed',
          accountConfig: accountConfigQuery.data,
        },
      });
      if (onClose) { onClose(); }
    }

    if (accountConfigQuery.data?.maximumFX?.sellAmount) {
      setMaxSpotLimit(accountConfigQuery.data?.maximumFX?.sellAmount);
    }

    if (accountConfigQuery.data?.minimumFX?.sellAmount) {
      setMinSpotLimit(accountConfigQuery.data?.minimumFX?.sellAmount);
    }
  },
    [
      open,
      accountConfigQuery.data?.spotAllowed,
      accountConfigQuery.data?.maximumFX,
      accountConfigQuery.data?.minimumFX,
    ]);

  return (
    <BaseDrawer.Drawer
      open={open}
      onClose={onDrawerClose}
      anchor="right"
      className={styles.drawer}
      disabled={submitting}
    >
      <Box className={styles.container}>
        {drawerState === 'InputTrade' && (
          <>
            <h2 style={{ padding: '0px 20px' }}>
              {heading}
            </h2>
            <InputSpotTrade
              firstPartyFlow={firstPartyFlow}
              form={form}
              setDrawerState={setDrawerState}
              padAllowed={accountConfigQuery.data?.padAllowed
                && accountConfigQuery.data?.padEligible
                && padApprover}
              setBookedTrade={setBookedTrade}
              validatedTrade={validatedTrade}
              setValidatedTrade={setValidatedTrade}
              tradeDraft={tradeDraft}
              handleClose={onDrawerClose}
              submittingDraft={spotInputter && !spotBooker}
              isTransfer={!!bookedTransfer}
            />
          </>
        )}
        {drawerState === 'ConfirmTrade' && (
          <>
            <h2 style={{ padding: '0px 20px' }}>
              <Typography className={styles.heading}>
                <FontAwesomeIcon icon={faCheckSquare as IconProp} className={styles.icon} />
                {' '}
                {`${t('spot_trade_booked')} ${bookedTransfer ? `- ${t('inter_account_transfer')}` : ''}`}
              </Typography>
            </h2>
            <ConfirmTrade
              form={form}
              handleClose={onDrawerClose}
              setDrawerState={setDrawerState}
              bookedTrade={bookedTrade}
              paymentsAllowed={!tradeDraft}
              bookedTransfer={bookedTransfer}
              firstPartyFlow={firstPartyFlow}
            />
          </>
        )}
        {drawerState === 'TradePayments' && (
          <>
            <h2 style={{ padding: '0px 20px' }}>{t('linked_payment')}</h2>
            {' '}
            <TradePayments
              tradeId={bookedTrade?.tradeId}
              tradeDraftId={validatedTrade?.id}
              form={form}
              bookedTrade={bookedTrade}
              handleClose={onDrawerClose}
              setDrawerState={setDrawerState}
            />
          </>
        )}
      </Box>
    </BaseDrawer.Drawer>
  );
};

export default CreateSpotDrawer;
