import React, { memo, useEffect, useState } from 'react';

import {
  CircularProgress, MenuItem, Select,
} from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import { TProgramData } from '../../../../services/HedgingPrograms/hedgingPrograms.interfaces';
import t from 'utils/translationHelper';

import useStyles from './Dropdown.styles';

interface IProps {
  data: TProgramData[];
  selectValue?: string;
  selectName?: string;
  placeholderText?: string;
  disabled?: boolean;
  testId?: string;
  handleChange: (event: any) => void;
  isLoading: boolean;
}

const Dropdown: React.FC<IProps> = (props: IProps) => {
  const {
    data,
    selectValue,
    selectName,
    placeholderText,
    disabled,
    testId,
    handleChange,
    isLoading,
  } = props;
  const styles = useStyles();

  const [showValueOrPlaceHolder, setShowValueOrPlaceholder] = useState<string | undefined>(selectValue || 'placeholder');

  const dropdownItems = data.map((option) => (
    <MenuItem
      className={styles.menuItem}
      key={option.programId}
      value={option.programId}
    >
      {option.programName}
    </MenuItem>
  ));

  const renderedPlaceHolderText = data.length ? (placeholderText || t('select_a_program_you_would_like_to_view')) : t('no_programs_available_on_this_account');

  useEffect(() => {
    setShowValueOrPlaceholder(selectValue || 'placeholder');
  }, [selectValue]);

  return (
    <div className={styles.dropdownContainer}>
      {
        isLoading
          ? <CircularProgress size={20} />
          : (
            <>
              <Select
                disabled={disabled}
                onChange={handleChange}
                value={showValueOrPlaceHolder}
                name={selectName}
                id={testId || 'dropdown'}
                fullWidth
                disableUnderline
                className={styles.dropdown}
                placeholder={t('select_a_program_you_would_like_to_view')}
              >
                {[
                  <MenuItem value="placeholder" disabled>
                    <Typography variant="subtitle1" className={styles.placeholder}>
                      {renderedPlaceHolderText}
                    </Typography>
                  </MenuItem>,
                  ...dropdownItems,
                ]}
              </Select>
            </>
          )
      }
    </div>
  );
};

export default memo(Dropdown);
