import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography, palette }) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '30px',
    backgroundColor: '#fafafa',
    minHeight: 'calc(100vh - 310px)',
    '& .MuiTableCell-head, .MuiTableCell-body': {
      'padding-right': '20px !important',
    },
    '& .MuiTableCell-head:last-of-type, .MuiTableCell-body:last-of-type': {
      'text-align': 'right',
      'padding-right': '0 !important',
    },
  },
  paymentDrawerContent: {
    padding: '45px 0px',
    '& > div': {
      '&:first-of-type': {
        marginBottom: '28px',
      },
      '&:nth-of-type(2)': {
        marginBottom: '30px',
      },
    },
  },
  spotButtonWrapper: {
    '& .MuiButtonBase-root': {
      color: '#fff',
      backgroundColor: '#1E8777',
    },
    '& .MuiButton-label': {
      marginLeft: 'unset',
      height: 'unset',
    },
  },
  tradesContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.03)',
    padding: '20px',
    marginTop: '20px',
    borderRadius: '10px',
  },
  tableHeading: {
    fontSize: '16px',
    alignSelf: 'left',
    fontWeight: typography.fontWeightBold,
  },
  tableSubHeading: {
    marginBottom: '20px',
    fontSize: '12px',
    alignSelf: 'left',
    color: palette.grey[500],
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '30px',
  },
  icon: {
    transition: 'all 0.1s linear',
    '&.up': {
      transform: 'rotate(180deg)',
    },
  },
}), { name: 'Dashboard' });

export default useStyles;
