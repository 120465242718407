import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  title: {
    color: 'white',
    fontSize: '22px',
    fontWeight: 100,
  },
  eyeIconStyles: {
    fontSize: '18px',
    transform: 'translate(-50px, 14px)',
    color: 'white',
    position: 'absolute',
    opacity: '50%',
    backgroundColor: 'transparent',
    border: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapperDiv: {
    height: '345px'
  },
  subtitle: {
    color: 'white',
    fontSize: '14px',
    opacity: '70%',
  },
  label: {
    color: 'white',
    fontSize: '14px',
    marginBottom: '8px',
  },
  input: {
    marginBottom: '32px',
  },
  section: {
    marginBottom: '32px',
  },
  white: {
    color: 'white',
  },
  formBacklink: {
    display: 'inherit',
    marginTop: '8px',
  },
  oldPortalLink: {
    marginTop: '100px',
  }
}), { name: 'FormStyles' });

export default useStyles;
