/* eslint-disable max-lines-per-function */
import clipboardCross from 'assets/clipboardCross.svg';
import ExternalTradesMtm from 'components/Molecules/Credit/ExternalTradesMtm/ExternalTradesMtm';
import TermsLetterDrawer from 'components/Molecules/Credit/TermsLetterDrawer/TermsLetterDrawer';
import TermSummary from 'components/Molecules/Credit/TermSummary/TermSummary';
import TermUtilisation from 'components/Molecules/Credit/TermUtilisation';
import EmptyTable from 'components/Table/EmptyTable';
import AmendmentRequestModal from 'domain/CreditFacility/AmendmentRequestModal/AmendmentRequestModal';
import MTMSummary from 'domain/CreditFacility/MTMSummary/MTMSummary';
import OptionsPositionValuationTable from 'domain/CreditFacility/OptionsPositionValuationTable';
import PositionValuationTable from 'domain/CreditFacility/PositionValuationTable';
import MtmWidget from 'domain/Dashboard/WidgetPanel/Widgets/MtmWidget';
import useAuthorization from 'hooks/useAuthorization';
import useQueryString from 'hooks/useQueryString';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { UserRole } from 'models/user';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import routes from 'routes.path';
import CreditService from 'services/Credit/credit.service';
import browserHistory from 'services/history/browserHistory';
import { TStore } from 'store';
import getDefaultMtmDate from 'utils/fxTrades/getDefaultMtmDate';
import t from 'utils/translationHelper';

import { CreditFacilityDto, CreditFacilityTermDto } from '@alpha/credit-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Status } from '@alpha/ui-lib/ui/Status';
import { mapFacilityTermStatus } from 'utils/credit/mapFacilityTermStatus';
import { CreditFacilityTermStatus } from '@alpha/credit-dtos';

import useStyles from './index.styles';
import useFacilityTerm from './useFacilityTerm';

const FacilityTerm: React.FC = () => {
  const styles = useStyles();
  const { homePageUrl } = useSwitchAccount();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl }, { text: t('credit_facility'), url: routes.credit }];
  const { termId } = useParams<{ termId: string; }>();

  const [searchText, setSearchText] = useState<string>();
  const [buyCurrencies, setBuyCurrencies] = useState<string[]>([]);
  const [soldCurrencies, setSoldCurrencies] = useState<string[]>([]);
  const [mtmDate, setMtmDate] = useQueryString('date', undefined);

  const [openAmendmentRequestModal, setOpenAmendmentRequestModal] = useState(false);
  const [openTermsLetterDrawer, setOpenTermsLetterDrawer] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const { authorized: creditAdmin } = useAuthorization(
    [[UserRole.CREDIT_ADMIN]],
  );
  const [hasExternalTrades, setHasExternalTrades] = useState(false);

  const facilityMode = useSelector<TStore, string>(
    (state: TStore) => state.creditFacility.facilityMode,
  );
  const creditFacility = useSelector<TStore, CreditFacilityDto>(
    (state: TStore) => state.creditFacility.facility,
  );
  const facilityLoading = useSelector<TStore, boolean>(
    (state: TStore) => state.creditFacility.facilityLoading,
  );
  const facilityTerm = useSelector<TStore, CreditFacilityTermDto>(
    (state: TStore) => state.creditFacility.termSummary,
  );
  const termLoading = useSelector<TStore, boolean>(
    (state: TStore) => state.creditFacility.termLoading,
  );

  const activeTerms = creditFacility?.facilityTerms?.filter((term) => term.status === CreditFacilityTermStatus.ACTIVE)

  const {
    termSummaries,
    bespokeItems,
  } = useFacilityTerm(creditFacility, facilityTerm);

  const getLatestMtmDate = async () => {
    try {
      const response = await CreditService.getMtmLatestAvailableDate(termId);
      setMtmDate(response?.latestMTMDate);
    } catch (e) {
      setMtmDate(getDefaultMtmDate());
    }
  };

  useEffect(() => {
    if (facilityTerm) {
      if (!mtmDate && facilityTerm?.rateDate) {
        setMtmDate(moment(facilityTerm?.rateDate).format('YYYY-MM-DD'));
      } else if (!mtmDate && !facilityTerm?.rateDate) {
        getLatestMtmDate();
      }
      setSelectedCurrency(facilityTerm?.currencyCode);
    }
  }, [facilityTerm]);

  const styledTitle = (
    <p className={styles.buttons}>
      {(activeTerms?.length !== 1) && (
        <ActionButton
          startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
          testId="trade-back-btn"
          size="large"
          onClick={() => browserHistory.push(routes.credit)}
          style={{
            minWidth: '33px', minHeight: '33px', height: '33px', marginTop: '-5px', background: '#fdfdfd', color: '#212529',
          }}
        />
      )}
      {t('facility_term')}
      {facilityTerm && !termLoading && (
        <p className={styles.header}>
          {' '}
          (
          {facilityTerm.displayName}
          )
          {' '}
        </p>
      )}
      {
        <Box className={styles.statusContainer}>
          <Status
            className={styles.status}
            variant={mapFacilityTermStatus(facilityTerm?.status).variant}
          >
            {t(mapFacilityTermStatus(facilityTerm?.status).text)}
          </Status>
        </Box>
      }
    </p>
  );

  const rightAlignedCta = () => {
    if (creditAdmin) {
      return (
        <Box display="flex" gridGap={8}>
          {(creditFacility?.facilityTerms?.length > 1 && activeTerms?.length === 1) && (
            <ActionButton
              testId="view-all-facility-terms-btn"
              size="medium"
              rounded
              onClick={() => {
                browserHistory.push(routes.credit, { from: '/credit-facility/term' });
              }}
            >
              {t('view_all_facility_terms')}
            </ActionButton>
          )}
          {
            facilityMode === 'single' ? (
              <>
                <ActionButton
                  size="medium"
                  startIcon={<FontAwesomeIcon icon={faDownload} />}
                  style={{ background: '#F7F7F7', color: '#212529' }}
                  onClick={() => {
                    setOpenTermsLetterDrawer(true);
                  }}
                >
                  {t('download_terms_letter')}
                </ActionButton>
                <ActionButton
                  size="medium"
                  onClick={() => {
                    setOpenAmendmentRequestModal(true);
                  }}
                >
                  {t('amendment_request')}
                </ActionButton>
              </>
            ) : null
          }
        </Box>
      );
    }

    return (
      <>
        {(creditFacility?.facilityTerms?.length > 1 && activeTerms?.length === 1) && (
          <ActionButton
            testId="view-all-facility-terms-btn"
            size="medium"
            rounded
            onClick={() => {
              browserHistory.push(routes.credit, { from: '/credit-facility/term' });
            }}
          >
            {t('view_all_facility_terms')}
          </ActionButton>
        )}
      </>
    )
  };

  return (
    <APMainLayout
      data-id="term-facility"
      title={styledTitle}
      breadCrumbs={breadcrumbs}
      height={facilityMode === 'single' || creditAdmin ? '162px' : '157px'}
      rightAlignedCta={rightAlignedCta()}
      sticky
    >
      <Box className={styles.root}>
        {facilityLoading || termLoading ? (
          <div style={{ width: '100%' }}>
            <Loader testId="loading-term" />
          </div>
        ) : (
          <Box className={styles.panel}>
            <TermSummary
              summaries={termSummaries}
              bespokeItems={bespokeItems}
              totalBespokeItems={facilityTerm?.bespokeTerms?.total ?? 0}
            />
            <MtmWidget isCreditPage termId={facilityTerm?.id} shouldUseBrackets />
            {facilityTerm ? <TermUtilisation term={facilityTerm} />
              : (
                <div className={styles.widget}>
                  <EmptyTable
                    title={t('no_utilisation_data_available')}
                    subtitle={(
                      <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: '300' }}>
                        {t('there_are_currently_no_term_limits_or_open_positions')}
                        <br />
                        {t('you_will_be_able_to_see_your_utilisation_once_these_limits_are_set')}
                      </Typography>
                    )}
                    icon={clipboardCross}
                    className={styles.emptyTable}
                  />
                </div>
              )}
            {mtmDate ? (
              <MTMSummary
                title={t('forwards_position_valuation_by_CP')}
                fullWidth
                mtmDate={mtmDate}
                setMtmDate={setMtmDate}
                selectedCurrency={selectedCurrency || 'GBP'}
                searchText={searchText}
                buyCurrency={buyCurrencies}
                soldCurrency={soldCurrencies}
                setBuyCurrencies={setBuyCurrencies}
                setSoldCurrencies={setSoldCurrencies}
                setSelectedCurrency={setSelectedCurrency}
                termId={termId}
                enableFilter
                shouldUseBrackets
                hasExternalTrades={hasExternalTrades}
                facilityMode={facilityMode}
              />
            ) : null}
            <PositionValuationTable
              fullWidth
              testId="position-valuation-table"
              mtmDate={mtmDate}
              setSearchTextForReport={setSearchText}
              buyCurrencies={buyCurrencies}
              sellCurrencies={soldCurrencies}
              setBuyCurrencies={setBuyCurrencies}
              setSoldCurrencies={setSoldCurrencies}
              termId={termId}
              enableFilter={false}
              shouldUseBrackets
              title={`${t('forwards')}: `}
              isCredit
              selectedCurrency={selectedCurrency}
            />
            <OptionsPositionValuationTable
              fullWidth
              testId="options-position-valuation-table"
              alertText={t('for_more_detail_on_options_positions')}
              mtmDate={mtmDate}
              setSearchTextForReport={setSearchText}
              buyCurrencies={buyCurrencies}
              sellCurrencies={soldCurrencies}
              termId={termId}
              shouldUseBrackets
              selectedCurrency={selectedCurrency}
            />
            {facilityMode === 'single' && <ExternalTradesMtm mtmDate={mtmDate} setHasExternalTrades={setHasExternalTrades} selectedCurrency={selectedCurrency} />}
          </Box>
        )}
        <AmendmentRequestModal
          handleClose={() => setOpenAmendmentRequestModal(false)}
          open={openAmendmentRequestModal}
        />
        <TermsLetterDrawer
          open={openTermsLetterDrawer}
          onClose={() => {
            setOpenTermsLetterDrawer(false);
          }}
          termId={termId}
        />
      </Box>
    </APMainLayout>
  );
};

export default FacilityTerm;
