/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { camelCase } from 'lodash';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import {
  BankingFieldWithValidation, BeneficiaryDto, BeneficiaryParty, CountryBankFields, ValidateBeneficiaryRequest,
} from '@alpha/bene-dtos';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import BeneficiariesService from '../../../../../services/Beneficiaries/beneficiaries.service';
import validation from '../formData';

const usePaymentMethods = (
  form: FormikProps<ValidateBeneficiaryRequest>,
  requiresAddress: boolean,
  setRequiresAddress: React.Dispatch<React.SetStateAction<boolean>>,
  formValidation: typeof validation,
  setFormValidation: React.Dispatch<React.SetStateAction<any>>,
  setAvailablePaymentMethods: React.Dispatch<React.SetStateAction<CountryBankFields | undefined>>,
  availablePaymentMethods?: CountryBankFields,
  selectedBeneficiary?: BeneficiaryDto,
  requiresCorrespondentBankDetails?: boolean,
  setRequiresCorrespondentBankDetails?: React.Dispatch<React.SetStateAction<boolean>>,
  updateDrawer?: boolean,
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();

  const [tab, setTab] = useState<'international' | 'local'>('international');

  const { bankCountryCode, currencyCode } = form.values;
  const { logError } = useLog();
  const { currentAccount } = useSwitchAccount();

  let tabTitles = generateTabTitles(availablePaymentMethods);
  const { getAccountDetailsFromLocalStorage } = useSwitchAccount();

  const { countryCode } = getAccountDetailsFromLocalStorage();

  useEffect(() => {
    if (bankCountryCode === selectedBeneficiary?.bankCountryCode
      && currencyCode === selectedBeneficiary?.currencyCode) {
      resetExtraBankInformation(selectedBeneficiary);
    } else {
      resetExtraBankInformation();
    }
    if (bankCountryCode && currencyCode) {
      resetBankFieldValues(selectedBeneficiary);
      getAvailablePaymentMethods(bankCountryCode, currencyCode);
    }
    if (form?.values?.party) {
      if (form.values.party === BeneficiaryParty.FIRST) {
        form.setFieldValue('countryCode', selectedBeneficiary?.countryCode || countryCode || '');
      } else {
        form.setFieldValue('countryCode', selectedBeneficiary?.countryCode || form.values.bankCountryCode || '');
      }
    }
  }, [bankCountryCode, currencyCode]);

  useEffect(() => {
    tabTitles = generateTabTitles(availablePaymentMethods);
    if (availablePaymentMethods?.international?.length === 0) {
      setTab('local');
    } else {
      setTab('international');
    }
  }, [availablePaymentMethods]);

  useEffect(() => {
    let newValidation: Record<any, any> = validation;
    if (availablePaymentMethods?.international.length && tab === 'international' && !updateDrawer) {
      newValidation = getNewFormValidation(availablePaymentMethods.international,
        validation);
    } else if (availablePaymentMethods?.local.length && tab === 'local' && !updateDrawer) {
      newValidation = getNewFormValidation(availablePaymentMethods.local,
        validation);
    }

    setFormValidation(getFormValidationRequiresAddress(requiresAddress, newValidation));
  }, [tab, availablePaymentMethods, requiresAddress, updateDrawer]);

  useEffect(() => {
    if (selectedBeneficiary) {
      form.setValues(selectedBeneficiary);
    }
  }, []);

  const handleDynamicValidationTabChange = (newTabIndex: Readonly<number>) => {
    if (tabTitles[newTabIndex]) {
      setTab(tabTitles[newTabIndex] as 'international' | 'local');
    } else {
      setTab(tabTitles[0] as 'international' | 'local');
    }

    resetBankFieldValues();
  };

  const getAvailablePaymentMethods = async (
    _bankCountryCode: Readonly<string>,
    _currencyCode?: Readonly<string>,
  ) => {
    try {
      setLoading(true);
      const response = await BeneficiariesService.getCountryRequirements(_bankCountryCode && _currencyCode
        ? [{ countryCode: _bankCountryCode, currencyCode: _currencyCode }] : []);
      setAvailablePaymentMethods(response[0].bankFields);
      setRequiresAddress(response[0].requiresAddress || currentAccount.country?.toLowerCase() === 'canada' || false);

      if (setRequiresCorrespondentBankDetails) { setRequiresCorrespondentBankDetails(response[0].requiresCorrespondentBankDetails || false); }
    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || t('there_was_an_error_retrieving_the_available_payment'));
      logError({ action: 'Error retrieving the available payment methods for the selected bank country', error: e });
    } finally {
      setLoading(false);
    }
  };

  const getNewFormValidation = (bankingFieldsWithValidation: BankingFieldWithValidation[],
    exisitingFormValidation: Record<any, any>) => {
    const newValidation = { ...exisitingFormValidation };
    for (let i = 0; i <= bankingFieldsWithValidation.length - 1; i += 1) {
      const fieldNameToCamelCase = camelCase(bankingFieldsWithValidation[i].field);
      newValidation[fieldNameToCamelCase] = yup.string().matches(
        new RegExp(bankingFieldsWithValidation[i].pattern),
        bankingFieldsWithValidation[i].errorMessage,
      ).required(`${bankingFieldsWithValidation[i].field} is a required field`);
    }

    return newValidation;
  };

  const getFormValidationRequiresAddress = (
    required: boolean,
    exisitingFormValidation: Record<any, any>,
  ) => {
    const newValidation = { ...exisitingFormValidation };

    if (required) {
      newValidation.addressLine1 = yup
        .string()
        .matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
        .min(3, t('must_be_3_characters_or_more'))
        .max(100, t('must_be_100_characters_or_less'))
        .required(t('address_line_1_required'));
      newValidation.addressLine2 = yup
        .string()
        .matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
        .min(3, t('must_be_3_characters_or_more'))
        .max(100, t('must_be_100_characters_or_less'));
      newValidation.state = yup
        .string()
        .matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
        .min(2, t('must_be_2_characters_or_more'))
        .max(255, t('must_be_255_characters_or_less'))
        .required(t('state_required'));
      newValidation.city = yup
        .string()
        .matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
        .min(3, t('must_be_3_characters_or_more'))
        .max(255, t('must_be_255_characters_or_less'))
        .required(t('city_required'));
      newValidation.zip = yup
        .string()
        .matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
        .min(3, t('must_be_3_characters_or_more'))
        .max(20, t('must_be_20_characters_or_less'))
        .required(t('zip_required'));
      newValidation.countryCode = yup
        .string()
        .matches(/^[A-Za-z0-9 ]*$/, t('special_characters_are_not_allowed'))
        .max(80, t('must_be_80_characters_or_less'))
        .required(t('country_is_required_for_this_bank_country'));
    } else {
      newValidation.addressLine1 = yup
        .string()
        .matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
        .min(3, t('must_be_3_characters_or_more'))
        .max(255, t('must_be_255_characters_or_less'));

      newValidation.countryCode = yup
        .string()
        .matches(/^[A-Za-z0-9 ]*$/, t('special_characters_are_not_allowed'))
        .max(80, t('must_be_80_characters_or_less'));
    }

    return newValidation;
  };

  function resetBankFieldValues(_selectedBeneficiary?: BeneficiaryDto) {
    form.setFieldValue('iban', _selectedBeneficiary?.iban || '');
    form.setFieldValue('accountNumber', _selectedBeneficiary?.accountNumber || '');
    form.setFieldValue('swift', _selectedBeneficiary?.swift || '');
    form.setFieldValue('nationalBankCode', _selectedBeneficiary?.nationalBankCode || '');
  }

  function generateTabTitles(_availablePaymentMethods: CountryBankFields | undefined): Array<'international' | 'local'> {
    const newTabTitles: Array<'international' | 'local'> = [];

    if (!_availablePaymentMethods) return newTabTitles;

    if (_availablePaymentMethods.international.length) {
      newTabTitles.push('international');
    }
    if (_availablePaymentMethods.local.length) {
      newTabTitles.push('local');
    }

    return newTabTitles;
  }

  function resetExtraBankInformation(_selectedBeneficiary?: BeneficiaryDto) {
    form.setValues({
      ...form.values,
      correspondentAccountNumber: _selectedBeneficiary?.correspondentAccountNumber || undefined,
      correspondentSwift: _selectedBeneficiary?.correspondentSwift || undefined,
      furtherToAccountNumber: _selectedBeneficiary?.furtherToAccountNumber || undefined,
      furtherToSwift: _selectedBeneficiary?.furtherToSwift || undefined,
    });
  }

  return {
    loading,
    tabTitles,
    tab,
    bankCountryCode,
    currencyCode,
    handleDynamicValidationTabChange,
    requiresAddress,
  };
};

export default usePaymentMethods;
