import React from 'react';

import { PaymentDto } from '@alpha/payments-dtos';
import { Stat } from '@alpha/ui-lib/ui/Stat';

import { formatNumber, getCurrencySymbol } from '../../../../../utils/currency.helpers';
import isPaymentBatchDraftDto from '../../../../../utils/payments/isPaymentBatchDraftDto.helper';
import { IBatchCurrencyPairDecorated } from '../../BatchSummary.interface';
import { useStyles } from '../BatchDetails.styles';

interface IProps {
  batchDetails: PaymentDto,
  currencyPairTab: IBatchCurrencyPairDecorated,
}

const CurrencyAmount = (props: IProps) => {
  const classes = useStyles();
  const {
    batchDetails,
    currencyPairTab,
  } = props;

  const handleAmount = (): number => {
    if ((batchDetails.status !== 'PROCESSING'
      && batchDetails.status !== 'COMPLETED')) {
      return currencyPairTab.amount;
    } return currencyPairTab.debitAmount;
  };

  if (((batchDetails.status === 'PROCESSING'
    || batchDetails.status === 'COMPLETED')
    && !isPaymentBatchDraftDto(batchDetails))
    || (batchDetails.status !== 'PROCESSING'
      && batchDetails.status !== 'COMPLETED')
  ) {
    return (
      <Stat
        testId="currency-pair-amount"
        title={`${currencyPairTab.fixedSide} Amount`}
        value={(
          <span className={classes.bold}>
            {`${getCurrencySymbol(currencyPairTab.fixedSide)}${formatNumber(
              +handleAmount(),
              2,
            )}`}
          </span>
        )}
        titleAlignRight
      />
    );
  }
  return null;
};

export default CurrencyAmount;
