import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles((theme) => ({
  table: {
    '& td:not(:nth-child(1)), & th:not(:nth-child(1))': { paddingLeft: '10px !important', paddingRight: '4px !important' },
    '& td:nth-child(1), & tr:not(:nth-child(1)) th:nth-child(1)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(2), & tr:not(:nth-child(1)) th:nth-child(2)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(5), & tr:not(:nth-child(1)) th:nth-child(5)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(8), & tr:not(:nth-child(1)) th:nth-child(8)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(12):not(:last-child), & tr:not(:nth-child(1)) th:nth-child(12):not(:last-child)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(15):not(:last-child), & tr:not(:nth-child(1)) th:nth-child(15):not(:last-child)': { borderRight: '#eee solid 1px !important' },
    '& thead tr:nth-child(1) th:not(:last-child)': { borderRight: '#eee solid 1px !important' },

    borderCollapse: 'separate',
    '& thead': {
      position: 'sticky',
      top: '221px',
      marginTop: '-115px',
      zIndex: '100',
    },
    '& thead tr th': {
      backgroundColor: '#ffffff !important',
    },
  },
  greenText: {
    fontWeight: theme.typography.fontWeightSemiBold,
    color: 'rgb(9, 131, 117)',
  },
}), { name: 'PersonalPermissionsMatrixTable' });

export default useStyles;
