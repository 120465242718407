import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  (typography) => ({
    root: {
      paddingTop: '24px',
      paddingBottom: '30px',
      backgroundColor: '#fafafa',
      minHeight: 'calc(100vh - 310px)',
    },
    container: {
      padding: '0 8px !important',
      width: '974px',
    },
    modalHeader: {
      fontSize: '16px',
      fontFamily: typography.fontFamily,
      fontWeight: 800,
      padding: '4px 0 20px 36px',
    },
    buttonContainer: {
      width: '100%',
      height: '49.04px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      marginBottom: '10px',
    },
    summary: {
      minWidth: 'max-content',
      width: '65%',
      '@media (max-width: 800px)': {
        gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr));',
      },
      backgroundColor: '#fff',
      borderRadius: '10px',
      marginBottom: '20px',
    },
    statsContainer: {
      padding: '20px',
      display: 'flex',
      justifyContent: 'start',
      gap: 36,
    },
    statsModalContainer: {
      padding: '10px',
      display: 'flex',
      justifyContent: 'start',
      gap: 36,
      border: '1px solid #EAEAEA',
      borderRadius: '10px',
      width: 'fit-content',
      marginTop: '2px',
    },
    statValue: {
      fontWeight: 300,
      fontSize: '14px',
    },
    statHeader: {
      fontWeight: 300,
      fontSize: '12px',
    },
    panel: {
      maxWidth: '100%',
      '@media (max-width: 800px)': {
        gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr));',
      },
      paddingBottom: '40px',
      margin: 'auto',
      backgroundColor: '#fff',
      borderRadius: '10px',
      marginBottom: '20px',
    },
    title: {
      padding: '20px 20px 10px 20px',
      fontWeight: 600,
      fontFamily: 'Source Sans Pro',
      fontSize: '16px',
    },
    tickIcon: {
      fontSize: 15,
      color: '#51A869',
      marginRight: '10px',
    },
    downloadIcon: {
      fontSize: 15,
      color: '#999999',
      marginLeft: '10px',
    },
    warningIcon: {
      fontSize: 15,
      color: '#CF752F',
      marginRight: '10px',
    },
    rejectedIcon: {
      fontSize: 15,
      color: '#A62B23',
      marginRight: '10px',
    },
    icon: {
      marginRight: '10px',
    },
    alert: {
      margin: '20px',
    },
    tableWrapper: {
      padding: '20px',
      '& .MuiTableCell-root': {
        'padding-right': '10px !important',
        'padding-left': '10px !important',
      },
      '& th.MuiTableCell-head': {
        color: '#9A9A9A',
      },
      '& td.MuiTableCell-root': {
        fontSize: '13px',
      },
      '@media (max-width: 1200px)': {
        '& .MuiTableCell-root': {
          'padding-right': '10px !important',
          'padding-left': '10px !important',
          fontSize: '13px',
        },
        '& th.MuiTableHead-root, & td.MuiTableCell-root': {
          fontSize: '12px',
          '& span': {
            fontSize: '11px',
          },
        },
      },
      '& td': {
        padding: '0px 0px !important',
      },
    },
  }),
  { name: 'BatchBeneficiaries' },
);

export default useStyles;
