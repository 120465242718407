import React from 'react';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Typography } from '@alpha/ui-lib/ui/external';

import MultiSelectDropDown from '../../../../../../components/MultiSelectDropDown/MultiSelectDropDown';

interface IProps {
  currentOptions: Approver[],
  loading: boolean,
  awaitingSubmit: boolean,
  menuCloseCount: number,
  setMenuCloseCount?: React.Dispatch<React.SetStateAction<number>>
  handleSubmit?: () => void,
  setCurrentOptions?: React.Dispatch<React.SetStateAction<Approver[]>>,
  setTextValue?: React.Dispatch<React.SetStateAction<string>>,
  eligibleApprovers: () => Approver[],
  id?: string,
}

const DropDownWrapper = ({
  loading,
  currentOptions,
  awaitingSubmit,
  menuCloseCount,
  setMenuCloseCount,
  handleSubmit,
  eligibleApprovers,
  setTextValue,
  setCurrentOptions,
  id,
}: IProps) => {

  return (
    <Box display="flex" flexDirection="column">
      <Typography>
        {t('select_from_list_of_available_approvers')}
      </Typography>
      <Box paddingTop="7px" paddingBottom="16px">
        <MultiSelectDropDown
          onClose={() => {
            if (setMenuCloseCount) {
              setMenuCloseCount(menuCloseCount + 1);
            }
          }}
          onOpen={() => { if (setMenuCloseCount) { setMenuCloseCount(0); } }}
          loading={loading}
          options={eligibleApprovers()}
          setCurrentOptions={setCurrentOptions}
          placeholder={t('select')}
          setTextValue={setTextValue}
          id={id}
        />
      </Box>
      <ActionButton
        disabled={currentOptions?.length === 0 || awaitingSubmit}
        loading={awaitingSubmit}
        fullWidth
        size="large"
        testId="send-email"
        onClick={handleSubmit}
      >
        {t('send_email')}
      </ActionButton>
    </Box>
  );
};
export default DropDownWrapper;
