import React from 'react';
import { useMutation } from 'react-query';

import { TradeDto } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import t from 'utils/translationHelper';

import Warning from '../../../../assets/warning.svg';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import instance from '../../../../services/Axios/instance';
import APModal from '../../../Modals/APModal/APModal';
import useStyles from '../../Payments/RejectPaymentModal/index.styles';

type Props = {
  trade: TradeDto;
  open: boolean;
  handleClose: () => void;
  handleReloadTradesTable?: () => void;
}

const CancelTradeModal: React.FC<Props> = ({
  open, trade, handleClose, handleReloadTradesTable,
}: Props) => {

  const styles = useStyles();
  const { logEvent, logError } = useLog();
  const sb = useAlphaSnackbar();

  const rejectMutation = useMutation((tradeId: string) => instance.delete(`fx/trades/${tradeId}`), {
    onSuccess: () => {
      sb.trigger(t('successfully_cancelled_trade'), 'success');
      logEvent({ action: 'Successfully cancelled draft trade' });
      if (handleReloadTradesTable) handleReloadTradesTable();
      handleClose();
    },
    onError: (error) => {
      logError({ action: 'Error cancelling trade', error });
    },
  });

  const displayModalMessage = (
  ) => (
    <>
      <Typography className={styles.modalBoldText}>
        {t('you_are_about_to_cancel_this_trade')}
      </Typography>
      <Typography>{t('are_you_sure_you_want_to_perform_this_action')}</Typography>
    </>
  );

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div className={styles.modalContent}>
          <img src={Warning} className={styles.modalImage} alt="Warning" />
          <div style={{ marginBottom: '48px' }}>
            {displayModalMessage()}
          </div>
          <div style={{ width: '100%' }}>
            <ActionButton
              size="medium"
              style={{
                background: '#F7F7F7', color: '#212529', width: '170px', marginRight: '8px',
              }}
              variant="outlined"
              disabled={rejectMutation.isLoading}
              onClick={handleClose}
            >
              {t('cancel')}
            </ActionButton>
            <ActionButton
              size="medium"
              style={{ width: '170px' }}
              loading={rejectMutation.isLoading}
              disabled={rejectMutation.isLoading || !trade}
              onClick={() => rejectMutation.mutate(trade.id)}
            >
              {t('cancel_trade')}
            </ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default CancelTradeModal;
