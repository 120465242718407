import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    tableButton: {
      textTransform: 'none',
      fontSize: 14,
      padding: 0,
      fontWeight: 600,
      color: 'rgb(9, 131, 117)',
      textDecoration: 'none',
      textAlign: 'left',
      justifyContent: 'left',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
    amount: {
      fontWeight: 600,
    },
    badge: {
      background: '#EAEAEA 0% 0% no-repeat padding-box',
      borderRadius: '4px',
      textAlign: 'center',
      font: 'normal normal 600 11px',
      letterSpacing: '1.65px',
      padding: '8px 4px',
      width: '52px',
      color: '#626262',
      textTransform: 'uppercase',
    },
    status: {
      minWidth: '154px',
      textAlign: 'center',
    },
  }),
  { name: 'PaymentTable' },
);

export default useStyles;
