import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  (theme) => ({
    sectionHeader: {
      fontSize: 16,
      weight: theme.typography.fontWeightSemiBold,
      marginBottom: '30px',
    },
    programName: {
      color: '#808080',
      marginLeft: '4px',
    },
    toggleContainer: {
      marginBottom: '20px',
      display: 'flex',
      marginLeft: 'auto',
      justifyContent: 'end',
    },
    toggleButton: {
      backgroundColor: ' #f7f7f7',
      borderRadius: '5px',
      fontWeight: 600,
      "& span[class*=' Chip-root'], & span[class^='Chip-root']": {
        borderRadius: '0px',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },
      "& span[class*=' Chip-root']:nth-child(2), & span[class^='Chip-root']:nth-child(2)": {
        marginLeft: '0px',
        borderRadius: '0px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      },
      "& span[class*=' Chip-selected'], & span[class^='Chip-selected']": {
        backgroundColor: '#1E8777',
        color: 'white',
        transition: 'background-color 200ms ease-in-out',
      },
    },
  }),
  { name: 'HedgingProgramChart' },
);

export default useStyles;
