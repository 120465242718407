import React, { ChangeEvent, useEffect } from 'react';
import useLog from 'hooks/useLog';
import useSearch, { TSearchParams } from 'hooks/useSearch';
import { useLocation } from 'react-router-dom';

const usePositionValuationTable = (
  mtmDate: string,
  setSearchTextForReport?: React.Dispatch<React.SetStateAction<string | undefined>>,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
  buyCurrencies?: string[],
  sellCurrencies?: string[],
  termId?: string,
  termsIds?: string[],
  selectedCurrency?: string,
) => {
  const table = useSearch();
  const location = useLocation();
  const { logEvent } = useLog();

  const setBaseUrl = () => {
    if (termId) {
      return `/credit/facility/terms/${termId}/mtm`;
    }
    if (termsIds!.length > 0) {
      return `/credit/facility/terms/${termsIds[0]}/mtm`;
    }
    if (location.pathname.includes('credit')) {
      return '/credit/facility/terms/mtm';
    }
    return '/credit/trades/mtm/search';
  };

  const createSearchParams = (
    skip: number,
    sortByParam?: string,
    sortOrderParam: 'asc' | 'desc' = 'desc',
    searchText?: string,
  ): TSearchParams => {
    const searchParams: TSearchParams = {
      baseUrl: setBaseUrl(),
      queryParams: {
        skip,
        take: 10,
        sortby: sortByParam || '',
        sortorder: sortOrderParam,
        mtmdate: mtmDate,
        includeOptions: true,
        mtmcurrency: selectedCurrency,
      },
    };

    if (searchText) {
      searchParams.queryParams.searchtext = searchText;
    }
    return searchParams;
  };

  const defaultSortBy = sortBy ?? 'tradeDate';
  const defaultSortOrder = sortOrder ?? 'desc';
  const searchParams = createSearchParams(
    table.skip,
    defaultSortBy,
    defaultSortOrder,
    table.searchText,
  );

  const handleTableSortClick = (column: string) => {
    if (sellCurrencies) {
      searchParams.queryParams.soldcurrency = sellCurrencies.join(',');
    }
    if (buyCurrencies) {
      searchParams.queryParams.buycurrency = buyCurrencies.join(',');
    }
    table.handleTableSortClick(
      searchParams, column,
    );
  };

  useEffect(() => {
    if (mtmDate) {
      searchParams.queryParams.mtmdate = mtmDate;
      if (sellCurrencies) {
        searchParams.queryParams.soldcurrency = sellCurrencies.join(',');
      }
      if (buyCurrencies) {
        searchParams.queryParams.buycurrency = buyCurrencies.join(',');
      }
      searchParams.queryParams.skip = 0;
      table.handleInitialSearch(searchParams);
    }
  }, [mtmDate]);

  useEffect(() => {
    if (mtmDate) {
      searchParams.queryParams.mtmdate = mtmDate;
      if (sellCurrencies) {
        searchParams.queryParams.soldcurrency = sellCurrencies.join(',');
      }
      if (buyCurrencies) {
        searchParams.queryParams.buycurrency = buyCurrencies.join(',');
      }
      searchParams.queryParams.skip = 0;
      table.handleNewSearch(searchParams);
    }
  }, [sellCurrencies, buyCurrencies, mtmDate]);

  useEffect(() => {
    if (selectedCurrency) {
      searchParams.queryParams.mtmcurrency = selectedCurrency;
      table.handleNewSearch(searchParams);
    }
  }, [selectedCurrency]);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    table.setSearchText(e.target.value);
    logEvent({ action: `Search ${e.target.value}` });
    if (e.target.value) {
      searchParams.queryParams.skip = 0;
      searchParams.queryParams.searchtext = e.target.value;
      if (setSearchTextForReport) { setSearchTextForReport(e.target.value); }
    } else {
      delete searchParams.queryParams.searchtext;
    }
    if (sellCurrencies) {
      searchParams.queryParams.soldcurrency = sellCurrencies.join(',');
    }
    if (buyCurrencies) {
      searchParams.queryParams.buycurrency = buyCurrencies.join(',');
    }
    if (mtmDate) {
      table.handleNewSearch(searchParams);
    }
  };

  return {
    table,
    searchParams,
    handleInputChange,
    handleTableSortClick,
  };
};

export default usePositionValuationTable;
