import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import queryString from 'querystring';
import { useMutation } from 'react-query';
import t from 'utils/translationHelper';

import {
  AccountConfigurationFXDto,
  ExternalTradeRequest,
  ProgramDirection,
  ProgramDto,
  ProgramPeriodDto,
  TradeDto,
} from '@alpha/fx-dtos';
import { datadogLogs } from '@datadog/browser-logs';

import useAlphaSnackbar from '../../hooks/useAlphaSnackbar';
import useForm from '../../hooks/useForm';
import useLog from '../../hooks/useLog';
import FXTradeService from '../../services/FXTrade/fxTrade.service';
import HedgingProgramsService from '../../services/HedgingPrograms/hedgingPrograms.service';
import browserHistory from '../../services/history/browserHistory';

import { initialValues, validation } from './UploadTradeModal/formData';

export const useHedgingPrograms = (
  setOpenExternalTradeModal: Dispatch<SetStateAction<boolean>>,
  programId: string | undefined,
  setProgramId: Dispatch<SetStateAction<string | undefined>>,
  setAccountConfiguration: Dispatch<SetStateAction<AccountConfigurationFXDto | undefined>>,
) => {
  const { logEvent, logError } = useLog();
  const [program, setProgram] = useState<ProgramDto>();
  const [periods, setPeriods] = useState<ProgramPeriodDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [submittingTrade, setSubmittingTrade] = useState<boolean>(false);
  const [externalTrades, setExternalTrades] = useState<TradeDto[]>([]);
  const [periodsToShowInChart, setPeriodsToShowInChart] = useState<ProgramPeriodDto[]>([]);

  const sb = useAlphaSnackbar();

  const postExtTrade = useMutation('postExtTrade', FXTradeService.postExternalTrade, {
    onSuccess: () => {
      setOpenExternalTradeModal(false);
      setSubmittingTrade(false);
      externalTradeForm.setSubmitting(false);
      externalTradeForm.resetForm();
      externalTradeForm.setFieldValue('programId', programId);
      sb.trigger(t('successfully_added_external_trade'), 'success');
      logEvent({ action: 'Successfully added External Trade' });
      if (programId) getProgramPeriodsData(programId, false);
      setTimeout(() => {
        handleReloadExternalTradesTable();
      }, 3000);
    },
    onError: (error) => {
      externalTradeForm.setSubmitting(false);
      setSubmittingTrade(false);
      sb.trigger(t('error_submitting_external_trade'), 'error');
      logError({ action: 'Error submitting External Trade', error });
    },
  });

  useEffect(() => {
    const lastNonEmptyPeriod = [...periods].reverse().find((period) => period.programAmount);
    const lastNonEmptyIndex = lastNonEmptyPeriod ? periods.lastIndexOf(lastNonEmptyPeriod) : 0;

    setPeriodsToShowInChart(periods.slice(0, Math.max(4, lastNonEmptyIndex + 1)));
  }, [periods]);

  const getProgramPeriodsData = async (id: string, showLoadingSpinner = true) => {
    try {
      if (showLoadingSpinner) { setLoading(true); }
      const result = await HedgingProgramsService.getHedgingProgramPeriodsData(id);

      if (result.program) { setProgram(result.program); }
      if (result.periods) { setPeriods(result.periods || []); }
    } catch (e) {
      sb.trigger(e?.message || t('could_not_load_program_data'));
      datadogLogs.logger.error(e);
    } finally {
      if (showLoadingSpinner) { setLoading(false); }
    }
  };

  const getExternalTradesData = async (tradesProgram: ProgramDto) => {
    const programCurrencies = tradesProgram.currencyPairCode ? tradesProgram.currencyPairCode.split('/') : [];

    let programBuyCurrency = '';
    let programSellCurrency = '';

    if (tradesProgram.direction === ProgramDirection.BUY) {
      programBuyCurrency = programCurrencies.find((c) => c === tradesProgram.currencyCode) || '';
      programSellCurrency = programCurrencies.find((c) => c !== tradesProgram.currencyCode) || '';
    } else {
      programBuyCurrency = programCurrencies.find((c) => c !== tradesProgram.currencyCode) || '';
      programSellCurrency = programCurrencies.find((c) => c === tradesProgram.currencyCode) || '';
    }

    try {
      const result = await HedgingProgramsService.getPeriodTradesData({
        queryParams: {
          take: 9999,
          skip: 0,
          status: 'ACTIVE,COMPLETED',
          sortby: 'valueDate',
          sortorder: 'asc',
          buycurrency: programBuyCurrency,
          soldcurrency: programSellCurrency,
          valuedatefrom: tradesProgram.startDate,
          valuedateto: tradesProgram.endDate,
          applyprogramexclusion: 'true',
        },
      });
      const trades = result.filter((trade) => trade.origin === 'EXTERNAL');
      if (trades.length) {
        setExternalTrades(trades);
      }
    } catch (e) {
      sb.trigger(e?.message || t('could_not_load_program_data'));
      datadogLogs.logger.error(e);
    }
  };

  const externalTradeForm = useForm<ExternalTradeRequest>(
    initialValues,
    validation(), (externalTrade: ExternalTradeRequest) => {
      setSubmittingTrade(true);
      const tradesRequest: ExternalTradeRequest[] = [externalTrade];
      postExtTrade.mutate(tradesRequest);
    },
  );

  const handleCloseTradeModal = () => {
    setOpenExternalTradeModal(false);
    externalTradeForm.resetForm();
    externalTradeForm.setFieldValue('programId', programId);
  };

  const checkExternalTradeDate = () => {
    if (program?.startDate && program.endDate) {
      if (Date.parse(program?.startDate) > Date.now()) {
        externalTradeForm.setFieldValue('valueDate', program.startDate);
      } else if (Date.parse(program.endDate) < Date.now()) {
        externalTradeForm.setFieldValue('valueDate', program.endDate);
      }
    }
  };

  const handleReloadExternalTradesTable = () => {
    if (program) {
      getExternalTradesData(program);
    }
  };

  const checkUrlForProgramId = () => {
    const filterParams = browserHistory.location.search.substring(1);
    const filtersFromParams = queryString.parse(filterParams);
    if (filtersFromParams.id) {
      setProgramId(String(filtersFromParams.id));
    }
  };

  const getAccountConfiguration = async () => {
    try {
      const result = await FXTradeService.getAccountConfiguration();
      setAccountConfiguration(result);
    } catch (e) {
      sb.trigger(e?.message || t('could_not_load_account_configuration_data'));
    }
  };

  return {
    handleCloseTradeModal,
    checkExternalTradeDate,
    externalTradeForm,
    getProgramPeriodsData,
    program,
    periods,
    loading,
    submittingTrade,
    getExternalTradesData,
    getAccountConfiguration,
    externalTrades,
    handleReloadExternalTradesTable,
    checkUrlForProgramId,
    periodsToShowInChart,
  };
};

export default useHedgingPrograms;
