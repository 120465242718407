import React from 'react';

import { makeStyles } from '@alpha/ui-lib/theme';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import authyIcon from '../../../assets/authyIcon.png';

const useStyles = makeStyles(
  ({ palette }) => ({
    container: {
      marginBottom: '40px',
    },
    hr: {
      width: '65px',
      height: '3px',
      color: palette.primary.main,
      borderColor: palette.primary.main,
      backgroundColor: palette.primary.main,
      margin: '30px auto 25px auto',
    },
  }),
  { name: 'Success' },
);

interface IProps {
  headerText: string;
  authySymbol: boolean;
  subHeaderText: JSX.Element | string;
  classes: Record<'header' | 'mfaInputFields' | 'authContainer' | 'info' | 'cta', string>;
}

const Header: React.FC<IProps> = ({
  headerText,
  authySymbol,
  subHeaderText,
  classes,
}: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <img
        src={authySymbol ? authyIcon : '/static/images/lock.png'}
        alt={authySymbol ? 'Authy Icon' : 'Lock sign'}
        style={{ borderRadius: authySymbol ? '50%' : '0%' }}
      />
      <h2 className={classes.header}>{headerText}</h2>
      <hr className={styles.hr} />
      <Typography variant="h2" className={classes.info}>
        {subHeaderText}
      </Typography>
    </div>
  );
};

export default Header;
