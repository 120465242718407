class NetworkError {
  constructor(message: string) {
    const error = Error(message);

    // set immutable object properties
    Object.defineProperty(error, 'message', {
      get() {
        return message;
      },
    });
    Object.defineProperty(error, 'name', {
      get() {
        return 'NetworkError';
      },
    });
    return error;
  }
}

export default NetworkError;
