import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AccountDto } from '@alpha/auth-dtos';
import { BeneCopyRequestDto } from '@alpha/bene-dtos';
import t from 'utils/translationHelper';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import BeneficiariesService from '../../../../services/Beneficiaries/beneficiaries.service';
import { initiateBeneficiaryBatch } from '../../../../store/authy/actions';

const useShareBeneficiary = (beneficiaryId: string, availableAccounts: AccountDto[]) => {
  const { logError, logEvent } = useLog();
  const searchTerm = useRef<string>('');
  const [
    displayingAccounts,
    setDisplayingAccounts,
  ] = useState<AccountDto[]>(availableAccounts || []);
  const [selectedAccounts, setSelectedAccounts] = useState<AccountDto[]>([]);
  const [loading, setLoading] = useState(false);


  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();

  useEffect(() => {
    setDisplayingAccounts(availableAccounts);
  }, [availableAccounts]);

  const handleShareBeneficiarySubmit = async () => {
    try {
      setLoading(true);
      const { beneCopyBatchId } = await BeneficiariesService
        .shareBeneficiaryWithSelectedAccounts({
          beneficiaryId,
          accountIds: selectedAccounts.map((sa) => sa.id),
        } as BeneCopyRequestDto);
      dispatch(initiateBeneficiaryBatch({
        batchId: beneCopyBatchId,
        type: 'BENEFICIARY_BATCH',
      }));
      logEvent({ action: 'Successfully shared beneficiary' });
    } catch (e) {
      sb.trigger(e?.message || e?.toString() || t('something_went_wrong_sharing_your_accounts'));
      logError({ action: 'Error sharing beneciary', error: e });
    } finally {
      setLoading(false);
    }
  };

  const getFilterSearch = (input: string, arr: AccountDto[]) => arr.filter(
    (accountToShare: AccountDto) => accountToShare.name.toLowerCase()
      .includes(input.toLowerCase()),
  );

  const handleShareKeyUp = (e: any): void => {
    const { value } = e.target;
    searchTerm.current = value;
    setDisplayingAccounts(getFilterSearch(value, availableAccounts));
  };

  const handleSelectAccount = (accountId: string) => {
    const selectedAccountIndex = selectedAccounts.findIndex((sa) => sa.id === accountId);

    if (selectedAccountIndex > -1) {
      setSelectedAccounts(selectedAccounts.filter((sa) => sa.id !== accountId));
      return;
    }

    const findAccount = availableAccounts.find(
      (accountDto: AccountDto) => accountDto.id === accountId,
    );

    if (findAccount) {
      setSelectedAccounts([...selectedAccounts, findAccount]);
    }
  };

  const handleAllAccounts = () => {
    if (selectedAccounts.length === availableAccounts.length) {
      setSelectedAccounts([]);
      return;
    }

    setSelectedAccounts(availableAccounts);
  };

  return {
    selectedAccounts,
    availableAccounts,
    displayingAccounts,
    searchTerm,
    loading,
    handleSelectAccount,
    handleAllAccounts,
    handleShareKeyUp,
    handleShareBeneficiarySubmit,
  };
};

export default useShareBeneficiary;
