import React from 'react';

import { Button } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from '../../FxTradeTable.styles';

import TradeLinkContent from './TradeLinkContent/TradeLinkContent';

interface IProps {
  tradeContractNumber?: string;
  tradeSellCurrency?: string;
  tradeId: string;
  handleTradeLinkClick: (tradeId: string) => void;
}

const TradeLink: React.FC<IProps> = (props: IProps) => {
  const {
    tradeContractNumber, tradeSellCurrency, tradeId, handleTradeLinkClick,
  } = props;
  const classes = useStyles();
  return (
    <div>
      <APTooltip
        data-testid="trade-link"
        title={
          tradeContractNumber
          && tradeSellCurrency
          && tradeId
            ? (
              <TradeLinkContent
                tradeContractNumber={tradeContractNumber}
                tradeSellCurrency={tradeSellCurrency}
                tradeId={tradeId}
              />
            ) : ''
          }
      >
        <div>
          <Button
            className={classes.tradeLinkIcon}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              if (handleTradeLinkClick) { handleTradeLinkClick(tradeId); }
            }}
            data-testid="trade-link-btn"
          >
            <FontAwesomeIcon size="lg" icon={faLink as IconProp} />
          </Button>
        </div>
      </APTooltip>
    </div>
  );
};

export default TradeLink;
