import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ typography }) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'absolute',
      border: '1px solid #E2E2E2',
      padding: '0 24px',
      width: 'auto',
      height: '45px',
      backgroundColor: 'white',
      transform: 'translateY(-155%)',

      '& .MuiTypography-root': {
        alignSelf: 'center',
      },
    },
    rateFont: {
      alignSelf: 'center',
      display: 'flex',
      height: 25,
      color: '#222222',
      fontWeight: typography.fontWeightBold,
      fontSize: 12,
    },
    coloredBackground: {
      padding: '4px 10px',
      textTransform: 'uppercase',
      letterSpacing: 1.5,
      borderRadius: 5,
    },
    live: {
      backgroundColor: '#1E8777',
      color: 'white',
    },
    indicative: {
      backgroundColor: 'rgba(25, 127, 171, 0.2)',
      color: '#197FAB',
    },
    loading: {
      backgroundColor: '#dddddd',
    },
    expired: {
      backgroundColor: '#707070',
    },
    liveRate: {
      color: '#1E8777',
    },
    rate: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    inverseRateWrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px 0 10px 10px',
      borderLeft: '1px solid rgba(112, 112, 112, 0.3)',
      paddingLeft: 17,
    },
    inverseRate: {
      color: '#9F9F9F',
      fontweight: typography.fontWeightRegular,
    },
    icon: {
      alignSelf: 'center',
      marginLeft: 9,
      marginTop: 2,
    },
    tooltip: {
      width: 160,
    },
    amount: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiTypography-root': {
        marginRight: '8px',
        color: '#222222',
        lineHeight: '26px',
      },
      '& > svg': {
        position: 'relative',
      },
    },
    green: {
      color: '#1E8777',
      top: '4px',
    },
    red: {
      color: '#B95656',
      top: '-2px',
    },
    bold: {
      fontWeight: 'bold',
      marginLeft: 15,
    },
    light: {
      fontWeight: 300,
    },
    exampleRate: {
      fontWeight: 'bold',
      color: '#707070',
    },
    verticalLine: {
      height: '25px',
      borderRight: '1px solid #E2E2E2',
    },
  }), { name: 'InterAccountTransferIndicativeRate' },
);

export default useStyles;
