import { makeStyles } from '@alpha/ui-lib/ui/external';

const useStyles = makeStyles({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: '12px',
    backgroundColor: '#fafafa',
  },
});

export default useStyles;
