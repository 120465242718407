import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    alignItems: 'center',
  },
  description: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}), { name: 'PaymentPurposeDropdownItemRenderValue' });
export default useStyles;
