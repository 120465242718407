import React, { FC } from 'react';
import t from 'utils/translationHelper';

import { Footer as OriginalFooter } from '@alpha/ui-lib/ui/layout/Footer';

const VERSION = process.env.REACT_APP_VERSION;
const mainUrl = { text: 'Alpha Group plc', url: 'https://www.alphafx.co.uk' };
const year = new Date().getFullYear();

export const Footer: FC = () => {

  const footerLinks = [
    { text: t('privacy_and_cookie_policy'), url: 'https://alphafx.co.uk/legal/privacy-policy' },
    { text: t('terms_of_use'), url: 'https://alphafx.co.uk/site/legal/terms-of-use' },
    { text: VERSION ? `v.${VERSION}` : '', url: '' },
  ];
  return (
    <OriginalFooter
      testId="main-footer"
      year={year}
      mainUrl={mainUrl}
      footerLinks={footerLinks}
      copyright={t('copyright')}
      rights={t('all_rights_reserved')}
    />
  );
};
