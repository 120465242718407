import React, { useState } from 'react';
import APModal from 'components/Modals/APModal/APModal';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import t from 'utils/translationHelper';
import CreditService from 'services/Credit/credit.service';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Multiline } from '@alpha/ui-lib/ui/Multiline';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import useStyles from './index.styles';

type Props = {
  open: boolean;
  handleClose: () => void;
}

const AmendmentRequestModal: React.FC<Props> = (
  {
    open, handleClose,
  }: Props,
) => {
  const styles = useStyles();

  const sb = useAlphaSnackbar();
  const { logEvent, logError } = useLog();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [request, setRequest] = useState<string>('');

  const submitAmendment = async (): Promise<void> => {
    try {
      setSubmitting(true);
      await CreditService.postFacilityAmendmentRequestAsync({ amendmentRequestText: request });
      logEvent({ action: 'Successfully submitted amendment request' });
      sb.trigger(t('successfully_submitted_amendment_request'), 'success');
    } catch (e) {
      sb.trigger(t('error_submitting_amendment_request'));
      logError({ action: 'Error submitting amendment request', error: e });
    } finally {
      if (handleClose) {
        handleClose();
      }
      setSubmitting(false);
      setRequest('');
    }
  };

  return (
    <APModal.Container
      open={open}
      className={
        styles.modalContainer
      }
      onClose={handleClose}
    >
      <>
        <APModal.Header
          onClose={() => {
            handleClose();
          }}
          showCloseButton
        >
          <Typography className={styles.modalHeader}>
            {t('facility_amendment_request')}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={styles.container}>
          <div className={styles.inputContainer}>
            <div>
              <Typography>
                {t('please_enter_details_of_request1')}
                {' '}
                <strong>{t('please_enter_details_of_request2')}</strong>
                {' '}
                {t('please_enter_details_of_request3')}
              </Typography>
              <Box className={styles.multilineContainer}>
                <Multiline
                  label={t('request')}
                  defaultValue={request}
                  value=""
                  placeholder={t('click_and_type_your_request')}
                  width="538px"
                  onChange={(event) => {
                    const { value } = event.target;
                    setRequest(value);
                  }}
                  disabled={submitting}
                />
              </Box>
            </div>
          </div>
        </APModal.Wrapper>
        <Box className={styles.footer}>
          <Box className={styles.btn}>
            <ActionButton
              size="medium"
              onClick={() => {
                submitAmendment();
              }}
              disabled={submitting || !request.length}
              loading={submitting}
            >
              {t('confirm')}
            </ActionButton>
          </Box>
        </Box>
      </>
    </APModal.Container>
  );
};

export default AmendmentRequestModal;
