import { OptionsLayoutDto, OptionsStripDto } from 'models/optionTrades';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import t from 'utils/translationHelper';

export const mapCamelCaseToSnakeCase = (input: string): string => input.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

export const mapOptionsStripDtoToColumns = (strips: OptionsStripDto[], layout: OptionsLayoutDto): ITableColumn[] => {
  if (!strips?.length || !layout?.columns?.length) {
    return [];
  }
  const { columns } = layout;

  return columns.map((column: { accessor: string | number; columnName: string; }) => ({
    header: t(mapCamelCaseToSnakeCase(column.columnName)),
    accessor: column.accessor,
    width: 200,
  })).filter((column) => column.accessor);
};
