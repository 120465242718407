/* eslint-disable no-return-await */
import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import routes from 'routes.path';
import browserHistory from 'services/history/browserHistory';

import {
  ChangePasswordRequest,
  NewPasswordRequest,
  ResetPasswordRequest,
  UserAcknowledgmentRequest,
} from '@alpha/auth-dtos';
import { MfaMethod } from '@alpha/profile-dtos';
import { CognitoUser } from '@aws-amplify/auth';

import { TSignIn } from '../../models/auth';
import { TUser } from '../../models/user';
import instance from '../Axios/instance';
import publicInstance from '../Axios/publicInstance';

class AuthService {
  public static async signIn(username: string, password: string): Promise<TSignIn> {
    return await Auth.signIn(username, password);
  }

  public static async signOut(): Promise<void> {
    await instance.post('/auth/sign-out'); // this is to perform a global sign out
    await Auth.signOut(); // this is to handle the sign out FE wise
  }

  public static async forgotPassword(username: string) {
    return await Auth.forgotPassword(username);
  }

  public static async forgotPasswordSubmit(
    username: string,
    code: string,
    password: string,
  ): Promise<void> {
    const body = {
      username,
      confirmationCode: code,
      password,
    } as ResetPasswordRequest;
    await publicInstance.post('/login/reset-password', body);
  }

  public static async newPassword(
    username: string,
    password: string,
    session: string,
  ): Promise<void> {
    const body = {
      username,
      password,
      session,
    } as NewPasswordRequest;
    await publicInstance.post('/login/new-password', body);
  }

  public static async changePassword(
    previousPassword: string,
    newPassword: string,
  ): Promise<void> {
    const body = {
      newPassword,
      previousPassword,
    } as ChangePasswordRequest;
    await instance.post('/auth/change-password', body);
  }

  public static async confirmMfa(user: Record<string, any>, code: string): Promise<void> {
    return await Auth.confirmSignIn(user, code);
  }

  public static async sendCustomChallengeAnswer(
    user: Record<string, any>,
    answer: string,
  ): Promise<Record<string, unknown>> {
    return await Auth.sendCustomChallengeAnswer(user, answer);
  }

  public static async currentSession() {
    return await Auth.currentSession();
  }

  public static async currentUserInfo(): Promise<TUser | undefined> {
    const response = await Auth.currentAuthenticatedUser();
    if (response) {
      return this.mapAmplifySignInResponseToUser(response);
    }
  }

  public static async getAccessToken() {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    return cognitoUser.getSignInUserSession()?.getAccessToken().getJwtToken();
  }

  public static async getAccessTokenIssuedAt() {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    return +(
      cognitoUser.getSignInUserSession()?.getAccessToken().getIssuedAt().toFixed() as string
    );
  }

  public static async getAccessTokenExpiry() {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    return +(
      cognitoUser.getSignInUserSession()?.getAccessToken().getExpiration().toFixed() as string
    );
  }

  public static async tokenExpirationTime(): Promise<number> {
    const issuedAt = await AuthService.getAccessTokenIssuedAt();
    const expirationTime = await AuthService.getAccessTokenExpiry();
    return expirationTime - issuedAt;
  }

  private static mapAmplifySignInResponseToUser(response: Record<string, any>): TUser {
    const userAttributes = response.attributes;
    return {
      SubId: userAttributes.sub,
      Username: response.username,
      Email: userAttributes.email,
      Name: userAttributes.name,
      PhoneNumber: userAttributes.phone_number,
    };
  }

  public static async postUserAcknowlegement(request: UserAcknowledgmentRequest): Promise<any> {
    const response: AxiosResponse<any> = await instance.post(
      '/auth/acknowledgment',
      request,
    );
    return response.data;
  }

  public static async sendPasswordChangeEmail(): Promise<any> {
    const response: AxiosResponse<any> = await instance.post('/auth/users/password-changed');
    return response.data;
  }

  public static async updateMfaMethod(request: MfaMethod): Promise<any> {
    const body = {
      mfaMethod: request,
    };
    const response: AxiosResponse<any> = await instance.put('/auth/update-mfa-preference', body);
    return response.data;
  }

  public static async updateLangPreference(request: string): Promise<any> {
    const body = {
      langPreference: request,
    };
    const response: AxiosResponse<any> = await instance.put('/auth/update-lang-preference', body);
    return response.data;
  }

  public static async updateFirstLanguagePreference(request: boolean): Promise<any> {
    const body = {
      firstLanguagePreference: request,
    };
    const response: AxiosResponse<any> = await instance.put('/auth/update-first-language-preference', body);
    return response.data;
  }

  public static async getIdToken(): Promise<string | undefined> {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    return cognitoUser.getSignInUserSession()?.getIdToken().getJwtToken();
  }

  public static async getSessionIdFromIdToken(): Promise<string | null> {
    try {
      const idToken = await AuthService.getIdToken();

      if (!idToken) throw new Error('No id token');

      const decodedIdToken = JSON.parse(Buffer.from(idToken.split('.')[1], 'base64').toString());

      return decodedIdToken?.sessionId;
    } catch (error: any) {
      return null;
    }
  }

  public static async saveSessionIdToLocalStorage(): Promise<void> {
    const sessionId = await AuthService.getSessionIdFromIdToken();

    if (sessionId) {
      localStorage.setItem('platform-session-id', sessionId);
    }
  }

  public static getSessionIdFromLocalStorage(): string | null {
    return localStorage.getItem('platform-session-id');
  }

  public static async handleInvalidSession(): Promise<void> {
    await Auth.signOut({
      global: false,
    });

    browserHistory.push(routes.invalidSession);
  }
}

export default AuthService;
