import React from 'react';

import { AccountDto } from '@alpha/auth-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';

import clipboardCross from '../../../../../assets/clipboardCross.svg';
import EmptyTable from '../../../../../components/Table/EmptyTable';
import SelectAccount from '../SelectAccount/index';
import t from 'utils/translationHelper';

import useStyles from './index.styles';

interface IProps {
  availableAccounts: AccountDto[],
  selectedAccounts: AccountDto[],
  displayingAccounts: AccountDto[],
  searchTerm: string,
  loading: boolean,
  handleShareBeneficiarySubmit: () => Promise<void>,
  handleSelectAccount: (accountId: string) => void,
  handleAllAccounts: () => void,
}

const Body: React.FC<IProps> = ({
  availableAccounts,
  selectedAccounts,
  displayingAccounts,
  searchTerm,
  loading,
  handleShareBeneficiarySubmit,
  handleSelectAccount,
  handleAllAccounts,
}: IProps) => {

  const classes = useStyles();

  const numberOfAvailableAccounts = selectedAccounts.length;
  const textButton = `${numberOfAvailableAccounts > 1 ? t('share_beneficiary_to_entities') : t('share_beneficiary_to_entity')}`;

  const disabledActionButton = Boolean(loading || !selectedAccounts.length);

  return (
    <Box className={classes.bodyWrapper}>
      <Box>
        <div className={classes.allEntriesBox}>
          <Box className={classes.noOfAccounts}>
            {`${searchTerm ? `${t('number_of_entities_for')} "${searchTerm}" : ` : `${t('number_of_entities')} `}`}
            <b>{displayingAccounts.length}</b>
          </Box>
          <SelectAccount
            handleChange={handleAllAccounts}
            checked={availableAccounts.length === selectedAccounts.length}
            text={`${t('select_all_entities')} (${availableAccounts.length})`}
            id="all"
            disabled={Boolean(!availableAccounts.length)}
          />
        </div>
        <div className={`${displayingAccounts.length ? `${classes.accountsBox}` : ''}`}>
          {displayingAccounts.length
            ? displayingAccounts.map(({ id, name }: AccountDto) => (
              <SelectAccount
                key={id}
                id={id}
                text={name}
                checked={selectedAccounts.some((sa) => sa.id === id)}
                handleChange={() => handleSelectAccount(id)}
              />
            ))
            : (
              <div className={classes.emptyTable}>
                <EmptyTable
                  icon={clipboardCross}
                  title={t('no_entities_found')}
                  subtitle={t('no_results_found_for_this_search_term')}
                />
              </div>
            )}
        </div>
      </Box>
      <Box className={classes.accButton}>
        <Box>
          {t('selected_entities')}
          <b className={classes.totalSelected}>
            {` ${selectedAccounts.length}`}
          </b>
        </Box>
        <ActionButton
          size="large"
          testId="share-bene-btn"
          onClick={handleShareBeneficiarySubmit}
          disabled={disabledActionButton}
        >
          {textButton}
        </ActionButton>
      </Box>
    </Box>
  );
};

export default Body;
