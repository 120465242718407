import clipboardCross from 'assets/clipboardCross.svg';
import { CurrencyPair } from 'components/International';
import EmptyTable from 'components/Table/EmptyTable';
import i18n from 'i18n/config';
import React from 'react';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapMarketOrdersStatusDisplay from 'utils/fxTrades/mapMarketOrdersStatusDisplay';
import mapTradeTypeToFriendlyName from 'utils/fxTrades/mapTradeTypeToFriendlyName';
import t from 'utils/translationHelper';

import { MarketOrderDto } from '@alpha/fx-dtos';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';

import useStyles from './MarketOrdersTable.styles';
import useMarketOrdersTable from './useMarketOrdersTable';

const MarketOrdersTable = () => {
  const { marketOrders, loading } = useMarketOrdersTable();

  const classes = useStyles();

  const columns = {
    en: [
      { header: t('market_order_ref'), accessor: 'marketOrderRef' },
      { header: t('trade_type'), accessor: 'tradeType' },
      { header: t('sold_ccy'), accessor: 'soldCcy', width: 120 },
      {
        header: t('sold_amount'), accessor: 'soldAmount', align: 'right', width: 150,
      },
      {
        header: t('rate'), accessor: 'rate', align: 'right', width: 120,
      },
      { header: t('buy_ccy'), accessor: 'buyCcy', width: 120 },
      {
        header: t('buy_amount'), accessor: 'buyAmount', align: 'right', width: 120,
      },
      { header: t('expiry_date'), accessor: 'expiryDate' },
      { header: t('status'), accessor: 'status' },
    ],
    es: [
      { header: t('market_order_ref'), accessor: 'marketOrderRef', width: 230 },
      { header: t('trade_type'), accessor: 'tradeType', width: 210 },
      { header: t('sold_ccy'), accessor: 'soldCcy', width: 130 },
      {
        header: t('sold_amount'), accessor: 'soldAmount', width: 190,
      },
      {
        header: t('rate'), accessor: 'rate', width: 160,
      },
      { header: t('buy_ccy'), accessor: 'buyCcy', width: 150 },
      {
        header: t('buy_amount'), accessor: 'buyAmount', width: 200,
      },
      { header: t('expiry_date'), accessor: 'expiryDate', width: 200 },
      { header: t('status'), accessor: 'status' },
    ],
  };

  const formattedTableData = marketOrders.map((column: MarketOrderDto) => ({
    ...column,
    marketOrderRef: <span className={classes.tableLink}>{column.contractNumber}</span> || '-',
    tradeType: (column.type && t(mapTradeTypeToFriendlyName(column.type))) || '-',
    soldCcy: (column.soldCurrencyCode && (
      <CurrencyPair currencyCode={column.soldCurrencyCode} />
    ))
      || '-',
    soldAmount: <span className={classes.amount}>{[formatNumber(column.soldAmount, 2), column.soldCurrencyCode].join(' ')}</span>,
    rate: <span className={classes.tableLink}>{column.rate.toFixed(4)}</span> || '-',
    buyCcy: (column.buyCurrencyCode && (
      <CurrencyPair currencyCode={column.buyCurrencyCode} />
    ))
      || '-',
    buyAmount: <span className={classes.amount}>{[formatNumber(column.buyAmount, 2), column.buyCurrencyCode].join(' ')}</span>,
    expiryDate: formatIsoDate(column.expiryDate),
    status: (
      <Status className={classes.status} variant={mapMarketOrdersStatusDisplay(column.status).variant}>
        {t(mapMarketOrdersStatusDisplay(column.status).text) || '-'}
      </Status>),
  }));

  if (loading) return <Loader testId="market-orders-loader" />;

  if (marketOrders.length) {
    return (
      <div className={classes.tableWrapper}>
        <StyledGenericTable
          testId="market-orders-table"
          columns={columns[i18n.language as keyof typeof columns] ?? columns.en}
          data={formattedTableData}
          handleTableRowClick={() => { }}
        />
      </div>

    );
  }

  return (
    <EmptyTable
      title={t('no_market_orders')}
      subtitle={t('you_currently_have_no_market_orders')}
      icon={clipboardCross}
    />
  );
};

export default MarketOrdersTable;
