import { PayloadAction } from '@reduxjs/toolkit';

import {
  TAuthyBeneficiaryBatchType,
  TAuthyBeneficiaryType,
  TAuthyDrawdownType,
  TAuthyPaymentsType,
  TAuthyTradeType,
  TAuthyTransferType,
} from './reducer';

export enum Actions {
  INITIATE = 'INITIATE',
  SUBMIT = 'SUBMIT',
}

const initiateBeneficiary = (
  payload: TAuthyBeneficiaryType,
): PayloadAction<TAuthyBeneficiaryType> => ({
  type: Actions.INITIATE,
  payload,
});

const submit = (payload: {
  totp: number,
  approvalRequestId?: string,
  dynamicLinkingId?: string
  }): PayloadAction<{
  totp: number, approvalRequestId?: string, dynamicLinkingId?: string }> => ({
  type: Actions.SUBMIT,
  payload,
});

const initiatePayment = (
  payload: TAuthyPaymentsType,
): PayloadAction<TAuthyPaymentsType> => ({
  type: Actions.INITIATE,
  payload,
});

const initiateBeneficiaryBatch = (
  payload: TAuthyBeneficiaryBatchType,
): PayloadAction<TAuthyBeneficiaryBatchType> => ({
  type: Actions.INITIATE,
  payload,
});

const initiateDrawdown = (
  payload: TAuthyDrawdownType,
): PayloadAction<TAuthyDrawdownType> => ({
  type: Actions.INITIATE,
  payload,
});

const initiateTrade = (
  payload: TAuthyTradeType,
): PayloadAction<TAuthyTradeType> => ({
  type: Actions.INITIATE,
  payload,
});

const initiateTradePADApprove = (
  payload: TAuthyTradeType,
): PayloadAction<TAuthyTradeType> => ({
  type: Actions.INITIATE,
  payload,
});

const initiateDrawdownPADApprove = (
  payload: TAuthyDrawdownType,
): PayloadAction<TAuthyDrawdownType> => ({
  type: Actions.INITIATE,
  payload,
});

const initiateTransfer = (
  payload: TAuthyTransferType,
): PayloadAction<TAuthyTransferType> => ({
  type: Actions.INITIATE,
  payload,
});

export {
  initiateBeneficiary,
  initiateBeneficiaryBatch,
  initiateDrawdown,
  initiateDrawdownPADApprove,
  initiatePayment,
  initiateTrade,
  initiateTradePADApprove,
  initiateTransfer,
  submit,
};
