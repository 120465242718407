import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  container: {
    '& .MuiTextField-root > label': {
      color: '#212529',
      fontSize: '19px',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '25px',
    },
    '& .datePicker': {
      width: '100%',
    },
    '&  .MuiFormControl-fullWidth': {
      width: '100% !important',
    },
  },
  chip: {
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      border: 'none',
      backgroundColor: '#f5f5f5',
      padding: '10px 30px 5px 15px',
      borderRadius: '4px',
      marginTop: '6px',
    },
    '& .MuiFormHelperText-root': {
      position: 'relative',
      left: '-13px',
      top: '3px',
    },
  },
}), { name: 'createpaymentdrawer' });

export default useStyles;
