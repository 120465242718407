import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
    paddingTop: '40px',
    background: '#F7F7F7',
    width: '100%',
    minHeight: 'calc(100vh - 100px)',
    margin: 'auto',
  },
  alertContainer: {
    margin: 'auto',
    maxWidth: '1680px',
  },
  alert: {
    display: 'inline-flex',
    margin: '0 0 20px',
    padding: '19px 16px 19px 21px',
  },
}), { name: 'Dashboard' });

export default useStyles;
