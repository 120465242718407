import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    image: (props: any) => ({
      height: '168px',
      width: '168px',
      backgroundColor: 'rgba(225, 225, 225, 0.2)',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '23px',
      '& > img': {
        height: props.isCredit ? 'none' : '90px',
        width: props.isCredit ? 'none' : '90px',
        padding: props.isCredit ? '43px 30px 45px 48px' : '4px 4px 4px 4px',
      },
    }),
    parent: {
      position: 'relative',
    },
    text: {
      fontFamily: '\'Source Sans Pro\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
      fontWeight: 600,
      letterSpacing: '1.3px',
      fontSize: '11px',
      textTransform: 'uppercase',
    },
    multiLine: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > p': {
        fontSize: '14px',
        margin: 0,
        '&:first-of-type': {
          fontWeight: 100,
          fontSize: '21px',
          marginBottom: '11px',
        },
      },
    },
  }), { name: 'EmptyTable' },
);

export default useStyles;
