import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { BeneficiaryDto, CountryBankFields } from '@alpha/bene-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';

import DrawerBackdropLoader from '../../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../../hooks/useAuthorization';
import useLog from '../../../../hooks/useLog';
import { UserRole } from '../../../../models/user';
import BeneficiariesService from '../../../../services/Beneficiaries/beneficiaries.service';
import { TStore } from '../../../../store';
import { TAuthyState } from '../../../../store/authy/reducer';
import useCreateBeneficiary from '../useCreateBeneficiary';

import Footer from './Footer/Index';
import PaymentMethods from './PaymentMethods';
import PersonalDetails from './PersonalDetails';
import useStyles from './styles';
import PayeeConfirmationModal from '../PayeeConfirmationModal/PayeeConfirmationModal';
import RejectModal from 'domain/Transactions/BatchSummary/FooterContainer/ApproveRejectFooterContainer/RejectModal/RejectModal';

interface IProps {
  selectedBeneficiary?: BeneficiaryDto;
  firstPartyFlow: boolean;
  handleDrawerClose: () => void;
  updateDrawer?: boolean;
  refetch?: any,
}

const Form: React.FC<IProps> = ({
  selectedBeneficiary, handleDrawerClose, firstPartyFlow, updateDrawer, refetch,
}: IProps) => {
  const styles = useStyles();

  const { logError } = useLog();
  const [sendEmailNotification, setSendEmailNotification] = useState<boolean>(true);
  const [validatedBeneId, setValidatedBeneId] = useState<string>('');
  const { authorized: beneApproverOwn } = useAuthorization([[UserRole.BENEFICAIRY_APPROVER_OWN]]);
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<CountryBankFields>();

  const sb = useAlphaSnackbar();
  const {
    form,
    countries,
    currencyList,
    loading,
    error,
    requiresAddress,
    requiresCorrespondentBankDetails,
    setRequiresCorrespondentBankDetails,
    formValidation,
    setRequiresAddress,
    setFormValidation,
    setModalOpen,
    modalOpen,
    setCOPModalOpen,
    COPmodalOpen,
    validatedBene,
    handleApproveAFCreateBeneficiary,
  } = useCreateBeneficiary(selectedBeneficiary, handleDrawerClose, setValidatedBeneId, updateDrawer, refetch);

  useEffect(() => {
    setSendEmailNotification(!beneApproverOwn);
  }, [beneApproverOwn]);

  useEffect(() => {
    if (
      authyState.type?.type === 'BENEFICIARY'
      && authyState.status === 'SUCCESS'
      && !form.isSubmitting
      && sendEmailNotification
    ) {
      onSubmissionSuccess();
    }
    if (authyState.status === 'SUCCESS') {
      handleDrawerClose();
    }
  }, [authyState.status, authyState.type]);

  const onSubmissionSuccess = async () => {
    if (form.values) {
      try {
        const approvers: Approver[] = await BeneficiariesService.getBeneficiaryApprovers(
          validatedBeneId,
        );
        const approverIds: string[] = approvers.flatMap((approver: Approver) => (
          approver.eligible ? approver.userId : []));
        BeneficiariesService.postApproverEmails(validatedBeneId, { approverIds });
      } catch (e) {
        sb.trigger(t('there_was_an_error_sending_your_email_notification'), 'error');
        logError({ action: 'Error sending email notification', error: e });
      }
    }
  };

  return (
    <form onSubmit={form.handleSubmit} className={styles.formContainer}>
      <BaseDrawer.Body>
        <div className={styles.fieldsContainer}>
          <PersonalDetails
            form={form}
            countries={countries}
            currencyList={currencyList}
            requiresAddress={requiresAddress}
            selectedBeneficiary={selectedBeneficiary}
            isFirstPartyFlow={firstPartyFlow}
            disabled={updateDrawer}
          />
          <PaymentMethods
            form={form}
            formValidation={formValidation}
            setFormValidation={setFormValidation}
            requiresAddress={requiresAddress}
            selectedBeneficiary={selectedBeneficiary}
            requiresCorrespondentBankDetails={requiresCorrespondentBankDetails}
            setRequiresAddress={setRequiresAddress}
            setRequiresCorrespondentBankDetails={setRequiresCorrespondentBankDetails}
            sendEmailNotification={sendEmailNotification}
            setSendEmailNotification={setSendEmailNotification}
            showEmailCheckbox={!beneApproverOwn}
            disabled={updateDrawer}
            setAvailablePaymentMethods={setAvailablePaymentMethods}
            availablePaymentMethods={availablePaymentMethods}
          />
        </div>
      </BaseDrawer.Body>
      <Footer
        isFormValid={Boolean(form.isValid && (
          form.values.iban
          || (form.values.accountNumber && form.values.swift)
          || (form.values.accountNumber && form.values.nationalBankCode)
          || (availablePaymentMethods?.local?.length === 1 && form.values.accountNumber)
        ) && Boolean(!requiresCorrespondentBankDetails || (
          form.values.correspondentAccountNumber
          || form.values.correspondentSwift
          || form.values.furtherToAccountNumber
          || form.values.furtherToSwift
        )))}
        handleDrawerClose={handleDrawerClose}
        updateDrawer={updateDrawer}
        addressLine1={form.values.addressLine1}
        beneficiary={form.values}
      />
      <DrawerBackdropLoader text={t('validating_your_beneficiary')} display={loading} />

      {/* Modals */}
      <PayeeConfirmationModal
        open={COPmodalOpen}
        formValues={form.values}
        validatedBene={validatedBene}
        handleActionButton={handleApproveAFCreateBeneficiary}
        handleClose={() => {
          if (COPmodalOpen) { setCOPModalOpen(false) };
          form.setFieldValue('userIgnoresApplyFinancialErrors', false);
        }}
      />
      <RejectModal
        batchName={t('validation_failed')}
        subtitle1={t('the_details_you_have_entered_have_failed_our_validation')}
        subtitle2={t(error)}
        subtitle3={t('by_choosing_continue')}
        actionButton={t('continue')}
        open={modalOpen}
        handleActionButton={handleApproveAFCreateBeneficiary}
        handleClose={() => {
          if (modalOpen) { setModalOpen(false) };
          form.setFieldValue('userIgnoresApplyFinancialErrors', false);
        }}
      />

    </form>
  );
};

export default memo(Form);
