import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& >.MuiBox-root': {
        border: 0,
        borderRadius: '5px',
        outline: 'none',
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: '24px 0',
    },
    header: {
      borderBottom: '1px solid #f7f7f7',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      right: 20,
      top: 0,
      color: '#c3c3c3',
    },
  }),
  { name: 'APModal' },
);

export default useStyles;
