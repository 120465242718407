import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    drawer: {
      padding: '20px',
      minHeight: 'calc(100% - 55px)',
      position: 'relative',
      '& .MuiDrawer-paper': {
        '& > button:first-of-type': {
          zIndex: 1,
        },
        '& > .MuiBox-root:first-of-type': {
          paddingTop: '60px',
        },
      },
      '& .MuiTextField-root > label': {
        color: '#212529',
        marginBottom: '20px',
        marginTop: '-20px',
      },
      '& label + .MuiInput-formControl': {
        marginTop: '30px',
      },
      '& h2': {
        fontSize: '22px',
      },
    },
  }),
  { name: 'IATDrawerContainer' },
);

export default useStyles;
