const generateResidentAddress = (
  addressLine1: string | undefined,
  city: string | undefined,
  countryCode: string | undefined): string => {
  const address = [addressLine1, city, countryCode]
    .filter((field) => field)
    .join(', ')
  if (!addressLine1 && !city && !countryCode) {
    return '';
  }
  return address
};

export default generateResidentAddress;
