import { groupBy } from 'lodash';
import React, { memo } from 'react';
import t from 'utils/translationHelper';

import { MenuItem, Select } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import { PaymentPurposeDto } from '@alpha/payments-dtos';
import { Item } from './Item/Item';
import { useStyles } from './PaymentPurposeDropdown.styles';
import RenderValue from './RenderValue/RenderValue';

interface IProps {
  data: PaymentPurposeDto[];
  selectValue?: string;
  selectName?: string;
  placeholderText?: string;
  testId?: string;
  handleChange?: (event: any) => void;
  handleBlur?: (event: any) => void;
}
const PaymentPurposeDropdown: React.FC<IProps> = (props: IProps) => {
  const {
    data,
    selectValue,
    selectName,
    placeholderText,
    testId,
    handleChange,
    handleBlur,
  } = props;
  const styles = useStyles();

  let dataGroupedByGroup = groupBy(
    data,
    (dt: PaymentPurposeDto) => dt.group ?? 'Others',
  );

  dataGroupedByGroup = Object.keys(dataGroupedByGroup).sort().reduce(
    (obj, key) => {
      obj[key] = dataGroupedByGroup[key];
      return obj;
    },
    {},
  );

  const dropdownItems = Object.keys(dataGroupedByGroup).map(
    (group: string) => {
      const elements: JSX.Element[] = [
        <Item.Sticky group={group} />,
      ];
      const dataRows = dataGroupedByGroup[group].map(
        (dropdownItem) => (
          <MenuItem
            key={dropdownItem.description}
            value={dropdownItem.description}
            style={{ padding: 0, backgroundColor: 'transparent' }}
          >
            <Item.Data item={dropdownItem} />
          </MenuItem>
        ),
      );
      return elements.concat(dataRows);
    },
  );

  const renderValue: Function | undefined = (
    values: string,
    _data: PaymentPurposeDto[],
  ) => {
    const selectedPaymentPurpose = _data.find(
      (dt) => dt.description === values,
    );
    if (!selectedPaymentPurpose) return undefined;
    return () => (
      <RenderValue selectedPaymentPurpose={selectedPaymentPurpose} />
    );
  };

  const showValueOrPlaceHolder = selectValue || 'placeholder';
  const renderedPlaceHolderText = placeholderText || t('select_payment_purpose');
  return (
    <div className={styles.dropdownContainer}>
      <Select
        onChange={handleChange}
        onBlur={handleBlur}
        value={showValueOrPlaceHolder}
        name={selectName}
        id={testId || 'dropdown'}
        renderValue={renderValue(showValueOrPlaceHolder, data)}
        disableUnderline
        fullWidth
        className={styles.dropdown}
        MenuProps={{
          className: styles.dropdownList,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {[
          <MenuItem value="placeholder">
            <Typography variant="subtitle1" className={styles.placeholder}>
              {renderedPlaceHolderText}
            </Typography>
          </MenuItem>,
          ...dropdownItems,
        ]}
      </Select>
    </div>
  );
};

export default memo(PaymentPurposeDropdown);
