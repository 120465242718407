import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    width: '600px',
    padding: 0,
  },
  modalHeader: {
    fontSize: '18px',
    fontFamily: typography.fontFamily,
    fontWeight: typography.fontWeightSemiBold,
    padding: '4px 0 20px 36px',
  },
  modalContent: {
    padding: '30px 36px',
    maxHeight: '600px',
    overflowY: 'auto',
  },
  footer: {
    padding: '27px 51px 10px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(112, 112, 112, .1)',
  },
  ccyDropdown: {
    backgroundColor: '#F7F7F7',
    height: '44px',
    width: '100%',
    borderRadius: '5px',
    marginTop: '5px',
    '& .MuiSelect-select.MuiSelect-select': {
      background: '#F7F7F7',
      paddingLeft: '16px',
    },
  },
  friendlyNameContainer: {
    marginTop: '20px',
    width: '100%',
    marginBottom: '20px',
  },
  inputContainer: {
    padding: '45px 56px',
  },
  ccySubtitle: {
    color: 'rgba(34, 34, 34, .4)',
    padding: '3px 0',
  },
  friendlyNameInput: {
    marginTop: '4px',
  },
}), { name: 'RequestCurrencyAccountModal' });

export default useStyles;
