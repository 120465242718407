/* eslint-disable max-len */
import PaymentContext from 'components/Molecules/Payments/Beneficiaries/AddPayment/paymentContext';
import CreateBeneficiary from 'domain/Beneficiaries/CreateBeneficiary';
import BeneficiaryDropdownContainer from 'domain/Transactions/ManualPayments/Body/PaymentType/ManualPayment/BeneficiaryDropdown/BeneficiaryDropdownContainer';
import { FormikProps } from 'formik';
import Authorization from 'hocs/Authorization';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import useSwitchAccount from 'hooks/useSwitchAccount';
import i18n from 'i18n/config';
import { UserRole } from 'models/user';
import moment, { Moment } from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import { formatNumber } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { DrawdownFundingMethod, TradeDto } from '@alpha/fx-dtos';
import { PaymentDateOptionsDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { MenuItem } from '@alpha/ui-lib/ui/external';
import { Select } from '@alpha/ui-lib/ui/Select';
import { SingleStat } from '@alpha/ui-lib/ui/StatSummary/SingleStat';
import { Status } from '@alpha/ui-lib/ui/Status';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputAndLabel from '../../../../Inputs/Beneficiary/InputAndLabel';
import FundingMethod from '../../CreateDrawdownDrawer/Drawdown/FundingMethod';
import { FirstPartyDrawdownType } from '../formData';

import { Alert } from '@alpha/ui-lib/ui/Alert';
import useStyles from './index.styles';

type Props = {
  trade?: TradeDto,
  drawdownForm: FormikProps<FirstPartyDrawdownType>,
  defaultSellCurrencyAccount: CurrencyAccountDto,
  buyCurrencyAccount: CurrencyAccountDto,
  padAllowed?: boolean,
  beneficiary: BeneficiaryDto,
  handleAmountChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleDropdownChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: ReactNode) => void
}

// eslint-disable-next-line max-lines-per-function
const Drawdown: React.FC<Props> = ({
  trade,
  drawdownForm,
  defaultSellCurrencyAccount,
  buyCurrencyAccount,
  padAllowed,
  beneficiary,
  handleAmountChange,
  handleDropdownChange,
}) => {
  const classes = useStyles();
  const sb = useAlphaSnackbar();
  const { logError } = useLog();
  const [insufficientFunds, setInsufficientFunds] = useState<boolean>(false);
  const [beneficiaryDrawerOpen, setBeneficiaryDrawerOpen] = useState<boolean>(false);
  const { isEMoneyDisabled } = useSwitchAccount();

  const availableDateQuery = useQuery('getPaymentDates', () => FXTradeService.getAvailableDrawdownDates(
    trade?.id || '',
  ), {
    enabled: false,
    onError: () => {
      sb.trigger(t('could_not_load_drawdown_available_date'), 'error');
      logError({ action: 'Error loading payment available date' });
    }
  });

  const generateMenuOptions = () => {
    if (trade) {
      return [
        <MenuItem value={trade.buyCurrencyCode}>{trade.buyCurrencyCode}</MenuItem>,
        <MenuItem value={trade.soldCurrencyCode}>{trade.soldCurrencyCode}</MenuItem>,
      ];
    }
    return <></>;
  };

  useEffect(() => {
    if (trade) {
      drawdownForm.setFieldValue('buyCurrencyCode', trade.buyCurrencyCode);
      drawdownForm.setFieldValue('sellCurrencyCode', trade.soldCurrencyCode);
      if (trade.defaultSSI) {
        drawdownForm.setFieldValue('beneficiary', beneficiary);
      }
    }
  }, []);

  const calculateDisableDates = (_date: Moment) => {
    if (availableDateQuery.data) {
      const dates = availableDateQuery.data;
      const formattedDate = _date.format('YYYY-MM-DD');
      const todayDate = moment().format('YYYY-MM-DD');
      return (
        moment(formattedDate).isBefore(todayDate)
        || moment(formattedDate).isAfter(dates.latestDate)
        || dates.holidays.some((holiday) => moment(holiday.date).format('YYYY-MM-DD') === formattedDate)
      );
    }
    return false;
  };

  const handleDateChange = (_date: Moment) => {
    if (_date) {
      drawdownForm.setFieldValue('valueDate', _date.format('YYYY-MM-DD'), false);
    }
  };

  const showFunding = (): void => {
    if (drawdownForm.values.fixCurrencyCode && drawdownForm.values.valueDate
      && drawdownForm.values.amount && !drawdownForm.errors.amount) {
      drawdownForm.setFieldValue('fundingMethod', DrawdownFundingMethod.WIRE_TRANSFER); // set to wire transfer.
    }
  };

  useEffect(() => {
    if (trade) {
      availableDateQuery.refetch();
    }
  }, [trade]);

  useEffect(() => {
    checkInsufficientAmount();
    showFunding();
  }, [drawdownForm.values.fixCurrencyCode, drawdownForm.values.amount, drawdownForm.values.valueDate]);

  const checkInsufficientAmount = () => {
    const amountValue = parseFloat(drawdownForm.values.amount.toString().replace(/,/g, ''));

    if (drawdownForm.values.fixCurrencyCode === drawdownForm.values.sellCurrencyCode) {
      if (defaultSellCurrencyAccount) {
        setInsufficientFunds(defaultSellCurrencyAccount.clearedBalance < amountValue);
      }
    } else if (defaultSellCurrencyAccount) {
      setInsufficientFunds(
        defaultSellCurrencyAccount.clearedBalance < (amountValue / trade?.rate!),
      );
    }
  };

  return (
    <>
      <div>
        <Typography className={classes.tradeDetails}>{t('trade_details')}</Typography>
        <div className={classes.statsContainer}>
          <SingleStat testId="trade-number-stat" statHeader={t('trade_number')} value={trade?.contractNumber || '-'} style={{ color: '#1E8777', fontWeight: '600' }} />
          <SingleStat testId="currency-pair-stat" statHeader={t('currency_pair')} value={`${trade?.soldCurrencyCode}/${trade?.buyCurrencyCode}` || '-'} />
          {trade?.sellBalance
            && (
              <SingleStat testId="sell-balance-stat" statHeader={t('sell_balance')} value={`${formatNumber(trade?.sellBalance, 2)} ${trade?.soldCurrencyCode}` || '-'} />
            )}
          {trade?.buyBalance
            && (<SingleStat testId="buy-balance-stat" statHeader={t('buy_balance')} value={`${formatNumber(trade?.buyBalance, 2)} ${trade?.buyCurrencyCode}` || '-'} />
            )}
        </div>
        <Typography className={classes.drawdownHeading}>{t('drawdown_details')}</Typography>
        <form className={classes.formContainer}>
          <div className={classes.ccyContainer}>
            <div>
              <Typography variant="subtitle1" className="label" style={{ marginBottom: '5px' }}>
                {t('currency')}
              </Typography>
              <Select
                data-testid="fixed-currency-select"
                onChange={handleDropdownChange}
                value={drawdownForm.values.fixCurrencyCode}
                disableUnderline
                className={classes.dropdown}
              >
                {[
                  generateMenuOptions(),
                ]}
              </Select>
            </div>
            <div style={{ flexGrow: 1 }}>
              <InputAndLabel
                label={t('amount')}
                name="amount"
                id="amount"
                type="amount"
                className="amount"
                testId="drawdown-input-amount"
                onChange={handleAmountChange}
                value={!drawdownForm.touched.amount && drawdownForm.values.amount === 0 ? '' : drawdownForm.values.amount}
                error={
                  drawdownForm.touched.amount
                  && Boolean(drawdownForm.errors.amount)
                }
                helperText={
                  drawdownForm.touched.amount
                  && drawdownForm.errors.amount
                }
              />
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <APDatePicker
              fullWidth
              label={t('value_date')}
              language={i18n.language}
              className="datePicker"
              onChange={handleDateChange}
              value={drawdownForm.values.valueDate}
              disabled={!availableDateQuery.data}
              placeholder={t('value_date')}
              emptyLabel={t('select_date')}
              shouldDisableDate={calculateDisableDates}
              autoOk
            />
          </div>
          <div>
            <Alert
              className={classes.alert}
              variant="info"
              text={t('value_date_adjustment')}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px',
            }}
            >
              <Typography variant="subtitle1">{t('beneficiary')}</Typography>
              <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]} />
            </div>
            {trade?.defaultSSI && !isEMoneyDisabled() ? (
              <>
                <div className={classes.defaultBeneficiaryContainer}>
                  <div><Typography variant="subtitle1">{beneficiary?.name}</Typography></div>
                  <div><Status variant="info">DEFAULT SSI</Status></div>
                </div>
              </>
            )
              : (
                <BeneficiaryDropdownContainer
                  currencyCode={buyCurrencyAccount?.currencyCode || ''}
                  beneficiary={beneficiary}
                  setFieldValue={drawdownForm.setFieldValue}
                />
              )}
            <div style={{ marginTop: '10px' }}>
              <InputAndLabel
                disabled={!drawdownForm.values.fixCurrencyCode}
                label={t('reference_(drawdown)')}
                name="reference"
                id="reference"
                testId="reference"
                onChange={drawdownForm.handleChange}
                onBlur={drawdownForm.handleBlur}
                value={drawdownForm.values.reference}
                error={
                  drawdownForm.touched.reference
                  && Boolean(drawdownForm.errors.reference)
                }
                helperText={
                  drawdownForm.touched.reference
                  && drawdownForm.errors.reference
                }
              />
            </div>
          </div>

          {trade?.defaultSSI && !isEMoneyDisabled() ? (
            <FundingMethod
              drawdownForm={drawdownForm}
              padAllowed={padAllowed}
              defaultAccountName={defaultSellCurrencyAccount?.friendlyName || defaultSellCurrencyAccount?.accountName || '-'}
              defaultAccountBalance={defaultSellCurrencyAccount?.clearedBalance}
              defaultAccountCurrencyCode={defaultSellCurrencyAccount?.currencyCode}
              insufficientFunds={insufficientFunds}
            />
          ) : ''}
        </form>
      </div>
      <div style={{
        display: 'flex', flexDirection: 'row', padding: '92px 0 42px 0', justifyContent: 'space-between',
      }}
      >
        <div style={{
          position: 'absolute', transform: 'translateY(-42px)', width: '100%', left: 0,
        }}
        >
          <BaseDrawer.LineBreak />
        </div>
        <ActionButton style={{ background: '#F7F7F7', color: '#212529' }} disabled>
          <FontAwesomeIcon icon={faAngleLeft as IconProp} />
          {' '}
          {t('prev')}
        </ActionButton>
        <ActionButton
          disabled={drawdownForm.touched && !drawdownForm.isValid}
          onClick={() => drawdownForm.handleSubmit()}
        >
          {t('next_IT_')}
          {' '}
          <FontAwesomeIcon icon={faAngleRight as IconProp} />
        </ActionButton>
      </div>
      <PaymentContext.Provider value={{
        currencyCode: buyCurrencyAccount?.currencyCode,
        currency: buyCurrencyAccount?.currency,
        ...drawdownForm,
      }}
      >
        <CreateBeneficiary
          open={beneficiaryDrawerOpen}
          header="Create Drawdown / New Beneficiary"
          handleDrawerClose={() => setBeneficiaryDrawerOpen(false)}
          firstPartyFlow={isEMoneyDisabled()}
        />
      </PaymentContext.Provider>
    </>
  );
};

export default Drawdown;
