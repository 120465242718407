import { useState } from 'react';

import { TCurrencyAccount, TCurrencyAccountDrawer, TCurrencyTab } from '../../../models/currencyAccounts';

const useBody = (selectedTabIndex: number, tabs: TCurrencyTab[]) => {
  const [drawer, setDrawer] = useState<TCurrencyAccountDrawer>();
  const [selectedAccount, setSelectedAccount] = useState<{
    tabs: TCurrencyTab,
    account: TCurrencyAccount
  }>();

  const handleOpenDrawer = (_drawer: TCurrencyAccountDrawer) => {
    switch (_drawer) {
      case 'IAT':
        setDrawer('IAT');
        return;
      case 'AccountDrawer':
        setDrawer('AccountDrawer');
        return;
      case 'PendingTransfers':
        setDrawer('PendingTransfers');
        return;
      case 'CreatePayment':
        setDrawer('CreatePayment');
        return;
      default:
        setDrawer(undefined);
    }
  };

  return {
    drawer,
    selectedAccount,
    handleAccountTableRowClick: (account: TCurrencyAccount) => {
      setSelectedAccount({ tabs: tabs[selectedTabIndex], account });
    },
    handleOpenDrawer,
  };
};

export default useBody;
