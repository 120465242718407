import { makeStyles } from '@alpha/ui-lib/theme';
import { emphasize } from '@alpha/ui-lib/ui/external';

const useStyles = makeStyles(
  ({ palette: { primary }, typography }) => ({
    loader: {
      display: 'flex',
      height: '100%',
      flexGrow: 1,
      flexDirection: 'column',
    },
    success: {
      backgroundColor: '#3C8C51',
      padding: '14px 16px 14px 16px',
    },
    info: {
      backgroundColor: '#1473E6',
      padding: '14px 16px 14px 16px',
    },
    error: {
      backgroundColor: '#D55E5E',
      padding: '14px 16px 14px 16px',
    },
    '@global': {
      'body, html': {
        fontSize: 13,
        lineHeight: 1.5,
        fontWeight: 300,
        height: '100%',
      },
      body: {
        fontFamily: '\'Source Sans Pro\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
        backgroundColor: '#fff',
      },
      '#root': {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
      },
      a: {
        fontWeight: typography.fontWeightRegular,
        color: emphasize(primary.main, 0.15),
        textDecoration: 'none',
      },
      '*': {
        boxSizing: 'border-box',
      },
      '.MuiAutocomplete-popper': {
        '& li': {
          padding: '8px 18px',
          '& > div > span:first-of-type': {
            height: '20px',
            width: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            overflow: 'hidden',
            '& img': {
              height: '100%',
              objectFit: 'cover',
            },
          },
        },
      },
    },
  }),
  { name: 'App' },
);

export default useStyles;
