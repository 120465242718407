import { AxiosResponse } from 'axios';

import { TFormAccount } from '../../domain/InterAccountTransfer/FormValues';
import {
  TCurrencyAccount,
  TCurrencyAccountOptionGroup,
  TCurrencyResponse,
} from '../../models/currencyAccounts';
import { TStatementData } from '../../models/statements';
import instance from '../Axios/instance';

export default class StatementsService {
  public static createStatementData = (
    debitingAccount: Omit<TFormAccount, 'reference'>,
    creditingAccount: TFormAccount,
    valueDate: string | undefined,
    fxRate: number | undefined,
    tradeId: string | undefined,
    tradeFriendlyId: string | undefined,
  ): TStatementData => ({
    debitFriendlyName: debitingAccount.currencyAccount!.friendlyName,
    creditFriendlyName: creditingAccount.currencyAccount!.friendlyName,
    reference: creditingAccount.reference || '-',
    creditAmount: creditingAccount.amount!,
    debitAmount: debitingAccount.amount!,
    debitReference: debitingAccount.currencyAccount?.reference,
    creditReference: creditingAccount.currencyAccount?.reference,
    debitIban: debitingAccount.currencyAccount?.iban,
    creditIban: creditingAccount.currencyAccount?.iban,
    debitType: debitingAccount.currencyAccount?.type,
    creditType: creditingAccount.currencyAccount?.type,
    debitCurrencyCode: debitingAccount.currencyAccount?.currencyCode!,
    creditCurrencyCode: creditingAccount.currencyAccount?.currencyCode!,
    arrivalDate: new Date().toISOString(),
    valueDate,
    fxRate,
    tradeId,
    tradeFriendlyId,
  });

  public static async getStatementAccounts(): Promise<TCurrencyResponse> {
    const response: AxiosResponse<TCurrencyResponse> = await instance.get(
      '/ca/currency-accounts',
    );

    return response.data;
  }

  public static groupResponse(
    data: TCurrencyResponse,
  ): (TCurrencyAccountOptionGroup | TCurrencyAccount)[] {
    if (!data) {
      return [];
    }

    const groups: { [key: string]: TCurrencyAccount[] } = {};

    const output = data.currencyAccounts.filter(
      (account) => account.currencyCode,
    );

    output.forEach((account) => {
      groups[account.currencyCode!] = [];
    });
    output.forEach((account) => {
      groups[account.currencyCode!].push(account);
    });

    const grouped = Object.keys(groups).map((currencyCode) => ({
      currencyCode,
      currencyAccounts: groups[currencyCode],
    }));

    let flattened: (TCurrencyAccountOptionGroup | TCurrencyAccount)[] = [];

    grouped.forEach((group) => {
      flattened.push({ type: 'category', currencyCode: group.currencyCode });
      flattened = flattened.concat(group.currencyAccounts);
    });

    return flattened;
  }
}
