import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  padTooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    '&.mui-tooltip:hover': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  tooltipButton: {
    justifyContent: 'left',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
  tooltipText: {
    marginTop: '10px',
    textTransform: 'none',
    fontSize: '13px',
    fontWeight: 200,
    color: '#00A09B',
    textDecoration: 'underline',
    textAlign: 'left',
  },
  approvalKey: {
    color: '#FFFFFF',
    opacity: '56%',
    paddingRight: '5px',
  },
  approvalDiv: {
    marginTop: '10px',
  },
  tableButton: {
    textTransform: 'none',
    fontSize: 14,
    padding: 0,
    fontWeight: 600,
    color: 'rgb(9, 131, 117)',
    textDecoration: 'none',
    textAlign: 'left',
    justifyContent: 'left',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
}), { name: 'PadColumnValue' });

export default useStyles;
