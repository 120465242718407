import React from 'react';
import clsx from 'clsx';

import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';

import { ISelectedAccount } from '../../AccountInfoDrawer';

import useStyles from './HeaderText.styles';

interface IProps {
  selectedAccount: ISelectedAccount | undefined;
}

const HeaderText: React.FC<IProps> = ({ selectedAccount }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.flag}>
        <Flag code={selectedAccount?.tabs.currencyCode} size="lg" />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography className={clsx(classes.title, classes.SemiBold)}>
          {selectedAccount?.account.friendlyName || selectedAccount?.account.reference || '-'}
        </Typography>
        <Typography className={clsx(classes.subtitle, classes.SemiBold)}>
          {selectedAccount?.account.currency || '-'}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeaderText;
