import React from 'react';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { StyledStatSummary, TStyledStatSummaryData } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary';
import t from 'utils/translationHelper';

import AlphaLogo from '../../../../../assets/alphaGreenLogo.svg';

import useStyles from './PaymentBatchFunding.styles';

interface IFundingOptionWireTransferProps {
  alphaBankDetails: TStyledStatSummaryData[];
  defaultCurrencyAccount: CurrencyAccountDto | undefined;
}

const FundingOptionWireTransfer: React.FC<IFundingOptionWireTransferProps> = (
  {
    alphaBankDetails,
    defaultCurrencyAccount,
  }: IFundingOptionWireTransferProps,
) => {
  const styles = useStyles();


  return (
    <Box className={styles.radioButtonsContainer}>
      <div className={styles.boxHeading}>
        <Typography className={styles.boxHeaderText}>
          {t('fund_via_wire_transfer')}
        </Typography>
      </div>
      <Typography className={styles.boxText}>
        {t('please_ensure_funds_are_remitted_to_alpha_bank')}
      </Typography>

      <Typography className={styles.boxText}>
        <img
          alt="Alpha Account"
          src={AlphaLogo}
          style={{ verticalAlign: 'top', marginRight: '8px' }}
        />
        {defaultCurrencyAccount?.friendlyName || defaultCurrencyAccount?.accountName || ''}
      </Typography>
      <StyledStatSummary data={alphaBankDetails} />
    </Box>
  );
};

export default FundingOptionWireTransfer;
