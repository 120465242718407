import React from 'react';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';

import { TProgramImage } from '../../../services/HedgingPrograms/hedgingPrograms.interfaces';
import ExportMenu from '../ExportMenu/ExportMenu';

import useStyles from './HedgingProgramsCtas.styles';

export interface IProps {
  componentTestId?: string;
  programId?: string;
  programName?: string;
  addExternalTradesAllowed: boolean,
  handleAddTrade: () => void;
  programImages: TProgramImage[];
}

const HedgingProgramsCtas: React.FC<IProps> = (props: IProps) => {
  const {
    componentTestId,
    programId,
    programName,
    addExternalTradesAllowed,
    handleAddTrade,
    programImages,
  } = props;


  const classes = useStyles();

  return (
    <div data-testid={componentTestId} className={classes.rightAlignedCtas}>
      {programName && programId && (
        <>
          <ExportMenu
            programId={programId}
            programImages={programImages}
          />
          {addExternalTradesAllowed && (
            <ActionButton
              style={{ borderRadius: '5px', marginLeft: '8px' }}
              onClick={handleAddTrade}
            >
              {t('add_trade_to')}
              {' '}
              {programName}
            </ActionButton>
          )}
        </>
      )}
    </div>
  );
};

export default HedgingProgramsCtas;
