import React from 'react';

import { Box } from '@alpha/ui-lib/ui/external';
import { TStyledGenericTableSortOrder } from '@alpha/ui-lib/ui/table';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table/StyledGenericTable';

import { ITableColumn, ITableData } from '../../services/DatabaseServices/marker.interface';

import useStyles from './Table.styles';

export type TTableProps = {
  columns: ITableColumn[];
  data: ITableData[];
  clickable?: boolean;
  handleTableRowClick?: (e: any) => void;
  handleTableSortClick?: ((accessor: string,
  orderBy: TStyledGenericTableSortOrder) => any) | undefined;
  shortRows?: boolean;
  dataTestId?: string;
  activeColumn?: string;
  sortOrder?: 'asc' | 'desc';
  multiSelectable?: boolean;
  totalRowNumber?: number;
  keyColumn?: string;
  canOnlySelectSameTypeBaseOnColumn?: string | undefined;
  checkboxDisabledMessage?: string;
  selectedIds?: string[];
  setSelectedIds?: React.Dispatch<string[]>;
  canOnlySelectSameTypeBaseOnFunction?: (firstSelectedItem: any, item: any) => boolean;
  skip: number;
  showSelectAllCheckbox?: boolean;
  hideSelect?: boolean;
}

export const Table: React.FC<TTableProps> = (props: TTableProps) => {
  const {
    columns,
    data,
    shortRows,
    clickable,
    dataTestId,
    activeColumn,
    sortOrder,
    handleTableRowClick,
    handleTableSortClick,
    multiSelectable = false,
    totalRowNumber,
    keyColumn,
    canOnlySelectSameTypeBaseOnColumn,
    checkboxDisabledMessage,
    selectedIds,
    setSelectedIds,
    canOnlySelectSameTypeBaseOnFunction,
    showSelectAllCheckbox,
    hideSelect,
  } = props;
  const styling = useStyles({ shortRows });

  return (
    <Box className={styling.root}>
      <StyledGenericTable
        clickable={!!clickable}
        testId={dataTestId || ''}
        columns={columns}
        data={data}
        handleTableRowClick={handleTableRowClick ? handleTableRowClick! : () => { }}
        handleTableSorting={handleTableSortClick as any}
        activeColumn={activeColumn}
        sortOrder={sortOrder}
        multiSelectable={multiSelectable}
        totalRowNumber={totalRowNumber}
        keyColumn={keyColumn}
        canOnlySelectSameTypeBaseOnColumn={canOnlySelectSameTypeBaseOnColumn}
        canOnlySelectSameTypeBaseOnFunction={canOnlySelectSameTypeBaseOnFunction}
        checkboxDisabledMessage={checkboxDisabledMessage}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        showSelectAllCheckbox={showSelectAllCheckbox}
        hideSelect={hideSelect}
      />
    </Box>
  );
};

export default Table;
