import React, { useState } from 'react';

import { AccountDto } from '@alpha/auth-dtos';
import { User } from '@alpha/profile-dtos';
import { Box } from '@alpha/ui-lib/ui/external';

import useChangePassword from '../../../hooks/useChangePassword';

import ChangePassword from './ChangePassword/ChangePassword';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import PersonalNotificationSettings from './PersonalNotificationSettings/PersonalNotificationSettings';
import PersonalPortalPermissions from './PersonalPortalPermissions/PersonalPortalPermissions';
import useStyles from './PersonalSettings.styles';

interface IProps {
  user: User | undefined,
  loading?: boolean,
  selectedAccount?: AccountDto;
}

const PersonalSettings: React.FC<IProps> = ({
  user,
  loading,
  selectedAccount,
}:IProps) => {
  const classes = useStyles();

  const [expandPasswordWidget, setExpandPasswordWidget] = useState<boolean>(false);
  const { formik, changePasswordLoading } = useChangePassword();

  return (
    <Box display="flex" flexDirection="column" gridGap="12px">
      <Box display="flex" gridGap="20px" justifyContent="start" overflow="auto">
        <Box className={classes.widget} width="35%" minWidth="300px">
          <PersonalInfo user={user} loading={loading} selectedAccount={selectedAccount} />
        </Box>
        <Box
          className={classes.widget}
          width={expandPasswordWidget ? '64%' : '27%'}
          minWidth={expandPasswordWidget ? '800px' : '500px'}
        >
          <ChangePassword
            loading={changePasswordLoading}
            formik={formik}
            expandPasswordWidget={expandPasswordWidget}
            setExpandPasswordWidget={setExpandPasswordWidget}
          />
        </Box>
      </Box>
      <Box className={classes.widget}>
        <PersonalPortalPermissions />
      </Box>
      <Box className={classes.widget}>
        <PersonalNotificationSettings canEdit />
      </Box>
    </Box>
  );
};

export default PersonalSettings;
