import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
    },
  }),
  { name: 'DropDownWrapper' },
);

export default useStyles;
