import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    drawer: {
      padding: '20px',
      minHeight: 'calc(100% - 55px)',
      position: 'relative',
      '& .MuiDrawer-paper': {
        '& > button:first-of-type': {
          zIndex: 1,
        },
        '& > .MuiBox-root:first-of-type': {
          paddingTop: '60px',
        },
      },
      '& .MuiTextField-root > label': {
        color: '#212529',
        marginBottom: '20px',
        marginTop: '-20px',
      },
      '& label + .MuiInput-formControl': {
        marginTop: '30px',
      },
      '& h2': {
        fontSize: '22px',
      },
    },
    drawerBody: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      gap: '24px',
      paddingTop: '0px',
      fontWeight: 900,
      minHeight: 'calc(100vh - 310px)',
      '& form': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      },
      '& .MuiFormHelperText-root': {
        fontSize: '14px',
        fontWeight: typography.fontWeightMedium,
        bottom: '-25px',
      },
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: '30px 0 20px 0',
      justifyContent: 'space-between',
    },
    cancelBtn: {
      backgroundColor: '#FAFAFA',
      borderRadius: '4px',
      color: '#212529',
    },
    red: {
      color: '#b95656 !important',
      margin: '0 0 10px',
      fontSize: '14px',
      fontWeight: typography.fontWeightMedium,
    },
    balanceAfter: {
      margin: '0 0 20px 0',
      width: '200px',
      maxWidth: '250px',
      '& span': {
        marginLeft: '4px',
      },
      '& .MuiTypography-subtitle2': {
        textAlign: 'left',
      },
    },
    lightWeight: {
      fontWeight: typography.fontWeightLight,
    },
    title: {
      fontSize: 22,
      letterSpacing: -0.5,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: '60px 55px 30px 55px',
    },
    currencyToggleContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    amountInput: {
      display: 'flex',
      maxWidth: '300px',
      fontSize: '40px',
      fontWeight: typography.fontWeightLight,
    },
    ccyContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      paddingRight: '10px',
    },
    dropdown: {
      '& .MuiSelect-root': {
        backgroundColor: '#f7f7f7',
        height: '44px',
        width: '73px',
        borderRadius: '5px',
        padding: '0 16px',
        paddingRight: '20px',
        marginBottom: '6px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '& .MuiSvgIcon-root': {
        right: '7px',
      },
    },
    alertContainer: {
      padding: '25px 0 0',
      '& svg': {
        color: '#3B82F6!important',
      },
      '& p': {
        color: '#3B82F6!important',
      },
    },
    fxInfo: {
      fontWeight: typography.fontWeightLight,
      margin: 0,
    },
  }),
  { name: 'IATDrawerForm' },
);

export default useStyles;
