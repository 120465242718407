import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    width: '808px',
    padding: 0,
  },
  modalHeader: {
    fontSize: '18px',
    fontFamily: typography.fontFamily,
    fontWeight: typography.fontWeightSemiBold,
    padding: '4px 0 20px 36px',
  },
  modalContent: {
    padding: '30px 36px',
    maxHeight: '600px',
    overflowY: 'auto',
  },
  footer: {
    padding: '20px 35px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  unreadMarker: {
    width: '8px',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: '#1473E6',
    marginTop: '15px',
    marginRight: '7px',
  },
  notificationDate: {
    fontSize: '12px',
    color: '#9A9A9A',
  },
  notificationTitle: {
    fontSize: '18px',
    color: '#2C2C2C',
    marginTop: '5px',
    fontWeight: typography.fontWeightMedium,
  },
  notificationSubtitle: {
    fontSize: '14px',
    color: '#8A8A8A',
    marginTop: '10px',
    marginBottom: '20px',
    fontWeight: typography.fontWeightRegular,
    whiteSpace: 'pre-wrap',
  },
  noticationImg: {
    width: '720px',
    height: '340px',
    margin: 'auto',
    objectFit: 'contain',
    backgroundColor: '#fcfcfc',
  },
  videoPlayer: {
    maxHeight: '360px',
    backgroundColor: '#fcfcfc',
  },
  stepper: {
    margin: 'auto',
    marginTop: '10px',
    backgroundColor: 'transparent',
    maxWidth: '300px',
  },
}), { name: 'NotificationsModal' });

export default useStyles;
