import React from 'react';
import clsx from 'clsx';

import * as MaterialUi from '@alpha/ui-lib/ui/external';

import AuthyResponse from '../../../../components/AuthyComponent/AuthyResponse/AuthyResponse';
import BackdropLoader from '../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import { TAuthyStatus } from '../../../../store/authy/reducer';
import { useStyles } from '../Modal.styles';

import Form from './Form/Form';

interface IBodyProps {
  status: TAuthyStatus | undefined;
  authyPhoneNumber: string | undefined;
  handleComplete: (code: number) => void;
}
const Body: React.FC<IBodyProps> = ({
  status,
  authyPhoneNumber,
  handleComplete,
}: IBodyProps) => {
  const classes = useStyles();

  if (status === 'INITIATED' || status === 'CODE SUBMITTED') {
    return (
      <>
        {authyPhoneNumber && status !== 'CODE SUBMITTED' ? (
          <MaterialUi.Box className={clsx(classes.wrapper, classes.noOutline)}>
            <Form
              handleComplete={handleComplete}
              phoneNumber={authyPhoneNumber}
            />
          </MaterialUi.Box>
        ) : (
          <BackdropLoader
            testId="backdrop-loader"
            thickness={5}
            size={60}
            className={classes.noOutline}
          />
        )}
      </>
    );
  }
  if (status) {
    return <AuthyResponse status={status} />;
  }

  return null;
};

export default Body;
