import React from 'react';
import useAuthorization from 'hooks/useAuthorization';
import { UserRole } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';
import { initiateBeneficiary } from 'store/authy/actions';
import { shouldShowApprovalButtons } from 'utils/payments/shouldShowApprovalButtons';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Button } from '@alpha/ui-lib/ui/external';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';

import useStyles from './BeneActionBar.styles';

interface IBeneActionBar {
  selectedBenes: BeneficiaryDto[] | undefined;
  handleClearButtonClick: () => void;
  handleOpenApprovalDrawer: () => void;
  show?: boolean;
}

const BeneActionBar: React.FC<IBeneActionBar> = (props: IBeneActionBar) => {
  const {
    selectedBenes,
    handleClearButtonClick,
    handleOpenApprovalDrawer,
    show = false,
  } = props;

  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);
  const canApproveOthers = useAuthorization([[UserRole.BENEFICIARY_APPROVER]]);
  const canApproveOwn = useAuthorization([[UserRole.BENEFICAIRY_APPROVER_OWN]]);

  const classes = useStyles();
  let ids;

  const rootClasses = [classes.root, show ? null : 'hide']?.join(' ');
  if (selectedBenes?.length > 0) {
    ids = selectedBenes?.map((b) => b.id);
  }

  if (!selectedBenes?.length || !userId) return <></>;

  const showApproveButton = shouldShowApprovalButtons(
    canApproveOthers.authorized,
    canApproveOwn.authorized,
    selectedBenes,
    userId,
    true,
  );

  return (
    <div className={rootClasses}>
      <div className={classes.leftContent}>
        <span className={classes.selectedPayments}>
          {selectedBenes ? selectedBenes.length : 0}
          {' '}
          {selectedBenes && selectedBenes.length > 1 ? t('beneficiaries') : t('beneficiary')}
          {' '}
          {t('selected')}
        </span>
        <StyledDivider vertical />
        <Button
          disableRipple
          className={classes.clearButton}
          onClick={handleClearButtonClick}
        >
          {t('clear_selection')}
        </Button>
      </div>

      <div className={classes.rightContent}>
        {showApproveButton ? (
          <>
            {' '}
            <ActionButton
              size="medium"
              testId="approve-btn"
              className={classes.approveButton}
              onClick={handleOpenApprovalDrawer}
            >
              {t('approve_beneficiaries')}
            </ActionButton>
          </>
        ) : <p>{t('no_actions_available_for_the_selected_beneficiaries')}</p>}
      </div>

    </div>
  );
};
export default BeneActionBar;
