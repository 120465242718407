import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    tableWrapper: {
      paddingBottom: '30px',
      '& .MuiTableCell-root': {
        'padding-right': '30px !important',
      },
      '@media (max-width: 1200px)': {
        '& .MuiTableCell-root': {
          'padding-right': '20px !important',
        },
        '& th.MuiTableHead-root, & td.MuiTableCell-root': {
          fontSize: '12px',
          '& span': {
            fontSize: '11px',
          },
        },
      },
      '& td': {
        padding: '10px 0px !important',
      },
      '& th': {
        paddingTop: '20px !important',
      },
    },
    tableLink: {
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    amount: {
      fontWeight: 600,
    },
    status: {
      minWidth: '130px',
      textAlign: 'center',
    },
  }),
  { name: 'MarketOrderTable' },
);

export default useStyles;
