import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'utils/translationHelper';

import { PaymentBatchStatus } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import routes from '../../../routes.path';
import browserHistory from '../../../services/history/browserHistory';
import { TStore } from '../../../store';
import { TPaymentsStore } from '../../../store/payments/payments.reducer';

import UploadFile from './UploadFile/UploadFile';
import UploadHistory from './UploadHistory/UploadHistory';
import UploadProgress from './UploadProgress/UploadProgress';
import useStyles from './index.styles';

const PaymentFileUpload: React.FC = () => {
  const styles = useStyles();
  const paymentsUploadState = useSelector<TStore, TPaymentsStore>((state) => state.payments);

  const [showGuide, setShowGuide] = useState<boolean>(true);

  const shouldShowLoading = () => paymentsUploadState.batchDetails?.status && !(
    paymentsUploadState.batchDetails?.status === PaymentBatchStatus.VALIDATED
    && paymentsUploadState.batchDetails
    && paymentsUploadState.batchDetails.processedRecords!
    === paymentsUploadState.batchDetails.noOfRecords!
  );

  useEffect(() => {
    if (paymentsUploadState.batchDetails?.status === PaymentBatchStatus.VALIDATED
      && paymentsUploadState.batchDetails.processedRecords!
      === paymentsUploadState.batchDetails.noOfRecords!) {
      const { batchId } = paymentsUploadState.batchDetails;
      browserHistory.push(`/app/transactions/upload-summary/${batchId}`);
    }
  }, [paymentsUploadState.batchDetails]);

  return (
    <APMainLayout
      sticky
      title={(
        <div>
          <ActionButton
            style={{
              background: '#F7F7F7', color: '#212529', marginLeft: '25px', marginBottom: '10px',
            }}
            startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
            testId="trade-back-btn"
            onClick={() => browserHistory.push(routes.transactions.base)}
          >
            {t('transactions')}
          </ActionButton>
          <Typography className={styles.paymentUploadTitle}>{t('payment_upload')}</Typography>
        </div>
      )}
    >
      <Box className={styles.root}>
        {shouldShowLoading()
          ? (
            <Box className={styles.uploadProgressContainer}>
              <UploadProgress
                store={paymentsUploadState}
              />
            </Box>
          ) : (
            <Box className={styles.fileUploadContainer}>
              <UploadFile showGuide={showGuide} />
              <Box className={styles.closeGuideBox}>
                <Typography
                  className={styles.underlinedGreenText}
                  onClick={() => setShowGuide(!showGuide)}
                >
                  {showGuide ? t('close_guide_and_template_packs') : t('show_guide_template_packs')}
                </Typography>
              </Box>
            </Box>
          )}
        <Box className={styles.fileUploadContainer}>
          <UploadHistory />
        </Box>
      </Box>
    </APMainLayout>
  );
};

export default PaymentFileUpload;
