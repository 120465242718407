import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import t from 'utils/translationHelper';

import { DrawdownDto } from '@alpha/fx-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import useActionDropdown from '../../../../../../../hooks/useActionDropDown';
import useAuthorization from '../../../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../../../models/user';
import * as authyActions from '../../../../../../../store/authy/actions';
import { useStyles } from '../../../../../../Beneficiaries/Body/BeneficiaryActionDropdown/BeneficiaryActionDropDown.style';

interface IProps {
  drawdown: DrawdownDto;
  handleViewDrawdown?: (drawdown: DrawdownDto) => void;
  handleDrawdownDownload?: (drawdownId: string) => void;
  displayMultiSelect: boolean,
  setDisplayMultiSelect?: (val: boolean) => void,
}

const DrawdownActionDropdown: React.FC<IProps> = ({
  drawdown,
  handleViewDrawdown,
  handleDrawdownDownload,
  displayMultiSelect,
  setDisplayMultiSelect,
}: IProps) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const { authorized: authorisedPadApprover } = useAuthorization([[UserRole.PAD_APPROVER]]);

  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    setOpen,
  } = useActionDropdown();

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    if (setDisplayMultiSelect) { setDisplayMultiSelect(false); }
    setMenuCloseCount(0);
  };

  const generateMenuItems = () => {
    const items = [];
    items.push({
      content: (
        <Typography data-testid={`view-drawdown-item-${drawdown.id}`}>
          {t('view_drawdown')}
        </Typography>
      ),
      icon: displayIcon('View'),
      onClick: () => handleViewDrawdown && handleViewDrawdown(drawdown),
      underline: true,
    },
    {
      content: (
        <Typography data-testid="">
          {t('download_drawdown')}
        </Typography>
      ),
      icon: displayIcon('Download'),
      onClick: () => handleDrawdownDownload && handleDrawdownDownload(drawdown.id),
    });
    if (drawdown.padEligible && !drawdown.padApproved && authorisedPadApprover) {
      items.splice(1, 0, {
        content: (
          <Typography data-testid="">
            {t('pad_approve')}
          </Typography>
        ),
        icon: displayIcon('Pad'),
        onClick: () => {
          dispatch(
            authyActions.initiateDrawdownPADApprove({
              type: 'DRAWDOWN_PAD_APPROVE',
              drawdownId: drawdown.id,
              approverOwn: false, // PAD approval does not care
              paymentIds: [], // PAD approval does not care
            }),
          );
        },
        underline: true,
      });
    }
    setMenuItems(items);
  };

  useEffect(() => {
    generateMenuItems();
  }, [drawdown, authorisedPadApprover]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box data-testid="action-drawdown-dropdown" className={classes.wrapper}>
        <APMenu
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          disableHoverEffect={displayMultiSelect}
          onClose={handleClose}
          buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
          buttonTitle=""
          icon={faEllipsisH as IconDefinition}
          actions
        >
          {
            menuItems.map((item) => <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>)
          }
        </APMenu>
      </Box>
    </ClickAwayListener>
  );
};

export default DrawdownActionDropdown;
