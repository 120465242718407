import React from 'react';

import { makeStyles } from '@alpha/ui-lib/theme';
import { Input, TInputProps } from '@alpha/ui-lib/ui/Input';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  inputWrapper: {
    position: 'relative',
    '& .searchIcon': {
      position: 'absolute',
      top: '50%',
      transform: 'translate(-34px, -50%)',
      color: '#1E8777',
      fontSize: '18px',
    },
    display: 'inline-block',
    '& > h6': {
      fontWeight: 'bold',
    },
  },
}), { name: 'Search' });

type TProps = TInputProps;

const SearchInput: React.FC<TProps> = ({ ...props }: TProps) => {
  const styles = useStyles();
  return (
    <div className={styles.inputWrapper}>
      <Input
        fullWidth
        variant="filled"
        {...props}
      />
      <FontAwesomeIcon icon={faSearch as IconProp} className="searchIcon" />
    </div>
  );
};

export default SearchInput;
