import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '32px',
    padding: '35px 53px 35px 50px',
  },
}), { name: 'CreateBeneficiaryDrawer' });

export default useStyles;
