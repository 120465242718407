import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    drawerBody: {
      position: 'relative',
      height: '100%',
      '& .MuiTypography-root': {
        position: 'relative',
        height: '100%',
      },
    },
  }),
  { name: 'AccountsTable' },
);

export default useStyles;
