import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserAcknowledgmentRequest, UserAcknowledgmentType } from '@alpha/auth-dtos';
import { ExpiredPayment, PaymentDto, PaymentsApprovalResponse } from '@alpha/payments-dtos';

import t from 'utils/translationHelper';
import { PaymentEvents } from '../domain/Transactions/Dashboard/PaymentTableContainer/usePaymentTable';
import AuthService from '../services/Auth/auth.service';
import PaymentsService from '../services/Payments/payments.service';
import { TStore } from '../store';
import { initiatePayment } from '../store/authy/actions';
import { TAuthyState } from '../store/authy/reducer';
import { formatCurrency } from '../utils/currency.helpers';

import i18n from 'i18n/config';
import useAlphaSnackbar from './useAlphaSnackbar';
import useLog from './useLog';

const usePaymentApproval = (
  paymentsToApprove: PaymentDto[],
  onClose: () => void,
) => {

  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();
  const { logEvent, logError } = useLog();
  const [loading, setLoading] = useState<boolean>(false);
  const [failedPayments, setFailedPayments] = useState<PaymentDto[]>([]);
  const [expiredPayments, setExpiredPayments] = useState<ExpiredPayment[]>([]);

  const authy = useSelector<TStore, TAuthyState>((store) => store.authy);
  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);
  const nonFinalApprovers = paymentsToApprove.filter(
    (sp) => (sp.approvalInfo.approvalsRequired - sp.approvalInfo.approvals.length) > 1,
  );
  const finalApprovers = paymentsToApprove.filter(
    (sp) => (sp.approvalInfo.approvalsRequired - sp.approvalInfo.approvals.length) === 1,
  );

  const onDrawerClose = () => {
    setLoading(false);
    onClose();
  };

  const approveOwnPayment = async () => {
    try {
      setLoading(true);
      const response = await PaymentsService.approvePayment({
        paymentIds: paymentsToApprove.map((sp) => sp.id),
        dynamicLinkingId: 'empty',
        totp: '123456',
        softToken: true,
      });
      const erroredPaymentIds: string[] = [
        ...(response.failedPaymentIds || []),
        ...(response.unapprovablePayments.map((up) => up.paymentId!) || []),
      ];
      const erroredPayments = paymentsToApprove.filter((sp) => erroredPaymentIds?.includes(sp.id));
      setFailedPayments(erroredPayments);
      if (erroredPayments.length === 0) onDrawerClose();

      const refreshEvent = new Event(PaymentEvents.RefreshPaymentTable);
      window.dispatchEvent(refreshEvent);
    } catch (error) {
      sb.trigger(t('something_went_wrong_approving_your_payment'));
      logError({ action: 'Error approving payment', error });
    }
  };

  const verifyPaymentsValueDate = async (payments: PaymentDto[]): Promise<boolean> => {
    const paymentIds = payments.map((sp) => sp.id);
    const {
      expiredPayments: expiredPaymentsFromResponse,
    } = await PaymentsService.verifyPaymentsValueDate(paymentIds);

    if (expiredPaymentsFromResponse && expiredPaymentsFromResponse.length > 0) {
      setExpiredPayments(expiredPaymentsFromResponse);
      return false;
    }
    return true;
  };

  const submitApproval = async (payments: PaymentDto[]): Promise<void> => {
    const requiresMfa = payments.find((sp) => sp.submittedById !== userId);
    if (!requiresMfa) {
      await approveOwnPayment();
    } else {
      dispatch(initiatePayment({
        paymentIds: paymentsToApprove.map((sp) => sp.id),
        type: 'PAYMENT_APPROVE',
      }));
    }
  };

  const handleApprovals = async (): Promise<void> => {
    setLoading(true);
    const notRequiredAcknowledgement = await verifyPaymentsValueDate(paymentsToApprove);

    if (notRequiredAcknowledgement) {
      await submitApproval(paymentsToApprove);
    }
    setLoading(false);
  };

  const handleValueDateChangeAcknowlegement = async () => {
    const acknowledgementRequest: UserAcknowledgmentRequest = {
      itemId: expiredPayments[0].id,
      type: UserAcknowledgmentType.TRADE,
      content: {
        payments: paymentsToApprove,
        expiredPayments,
        message: 'Payment value date has changed.',
      },
    };
    AuthService.postUserAcknowlegement(acknowledgementRequest);

    setExpiredPayments([]);
    await submitApproval(paymentsToApprove);
  };

  const handleInvalidApprovalAcknowlegement = () => {
    setFailedPayments([]);
    onDrawerClose();
  };

  const totalAmountToApprove = paymentsToApprove
    .map((p) => p.amount)
    .reduce((sum, current) => sum + current, 0);

  const approvalData = [
    {
      key: t('approved_payments'),
      value: `${finalApprovers.length}` || '0',
    },
    {
      key: t('total_amount_to_be_approved'),
      value: `${formatCurrency(paymentsToApprove[0]?.currency, totalAmountToApprove) || '0'}`,
    },
    {
      key: t('requiring_further_approval'),
      value: `${nonFinalApprovers.length}` || '0',
    },
  ];

  useEffect(() => {
    if (authy.status === 'SUCCESS' && authy.type?.type === 'PAYMENT_APPROVE') {
      onClose();
      const extraInfo = (authy.extraInfo as PaymentsApprovalResponse | undefined);
      const erroredPaymentIds: string[] = [
        ...(extraInfo?.failedPaymentIds || []),
        ...(extraInfo?.unapprovablePayments?.map((up) => up.paymentId!) || []),
      ];

      const erroredPayments = paymentsToApprove.filter((sp) => erroredPaymentIds.includes(sp.id));
      if (!erroredPayments.length) {
        sb.trigger(t('your_payments_were_successfully_approved'), 'success');
        logEvent({ action: 'Succefully approved payments' });
      }
      if (erroredPayments.length) {
        sb.trigger(`${i18n.language === 'it' || i18n.language === 'fr'
          ? (`${t('payments_failed_to_approve1')} ${erroredPayments.length}/${paymentsToApprove.length} ${t('payments_failed_to_approve2')}`)
          : (`${erroredPayments.length}/${paymentsToApprove.length} ${t('payments_failed_to_approve')}`)}`, 'warning');
        logEvent({
          action: 'Partly approved payments',
          detail: {
            failedPaymentNumber: erroredPayments.length,
          },
        });
      }
      setTimeout(() => {
        setFailedPayments(erroredPayments);
      }, 1500);
    }
  }, [authy.status]);

  return {
    userId,
    nonFinalApprovers,
    finalApprovers,
    loading,
    failedPayments,
    expiredPayments,
    approvalData,
    setFailedPayments,
    setExpiredPayments,
    setLoading,
    handleApprovals,
    handleValueDateChangeAcknowlegement,
    handleInvalidApprovalAcknowlegement,
    onDrawerClose,
  };
};

export default usePaymentApproval;
