import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: '0.5s ease in out',
    opacity: 1,
    '& .assetImage': {
      width: '245px', height: '500px', marginBottom: '16px',
    },
    '& .assetTitle': {
      color: 'white', fontWeight: 100, fontSize: '18px', marginBottom: '16px',
    },
    '& .authyIcon': {
      borderRadius: '50%',
      width: '18px',
      position: 'relative',
      top: '2px',
      marginRight: '8px',
    },
    '& .marginRight': {
      marginRight: '8px',
    },
  },
  fullOpacity: {
    opacity: 1,
  },
  noOpacity: {
    opacity: 0,
  },
}), { name: 'VerifyHelperText' });

export default useStyles;
