import React from 'react';

import { VerificationMethod } from '@alpha/payments-dtos';
import { makeStyles } from '@alpha/ui-lib/theme';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';
import t from 'utils/translationHelper';

import { AuthyPageStates, TAuthyType } from '../../../../../../store/authy/reducer';

interface IProps {
  type?: TAuthyType;
  pageState: AuthyPageStates;
  prevPageState: AuthyPageStates;
  classes: Record<string, string>;
  handleSetPageState: (_pageState: AuthyPageStates) => void;
  triggerMfa?: (
    mfaOptionChosen?: string | undefined,
    verificationMethod?: VerificationMethod | undefined
  ) => Promise<void>;
}

const useStyles = makeStyles(
  () => ({
    paymentsFooter: {
      '& > h3': {
        color: 'white',
        marginBottom: '16px',
      },
      '& h3': {
        textDecoration: 'none',
        fontWeight: 700,
      },
    },
  }),
  { name: 'Footer' },
);

const Footer: React.FC<IProps> = ({
  pageState,
  prevPageState,
  type,
  classes,
  handleSetPageState,
  triggerMfa,
}: IProps) => {

  const styles = useStyles();

  const handleHelperTextClick = (): void => {
    if (pageState === AuthyPageStates.OPTIONS) {
      handleSetPageState(prevPageState);
    } else {
      handleSetPageState(AuthyPageStates.OPTIONS);
    }
  };

  if (triggerMfa) {
    const handleLeftActionButtonClick = async () => {
      if (pageState === AuthyPageStates.SMS) {
        await sendSMS();
        return;
      }

      await resendPushNotification();
    };

    const handleRightActionButtonClick = async () => {
      if (pageState === AuthyPageStates.AUTHY) {
        await sendSMS();
        return;
      }
      await resendPushNotification();
    };

    const sendSMS = async () => {
      await triggerMfa('sms', VerificationMethod.SMS);
    };

    const resendPushNotification = async () => {
      await triggerMfa(undefined, VerificationMethod.PUSH_NOTIFICATION);
    };

    return (
      <div className={styles.paymentsFooter}>
        <Typography style={{ color: 'white' }} variant="h3" className={classes.cta}>
          {t('onetouch_not_available?')}
        </Typography>
        <div style={{ display: 'flex', justifyContent: pageState === AuthyPageStates.SMS ? 'space-between' : 'center' }}>
          {pageState === AuthyPageStates.SMS && (
            <Typography variant="h3" className={classes.cta} onClick={handleLeftActionButtonClick}>
              {t('resend_sms')}
            </Typography>
          )}
          <Typography
            variant="h3"
            className={classes.cta}
            onClick={handleRightActionButtonClick}
          >
            {pageState === AuthyPageStates.SMS ? t('send_authy') : t('send_sms')}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <Typography variant="h3" className={classes.cta} onClick={handleHelperTextClick!}>
      {pageState !== AuthyPageStates.OPTIONS ? t('havent_received_your_code_yet') : t('go_back')}
    </Typography>
  );
};

export default Footer;
