import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(({ typography }) => ({
  tradeContainer: {
    backgroundColor: '#F4F4F4',
    padding: '12px 17px 39px 20px',
    margin: '0px 35px 0px 25px',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '21px',
  },
  transfersTitle: {
    margin: '0px 35px 0px 25px',
  },
  textStyle: {
    fontSize: 14,
    fontFamily: typography.fontFamilySecondary,
  },
  subHeading: {
    fontFamily: typography.fontFamilySecondary,
    fontSize: '14px',
    fontWeight: typography.fontWeightSemiBold,
    paddingBottom: '30px',
  },
  remitContainer: {
    padding: '46px 0px 0px 25px',
    margin: '0px 25px 0px 0px',
  },
  buttonContainer: {
    padding: '46px 16px 24px 16px',
  },
  wireTransferGuide: {
    margin: '40px 35px 0px 25px',
  },
  wireTransferFirstParty: {
    margin: '40px 35px 290px 25px',
  },
  accountDetailTable: {
    background: 'transparent',
    marginTop: '12px',
    '& .MuiTableBody-root tr': {
      borderBottom: 'none',
    },
    '& .MuiTableBody-root tr td': {
      padding: '5px 0px !important',
    },
  },
  wireDetailsHeader: {
    marginTop: '35px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioSubtitle: {
    opacity: '50%',
    fontFamily: typography.fontFamilySecondary,
    fontSize: 12,
    padding: '0px 0px 24px 28px',
  },
  divider: {
    padding: '0px 0px 24px 0px',
  },
  transferDivider: {
    padding: '24px 0px 24px 0px',
  },
  linkedPayments: {
    fontWeight: 1000,
    fontSize: 14,
    color: 'Black',
    marginBottom: '40px',
  },
}), { name: 'ConfirmTrade' });

export default useStyles;
