import React from 'react';
import { Link } from 'react-router-dom';
import t from 'utils/translationHelper';

import { Button } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faFileAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../hooks/useReportsPolling';

import useStyles from './HeaderButtons.styles';

interface IProps {
  currencyAccountId: string | undefined;
  eMoneyDisabled?: boolean
}

const HeaderButtons: React.FC<IProps> = (
  { currencyAccountId, eMoneyDisabled }: IProps,
) => {
  const classes = useStyles();
  const { executeReportGeneration } = useReportsPolling(PageType.CurrencyAccount);

  const handleReportGeneration = async (type: FileType, downloadType: DownloadType) => {
    if (currencyAccountId) {
      await executeReportGeneration(type, currencyAccountId, undefined, undefined, downloadType);
    }
  };

  return (
    <div>
      {!eMoneyDisabled && (
        <Link to={`/app/statements/${currencyAccountId}`} data-testid="currency-account-statements-link">
          <APTooltip title={t('statements')}>
            <Button className={classes.button}>
              <FontAwesomeIcon icon={faFileAlt as IconProp} />
            </Button>
          </APTooltip>
        </Link>
      )}
      <APTooltip title={t('download_account_letter')}>
        <Button
          data-testid="currency-account-download-alpha-details"
          onClick={() => handleReportGeneration(FileType.PDF, DownloadType.AccountLetter)}
          className={classes.button}
        >
          <FontAwesomeIcon icon={faDownload as IconProp} />
        </Button>
      </APTooltip>
    </div>
  );
};

export default HeaderButtons;
