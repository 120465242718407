/* eslint-disable import/prefer-default-export */
import { CurrencyAccountTransferDto, CurrencyAccountTransferStatus } from '@alpha/currency-accounts-dtos';

export const shouldShowTransferApprovalButtons = (
  canApproveOthers: boolean,
  canApproveOwn: boolean,
  transfers: CurrencyAccountTransferDto[],
  userId: string,
  isApproveButton: boolean,
): boolean => {
  let containsOwn = false;
  let containsOthers = false;
  for (let i = 0; i < transfers.length; i += 1) {
    const alreadyApprovedByUser = isApproveButton
      ? transfers[i].approvalInfo?.approvals?.some((a) => a.userId === userId)
      : false;
    if (transfers[i].transferStatus !== CurrencyAccountTransferStatus.SUBMITTED
      || alreadyApprovedByUser) {
      return false;
    }
    if (transfers[i].submittedById === userId) {
      containsOwn = true;
    } else {
      containsOthers = true;
    }
  }

  if (containsOwn && !canApproveOwn) { return false; } // has own item, but cannot approve-> false
  if (containsOthers && !canApproveOthers) { return false; }

  return true;
};
