import { TradeFundingMethod } from '@alpha/fx-dtos';

export const mapTradeFundingMethodToFriendlyName = (
  fundingMethod: TradeFundingMethod,
): string => {
  switch (fundingMethod) {
    case TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE:
      return 'Currency Account Balance';
    case TradeFundingMethod.WIRE_TRANSFER:
      return 'Wire Transfer';
    case TradeFundingMethod.PAD:
      return 'PAD';
    default:
      return '-';
  }
};

export default mapTradeFundingMethodToFriendlyName;
