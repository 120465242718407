import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  clearButton: {
    color: '#A62B23',
    backgroundColor: '#A62B231A',
    marginRight: '8px',
    '&:hover': {
      backgroundColor: '#A62B2380'
    }
  },
  updateInfoButton: {
    width: 'calc(100% - 8px - 64px)'
  }
}), { name: 'beneficiaries' });

export default useStyles;
