import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  container: {
    '& > div, > .address > div': {
      marginBottom: '24px',
    },
  },
  typeInfomation: {
    marginBottom: '44px',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > div': {
      width: '48%',
    },
  },
  infoIcon: {
    marginTop: '4px',
    marginLeft: '4px',
    cursor: 'pointer',
    color: '#1E8777',
  },
  ssiInfo: {
    fontSize: '11px',
    padding: '8px 16px',
  },
  chip: {
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      border: 'none',
      backgroundColor: '#f5f5f5',
      padding: '10px 30px 5px 15px',
      borderRadius: '4px',
      marginTop: '6px',
    },
    '& .MuiFormHelperText-root': {
      position: 'relative',
      left: '-13px',
      top: '3px',
    },
  },
}), { name: 'CreateBeneficiaryDrawer' });

export default useStyles;
