import React from 'react';
import useActionDropdown from 'hooks/useActionDropDown';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useDownloadFile from 'hooks/useDownloadFile';
import useLog from 'hooks/useLog';
import useReportsPolling, { DownloadType, FileType, PageType } from 'hooks/useReportsPolling';
import { OptionTradeDto } from 'models/optionTrades';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import OptionTradeService from 'services/OptionTrades/optionTrades.service';

import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import useStyles from './ActionDropDown.styles';

interface IProps {
  trade?: any; // @TO-DO
  handleReloadOptionTradeTable?: () => void,
  setSelectedOptionTrade?: React.Dispatch<React.SetStateAction<OptionTradeDto | undefined>>,
}

const OptionTradeActionDropDown: React.FC<IProps> = ({
  trade,
  setSelectedOptionTrade,
  handleReloadOptionTradeTable,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const sb = useAlphaSnackbar();
  const { t } = useTranslation();
  const { logError } = useLog();
  const { downloadFile } = useDownloadFile();

  const downloadTermSheet = async (tradeId: string) => {
    try {
      const url = await OptionTradeService.getTermSheetDownloadUrl(tradeId);
      await downloadFile(url, `${t('term_sheet')}`);
    } catch (error) {
      sb.trigger(t('error_downloading_term_sheet_file'));
      logError({ action: 'Error getting term sheet url ', error });
    }
  };

  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    setOpen,
  } = useActionDropdown({ });

  const menuItems = [];
  if (trade) {
    menuItems.push(
      {
        key: 'view',
        content: (
          <Typography data-testid={`view-option-info-action-${trade.id}`}>
            {t('view_trade')}
          </Typography>
        ),
        icon: displayIcon('View'),
        onClick: () => {
          if (setSelectedOptionTrade) {
            setSelectedOptionTrade(trade);
            history.push(`/app/transactions/option-trades/${trade.id}`);
          }
        },
        underline: true,
      },
    );
    menuItems.push({
      key: 'download',
      content: (
        <Typography data-testid="download-term-sheet">
          {t('download_term_sheet')}
        </Typography>
      ),
      icon: displayIcon('Download'),
      onClick: () => downloadTermSheet(
        trade.id,
      ),
    });
  }

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box data-testid="action-dropdown" className={classes.wrapper}>
        <APMenu
          actions
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          disableHoverEffect
          buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
          buttonTitle=""
          // @ts-ignore
          icon={faEllipsisH}
        >
          {
            menuItems.map((item) => (
              <MenuDropdownItem
                setOpen={setOpen}
                key={item.key}
              >
                {item}
              </MenuDropdownItem>
            ))
          }
        </APMenu>
      </Box>
    </ClickAwayListener>
  );
};

export default OptionTradeActionDropDown;
