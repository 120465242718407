import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    drawer: {
      '& .MuiDrawer-paper': {
        overflowX: 'hidden',
        '& > button': {
          zIndex: 100,
        },
      },
    },
    finishButton: {
      marginTop: 'auto',
      marginBottom: 40,
      width: '100%',
      padding: '0px 40px',
    },
  }),
  { name: 'StatementsInfoDrawer' },
);

export default useStyles;
