import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  checkbox: {
    paddingTop: '12px',
  },
  tabs: {
    '& .MuiTab-root .MuiTab-wrapper div': {
      textTransform: 'capitalize !important',
    },
  },
}), { name: 'PaymentMethods' });

export default useStyles;
