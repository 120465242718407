import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ palette }) => ({
    root: {
      display: 'flex',
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        '& > div': {
          paddingRight: '3px',
        },
      },
    },
  }),
  { name: 'NationalityPair' },
);
