import React from 'react';
import useAuthorization from 'hooks/useAuthorization';
import { UserRole } from 'models/user';
import t from 'utils/translationHelper';

import Table from '../../../components/Table/Table';
import {
  TCurrencyAccount, TCurrencyAccountDrawer, TCurrencyAccounts,
} from '../../../models/currencyAccounts';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import { formatCurrency } from '../../../utils/currency.helpers';

import Buttons from './Buttons/Buttons';

interface IProps {
  currencyCode: string;
  currencyAccounts: TCurrencyAccounts;
  handleAccountTableRowClick: (account: TCurrencyAccount) => void;
  handleOpenDrawer: (_drawer: TCurrencyAccountDrawer) => void
}

const CurrencyAccountsTable: React.FC<IProps> = (props: IProps) => {
  const {
    currencyCode, currencyAccounts, handleAccountTableRowClick, handleOpenDrawer,
  } = props;

  const columns: ITableColumn[] = [
    { header: t('account_details'), accessor: 'friendlyNameJSX', width: 180 },
    { header: '', accessor: 'buttons', width: 600 },
    {
      header: t('pending_transactions'),
      accessor: 'pendingTransactionsFormatted',
      align: 'right',
    },
    {
      header: t('cleared_balance'),
      accessor: 'clearedBalanceFormatted',
      align: 'right',
    },
    {
      header: t('available_balance'),
      accessor: 'availableBalanceFormatted',
      align: 'right',
    },
  ];

  const { authorized: authorisedIATInputter } = useAuthorization([[UserRole.IAT_INPUTTER]]);

  const hideIATBtn = !(authorisedIATInputter);

  const formatteddata = currencyAccounts.map((column) => ({
    ...column,
    friendlyNameJSX: (
      <FriendlyName
        testId={column.id}
        handleOpenDrawer={handleOpenDrawer}
        friendlyName={column.friendlyName}
        pendingIATTransfers={column.pendingIATDrafts}
        bankInformation={column.type?.toLocaleLowerCase() === 'wallet' ? column.reference : column.iban}
      />
    ),
    buttons: (
      <Buttons
        handleOpenDrawer={handleOpenDrawer}
        accountId={column.id}
        accountType={column.accountType}
        accountName={column.friendlyName}
        hideIATBtn={hideIATBtn}
      />
    ),
    clearedBalanceFormatted: formatCurrency(currencyCode, column.clearedBalance),
    availableBalanceFormatted: formatCurrency(currencyCode, column.availableBalance),
    pendingTransactionsFormatted: formatCurrency(currencyCode, column.pendingTransactions),
  }));

  return (
    <Table
      columns={columns}
      data={formatteddata}
      handleTableRowClick={(account: TCurrencyAccount) => {
        handleAccountTableRowClick(account);
      }}
      skip={0}
    />
  );
};

interface IFriendlyNameProps {
  testId: string;
  friendlyName: string,
  bankInformation: string | undefined,
  pendingIATTransfers?: number,
  handleOpenDrawer: (_drawer: 'PendingTransfers') => void
}

const FriendlyName: React.FC<IFriendlyNameProps> = ({
  testId,
  friendlyName,
  bankInformation,
  handleOpenDrawer,

}: IFriendlyNameProps) => (
  <>
    {friendlyName || bankInformation}
  </>
);

export default CurrencyAccountsTable;
