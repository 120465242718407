import React from 'react';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Authorization from '../../../../../../hocs/Authorization';
import { UserRole } from '../../../../../../models/user';

import useStyles from './RightAlignedCta.styles';

interface IProps {
  handleContractDownload?: () => void;
  handleRejectBatch: () => void;
  handleApproveBatch: () => void;
  showContractNoteButton: boolean;
  showRejectBatchButton: boolean;
  showApproveBatchButton: boolean;
  requiredRole: UserRole;
}

const RightAlignedCta: React.FC<IProps> = ({
  handleContractDownload,
  handleRejectBatch,
  handleApproveBatch,
  showContractNoteButton,
  showRejectBatchButton,
  showApproveBatchButton,
  requiredRole,
}: IProps) => {
  const classes = useStyles();

  return (
    <Box flex="column">
      {showContractNoteButton && (
        <ActionButton
          style={{ background: '#F7F7F7', color: '#212529', marginRight: '10px' }}
          startIcon={<FontAwesomeIcon icon={faDownload as IconProp} />}
          testId="download-contract-note-btn"
          size="medium"
          onClick={handleContractDownload}
        >
          {t('download_summary')}
        </ActionButton>
      )}
      {showRejectBatchButton && (
        <Authorization requiredRoles={[[requiredRole]]}>
          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <ActionButton
              className={classes.rejectButton}
              testId="reject-batch-btn"
              size="medium"
              style={{ background: '#F7F7F7', color: '#212529' }}
              onClick={handleRejectBatch}
            >
              {t('reject_batch')}
            </ActionButton>
          </div>
        </Authorization>
      )}
      {showApproveBatchButton && (
        <Authorization requiredRoles={[[requiredRole]]}>
          <div style={{ display: 'inline-block' }}>
            <ActionButton
              className={classes.approveButton}
              testId="approve-batch-btn"
              size="medium"
              rounded={false}
              onClick={handleApproveBatch}
            >
              {t('approve_batch')}
            </ActionButton>
          </div>
        </Authorization>
      )}
    </Box>
  );
};

export default RightAlignedCta;
