/* eslint-disable max-lines-per-function */
import clipboardCross from 'assets/clipboardCross.svg';
import ApprovalsDrawer from 'components/Molecules/Payments/ApprovalDrawer';
import RejectPaymentModal from 'components/Molecules/Payments/RejectPaymentModal';
import EmptyTable from 'components/Table/EmptyTable';
import BeneficiaryInfoDrawer from 'domain/Transactions/BatchSummary/PaymentSummary/BeneficiaryInfoDrawer/BeneficiaryInfoDrawer';
import ActionDropDown from 'domain/Transactions/Dashboard/FxTradeTable/DisplayTable/ActionDropDown/ActionDropDown';
import NoOfApprovers from 'domain/Transactions/Dashboard/FxTradeTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import PaymentInfoDrawer from 'domain/Transactions/PaymentInfoDrawer/PaymentInfoDrawer';
import useSwitchAccount from 'hooks/useSwitchAccount';
import React, {
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import browserHistory from 'services/history/browserHistory';
import PaymentsService from 'services/Payments/payments.service';
import { TStore } from 'store';
import { TAuthyState } from 'store/authy/reducer';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapPaymentStatusDisplay from 'utils/payments/mapPaymentStatusDisplay';
import t from 'utils/translationHelper';

import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';
import { Box, Button } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { Status } from '@alpha/ui-lib/ui/Status';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { datadogLogs } from '@datadog/browser-logs';

import { OutstandingActionsContext } from '..';
import useStyles from '../index.styles';
import useOutstandingActionCache from '../useOutstandingActionCache';

const PaymentApprovals: React.FC = () => {
  const classes = useStyles();
  const { currentAccount } = useSwitchAccount();
  const CACHE_KEY = `${currentAccount.id}-pending-payments`;
  const [pendingApprovalPayments, setPendingApprovalPayments] = useState<PaymentDto[]>([]);
  const [beneficiaryId, setBeneficiaryId] = useState<string>();
  const { saveResponse, getResponse } = useOutstandingActionCache();
  const [selectedPayment, setSelectedPayment] = useState<PaymentDto>();
  const { pendingPaymentsCount, setPendingPaymentsCount } = useContext(OutstandingActionsContext);
  const [paymentsToReject,
    setPaymentsToReject] = useState<PaymentDto[]>([]);
  const [rejectedPaymentIds, setRejectedPaymentIds] = useState<string[]>([]);
  const [paymentsToApprove,
    setPaymentsToApprove] = useState<PaymentDto[]>([]);
  const [approvalDrawerOpen,
    setApprovalDrawerOpen] = useState<boolean>(false);
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const SHOW_LIMIT = 5;

  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  const loadPendingPayments = async () => {
    try {
      const {
        total,
        records: pendingPayments,
      } = await PaymentsService.getPendingApprovalPayments();
      setPendingApprovalPayments(pendingPayments);
      if (setPendingPaymentsCount) { setPendingPaymentsCount(total); }
      saveResponse(CACHE_KEY, pendingPayments);
      saveResponse(`${CACHE_KEY}_count`, total);
    } catch (e) {
      datadogLogs.logger.error(e);
    }
  };

  // check if succedded payment id in in pending approvals
  // and reload in case of slow BE (needs to update from SUBMITTED to APPROVED)
  const reloadIfSubmitted = (id: string): void => {
    const payment = pendingApprovalPayments?.find((p) => p.id === id);
    if (payment) loadPendingPayments();
  };

  // Handle reload data after actions
  useEffect(() => {
    if (
      authyState.type?.type === 'PAYMENTS'
    ) {
      setTimeout(() => {
        loadPendingPayments();
        setSelectedPayment(undefined);
      }, 6000);
    } else if (authyState.type?.type === 'PAYMENT_APPROVE' && authyState.status === 'SUCCESS') {
      setTimeout(() => {
        loadPendingPayments();
        setSelectedPayment(undefined);
      }, 3000);
    }
    setTimeout(() => {
      const id = authyState.type?.paymentIds?.find((id) => id);
      if (id) reloadIfSubmitted(id);
    }, 6000);
  }, [authyState.status, authyState.type, browserHistory.push]);

  useEffect(() => {
    if (rejectedPaymentIds && rejectedPaymentIds.length > 0) {
      setTimeout(() => {
        loadPendingPayments();
      }, 1000);
      setSelectedPayment(undefined);
    }
  }, [rejectedPaymentIds]);
  // End Handle reload data after actions

  useEffect(() => {
    try {
      const cachedPayments = getResponse<PaymentDto[]>(CACHE_KEY);
      const cachedCount = getResponse<number>(`${CACHE_KEY}_count`);
      if (cachedPayments) {
        setPendingApprovalPayments(cachedPayments as PaymentDto[]);
      }
      if (cachedCount) {
        if (setPendingPaymentsCount) { setPendingPaymentsCount(cachedCount); }
      }
    } finally {
      loadPendingPayments();
    }
  }, []);

  const handleBeneficiaryButton = (beneId: string) => {
    setBeneficiaryId(beneId);
  };

  const handleApprovePayments = (payments: PaymentDto[]): void => {
    setPaymentsToApprove(payments);
    setApprovalDrawerOpen(true);
  };

  const handleRejectPayments = (payments: PaymentDto[]): void => {
    setPaymentsToReject(payments);
    setRejectModalOpen(true);
  };

  const columns: IStyledGenericTableProps['columns'] = [{
    header: t('id'),
    accessor: 'contractNumber',
    width: 100,
  },
  {
    header: t('account'),
    accessor: 'account',
    align: 'left',
  },
  {
    header: t('upload_date'),
    accessor: 'uploadedDate',
    align: 'left',
  },
  {
    header: t('amount'),
    accessor: 'amount',
    align: 'left',
  },
  {
    header: t('currency'),
    accessor: 'currency',
    align: 'left',
  },
  {
    header: t('approvals'),
    accessor: 'approvals',
    align: 'left',
  },
  {
    header: t('status'),
    accessor: 'paymentStatus',
    align: 'left',
  }, {
    header: t('actions'),
    accessor: 'actions',
    align: 'left',
  }];

  const data = useMemo((): IStyledGenericTableProps['data'] => pendingApprovalPayments.slice(0, SHOW_LIMIT).map((payment) => ({
    ...payment,
    contractNumber: payment.contractNumber || '-',
    amount: (
      <span className={classes.amount}>
        {formatNumber(payment.amount, 2)}
      </span>),
    account: (
      <Button
        disableRipple
        className={classes.tableButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          handleBeneficiaryButton(payment.beneficiaryId);
        }}
      >
        {payment.beneficiaryName || '-'}
      </Button>),
    uploadedDate: formatIsoDate(payment.uploadedDate),
    currency: (
      <div className={classes.currencyFlag}>
        <Flag code={payment.debitingCurrency} size="sm" />
        {' '}
        {payment.debitingCurrency}
      </div> || '-'),

    approvals: (payment
      && (
        <NoOfApprovers
          uploadedBy={payment.uploadedBy}
          uploadedDate={payment.uploadedDate}
          noOfApprovals={payment.approvalInfo.approvals?.length}
          approvedBy={payment.approvalInfo.approvals || []}
          approvalsRequired={payment.approvalInfo.approvalsRequired}
        />
      )
    ) || '-',
    paymentStatus: (
      <Status
        className={classes.status}
        variant={mapPaymentStatusDisplay(payment.status).variant}
      >
        {t(mapPaymentStatusDisplay(payment.status).text) || '-'}

      </Status>),
    actions: (
      <ActionDropDown
        payment={payment}
        setDisplayMultiSelect={() => { }}
        displayMultiSelect={false}
        setSelectedPayment={setSelectedPayment}
        handleReloadPaymentTable={undefined}
        handleApprovePayments={handleApprovePayments}
        handleRejectPayments={handleRejectPayments}
        currentOptions={undefined}
        setCurrentOptions={undefined}
        setTextValue={undefined}
      />
    ),
  }
  )), [pendingApprovalPayments]);

  return (
    <Box style={{
      overflow: 'auto',
      height: '230px',
    }}
    >
      {data.length > 0 ? (
        <>
          <StyledGenericTable
            className={classes.stickyHeaderTable}
            testId="pending-payments-table"
            columns={columns}
            data={data}
          />

          {pendingPaymentsCount && pendingPaymentsCount > SHOW_LIMIT ? (
            <div className={classes.showAllMessage}>
              <span>
                {t('showing_top')}
                {' '}
                {SHOW_LIMIT}
                {' '}
                {t('items_please_click_to_view_all_items')}
              </span>
            </div>
          ) : null}
        </>
      ) : (
        <EmptyTable
          title={t('no_pending_payments')}
          subtitle={t('payment_awaiting_approval_display')}
          className={classes.emptyTable}
          icon={clipboardCross}
        />
      )}

      <BeneficiaryInfoDrawer
        paymentSummaryBeneficiaryId={beneficiaryId}
        onClose={() => setBeneficiaryId(undefined)}
        open={Boolean(beneficiaryId)}
        setPaymentSummaryBeneficiaryId={setBeneficiaryId}
      />
      {
        (
          paymentsToApprove.length > 0
          && paymentsToApprove[0].status === PaymentStatus.SUBMITTED)
        && (
          <ApprovalsDrawer
            open={approvalDrawerOpen}
            onClose={() => setApprovalDrawerOpen(false)}
            selectedPayments={paymentsToApprove}
          />
        )
      }
      {
        (
          paymentsToReject.length > 0
          && paymentsToReject[0].status === PaymentStatus.SUBMITTED)
        && (
          <RejectPaymentModal
            open={rejectModalOpen}
            handleClose={() => setRejectModalOpen(false)}
            payments={paymentsToReject}
            handleRejected={(rejectedIds) => {
              setRejectedPaymentIds(rejectedIds);
            }}
          />
        )
      }
      <PaymentInfoDrawer
        paymentDrawerOpen={Boolean(selectedPayment)}
        setPaymentDrawerOpen={setSelectedPayment}
        selectedPayment={selectedPayment}
        sharedStyles={classes}
        testIds={
          {
            paymentDrawerBeneficiaryTable: 'payment-info-drawer-bene',
            paymentDrawerPaymentTable: 'payment-info-drawer-payment',
          }
        }
      />
    </Box>
  );
};
export default PaymentApprovals;
