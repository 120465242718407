/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import moment from 'moment';
import t from 'utils/translationHelper';
import { useMutation } from 'react-query';

import { Approver } from '@alpha/auth-dtos';
import {
  PaymentBatchDto, PaymentDto, PaymentStatus,
} from '@alpha/payments-dtos';
import { TStyledStatSummaryData } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import { TSearchParams } from '../../../../hooks/useSearch';
import PaymentsService from '../../../../services/Payments/payments.service';
import SearchService from '../../../../services/Search/SearchService';
import timer from '../../../../utils/timer';

import TranslationHelper from '../../../../utils/translationHelper';
import useStyles from './UploadSummary.styles';

interface IProps {
  batchId: string;
}

// eslint-disable-next-line max-lines-per-function
const useUploadSummary = (props: IProps) => {
  const { batchId } = props;

  const styles = useStyles();
  const { logError } = useLog();
  const [batchStatsSummary, setBatchStatsSummary] = useState<TStyledStatSummaryData[]>([]);
  const [paymentsStatsSummary, setPaymentsStatsSummary] = useState<TStyledStatSummaryData[]>([]);
  const [rejectedPayments, setRejectedPayments] = useState<PaymentDto[]>([]);
  const [erroredPayments, setErroredPayments] = useState<PaymentDto[]>([]);
  const [validPayments, setValidPayments] = useState<PaymentDto[]>([]);
  const [rejectedLoading, setRejectedLoading] = useState<boolean>(false);
  const [erroredLoading, setErroredLoading] = useState<boolean>(false);
  const [validLoading, setValidLoading] = useState<boolean>(false);
  const [batchUploadResult, setBatchUploadResult] = useState<PaymentBatchDto>();
  const [openCancelBatchModal, setOpenCancelBatchModal] = useState<boolean>(false);

  const [textValue, setTextValue] = useState<string>('');
  const [approvers, setApprovers] = useState<Approver[]>([]);
  const [selectedApprovers, setSelectedApprovers] = useState<Approver[]>([]);
  const [dropdownLoading, setDropdownLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();

  const getBatchSummary = useMutation('getBatchSummary', PaymentsService.getBatchPaymentsSummary, {
    onSuccess: (data: PaymentBatchDto) => {
      createBatchStatSummaryData(data);
      createPaymentStatSummaryData(data);
      setBatchUploadResult(data);
    },
    onError: (error) => {
      sb.trigger(`${t('error_getting_batch_details')} ${error}`);
      logError({ action: 'Error loading payment batch details', error });
    },
  });

  const createBatchStatSummaryData = (batchStats: PaymentBatchDto) => {
    const batchSummaryStat: TStyledStatSummaryData[] = [{
      key: t('file_name'),
      value: <div className={styles.statValue} > {`${batchStats.fileName.toString() || '-'}`}</div>,
    },
    {
      key: t('batch_id'),
      value: <div className={styles.statValue}>{`${batchStats.friendlyId.toString() || '-'}`}</div>,
    },
    {
      key: t('upload_date'),
      value: <div className={styles.statValue} > {`${moment(batchStats.uploadedDate).format('DD/MM/YYYY') || '-'}`}</div>,
    }];
    setBatchStatsSummary(batchSummaryStat);
  };

  const createPaymentStatSummaryData = (batchStats: PaymentBatchDto) => {
    const paymentSummaryData: TStyledStatSummaryData[] = [{
      key: t('number_of_payments'),
      value: <div className={styles.statValue}>{`${batchStats.processedRecords.toString() || '-'}`}</div>,
    },
    {
      key:
        <div>
          <FontAwesomeIcon icon={faCheckCircle as IconProp} className={styles.passedIcon} />
          {t('passed')}
        </div>,
      value: <div className={styles.statValue}>{`${batchStats.validRecords.toString() || '-'}`}</div>,
    },
    {
      key:
        <div>
          <FontAwesomeIcon icon={faCircleExclamation as IconProp} className={styles.rejectedIcon} />
          {t('rejected')}
        </div>,
      value: <div className={styles.statValue}>{`${(batchStats.invalidRecords + batchStats.erroredRecords)?.toString() || '-'}`}</div>,
    }];
    setPaymentsStatsSummary(paymentSummaryData);
  };

  const loadRejectedPaymentsData = async (): Promise<PaymentDto[]> => {
    const searchParams: TSearchParams = {
      baseUrl: '/payments/search',
      queryParams: {
        skip: 0,
        take: 1000,
        sortby: 'contractNumber',
        sortorder: 'asc',
        batchid: batchId,
        status: PaymentStatus.INVALID,
      },
    };

    try {
      setRejectedLoading(true);
      const result: {
        total: number,
        records: PaymentDto[]
      } = await SearchService.GetTableData(searchParams);
      setRejectedPayments(result.records);
      return result.records;
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_rejected_payments'));
      logError({ action: 'Error loading rejected payments', error });
      return [];
    } finally {
      setRejectedLoading(false);
    }
  };

  const loadErroredPaymentsData = async (): Promise<PaymentDto[]> => {
    const searchParams: TSearchParams = {
      baseUrl: '/payments/search',
      queryParams: {
        skip: 0,
        take: 1000,
        sortby: 'contractNumber',
        sortorder: 'asc',
        batchid: batchId,
        status: PaymentStatus.ERRORED,
      },
    };

    try {
      setErroredLoading(true);
      const result: {
        total: number,
        records: PaymentDto[]
      } = await SearchService.GetTableData(searchParams);
      setErroredPayments(result.records);
      return result.records;
    } catch (error) {
      sb.trigger(t('failed_to_load_rejected_payments'));
      logError({ action: 'Error loading rejected payments', error });
      return [];
    } finally {
      setErroredLoading(false);
    }
  };

  const loadValidPaymentsData = async (): Promise<PaymentDto[]> => {
    const searchParams: TSearchParams = {
      baseUrl: '/payments/search',
      queryParams: {
        skip: 0,
        take: 1000,
        sortby: 'contractNumber',
        sortorder: 'asc',
        batchid: batchId,
        status: PaymentStatus.VALIDATED,
        excludebatchdraftpayments: 'false',
      },
    };

    try {
      setValidLoading(true);
      const result: {
        total: number,
        records: PaymentDto[]
      } = await SearchService.GetTableData(searchParams);
      setValidPayments(result.records);
      return result.records;
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_valid_payments'));
      logError({ action: 'Error loading valid payments', error });
      return [];
    } finally {
      setValidLoading(false);
    }
  };

  const handlePaymentApprovers = async () => {
    try {
      setDropdownLoading(true);
      setApprovers(await PaymentsService.getApprovers());
    } catch (e) {
      sb.trigger(e?.message || t('there_was_an_error_retrieving_your_approvers'));
      logError({ action: 'Error loading approvers' });
    } finally {
      setDropdownLoading(false);
    }
    return null;
  };

  const submitApproverEmails = async (): Promise<boolean> => {
    const currentApproverIds: string[] = [];
    if (selectedApprovers) {
      selectedApprovers.forEach((option) => currentApproverIds.push(option.userId));
    }
    try {
      await PaymentsService.postBatchPaymentsApproversEmail(
        batchId, { approverIds: currentApproverIds },
      );
      sb.trigger(t('successfully_sent_email_reminders'), 'success');
      return true;
    } catch (e) {
      sb.trigger(e?.message || t('unable_to_send_email_reminders'));
      logError({ action: 'Error sending email reminders' });
      return false;
    }
  };

  const pollValidPayments = async (retry = 5, delay = 2000) => {
    if (retry === 0) {
      sb.trigger(t('failed_to_retrieve_valid_payments_please_refresh_the_page_to_try_again'), 'error');
      logError({ action: 'Error loading valid payments' });
      return;
    }
    const records = await loadValidPaymentsData();
    await timer(delay);
    if (records.length !== batchUploadResult?.validRecords) {
      pollValidPayments(retry - 1);
    }
  };

  const pollInvalidPayments = async (retry = 5, delay = 2000) => {
    if (retry === 0) {
      sb.trigger(t('failed_to_retrieve_rejected_payments_please_refresh_the_page_to_try_again'), 'error');
      logError({ action: 'Error loading rejected payments' });
      return;
    }
    const records = await loadRejectedPaymentsData();
    await timer(delay);
    if (records.length !== batchUploadResult?.invalidRecords) {
      pollInvalidPayments(retry - 1);
    }
  };

  const pollErroredPayments = async (retry = 5, delay = 2000) => {
    if (retry === 0) {
      sb.trigger(t('failed_to_retrieve_errored_payments'), 'error');
      logError({ action: 'Error loading errored payments' });
      return;
    }
    const records = await loadErroredPaymentsData();
    await timer(delay);
    if (records.length !== batchUploadResult?.erroredRecords) {
      pollErroredPayments(retry - 1);
    }
  };

  return {
    rejectedPayments,
    validPayments,
    erroredPayments,
    getBatchSummary,
    batchStatsSummary,
    paymentsStatsSummary,
    rejectedLoading,
    validLoading,
    erroredLoading,
    loadRejectedPaymentsData,
    loadValidPaymentsData,
    loadErroredPaymentsData,
    batchUploadResult,
    openCancelBatchModal,
    setOpenCancelBatchModal,
    textValue,
    setTextValue,
    handlePaymentApprovers,
    approvers,
    setApprovers,
    dropdownLoading,
    selectedApprovers,
    setSelectedApprovers,
    submitApproverEmails,
    pollValidPayments,
    pollInvalidPayments,
    pollErroredPayments,
  };
};

export default useUploadSummary;
