import { MarketOrderStatus } from '@alpha/fx-dtos';


export const mapMarketOrdersStatusDisplay = (
  marketOrderStatus: MarketOrderStatus,
): {
  variant: 'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined, text:
    string
} => {
  switch (marketOrderStatus) {
    case MarketOrderStatus.ACTIVE:
      return { variant: 'info', text: 'ACTIVE' };
    case MarketOrderStatus.CANCELLED:
      return { variant: 'error', text: 'CANCELLED' };
    case MarketOrderStatus.COMPLETED:
      return { variant: 'success', text: 'FILLED' };
    default:
      return { variant: 'warning', text: '-' };
  }
};

export default mapMarketOrdersStatusDisplay;
