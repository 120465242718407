import getSymbolFromCurrency from 'currency-symbol-map';

export const formatNumber = (value: number, decimalPlaces: number, shouldUseBrackets = false):string => {
  const absolute = Math.abs(value);

  if (value || value === 0) {
    if (shouldUseBrackets && (Math.sign(value) === -1)) {
      const result = absolute?.toLocaleString('un-US', { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
      return `(${result})`;
    }

    return value?.toLocaleString('un-US', { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
  }

  return '';
};

export const formatCurrency = (currency: string | undefined, value: number | undefined, shouldUseBrackets = false):string => {
  if (currency && (value || value === 0)) {
    const symbol = getCurrencySymbol(currency);
    return `${symbol}${formatNumber(value, 2, shouldUseBrackets)}`;
  } return '-';
};

export const getCurrencySymbol = (currency?: string): string => {
  if (currency) {
    const currencyCode = (currency === 'CNH') ? 'CNY' : currency;
    const symbol = getSymbolFromCurrency(currencyCode) || '';
    return symbol;
  }
  return '';
};

export const splitCurrencyPair = (currencyPair: string): string[] => currencyPair?.split('/', 2);
