import { AccountAuditDto, UserAccountDto } from '@alpha/auth-dtos';
import { AccountConfigurationFXDto } from '@alpha/fx-dtos';
import {
  AccountUserEmailSettingsDto,
  AccountUserEmailSettingsUpdateRequest,
  AccountUsersEmailSettingsUpdateRequest,
  UsersEmailSettingsDto,
} from '@alpha/notifications-dtos';
import { DeleteAccountUsersRequest, UpdateUsersRolesRequest, User } from '@alpha/profile-dtos';

import { FileType } from '../../hooks/useReportsPolling';
import instance from '../Axios/instance';

export class AccountSettingsService {
  public static async getUserAccountRoles(): Promise<UserAccountDto[]> {
    const response = await instance.get('/auth/user-accounts');
    return response.data;
  }

  public static async getTeamPermissionSettings(accountId: string): Promise<User[]> {
    const response = await instance.get(`/profiles/accounts/${accountId}/users`);
    return response.data;
  }

  public static async getPersonalEmailSettings(
    accountId?: string,
  ): Promise<AccountUserEmailSettingsDto[]> {
    const config = {
      headers: {
        'account-id': accountId,
      },
    };
    const response = await instance.get('/notifications/user-email-settings', accountId ? config : {});
    return response.data;
  }

  public static async getTeamEmailSettings(
    accountId: string,
  ): Promise<UsersEmailSettingsDto[]> {
    const config = {
      headers: {
        'account-id': accountId,
      },
    };

    const response = await instance.get(`/notifications/account-users-email-settings?accountid=${accountId}`, config);
    return response.data;
  }

  public static async postCreateUser(userRequest: any): Promise<void> {
    const response = await instance.post('/auth/users/create',
      userRequest);
    return response.data;
  }

  public static async postPersonalEmailSettings(
    request: AccountUserEmailSettingsUpdateRequest[],
  ): Promise<void> {
    const response = await instance.post('/notifications/user-email-settings',
      request);
    return response.data;
  }

  public static async postTeamEmailSettings(
    accountId : string,
    request: AccountUsersEmailSettingsUpdateRequest[],
  ): Promise<void> {
    const response = await instance.post(`/notifications/account-users-email-settings?accountid=${accountId}`,
      request);
    return response.data;
  }

  public static async postUserRoles(request: UpdateUsersRolesRequest): Promise<void> {
    const config = {
      headers: {
        'account-id': request.accountId,
      },
    };
    const response = await instance.post(
      '/profiles/update-roles',
      request,
      config,
    );
    return response.data;
  }

  public static async getAccountConfigurationForAccount(
    accountId: string,
  ): Promise<AccountConfigurationFXDto> {
    const config = {
      headers: {
        'account-id': accountId,
      },
    };
    const response = await instance.get('/fx/account-configuration', config);
    return response.data;
  }

  public static async deleteUser(request: DeleteAccountUsersRequest): Promise<number> {
    const body = {
      data:
        request,
    };
    const response = await instance.delete(
      '/profiles/account-users',
      body,
    );
    return response.status;
  }

  public static async getAccountAudits(
    accountId: string,
  ): Promise<AccountAuditDto[]> {
    const config = {
      headers: {
        'account-id': accountId,
      },
    };
    const response = await instance.get('/auth/account-audits', config);
    return response.data;
  }

  public static async getAccountAuditReport(
    accountId: string,
    type: FileType,
  ): Promise<string> {
    const config = {
      headers: {
        'account-id': accountId,
      },
    };
    const response = await instance.get(`/reports/audit-logs?type=${type}`, config);
    return response.data.executionArn;
  }
}

export default AccountSettingsService;
