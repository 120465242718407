import React from 'react';
import t from 'utils/translationHelper';

import Header from '../../../../../components/AuthyComponent/Header';
import BackdropLoader from '../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import useAuthy from '../../../../../hooks/useAuthy';
import usePrevious from '../../../../../hooks/usePrevious';
import { actions, AuthyPageStates } from '../../../../../store/authy/reducer';

import Footer from './Footer';
import useStyles from './Form.styles';
import FormBody from './FormBody';
import Subheader from './Subheader';

interface IForm {
  phoneNumber: string;
  handleComplete: (code: number) => void;
}

const Form: React.FC<IForm> = ({
  phoneNumber,
  handleComplete,
}: IForm) => {
  const { reduxState, triggerMfa, dispatch } = useAuthy();
  const { pageState } = reduxState;
  const prevPageState = usePrevious<AuthyPageStates>(reduxState.pageState || AuthyPageStates.AUTHY);

  const classes = useStyles();

  if (!pageState) {
    return (
      <BackdropLoader
        testId="backdrop-loader"
        thickness={5}
        size={60}
        style={{ outline: 'none' }}
      />
    );
  }

  const handleSetPageState = (_pageState: AuthyPageStates) => {
    dispatch(actions.updateAuthyPageState(_pageState));
  };

  return (
    <div className={classes.padding} data-testid="children-wrapper">
      <Header
        authySymbol={Boolean(pageState === AuthyPageStates.AUTHY)}
        classes={classes}
        headerText={reduxState.approvalRequestId ? t('authy_2fa_approval_required') : t('two-factor_authentication~')}
        subHeaderText={reduxState.approvalRequestId ? '' : <Subheader phoneNumber={phoneNumber} pageState={pageState} />}
      />
      <FormBody
        classes={classes}
        handleComplete={handleComplete}
        phoneNumber={phoneNumber}
      />
      <Footer
        type={reduxState.type?.type}
        triggerMfa={triggerMfa}
        pageState={pageState}
        prevPageState={prevPageState}
        handleSetPageState={handleSetPageState}
        classes={classes}
      />
    </div>
  );
};

export default Form;
