import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { AccountUsersEmailSettingsUpdateRequest, EmailNotificationType, UsersEmailSettingsDto } from '@alpha/notifications-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import {
  Box, Collapse,
  IconButton, Typography,
} from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faMessageDots } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from '../../../../components/Alert';
import TeamNotificationMatrixTable from '../../../../components/Molecules/AccountSettings/TeamNotificationMatrixTable/TeamNotificationMatrixTable';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import AccountSettingsService from '../../../../services/AccountSettings/accountSettings.service';
import useStyles from '../TeamSettings.styles';

interface IProps {
  selectedAccount?: AccountDto;
  canEditNotification?: boolean;
}

const TeamNotificationSettings: React.FC<IProps> = ({
  selectedAccount,
  canEditNotification = false,
}: IProps) => {

  const classes = useStyles();
  const sb = useAlphaSnackbar();
  const { logEvent, logError } = useLog();
  const [teamSettings, setTeamSettings] = useState<UsersEmailSettingsDto[]>([]);
  const [originalSettings, setOriginalSettings] = useState<UsersEmailSettingsDto[]>([]);
  const [submitting, setSubitting] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const loadTeamNotificationSettings = async (accountId: string) => {
    try {
      const accountSettings = await AccountSettingsService.getTeamEmailSettings(accountId);
      setOriginalSettings(JSON.parse(JSON.stringify(accountSettings)));
      setTeamSettings(accountSettings);
    } catch (e) {
      console.error(e);
      logError({ action: 'Error loading team communication settings' });
    }
  };

  const checkNotificationSettingsRequirement = (
    teamEmailSettings: UsersEmailSettingsDto[],
  ): boolean => {
    const monthlyTradeNotificationSubscriber = teamEmailSettings.filter(
      (settings) => settings.enabled.includes(EmailNotificationType.MONTHLY_TRADE_NOTIFICATIONS),
    );
    return (monthlyTradeNotificationSubscriber.length !== 0);
  };

  const saveTeamNotificationSettings = async () => {
    if (!checkNotificationSettingsRequirement(teamSettings)) {
      sb.trigger(t('at_least_one_user_must_receive_the_monthly_trade_emails'));
      return;
    }

    if (selectedAccount) {
      const request: AccountUsersEmailSettingsUpdateRequest[] = teamSettings.map((settings) => ({
        userId: settings.userId,
        enabled: settings.enabled,
        disabled: settings.disabled,
      }));

      try {
        setSubitting(true);
        await AccountSettingsService.postTeamEmailSettings(
          selectedAccount.id,
          request,
        );
        sb.trigger(t('communication_settings_saved'), 'success');
        logEvent({ action: 'Successfully saved team communication settings' });
        loadTeamNotificationSettings(selectedAccount.id);
      } catch (e) {
        sb.trigger(error?.message || t('could_not_update_communication_settings'));
        logError({ action: 'Error saving team communication settings' });
        console.error(e);
      } finally {
        setSubitting(false);
      }
    }
  };

  useEffect(() => {
    if (selectedAccount && canEditNotification) {
      loadTeamNotificationSettings(selectedAccount.id);
    }
  }, [selectedAccount, canEditNotification]);

  useEffect(() => {
    function jsonEqual(a: any, b: any): boolean {
      return JSON.stringify(a) === JSON.stringify(b);
    }
    const isOriginal = jsonEqual(teamSettings.map((a) => a.enabled.sort()),
      originalSettings.map((a) => a.enabled.sort()));
    setFormTouched(!isOriginal);
  }, [teamSettings, originalSettings]);

  if (canEditNotification) {
    return (
      <Box flexDirection="rows" display="flex" gridGap="10px">
        <Box>
          <IconButton
            onClick={() => { setExpanded(!expanded); }}
            style={{ marginTop: '16px', borderRadius: 0 }}
            size="small"
          >
            <FontAwesomeIcon
              className={expanded ? classes.iconDown : classes.iconRight}
              icon={faChevronDown as IconProp}
              size="sm"
            />
          </IconButton>
        </Box>
        <Box display="flex" gridGap="10px" flexDirection="column" flexGrow={1}>
          <Box display="flex" flexDirection="row" className={classes.stickySection}>
            <Box flexGrow={1}>
              <Typography variant="h6" className={classes.boxHeaderText}>
                <FontAwesomeIcon className="icon" icon={faMessageDots as IconProp} color="#1E8777" />
                <span>{t('communication_settings')}</span>
              </Typography>
              <Typography variant="subtitle1" className={classes.subTitleText}>
                {t('here_you_can_manage_what_confirmations_you_receive')}
              </Typography>
            </Box>
            <Collapse in={expanded}>
              <Box>
                <ActionButton
                  disabled={!formTouched || submitting}
                  loading={submitting}
                  onClick={() => { saveTeamNotificationSettings(); }}
                  style={{ borderRadius: '4px' }}
                >
                  {t('save')}
                </ActionButton>
              </Box>
            </Collapse>
          </Box>
          <Collapse in={expanded}>
            {teamSettings.length > 0 && (
              <Box>
                <Alert variant="info" text={t('please_note_that_at_least_one_user_must_receive_the_monthly_trade_emails')} />
              </Box>
            )}
            <TeamNotificationMatrixTable
              teamNotificationSettings={teamSettings}
              setTeamNotificationSettings={setTeamSettings}
              readonly={submitting}
            />
          </Collapse>
        </Box>
      </Box>
    );
  }
  return (
    <>
      {t('you_do_not_have_access_to_edit_team_notification_settings')}
    </>
  );
};
export default TeamNotificationSettings;
