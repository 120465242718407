import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  card: {
    minWidth: 500,
  },
  initiate: {
    backgroundColor: '#197fab',
  },
  error: {
    backgroundColor: '#b95656',
  },
  completed: {
    backgroundColor: '#1E8777',
  },
  wrapper: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: 'white',
    marginRight: '24px',
  },
  typography: {
    color: 'white',
    '& h3': {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '2px',
    },
    '& h6': {
      fontSize: '13px',
      fontWight: '200',
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loader: {
    color: 'white',
  },
  download: {
    color: 'white',
    fontWeight: 'bold',
    letterSpacing: '1.4px',
  },
  close: {
    color: 'white',
  },
}), { name: 'LoadingButton' });

export default useStyles;
