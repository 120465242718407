import React from 'react';

import { IconButton } from '@alpha/ui-lib/ui/button/IconButton';
import * as MaterialUi from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { TAuthyStatus } from '../../../store/authy/reducer';

import Body from './Body/Body';
import { useStyles } from './Modal.styles';

interface IModalProps {
  open: boolean;
  status: TAuthyStatus | undefined;
  authyPhoneNumber: string | undefined;
  onClose: () => void;
  handleComplete: (code: number) => void;
}
const Modal: React.FC<IModalProps> = ({
  open,
  authyPhoneNumber,
  status,
  onClose,
  handleComplete,
}: IModalProps) => {
  const classes = useStyles();
  return (
    <MaterialUi.Modal
      open={open}
      className={classes.container}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={MaterialUi.Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
      disableBackdropClick
    >
      <MaterialUi.Fade in={open}>
        <>
          <IconButton
            icon={faTimes as IconProp}
            onClick={onClose}
            className={classes.closeButton}
          />
          <Body
            handleComplete={handleComplete}
            authyPhoneNumber={authyPhoneNumber}
            status={status}
          />
        </>
      </MaterialUi.Fade>
    </MaterialUi.Modal>
  );
};

export default Modal;
