import React from 'react';
import clsx from 'clsx';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import * as MaterialUi from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMobile } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import appDownload from '../../assets/appDownload.png';
import AuthyIcon from '../../assets/authyIcon.png';
import iphoneAuthyApp from '../../assets/iphoneAuthyApp.svg';
import IconBadge from '../Atoms/IconBadge';

import useStyles from './index.styles';

interface IProps {
  open: boolean;
  phoneNumber: string;
  handleModalClose: () => void;
  handleSuccessButtonClick: () => void;
}

export const AuthSetupModal: React.FC<IProps> = ({
  open,
  phoneNumber,
  handleModalClose,
  handleSuccessButtonClick,
}: IProps) => {
  const styles = useStyles();

  return (
    <MaterialUi.Modal
      open={open}
      className={styles.container}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={MaterialUi.Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <MaterialUi.Fade in={open}>
        <div className={styles.wrapper}>
          <Typography className={styles.mainTitle}>{t('authy_setup')}</Typography>
          <div className={styles.step} style={{ justifyContent: 'space-between' }}>
            <div className={styles.downloadContainer}>
              <div className={styles.title}>{t('step_1_download_authy')}</div>
              <div className={styles.body}>
                <Typography variant="subtitle1">{t('please_download_the_app_to_continue')}</Typography>
                <div className={styles.appImages}>
                  <img src={appDownload} alt="Google Play and Apple Store icons" />
                </div>
              </div>
            </div>
            <IconBadge
              backgroundColor="#EC1C24"
              element={(
                <img
                  src={AuthyIcon}
                  alt="Authy icon"
                />
              )}
              className={styles.iconBadgeContainer}
            />
          </div>
          <div className={styles.step}>
            <div className={styles.body}>
              <div className={styles.title}>{t('step_2_register_your_device')}</div>
              <Typography variant="subtitle1">
                <FontAwesomeIcon icon={faMobile as IconProp} className={styles.icon} />
                {t('once_downloaded_please_register_with_your_phone_number')}
              </Typography>
              <Typography
                className={styles.phoneBackground}
                variant="subtitle1"
              >
                {phoneNumber}
              </Typography>
            </div>
          </div>
          <div className={styles.title} style={{ marginBottom: '32px' }}>
            {t('step_3_use_the_application_to_authenticate')}
          </div>
          <div className={styles.step}>
            <div className={clsx(styles.body, styles.flexRow)}>
              <div className={styles.authyPhoneImage}>
                <img src={iphoneAuthyApp} alt="Iphone displaying authy app" />
              </div>
              <div className={styles.paragraphContainer}>
                <Typography className={styles.topPara} variant="subtitle1">
                  {t('you_will_then_see_alpha_automatically_appear_within_the_authy_app')}
                </Typography>
                <Typography className={styles.lowerPara} variant="subtitle1">
                  {t('you_can_now_use_this_token_to_securely_login')}
                </Typography>
              </div>
            </div>
          </div>
          <ActionButton onClick={handleSuccessButtonClick} size="large">
            {t('i_have_completed_registration')}
          </ActionButton>
        </div>
      </MaterialUi.Fade>
    </MaterialUi.Modal>
  );
};

export default AuthSetupModal;
