import t from 'utils/translationHelper';

import { DrawdownDto } from '@alpha/fx-dtos';
import { TDrawdownStatSummaryData } from '@alpha/ui-lib/ui/StatSummary/DrawdownStatSummary';

import formatIsoDate from './formatIsoDate';

const mapDrawdownDtoToSummary = (drawdownDto?: DrawdownDto): TDrawdownStatSummaryData => {


  const summaryData: TDrawdownStatSummaryData = {
    tradeCurrencySell: drawdownDto?.soldCurrencyCode || 'N/A',
    tradeCurrencyBuy: drawdownDto?.buyCurrencyCode || 'N/A',
    buyAmount: drawdownDto?.buyAmount || 0,
    sellAmount: drawdownDto?.soldAmount || 0,
    rate: drawdownDto?.rate || 0,
    valueDate: formatIsoDate(drawdownDto?.valueDate) || 'N/A',
    valueDateText: t('value_date'),
    sellAmountText: t('sell_amount'),
    buyAmountText: t('buy_amount'),
    rateText: t('rate'),
  };

  return summaryData;
};

export default mapDrawdownDtoToSummary;
