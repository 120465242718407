import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        '& > div': {
          paddingRight: '5px',
        },
      },
    },
    tableCode: {
      display: 'inline-block',
      lineHeight: '15px',
    },
    tableIcon: {
      display: 'inline-block',
      margin: '0 8px 0 0',
      width: '15px',
      height: '15px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '100%',
    },
  }),
  { name: 'CurrencyPair' },
);
