import { ReactText } from 'react';

import { PayloadAction } from '@reduxjs/toolkit';

import { PageType } from '../../hooks/useReportsPolling';

export enum Actions {
  INITIATE_POLLING = 'INITIATE_POLLING',
  INITIATE_SYNCHRONOUS_DOWNLOAD = 'INITIATE_SYNCHRONOUS_DOWNLOAD',
}

export const initiatePolling = (
  id: string,
  executionArn: string,
  snackId: ReactText,
  pageType: PageType,
): PayloadAction<{
  executionArn: string;
  id: string;
  snackId: ReactText;
  pageType: PageType,
}> => ({
  type: Actions.INITIATE_POLLING,
  payload: {
    id, executionArn, snackId, pageType,
  },
});

export const initiateSynchronousDownload = (
  id: string,
  downloadLink: string,
  snackId: ReactText,
): PayloadAction<{
  id: string,
  downloadLink: string,
  snackId: ReactText,
}> => ({
  type: Actions.INITIATE_SYNCHRONOUS_DOWNLOAD,
  payload: {
    id,
    downloadLink,
    snackId,
  },
});

export default {
  initiatePolling,
};
