import React from 'react';

import { CurrencyPair } from '../../../../../../../components/International';

import useStyles from './TradeLinkContent.styles';

import t from 'utils/translationHelper';

interface IProps {
  tradeContractNumber: string;
  tradeSellCurrency: string;
  tradeId: string
}

const TradeLinkContent: React.FC<IProps> = (props: IProps) => {
  const {
    tradeContractNumber, tradeSellCurrency, tradeId,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.column}>
        <b>{t('trade_id')}</b>
        <span>{tradeContractNumber}</span>
      </div>
      <div className={classes.column}>
        <b>{t('sell_currency')}</b>
        <span>{tradeSellCurrency && (<CurrencyPair currencyCode={tradeSellCurrency} />)}</span>
      </div>
      <div className={classes.infoText}>
        <b>{t('click_on_icon_to_view_trade')}</b>
      </div>
    </div>
  );
};

export default TradeLinkContent;
