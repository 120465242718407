import { makeStyles } from '@alpha/ui-lib/theme';

export default makeStyles(
  ({ typography }) => ({
    statementCurrencySummary: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '35px 0',
      '& > div': {
        display: 'flex',
      },
    },
    statementCurrencySummaryRight: {
      '& > div': {
        '& > p:first-of-type': {
          textAlign: 'right',
        },
        '&:not(:last-of-type)': {
          marginRight: '50px',
        },
        '&:last-of-type': {
          '& > p:last-of-type': {
            fontWeight: typography.fontWeightBold,
          },
        },
      },
    },
    statementCurrencySummaryLeft: {
      '& > div': {
        '& > p:first-of-type': {
          textAlign: 'left',
        },
      },
    },
  }),
  { name: 'StatementsTable' },
);
