import React from 'react';

import { Stat } from '@alpha/ui-lib/ui/Stat';

import { TCurrencyStats } from '../../../models/currencyAccounts';
import { formatCurrency } from '../../../utils/currency.helpers';
import useStyles from '../CurrencyAccounts.styles';
import t from 'utils/translationHelper';

export const testIds = {
  totalAccounts: 'total-accounts',
  pendingTransactions: 'pending-transactions',
  clearedBalance: 'cleared-balance',
};

interface Props {
  totals: TCurrencyStats;
  currencyCode: string;
}

const CurrencyTotals: React.FC<Props> = ({ totals, currencyCode }: Props) => {

  const classes = useStyles();

  return (
    <>
      {/* <Stat
        testId={testIds.totalAccounts}
        data-testid="total-accounts"
        title="Total Accounts"
        value={totals.totalAccounts.toString()}
      /> */}
      <div className={classes.pullRight}>
        <Stat
          testId={testIds.pendingTransactions}
          data-testid="pending-transactions"
          title="Pending Transactions"
          value={formatCurrency(currencyCode, totals.pendingTransactions)}
          alignRight
        />
        <Stat
          testId={testIds.clearedBalance}
          data-testid="cleared-balance"
          title="Cleared Balance"
          value={formatCurrency(currencyCode, totals.clearedBalance)}
          alignRight
        />
        <Stat
          testId={testIds.clearedBalance}
          data-testid="available-balance"
          title={t('available_balance')}
          value={(
            <strong>
              {formatCurrency(currencyCode, totals.availableBalance)}
            </strong>
          )}
          alignRight
        />
      </div>
    </>
  );
};

export default CurrencyTotals;
