import React, { memo } from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';

import useStyles from './styles';

import { ValidateBeneficiaryRequest } from '@alpha/bene-dtos';
import isEmpty from 'utils/isEmpty';
import t from 'utils/translationHelper';


interface IProps {
  isFormValid: boolean;
  handleDrawerClose: () => void;
  updateDrawer?: boolean;
  addressLine1?: string;
  beneficiary?: ValidateBeneficiaryRequest
}

const Footer: React.FC<IProps> = ({ isFormValid, handleDrawerClose, updateDrawer, beneficiary }: IProps) => {

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <ActionButton
        onClick={handleDrawerClose}
        style={{ background: '#F7F7F7', color: '#212529' }}
        testId="cancelCreateBeneficiaryButton"
        size="medium"
      >
        {t('cancel')}
      </ActionButton>
      <ActionButton
        disabled={!isFormValid || (updateDrawer && (
          isEmpty(beneficiary?.addressLine1) ||
          isEmpty(beneficiary?.countryCode)
        ))}
        type="submit"
        size="medium"
        testId="submitCreateBeneficiaryButton"
      >
        {updateDrawer ? t('update_information') : t('submit_beneficiary_for_approval')}
      </ActionButton>
    </div >
  );
};

export default memo(Footer);
