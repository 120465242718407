import React, { useState } from 'react';

import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faFileExcel, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import t from 'utils/translationHelper';

import useReportsPolling, { FileType, PageType } from '../../../hooks/useReportsPolling';

interface IExportMenuProps {
  startDate: string;
  endDate: string;
  currencyAccountId: string;
  disabled?: boolean;
}
const ExportMenu: React.FC<IExportMenuProps> = (props: IExportMenuProps) => {
  const [open, setOpen] = useState<null | HTMLElement>(null);

  const {
    startDate, endDate, currencyAccountId, disabled,
  } = props;
  const { executeReportGeneration } = useReportsPolling(PageType.Statements);
  const handleReportGeneration = async (fileType: FileType) => {
    await executeReportGeneration(
      fileType,
      currencyAccountId,
      startDate,
      endDate,
      undefined,
      fileType,
    );
  };
  const menuItems = [
    {
      content: t('export_pdf'),
      icon: faFilePdf,
      onClick: () => handleReportGeneration(FileType.PDF),
      underline: true,
    },
    {
      content: t('export_excel'),
      icon: faFileExcel,
      onClick: () => handleReportGeneration(FileType.EXCEL),
    },
  ];
  return (
    <APMenu
      open={Boolean(open)}
      setOpen={setOpen}
      anchorEl={open}
      buttonProps={{ disabled }}
      buttonTitle={t('export')}
    >
      {
        menuItems.map((item) => <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>)
      }
    </APMenu>
  );
};

export default ExportMenu;
