import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    widget: {
      padding: '20px',
      background: '#ffffff',
      borderRadius: '10px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
      '@media (max-width: 400px)': {
        marginTop: '10px',
        gridColumn: '1/1 !important',
      },
      fontWeight: typography.fontWeightSemiBold,
      fontSize: '16px',
      height: '394px',
    },
    widgetTitle: {
      fontSize: '16px',
      fontWeight: 600,
    },
    mtmStat: {
      fontFamily: typography.fontFamilySecondary,
      fontSize: 14,
      padding: '16px 0px',
      marginLeft: 'auto',
    },
    mtmStatValuation: {
      paddingRight: '40px',
      fontSize: 14,
    },
    mtmStatsInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '20px',
      gap: '10px',
    },
    mtmStatsContainer: {
      '&.isCreditPage': {
        fontWeight: 600,
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    title: {
      display: 'inline',
      fontWeight: 100,
    },
    demo: {
      position: 'relative',
      '&::before': {
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        padding: '0',
        margin: '0',
        textAlign: 'center',
        verticalAlign: 'middle',
        background: 'rgba(255,255,255, 0.9)',
        zIndex: '2',
        content: '" "',
      },
      '&::after': {
        position: 'absolute',
        top: '49%',
        left: '0',
        bottom: '0',
        right: '0',
        padding: '0px 10px',
        textAlign: 'center',
        margin: 'auto',
        marginTop: '-20px',
        maxWidth: '300px',
        content: '"No data to display as this widget is not applicable."',
        whitespace: 'pre',
        zIndex: '3',
        color: '#666666',
        fontSize: '16px',
        fontWeight: '200',
      },
    },
    emptyTable: {
      position: 'relative',
      marginLeft: '5%',
      marginTop: '-40px',
      '& .image': {
        height: '120px',
        width: '120px',
        '& img': {
          height: '60',
          width: '60px',
        },
      },
    },
  }), { name: 'Widget' },
);

export default useStyles;
