import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  subHeading: {
    fontFamily: typography.fontFamilySecondary,
    fontSize: '12px',
    marginBottom: '18px',
    fontWeight: typography.fontWeightSemiBold,
  },
  subHeading2: {
    fontFamily: typography.fontFamilySecondary,
    fontSize: '12px',
    fontWeight: typography.fontWeightSemiBold,
    marginBottom: '1.5em',
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dropdown: {
    '& .MuiSelect-root': {
      backgroundColor: '#f7f7f7',
      height: '53px',
      borderRadius: '5px',
      padding: '0 16px',
      paddingRight: '40px',
      marginBottom: '6px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      right: '7px',
    },
  },
  detailTable: {
    padding: '10px',
    background: '#f7f7f7',
    borderRadius: '5px',

    '& .MuiTableBody-root tr': {
      borderBottom: 'none',
    },
    '& .MuiTableBody-root tr:nth-child(5)': {
      borderTop: '1px solid rgba(112, 112, 112, 0.1)',
      paddingTop: '12px',
      paddingBottom: '2px',
    },
    '& .MuiTableBody-root tr:first-child td:nth-child(2)': {
      color: '#1E8777',
      fontWeight: typography.fontWeightSemiBold,
    },

  },

}), { name: 'SettlementsStyles' });

export default useStyles;
