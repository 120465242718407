import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';
import { initiateTransfer } from 'store/authy/actions';
import { TAuthyState } from 'store/authy/reducer';
import { formatCurrency } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import { CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';

import useAlphaSnackbar from './useAlphaSnackbar';
import useLog from './useLog';

const useTransferApproval = (
  transferToApprove: CurrencyAccountTransferDto,
  onClose: () => void,
) => {

  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();
  const { logEvent, logError } = useLog();
  const [loading, setLoading] = useState<boolean>(false);
  const [failedTransfer, setFailedTransfer] = useState<CurrencyAccountTransferDto>();

  const authy = useSelector<TStore, TAuthyState>((store) => store.authy);
  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);

  const nonFinalApprovers = transferToApprove?.approvalInfo?.approvalsRequired.length ?? 0;

  const finalApprovers = transferToApprove?.approvalInfo?.approvals.length ?? 0;

  const onDrawerClose = () => {
    setLoading(false);
    onClose();
  };

  const submitApproval = async (): Promise<void> => {
    dispatch(initiateTransfer({
      transferId: transferToApprove.id,
      type: 'TRANSFER_APPROVE',
    }));
  };

  const handleApprovals = async (): Promise<void> => {
    setLoading(true);
    await submitApproval();
    setLoading(false);
  };

  const approvalData = [
    {
      key: t('approved_payments'),
      value: `${finalApprovers}` || '0',
    },
    {
      key: t('total_amount_to_be_approved'),
      value: `${formatCurrency(transferToApprove?.debitingCurrencyCode, transferToApprove.debitingAmount) || '0'}`,
    },
    {
      key: t('requiring_further_approval'),
      value: `${nonFinalApprovers}` || '0',
    },
  ];

  useEffect(() => {
    if (authy.status === 'SUCCESS' && authy.type?.type === 'TRANSFER_APPROVE') {
      onClose();
      sb.trigger(t('your_transfer_was_successfully_approved'), 'success');
      logEvent({ action: 'Succefully approved transfer' });
    } else if (authy.status === 'ERROR') {
      setTimeout(() => {
        setFailedTransfer(transferToApprove);
        logError({ action: 'Error approving transfer', detail: { transfer: transferToApprove.contractNumber } });
      }, 1500);
    }
  }, [authy.status]);

  return {
    userId,
    nonFinalApprovers,
    finalApprovers,
    loading,
    failedTransfer,
    approvalData,
    setFailedTransfer,
    setLoading,
    handleApprovals,
    onDrawerClose,
  };
};

export default useTransferApproval;
