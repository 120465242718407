import React from 'react';
import t from 'utils/translationHelper';
import { useMutation } from 'react-query';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import Warning from '../../../../assets/warning.svg';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import instance from '../../../../services/Axios/instance';
import APModal from '../../../Modals/APModal/APModal';

import useStyles from './index.styles';

type Props = {
  tradeId: string;
  open: boolean;
  handleClose: () => void;
  handleReloadTradesTable?: () => void;
  handleReloadPeriodsData?: () => void;
}

const DeleteExternalTradeModal: React.FC<Props> = ({
  open, tradeId, handleClose, handleReloadTradesTable, handleReloadPeriodsData,
}: Props) => {

  const styles = useStyles();
  const { logError, logEvent } = useLog();
  const sb = useAlphaSnackbar();

  const deleteMutation = useMutation((_tradeId: string) => instance.delete(`/fx/external-trades/${_tradeId}`), {
    onSuccess: () => {
      sb.trigger(t('successfully_deleted_trade'), 'info');
      logEvent({ action: 'Successfully deleted draft trade' });
      handleClose();
      if (handleReloadPeriodsData) handleReloadPeriodsData();
      setTimeout(() => {
        if (handleReloadTradesTable) handleReloadTradesTable();
      }, 1000);
    },
    onError: (e) => {
      sb.trigger(t('there_was_an_error_deleting_this_trade'), 'error');
      logError({ action: 'Error deleting trade', error: e });
    },
  });
  return (
    <APModal.Container open={open} onClose={handleClose} disableBackdropClick>
      <APModal.Wrapper className={styles.container}>
        <div className={styles.innerContainer}>
          <img src={Warning} className={styles.warningStyle} alt="Warning" />
          <div style={{ marginBottom: '48px' }}>
            <Typography className={styles.headerText}>
              {t('are_you_sure_you_want_to_remove')}
            </Typography>
            <Typography>{t('this_action_cannot_be_reversed')}</Typography>
          </div>
          <div style={{ width: '100%' }}>
            <ActionButton size="medium" disabled={deleteMutation.isLoading} style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }} onClick={handleClose}>{t('cancel')}</ActionButton>
            <ActionButton size="medium" disabled={deleteMutation.isLoading} loading={deleteMutation.isLoading} onClick={() => deleteMutation.mutate(tradeId)}>{t('delete_trade')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default DeleteExternalTradeModal;
