import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import useSwitchAccount from 'hooks/useSwitchAccount';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapTradeTypeToFriendlyName from 'utils/fxTrades/mapTradeTypeToFriendlyName';
import t from 'utils/translationHelper';

import { TradeDto } from '@alpha/fx-dtos';
import { Box, Button } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { datadogLogs } from '@datadog/browser-logs';

import { OutstandingActionsContext } from '..';
import ActionDropDown from '../../../../../Transactions/Dashboard/FxTradeTable/DisplayTable/ActionDropDown/ActionDropDown';
import useFxTradeTable from '../../../../../Transactions/Dashboard/FxTradeTable/useFxTradeTable';
import useStyles from '../index.styles';
import useOutstandingActionCache from '../useOutstandingActionCache';

const TradeApprovals: React.FC = () => {
  const classes = useStyles();
  const { pendingTradesCount, setPendingTradesCount } = useContext(OutstandingActionsContext);

  const { currentAccount } = useSwitchAccount();
  const CACHE_KEY = `${currentAccount.id}-pending-trades`;
  const SHOW_LIMIT = 5;
  const [
    pendingTrades,
    setPendingTrades,
  ] = useState<TradeDto[]>([]);
  const { saveResponse, getResponse } = useOutstandingActionCache();
  const {
    handleTradeNumberClick,
  } = useFxTradeTable();

  const loadPendingTrades = async () => {
    try {
      const { total, records: trades } = await FXTradeService.getPendingTrades();
      setPendingTrades(trades);
      if (setPendingTradesCount) { setPendingTradesCount(total); }
      saveResponse(CACHE_KEY, trades);
      saveResponse(`${CACHE_KEY}_count`, total);
    } catch (e) {
      datadogLogs.logger.error(e);
    }
  };

  useEffect(() => {
    try {
      const cachedTrades = getResponse<TradeDto[]>(CACHE_KEY);
      const cachedCount = getResponse<number>(`${CACHE_KEY}_count`);
      if (cachedTrades) {
        setPendingTrades(cachedTrades as TradeDto[]);
      }
      if (cachedCount) {
        if (setPendingTradesCount) { setPendingTradesCount(cachedCount); }
      }
    } finally {
      loadPendingTrades();
    }
  }, []);

  const columns: IStyledGenericTableProps['columns'] = [
    {
      header: t('trade_no'),
      accessor: 'contractNumber',
      width: 100,
    },
    {
      header: t('trade_type'),
      accessor: 'tradeType',
      align: 'left',
    },
    {
      header: t('trade_date'),
      accessor: 'tradeDate',
      align: 'left',
    },
    {
      header: t('sold_amount'),
      accessor: 'soldAmount',
      align: 'right',
    },
    {
      header: t('rate'),
      accessor: 'rate',
      align: 'left',
      width: 100,
    },
    {
      header: t('buy_amount'),
      accessor: 'buyAmount',
      align: 'right',
    },
    {
      header: t('actions'),
      accessor: 'actions',
      align: 'right',
    },
  ];

  const data: IStyledGenericTableProps['data'] = useMemo(
    (): IStyledGenericTableProps['data'] => pendingTrades.slice(0, SHOW_LIMIT).map((trade) => ({
      ...trade,
      contractNumber: (
        <APTooltip
          placement="bottom-start"
          arrow
          title="Click to open trade details page"
        >
          <Button
            disableRipple
            className={classes.tableButton}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              if (handleTradeNumberClick) { handleTradeNumberClick(trade.id); }
            }}
          >
            {trade.contractNumber || '-'}
          </Button>

        </APTooltip>),
      tradeType: mapTradeTypeToFriendlyName(trade.type) || '-',
      tradeDate: formatIsoDate(trade.date),
      rate: <span className={classes.tableLink}>{trade.rate?.toFixed(4)}</span> || '-',
      buyAmount: (
        <div className={classes.amount}>
          <div>{[formatNumber(trade.buyAmount || 0, 2), trade.buyCurrencyCode].join(' ')}</div>
          <div className={classes.currencyFlag}><Flag code={trade.buyCurrencyCode} size="sm" /></div>
        </div>
      ),
      soldAmount: (
        <div className={classes.amount}>
          <div>{[formatNumber(trade.soldAmount || 0, 2), trade.soldCurrencyCode].join(' ')}</div>
          <div className={classes.currencyFlag}><Flag code={trade.soldCurrencyCode} size="sm" /></div>
        </div>),
      actions: (
        <ActionDropDown
          trade={trade}
          setDisplayMultiSelect={() => { }}
          displayMultiSelect={false}
          handleReloadTradesTable={undefined}
          showCancelTrade
        />
      ),
    })), [pendingTrades],
  );

  return (
    <Box style={{
      overflow: 'auto',
      height: '230px',
      position: 'relative',
    }}
    >
      {data.length > 0 ? (
        <>
          <StyledGenericTable
            className={classes.stickyHeaderTable}
            testId="upcoming-trades-table"
            columns={columns}
            data={data}
            stickyHeader
          />
          {pendingTradesCount && pendingTradesCount > SHOW_LIMIT ? (
            <div className={classes.showAllMessage}>
              <span>
                {t('showing_top')}
                {' '}
                {SHOW_LIMIT}
                {' '}
                {t('items, please click view all to view all items.')}
              </span>
            </div>
          ) : null}
        </>
      ) : (
        <EmptyTable
          title={t('no_pending_trades')}
          subtitle={t('no_trades_awaiting_approval')}
          className={classes.emptyTable}
          icon={clipboardCross}
        />
      )}
    </Box>
  );
};
export default TradeApprovals;
