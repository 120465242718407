import moment from 'moment';
import React from 'react';
import t from 'utils/translationHelper';

import { Typography } from '@alpha/ui-lib/ui/Typography';

import DragAndDrop from '../../../../assets/paymentUpload.svg';
import { formatCurrency } from '../../../../utils/currency.helpers';
import { cutString } from '../ApprovalDrawer/ApprovalSummary';
import { FormikType } from '../CreatePaymentDrawer/formData';

type Props = {
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  values: FormikType;
};

const Summary: React.FC<Props> = ({ values, setFieldValue }) => {

  if (!values.payments?.length) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        paddingTop: '20px',
      }}
      >
        <div style={{
          textAlign: 'center', width: '328px', height: '243px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <div style={{
            width: '165px',
            height: '165px',
            backgroundColor: 'rgba(222, 222, 222,0.20)',
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <img src={DragAndDrop} alt="Empty payments" />
          </div>
          <Typography variant="h3" style={{ marginTop: '24px', marginBottom: '8px' }}>{t('no_payments_added')}</Typography>
          <Typography variant="subtitle1" style={{ color: 'rgba(33,37,41,0.4)' }}>{t('you_can_add_a_payment_from_the_add_payments_tab')}</Typography>
        </div>
      </div>
    );
  }

  const rows = values.payments.map((p) => ({
    beneficiaryName: p.beneficiary!.name,
    amount: +p.amount!,
    reference: p.reference,
    purpose: p.purpose,
    date: p.date,
    currencyCode: p.beneficiary!.currencyCode,
  }));

  return (
    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <tr>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('beneficiary')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('amount')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('reference_(payment_summary)')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('purpose')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('date')}
        </td>
      </tr>
      <tr style={{ borderBottom: '1px solid rgba(112,112,112,0.3)', height: '8px' }} />
      <tr style={{ height: '16px' }} />
      {rows.map((r, i) => (
        <>
          {i > 0 && (
            <>
              <tr style={{ borderBottom: '1px solid rgba(112,112,112,0.3)', height: '8px' }} />
              <tr style={{ height: '8px' }} />
            </>
          )}
          <tr>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(r.beneficiaryName)}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(formatCurrency(r.currencyCode, r.amount))}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(r.reference)}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>
              {cutString((
                typeof r.purpose === 'string' ? r.purpose?.toString()
                  : r.purpose?.description))}
            </td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(moment(r.date!).format('DD/MM/YY').toString())}</td>
          </tr>
        </>
      ))}
    </table>
  );
};

export default Summary;
