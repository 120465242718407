import { useState } from 'react';
import { FormikProps } from 'formik';

import { Currency } from '@alpha/bene-dtos';
import { AllowedCurrenciesDto } from '@alpha/fx-dtos';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import FXTradeService from '../../../../services/FXTrade/fxTrade.service';
import { IOption } from '../../../AutocompleteDropDown/AutocompleteDropDown';

import { FormikType } from './formData';

export const useCreatePaymentDrawer = (
  form: FormikProps<FormikType>,
) => {
  const { logError } = useLog();
  const [allowedBuyCurrencies, setAllowBuyCurrencies] = useState<Currency[]>([]);
  const [allowedSellCurrencies, setAllowSellCurrencies] = useState<Currency[]>([]);

  const [
    currentSellCurrencyOption,
    setCurrentSellCurrencyOption,
  ] = useState<IOption>({ name: '', code: '' });

  const [
    currentBuyCurrencyOption,
    setCurrentBuyCurrencyOption,
  ] = useState<IOption>({ name: '', code: '' });

  const [
    allowedSellCurrenciesOption,
    setAllowedSellCurrenciesOption,
  ] = useState<IOption[]>([]);

  const [
    allowedBuyCurrenciesOption,
    setAllowedBuyCurrenciesOption,
  ] = useState<IOption[]>([]);

  const sb = useAlphaSnackbar();

  const getAvailableCurriencies = async (): Promise<void> => {
    try {
      const allowCurrencies: AllowedCurrenciesDto = await FXTradeService.getAllowedFXCurrencies();
      setAllowBuyCurrencies(allowCurrencies.buyAllowed);
      setAllowedBuyCurrenciesOption(allowCurrencies.buyAllowed);
      setAllowSellCurrencies(allowCurrencies.sellAllowed);
      setAllowedSellCurrenciesOption(allowCurrencies.sellAllowed);
    } catch (error) {
      sb.trigger(error?.message || 'Failed to load available currencies.', 'error');
      logError({ action: 'Error loading available currencies' });
    }
  };

  return {
    allowedBuyCurrencies,
    setAllowBuyCurrencies,
    allowedSellCurrencies,
    setAllowSellCurrencies,
    currentSellCurrencyOption,
    setCurrentSellCurrencyOption,
    currentBuyCurrencyOption,
    setCurrentBuyCurrencyOption,
    allowedSellCurrenciesOption,
    setAllowedSellCurrenciesOption,
    allowedBuyCurrenciesOption,
    setAllowedBuyCurrenciesOption,
    getAvailableCurriencies,
  };
};

export default useCreatePaymentDrawer;
