import { Loader } from '@alpha/ui-lib/ui/Loader';
import APPagination from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination';
import { Card, Collapse, IconButton } from '@alpha/ui-lib/ui/external';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faExclamationCircle, faList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import { t } from 'i18next';
import React, { useState } from 'react';
import useStyles from './ExportsTable.styles';
import useExportsTable from './useExportsTable';

interface IProps {
  loading: boolean;
  apiData: any;
  setPageNumber: Function
  pageNumber: number
}

const ExportsTable: React.FC<IProps> = ({ loading, apiData, setPageNumber, pageNumber }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<boolean>(true)
  const { columns, data: mappedData } = useExportsTable(apiData, pageNumber)


  return (
    <Card className={classes.card}>
      <div>
        <div style={{ display: 'inline-block', float: 'left' }}>
          <div className={classes.titleContainer}>
            <FontAwesomeIcon icon={faList} />
            <div style={{ display: 'inline-block', marginLeft: '8px' }}>
              {t('exports')}
            </div>
          </div>
          <div className={classes.subtitle}>
            {t('list_of_all_your_mass_report_exports_&_download_links')}
          </div>
        </div>
        <div className={classes.infoCard}>
          <span style={{ fontWeight: 600, marginRight: '8px' }}>
            <FontAwesomeIcon icon={faExclamationCircle} style={{ marginRight: '8px' }} />
            {t('please_note')}
          </span>
          {t('download_links_will_expire_after_12_hours')}.
        </div>
        <div style={{ display: 'inline-block', float: 'right', marginTop: '0px' }}>
          <IconButton
            data-testid="expandButton"
            onClick={() => { setExpanded(!expanded); }}
            size="medium"
          >
            <FontAwesomeIcon
              className={expanded ? classes.iconDown : classes.iconRight}
              icon={faChevronDown as IconProp}
              size="sm"
            />
          </IconButton>
        </div>
      </div>
      <div style={{ marginTop: '2px' }}>
        <Collapse in={expanded}>
          {loading ? (<Loader testId={'exports-table-loader'} />) : (
            <>
              {mappedData?.length ? (
                <>
                  <StyledGenericTable testId={'exports-table'} columns={columns} data={mappedData} />
                </>
              ) : (
                <EmptyTable
                  title={t('no_exports_available')}
                  subtitle={t('if_incorrect_contact_dealer')}
                  icon={clipboardCross}
                />
              )}
              <div style={{ marginTop: '16px' }}>
                <div style={{ display: 'inline-block' }}>
                  {t('showing')}
                  {' '}
                  {((pageNumber) * (20)) + 1}
                  {' '}
                  -
                  {' '}
                  {((pageNumber) * 20) + mappedData.length}
                  {' '}
                  {t('of')}
                  {' '}
                  {apiData.length}
                </div>
                <div style={{ display: 'inline-block', float: 'right', marginTop: '-3px' }}>
                  <APPagination
                    handleNext={() => {
                      setPageNumber(pageNumber + 1)
                    }}
                    handlePrevious={() => {
                      setPageNumber(pageNumber - 1)
                    }}
                    hasNext={((pageNumber + 1) * 20) < apiData.length}
                    hasPrevious={pageNumber !== 0}
                  />
                </div>
              </div>
            </>
          )}
        </Collapse>
      </div>
    </Card >
  )
};

export default ExportsTable;
