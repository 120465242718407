import React from 'react';
import clsx from 'clsx';

import { DrawdownDto } from '@alpha/fx-dtos';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';
import t from 'utils/translationHelper';
import mapDrawdownStatusDisplay from '../../../../../../../utils/fxTrades/mapDrawdownStatusDisplay';

import useStyles from './HeaderText.styles';

interface IProps {
  selectedDrawdown: DrawdownDto | undefined;
}

const HeaderText: React.FC<IProps> = (props: IProps) => {

  const classes = useStyles();
  const {
    selectedDrawdown,
  } = props;
  return (
    <Box display="flex" flexDirection="column">
      <Typography className={clsx(classes.title, classes.Light)}>
        {t(selectedDrawdown?.contractNumber || '-')}
      </Typography>
      <Typography className={clsx(classes.subtitle, classes.SemiBold)}>
        {t('drawdown')}
      </Typography>
      <div className={classes.status}>
        <Status variant={selectedDrawdown?.status ? mapDrawdownStatusDisplay(selectedDrawdown?.status).variant : 'default'}>
          {selectedDrawdown?.status ? t(mapDrawdownStatusDisplay(selectedDrawdown?.status).text) : '-'}
        </Status>
      </div>
    </Box>
  );
};

export default HeaderText;
