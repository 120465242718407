import axios from 'axios';

import { datadogRum } from '@datadog/browser-rum';

import environmentVars from '../../env.variables';

import NetworkError from './NetworkError';

/**
 * @description This instance is to connect to API Gateway without authentication (Login API)
 */
export const publicInstance = axios.create({
  baseURL: environmentVars.REACT_APP_API_URL,
});

publicInstance.interceptors.response.use((response) => response, (error) => {
  datadogRum.addError(error);

  if (error.message === 'Network Error') {
    throw new NetworkError('Network Error. Please check your connection.');
  }
  throw error;
});

export default publicInstance;
