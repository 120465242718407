import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      '& > .MuiPaper-root': {
        width: '65%',
        maxWidth: '800px',
      },
      '& > div > div:first-of-type': {
        paddingTop: '70px',
      },
      '& .stepper': {
        '& h6': {
          color: '#a0a0a0',
          fontSize: '16px',
          marginTop: '16px',
        },
        '& .active': {
          color: 'black',
        },
      },
    },
    tableWrapper: {
      '& .MuiTableCell-root': {
        'padding-right': '10px !important',
        'padding-left': '10px !important',
      },
      '& th.MuiTableCell-head': {
        color: '#9A9A9A',
      },
      '& td.MuiTableCell-root': {
        fontSize: '13px',
      },
      '@media (max-width: 1200px)': {
        '& .MuiTableCell-root': {
          'padding-right': '10px !important',
          'padding-left': '10px !important',
          fontSize: '13px',
        },
        '& th.MuiTableHead-root, & td.MuiTableCell-root': {
          fontSize: '12px',
          '& span': {
            fontSize: '11px',
          },
        },
      },
      '& td': {
        padding: '0px 0px !important',
      },
    },
  }),
  { name: 'ApprovalDrawer' },
);

export default useStyles;
