import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  (theme) => ({
    tableWrapper: {
      padding: '0px 30px 10px 30px',
      '& .MuiTableCell-root': {
        'padding-right': '20px !important',
      },
      '@media (max-width: 1200px)': {
        '& .MuiTableCell-root': {
          'padding-right': '10px !important',
        },
        '& th.MuiTableHead-root, & td.MuiTableCell-root': {
          fontSize: '12px',
          '& span': {
            fontSize: '11px',
          },
        },
      },
      '& td': {
        padding: '10px 0px !important',
      },
    },
    amount: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    tableLink: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    sectionHeader: {
      fontSize: 16,
      weight: theme.typography.fontWeightSemiBold,
      marginBottom: '30px',
    },
    programName: {
      color: '#808080',
      marginLeft: '4px',
    },
    emptyTable: {
      height: '300px',
      position: 'relative',
      marginBottom: '41px',
    },
  }), { name: 'FxSummaryTable' },
);

export default useStyles;
