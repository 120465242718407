import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 8px !important',
  },
  modalHeader: {
    padding: '12px 24px 24px 24px',
    fontWeight: 600,
    fontSize: '18px',
  },
  inputContainer: {
    padding: '40px 24px 40px 12px',
    fontWeight: 400,
    fontSize: '14px',
  },
  greyText: {
    color: 'rgba(33, 37, 41, 0.4)',
  },
  footer: {
    padding: '27px 10px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(112, 112, 112, .1)',
  },
  radioButtons: {
    paddingTop: '30px',
    fontWeight: 600,
    paddingBottom: '20px',
  },
  icons: {
    borderRadius: '4px',
  },
  labels: {
    padding: '8px',
  },
  graphInfo: {
    paddingTop: '5px',
    display: 'flex',
  },
  colorLegend: {
    width: '32px',
    height: '32px',
    margin: '10px',
    marginLeft: '0px',
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
  },
  title: {
    fontSize: '14px',
    lineHeight: '28px',
    margin: '0 0 8px 0',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}), { name: 'InfoModal' });

export default useStyles;
