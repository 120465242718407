import React, { memo } from 'react';

import { Flag } from '@alpha/ui-lib/ui/Flag';

import useStyles from './CurrencyHeader.styles';

interface IProps {
  currencyCode: string;
  currency: string;
  account?: string;
}

const CurrencyHeader: React.FC<IProps> = (props: IProps) => {
  const { currencyCode, currency, account } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tableIcon}>
        <Flag code={currencyCode} size="lg" />
      </div>
      <div className={classes.info}>
        <p className={classes.currencyCode}>
          {!account
            ? <span className={classes.uppercase}>{currencyCode}</span>
            : <span>{account}</span>}
        </p>
        <p className={classes.currencyName}>{currency}</p>
      </div>
    </div>
  );
};

export default memo(CurrencyHeader);
