import t from 'utils/translationHelper';
import { FormikProps } from 'formik';
import * as yup from 'yup';

import { DrawdownFundingMethod, DrawdownRequest, DrawdownSubmissionRequest } from '@alpha/fx-dtos';

import { FormikPaymentType } from '../../Payments/CreatePaymentDrawer/formData';

type Modify<T, R> = Omit<T, keyof R> & R;
export type DrawdownRequestForm = Modify<DrawdownRequest, {
  valueDate: string | null
}>

export const initialValues: DrawdownRequestForm = {
  tradeId: '',
  fixCurrencyCode: '',
  valueDate: null,
  amount: 0,
  fundingMethod: DrawdownFundingMethod.CURRENCY_ACCOUNT_BALANCE,
  sellCurrencyCode: '',
  buyCurrencyCode: '',
  rate: 0,
};

export const drawdownSubmissionInitialValues: DrawdownSubmissionRequest = {
  paymentIds: [],
  dynamicLinkingId: '',
};

export const paymentsInitialValues: FormikType = {
  payments: [],
};

export type FormikValueProps = FormikProps<FormikType>;
export type FormikType = {
  payments?: FormikPaymentType[];
}

const replaceCommaSeperator = (o: string, v: string) => parseFloat(v ? v.replace(/,/g, '') : '');

export const validation = {
  amount: yup
    .number().transform(replaceCommaSeperator)
    .typeError(t('please_enter_numbers_only'))
    .required(t('please_enter_a_valid_amount'))
    .positive(t('please_enter_a_positive_amount')),
  fixCurrencyCode: yup.string().required(t('please_select_a_currency')),
  valueDate: yup.string().required(t('please_specify_a_value_date')),
};

export const submissionformValidation = {
  paymentIds: yup.array().typeError(t('please_enter_an_id_array')),
};

export default validation;
