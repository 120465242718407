import React, { useEffect, useRef, useState } from 'react';
import t from 'utils/translationHelper';

import { ProgramDto, ProgramPeriodDto } from '@alpha/fx-dtos';
import { APHedgingProgramBarChart, THedgingChartType } from '@alpha/ui-lib/ui/Chart/APHedgingProgramBarChart';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { RadioButtonGroup } from '@alpha/ui-lib/ui/RadioButton';

import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import { TProgramImage } from '../../../services/HedgingPrograms/hedgingPrograms.interfaces';

import useStyles from './HedgingProgramChart.styles';

interface IProps {
  program: ProgramDto | undefined;
  periods: ProgramPeriodDto[];
  handleEditForecast?: (id: string) => void;
  handleBarClick?: (index: number, datasetIndex?: number) => void;
  loading?: boolean;
  setProgramImages: React.Dispatch<React.SetStateAction<TProgramImage[]>>;
}

const HedgingProgramChart = ({
  program, periods, handleEditForecast, handleBarClick, loading, setProgramImages,
}:
  IProps) => {

  const styles = useStyles();
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartType, setChartType] = useState<THedgingChartType>('amount');

  const chartWidth = () => {
    if (periods) {
      return (Math.max(600, periods.length >= 8 ? periods.length * 160 : periods.length * 240));
    }
    return 600;
  };

  const getProgramImageProperties = () => {
    const chartEl = document.getElementById('hedging-programs-chart') as HTMLCanvasElement;
    if (chartEl) {
      const image = chartEl.toDataURL('image/png', 1.0);
      setProgramImages(
        [{ encodedImage: image, imageHeight: chartEl.height, imageWidth: chartEl.width }],
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (chartRef && periods) {
        getProgramImageProperties();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [chartRef, periods]);

  if (loading) {
    return (
      <div style={{ height: 600 }}>
        <BackdropLoader testId="hedging-program-chart-loader" style={{ position: 'relative' }} />
      </div>
    );
  }

  const showChart = periods.length > 0;

  return (
    <>
      <Box hidden={!showChart}>
        <Box justifyContent="space-between" flexDirection="row" alignContent="space-between" display="flex" style={{ marginBottom: '20px' }}>
          <Typography className={styles.sectionHeader}>
            <span>{t('hedging_program')}</span>
            {' '}
            <span className={styles.programName}>{program?.programName}</span>
          </Typography>
          <div className={styles.toggleContainer}>
            <RadioButtonGroup
              testId="hedging-chart-type-toggle"
              onChange={(event) => {
                setChartType(event.target.value as THedgingChartType);
              }}
              value={chartType}
              variant="chip"
              className={styles.toggleButton}
              items={[
                {
                  label: t('amount'), icon: undefined, value: 'amount', testId: 'chart-type-amount',
                },
                {
                  label: t('percentage'),
                  icon: undefined,
                  value: 'percentage',
                  testId: 'chart-type-percentage',
                },
              ]}
            />
          </div>
        </Box>
        <div
          style={{
            overflowX: 'auto',
            overflowY: 'hidden',
            position: 'relative',
            margin: 'auto',
            maxWidth: chartWidth(),
          }}
          ref={chartRef}
        >
          <APHedgingProgramBarChart
            id="hedging-programs-chart"
            type={chartType}
            data={periods}
            text={{
              actuals: t('actuals'),
              overhedged: t('overhedged'),
              underhedged: t('underhedged'),
              forecasted: t('forecasted'),
              program: t('program'),
              editForecast: t('edit_forecast'),
            }}
            editCallback={(id: string): void => {
              if (handleEditForecast) { handleEditForecast(id); }
            }}
            currencyCode={program?.currencyCode || ''}
            width={chartWidth()}
            height={600}
            style={{ minWidth: 0 }}
            onBarClick={handleBarClick}
          />
        </div>
      </Box>
    </>
  );
};

export default HedgingProgramChart;
