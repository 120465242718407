import { call, put, takeLatest } from 'redux-saga/effects';

import { UserNotificationsResponseDto } from '@alpha/notifications-dtos';

import env from '../../env.variables';
import { apiRequest, TAPIRequestsResult } from '../requestSaga';

import { actions } from './clientNotifications.reducer';
import { datadogLogs } from '@datadog/browser-logs';

export const loadClientNotificationsRequestName = 'clientNotifications';
export function* loadClientNotificationsSaga() {
  try {
    const result: TAPIRequestsResult<UserNotificationsResponseDto> = yield call(apiRequest, {
      config: {
        method: 'GET',
        url: `${env.REACT_APP_API_URL}notifications`,
      },
      name: loadClientNotificationsRequestName,
    });
    yield put(actions.loaded(result.data));
  } catch (e) {
    datadogLogs.logger.error(e);
  }
}

export function* clientNotificationsSaga() {
  yield takeLatest(actions.load.type, loadClientNotificationsSaga);
}
