import { createContext } from 'react';
import { FormikProps } from 'formik';

import { FormikPaymentType } from '../../CreatePaymentDrawer/formData';

export type PaymentContextType = FormikProps<FormikPaymentType> & {
     currencyCode: string
     currency: string
} | undefined;

const PaymentContext = createContext<PaymentContextType>(undefined);

export default PaymentContext;
