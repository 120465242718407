import useLog from 'hooks/useLog';
import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import MenuDropdownItem, { IMenuItem } from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Button, Menu, Typography } from '@alpha/ui-lib/ui/external';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { StyledTabsWrapper, TabContent } from '@alpha/ui-lib/ui/Tabs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAccountSettings from '../../hooks/useAccountSettings';
import useProfile from '../../hooks/useProfile';
import useSwitchAccount from '../../hooks/useSwitchAccount';
import { UserRole } from '../../models/user';

import useStyles from './index.styles';
import MassReporting from './MassReporting/MassReporting';
import NewUserRequestModal from './NewUserRequestModal/NewUserRequestModal';
import PersonalSettings from './PersonalSettings/PersonalSettings';
import RightAlignedCta from './RightAlignedCTA/RightAlignedCTA';
import TeamSettings from './TeamSettings/TeamSettings';

const AccountSettings: React.FC = () => {

  const classes = useStyles();
  const tabs: string[] = [t('personal_settings'), t('team_settings'), t('mass_reporting')];
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [openNewuserModal, setOpenNewUserModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = React.useState<IMenuItem[]>([]);
  const [selectedAccount, setSelectedAccount] = React.useState<AccountDto>();
  const [userAccounts, setUserAccounts] = React.useState<AccountDto[]>();
  const { userProfile, loading: profileLoading } = useProfile();
  const [accountsLoading, setAccountsLoading] = useState<boolean>(true)

  const handleTabClick = (value: number): void => {
    setSelectedTabIndex(value);
  };

  const {
    getUserAccounts,
    currentAccount,
    homePageUrl,
  } = useSwitchAccount();

  const { logError } = useLog();
  const { hasPermission } = useAccountSettings();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchAdminAccount = (account: AccountDto) => {
    setSelectedAccount(account);
  };

  const loadAccountMenu = async () => {
    try {
      setAccountsLoading(true);
      const allUserAccounts = await getUserAccounts();
      const accountItems = allUserAccounts.map((
        account: AccountDto,
        i: number,
        row: AccountDto[],
      ) => ({
        content: account.name,
        icon: undefined,
        arrow: false,
        onClick: () => switchAdminAccount(account),
        underline: !(i + 1 === row.length),
        id: account.id,
      }));
      setMenuItems(accountItems);
      setUserAccounts(allUserAccounts);
      setSelectedAccount(currentAccount);
      setAccountsLoading(false);
    } catch (e) {
      logError({ action: 'Error loading user accounts', error: e });
    }
  };

  useEffect(() => {
    loadAccountMenu();
  }, []);

  const accountMenu = (
    <>
      <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        className={classes.accountDropDownButton}
        endIcon={(
          <FontAwesomeIcon
            icon={faAngleDown as IconProp}
            size="xs"
            fontSize={10}
            color="#484848"
          />
        )}
      >
        {selectedAccount?.name}
      </Button>
      <Menu
        open={Boolean(open)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        {menuItems.map((item) => (
          <MenuDropdownItem
            setOpen={setAnchorEl}
          >
            {item}
          </MenuDropdownItem>
        ))}
      </Menu>
    </>
  );

  return (
    <div className={classes.accountSettingsPageMainOverride}>
      <APMainLayout
        height="172px"
        data-id="account-settings"
        sticky
        title={(
          <Typography className={classes.accountTitle}>
            {t('entity_settings')} |
            {' '}
            {accountMenu}
          </Typography>
        )}
        breadCrumbs={breadcrumbs}
        pageTabs={(
          <StyledTabsWrapper
            testId="account-settings-tabs"
            tabTitles={tabs}
            tabIndex={selectedTabIndex}
            handleChange={handleTabClick}
          />
        )}
        rightAlignedCta={(
          <RightAlignedCta
            handleOpenNewUserModal={() => setOpenNewUserModal(true)}
            tab={selectedTabIndex}
            selectedAccount={selectedAccount}
          />
        )}
      >
        <div className={classes.container}>
          <TabContent index={0} value={selectedTabIndex}>
            <PersonalSettings
              user={userProfile}
              loading={profileLoading}
              selectedAccount={selectedAccount}
            />
          </TabContent>
          <TabContent index={1} value={selectedTabIndex}>
            <TeamSettings
              selectedAccount={selectedAccount}
              canEditNotification={selectedAccount ? hasPermission(
                UserRole.USER_EMAIL_NOTIFICATION_MANAGER,
                selectedAccount.id,
              ) : false}
              canEditUserPermission={selectedAccount ? hasPermission(
                UserRole.USER_PERMISSION_MANAGER,
                selectedAccount.id,
              ) : false}
            />
          </TabContent>
          <TabContent index={2} value={selectedTabIndex}>
            <MassReporting userAccounts={userAccounts} loading={accountsLoading} />

          </TabContent>
        </div>

        <NewUserRequestModal
          open={openNewuserModal}
          handleClose={() => { setOpenNewUserModal(false); }}
          selectedAccount={selectedAccount}
          handleUserAdded={() => {
            // Updating selectedAccount to trigger settings reload
            setSelectedAccount({ ...selectedAccount } as AccountDto);
          }}
        />
      </APMainLayout>
    </div>
  );
};

export default AccountSettings;
