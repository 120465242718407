import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import routes from '../../../../routes.path';
import { IAuthProps } from '../..';
import Login from '../../Login';
import SetupMFA from '../../SetupMFA';
import VerifyMfa from '../../VerifyMfa';
import Welcome from '../../Welcome/Welcome';

const PostLogin: React.FC<IAuthProps> = ({ userObject, userInfo }: IAuthProps) => {
  if (!userObject && !userInfo) {
    return <Redirect to={routes.auth.login} />;
  }
  return (
    <>
      <Route path={routes.auth.login}>
        <Login />
      </Route>
      <Route path={routes.auth.totp}>
        <VerifyMfa userObject={userObject} />
      </Route>
      <Route path={routes.auth.mfaOptions}>
        <SetupMFA userObject={userObject} />
      </Route>
      <Route path={routes.auth.welcome}>
        <Welcome name={userInfo?.Name || ''} />
      </Route>
    </>
  );
};

export default PostLogin;
