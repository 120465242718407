import { makeStyles } from '@alpha/ui-lib/ui/external';

const useStyles = makeStyles({
  imageHolder: {
    marginRight: '8px',
    display: 'inline-block',
  },
});

export default useStyles;
