import React, { memo, useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';

import BeneficiaryDrawer from '../../../../../components/Drawer/BeneficiaryDrawer/BeneficiaryDrawer';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import BeneficiariesService from '../../../../../services/Beneficiaries/beneficiaries.service';

export type TBeneficiaryInfoDrawer = BeneficiaryDto;
interface IProps {
  paymentSummaryBeneficiaryId?: string;
  setPaymentSummaryBeneficiaryId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  onClose: Function;
  open: boolean;
}

const BeneficiaryInfoDrawer: React.FC<IProps> = (props: IProps) => {
  const {
    paymentSummaryBeneficiaryId, setPaymentSummaryBeneficiaryId, onClose, open,
  } = props;
  const [beneficiary, setBeneficiary] = useState<BeneficiaryDto>();
  const [loading, setLoading] = useState<boolean>();

  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const handleClose = () => {
    setPaymentSummaryBeneficiaryId(undefined);
    onClose(false);
  };

  const getBeneficiaryFromAPI = async (beneId: string | undefined) => {
    if (!beneId) {
      handleClose();
      setBeneficiary(undefined);
    } else {
      setLoading(true);
      try {
        const bene: BeneficiaryDto = await BeneficiariesService.getIndividualBeneficiary(
          beneId,
        );
        setLoading(false);
        setBeneficiary(bene);
      } catch (e) {
        setBeneficiary(undefined);
        setLoading(false);
        handleClose();
        const errorMessage = (e.response?.data?.errors?.length
          && (e.response?.data?.errors[0])) || e.response?.data?.error || e.message || t('there_was_an_error_loading_the_beneficiary');
        sb.trigger(errorMessage);
        logError({ action: 'Error loading beneficiary', error: e });
      }
    }
  };

  useEffect(() => {
    if (paymentSummaryBeneficiaryId) {
      getBeneficiaryFromAPI(paymentSummaryBeneficiaryId);
    }
  }, [paymentSummaryBeneficiaryId]);

  return (
    <BeneficiaryDrawer
      loading={loading}
      beneficiary={beneficiary}
      open={open}
      handleClose={handleClose}
      disableBackdropClick={false}
    />
  );
};

export default memo(BeneficiaryInfoDrawer);
