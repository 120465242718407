import React, { useCallback, useEffect, useState } from 'react';
import t from 'utils/translationHelper';
import { Link } from 'react-router-dom';

import { MarginAccountDto } from '@alpha/currency-accounts-dtos';
import { Flag } from '@alpha/ui-lib/ui/Flag';

import Table from '../../../../../components/Table/Table';
import { ITableColumn } from '../../../../../services/DatabaseServices/marker.interface';
import { formatCurrency } from '../../../../../utils/currency.helpers';

import useStyles from './index.styles';

interface IProps {
  marginAccounts: MarginAccountDto[];
}

export const MarginAccounts: React.FC<IProps> = ({ marginAccounts }) => {
  const classes = useStyles();
  const [availableAccounts, setAvailableAccounts] = useState<{ [x: string]: number; }[]>([]);


  const columns: ITableColumn[] = [
    { header: '', accessor: 'flag', width: 70 },
    { header: t('margin_accounts'), accessor: 'friendlyNameJSX', width: 180 },
    {
      header: '',
      accessor: 'marginHeldFormatted',
      align: 'right',
    },
  ];

  const mapCurrencies = useCallback((mAccounts: MarginAccountDto[]) => Array.from(
    new Set(mAccounts.map((acc) => acc.currencyCode)),
  ), []);

  useEffect(() => {
    const availableCurrencies = mapCurrencies(marginAccounts);
    const availableBalanceSum = availableCurrencies.map((currency) => ({
      [currency]: marginAccounts
        .filter((a) => (a.currencyCode === currency))
        .reduce((partialSum, a) => partialSum + a.marginHeld, 0),
    }));
    setAvailableAccounts(availableBalanceSum);
  }, [marginAccounts]);

  const formattedData = availableAccounts?.map((account) => {
    const currencyCode = Object.keys(account)[0];
    const accountsForCurrency = marginAccounts
      .filter((acc) => acc.currencyCode === currencyCode);
    return ({
      ...account,
      friendlyNameJSX: (
        <tr className={classes.marginAccounts}>
          {accountsForCurrency.map((acc) => (
            accountsForCurrency.length > 1
              ? <td>{t(acc.friendlyName) || '-'}</td>
              : <span>{t(acc.friendlyName) || '-'}</span>
          ))}
        </tr>
      ),
      flag: (
        <Link
          to={`/app/currency-accounts?currency=${currencyCode}`}
          data-testid="margin-account-link"
        >
          <div className={classes.currencyFlag}>
            <Flag code={currencyCode} size="md" />
            {' '}
            <span className={classes.currency}>{currencyCode}</span>
          </div>
        </Link>),
      marginHeldFormatted: (
        <tr className={classes.marginValues}>
          {accountsForCurrency.map((a) => (accountsForCurrency.length > 1
            ? <td>{formatCurrency(currencyCode, a.marginHeld)}</td>
            : formatCurrency(currencyCode, a.marginHeld)))}
        </tr>),
    });
  });

  return (
    <Table
      columns={columns}
      data={formattedData}
      skip={0}
    />
  );
};

export default MarginAccounts;
