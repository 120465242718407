/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import CreateSpotDrawer from 'components/Molecules/Trade/CreateSpotDrawer';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useAuthorization from 'hooks/useAuthorization';
import useLog from 'hooks/useLog';
import { UserRole } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import IATService, { CurrencyAccountTransferRequest } from 'services/IAT/IAT.service';
import { TStore } from 'store';
import { initiateTransfer } from 'store/authy/actions';
import { TAuthyState } from 'store/authy/reducer';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { CurrencyAccountDto, CurrencyAccountTransferDto, TransferApprovalResponseDto } from '@alpha/currency-accounts-dtos';
import { TradeDto } from '@alpha/fx-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';

import IATConfirmation from './IATConfirmation/IATConfirmation';
import IATDrawerForm from './IATDrawerForm/IATDrawerForm';
import TransferSpotDraftConfirmation from './TransferSpotDraftConfirmation/TransferSpotDraftConfirmation';
import useStyles from './index.styles';

type Props = {
  open: boolean,
  onClose: () => void,
  openFromCurrencyAccountId?: string,
  onSubmittedTransfer?: () => void;
}
export interface TransferConfirmationData extends CurrencyAccountTransferRequest {
  fundingCurrencyAccount: CurrencyAccountDto,
  debitingCurrencyAccount: CurrencyAccountDto,
}

const IATDrawerContainer: React.FC<Props> = ({
  open, onClose, openFromCurrencyAccountId, onSubmittedTransfer,
}) => {
  const dispatch = useDispatch();
  const [drawerState, setDrawerState] = useState('InputTransfer');
  const [IATRequestValues, setIATRequestValues] = useState<TransferConfirmationData>();
  const [sendEmailNotification, setSendEmailNotification] = useState<boolean>(true);
  const [transferSubmitResponse, setTransferSubmitResponse] = useState<CurrencyAccountTransferDto>();
  const [trade, setTrade] = useState<TradeDto>();
  const [spotOpen, setSpotOpen] = useState(false);
  const [approvalResponse, setApprovalResponse] = useState<TransferApprovalResponseDto>();
  const { authorized: IATApproverOwn } = useAuthorization([[UserRole.IAT_APPROVER_OWN]]);
  const { authorized: IATInputter } = useAuthorization([[UserRole.IAT_INPUTTER]]);
  const { authorized: spotBooker } = useAuthorization([[UserRole.SPOT]]);
  const { authorized: spotInputter } = useAuthorization([[UserRole.SPOT_INPUTTER]]);
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const styles = useStyles();

  const onDrawerClose = (): void => {
    setDrawerState('InputTransfer');
    if (onClose) { onClose(); }
  };
  const { logError, logEvent } = useLog();
  const sb = useAlphaSnackbar();

  const createRequestBody = (data: TransferConfirmationData): CurrencyAccountTransferRequest => {
    // a transfer from a GBP acc to a EUR acc will buy EUR for GBP (product logic)
    // however on the BE the naming is swapped for debiting/funding
    // so swapping them debiting will become funding/buy and funding will become debiting/sell
    // in the fx flows and displaying transfer info
    const newValues = {
      fundingCurrencyAccountId: data.debitingCurrencyAccountId,
      debitingCurrencyAccountId: data.fundingCurrencyAccountId,
      debitingAccountId: data.debitingAccountId,
      instructedAmount: Number(data.instructedAmount),
      instructedCurrencyCode: data.instructedCurrencyCode,
      reference: data?.reference,
      valueDate: data.valueDate,
    };
    return newValues;
  };

  const getIsSingleCurrency = (fundingCurrency: string, debitingCurrency: string) => fundingCurrency === debitingCurrency;

  const onSubmissionSuccess = async (submitResponse: CurrencyAccountTransferDto) => {
    try {
      const approvers: Approver[] = await IATService.getIATApprovers(submitResponse?.id || '');
      const approverIds: string[] = approvers.flatMap((approver: Approver) => (
        approver.eligible ? approver.userId : []));
      IATService.postIATApproverEmails(submitResponse?.id, approverIds);
    } catch (error) {
      sb.trigger(t('there_was_an_error_sending_your_email_notification'), 'error');
      logError({ action: 'Error sending your email notification' });
    }
  };

  const handleTransferMfa = (id: string) => {
    dispatch(initiateTransfer({
      type: 'TRANSFER_APPROVE',
      approverOwn: IATApproverOwn,
      transferId: id,
    }));
  };

  const submitTransfer = async () => {
    try {
      if (IATRequestValues?.debitingCurrencyAccountId) {
        const body = createRequestBody(IATRequestValues);
        const response = await IATService.postTransferRequest(body);
        if (response.transferStatus === 'SUBMITTED') {
          if (IATApproverOwn) handleTransferMfa(response.id);
          setTransferSubmitResponse(response);
          logEvent({ action: 'Successfully submitted transfer' });
          if (!IATApproverOwn && IATInputter) {
            sb.trigger(t('successfully_submitted_transfer'), 'success');
            if (sendEmailNotification) onSubmissionSuccess(response);
            if (onSubmittedTransfer) { onSubmittedTransfer(); }
            onDrawerClose();
          } else if (!spotInputter) {
            if (onSubmittedTransfer) { onSubmittedTransfer(); }
            onDrawerClose();
          }
        }
      }
    } catch (e) {
      logError({ action: 'Error submitting IAT request', error: e });
      sb.trigger(t('error_submitting_iat_request'), 'error');
      onDrawerClose();
    }
  };

  const getTradeData = async (queryTradeId: string, retry = 1) => {
    let errored = false;
    // polling for trade details as takes time to create record after approval
    const timerId = setInterval(async () => {
      try {
        const tradeData = await FXTradeService.getTransfersFXData(queryTradeId);
        if (tradeData?.id) {
          setTrade(tradeData);
          clearInterval(timerId);
          errored = false;
        }
      } catch (error) {
        errored = true;
        sb.trigger(error?.message || t('failed_to_load_trade_details'));
        logError({ action: 'Error loading trade details', error });
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(timerId);
      if (errored && retry !== 0) {
        onDrawerClose();
        getTradeData(queryTradeId, 0);
      }
    }, 5000);
  };

  useEffect(() => {
    setSendEmailNotification(!IATApproverOwn);
  }, [IATApproverOwn]);

  useEffect(() => {
    if (authyState.status === 'SUCCESS' && authyState.type?.type === 'TRANSFER_APPROVE') {
      // TODO need to add source / namespace so that we can distinguish between different actions
      if (open) sb.trigger(t('transfer_submitted_and_approved'), 'success');
      if (transferSubmitResponse?.debitingCurrencyCode) {
        const isSingleCurrency = getIsSingleCurrency(transferSubmitResponse?.debitingCurrencyCode, transferSubmitResponse?.fundingCurrencyCode);
        const spotAndFinalApprovalWithSpotRoles = !isSingleCurrency && approvalResponse?.isLastApproval && (spotBooker || spotInputter);
        if (transferSubmitResponse?.id && spotAndFinalApprovalWithSpotRoles && open) {
          getTradeData(transferSubmitResponse.id);
          if (spotBooker) {
            onDrawerClose();
            setSpotOpen(true);
          }
          if (spotInputter && !spotBooker && !isSingleCurrency) setDrawerState('ConfirmTransferSpot');
        } else if (isSingleCurrency || !approvalResponse?.isLastApproval) {
          onDrawerClose();
        }
      } else if ((!spotBooker || !spotInputter) || !approvalResponse?.isLastApproval) {
        onDrawerClose();
      }
    }
  }, [authyState.status, authyState.type, approvalResponse, transferSubmitResponse]);

  useEffect(() => {
    if (authyState?.extraInfo?.isLastApproval && !authyState.extraInfo?.pending) {
      setApprovalResponse(authyState.extraInfo);
    }
  }, [authyState?.extraInfo]);

  return (
    <>
      {open ? (
        <BaseDrawer.Drawer
          open={open}
          onClose={onDrawerClose}
          anchor="right"
          className={styles.drawer}
          disableEnforceFocus
        >
          {drawerState === 'InputTransfer' && (
            <IATDrawerForm
              onClose={onDrawerClose}
              setDrawerState={setDrawerState}
              setIATRequestValues={setIATRequestValues}
              submitTransfer={submitTransfer}
              defaultFromAccountId={openFromCurrencyAccountId}
            />
          )}
          {drawerState === 'ConfirmTransfer' && IATRequestValues?.debitingCurrencyAccountId && (
            <IATConfirmation
              values={IATRequestValues}
              handleFormSubmit={submitTransfer}
              handleSetSection={setDrawerState}
              sendEmailNotification={sendEmailNotification}
              setSendEmailNotification={setSendEmailNotification}
              authyStateOpen={authyState.open}
            />
          )}
          {transferSubmitResponse && trade && drawerState === 'ConfirmTransferSpot'
            && (
              <TransferSpotDraftConfirmation
                trade={trade}
                transferSubmitResponse={transferSubmitResponse}
                onClose={onDrawerClose}
              />
            )}
        </BaseDrawer.Drawer>
      ) : null}
      {spotOpen && trade && (
        <CreateSpotDrawer
          open={spotOpen}
          onClose={() => setSpotOpen(false)}
          tradeDraft={trade}
          heading={`- ${'inter_account_transfer'}`}
          bookedTransfer={transferSubmitResponse}
        />
      )}
    </>
  );
};

export default IATDrawerContainer;
