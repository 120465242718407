import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography, palette }) => ({
  fundingOptionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    borderRadius: '5px',
    padding: '45px 0px 45px 60px',
    margin: '20px 48px 0px 84px',
    minWidth: '800px',
  },
  fundingSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '35%',
    justifyContent: 'space-between',
  },
  radioButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fundingOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
    overflow: 'auto',
  },
  boxHeaderText: {
    marginBottom: '20px',
    fontSize: '17px',
    alignSelf: 'left',
    fontWeight: typography.fontWeightBold,
  },
  boxHeading: {
    display: 'flex',
    flexDirection: 'row',
  },
  boxText: {
    marginBottom: '20px',
    fontSize: '14px',
    alignSelf: 'left',
  },
  boxAlert: {
    marginTop: '20px',
  },
  icon: {
    backgroundColor: palette.grey[100],
    borderRadius: '20px',
    padding: '5px',
    marginRight: '10px',
  },
  radioButtonText: {
    flexDirection: 'column',
    '& .MuiFormControlLabel-root': {
      '& .MuiTypography-body1': {
        fontSize: '14px',
        alignSelf: 'left',
      },
    },
  },
  batchSummaryStats: {
    marginRight: '20px',
    display: 'flex',
    width: '100%',
  },
  statValue: {
    fontSize: '18px',
    fontWeight: typography.fontWeightSemiBold,
  },
  tickIcon: {
    color: '#88C165',
  },
  tooltipText: {
    fontSize: '12px',
    padding: '8px 16px',
  },
}), { name: 'PaymentBatchFunding' });

export default useStyles;
