import { AxiosResponse } from 'axios';

import { DashboardUserAccountPreferencesDto } from '@alpha/profile-dtos';

import instance from '../Axios/instance';

export default class DashboardService {
  public static async getUserDashboardSettings(): Promise<any> {
    const response: AxiosResponse<DashboardUserAccountPreferencesDto> = await instance.get(
      '/profiles/dashboard-preferences',
    );
    return response.data;
  }

  public static async postUpdateUserDashboardSettings(
    userDashboardSettings: DashboardUserAccountPreferencesDto,
  ) : Promise<DashboardUserAccountPreferencesDto> {
    const response = await instance.post(
      '/profiles/dashboard-preferences',
      userDashboardSettings,
    );
    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }

    return response.data;
  }
}
