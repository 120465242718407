import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    newButton: {
      float: 'right',
      marginBottom: '24px',
      marginRight: '32px',
      marginTop: '16px',
    },
    backButton: {
      backgroundColor: '#F7F7F7',
      color: 'black',
      marginBottom: '24px',
      marginLeft: '32px',
      float: 'left',
      marginTop: '16px',
      '&:hover': {
        backgroundColor: '#F7F7F7',
      },
    },
    formLabel: {
      lineHeight: '24px',
      fontWeight: 400,
    },
    alert: {
      width: '545px',
      fontColor: '#3E8CC11A',
    },
  }), { name: 'UploadModal' },
);

export default useStyles;
