import { makeStyles as alphaMakeStyles } from '@alpha/ui-lib/theme';

export const useStyles = alphaMakeStyles(
  ({ palette }) => ({
    flagText: {
      fontFamily: 'Source Sans Pro',
      lineHeight: '1px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: 'white',
      fontSize: '14px',
      verticalAlign: 'middle',
      marginLeft: '10px',

    },
    flagCombine: {
      width: '85px',
      height: '17px',
      flexShrink: 0,
    },
    flag: {
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '15px',
    },
    button: {
      width: '83px',
      marginLeft: '8px',
      marginRight: '8px',
      height: '47px',
      textTransform: 'none',
      backgroundColor: 'rgba(247, 247, 247, 0.13)',
      
      '&:hover': {
        background: palette.primary.main
      }
    },
    selectedBtn: {
      width: '100px',
    },
    langSelector: {
      marginTop: '100px',
      marginLeft: '-10.2px',
      width: '612px',
      paddingBottom: '25px',

    },

  }),
  { name: 'LangSelectorGroup' },
);
