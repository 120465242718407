import { BeneficiaryBatchStatus } from '@alpha/bene-dtos';
import t from 'utils/translationHelper';

export const mapBeneBatchStatusDisplay = (
  beneBatchStatus: BeneficiaryBatchStatus,
): {
  variant: 'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined, text:
  string
} => {
  switch (beneBatchStatus) {
    case BeneficiaryBatchStatus?.PROCESSING:
      return { variant: 'info', text: t('processing').toUpperCase() };
    case BeneficiaryBatchStatus?.PENDING_SUBMISSION:
      return { variant: 'info', text: t('pending_submission').toUpperCase() };
    case BeneficiaryBatchStatus?.VALIDATING:
      return { variant: 'info', text: t('validating').toUpperCase() };
    case BeneficiaryBatchStatus?.COMPLETED:
      return { variant: 'success', text: t('Completed').toUpperCase() };
    case BeneficiaryBatchStatus?.FAILED:
      return { variant: 'error', text: t('failed').toUpperCase() };
    case BeneficiaryBatchStatus.INVALID:
      return { variant: 'error', text: t('INVALID') };
    default:
      return { variant: 'info', text: t('processing').toUpperCase() };
  }
};

export default mapBeneBatchStatusDisplay;
