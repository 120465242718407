import React from 'react';
import t from 'utils/translationHelper';

import { PaymentBatchDto, PaymentDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { StyledStatSummary } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary';

import usePaymentApproval from '../../../../hooks/usePaymentApproval';
import APModal from '../../../Modals/APModal/APModal';
import InvalidApprovalModal from '../InvalidApprovalModal';
import ValueDateChangedModal from '../ValueDateChangedModal';

import useStyles from './index.styles';

type Props = {
  payments: PaymentDto[];
  batch: PaymentBatchDto | undefined;
  open: boolean;
  handleClose: () => void;
}

const ApprovePaymentModal: React.FC<Props> = ({
  open, payments, batch, handleClose,
}: Props) => {

  const styles = useStyles();

  const {
    nonFinalApprovers,
    loading,
    failedPayments,
    expiredPayments,
    approvalData,
    setFailedPayments,
    setExpiredPayments,
    setLoading,
    handleApprovals,
    handleValueDateChangeAcknowlegement,
    handleInvalidApprovalAcknowlegement,
  } = usePaymentApproval(payments, handleClose);

  const displayModalMessage = (
    modalPayments: PaymentDto[],
  ) => (modalPayments.length > 0
    ? (
      <div className={styles.modalMessageBox}>
        <Typography className={styles.modalText}>
          {t('you_are_about_to_approve_batch')}
          <span className={styles.modalBoldText}>{` ${batch?.friendlyId || ''}`}</span>
        </Typography>
        <Typography className={styles.modalText}>
          {`${nonFinalApprovers.length ? `${t('not_all_payments_will_be_fully_approved_after')} ` : ''}${t('please_see_summary')}`}
        </Typography>
        <div className={styles.customStyledStat}>
          <StyledStatSummary data={approvalData} />
        </div>
        <Typography className={styles.modalText}>{t('do_you_wish_to_continue')}</Typography>
      </div>
    )
    : (
      <Typography className={styles.modalText}>
        {t('there_are_no_eligible_payments_to_approve')}
      </Typography>
    )
  );

  return (
    <APModal.Container
      open={open}
      onClose={() => {
        handleClose();
        setLoading(false);
      }}
    >
      <APModal.Wrapper className={styles.container}>
        <Box className={styles.headerBox}>
          <Typography className={styles.headerText}>{t('batch_approval')}</Typography>
        </Box>
        <div className={styles.modalContent}>
          <div style={{ marginBottom: '24px' }}>
            {displayModalMessage(payments)}
          </div>
          <div style={{ width: '100%' }}>
            <ActionButton
              size="medium"
              style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }}
              disabled={loading}
              onClick={handleClose}
            >
              {t('cancel')}
            </ActionButton>
            <ActionButton
              size="medium"
              loading={loading}
              disabled={loading || payments.length === 0}
              onClick={handleApprovals}
            >
              {t('proceed')}
            </ActionButton>
          </div>
        </div>
        <ValueDateChangedModal
          handleAcknowledge={handleValueDateChangeAcknowlegement}
          handleClose={() => {
            setExpiredPayments([]);
            setLoading(false);
          }}
          open={Boolean(expiredPayments.length)}
          expiredPayments={expiredPayments}
        />
        <InvalidApprovalModal
          handleClose={() => {
            setFailedPayments([]);
            setLoading(false);
          }}
          handleAcknowledge={handleInvalidApprovalAcknowlegement}
          open={Boolean(failedPayments.length)}
          payments={failedPayments}
        />
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default ApprovePaymentModal;
