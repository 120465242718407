import * as yup from 'yup';

import { BeneficiaryDto } from '@alpha/bene-dtos';

export type QuickDrawdownRequest = {
  beneficiary?: BeneficiaryDto;
}

export const QuickDrawdownInitialValues: QuickDrawdownRequest = {
  beneficiary: undefined,
};

export const validation = {
  beneficiary: yup.string().required('Beneficiary required'),
};

export default validation;
