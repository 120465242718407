import React from "react";
import { AuthyPageStates } from "store/authy/reducer";
import t from 'utils/translationHelper';
import { ReactElement } from "react-markdown/lib/react-markdown";

interface iProps {
    phoneNumber: string;
    pageState: AuthyPageStates;
}

const Subheader: React.FC<iProps> = ({ phoneNumber, pageState }) => {


    let check: string | ReactElement;
    if (pageState === AuthyPageStates.AUTHY) {
        check = (<>{t('please_open_the_authy_app_to_retrieve_your_code')}</>);
    } else if (pageState === AuthyPageStates.OPTIONS) {
        check = (<>{t('please_select_which_backup_method_you_would_like')}</>);
    } else {
        check = (<>
            {t('we_have_sent_a_code_to_your_registered_device')}
            {' '}
            {phoneNumber}
            .
            <br />
            {t('please_enter_the_code_to_continue')}
        </>)
    }
    return (
        check
    );
}

export default Subheader;