import {
  call, putResolve, takeLatest,
} from 'redux-saga/effects';
import OptionTradeService from 'services/OptionTrades/optionTrades.service';

import { datadogLogs } from '@datadog/browser-logs';

import { actions } from './reducer';

async function getOptionTrade(tradeId: string) {
  const response = await OptionTradeService.getOptionTrade(tradeId);
  return response;
}

export function* getOptionTradeSaga(action: ReturnType<typeof actions.loadTrade>) {
  yield putResolve(actions.setTradeLoading({ tradeLoading: true }));
  try {
    const result = yield call(getOptionTrade, action.payload.tradeId);
    if (result) {
      yield putResolve(actions.setOptionTrade({ trade: result }));
    }
  } catch (e) {
    datadogLogs.logger.error(e);
  } finally {
    yield putResolve(actions.setTradeLoading({ tradeLoading: false }));
  }
}

export function* watchGetOptionTradeSaga() {
  yield takeLatest(actions.loadTrade.type, getOptionTradeSaga);
}
