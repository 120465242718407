import React from 'react';
import t from 'utils/translationHelper';

import { TradeDto } from '@alpha/fx-dtos';
import { Button } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';

import { CurrencyPair } from '../../../../../../components/International';
import BackdropLoader from '../../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import { ITableColumn } from '../../../../../../services/DatabaseServices/marker.interface';
import browserHistory from '../../../../../../services/history/browserHistory';
import { formatNumber } from '../../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../../utils/formatIsoDate';
import mapFxTradeStatusDisplay from '../../../../../../utils/fxTrades/mapFxTradeStatusDisplay';

import useStyles from './LinkedTradesTable.styles';

interface IProps {
  trades: TradeDto[],
  loading: boolean,
}

const LinkedTradesTable: React.FC<IProps> = (props: IProps) => {
  const { trades, loading } = props;


  const classes = useStyles();

  const columns: ITableColumn[] = [
    { header: t('trade_number'), accessor: 'contractNumber' },
    { header: t('trade_date'), accessor: 'tradeDate' },
    { header: t('sold_ccy'), accessor: 'soldCurrencyCode' },
    { header: t('sold_amount'), accessor: 'soldAmount', align: 'right' },
    { header: t('rate'), accessor: 'rate', align: 'right' },
    { header: t('buy_ccy'), accessor: 'buyCurrencyCode' },
    { header: t('buy_amount'), accessor: 'buyAmount', align: 'right' },
    { header: t('buy_balance'), accessor: 'buyBalance', align: 'right' },
    { header: t('value_date'), accessor: 'valueDate' },
    { header: t('status'), accessor: 'tradeStatus', width: 150 },
  ];

  const handleTradeNumberClick = (e: TradeDto) => {
    const tradeId = e.id;
    browserHistory.push(`/app/trades/${tradeId}`);
  };

  const formattedTableData = trades.map((trade: TradeDto) => ({
    ...trade,
    contractNumber: (
      <Button
        disableRipple
        className={classes.tradeTableButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (handleTradeNumberClick) { handleTradeNumberClick(trade); }
        }}
      >
        {trade.contractNumber || '-'}
      </Button>),
    tradeDate: formatIsoDate(trade.date),
    soldCurrencyCode: (trade.soldCurrencyCode && (
      <CurrencyPair currencyCode={trade.soldCurrencyCode} />
    ))
      || '-',
    soldAmount: <span className={classes.amount}>{[formatNumber(trade.soldAmount || 0, 2), trade.soldCurrencyCode].join(' ')}</span>,
    rate: <span className={classes.tableLink}>{trade.rate?.toFixed(4)}</span> || '-',
    buyCurrencyCode: (trade.buyCurrencyCode && (
      <CurrencyPair currencyCode={trade.buyCurrencyCode} />
    ))
      || '-',
    buyAmount: <span className={classes.amount}>{[formatNumber(trade.buyAmount || 0, 2), trade.buyCurrencyCode].join(' ')}</span>,
    buyBalance: <span className={classes.amount}>{[formatNumber(trade.buyBalance || 0, 2), trade.buyCurrencyCode].join(' ')}</span>,
    valueDate: formatIsoDate(trade.valueDate),
    tradeStatus: (
      <Status className={classes.status} variant={mapFxTradeStatusDisplay(trade.status).variant}>{t(mapFxTradeStatusDisplay(trade.status).text) || '-'}</Status>),
  }));

  if (loading) return <BackdropLoader testId="batch-payments-loader" />;

  return (
    <div className={classes.tableWrapper}>
      <StyledGenericTable
        testId="batch-payments-table"
        columns={columns}
        data={formattedTableData}
      />
    </div>
  );
};

export default LinkedTradesTable;
