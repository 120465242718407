import instance from '../Axios/instance';

export class NotificationsService {
  public static async putNotificationAcknowledge(notificationId: string): Promise<void> {
    const response = await instance.put(`/notifications/${notificationId}/acknowledge`);
    return response.data;
  }
}

export default NotificationsService;
