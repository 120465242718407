import React, { ReactNode } from 'react';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Divider } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';


import APModal from 'components/Modals/APModal/APModal';

import useStyles from './PayeeConfirmationModal.styles';
import { StyledKeyValueTable } from '@alpha/ui-lib/ui/table';
import usePayeeConfirmation from './usePayeeConfirmation';
import Alert from 'components/Alert';
import { ValidateBeneficiaryRequest, ValidateBeneficiaryResponse } from '@alpha/bene-dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faBan, faMinusCircle, faShieldCheck, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

interface IProps {
  formValues: ValidateBeneficiaryRequest;
  open: boolean;
  validatedBene: ValidateBeneficiaryResponse | undefined;
  handleClose: () => void;
  handleActionButton: (validationDetail?: object) => Promise<void> | void;
}
const PayeeConfirmationModal: React.FC<IProps> = (props: IProps) => {
  const {
    open,
    formValues,
    handleClose,
    handleActionButton,
    validatedBene,
  } = props;

  const { COPData } = usePayeeConfirmation(formValues,
    validatedBene?.applyFinancialErrored,
    validatedBene?.errors,
    validatedBene?.confirmationOfPayeeResponse,);

  const styles = useStyles();

  const matchIcon = (() => {
    switch (COPData.accountDetail.matchType) {
      case 'Full Match': return <FontAwesomeIcon icon={faShieldCheck} color='#51A869' className={styles.floatingIcon} />;
      case 'Close Match': return <FontAwesomeIcon icon={faExclamationTriangle} color='#CF752F' className={styles.floatingIcon} />;
      case 'No Match': return <FontAwesomeIcon icon={faBan} color='#A62B23' className={styles.floatingIcon} />;
      case 'Unavailable': return <FontAwesomeIcon icon={faMinusCircle} color='#C4C4C4' className={styles.floatingIcon} />;
    }
  })();
  const showApplyFinance = COPData.applyFinance.valid || COPData.accountDetail.matchType === "Unavailable";

  return (
    <APModal.Container
      open={open}
      onClose={handleClose}
      className={styles.container}
    >
      <>
        <APModal.Header
          onClose={handleClose}
          style={{ marginBottom: '20px' }}
          showCloseButton>
          <Box className={styles.header}>
            <Typography variant="h2">
              <FontAwesomeIcon icon={faInfoCircle} color='#3E8CC1' size="sm" style={{ marginRight: '8px' }} />
              {t('Beneficiary Validation')}
            </Typography>
          </Box>
        </APModal.Header>
        <APModal.Wrapper className={styles.wrapper}>
          <>
            {/* Apply Finance */}
            {showApplyFinance && (
              <Box className={styles.body} >
                {COPData.applyFinance.valid && (<>
                  <Typography variant="h3" style={{ position: 'relative' }}>
                    <FontAwesomeIcon icon={faShieldCheck} color='#51A869' className={styles.floatingIcon} />
                    {t('Valid Account')}
                  </Typography>
                  <p className={styles.message}>
                    {t('beneficiary_passed_validation_checks')}
                  </p>
                </>)}


                {!COPData.applyFinance.valid && (<>
                  <Typography variant="h3" style={{ position: 'relative' }}>
                    <FontAwesomeIcon icon={faTimesCircle} color='#A62B23' className={styles.floatingIcon} />
                    {t('Invalid Account')}
                  </Typography>
                  <p className={styles.message}>
                    {t(COPData.applyFinance.errorMessage)}
                  </p>
                </>)}
              </Box>
            )}
            {/* COP */}
            <Box className={styles.body}>
              <Typography variant="h3" style={{ position: 'relative' }}>
                {matchIcon}
                {t(COPData.accountDetail?.matchType)}
              </Typography>
              <p className={styles.message}>
                {COPData.accountDetail?.closeMatchMessage ?? t("The name details you have provided match the beneficiary account.")}
              </p>
              {/* Close Match */}
              {COPData.accountDetail?.closeMatchDetail?.length > 0 &&
                (<Box style={{ marginTop: '20px' }}>
                  <StyledKeyValueTable
                    testId={'close-match-table'}
                    className={styles.noDivider}
                    data={COPData.accountDetail?.closeMatchDetail} />
                  <Divider style={{ marginTop: '20px', backgroundColor: '#f7f7f7' }} />
                </Box>)
              }
              {/* Table  */}
              <StyledKeyValueTable
                testId={'matching-table'}
                className={[styles.noDivider, styles.matchingTable].join(' ')}
                data={COPData.accountDetail.matchDetail} />

              {COPData.accountDetail.matchType !== 'Full Match' && (
                <Box marginTop="20px">
                  <Alert
                    variant="warning"
                    className={styles.copWarning}
                    text={<><strong>{t('please_note')}</strong> {t('cop_alert_message')}</>}
                  />
                </Box>)}
            </Box>
          </>
        </APModal.Wrapper >
        <Divider style={{ width: '100%', marginTop: '20px', marginBottom: '20px', backgroundColor: '#f7f7f7' }} />
        <Box className={styles.buttonsContainer}>
          <ActionButton
            onClick={handleClose}
            className="cancelButton"
          >
            {t('edit_details')}
          </ActionButton>
          <ActionButton
            testId="modal-reject-button"
            onClick={() => {
              handleActionButton({ beneficiaryValidation: validatedBene, friendlyCOPessage: COPData.accountDetail.closeMatchMessagePlainText, COPType: COPData.accountDetail.matchType });
            }}
            className="submitButton"
          >
            {t('Acknowledge & Continue')}
          </ActionButton>
        </Box>
      </>
    </APModal.Container >
  );
};

export default PayeeConfirmationModal;
