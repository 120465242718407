import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import t from 'utils/translationHelper';
import { NavLink, Redirect, useHistory } from 'react-router-dom';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APInput } from '@alpha/ui-lib/ui/Input';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import useUpdatePassword from '../../../hooks/useUpdatePassword';
import routes from '../../../routes.path';
import hasCorrectChars from '../../../utils/hasCorrectChars';
import hasLowerCase from '../../../utils/hasLowerCase';
import hasNumber from '../../../utils/hasNumber';
import hasSpecialChars from '../../../utils/hasSpecialChars';
import hasUpperCase from '../../../utils/hasUpperCase';
import useFormStyles from '../form.styles';
import LangSelectorGroup from '../LangSelectorGroup';
import { IOnboarding, useOnboardingContext } from '../Onboarding';
import { IReturning } from '../Returning';

import useStyles from './index.styles';

interface IProps extends IOnboarding, IReturning {
  onboarded?: boolean
}

const NewPassword: React.FC<IProps> = ({ onboarding, userObject, onboarded }: IProps) => {

  const classes = useStyles();
  const formClasses = useFormStyles();
  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');

  const { formik, loading } = useUpdatePassword(userObject);
  const { handleSetLoaderPercentage } = useOnboardingContext();
  const history = useHistory();
  const snackbar = useAlphaSnackbar();

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      if (onboarding && onboarded) {
        handleSetLoaderPercentage(75);
        snackbar.trigger(t('please_complete_the_onboarding_process'), 'warning');
        history.push(routes.auth.onboarding.setupMfa);
      }
      handleSetLoaderPercentage(50);
    }
  }, []);

  const handleButtonDisabled = (): boolean => {
    if (!(
      hasLowerCase(formik.values.password)
      && hasUpperCase(formik.values.password)
      && hasNumber(formik.values.password)
      && hasCorrectChars(formik.values.password, 8)
      && hasSpecialChars(formik.values.password))
    ) return true;

    return false;
  };

  return (
    <div className={formClasses.wrapperDiv}>
      <div>
        <FormTitle
          number={2}
          title={t('set_your_new_password')}
          description={t('thank_you_for_verifying_your_email')}
        />
        <form onSubmit={formik.handleSubmit}>
          <Typography className={formClasses.label}>{t('new_password^')}</Typography>
          <APInput
            autoComplete="off"
            testid="password"
            id="password"
            name="password"
            placeholder={t('enter_a_new_password')}
            variant="filled"
            type={passwordType}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <button
            type="button"
            className={formClasses.eyeIconStyles}
            onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
          >
            <FontAwesomeIcon
              icon={(passwordType === 'password' ? faEye : faEyeSlash) as IconProp}
            />
          </button>
          <ul className={classes.list}>
            <li
              className={clsx(hasLowerCase(formik.values.password) && classes.contains)}
            >
              {t('one_lowercase_character')}

            </li>
            <li
              className={clsx(hasUpperCase(formik.values.password) && classes.contains)}
            >
              {t('one_uppercase_character')}
            </li>
            <li
              className={clsx(hasNumber(formik.values.password) && classes.contains)}
            >
              {t('one_number')}
            </li>
            <li
              className={clsx(hasCorrectChars(formik.values.password, 8) && classes.contains)}
            >
              {t('8_characters_minimum')}
            </li>
            <li
              className={clsx(hasSpecialChars(formik.values.password) && classes.contains)}
            >
              {t('one_special_character')}
            </li>
          </ul>
          <ActionButton
            type="submit"
            testId="submit-new-password"
            fullWidth
            loading={loading}
            disabled={loading || handleButtonDisabled()}
            size="large"
          >
            {t('submit_new_password')}
          </ActionButton>
          <div className={formClasses.formBacklink}>
            <span className={formClasses.white}>
              {t('need_more_time')}
              {' '}
            </span>
            <NavLink to={onboarding ? routes.auth.onboarding.login : routes.auth.login}>
              {t('go_back_to_beginning')}
            </NavLink>
          </div>
        </form>

      </div>
      <LangSelectorGroup />
    </div>
  );
};

export default NewPassword;
