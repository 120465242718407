import clsx from 'clsx';
import React from 'react';
import NumberFormat from 'react-number-format';

import { makeStyles } from '@alpha/ui-lib/theme';
import { Input } from '@alpha/ui-lib/ui/Input/Input';
import { TInputProps } from '@alpha/ui-lib/ui/Input/Input.interfaces';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';

const useStyles = makeStyles(() => ({
  disabled: {
    '& .MuiInputBase-input': {
      color: 'rgba(52,52,52,0.5) !important',
    },
  },
  root: {
    '& .MuiInputBase-input': {
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
}), { name: 'inputAndLabel' });

type TInputAndLabelProps = {
  label: string;
  type?: 'amount' | TInputProps['type'];
} & Omit<TInputProps, 'variant'>
const InputAndLabel: React.FC<TInputAndLabelProps> = ({
  label,
  type = 'text',
  name,
  id,
  placeholder,
  testId,
  onChange,
  onBlur,
  value,
  error,
  helperText,
  disabled,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Typography variant="subtitle1" style={{ marginBottom: '6px' }}>{label}</Typography>
      {type === 'amount'
        ? (
          <NumberFormat
            fullWidth
            thousandSeparator
            allowLeadingZeros={false}
            name={name}
            id={id}
            type="text"
            variant="filled"
            placeholder={placeholder}
            testId={testId}
            onChange={onChange}
            onBlur={onBlur}
            value={String(value) || ''}
            error={error}
            helperText={helperText}
            disabled={disabled}
            className={clsx({ [styles.disabled]: disabled })}
            customInput={Input}
          />
        )
        : (
          <Input
            fullWidth
            name={name}
            id={id}
            variant="filled"
            placeholder={placeholder}
            testId={testId}
            onChange={onChange}
            onBlur={onBlur}
            value={value || ''}
            error={error}
            helperText={helperText}
            disabled={disabled}
            type={type}
            className={clsx({ [styles.disabled]: disabled })}
          />
        )}
    </div>

  );
};

export default InputAndLabel;
