import React, { FC } from 'react';
import { TBeneficariesStore } from 'store/beneficiaries/beneficiaries.reducer';
import t from 'utils/translationHelper';

import { PaymentBatchStatus } from '@alpha/payments-dtos';
import { Box, CircularProgress } from '@alpha/ui-lib/ui/external';

import { TPaymentsStore } from '../../../../store/payments/payments.reducer';

import useStyles from './UploadProgress.styles';

interface IUploadProgress {
  store: TPaymentsStore | TBeneficariesStore
}

const UploadProgress: FC<IUploadProgress> = ({
  store,
}: IUploadProgress) => {
  const styles = useStyles();

  const { batchDetails } = store;

  if (!batchDetails) {
    return (<></>);
  }

  const { status } = batchDetails;
  const percentage = () => ((batchDetails.processedRecords || 0)
    / (batchDetails.noOfRecords || 1)) * 100;

  return (
    <Box className={styles.progressBoxContainer}>
      <div className={styles.circularProgressBackground}>
        <CircularProgress
          variant="indeterminate"
          value={percentage()}
          size={60}
        />
      </div>
      {status === 'REQUESTED' && (
        <p>
          {t('we_are_processing_your_file')}
          ...
        </p>
      )}
      {status === PaymentBatchStatus.PENDING_UPLOAD && (
        <p>
          {t('your_file_is_uploading')}
          ...
        </p>
      )}
      {status === PaymentBatchStatus.VALIDATED && (
        <p>
          {t('processing')}
          {' '}
          {batchDetails.processedRecords}
          {' '}
          {t('of')}
          {' '}
          {batchDetails.noOfRecords}
          {' '}
          {batchDetails?.noOfRecords && batchDetails?.noOfRecords > 1 ? t('payments~') : t('payment~')}
        </p>
      )}
    </Box>
  );
};

export default UploadProgress;
