import { AxiosResponse } from 'axios';
import { FileType } from 'hooks/useReportsPolling';
import { TReportGenerationResponse } from 'models/currencyAccounts';

import {
  CreditFacilityDto,
  CreditFacilityTermDto,
  ExternalTradeMTMDto,
  TermLetterDto,
  TermMTMDto,
  TermMTMFilterOptionsDto,
} from '@alpha/credit-dtos';
import {
  CurrencyPairMTMDto,
  FacilitiesResponseDto,
} from '@alpha/fx-dtos';

import instance from '../Axios/instance';

export type TExternalTradesMtmRequest = {
  mtmdate: string,
  mtmcurrencyCode: string,
  searchtext?: string,
};

export class CreditService {
  public static async getCreditFacilitiesUtilisationGraphData(
    currencyCode: string,
  ): Promise<FacilitiesResponseDto> {
    return this.getCreditFacilitiesUtilisationGraphDataAsync(currencyCode);
  }

  private static async getCreditFacilitiesUtilisationGraphDataAsync(
    currencyCode: string,
  ): Promise<FacilitiesResponseDto> {
    const response: AxiosResponse<FacilitiesResponseDto> = await instance.get(`/credit/credit-facilities?currencycode=${currencyCode}`);
    return response.data;
  }

  public static async getMtmSummary(
    mtmDate: string,
    mtmCurrencyCode: string,
    termId?: string,
    buyCurrencies?: string[],
    soldCurrencies?: string[],
    includeOptions?: boolean,
  ): Promise<CurrencyPairMTMDto[]> {
    if (!termId) {
      return this.getMtmSummaryAsync(mtmDate, mtmCurrencyCode);
    }
    return this.getTermMtmSummaryAsync(mtmDate, mtmCurrencyCode, termId, buyCurrencies, soldCurrencies, includeOptions);
  }

  private static async getMtmSummaryAsync(
    mtmDate: string,
    mtmCurrencyCode: string,
  ): Promise<CurrencyPairMTMDto[]> {
    const response: AxiosResponse<CurrencyPairMTMDto[]> = await instance.get(`/credit/trades/mtm/summary?mtmdate=${mtmDate}&mtmcurrency=${mtmCurrencyCode}`);
    return response.data;
  }

  public static async getFacilityMTMSummary(
    mtmDate: string,
    mtmCurrencyCode: string,
    soldCurrencies?: string[],
    buyCurrencies?: string[],
    includeOptions?: boolean,
  ): Promise<CurrencyPairMTMDto[]> {
    const response: AxiosResponse<CurrencyPairMTMDto[]> = await instance.get(`/credit/facility/terms/mtm/summary?mtmdate=${mtmDate}&mtmcurrency=${mtmCurrencyCode}&soldcurrency=${soldCurrencies.join(',')}&buycurrency=${buyCurrencies.join(',')}&includeOptions=${includeOptions}`);
    return response.data;
  }

  public static async getAllMtms(
    mtmDate: string,
  ): Promise<TermMTMDto[]> {
    const response: AxiosResponse<{ records: TermMTMDto[] }> = await instance.get(
      '/credit/trades/mtm/search', {
      params: {
        skip: 0,
        take: 9999,
        mtmdate: mtmDate,
        includeexternaltrades: 'true',
      },
    },
    );
    return response.data?.records;
  }

  private static async getTermMtmFilterOptions(
    mtmDate: string,
    termId: string,
    includeOptions?: boolean,
  ): Promise<TermMTMFilterOptionsDto> {
    const response: AxiosResponse<TermMTMFilterOptionsDto> = await instance.get(
      `/credit/facility/terms/${termId}/mtm/filter-options?mtmdate=${mtmDate}&includeOptions=${includeOptions}`,
    );
    return response.data;
  }

  public static async getMtmFilterOptions(
    mtmDate: string,
    termId?: string,
    includeOptions?: boolean,
  ): Promise<TermMTMFilterOptionsDto> {
    if (!termId) {
      return this.getMtmFilterOptionsAsync(mtmDate);
    }
    return this.getTermMtmFilterOptions(mtmDate, termId, includeOptions);
  }

  private static async getMtmFilterOptionsAsync(
    mtmDate: string,
  ): Promise<TermMTMFilterOptionsDto> {
    const response: AxiosResponse<TermMTMFilterOptionsDto> = await instance.get(`/credit/trades/mtm/search/filter-options?mtmdate=${mtmDate}`);
    return response.data;
  }

  public static async getFacilityMtmFilterOptions(
    mtmDate: string,
    includeOptions?: boolean,
  ): Promise<TermMTMFilterOptionsDto> {
    const response: AxiosResponse<TermMTMFilterOptionsDto> = await instance.get(`/credit/facility/terms/mtm/filter-options?mtmdate=${mtmDate}&includeOptions=${includeOptions}`);
    return response.data;
  }

  public static async getFacilityMtmFilterOptionsLatestDate(
  ): Promise<TermMTMDto> {
    const response: AxiosResponse<TermMTMDto> = await instance.get('/credit/facility/terms/mtm/latest-date');
    return response.data;
  }

  public static async getFacilitySummary(): Promise<CreditFacilityDto> {
    const facilitySummary = await this.getFacilitySummaryAsync();
    return facilitySummary;
  }

  private static async getFacilitySummaryAsync(
  ): Promise<CreditFacilityDto> {
    const response: AxiosResponse<CreditFacilityDto> = await instance.get('credit/facility/summary');
    return response.data;
  }

  public static async getFacilityTermSummary(
    termId: string,
  ): Promise<CreditFacilityTermDto> {
    const response: AxiosResponse<CreditFacilityTermDto> = await instance.get(`/credit/facility/terms/${termId}/`);
    return response.data;
  }

  private static async getTermMtmSummaryAsync(
    mtmDate: string,
    mtmCurrencyCode: string,
    termId: string,
    soldCurrencies: string[],
    buyCurrencies: string[],
    includeOptions: boolean,
  ): Promise<CurrencyPairMTMDto[]> {
    const response: AxiosResponse<CurrencyPairMTMDto[]> = await instance.get(
      `/credit/facility/terms/${termId}/mtm/summary?mtmdate=${mtmDate}&mtmcurrency=${mtmCurrencyCode}&soldcurrency=${soldCurrencies.join(',')}&buycurrency=${buyCurrencies.join(',')}&includeOptions=${includeOptions}`,
    );
    return response.data;
  }

  private static async getFacilityTermMTMAsync(
    mtmDate: string,
    mtmCurrencyCode: string,
    termId: string,
  ): Promise<TermMTMDto[]> {
    const response: AxiosResponse<any> = await instance.get(`/credit/facility/terms/${termId}/mtm?mtmdate=${mtmDate}&mtmcurrency=${mtmCurrencyCode}`);
    return response.data;
  }

  public static async getMtmLatestAvailableDate(
    termId: string,
  ): Promise<{ latestMTMDate: string }> {
    const response = await instance.get(`/credit/facility/terms/${termId}/mtm/latest-date`);
    return response.data;
  }

  public static async postFacilityAmendmentRequestAsync(body: any): Promise<number> {
    const response: AxiosResponse<number> = await instance.post('/credit/facility/amendment', body);
    return response.status;
  }

  private static async postTermMtmReportGenerationAsync(
    type: FileType,
    mtmdate: string,
    mtmcurrency: string,
    termId?: string,
    searchtext?: string,
    soldcurrency?: string[],
    buycurrency?: string[],
    includeOptions?: boolean,
    isFacilityLevel?: boolean,
    includeexternaltrades?: boolean,
  ): Promise<TReportGenerationResponse> {
    let url = 'reports/term-mtm';
    if (!isFacilityLevel) {
      url = `reports/term-mtm/${termId}`;
    }

    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      url,
      {
        params: {
          type,
          mtmdate,
          mtmcurrency,
          searchtext,
          soldcurrency: soldcurrency?.join(','),
          buycurrency: buycurrency?.join(','),
          includeOptions,
          isFacilityLevel,
          includeexternaltrades,
        },
      },
    );
    return response.data;
  }

  public static async postTermMtmReportGeneration(
    type: FileType,
    mtmdate: string,
    mtmcurrency: string,
    termId?: string,
    searchtext?: string,
    soldcurrency?: string[],
    buycurrency?: string[],
    includeOptions?: boolean,
    isFacilityLevel?: boolean,
    includeexternaltrades?: boolean,
  ): Promise<string> {
    return (await this.postTermMtmReportGenerationAsync(
      type,
      mtmdate,
      mtmcurrency,
      termId,
      searchtext,
      soldcurrency,
      buycurrency,
      includeOptions,
      isFacilityLevel,
      includeexternaltrades,
    )).executionArn;
  }

  public static async getTermsLetterHistory(
    termId?: string,
  ): Promise<TermLetterDto[]> {
    const response = await instance.get('/credit/facility/letters');
    return response.data;
  }

  public static async getTermsLetterGeneration(
    fileName: string,
  ): Promise<string> {
    const response: AxiosResponse<{ downloadUrl: string }> = await instance.get(`/credit/facility/letters/${fileName}`);
    return response.data.downloadUrl;
  }

  public static async getExternalTradesMtm(
    {
      mtmdate,
      mtmcurrencyCode,
      searchtext,
    }: TExternalTradesMtmRequest,
  ): Promise<ExternalTradeMTMDto[]> {
    const response: AxiosResponse<ExternalTradeMTMDto[]> = await instance.get(
      '/credit/facility/external-trades/mtm',
      {
        params: {
          mtmdate,
          mtmcurrencyCode,
          searchtext,
        },
      },
    );
    return response.data;
  }
}

export default CreditService;
