import { VerificationMethod } from '@alpha/payments-dtos';

import { TSendTotp } from '../../models/auth';
import instance from '../Axios/instance';
import TwoFactorAuthService from '../TwoFactorAuth/twoFactorAuth.service';

export class AuthyService {
  public static async requestPhoneNumber(
    method?: string,
    id?: string,
  ): Promise<TSendTotp> {
    try {
      return (await instance.get(`/auth/mfa/send-totp?${method ? `method=${method}&` : ''}itemId=${id || '123456'}`)).data;
    } catch (e) {
      throw Error(e.response?.data?.message || 'Something went wrong');
    }
  }

  public static async getPaymentsPhoneNumber(
    paymentIds: string[],
    verificationMethod?: VerificationMethod,
    firstPartyFlow?: boolean,
  ): Promise<any> {
    return this.getPaymentsPhoneNumberAsync(paymentIds, verificationMethod, firstPartyFlow);
  }

  public static async getPaymentsApprovalPhoneNumber(
    paymentIds: string[],
    verificationMethod?: VerificationMethod,
  ): Promise<any> {
    return this.getPaymentsApprovalPhoneNumberAsync(paymentIds, verificationMethod);
  }

  public static async selectDefaultAuthenticationMethod() {
    const authyStatus = await TwoFactorAuthService.getAuthyStatus();
    return authyStatus.registered
      ? VerificationMethod.PUSH_NOTIFICATION : VerificationMethod.SMS;
  }

  private static async getPaymentsPhoneNumberAsync(
    paymentIds: string[],
    verificationMethod?: VerificationMethod,
    firstPartyFlow?: boolean,
  ): Promise<any> {
    let finalVM = verificationMethod;

    if (!finalVM) {
      finalVM = await this.selectDefaultAuthenticationMethod();
    }

    const response = firstPartyFlow
      ? await instance.post(`/payments/verification/submit/all-roles?method=${finalVM}`, { paymentIds })
      : await instance.post(`/payments/verification/submit?method=${finalVM}`, {
        paymentIds,
      });

    return response.data;
  }

  private static async getPaymentsApprovalPhoneNumberAsync(
    paymentIds: string[],
    verificationMethod?: VerificationMethod,
  ): Promise<any> {
    let finalVM = verificationMethod;
    if (!finalVM) {
      finalVM = await this.selectDefaultAuthenticationMethod();
    }
    const response = await instance.post(`/payments/verification/approve?method=${finalVM}`, { paymentIds });

    return response.data;
  }
}

export default AuthyService;
