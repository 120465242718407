import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography, palette }) => ({
    root: {
      padding: '22px',
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
    },
    header: {
      fontWeight: typography.fontWeightSemiBold,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    emptyTable: {
      height: '341px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    amount: {
      fontWeight: 600,
    },
    tableLink: {
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    status: {
      minWidth: '130px',
      textAlign: 'center',
    },
    tableButton: {
      textTransform: 'none',
      fontSize: 14,
      padding: 0,
      fontWeight: 600,
      color: 'rgb(9, 131, 117)',
      textDecoration: 'none',
      textAlign: 'left',
      justifyContent: 'left',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
    paymentTable: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0px 0px 12px 0px',
    },
  }), { name: 'Payments' },
);

export default useStyles;
