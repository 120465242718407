import React from 'react';

import { DrawdownDto } from '@alpha/fx-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';

import Body from './Body/Body';
import useStyles from './DrawdownInfoDrawer.styles';
import Header from './Header';

interface IProps {
  selectedDrawdown: DrawdownDto | undefined,
  open: boolean;
  handleClose: () => void;
  handleDrawdownPaymentContractNoteDownload: (paymentId: string) => void;
}

const DrawdownInfoDrawer: React.FC<IProps> = ({
  selectedDrawdown,
  open,
  handleClose,
  handleDrawdownPaymentContractNoteDownload,
}: IProps) => {
  const styles = useStyles();

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={() => handleClose()}
        anchor="right"
      >
        <Header
          selectedDrawdown={selectedDrawdown}
        />
        {selectedDrawdown
          && (
            <div className={styles.drawerBody}>
              {selectedDrawdown
                && (
                  <Body
                    selectedDrawdown={selectedDrawdown}
                    handleDrawdownPaymentContractNoteDownload={
                      handleDrawdownPaymentContractNoteDownload
                    }
                  />
                )}
            </div>
          )}
      </BaseDrawer.Drawer>
    </>
  );
};

export default DrawdownInfoDrawer;
