import React, { ChangeEvent } from 'react';

import { AccountDto } from '@alpha/auth-dtos';
import { Chip, TextField } from '@alpha/ui-lib/ui/external';
import { Autocomplete } from '@alpha/ui-lib/ui/Select/AutocompleteSelect';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './MultiSelectEntityDropdown.styles';

  interface IProps {
    options: AccountDto[];
    currentOptions: AccountDto[];
    disabled?: boolean;
    loading?: boolean;
    onClose?: (event: ChangeEvent<{}>) => void;
    onOpen?: (event: ChangeEvent<{}>) => void;
    setCurrentOptions: React.Dispatch<React.SetStateAction<any[]>>;
    setTextValue: React.Dispatch<React.SetStateAction<string>>;
    id?: string;
  }

const MultiSelectEntityDropDown: React.FC<IProps> = ({
  options,
  currentOptions,
  disabled,
  loading,
  onClose,
  onOpen,
  setCurrentOptions,
  setTextValue,
  id,
}: IProps) => {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      id={id}
      value={currentOptions}
      onOpen={onOpen}
      onClose={onClose}
      loading={loading}
      disabled={disabled || false}
      popupIcon={<FontAwesomeIcon icon={faAngleDown as IconProp} />}
      className={classes.dropdown}
      onInputChange={(event, value) => setTextValue(value)}
      onChange={(event: ChangeEvent<{}>, value: any | null) => setCurrentOptions(value)}
      disableCloseOnSelect
      options={options}
      renderInput={(params) => (
        <TextField {...params} />
      )}
      getOptionLabel={(option) => option.name}
      renderTags={(_value: any[], getTagProps) => _value.map(
        (value: any, index: number) => (
          <div
            className={classes.chip}
          >
            <Chip
              label={value.name}
              {...getTagProps({ index })}
            />

          </div>
        ),
      )}
    />
  );
};

export default MultiSelectEntityDropDown;
