import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import {
  CircularProgress, MenuItem, Select,
} from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import { formatCurrency } from '../../../../../../utils/currency.helpers';

import useStyles from './ForwardContractSelectDropdown.styles';

type TForwardTradeData = {
  id: string,
  contractNumber: string,
  fundingCurrency: string,
  buyCurrency: string,
  buyBalance: number
};

interface IProps {
  data: TForwardTradeData[];
  selectValue?: string;
  selectName?: string;
  placeholderText?: string;
  disabled?: boolean;
  testId?: string;
  handleChange: (event: any) => void;
  isLoading: boolean;
}

const ForwardContractSelectDropdown: React.FC<IProps> = (props: IProps) => {
  const {
    data,
    selectValue,
    selectName,
    placeholderText,
    disabled,
    testId,
    handleChange,
    isLoading,
  } = props;
  const styles = useStyles();

  const [showValueOrPlaceHolder, setShowValueOrPlaceholder] = useState<string | undefined>(selectValue || 'placeholder');

  const dropdownItems = data.map((option) => (
    <MenuItem
      className={styles.menuItem}
      key={option.id}
      value={option.id}
    >
      {option.contractNumber}
      {' '}
      <span style={{ color: '#aaa', marginLeft: '10px' }}>
        {option.fundingCurrency}
        /
        {option.buyCurrency}
      </span>
      <span style={{
        color: '#aaa', marginLeft: '10px', textAlign: 'right', width: '100%',
      }}
      >
        {formatCurrency(option.buyCurrency, option.buyBalance)}
      </span>
    </MenuItem>
  ));

  const renderedPlaceHolderText = data.length ? (placeholderText || t('select_a_contract_to_drawdown_from')) : t('no_eligible_forward_contracts_available_to_drawdown');

  useEffect(() => {
    setShowValueOrPlaceholder(selectValue || 'placeholder');
  }, [selectValue]);

  return (
    <div className={styles.dropdownContainer}>
      {
        isLoading
          ? <CircularProgress size={20} />
          : (
            <>
              <Select
                disabled={disabled}
                onChange={handleChange}
                value={showValueOrPlaceHolder}
                name={selectName}
                id={testId || 'dropdown'}
                fullWidth
                disableUnderline
                className={styles.dropdown}
                placeholder={t('select_a_contract_to_drawdown_from')}
              >
                {[
                  <MenuItem value="placeholder" disabled>
                    <Typography variant="subtitle1" className={styles.placeholder}>
                      {renderedPlaceHolderText}
                    </Typography>
                  </MenuItem>,
                  ...dropdownItems,
                ]}
              </Select>
            </>
          )
      }
    </div>
  );
};

export default ForwardContractSelectDropdown;
