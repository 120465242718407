import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      padding: '70px 55px 20px 55px',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
  { name: 'Header' },
);

export default useStyles;
