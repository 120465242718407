import { FormikProps } from 'formik';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { PaymentOrigin, PaymentPurposeDto } from '@alpha/payments-dtos';

// Single Payment
export type FormikPaymentType = {
  id?: string,
  beneficiary?: BeneficiaryDto,
  amount?: number,
  date?: string | null,
  purpose?: PaymentPurposeDto | string,
  reference?: string,
  emailAddresses: string[],
  singlePayment?: boolean,
  paymentOrigin: PaymentOrigin,
  tradeId?: string, // For adding payment AFTER booking Trade
  tradeDraftId?: string, // For fund payments by Spot flow, where trade is not booked yet
  invoiceNumber?: string, // For Service type payment purposes
  invoiceDate?: string, // For Service type payment purposes
  purposeType?: string,
}

// Batch of Payments
export type FormikValueProps = FormikProps<FormikType>;
export type FormikType = {
  paymentCurrencyAccount?: CurrencyAccountDto;
  sellCurrencyAccount?: CurrencyAccountDto;
  payments?: FormikPaymentType[];
  isDraftPayment?: boolean;
  sellCurrencyCode?: string,
  paymentCurrencyCode?: string,
  tradeDraftId?: string,
  tradeId?: string,
  paymentOrigin: PaymentOrigin,
}
export const initialValues: FormikType = {
  paymentOrigin: PaymentOrigin.STANDALONE,
};

export const validation = {
  paymentCurrencyAccount: yup
    .object({
      id: yup.string().required(),
    })
    .required(t('please_select_a_currency_account')),
};

export default validation;
