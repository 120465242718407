import React, { useEffect, useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import CreditSummary from 'components/Molecules/Credit/CreditSummary/CreditSummary';
import ExternalTradesMtm from 'components/Molecules/Credit/ExternalTradesMtm/ExternalTradesMtm';
import FacilityTermsTable from 'components/Molecules/Credit/FacilityTermsTable';
import FacilityUtilisation from 'components/Molecules/Credit/FacilityUtilisation';
import TermsLetterDrawer from 'components/Molecules/Credit/TermsLetterDrawer/TermsLetterDrawer';
import EmptyTable from 'components/Table/EmptyTable';
import MTMSummary from 'domain/CreditFacility/MTMSummary/MTMSummary';
import PositionValuationTable from 'domain/CreditFacility/PositionValuationTable';
import useAuthorization from 'hooks/useAuthorization';
import useLog from 'hooks/useLog';
import useQueryString from 'hooks/useQueryString';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { UserRole } from 'models/user';
import moment from 'moment';
import { useSelector } from 'react-redux';
import browserHistory from 'services/history/browserHistory';
import { TStore } from 'store';
import getDefaultMtmDate from 'utils/fxTrades/getDefaultMtmDate';
import t from 'utils/translationHelper';

import { CreditFacilityDto } from '@alpha/credit-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Card } from '@alpha/ui-lib/ui/layout/Card/Card';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AmendmentRequestModal from './AmendmentRequestModal/AmendmentRequestModal';
import useStyles from './index.styles';
import OptionsPositionValuationTable from './OptionsPositionValuationTable';
import useCreditSummary from './useCreditSummary';
import { CreditFacilityTermStatus } from '@alpha/credit-dtos';

// eslint-disable-next-line max-lines-per-function
const CreditFacility: React.FC = () => {
  const styles = useStyles();
  const { homePageUrl } = useSwitchAccount();
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState<string>();
  const [buyCurrencies, setBuyCurrencies] = useState<string[]>([]);
  const [soldCurrencies, setSoldCurrencies] = useState<string[]>([]);
  const [mtmDate, setMtmDate] = useQueryString('date', undefined);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const [termsIds, setTermsIds] = useState<string[]>([]);
  const [openTermsLetterDrawer, setOpenTermsLetterDrawer] = useState(false);
  const { authorized: creditAdmin } = useAuthorization(
    [[UserRole.CREDIT_ADMIN]],
  );
  const creditFacility = useSelector<TStore, CreditFacilityDto>(
    (state: TStore) => state.creditFacility.facility,
  );
  const facilityMode = useSelector<TStore, string>(
    (state: TStore) => state.creditFacility.facilityMode,
  );
  const facilityLoading = useSelector<TStore, boolean>(
    (state: TStore) => state.creditFacility.facilityLoading,
  );
  const { logEvent } = useLog();
  const [hasExternalTrades, setHasExternalTrades] = useState(false);

  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];
  const styledTitle = (
    <p className={styles.styledTitle}>
      {t('credit_facility')}
      {' '}
      |
      <p className={styles.header}>
        {' '}
        {t('overview')}
        {' '}
      </p>
    </p>
  );

  const creditSummary = useCreditSummary(creditFacility);
  useEffect(() => {
    // re-route if only 1 active ft
    // don't reroute if coming to this route via clicking the back button
    const activeTerms = creditFacility?.facilityTerms?.filter((term) => term.status === CreditFacilityTermStatus.ACTIVE)
    if (creditFacility?.facilityTerms?.length >= 1 && activeTerms?.length === 1) {
      if(browserHistory.location.state?.from !== '/credit-facility/term' && browserHistory.location.state?.from !== '/dashboard'){
        browserHistory.push(`/app/credit-facility/term/${activeTerms[0].id}`);
      }
    }
    setSelectedCurrency(creditFacility?.currencyCode);
  }, [facilityMode, creditFacility]);

  useEffect(() => {
    if (creditFacility?.rateDate) {
      setMtmDate(moment(creditFacility?.rateDate).format('YYYY-MM-DD'));
    } else if (!creditFacility?.rateDate && !mtmDate) {
      const date = getDefaultMtmDate();
      setMtmDate(date);
    }
  }, [creditFacility?.rateDate]);

  if (facilityLoading) {
    return <FullPageLoader testId="loading-credit-facility" />;
  }

  if (!creditFacility?.facilityTerms?.length) {
    return (
      <APMainLayout
        data-id="credit-facility"
        title={styledTitle}
        breadCrumbs={breadcrumbs}
        height="165px"
      >
        <Card>
          <div style={{ padding: '16px' }}>
            <EmptyTable
              title={t('no_credit_facility')}
              subtitle={t('you_currently_do_not_have_any_credit_facility')}
              icon={clipboardCross}
            />
          </div>
        </Card>
      </APMainLayout>
    );
  }

  const facilityUtilisation = (creditFacility?.facilityTerms?.length ? (
    <FacilityUtilisation
      facility={creditFacility}
    />
  )
    : (
      <div className={[styles.widget, styles.creditWidget].join(' ')}>
        <EmptyTable
          title={t('no_utilisation_data_available')}
          subtitle={(
            <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: '300' }}>
              {t('there_are_currently_no_term_limits_or_open_positions')}
              <br />
              {t('you_will_be_able_to_see_your_utilisation_once_these_limits_are_set')}
            </Typography>
          )}
          icon={clipboardCross}
          className={styles.emptyTableUtilisation}
        />
      </div>
    )
  );

  const facilityTermsTable = (<FacilityTermsTable span={2} shouldUseBrackets />);

  return (
    <>
      <APMainLayout
        data-id="credit-facility"
        title={styledTitle}
        breadCrumbs={breadcrumbs}
        height="165px"
        sticky
        rightAlignedCta={
          creditAdmin ? (
            <Box display="flex" gridGap={8}>
              <ActionButton
                size="medium"
                startIcon={<FontAwesomeIcon icon={faDownload} />}
                style={{ background: '#F7F7F7', color: '#212529' }}
                onClick={() => {
                  logEvent({ action: 'Clicked on download terms letter' });
                  setOpenTermsLetterDrawer(true);
                }}
              >
                {t('download_terms_letter')}
              </ActionButton>
              <ActionButton
                size="medium"
                onClick={() => {
                  logEvent({ action: 'Clicked on amendment request' });
                  setOpenModal(true);
                }}
              >
                {t('amendment_request')}
              </ActionButton>
            </Box>
          ) : undefined
        }
      >
        <Box className={styles.root}>
          <Box className={styles.panel}>
            {creditSummary && <CreditSummary summaries={creditSummary} />}
            {facilityTermsTable}
            {' '}
            {facilityUtilisation}
            <MTMSummary
              title={t('forwards_position_valuation_by_CP')}
              fullWidth
              mtmDate={mtmDate}
              setMtmDate={setMtmDate}
              selectedCurrency={selectedCurrency || 'GBP'}
              searchText={searchText}
              buyCurrency={buyCurrencies}
              soldCurrency={soldCurrencies}
              setBuyCurrencies={setBuyCurrencies}
              setSoldCurrencies={setSoldCurrencies}
              setSelectedCurrency={setSelectedCurrency}
              setTermsIds={setTermsIds}
              termsIds={termsIds}
              enableFilter
              shouldUseBrackets
              hasExternalTrades={hasExternalTrades}
              facilityMode={facilityMode}
            />
            <PositionValuationTable
              fullWidth
              testId="position-valuation-table"
              mtmDate={mtmDate}
              setSearchTextForReport={setSearchText}
              buyCurrencies={buyCurrencies}
              sellCurrencies={soldCurrencies}
              setBuyCurrencies={setBuyCurrencies}
              setSoldCurrencies={setSoldCurrencies}
              setTermsIds={setTermsIds}
              termsIds={termsIds}
              shouldUseBrackets
              enableFilter={false}
              title={`${t('forwards')}: `}
              isCredit
              selectedCurrency={selectedCurrency}
            />
            <OptionsPositionValuationTable
              fullWidth
              testId="options-position-valuation-table"
              alertText={t('for_more_detail_on_options_contact_dealer')}
              mtmDate={mtmDate}
              setSearchTextForReport={setSearchText}
              buyCurrencies={buyCurrencies}
              sellCurrencies={soldCurrencies}
              termsIds={termsIds}
              shouldUseBrackets
              selectedCurrency={selectedCurrency}
            />
            <ExternalTradesMtm mtmDate={mtmDate} setHasExternalTrades={setHasExternalTrades} selectedCurrency={selectedCurrency} />
            <AmendmentRequestModal
              handleClose={() => setOpenModal(false)}
              open={openModal}
            />
            <TermsLetterDrawer
              open={openTermsLetterDrawer}
              onClose={() => {
                setOpenTermsLetterDrawer(false);
              }}
            />
          </Box>
        </Box>
      </APMainLayout>
    </>
  );
};

export default CreditFacility;
