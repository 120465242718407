import React from 'react';
import t from 'utils/translationHelper';

import { PaymentDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import Warning from '../../../../assets/warning.svg';
import APModal from '../../../Modals/APModal/APModal';
import ApprovalSummary from '../ApprovalDrawer/ApprovalSummary';
import useStyles from '../RejectPaymentModal/index.styles';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleAcknowledge: () => void;
  payments: PaymentDto[];
}

const InvalidApprovalModal: React.FC<Props> = ({
  open, payments, handleClose, handleAcknowledge,
}: Props) => {

  const styles = useStyles();
  const langPreference = localStorage.getItem('i18nextLng');
  let notApprovedStatement = (
    <div style={{ marginBottom: '48px' }}>
      <Typography style={{ fontWeight: '800', marginBottom: '16px', fontSize: '16px' }}>
        {t('the_following')}
        {payments.length === 1 ? ` ${t('payment_was')} ` : ` ${payments.length} ${t('payments_were')} `}
        {t('not_approved')}
      </Typography>
      <Typography>
        {t('please_check_and_try_again')}
      </Typography>
    </div>
  );
  if (langPreference === 'de') {
    notApprovedStatement = (
      <div style={{ marginBottom: '48px' }}>
        <Typography style={{ fontWeight: '800', marginBottom: '16px', fontSize: '16px' }}>
          {payments.length === 1 ? t('the_following_payment_was_not_approved') : t('the_following_payments_were_not_approved')}
        </Typography>
      </div>
    );
  }

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div style={{ textAlign: 'center', padding: '0 32px', width: '700px' }}>
          <img src={Warning} style={{ width: '70px', marginBottom: '24px' }} alt="Warning" />
          {notApprovedStatement}
          <div style={{ textAlign: 'left' }}>
            <ApprovalSummary values={payments} />
          </div>
          <div style={{ width: '100%' }}>
            <ActionButton size="large" style={{ width: '250px', marginTop: '53px' }} onClick={handleAcknowledge}>{t('acknowlege')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default InvalidApprovalModal;
