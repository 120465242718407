import { Guid } from 'guid-typescript';
import { TPaymentSubmissionResponse } from 'models/payments';
import {
  call, delay, put, select, takeLatest,
} from 'redux-saga/effects';
import instance from 'services/Axios/instance';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import IATService from 'services/IAT/IAT.service';
import PaymentsService from 'services/Payments/payments.service';
import { actions as notificationsActions } from 'store/notifications/notifications.reducer';
import t from 'utils/translationHelper';

import { TransferApprovalResponseDto } from '@alpha/currency-accounts-dtos';
import { PaymentsApprovalResponse } from '@alpha/payments-dtos';
import { PayloadAction } from '@reduxjs/toolkit';

import { TStore } from '..';

import { Actions } from './actions';
import {
  actions, AuthyPageStates,
  TAuthyBeneficiaryBatchType, TAuthyBeneficiaryBatchUploadType, TAuthyBeneficiaryType, TAuthyDrawdownType,
  TAuthyPaymentsType,
  TAuthyTradeType,
  TAuthyTransferType,
} from './reducer';

type TSubmitBeneficiary = {
  type: TAuthyBeneficiaryType;
  totp: number;
  softToken: boolean;
};

type TSubmitBeneficiaryBatch = {
  type: TAuthyBeneficiaryBatchType;
  totp: number;
  softToken: boolean;
}

type TSubmitBeneficiaryBatchUpload = {
  type: TAuthyBeneficiaryBatchUploadType;
  totp: number;
  softToken: boolean;
}

type TSubmitDrawdown = {
  type: TAuthyDrawdownType;
  totp: number;
  approvalRequestId: string;
  dynamicLinkingId: string;
  canApproveOwn: boolean;
  softToken: boolean;
}

type TSubmitTrade = {
  type: TAuthyTradeType;
  totp: number;
  approvalRequestId: string;
  dynamicLinkingId: string;
  softToken: boolean;
}

function* initiate({
  payload,
}: PayloadAction<TAuthyBeneficiaryType
  | TAuthyPaymentsType
  | TAuthyBeneficiaryBatchType
  | TAuthyDrawdownType
  | TAuthyTradeType
>) {
  yield put(actions.initiate(payload));
}

const getBatchIdFromState = (
  state: TStore,
) => state.authy.type;

const getPageStateFromState = (
  state: TStore,
) => state.authy.pageState;

const approveBeneficiaryAsync = async ({
  batchId,
  code,
  softToken,
}: TAuthyBeneficiaryType & { code: number, softToken: boolean }) => {
  let response;
  if (typeof batchId === 'string') {
    response = await instance.post(
      '/bene/beneficiaries/approve',
      { totp: code, beneficiaryIds: [batchId], softToken },
    );
  } else {
    response = await instance.post(
      '/bene/beneficiaries/approve',
      { totp: code, beneficiaryIds: batchId, softToken },
    );
  }

  if (response.status !== 200) {
    throw Error(`${response.data.error}`);
  }
};

const submitBeneficiaryAsync = async ({
  batchId,
  code,
  softToken,
}: { batchId: string, code: number, softToken: boolean }) => {
  const response = await instance.post(
    `/bene/beneficiaries/${batchId}/submit`,
    { totp: code, softToken },
  );

  if (response.status !== 200) {
    throw Error(`${response.data.error}`);
  }
};

async function submitBeneficiary(params: TSubmitBeneficiary) {
  await submitBeneficiaryAsync({
    batchId: params.type.batchId,
    code: params.totp,
    softToken: params.softToken,
  });
  if (params.type.approverOwn) {
    await approveBeneficiary(params);
  }
}

async function submitBatchBeneficiary(params: TSubmitBeneficiaryBatchUpload) {
  await submitBatchBeneficiaryAsync({
    batchId: params.type.batchId,
    code: params.totp,
    softToken: params.softToken,
  });
}

const submitBatchBeneficiaryAsync = async ({
  batchId,
  code,
  softToken,
}: { batchId: string, code: number, softToken: boolean }) => {
  const response = await instance.post(
    `/bene/batches/${batchId}/submit`,
    { totp: code, softToken },
  );

  if (response.status !== 200) {
    throw Error(`${response.data.error}`);
  }
};

async function approveBeneficiary(params: TSubmitBeneficiary) {
  await approveBeneficiaryAsync({
    ...params.type, code: params.totp, softToken: params.softToken,
  });
}

async function approveBeneficiaryBatch(params: TSubmitBeneficiaryBatch) {
  await BeneficiariesService
    .approveBeneficiaryShareRequest(params.type.batchId, params.totp, params.softToken);
}

async function submitDrawdown(params: TSubmitDrawdown) {
  await FXTradeService.postSubmitDrawdownAndApprovePayments({
    drawdownId: params.type.drawdownId,
    paymentIds: params.type.paymentIds,
    dynamicLinkingId: params.dynamicLinkingId,
    totp: String(params.totp),
    approvalRequestId: params.approvalRequestId,
    softToken: params.softToken,
  },
  params.canApproveOwn);
}
type TSubmitPayment = {
  paymentIds: string[];
  approvalRequestId: string;
  dynamicLinkingId: string;
  totp: string;
  canApproveOwn: boolean;
  softToken: boolean;
  firstPartyFlow?: boolean;
};
type TSubmitTransfer = {
  transferId: string;
  approvalRequestId: string;
  dynamicLinkingId: string;
  totp: string;
  canApproveOwn: boolean;
  softToken: boolean;
};
async function submitPayment(params: TSubmitPayment): Promise<TPaymentSubmissionResponse> {
  const response = await PaymentsService.submitAndApprovePayment(
    {
      paymentIds: params.paymentIds,
      totp: params.totp,
      approvalRequestId: params.approvalRequestId,
      dynamicLinkingId: params.dynamicLinkingId || 'empty',
      softToken: params.softToken,
    },
    params.canApproveOwn,

  );
  return response;
}

async function submitApprovePayment(params: TSubmitPayment): Promise<PaymentsApprovalResponse> {
  return PaymentsService.approvePayment(
    {
      paymentIds: params.paymentIds,
      approvalRequestId: params.approvalRequestId,
      dynamicLinkingId: params.dynamicLinkingId,
      totp: params.totp,
      softToken: params.softToken,
    },
  );
}

async function submitApproveTransfer(params: TSubmitTransfer):
  Promise<TransferApprovalResponseDto> {
  return IATService.approveTransfer(
    {
      approvalRequestId: params.approvalRequestId,
      dynamicLinkingId: params.dynamicLinkingId,
      totp: params.totp,
      softToken: params.softToken,
    },
    params.transferId,
  );
}

async function submitTrade(params: TSubmitTrade) {
  const response = await FXTradeService.submitTrade({
    tradeId: (params.type as TAuthyTradeType).id,
    code: String(params.totp),
    approvalRequestId: params.approvalRequestId,
    dynamicLinkingId: params.dynamicLinkingId,
    softToken: params.softToken,
  });

  return response;
}

async function submitTradePADApproval(params: TSubmitTrade) {
  const response = await FXTradeService.putSubmitTradePADApproval(
    (params.type as TAuthyTradeType).id,
    {
      totp: String(params.totp),
      approvalRequestId: params.approvalRequestId,
      dynamicLinkingId: params.dynamicLinkingId,
      softToken: params.softToken,
    },
  );

  return response;
}

async function submitDrawdownPADApproval(params: TSubmitDrawdown) {
  const response = await FXTradeService.putSubmitDrawdownPADApproval(
    (params.type as TAuthyDrawdownType).drawdownId,
    {
      totp: String(params.totp),
      approvalRequestId: params.approvalRequestId,
      dynamicLinkingId: params.dynamicLinkingId,
      softToken: params.softToken,
    },
  );

  return response;
}

function* submit({ payload }: PayloadAction<{
  totp: number, approvalRequestId?: string, dynamicLinkingId?: string
}>) {
  try {
    const pageState: AuthyPageStates = yield select(getPageStateFromState);
    const softToken = pageState.toLowerCase() === 'authy';

    yield put(actions.updateStatus('CODE SUBMITTED'));
    const authyType:
      | TAuthyPaymentsType
      | TAuthyBeneficiaryType
      | TAuthyDrawdownType
      | TAuthyTradeType
      | TAuthyBeneficiaryBatchType
      | TAuthyTransferType
      | TAuthyBeneficiaryBatchUploadType
      | undefined = yield select(getBatchIdFromState);

    if (!authyType
      || (
        !(authyType as TAuthyPaymentsType).paymentIds
        && !(authyType as TAuthyBeneficiaryBatchType).batchId
      && !(authyType as TAuthyTransferType).transferId
      && !(authyType as TAuthyBeneficiaryType).batchId
        && !(authyType as TAuthyTradeType).id)) {
      throw Error('Unable to find the payload you are trying to submit');
    }

    let successMessage = '';
    const snackbarVariant = 'success';
    if (authyType?.type === 'PAYMENTS') {
      const { firstPartyFlow } = authyType as TAuthyPaymentsType;
      const response: TPaymentSubmissionResponse = yield call(submitPayment, {
        paymentIds: (authyType as TAuthyPaymentsType).paymentIds,
        approvalRequestId: payload.approvalRequestId!,
        dynamicLinkingId: payload.dynamicLinkingId!,
        canApproveOwn: firstPartyFlow ? true : Boolean(authyType.approverOwn),
        totp: payload.totp.toString(),
        softToken,
        firstPartyFlow,
      });

      if (response?.success || !response?.pending) {
        yield put(actions.updateAuthyExtraInfo(response));
      }

      successMessage = `${t('payment_successfully_submitted')} ${authyType.approverOwn ? t('and_approved') : ''
      }`;
    } else if (authyType?.type === 'PAYMENT_APPROVE') {
      const { firstPartyFlow } = authyType as TAuthyPaymentsType;
      const response: PaymentsApprovalResponse = yield call(submitApprovePayment, {
        paymentIds: (authyType as TAuthyPaymentsType).paymentIds,
        approvalRequestId: payload.approvalRequestId!,
        dynamicLinkingId: payload.dynamicLinkingId!,
        totp: payload.totp.toString(),
        canApproveOwn: firstPartyFlow ? true : Boolean(authyType.approverOwn),
        softToken,
        firstPartyFlow,
      });
      yield put(actions.updateAuthyExtraInfo(response));
    } else if (authyType?.type === 'TRANSFER_APPROVE') {
      const response: TransferApprovalResponseDto = yield call(submitApproveTransfer, {
        transferId: (authyType as TAuthyTransferType).transferId,
        approvalRequestId: payload.approvalRequestId!,
        dynamicLinkingId: payload.dynamicLinkingId!,
        totp: payload.totp.toString(),
        canApproveOwn: Boolean(authyType.approverOwn),
        softToken,
      });
      yield put(actions.updateAuthyExtraInfo(response));
    } else if (authyType?.type === 'BENEFICIARY') {
      if ((authyType as TAuthyBeneficiaryType).submission) {
        yield call(submitBeneficiary, {
          type: authyType as TAuthyBeneficiaryType,
          totp: payload.totp,
          softToken,
        });
        successMessage = t('successfully_submitted_bene');
      } else {
        yield call(approveBeneficiary, {
          type: authyType as TAuthyBeneficiaryType,
          totp: payload.totp,
          softToken,
        });
        successMessage = t('successfully_approved_bene');
      }
    } else if (authyType?.type === 'BENEFICIARY_BATCH') {
      yield call(approveBeneficiaryBatch, {
        type: authyType as TAuthyBeneficiaryBatchType,
        totp: payload.totp,
        softToken,
      });
      successMessage = t('successfully_shared_selected_bene');
    } else if (authyType?.type === 'BENEFICIARY_BATCH_UPLOAD') {
      yield call(submitBatchBeneficiary, {
        type: authyType as TAuthyBeneficiaryBatchUploadType,
        totp: payload.totp,
        softToken,
      });
      successMessage = t('successfully_submitted_batch_upload');
    } else if (authyType?.type === 'DRAWDOWN') {
      yield call(submitDrawdown, {
        type: authyType as TAuthyDrawdownType,
        totp: payload.totp,
        approvalRequestId: payload.approvalRequestId!,
        dynamicLinkingId: payload.dynamicLinkingId!,
        canApproveOwn: (authyType as TAuthyDrawdownType).approverOwn,
        softToken,
      });
    } else if (authyType?.type === 'TRADE') {
      yield call(submitTrade, {
        type: authyType as TAuthyTradeType,
        totp: payload.totp,
        approvalRequestId: payload.approvalRequestId || '',
        dynamicLinkingId: payload.dynamicLinkingId || '',
        softToken,
      });
      successMessage = t('successfully_validated');
    } else if (authyType?.type === 'TRADE_PAD_APPROVE') {
      yield call(submitTradePADApproval, {
        type: authyType as unknown as TAuthyTradeType,
        totp: payload.totp,
        approvalRequestId: payload.approvalRequestId || '',
        dynamicLinkingId: payload.dynamicLinkingId || '',
        softToken,
      });
    } else if (authyType?.type === 'DRAWDOWN_PAD_APPROVE') {
      yield call(submitDrawdownPADApproval, {
        type: (authyType as unknown as TAuthyDrawdownType),
        totp: payload.totp,
        approvalRequestId: payload.approvalRequestId || '',
        dynamicLinkingId: payload.dynamicLinkingId || '',
        canApproveOwn: (authyType as TAuthyDrawdownType).approverOwn || false,
        softToken,
      });
    }

    yield put(actions.updateStatus('SUCCESS'));
    if (successMessage) {
      yield put(
        notificationsActions.enqueueSnackbar({
          variant: snackbarVariant as any,
          key: Guid.create().toString(),
          message: successMessage,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        }),
      );
    }
    yield delay(1000);
    yield put(actions.reset());
  } catch (e) {
    if (e.response?.data?.error) {
      yield put(actions.updateStatus('INITIATED'));
      yield put(
        notificationsActions.enqueueSnackbar({
          variant: 'error',
          key: Guid.create().toString(),
          message: e.response?.data?.error || t('invalid_authy_code'),
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        }),
      );
    } else {
      yield put(actions.updateStatus('ERROR'));
      yield put(
        notificationsActions.enqueueSnackbar({
          variant: 'error',
          key: Guid.create().toString(),
          message: e.message || e.error || t('an_error_occurred_please_try_again'),
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        }),
      );
      yield delay(3000);
      yield put(actions.reset());
    }
  }
}

export function* authySaga() {
  yield takeLatest(Actions.INITIATE, initiate);
  yield takeLatest(Actions.SUBMIT, submit);
}

export default authySaga;
