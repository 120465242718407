import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  rejectButton: {
    background: '#fffffff',
    border: '#B95656 solid 1px',
    color: '#B95656FF',
    '&:hover': {
      background: '#B9565633',
      color: '#B95656FF',
    },
  },
  approveButton: {
    marginLeft: '12px',
    backgroundColor: '#1E8777',
    border: '1px solid #1E8777',
  },
}),
{ name: 'RightAlignedCta' });

export default useStyles;
