import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  header: {
    letterSpacing: -0.5,
    lineHeight: '26px',
    marginBottom: '112px',
  },
  form: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
  },
  // Amount and balance
  root: {
    '& .MuiFormLabel-root, .MuiTypography-subtitle2': {
      fontSize: '14px',
      color: '#333333',
      marginBottom: '8px',
    },
    '& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': { '-moz-appearance': 'textfield' },
    padding: '20px 30px',
  },
  selectAccount: {
    marginBottom: '40px',
  },
  inputAmount: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputLabel: {
    padding: '4px 30px',
    '& label': {
      marginTop: '20px',
      marginBottom: '20px',
    },
  },
  rateContainer: {
    marginTop: '20px',
    marginBottom: '40px',
    width: '100%',
    '& > div': {
      transform: 'translateY(-67%)',
      marginLeft: '30px',
    },
  },
  swapButton: {
    padding: '14px',
    minHeight: '33px',
    background: '#e7e7e7',
    color: '#212529',
    borderRadius: '10px',
    '&:hover': {
      background: '#c4c4c4',
    },
    '&:disabled': {
      background: '#e7e7e7',
    },
  },
  insufficientFundsTable: {
    '& tr:last-child td:last-child': {
      color: '#b95656 !important',
    },
  },
  sufficientFundsTable: {
    '& tr:last-child td:last-child': {
      color: '#1E8777 !important',
    },
  },
  willDebit: {
    width: '100%',
    '& .MuiInputBase-input': {
      width: '80%',
      maxWidth: '250px',
    },
    '& .MuiInput-root': {
      fontSize: '40px',
      fontWeight: 300,
      color: '#343434',
    },
  },
  liveRateLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  imageHolder: {
    display: 'flex',
    marginLeft: '5px',
    marginBottom: '5px',
  },
  balanceAfter: {
    marginTop: '20px',
    width: '200px',
    maxWidth: '250px',
    overflow: 'hidden',
    '& span': {
      marginLeft: '4px',
    },
    '& .MuiTypography-subtitle2': {
      textAlign: 'left',
    },
  },
  fixedIndicatorActive: {
    backgroundColor: 'rgba(8, 17, 31, 0.53)',
    color: '#FFFFFF',
  },
  fixedIndicatorInactive: {
    backgroundColor: 'rgba(8, 17, 31, 0.106)',
    color: '#FFFFFF',
  },
  fixedIndicatorHidden: {
    display: 'none',
    backgroundColor: 'rgba(8, 17, 31, 0.106)',
    color: '#FFFFFF',
  },
  amountInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& div > input.MuiInputBase-input': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  valueDateContainer: {
    padding: '10px 30px',
    margin: '10px 0 20px 0',
    '& .datePicker': {
      marginTop: '10px',
    },
  },
  valueDateTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
  },
  accountInfoContainer: {
    padding: '20px',
    background: '#f7f7f7',
    margin: '20px',
  },
  progressBarNoAnimation: {
    transition: 'none',
  },
  input: {
    '&::placeholder': {
      color: 'red',
    },
  },
  black: {
    color: '#343434 !important',
  },
  grey: {
    color: 'rgb(170, 170, 170) !important',
  },
  red: {
    color: '#b95656 !important',
  },
  green: {
    color: '#1E8777 !important',
  },
  bold: {
    fontWeight: 'bold',
  },
  error: {
    position: 'absolute',
    fontSize: '12px !important',
  },
  icon: {
    padding: '0px 2px',
    color: '#2A7BB7',
  },
}), { name: 'InputSpotTrade' });

export default useStyles;
