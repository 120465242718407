import React, { useState } from 'react';

import { TradeDto } from '@alpha/fx-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import DeleteExternalTradeModal from '../../../components/Molecules/Trade/DeleteExternalTradeModal';
import useActionDropdown from '../../../hooks/useActionDropDown';
import t from 'utils/translationHelper';

import useStyles from './ExternalTradeActionDropdown.style';

interface IProps {
  trade: TradeDto;
  handleReloadExternalTradesTable?: () => void;
  handleReloadPeriodsData?: () => void;
}

const ExternalTradeActionDropdown: React.FC<IProps> = ({
  trade,
  handleReloadExternalTradesTable,
  handleReloadPeriodsData,
}: IProps) => {
  const [openDeleteExternalTrade, setOpenDeleteExternalTrade] = useState<boolean>(false);

  const classes = useStyles();


  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    setOpen,
  } = useActionDropdown();

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  const menuItems = [];

  menuItems.push(
    {
      content: (
        <Typography data-testid={`delete-trade-action-${trade.id}`}>
          {t('delete_trade')}
        </Typography>
      ),
      icon: displayIcon('Delete'),
      onClick: () => setOpenDeleteExternalTrade(true),
      underline: false,
    },
  );

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box data-testid="action-bene-dropdown" className={classes.wrapper}>
          <APMenu
            open={Boolean(open)}
            setOpen={setOpen}
            anchorEl={open}
            disableBackdropClick={menuCloseCount === 1}
            disableHoverEffect={false}
            onClose={handleClose}
            buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
            buttonTitle=""
            // @ts-ignore
            icon={faEllipsisH}
          >
            {
              menuItems.map((item) => <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>)
            }
          </APMenu>
        </Box>
      </ClickAwayListener>
      {trade && (
        <DeleteExternalTradeModal
          tradeId={trade?.id}
          open={openDeleteExternalTrade}
          handleClose={() => setOpenDeleteExternalTrade(false)}
          handleReloadTradesTable={handleReloadExternalTradesTable}
          handleReloadPeriodsData={handleReloadPeriodsData}
        />
      )}
    </>
  );
};

export default ExternalTradeActionDropdown;
