import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { UserAccountDto } from '@alpha/auth-dtos';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAccountSettings from '../../../../hooks/useAccountSettings';
import { UserRole } from '../../../../models/user';

import useStyles from './PersonalPermissionsMatrixTable.styles';

interface IPersonalPermissionsMatrixProps {
  userAccounts: UserAccountDto[];
  setUserAccounts?: React.Dispatch<React.SetStateAction<UserAccountDto[]>>,
  readonly?: boolean;
  padAllowed?: boolean;
}

const PersonalPermissionsMatrixTable: React.FC<IPersonalPermissionsMatrixProps> = (
  {
    userAccounts,
    setUserAccounts,
    readonly = false,
    padAllowed = false,
  }: IPersonalPermissionsMatrixProps,
) => {
  const classes = useStyles();
  const {
    portalPermissionColumns,
    portalPermissionColumnGroups: columnGroups,
    portalPermissionColumnGroupsWithoutPad: columnGroupsWithoutPad,
  } = useAccountSettings();
  const [data, setData] = useState<any[]>([]);

  const accountColumn = [{
    header: <>{t('account')}</>,
    accessor: 'account',
    width: 200,
  }];

  const permissionColumns = portalPermissionColumns.flatMap((column) => {
    if (column.hidden || (column.forPadOnly && !padAllowed)) {
      return [];
    }

    const icon = column.caption ? (
      <APTooltip
        placement="bottom-start"
        title={column.caption ?? ''}
      >
        <Box display="inline-block">
          <FontAwesomeIcon icon={faInfoCircle as IconProp} />
        </Box>
      </APTooltip>
    ) : <></>;
    return {
      header: (
        <>
          {column.headerText || column.role}
          {' '}
          {icon}
        </>),
      accessor: column.role as string,
      width: column.width || 100,
    };
  });

  const columns = accountColumn.concat(permissionColumns);

  const getCheckbox = (account: UserAccountDto, name: string, value?: boolean,
    setValue?: React.Dispatch<React.SetStateAction<boolean>>,
    disabled = false) => (
    <Checkbox
      testId={`user-permission-${name}`}
      label=""
      checked={value}
      disabled={disabled}
      onChange={(event) => {
        if (event.target.checked) {
          account.roles.push(name);
        } else {
          const index = account.roles.indexOf(name);
          account.roles.splice(index, 1);
        }

        const tableData = mapAccountDetailToData(userAccounts);
        setData(tableData);
        if (setUserAccounts) setUserAccounts([...userAccounts]);
      }}
    />
  );

  const mapAccountDetailToData = (accounts: UserAccountDto[]) => accounts.map(
    (account) => {
      const result: {
        [key: string]: any,
      } = {
        account: (<span className={classes.greenText}>{account.name}</span>),
      };

      for (let i = 0; i < portalPermissionColumns.length; i += 1) {
        const col = portalPermissionColumns[i];
        const accessor = col.role as string;
        const isEMoneyDisabled = account.eMoneyDisabled && (col.role.includes('Payments') || col.role.includes('IAT') || col.role.includes('Spot Inputter'));
        result[accessor] = getCheckbox(
          account,
          accessor,
          account.roles.includes(portalPermissionColumns[i].role),
          undefined,
          !col.editable || readonly || !account.roles.includes(UserRole.USER_PERMISSION_MANAGER) || isEMoneyDisabled,
        );
      }
      return result;
    },
  );

  useEffect(() => {
    const tableData = mapAccountDetailToData(userAccounts);
    setData(tableData);
  }, [userAccounts, readonly]);

  const withOrWithoutPADColumnGroups = padAllowed ? columnGroups : columnGroupsWithoutPad;

  if (data.length > 0) {
    return (
      <StyledGenericTable
        testId="preference-table"
        columns={columns as any}
        data={data}
        className={classes.table}
        columnGroups={withOrWithoutPADColumnGroups.slice(0, -1)} // Skip last since no action
      />
    );
  }
  return (
    <Loader testId="permission-table-loading" />
  );
};

export default PersonalPermissionsMatrixTable;
