import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    root: {
      '&.fullWidth': {
        gridColumn: 'span 3 / auto',
        backgroundColor: '#ffffff',
        padding: '20px',
      },
      '& .MuiTable-root tr:last-child td': {
        borderBottom: 'none',
        fontWeight: '600',
      },
      '&h6': {
        fontWeight: typography.fontWeightSemiBold,
      },
      borderRadius: '10px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
    },
    verticalLine: {
      display: 'block',
      height: '100%',
      minHeight: '48px',
      border: '1px solid #F7F7F7',
      margin: '0 8px',
    },
    currencyButton: {
      border: 'none',
      height: '44px',
      '&.MuiInput-underline::after, &.MuiInput-underline::before': {
        display: 'none',
      },
      '& #currency-select': {
        minWidth: '36px',
        borderRadius: '10px',
        background: '#F7F7F7',
        color: '#212529',
        padding: '14.5px 20px 14.5px 16px',
      },
      '& .MuiSelect-icon': {
        top: 'calc(50% - 11px)',
        right: '4px',
      },
    },
    mtmValue: {
      fontWeight: typography.fontWeightSemiBold,
    },
    rate: {
      color: '#1E8777',
      fontWeight: typography.fontWeightSemiBold,
    },
    emptyTable: {
      height: '300px',
      position: 'relative',
      marginBottom: '41px',
    },
    averageRateAlert: {
      margin: '10px',
      padding: '8px',
    },
  }),
  { name: 'MTMSummary' },
);

export default useStyles;
