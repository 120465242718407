import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import i18n from 'i18n/config';
import t from 'utils/translationHelper';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { DrawdownRequest, DrawdownValidationDto, TradeDto } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { RadioButtonGroup } from '@alpha/ui-lib/ui/RadioButton';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { StyledKeyValueTable, TKeyValue } from '@alpha/ui-lib/ui/table';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAuthorization from '../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../models/user';
import { formatNumber } from '../../../../../utils/currency.helpers';
import Alert from '../../../../Alert';
import Beneficiaries from '../Beneficiaries';
import {
  FormikType,
} from '../formData';
import { Section } from '..';

import useStyles from './index.styles';

type Props = {
  handleSetSection: (_section: Section) => void,
  trade?: TradeDto,
  form?: FormikProps<DrawdownRequest>,
  buyCurrencyAccount?: CurrencyAccountDto,
  sellCurrencyAccount?: CurrencyAccountDto,
  paymentsForm: FormikProps<FormikType>,
  validatedDrawdown?: DrawdownValidationDto,
  setOpenPaymentClearWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  setWarningModalConfirmationAction?: React.Dispatch<React.SetStateAction<'BACK_TO_DRAWDOWN' | 'CLOSE_DRAWER'>>,
}

const Settlement: React.FC<Props> = ({
  handleSetSection, trade, form, buyCurrencyAccount, sellCurrencyAccount,
  paymentsForm, validatedDrawdown, setOpenPaymentClearWarning,
  setWarningModalConfirmationAction,
}) => {
  const classes = useStyles();
  const [remitPayment, setRemitPayment] = useState<boolean>(true);
  const [remitAmount, setRemitAmount] = useState<number>(0);
  const [drawdownData, setDrawdownData] = useState<TKeyValue[]>([]);

  const { authorized: isPaymentsInputter } = useAuthorization([[UserRole.PAYMENTS_INPUTTER]]);

  const paymentsTotalAmount = (): number => {
    if (paymentsForm?.values?.payments) {
      const payments = paymentsForm?.values?.payments;
      let total = 0;
      for (let i = 0; i < payments.length; i += 1) {
        total += +(payments[i].amount || 0);
      }
      return total;
    }
    return 0;
  };

  useEffect(() => {
    const buyAmount = validatedDrawdown?.buyAmount || 0;
    const sellAmount = validatedDrawdown?.sellAmount || 0;
    const newDrawdownData: TKeyValue[] = [
      { key: t('trade_number'), value: trade?.contractNumber },
      {
        key: t('drawdown_amount'),
        value: `${formatNumber(+sellAmount, 2)} ${form?.values.sellCurrencyCode}  |  ${formatNumber(+buyAmount, 2)} ${form?.values.buyCurrencyCode}`,
      },
      {
        key: t('rate'),
        value: formatNumber(trade?.rate || 0, 4),
        boldValue: true,
      },
    ];

    if (form && remitPayment && validatedDrawdown) {
      const paymentAmountInBuyCurrency = paymentsTotalAmount();
      const remainingBalanceInBuyCurrency = validatedDrawdown?.buyAmount
        - paymentAmountInBuyCurrency;

      newDrawdownData.push({
        key: t('total_payments_amount'),
        value: paymentsTotalAmount(),
        currency: form?.values.buyCurrencyCode,
        boldValue: true,
      },
      {
        key: t('drawdown_amount_balance_to_assign'),
        value: +remainingBalanceInBuyCurrency,
        currency: form?.values.buyCurrencyCode,
        boldValue: true,
      });
    }
    setDrawdownData(newDrawdownData);
  }, [remitPayment, paymentsForm?.values?.payments, form?.values.amount, validatedDrawdown]);

  useEffect(() => {
    setRemitAmount(validatedDrawdown?.finalRemittedAmount || 0);
  }, [validatedDrawdown]);

  useEffect(() => {
    if (paymentsForm.values.payments?.length! > 0) {
      setRemitPayment(true);
    }
  }, [
    paymentsForm.values.payments,
  ]);

  return (
    <>
      <div>
        <Typography className={classes.subHeading2}>{t('drawdown_details')}</Typography>
        <div className={classes.detailTable}>
          <StyledKeyValueTable
            testId="drawdown-data-table"
            data={drawdownData}
          />
        </div>
      </div>
      {isPaymentsInputter && (
        <div>
          <Typography className={classes.subHeading}>
            {t('would_you_like_to_send_payments_from_this_drawdown')}
          </Typography>
          <RadioButtonGroup
            testId="drawdown-remit-payment"
            orientation="vertical"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.value === 'false'
                && paymentsForm?.values.payments && (paymentsForm?.values?.payments?.length > 0)) {
                if (setOpenPaymentClearWarning) {
                  setOpenPaymentClearWarning(true);
                }
              } else {
                setRemitPayment(e.target.value === 'true');
              }
            }}
            value={remitPayment}
            items={[
              {
                label: (
                  <p>
                    <strong>{t('yes')}</strong>
                    {' '}
                    -
                    {' '}
                    {t('i_want_to_create_payments_to_have_funds_sent_onwards_by_alpha_fx')}
                  </p>
                ),
                value: true,
                testId: 'remit-yes',
              },
              {
                label: (
                  <p>
                    <strong>{t('no')}</strong>
                    {' '}
                    -
                    {' '}
                    {i18n.language === 'es'
                      ? t('curreny_will_be_added_to_currency_account') : `${t('the')} ${form?.values.buyCurrencyCode} 
                      ${t('bought_will_be_added_to_your_currency_account')} 
                      (${buyCurrencyAccount?.friendlyName || buyCurrencyAccount?.accountName}) ${t('held_by_alpha_fx')})`}
                  </p>
                ),
                value: false,
                testId: 'remit-no',
              },
            ]}
          />
        </div>
      )}

      <StyledDivider />

      {isPaymentsInputter && remitPayment
        ? (
          <>
            <Alert
              variant="info"
              text={t('please_note_any_payments_created_as_part_of_the_drawdown_request_will_be_processed_once_we_receive_settlement')}
            />
            <Beneficiaries
              {...paymentsForm}
              trade={trade}
              drawdownForm={form?.values}
              validatedDrawdown={validatedDrawdown}
              buyCurrencyAccount={buyCurrencyAccount}
              sellCurrencyAccount={sellCurrencyAccount}
              handleSetSection={handleSetSection}
              setOpenPaymentClearWarning={setOpenPaymentClearWarning}
              setWarningModalConfirmationAction={setWarningModalConfirmationAction}
            />
          </>
        ) : (
          <>
            <Alert
              variant="info"
              text={`${form?.values.buyCurrencyCode} ${formatNumber(remitAmount, 2)} ${t('will_be_available_in_your_currency_account')} (${buyCurrencyAccount?.friendlyName || buyCurrencyAccount?.accountName}) ${t('held_with_alpha_fx')}`}
            />
            <div style={{
              display: 'flex', flexDirection: 'row', padding: '92px 0 42px 0', justifyContent: 'space-between', rowGap: '36px',
            }}
            >
              <div style={{
                position: 'absolute', transform: 'translateY(-42px)', width: '100%', left: 0,
              }}
              >
                <BaseDrawer.LineBreak />
              </div>
              <ActionButton style={{ background: '#F7F7F7', color: '#212529' }} onClick={() => handleSetSection('Drawdown')}>
                <FontAwesomeIcon icon={faAngleLeft as IconProp} />
                {' '}
                {t('back_to_drawdown')}
              </ActionButton>
              <ActionButton onClick={() => handleSetSection('Submit')}>
                {t('continue_to_confirmation')}
                {' '}
                <FontAwesomeIcon icon={faAngleRight as IconProp} />
              </ActionButton>
            </div>
          </>
        )}
    </>
  );
};

export default Settlement;
