/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';

import { PaymentBatchDto, PaymentDto } from '@alpha/payments-dtos';
import {
  Box, Collapse, IconButton, Typography,
} from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { StyledStatSummary, TStyledStatSummaryData } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary';
import { Status } from '@alpha/ui-lib/ui/Status';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import { CurrencyPair } from '../../../../../components/International';
import { formatCurrency } from '../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../utils/formatIsoDate';

import useStyles from './ValidPayments.styles';

interface IValidPaymentProps {
  payments: PaymentDto[];
  batchSummary: PaymentBatchDto;
  loading: boolean;
}

const ValidPayments: React.FC<IValidPaymentProps> = ({
  payments, batchSummary, loading,
}: IValidPaymentProps) => {

  const styles = useStyles();

  const [paymentsTableData, setPaymentsTableData] = useState<any[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [validStatsSummary, setValidStatsSummary] = useState<TStyledStatSummaryData[]>([]);

  const columns: IStyledGenericTableProps['columns'] = [
    {
      header: t('contract_number'),
      accessor: 'contractNumber',
    },
    {
      header: t('transaction_date'),
      accessor: 'transactionDate',
    },
    {
      header: t('payment_currency'),
      accessor: 'paymentCurrency',
    },
    {
      header: t('amount'),
      accessor: 'amount',
      align: 'right',
    },
    {
      header: t('value_date'),
      accessor: 'valueDate',
    },
    {
      header: t('beneficiary_name'),
      accessor: 'beneficiaryName',
    },
    {
      header: t('payment_ref'),
      accessor: 'reference',
    },
  ];

  const createValidStatSummaryData = () => {
    const validSummaryStat: TStyledStatSummaryData[] = [{
      key: t('currency'),
      value:
        <div className={styles.statValue}>
          <CurrencyPair currencyCode={batchSummary.currencyCode} />
        </div>,
    },
    {
      key: t('total_payment_amount'),
      value: <div className={styles.statValue}>{`${formatCurrency(batchSummary.currencyCode, batchSummary.totalValidAmount) || '-'}`}</div>,
    }];
    setValidStatsSummary(validSummaryStat);
  };

  const updateValidPaymentsTableData = (validPayments: PaymentDto[]) => {
    const resultTableData = validPayments.map((payment) => ({
      ...payment,
      contractNumber: <span className={styles.greenValue}>{payment.contractNumber}</span> || '-',
      transactionDate: formatIsoDate(payment.uploadedDate) || '-',
      paymentCurrency: <CurrencyPair currencyCode={payment.currency} /> || '-',
      amount: formatCurrency(payment.currency, payment.amount) || '-',
      valueDate: formatIsoDate(payment.valueDate) || '-',
      beneficiaryName: <span className={styles.greenValue}>{payment.beneficiaryName}</span> || '-',
      reference: payment.reference || '-',
    }));

    setPaymentsTableData(resultTableData);
  };

  useEffect(() => {
    if (payments) {
      updateValidPaymentsTableData(payments);
      createValidStatSummaryData();
    }
  }, [payments]);

  return (
    <Box className={styles.validBox}>
      {loading ? (
        <Loader testId="valid-payments-loader" size={24} className={styles.loader} />
      )
        : (
          <>
            <div className={styles.validTitleContainer}>
              <IconButton
                onClick={() => setShowTable(!showTable)}
                className={styles.chevron}
                size="small"
              >
                <FontAwesomeIcon
                  className={showTable ? styles.iconUp : styles.iconDown}
                  icon={faChevronDown as IconProp}
                  size="sm"
                />
              </IconButton>
              <FontAwesomeIcon icon={faCircleCheck as IconProp} className={styles.validIcon} />
              <Typography className={styles.validTitle}>{`${t('valid_payments')} (${payments.length})`}</Typography>
              <Status variant="success" className={styles.status}>{t('ready_to_submit')}</Status>
            </div>
            <Typography className={styles.subtitle}>
              {t('these_payments_passed_validation_and_can_be_submitted')}
            </Typography>
            <Collapse in={showTable}>
              <div className={styles.statContainer}>
                <StyledStatSummary data={validStatsSummary} />
              </div>
              <div className={styles.tableContainer}>
                <StyledGenericTable
                  testId="trades-breakdown-table"
                  columns={columns}
                  data={paymentsTableData}
                  className={styles.table}
                />
              </div>
            </Collapse>

          </>
        )}
    </Box>
  );
};

export default ValidPayments;
