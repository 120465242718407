import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import LangSelectorGroup from 'domain/Auth/LangSelectorGroup';
import t from 'utils/translationHelper';
import { NavLink } from 'react-router-dom';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/external';
import { APInput } from '@alpha/ui-lib/ui/Input';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormTitle from '../../../../components/Molecules/Auth/FormTitle';
import usePasswordReset from '../../../../hooks/usePasswordReset';
import routes from '../../../../routes.path';
import browserHistory from '../../../../services/history/browserHistory';
import hasCorrectChars from '../../../../utils/hasCorrectChars';
import hasLowerCase from '../../../../utils/hasLowerCase';
import hasNumber from '../../../../utils/hasNumber';
import hasSpecialChars from '../../../../utils/hasSpecialChars';
import hasUpperCase from '../../../../utils/hasUpperCase';
import useFormStyles from '../../form.styles';
import useStyles from '../../NewPassword/index.styles';

interface IProps {
  username?: string,
  email?: string,
}

const Verify: React.FC<IProps> = ({ username, email }: IProps) => {
  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');

  const { submitNewPassword, loading } = usePasswordReset(username);
  const classes = useStyles();

  const formClasses = useFormStyles();

  useEffect(() => {
    if (!username || !email) browserHistory.push(routes.auth.passwordReset.base);
  }, []);

  const handleButtonDisabled = () => {
    if (!(
      hasLowerCase(submitNewPassword.values.password)
      && hasUpperCase(submitNewPassword.values.password)
      && hasNumber(submitNewPassword.values.password)
      && hasCorrectChars(submitNewPassword.values.password, 8)
      && hasSpecialChars(submitNewPassword.values.password)
      && submitNewPassword.values.verificationCode)
    ) return true;

    return false;
  };

  return (
    <div>
      <FormTitle
        title={t('please_check_your_inbox_for_your_verification_code')}
        description={(
          <>
            {t('a_verification_code_has_been_emailed_to')}
            {' '}
            <b>{email}</b>
            .
            <br />
            {t('please_input_the_code_along_with_your_new_password_to_continue')}
          </>
        )}
      />
      <form onSubmit={submitNewPassword.handleSubmit}>
        <Typography className={formClasses.label}>{t('verification_code')}</Typography>
        <APInput
          autoComplete="off"
          testid="enter-code"
          id="verification-code"
          name="verificationCode"
          placeholder={t('enter_your_code')}
          fullWidth
          variant="filled"
          type="text"
          onChange={submitNewPassword.handleChange}
          value={submitNewPassword.values.verificationCode}
          error={submitNewPassword.touched.verificationCode
            && Boolean(submitNewPassword.errors.verificationCode)}
          helperText={submitNewPassword.touched.verificationCode
            && submitNewPassword.errors.verificationCode}
        />
        <Typography className={formClasses.label} style={{ marginTop: '16px' }}>{t('new_password^')}</Typography>
        <APInput
          autoComplete="off"
          testid="input-password"
          id="password"
          name="password"
          placeholder={t('enter_your_new_password')}
          fullWidth
          variant="filled"
          type={passwordType}
          onChange={submitNewPassword.handleChange}
          value={submitNewPassword.values.password}
          error={submitNewPassword.touched.password && Boolean(submitNewPassword.errors.password)}
          helperText={submitNewPassword.touched.password && submitNewPassword.errors.password}
        />
        <button
          type="button"
          className={formClasses.eyeIconStyles}
          onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
        >
          <FontAwesomeIcon
            icon={(passwordType === 'password' ? faEye : faEyeSlash) as IconProp}
          />
        </button>
        <ul className={classes.list}>
          <li className={clsx(hasLowerCase(submitNewPassword.values.password)
            && classes.contains)}
          >
            {t('one_lowercase_character')}
          </li>
          <li className={clsx(hasUpperCase(submitNewPassword.values.password)
            && classes.contains)}
          >
            {t('one_uppercase_character')}
          </li>
          <li className={clsx(hasNumber(submitNewPassword.values.password) && classes.contains)}>
            {t('one_number')}
          </li>
          <li className={clsx(
            hasCorrectChars(submitNewPassword.values.password, 8) && classes.contains,
          )}
          >
            {t('8_characters_minimum')}
          </li>
          <li className={clsx(
            hasSpecialChars(submitNewPassword.values.password) && classes.contains,
          )}
          >
            {t('one_special_character')}
          </li>
        </ul>

        <ActionButton
          size="large"
          type="submit"
          testId="submit-password-reset"
          fullWidth
          loading={loading}
          disabled={loading || handleButtonDisabled()}
        >
          {t('log_in^^')}
        </ActionButton>
        <NavLink
          to={routes.auth.login}
          className={formClasses.formBacklink}
        >
          {t('back_to_sign_in~')}
        </NavLink>
      </form>
      <LangSelectorGroup />
    </div>
  );
};

export default Verify;
