import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    icon: {
      color: '#197FAB',
      marginLeft: 5,
    },
    key: {
      display: 'flex',
      alignItems: 'center',
    },
    tooltipText: {
      width: '790px',
    },
  }),
  { name: 'InverseFxRate' },
);

export default useStyles;
