import clipboardCross from 'assets/clipboardCross.svg';
import BackdropLoader from 'components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable from 'components/Table/EmptyTable';
import React from 'react';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import t from 'utils/translationHelper';

import { TradeJournal } from '@alpha/fx-dtos';
import { Box, Divider } from '@alpha/ui-lib/ui/external';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table/StyledGenericTable';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import useStyles from './CloseOuts.styles';

interface IProps {
  closeouts: TradeJournal[];
  contractNumber: string;
  loading?: boolean;
}

const CloseOuts: React.FC<IProps> = ({
  closeouts,
  contractNumber,
  loading,
}: IProps) => {
  const classes = useStyles();
  const isEmpty = closeouts.length === 0;

  const createTableData = () => closeouts.map((column: TradeJournal) => ({
    ...column,
    tradeNumber: contractNumber,
    closeOutDate: formatIsoDate(column.inputDate) || '-',
    closeOutRate: <span className={classes.rate}>{formatNumber(column.newClientPrice, 4)}</span>,
    closeOutAmount: <span className={classes.amount}>
      {column.buyAmount === column.sellAmountLink ? (
        <>
          {formatNumber(Number(column.buyAmount), 2, false) || formatNumber(Number(0), 2, false)}
          {' '}
          {column.buyCurrency}
        </>
      )
        : (
          <>
            {formatNumber(Number(column.sellAmount), 2, false) || formatNumber(Number(0), 2, false)}
            {' '}
            {column.sellCurrency}
          </>
        )}
    </span>,
    mtmAmount: <span className={classes.amount}>
      {formatNumber(Number(column.mtmClientCloseOut), 2, false)}
      {' '}
      {column.mtmClientCloseOutCurrency}
    </span>,
  }));

  const columns: ITableColumn[] = [
    {
      header: t('trade_number'),
      accessor: 'tradeNumber',
    },
    {
      header: t('close_out_date'),
      accessor: 'closeOutDate',
    },
    {
      header: t('close_out_rate'),
      accessor: 'closeOutRate',
    },
    {
      header: t('close_out_amount'),
      accessor: 'closeOutAmount',
    },
    {
      header: t('mtm_amount'),
      accessor: 'mtmAmount',
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.closeOutsTable}>
        <Typography variant="subtitle1" className={classes.header}>
          {t('close_outs')}
          {' '}
          {loading ? '' : `(${closeouts.length})`}
        </Typography>
      </div>
      <Divider />

      {loading && (
        <Box className={classes.emptyTable}>
          <BackdropLoader testId="closeouts-loader" />
        </Box>
      )}

      {!loading && (isEmpty ? (
        <Box className={classes.emptyTable}>
          <EmptyTable
            title={t('no_closeouts')}
            subtitle={t('you_do_not_have_any_closeouts_currently')}
            icon={clipboardCross}
          />
        </Box>
      ) : (
        <StyledGenericTable
          testId="trade-closeouts-table"
          columns={columns}
          data={createTableData()}
        />
      ))}
    </div>
  );
};

export default CloseOuts;
