import Alert from 'components/Alert';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import i18n from 'i18next';
import React, { useState } from 'react';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { RadioButtonGroup } from '@alpha/ui-lib/ui/RadioButton';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import APModal from '../../../../components/Modals/APModal/APModal';
import AuthService from '../../../../services/Auth/auth.service';

import useStyles from './index.styles';

type Props = {
  open: boolean;
  handleClose: () => void;
  loadProfile?: () => void;
}

const LanguagePreferenceModal: React.FC<Props> = (
  {
    open, handleClose, loadProfile,
  }: Props,
) => {
  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const { logError } = useLog();
  const [langPreference, setLangPreference] = useState(i18n.language);

  function updateLangLocal() {
    if (langPreference) {
      i18n.changeLanguage(langPreference);
      localStorage.setItem('authLanguagePreference', langPreference);
      window.location.reload(false);
    }
  }

  const updateLangProfile = async () => {
    try {
      if (langPreference) {
        await AuthService.updateLangPreference(langPreference);
      }
      updateLangLocal();
      handleClose();
    } catch (e) {
      sb.trigger(t('could_not_update_language_preference'));
      logError({ action: 'Error updating language preference', error: e });
    }
  };

  const availableLanguageButtons = [
    {
      label: (
        <Box display="flex" className={styles.flagCombine}>
          <div className={styles.flag}><Flag code="GBP" size="md" /></div>
          <span className={styles.flagText}>English (EN)</span>
        </Box>),
      value: 'en',
      testId: 'radio-button',
    },
    {
      label: (
        <Box display="flex" className={styles.flagCombine}>
          <div className={styles.flag}><Flag code="FR" size="md" /></div>
          <span className={styles.flagText}>Français (FR)</span>
        </Box>),
      value: 'fr',
      testId: 'radio-button',
    },
    {
      label: (
        <Box display="flex" className={styles.flagCombine}>
          <div className={styles.flag}><Flag code="ES" size="md" /></div>
          <span className={styles.flagText}>Español (ES)</span>
        </Box>),
      value: 'es',
      testId: 'radio-button',
    },
    {
      label: (
        <Box display="flex" className={styles.flagCombine}>
          <div className={styles.flag}><Flag code="IT" size="md" /></div>
          <span className={styles.flagText}>Italiano (IT)</span>
        </Box>),
      value: 'it',
      testId: 'radio-button',
    },
    {
      label: (
        <Box display="flex" className={styles.flagCombine}>
          <div className={styles.flag}><Flag code="DE" size="md" /></div>
          <span className={styles.flagText}>Deutsch (DE)</span>
        </Box>),
      value: 'de',
      testId: 'radio-button',
    },
  ];

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <>
        <APModal.Header
          onClose={() => {
            handleClose();
          }}
          showCloseButton
        >
          <Typography className={styles.modalHeader}>
            {t('preferred_language~')}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={styles.container}>
          <div className={styles.inputContainer}>
            <Alert
              variant="warning"
              text={<div className={styles.alert}>{`${t('please_note^')}: ${t('the_market_information_page_no_translation')}`}</div>}
            />
            <RadioButtonGroup
              id="test"
              className={styles.radioButtons}
              testId="lang-preference"
              orientation="vertical"
              onChange={(event) => setLangPreference(event.target.value as any)}
              value={langPreference}
              items={availableLanguageButtons}
            />

          </div>

          <Box className={styles.footer}>
            <ActionButton style={{ borderRadius: '10px' }} type="submit" onClick={updateLangProfile}>{t('save')}</ActionButton>
          </Box>
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default LanguagePreferenceModal;
