import t from 'utils/translationHelper';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { MfaMethod } from '@alpha/profile-dtos';
import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';

import { TSignIn } from '../models/auth';
import AuthService from '../services/Auth/auth.service';

import useHandleLogin from './useHandleLogin';

type TUpdatePassword = {
  password: string;
};

const initialValue: TUpdatePassword = {
  password: '',
};

const validation = yup.object({
  password: yup.string().required(t('password_is_required')),
});

const useUpdatePassword = (newUserObject: TSignIn) => {
  const snackbar = originalUseSnackbar();
  const {
    auth, loading, setLoading, handleLogin,
  } = useHandleLogin();

  const handleNewPassword = async (userObject: TSignIn, password: string, callback: Function) => {
    try {
      setLoading(true);
      await AuthService.newPassword(userObject.username, password, (userObject as any).Session);
      const response = await AuthService.signIn(userObject.username, password);
      await auth.challengeRedirect(response, callback, MfaMethod.METHOD_AUTHY);
    } catch (e: any) {
      const errorMessage = e?.response?.data?.error;
      if (e.message?.includes('CUSTOM_AUTH_REQUIRED')) {
        await handleLogin(userObject.username, password);
      } else {
        snackbar.enqueueSnackbar(errorMessage ?? e.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values: TUpdatePassword) => {
      try {
        await handleNewPassword(newUserObject, values.password, snackbar.enqueueSnackbar);
      } catch (e) {
        snackbar.enqueueSnackbar(e.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
        });
      }
    },
    validationSchema: validation,
  });

  return {
    formik,
    loading,
  };
};

export default useUpdatePassword;
