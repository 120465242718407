import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    main: {
      padding: '58px 0 0px',
      flex: '1 1 auto',
      maxWidth: '1920px',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#ffffff',
    },
    newMain: {
      flex: '1 1 auto',
      maxWidth: '100%',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#f7f7f7',
    },
    newMainDashboard: {
      padding: '20px 0 0px',
      flex: '1 1 auto',
      maxWidth: '100%',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#f7f7f7',
    },
    expired: {
      filter: 'blur(4px)',
    },
    userGuideTheme: {
      maxWidth: 'none !important',
      backgroundColor: 'rgb(22, 22, 22)',
      minHeight: '100vh',
    },
    newSideBar: {
      width: 'calc(100% - 60px)',
      marginLeft: '60px',
    },
  }),
  { name: 'Page' },
);

export default useStyles;
