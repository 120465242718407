import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import t from 'utils/translationHelper';

import Warning from 'assets/warning.svg';
import APModal from 'components/Modals/APModal/APModal';
import ApprovalSummary from '../ApprovalDrawer/ApprovalSummary';
import useStyles from '../RejectTransferModal/index.styles';
import { CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleAcknowledge: () => void;
  transfer: CurrencyAccountTransferDto | undefined;
}

const InvalidApprovalModal: React.FC<Props> = ({
  open, transfer, handleClose, handleAcknowledge,
}: Props) => {

  const styles = useStyles();

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div className={styles.modalWrapper}>
          <img src={Warning} className={styles.warningImg} alt="Warning" />
          <div className={styles.contentMarginBottom}>
            <Typography className={styles.heading}>
              {t('the_following_transfer_was_not_approved')}
            </Typography>
            <Typography>
              {t('please_check_and_try_again')}
            </Typography>
          </div>
          <div style={styles.textLeft}>
            {transfer ?
              <ApprovalSummary values={transfer} /> :
              ''
            }
          </div>
          <div className={styles.maxWidth}>
            <ActionButton size="large" className={styles.action} onClick={handleAcknowledge}>{t('acknowledge')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default InvalidApprovalModal;
