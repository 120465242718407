import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      '& > .MuiPaper-root': {
        width: '65%',
        maxWidth: '800px',
      },
      '& > div > div:first-of-type': {
        paddingTop: '70px',
      },
      '& .stepper': {
        '& h6': {
          color: '#a0a0a0',
          fontSize: '16px',
          marginTop: '16px',
        },
        '& .active': {
          color: 'black',
        },
      },
    },
    drawerBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: 'calc(100vh - 239px)',
    },
    lineBreak: {
      margin: '30px 0',
    },
    mb16: {
      marginBottom: '16px',
    },
    mt24: {
      marginTop: '24px',
    },
    note: {
      fontWeight: '800',
    },
    approvalRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: '92px 0 42px 0',
      justifyContent: 'flex-end',
    },
    rowAlignment: {
      position: 'absolute',
      transform: 'translateY(-42px)',
      width: '100%',
      left: 0,
    },
  }),
  { name: 'ApprovalDrawer' },
);

export default useStyles;
