import {
  Dispatch, SetStateAction, useEffect, useRef,
  useState,
} from 'react';

import { AccountDto } from '@alpha/auth-dtos';

import { TBeneficiaryApproved, TBeneficiaryPending } from '../models/beneficiaries';
import { UserRole } from '../models/user';
import BeneficiariesService from '../services/Beneficiaries/beneficiaries.service';
import t from 'utils/translationHelper';

import useAlphaSnackbar from './useAlphaSnackbar';
import useAuthorization from './useAuthorization';

const useShareBeneficiaryDrawer = (setSelectedBeneficiary: Dispatch<SetStateAction<{
  beneficiary: TBeneficiaryApproved | TBeneficiaryPending;
  approved: boolean;
} | undefined>>) => {
  const [accountBeneId, setAccountBeneId] = useState<string>('');
  const availableAccounts = useRef<AccountDto[]>([]);


  const sb = useAlphaSnackbar();
  const canShareBeneficiary = useAuthorization([[UserRole.BENEFICIARY_INPUTTER]]).authorized;

  useEffect(() => {
    if (canShareBeneficiary) {
      getShareAccounts();
    }
  }, [canShareBeneficiary]);

  async function getShareAccounts() {
    try {
      const response = await BeneficiariesService.getAvailableAccountsToShareBeneficiary();
      if (response) availableAccounts.current = response;
    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || t('something_went_wrong_retrieving_your_accounts'));
    }
  }

  const handleBeneficiaryShareButtonClick = (_accountBeneId?: string) => {
    if (typeof _accountBeneId === 'string') {
      setAccountBeneId(_accountBeneId);
    } else {
      setAccountBeneId('');
    }
    setSelectedBeneficiary(undefined);
  };

  return {
    availableAccounts: availableAccounts.current,
    accountBeneId,
    handleBeneficiaryShareButtonClick,
  };
};

export default useShareBeneficiaryDrawer;
