import React, { useEffect, useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import useLog from 'hooks/useLog';
import browserHistory from 'services/history/browserHistory';
import { formatCurrency } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import { CreditFacilityDto } from '@alpha/credit-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APUtilisationStackedBarChart } from '@alpha/ui-lib/ui/Chart/APUtilisationStackedBarChart';
import { Box, Divider, Typography } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { StyledTabsWrapper, TabContent } from '@alpha/ui-lib/ui/Tabs';

import FacilityUtilisationTerms from './FacilityUtilisationTerms/FacilityUtilisationTerms';
import useWidgetStyles from './index.styles';

interface IProps {
  facility?: CreditFacilityDto;
  isDashboardWidget?: boolean;
}

const FacilityUtilisation: React.FC<IProps> = ({
  facility,
  isDashboardWidget = false,
}) => {
  const widgetClasses = useWidgetStyles();
  const [tab, setTab] = useState(0);
  const [chartData, setChartData] = useState([]);
  const handleTabChange = (tabIndex: number) => {
    setTab(tabIndex);
  };
  const { logEvent } = useLog();

  const calculateAmountPercentage = (amount = 0, limit = 0) => (limit > 0 ? Math.ceil(amount / limit * 10000) / 100 : 0);

  useEffect(() => {
    if (facility?.facilityTerms?.length) {
      const data = facility?.facilityTerms?.map((term, index) => ({
        value: Number(calculateAmountPercentage(term.utilisationAmount, facility.facilityLimit).toFixed()),
        label: `${t('term')} ${index + 1}`,
      }));
      setChartData(data);
    }
  }, [facility]);
  return (
    <div className={[widgetClasses.widget, widgetClasses.creditWidget].join(' ')}>
      {facility && facility?.facilityTerms?.length && chartData?.length
        ? (
          <>
            <Box className={widgetClasses.termsContainer}>
              <Box display="flex" justifyContent="unset">
                <Typography className={widgetClasses.title}>
                  {t('facility_utilisation')}
                  {' '}
                  |
                  {' '}
                </Typography>
                <Typography className={widgetClasses.titleLimit}>
                  {t('facility_limit')}
                  {' '}
                  {formatCurrency(facility?.currencyCode, facility?.facilityLimit)}
                </Typography>
              </Box>
              {isDashboardWidget
                && (
                  <ActionButton
                    style={{ background: '#F7F7F7', color: '#212529' }}
                    onClick={() => {
                      logEvent({ action: 'Clicked on View all on facility utilisation' });
                      browserHistory.push('/app/credit-facility');
                    }}
                  >
                    {t('view_all')}
                  </ActionButton>
                )}
            </Box>

            <StyledTabsWrapper
              tabTitles={[]}
              tabIndex={tab}
              testId="credit-utilisation-tabs"
              handleChange={handleTabChange}
            />
            <TabContent index={0} value={tab}>
              {/* Facility Graph */}
              {facility
                ? (
                  <div>
                    {chartData?.length && facility?.facilityTerms?.length
                      ? (
                        <APUtilisationStackedBarChart
                          chartData={chartData}
                          height={80}
                          width="100%"
                          limit={100}
                          striped={false}
                          remainingText={t('remaining')}
                          showTooltip
                          anchorPosition="center"
                          showLegend={false}
                          hideLabels={facility.facilityTerms?.length > 3}
                          groupSmallItems
                        />
                      ) : <Loader testId="loading-terms-utilisation" />}
                    <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
                    <FacilityUtilisationTerms
                      terms={facility?.facilityTerms}
                      currenyCode={facility?.currencyCode}
                    />
                  </div>
                )
                : null}
            </TabContent>
          </>
        )
        : (
          <EmptyTable
            title={t('no_utilisation_data_available')}
            subtitle={(
              <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: '300' }}>
                {t('there_are_currently_no_term_limits_or_open_positions')}
                <br />
                {t('you_will_be_able_to_see_your_utilisation_once_these_limits_are_set')}
              </Typography>
            )}
            icon={clipboardCross}
            className={widgetClasses.emptyTableUtilisation}
          />
        )}
    </div>
  );
};

export default FacilityUtilisation;
