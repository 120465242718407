import React from 'react';

import { Box } from '@alpha/ui-lib/ui/external';
import { TKeyValue } from '@alpha/ui-lib/ui/table';

import PaymentDetails from './PaymentDetails/PaymentDetails';
import useStyles from './BodyDetails.styles';

interface IProps {
  referenceDetails: TKeyValue[];
  valueDateDetails: TKeyValue[];
  beneficiaryDetails: TKeyValue[];
  amountDetails: TKeyValue[];
}

const BodyDetails = (props: IProps) => {
  const classes = useStyles();
  const {
    referenceDetails,
    valueDateDetails,
    beneficiaryDetails,
    amountDetails,
  } = props;

  return (
    <Box className={classes.body}>
      <PaymentDetails
        beneficiaryDetails={beneficiaryDetails}
        valueDateDetails={valueDateDetails}
        referenceDetails={referenceDetails}
        amountDetails={amountDetails}
      />
    </Box>
  );
};

export default BodyDetails;
