import { makeStyles } from '@alpha/ui-lib/theme';

import Background from '../../assets/login.jpg';

const useStyles = makeStyles(
  ({ typography, breakpoints }) => ({
    container: {
      position: 'relative',
      backgroundAttachment: 'fixed',
      background: `url(${Background}) bottom center`,
      backgroundColor: 'rgba(22, 22, 22)',
      backgroundSize: 'cover',
      height: '100%',
      width: '100%',
      paddingBottom: '40px',
      marginBottom: '-30px',
      '& > .wrapper': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      },
    },
    header: {
      display: 'block',
      fontFamily: typography.fontFamilySecondary,
      fontSize: '28px',
      fontWeight: typography.fontWeightLight,
      color: '#FFFFFF',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '132px 0px 0px 0px',
      width: '608px',
      maxWidth: '100%',
      alignItems: 'center',
      textAlign: 'center',
    },
    subHeader: {
      display: 'block',
      fontFamily: typography.fontFamilySecondary,
      fontSize: '16px',
      fontWeight: typography.fontWeightRegular,
      color: '#FFFFFF',
      marginBottom: '8px',
      opacity: '70%',
    },
    divider: {
      color: '#1E8777',
      backgroundColor: '#1E8777',
      height: '4px',
      width: '47px',
      borderColor: '#1E8777',
      margin: '25px 0px 25px 0px',
    },
    gridContainer: {
      margin: '60px 0px 0px 0px',
      display: 'grid',
      gridTemplateColumns: '33% 33% 33%',
      gridRowGap: '40px',
      width: '88%',
      columnGap: '50px',
      justifyContent: 'center',
      [breakpoints.down('xs')]: {
        gridTemplateColumns: '100%',
      },
      [breakpoints.only('sm')]: {
        gridTemplateColumns: '50% 50%',
      },
    },
    videoContainer: {
      background: 'rgba(59,59,59,0.51)',
    },
    videoTitle: {
      fontSize: '14px',
      fontFamily: typography.fontFamilySecondary,
      color: '#FFFFFF',
      fontWeight: typography.fontWeightRegular,
      padding: '20px 0px 20px 17px',
    },
  }),
  { name: 'UserGuides' },
);

export default useStyles;
