/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react';
import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { DrawdownFundingMethod, DrawdownRequest, TradeDto } from '@alpha/fx-dtos';
import { PaymentDto, PaymentFundingMethod, PaymentOrigin } from '@alpha/payments-dtos';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../../hooks/useAuthorization';
import useLog from '../../../../hooks/useLog';
import { UserRole } from '../../../../models/user';
import CurrencyAccountsService from '../../../../services/CurrencyAccounts/currencyAccounts.service';
import FXTradeService from '../../../../services/FXTrade/fxTrade.service';
import PaymentsService from '../../../../services/Payments/payments.service';
import { initiateDrawdown, initiatePayment } from '../../../../store/authy/actions';

import t from 'utils/translationHelper';

const useSubmitBatchPayments = ({
  currencyCode, totalValidAmount, validPayments, batchId,
}
  : {
    currencyCode: string | undefined,
    totalValidAmount: number | undefined,
    validPayments: PaymentDto[],
    batchId: string,
  }) => {
  const sb = useAlphaSnackbar();
  const dispatch = useDispatch();
  const { logError } = useLog();

  const canApproveOwn = useAuthorization([[UserRole.PAYMENTS_APPROVER_OWN]]).authorized;
  const [openSubmitPaymentsModal, setOpenSubmitPaymentsModal] = useState<boolean>(false);
  const [selectedFundingMethod, setSelectedFundingMethod] = useState<PaymentFundingMethod>(
    PaymentFundingMethod.WIRE_TRANSFER,
  );

  const [selectedDrawdownFundingMethod,
    setSelectedDrawdownFundingMethod] = useState(DrawdownFundingMethod.WIRE_TRANSFER);

  const [selectedForward, setSelectedForward] = useState<TradeDto>();
  const [selectedFundingCurrencyAccount,
    setSelectedFundingCurrencyAccount] = useState<CurrencyAccountDto>();

  const [fundingCurrencyCode, setFundingCurrencyCode] = useState<string>();
  const [isValidFxLimit, setIsValidFxLimit] = useState(true);
  const [paymentSubmitting, setPaymentSubmitting] = useState<boolean>(false);
  const validateDrawdown = async ({
    drawdown,
  }: {
    drawdown: DrawdownRequest,
  }) => {
    const validateResult = await FXTradeService.postValidateDrawdown(drawdown);

    return validateResult;
  };

  const handleDrawdownSubmission = async ({
    drawdown,
  }: {
    trade: TradeDto,
    drawdown: DrawdownRequest,
  }) => {
    const validatedDrawdown = await validateDrawdown({ drawdown });
    if (validatedDrawdown.errors && validatedDrawdown.errors.length > 0) {
      sb.trigger(validatedDrawdown.errors[0]);
      logError({
        action: 'Error validating drawdown',
        error: { errors: validatedDrawdown.errors },
      });
      return;
    }

    dispatch(initiateDrawdown({
      type: 'DRAWDOWN',
      drawdownId: validatedDrawdown?.id || '',
      paymentIds: validPayments.map((payment: PaymentDto) => (payment.id)),
      approverOwn: canApproveOwn,
      softToken: true,
    }));
  };

  const getDefaultCurrencyAccount = async (_currencyCode: string) => {
    const currencyAccounts = await CurrencyAccountsService.getCurrencyStats(
      _currencyCode,
    );
    const defaultCA = currencyAccounts.currencyAccounts?.find(
      (ca) => ca.default,
    );
    return defaultCA;
  };

  useEffect(() => {
    const updateDefaultPaymentCA = async () => {
      if (fundingCurrencyCode) {
        let defaultCA: CurrencyAccountDto | undefined;
        try {
          defaultCA = await getDefaultCurrencyAccount(fundingCurrencyCode);
          if (!defaultCA) {
            sb.trigger(`${t('you_do_not_have_a_default_currency_account_for')} ${fundingCurrencyCode}`);
            logError({
              action: 'User has no default currency account',
              detail: { currencyCode: fundingCurrencyCode },
            });
            setFundingCurrencyCode(undefined);
          }
          setSelectedFundingCurrencyAccount(defaultCA);
        } catch (error) {
          sb.trigger(t('there_was_an_error_retrieving_your_currency_accounts'));
          logError({
            action: 'Error getting currency accounts',
            error,
          });
        }
      }
    };

    updateDefaultPaymentCA();
  }, [fundingCurrencyCode]);

  const putFundingMethod = async (fundingMethod: PaymentFundingMethod) => {
    const mapPaymentOrigin = (inputFundingMethod: PaymentFundingMethod): PaymentOrigin => {
      switch (inputFundingMethod) {
        case PaymentFundingMethod.FX:
          return PaymentOrigin.DRAFT_SPOT_TRADE;
        case PaymentFundingMethod.DRAWDOWN:
          return PaymentOrigin.DRAWDOWN;
        case PaymentFundingMethod.CURRENCY_ACCOUNT_BALANCE:
        case PaymentFundingMethod.WIRE_TRANSFER:
        default:
          return PaymentOrigin.STANDALONE;
      }
    };

    const onlineOrigin = mapPaymentOrigin(fundingMethod);

    try {
      return await PaymentsService.putUpdateBatchPayment(batchId, {
        fundingCurrencyCode: selectedFundingCurrencyAccount?.currencyCode || currencyCode || '',
        fundingAccountId: selectedFundingCurrencyAccount?.id,
        onlineOrigin,
      });
    } catch (e) {
      sb.trigger(e?.message || t('failed_updating_funding_method'));
      logError({ action: 'Error updating funding method' });
    }
  };

  useEffect(() => {
    if (selectedFundingMethod === PaymentFundingMethod.CURRENCY_ACCOUNT_BALANCE
      || selectedFundingMethod === PaymentFundingMethod.WIRE_TRANSFER) {
      setFundingCurrencyCode(currencyCode);
    } else if (selectedFundingMethod === PaymentFundingMethod.FX
      || selectedFundingMethod === PaymentFundingMethod.DRAWDOWN) {
      setFundingCurrencyCode(undefined); // To be updated when selecting from ccy list
    }
  }, [selectedFundingMethod, currencyCode]);

  useEffect(() => {
    if (selectedFundingMethod === PaymentFundingMethod.DRAWDOWN && selectedForward) {
      setFundingCurrencyCode(selectedForward.soldCurrencyCode);
    }
  }, [selectedFundingMethod, selectedForward]);

  const handleSubmission = async () => {
    setPaymentSubmitting(true);
    const paymentIds: string[] = validPayments.map((payment: PaymentDto) => (payment.id));
    if (canApproveOwn || openSubmitPaymentsModal) {
      try {
        await putFundingMethod(selectedFundingMethod);
      } catch (e) {
        sb.trigger(t('failed_to_update_funding_method'), 'error');
        logError({ action: 'Error updating funding method' });
        setPaymentSubmitting(false);
        return;
      }

      if (selectedFundingMethod !== PaymentFundingMethod.DRAWDOWN) {
        dispatch(initiatePayment({
          type: 'PAYMENTS',
          approverOwn: canApproveOwn,
          paymentIds,
        }));
        setOpenSubmitPaymentsModal(false);
      } else if (selectedForward && currencyCode && totalValidAmount) {
        const earliestValueDate = (payments: PaymentDto[]): string => {
          const earliestDatePayment = payments.reduce(
            (a, b) => (moment(a.valueDate) < moment(b.valueDate) ? a : b),
          );
          return earliestDatePayment.valueDate;
        };

        handleDrawdownSubmission(
          {
            trade: selectedForward,
            drawdown: {
              tradeId: selectedForward.id,
              fixCurrencyCode: currencyCode,
              amount: totalValidAmount,
              valueDate: earliestValueDate(validPayments),
              fundingMethod: selectedDrawdownFundingMethod,
              sellCurrencyCode: selectedForward.soldCurrencyCode,
              buyCurrencyCode: selectedForward.buyCurrencyCode,
              rate: selectedForward.rate,
            },
          },
        );
      } else {
        sb.trigger(t('invalid_trade'));
        logError({ action: 'Invalid trade' });
        setPaymentSubmitting(false);
      }
    } else {
      setOpenSubmitPaymentsModal(true);
    }
  };

  return {
    selectedFundingMethod,
    setSelectedFundingMethod,
    handleSubmission,
    openSubmitPaymentsModal,
    setOpenSubmitPaymentsModal,
    selectedForward,
    setSelectedForward,
    selectedFundingCurrencyAccount,
    setSelectedFundingCurrencyAccount,
    fundingCurrencyCode,
    setFundingCurrencyCode,
    paymentSubmitting,
    setPaymentSubmitting,
    selectedDrawdownFundingMethod,
    setSelectedDrawdownFundingMethod,
    isValidFxLimit,
    setIsValidFxLimit,
  };
};

export default useSubmitBatchPayments;
