/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import t from 'utils/translationHelper';

import {
  ProgramDirection,
  ProgramDto,
  ProgramPeriodDto,
  TradeDto,
} from '@alpha/fx-dtos';
import { Typography } from '@alpha/ui-lib/ui/external';
import { StyledStatSummary } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary';
import {
  IStyledGenericTableProps,
  StyledGenericTable,
} from '@alpha/ui-lib/ui/table';

import clipboardCross from '../../../assets/clipboardCross.svg';
import { CurrencyPair } from '../../../components/International';
import APModal from '../../../components/Modals/APModal/APModal';
import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable from '../../../components/Table/EmptyTable';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useLog from '../../../hooks/useLog';
import HedgingProgramsService from '../../../services/HedgingPrograms/hedgingPrograms.service';
import { formatNumber } from '../../../utils/currency.helpers';

import useStyles from './TradeBreakdownModal.styles';

type TTradeBreakdownModal = {
  program: ProgramDto | undefined;
  period: ProgramPeriodDto | undefined;
  handleClose?: () => void;
  open: boolean;
};

const TradeBreakdownModal: React.FC<TTradeBreakdownModal> = ({
  program,
  period,
  open,
  handleClose,
}) => {
  const classes = useStyles();
  const { logEvent } = useLog();
  const sb = useAlphaSnackbar();

  const [loading, setLoading] = useState(false);
  const [trades, setTrades] = useState<TradeDto[]>([]);
  const [tradesTableData, setTradesTableData] = useState<any[]>([]);

  const loadPeriodTradesData = async (
    currentPeriod: ProgramPeriodDto,
    buyCurrency: string,
    sellCurrency: string
  ) => {
    try {
      setLoading(true);
      const tradeResults = await HedgingProgramsService.getPeriodTradesData({
        queryParams: {
          sortby: 'valueDate',
          sortorder: 'asc',
          take: 9999,
          skip: 0,
          valuedatefrom: currentPeriod?.startDate,
          valuedateto: currentPeriod?.endDate,
          buycurrency: buyCurrency,
          soldcurrency: sellCurrency,
          status: 'ACTIVE,COMPLETED',
          applyprogramexclusion: 'true',
        },
      });
      setTrades(tradeResults as TradeDto[]);
      setLoading(false);
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_trades'));
      logEvent({ action: 'Error loading trades', detail: { error } });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (period && program && open) {
      const currencies = program.currencyPairCode?.split('/', 2);

      let buyCurrency = '';
      let sellCurrency = '';

      if (program.direction === ProgramDirection.BUY) {
        buyCurrency = currencies.find((c) => c === program.currencyCode) || '';
        sellCurrency = currencies.find((c) => c !== program.currencyCode) || '';
      } else {
        buyCurrency = currencies.find((c) => c !== program.currencyCode) || '';
        sellCurrency = currencies.find((c) => c === program.currencyCode) || '';
      }
      loadPeriodTradesData(period, buyCurrency, sellCurrency);
    }
  }, [period, open]);

  const columns: IStyledGenericTableProps['columns'] = [
    {
      header: t('trade_date'),
      accessor: 'date',
    },
    {
      header: t('trade_number'),
      accessor: 'contractNumber',
    },
    {
      header: t('value_date'),
      accessor: 'valueDate',
    },
    {
      header: t('sold_ccy'),
      accessor: 'soldCurrencyCode',
    },
    {
      header: t('sell_ccy_balance'),
      accessor: 'sellBalance',
      align: 'right',
    },
    {
      header: t('rate'),
      accessor: 'rate',
    },
    {
      header: t('buy_ccy'),
      accessor: 'buyCurrencyCode',
    },
    {
      header: t('buy_ccy_balance'),
      accessor: 'buyBalance',
      align: 'right',
    },
  ];

  const updateTradesTableData = (periodTrades: TradeDto[]) => {
    const resultTableData = periodTrades.map((trade) => ({
      ...trade,
      date: trade.date ? moment(trade.date).format('DD/MM/YYYY') : '-',
      contractNumber:
        <span className={classes.greenText}>{trade.contractNumber}</span> ??
        '-',
      valueDate: trade.valueDate
        ? moment(trade.valueDate).format('DD/MM/YYYY')
        : '-',
      soldCurrencyCode: trade.soldCurrencyCode ? (
        <CurrencyPair currencyCode={trade.soldCurrencyCode} />
      ) : (
        '-'
      ),
      sellBalance: trade.sellBalance ? (
        <span className={classes.semiBold}>
          {formatNumber(trade.sellBalance, 2)} {trade.soldCurrencyCode}
        </span>
      ) : (
        '-'
      ),
      rate: trade.rate ? (
        <span className={classes.greenText}>{formatNumber(trade.rate, 6)}</span>
      ) : (
        '-'
      ),
      buyCurrencyCode: trade.buyCurrencyCode ? (
        <CurrencyPair currencyCode={trade.buyCurrencyCode ?? ''} />
      ) : (
        '-'
      ),
      buyBalance: trade.buyBalance ? (
        <span className={classes.semiBold}>
          {formatNumber(trade.buyBalance, 2)} {trade.buyCurrencyCode}
        </span>
      ) : (
        '-'
      ),
    }));

    setTradesTableData(resultTableData);
  };

  useEffect(() => {
    if (trades) {
      updateTradesTableData(trades);
    }
  }, [trades]);

  const statData = [
    {
      key: t('program_name'),
      value: program?.programName || '-',
    },
    {
      key: t('total_weighted_avg_rate'),
      value: period?.weightedAverageRate
        ? formatNumber(period.weightedAverageRate, 6)
        : '-',
    },
    {
      key: t('program'),
      value: period?.activeHedgeRatio ? `${period.activeHedgeRatio}%` : '-',
    },
    {
      key: t('forecast'),
      value: period?.forecast ? formatNumber(period.forecast, 2) : '-',
    },
    {
      key: t('target'),
      value: period?.programAmount
        ? formatNumber(period.programAmount, 2)
        : '-',
    },
    {
      key: t('total_hedged_%'),
      value: period?.percentHedged ? `${period.percentHedged}%` : '-',
    },
    {
      key: t('total_to_hedge_%'),
      value: period?.percentToHedge ? `${period.percentToHedge}%` : '-',
    },
  ];

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <>
        <APModal.Header onClose={handleClose}>
          <Typography className={classes.modalHeader}>
            <span className={classes.greenText}>{period?.period || ''}</span>{' '}
            {t('trade_breakdown')}{' '}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={classes.modalWrapper}>
          {loading ? (
            <BackdropLoader testId='hedging-program-summary-loader' />
          ) : (
            <div style={{ padding: '24px', position: 'relative' }}>
              <StyledStatSummary data={statData} />

              {trades.length > 0 && (
                <StyledGenericTable
                  testId='trades-breakdown-table'
                  columns={columns}
                  data={tradesTableData}
                  className={classes.stickyHeaderTable}
                />
              )}

              {trades.length === 0 && (
                <div style={{ height: '360px', marginTop: '80px' }}>
                  <EmptyTable
                    title={t('no_trades_in_this_period')}
                    subtitle={t(
                      'you_currently_do_not_have_any_trades_in_this_period'
                    )}
                    icon={clipboardCross}
                  />
                </div>
              )}
            </div>
          )}
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default TradeBreakdownModal;
