import { useEffect, useState } from 'react';

import { DashboardUserAccountPreferencesDto, DashboardWidget } from '@alpha/profile-dtos';
import { datadogLogs } from '@datadog/browser-logs';

import useAlphaSnackbar from '../../hooks/useAlphaSnackbar';
import useLog from '../../hooks/useLog';
import routes from '../../routes.path';
import DashboardService from '../../services/Dashboard/dashboard.service';
import history from '../../services/history/browserHistory';

export const useDashboard = () => {
  const [dashboardSettings, setDashboardSettings] = useState<DashboardUserAccountPreferencesDto>();
  const { logEvent, logError } = useLog();
  const sb = useAlphaSnackbar();

  const loadDashboardSettings = async () => {
    try {
      const settings = await DashboardService.getUserDashboardSettings();
      setDashboardSettings(settings);
    } catch (e) {
      datadogLogs.logger.error(e);
      history.push(routes.currencyAccounts);
    }
  };

  const saveDashboardSettings = async () => {
    try {
      if (dashboardSettings) {
        logEvent({ action: 'Dashboard - Save Settings', detail: { widgets: dashboardSettings.widgets } });
        await DashboardService.postUpdateUserDashboardSettings(dashboardSettings);
        logEvent({ action: 'Dashboard - Save Settings Success' });
        sb.trigger('Saved', 'success');
      }
    } catch (e) {
      sb.trigger('Error saving dashboard settings', 'error');
      logError({ action: 'Dashboard - Save Settings Error', error: e });
    }
  };

  const handleRemoveWidget = async (widget: DashboardWidget) => {
    logEvent({ action: 'Dashboard - Removing widget', detail: { widget } });

    if (dashboardSettings?.widgets.includes(widget)) {
      const newSettings = dashboardSettings;
      newSettings?.widgets.splice(newSettings?.widgets.indexOf(widget), 1);
      setDashboardSettings(newSettings);
      await saveDashboardSettings();
      loadDashboardSettings();
    }
  };

  useEffect(() => {
    loadDashboardSettings();
  }, []);

  return {
    dashboardSettings,
    setDashboardSettings,
    handleRemoveWidget,
  };
};

export default useDashboard;
