import React from 'react';
import clsx from 'clsx';
import t from 'utils/translationHelper';
import formatIsoDate from 'utils/formatIsoDate';

import { Approval } from '@alpha/bene-dtos';

import useStyles from './ApproverContent.styles';

interface IProps {
  uploadedBy: string | undefined;
  uploadedDate: string | undefined;
  approvedBy: Approval[] | undefined;
}

const ApproverContent: React.FC<IProps> = ({ uploadedBy, uploadedDate, approvedBy }) => {

  const classes = useStyles();
  return (
    <div className={classes.contentWrapper}>
      <div className={classes.spaceBetween}>
        <b>
          {t('uploaded_by~')}
          {' '}
        </b>
        <span>{uploadedBy}</span>
      </div>
      <div className={
        clsx(classes.topSection,
          classes.spaceBetween)
      }
      >
        <b>{t('uploaded_date~')}</b>
        <span>
          {' '}
          {uploadedDate && formatIsoDate(uploadedDate, 'HH:mm:ss dd/MM/yyyy')}
        </span>
      </div>

      <div className={classes.column}>
        {
          approvedBy && approvedBy.length > 0
            ? <b>{t('approved_by')}</b>
            : <span>{t('no_approvers_yet')}</span>
        }
        {
          approvedBy?.map((approver: Approval) => (
            <div className={classes.spaceBetween}>
              <span>
                {approver.fullName}
              </span>
              <div>
                <span>
                  {formatIsoDate(approver.date, 'HH:mm:ss dd/MM/yyyy')}
                </span>
              </div>
            </div>
          ))
        }
      </div>

    </div>
  );
};

export default ApproverContent;
