/** useLog - Sending event tracking logs to desinated data warehouse,
 * It defaults logs the current accountId from useSwitchAccount
 */

import AuthService from 'services/Auth/auth.service';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import useSwitchAccount from './useSwitchAccount';

type TBaseEventDetail = {
  name?: string;
  id?: string;
  remarks?: string;
  accountId?: string;
}

type TEventDetails = TBaseEventDetail & Record<string, any>;

interface ILogEvent {
  action: string,
  error?: unknown,
  detail?: TEventDetails;
}

const useLog = () => {
  const { currentAccount } = useSwitchAccount();
  function logEvent({ action, detail }: ILogEvent) {
    datadogRum.addAction(action, { accountId: currentAccount?.id, ...detail });
    datadogLogs.logger.log(action, { accountId: currentAccount?.id, ...detail });
  }

  function logError({ action, error, detail }: ILogEvent) {
    const sessionId = AuthService.getSessionIdFromIdToken();

    datadogRum.addError(error ?? action, {
      action,
      accountId: currentAccount?.id,
      sessionId,
      ...detail,
    });
  }
  return {
    logEvent,
    logError,
  };
};

export default useLog;
