import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { TradeCurrencyPairSummary } from '@alpha/fx-dtos';
import { Box } from '@alpha/ui-lib/ui/external';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';

import clipboardCross from '../../../../assets/clipboardCross.svg';
import EmptyTable from '../../../../components/Table/EmptyTable';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import FXTradeService from '../../../../services/FXTrade/fxTrade.service';
import { formatNumber } from '../../../../utils/currency.helpers';

import useStyles from './FxSummaryTable.styles';

interface IFxSummaryTable {
  setOpenSummary: React.Dispatch<React.SetStateAction<boolean>>;
  emptyTitle?: string;
  emptySubtitle?: string;
  testId?: string;
}

const FxSummaryTable: React.FC<IFxSummaryTable> = (props: IFxSummaryTable) => {
  const {
    setOpenSummary,
    emptyTitle,
    emptySubtitle,
    testId,
  } = props;

  const [data, setData] = useState<IStyledGenericTableProps['data']>([]);

  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const classes = useStyles();

  const updateTable = (currencyPairs: TradeCurrencyPairSummary[]) => {
    const summaryData: IStyledGenericTableProps['data'] = currencyPairs.map(
      (currencyPair: TradeCurrencyPairSummary) => ({
        ...currencyPair,
        currencyPair: <span className={classes.tableLink}>{currencyPair.currencyPair}</span> || '-',
        sellAmount: <span className={classes.amount}>{[formatNumber(currencyPair.totalSoldAmount || 0, 2), currencyPair.sellCurrencyCode].join(' ')}</span>,
        sellBalance: <span className={classes.amount}>{[formatNumber(currencyPair.totalSoldBalance || 0, 2), currencyPair.sellCurrencyCode].join(' ')}</span>,
        averageRate: <span className={classes.tableLink}>{[formatNumber(currencyPair.averageRate || 0, 4)].join(' ')}</span>,
        buyAmount: <span className={classes.amount}>{[formatNumber(currencyPair.totalBuyAmount || 0, 2), currencyPair.buyCurrencyCode].join(' ')}</span>,
        buyBalance: <span className={classes.amount}>{[formatNumber(currencyPair.totalBuyBalance || 0, 2), currencyPair.buyCurrencyCode].join(' ')}</span>,
      }),
    );
    setData(summaryData);
  };

  const columns: IStyledGenericTableProps['columns'] = [
    { header: t('currency_pair'), accessor: 'currencyPair' },
    { header: t('sell_amount'), accessor: 'sellAmount', align: 'right' },
    { header: t('sell_balance'), accessor: 'sellBalance', align: 'right' },
    { header: t('avg._rate'), accessor: 'averageRate', align: 'right' },
    { header: t('buy_amount'), accessor: 'buyAmount', align: 'right' },
    { header: t('buy_balance'), accessor: 'buyBalance', align: 'right' },
  ];

  const loadSummary = async (): Promise<void> => {
    try {
      const summaries = await FXTradeService.getTradesSummary();
      updateTable(summaries);
      if (summaries.length > 0) {
        setOpenSummary(true);
      }
    } catch (error) {
      sb.trigger(error?.message || t('unable_to_load_trades_summary'), 'error');
      setData([]);
      logError({ action: 'Error loading trade summary', error });
    }
  };

  useEffect(() => {
    loadSummary();
  }, []);

  return (
    <div data-testid="trades-loader-component" className={classes.tableWrapper}>
      {data.length > 0 ? (
        <StyledGenericTable
          testId={testId || 'fx-summary-table'}
          columns={columns}
          data={data}
        />
      ) : (
        <Box className={classes.emptyTable}>
          <EmptyTable
            title={emptyTitle || t('no_fx_trades')}
            subtitle={emptySubtitle || t('you_currently_do_not_have_any_fx_trades')}
            icon={clipboardCross}
          />
        </Box>
      )}
    </div>
  );
};

export default FxSummaryTable;
