import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  (theme) => ({
    greenText: {
      fontWeight: theme.typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    modalHeader: {
      paddingLeft: '56px',
      fontSize: 16,
      padding: '12px 24px 24px 24px',
    },
    modalWrapper: {
      maxWidth: '1000px',
      width: '90vw',
      minWidth: '600px',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 290px)',
      minHeight: '300px',
    },
    semiBold: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    authoriseBtn: {
      backgroundColor: '#1E8777',
      color: '#FFFFFF',
      width: '168px',
      fontWeight: 500,
      borderRadius: '5px',
      '&:hover': {
        background: '#1E8777',
        color: '#FFFFFF',
      },
    },
    cancelBtn: {
      backgroundColor: '#f4f4f4',
      color: '#000000',
      width: '88px',
      fontWeight: 500,
      borderRadius: '5px',
      '&:hover': {
        background: '#e4e4e4',
        color: '#000000',
      },
    },
    buttonText: {
      marginLeft: '6px',
    },
    flag: {
      marginRight: '3px',
      marginTop: '-2px',
    },
  }),
  { name: 'TradePadAuthoriseModal' },
);

export default useStyles;
