import instance from '../Axios/instance';

export type latestMtmDateResponse = {
    latestMTMDate: string;
  }

export class HedgingSummaryService {
  public static async getMtmLatestAvailableDate(): Promise<latestMtmDateResponse> {
    const response = await instance.get('/credit/trades/mtm/latest-date');
    return response.data;
  }
}

export default HedgingSummaryService;
