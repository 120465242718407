import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography, palette }) => ({
  root: {
    padding: '20px 75px 40px 75px',
    backgroundColor: '#fafafa',
    minHeight: 'calc(100vh - 310px)',
  },
  backArrowButton: {
    minWidth: '33px',
    minHeight: '33px',
    height: '33px',
    marginTop: '-5px',
  },
  paymentUploadTitle: {
    fontSize: '18px',
    marginLeft: '35px',
    color: '#333333',
    letterSpacing: '0px',
  },
  fileUploadContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    borderRadius: '5px',
    padding: '20px',
    marginTop: '20px',
  },
  uploadProgressContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    borderRadius: '5px',
    padding: '20px',
    marginTop: '20px',
    height: '414px',
    textAlign: 'center',
  },
  tableHeading: {
    paddingLeft: '30px',
    fontSize: '16px',
    alignSelf: 'left',
    fontWeight: typography.fontWeightBold,
  },
  tableSubHeading: {
    paddingLeft: '30px',
    marginBottom: '20px',
    fontSize: '12px',
    alignSelf: 'left',
    color: palette.grey[500],
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '30px',
  },
  underlinedGreenText: {
    textDecoration: 'underline',
    fontSize: '15px',
    fontWeight: typography.fontWeightBold,
    color: palette.primary.main,
    marginBottom: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  closeGuideBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}), { name: 'PaymentFileUpload' });

export default useStyles;
