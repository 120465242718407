import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';

import { FeatureFlag } from '@alpha/feature-flags';

const useGetFeatureFlags = () => {
  const dispatch = useDispatch();
  const flags = useSelector<TStore, FeatureFlag[] | undefined>(
    (state: TStore) => state.featureFlags.flags,
  );
  const requestGetFeatureFlags = () => dispatch({ type: 'GET_FEATURE_FLAGS_REQUEST' });

  const isFlagActive = (flag: string):boolean => {
    if (!flags) { return false; }
    const flagsArray = Object.entries(flags);
    return flagsArray.filter((f) => (f[1].enabled)).map((f) => (f[0])).includes(flag);
  };

  return {
    requestGetFeatureFlags,
    flags,
    isFlagActive,
  };
};

export default useGetFeatureFlags;
