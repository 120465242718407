import { CreditFacilityTermDto, CreditFacilityTermStatus } from '@alpha/credit-dtos';

export const sortFacilityTerms = (
  facilityTerms: CreditFacilityTermDto[],
): CreditFacilityTermDto[] => {
  const activeTerms = facilityTerms ? facilityTerms.filter((term) => term?.status === CreditFacilityTermStatus.ACTIVE) : [];
  const otherTerms = facilityTerms ? facilityTerms.filter((term) => term?.status !== CreditFacilityTermStatus.ACTIVE) : [];

  return [activeTerms, otherTerms].flat();
};
