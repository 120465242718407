import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    drawerWrapper: {
      position: 'relative',
      '& > div > button': {
        zIndex: 101,
      },
    },
  }),
  { name: 'SwitchAccountDrawer' },
);

export default useStyles;
