import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useSearch, { TSearchParams } from 'hooks/useSearch';
import { ChangeEvent, useEffect } from 'react';

const useExternalTradesMtmTable = (
  mtmDate: string,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
  selectedCurrency?: string
) => {
  const table = useSearch();

  const sb = useAlphaSnackbar();

  const createSearchParams = (
    skip: number,
    sortByParam?: string,
    sortOrderParam: 'asc' | 'desc' = 'desc',
    searchText?: string,
    selectedCurrency?: string
  ): TSearchParams => {
    const searchParams: TSearchParams = {
      baseUrl: '/credit/facility/external-trades/mtm',
      queryParams: {
        skip,
        take: 10,
        sortby: sortByParam || '',
        sortorder: sortOrderParam,
        mtmdate: mtmDate,
      },
    };

    if (searchText) {
      searchParams.queryParams.searchtext = searchText;
    }
    if (selectedCurrency) {
      mtmcurrency: searchParams.queryParams.mtmcurrency = selectedCurrency;
    }

    return searchParams;
  };

  const defaultSortBy = sortBy ?? 'tradeDate';
  const defaultSortOrder = sortOrder ?? 'desc';
  const searchParams = createSearchParams(
    table.skip,
    defaultSortBy,
    defaultSortOrder,
    table.searchText,
    selectedCurrency
  );

  const handleTableSortClick = (column: string) => {
    table.handleTableSortClick(
      searchParams,
      column,
    );
  };

  useEffect(() => {
    if (mtmDate) {
      searchParams.queryParams.mtmdate = mtmDate;

      table.handleInitialSearch(searchParams);
    }
  }, [mtmDate]);

  useEffect(() => {
    if (selectedCurrency) {
      searchParams.queryParams.mtmcurrency = selectedCurrency;

      table.handleInitialSearch(searchParams);
    }
  }, [selectedCurrency]);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    table.setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.skip = 0;
      searchParams.queryParams.searchtext = e.target.value;
    } else {
      delete searchParams.queryParams.searchtext;
    }
    if (mtmDate) {
      table.handleNewSearch(searchParams);
    }
  };

  return {
    table,
    searchParams,
    handleInputChange,
    handleTableSortClick,
  };
};

export default useExternalTradesMtmTable;
