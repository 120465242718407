import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    tabContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      minHeight: 'calc(100vh - 310px)',
      '& > div': {
        width: '100%',
      },
    },
    searchBar: {
      display: 'flex',
      flexGrow: 1,
    },
    paymentTable: {
      paddingBottom: '30px',
      '&.show-action-bar': {
        marginBottom: '120px',
        '@media (max-width: 600px)': {
          marginBottom: '200px',
        },
      },
      '& .MuiTableHead-root': {
        borderTop: '#EAEAEA solid 1px',
      },
      '& .MuiTableCell-root': {
        'padding-right': '40px !important',
      },
      '@media (max-width: 1200px)': {
        '& .MuiTableCell-root': {
          'padding-right': '10px !important',
        },
        '& th.MuiTableHead-root, & td.MuiTableCell-root': {
          fontSize: '12px',
          '& span': {
            fontSize: '11px',
          },
        },
      },
    },
  }),
  { name: 'Body' },
);

export default useStyles;
