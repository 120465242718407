import React from 'react';

import { DrawdownFundingMethod, TradeDto } from '@alpha/fx-dtos';
import { PaymentFundingMethod } from '@alpha/payments-dtos';
import {
  Box, FormControlLabel, Radio, RadioGroup,
  Typography,
} from '@alpha/ui-lib/ui/external';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCoins } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FundingOptionAccountBalance from './FundingOptionAccountBalance';
import FundingOptionDrawdown from './FundingOptionDrawdown';
import FundingOptionFx from './FundingOptionFx';
import FundingOptionWireTransfer from './FundingOptionWireTransfer';
import useStyles from './PaymentBatchFunding.styles';
import usePaymentBatchFunding from './usePaymentBatchFunding';
import t from 'utils/translationHelper';

interface IPaymentBatchFundingProps {
  selectedFundingMethod: PaymentFundingMethod;
  setSelectedFundingMethod: (fundingMethod: PaymentFundingMethod) => void;
  currencyCode: string | undefined;
  totalValidAmount: number | undefined;
  selectedForward: TradeDto | undefined;
  setSelectedForward: (value?: TradeDto) => void;
  fundingCurrencyCode: string | undefined;
  setFundingCurrencyCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedDrawdownFundingMethod: DrawdownFundingMethod;
  setSelectedDrawdownFundingMethod: (fundingMethod: DrawdownFundingMethod) => void;
  isValidFxLimit: boolean;
  setIsValidFxLimit: (isValid: boolean) => void;
}

const PaymentBatchFunding: React.FC<IPaymentBatchFundingProps> = (
  {
    currencyCode,
    totalValidAmount,
    selectedFundingMethod,
    setSelectedFundingMethod,
    selectedForward,
    setSelectedForward,
    fundingCurrencyCode,
    setFundingCurrencyCode,
    selectedDrawdownFundingMethod,
    setSelectedDrawdownFundingMethod,
    isValidFxLimit,
    setIsValidFxLimit,
  }: IPaymentBatchFundingProps,
) => {

  const styles = useStyles();

  const {
    drawdownInputter,
    spotOrSpotInputter,
    defaultCurrencyAccount,
    insufficientFunds,
    trades,
    currencyAccountDetails,
    alphaBankDetails,
    fxEligible,
    allowedCurrencies,
  } = usePaymentBatchFunding(currencyCode,
    totalValidAmount);

  return (
    <Box className={styles.fundingOptionsContainer}>
      <Box className={styles.fundingSelectContainer}>
        <Box className={styles.radioButtonsContainer}>
          <div className={styles.boxHeading}>
            <FontAwesomeIcon size="sm" icon={faCoins as IconProp} className={styles.icon} />
            <Typography className={styles.boxHeaderText}>
              {t('funding')}
            </Typography>
          </div>
          <Typography className={styles.boxText}>
            {t('how_do_you_wish_to_fund_these_payments')}
          </Typography>
          <RadioGroup
            row
            value={selectedFundingMethod}
            onChange={(e) => {
              setSelectedFundingMethod(e.target.value as PaymentFundingMethod);
              setSelectedForward(undefined);
            }}
            className={styles.radioButtonText}
            id="batch-funding-method"
          >
            <FormControlLabel value={PaymentFundingMethod.WIRE_TRANSFER} control={<Radio color="primary" />} label={t('fund_via_wire_transfer')} data-testid="wire-transfer-payment-batch" name="batch-funding-method-wire" />
            <FormControlLabel value={PaymentFundingMethod.CURRENCY_ACCOUNT_BALANCE} control={<Radio color="primary" />} label={t('fund_via_currency_account')} data-testid="ca-balance-payment-batch" name="batch-funding-method-account" />
            <APTooltip
              arrow
              placement="bottom-start"
              title={!fxEligible || !spotOrSpotInputter
                ? (
                  <p className={styles.tooltipText}>
                    {!fxEligible && 'FX is not available for this currency. '}
                    {!spotOrSpotInputter && 'You do not have required permissions for FX. '}
                    {t('please_select_another_funding_option')}
                  </p>
                )
                : ''}
            >
              <FormControlLabel value={PaymentFundingMethod.FX} disabled={!fxEligible || !spotOrSpotInputter} control={<Radio color="primary" />} label={t('fund_via_fx')} data-testid="fx-payment-batch" name="batch-funding-method-fx" />
            </APTooltip>
            <APTooltip
              arrow
              placement="bottom-start"
              title={!drawdownInputter
                ? (
                  <p className={styles.tooltipText}>
                    {t('you_do_not_have_the_required_permissions_to_fund_these_payments_via_drawdown')}
                  </p>
                )
                : ''}
            >
              <FormControlLabel value={PaymentFundingMethod.DRAWDOWN} disabled={!drawdownInputter} control={<Radio color="primary" />} label={t('fund_via_drawdown')} data-testid="drawdown-payment-batch" name="batch-funding-method-drawdown" />
            </APTooltip>
          </RadioGroup>
        </Box>
        <StyledDivider vertical />
      </Box>
      <Box className={styles.fundingOptionContainer}>
        {selectedFundingMethod === PaymentFundingMethod.CURRENCY_ACCOUNT_BALANCE && (
          <FundingOptionAccountBalance
            currencyAccountDetails={currencyAccountDetails}
            insufficientFunds={insufficientFunds}
          />
        )}
        {selectedFundingMethod === PaymentFundingMethod.WIRE_TRANSFER && (
          <FundingOptionWireTransfer
            defaultCurrencyAccount={defaultCurrencyAccount}
            alphaBankDetails={alphaBankDetails}
          />
        )}

        {selectedFundingMethod === PaymentFundingMethod.FX && (
          <FundingOptionFx
            buyCurrencyCode={currencyCode}
            buyAmount={totalValidAmount || 0}
            allowedCurrencies={allowedCurrencies}
            fxFundingCurrencyCode={fundingCurrencyCode}
            setFxFundingCurrencyCode={setFundingCurrencyCode}
            isValidFxLimit={isValidFxLimit}
            setIsValidFxLimit={setIsValidFxLimit}
          />
        )}
        {selectedFundingMethod === PaymentFundingMethod.DRAWDOWN && (
          <FundingOptionDrawdown
            trades={trades}
            selectedForward={selectedForward}
            setSelectedForward={setSelectedForward}
            selectedDrawdownFundingMethod={selectedDrawdownFundingMethod}
            setSelectedDrawdownFundingMethod={setSelectedDrawdownFundingMethod}
            buyAmount={totalValidAmount || 0}
          />
        )}
      </Box>
    </Box>
  );
};

export default PaymentBatchFunding;
