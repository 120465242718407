/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import { TargetLogDto } from 'models/optionTrades';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import t from 'utils/translationHelper';

import { Box } from '@alpha/ui-lib/ui/external';
import APPagination from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';

import useStyles from '../../TransferTable/TransferTable.styles';

interface IProps {
  logs: TargetLogDto[];
  targetType?: string;
}

const TargetLogTable: React.FC<IProps> = ({ logs, targetType }) => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);
  const [filteredLogs, setFilteredLogs] = useState<TargetLogDto[]>();

  const totalPages = Math.ceil(logs.length / 10);
  useEffect(() => {
    const res = logs.filter((l) => l.originalTargetLog !== true);
    setFilteredLogs(res);
  }, [logs]);

  const sortedLogsData = filteredLogs
    ? filteredLogs.slice()?.sort((a, b) => {
      const individualNumberA = a?.stripId ? parseInt(a.stripId, 10) : 1;
      const individualNumberB = b?.stripId ? parseInt(b.stripId, 10) : 0;

      return individualNumberA - individualNumberB;
    })
    : [];

  const visibleLogsData = sortedLogsData.slice(pageNumber * 10, (pageNumber + 1) * 10);

  const hasPrev = () => pageNumber > 0;
  const hasNext = () => (pageNumber + 1) < totalPages;
  const handleNext = () => {
    setPageNumber(pageNumber + 1);
  };
  const handlePrev = () => {
    setPageNumber(pageNumber - 1);
  };

  const currentItems = hasNext() ? (pageNumber + 1) * 10 : sortedLogsData.length;

  const columns: ITableColumn[] = [
    {
      header: t('strip_id'), accessor: 'stripId', width: 200,
    },
    {
      header: t('fixing_date'), accessor: 'fixingDate', width: 200,
    },
    {
      header: t('strike'), accessor: 'strike', width: 200,
    },
    {
      header: t('fixing_reference'), accessor: 'fixingReference', width: 250,
    },
    {
      header: t('prev_target_value'), accessor: 'previousTargetValue', width: 160,
    },
    {
      header: t('deduction'), accessor: 'deduction', width: 260, align: 'left',
    },
    {
      header: t('new_target_value'), accessor: 'newTargetValue', width: 130, align: 'right',
    },
  ];

  const generateTableData = () => (visibleLogsData)?.map((log) => (
    {
      ...log,
      stripId: (log.stripId ? <div style={{ fontWeight: 600 }}>{log.stripId}</div> : '-'),
      fixingDate: formatIsoDate(log.fixingDate),
      strike: (log.strike ? formatNumber(log.strike, 4) : '-'),
      fixingReference: (log.fixingReference ? formatNumber(log.fixingReference, 5) : '-'),
      previousTargetValue: (log.prevTargetValue && targetType !== 'Accrual'
        ? <div style={{ fontWeight: 600, textAlign: 'right' }}>{log.prevTargetValue}</div>
        : <div style={{ fontWeight: 600, textAlign: 'right' }}>{formatNumber(log.prevTargetValue, 5) || '-'}</div>),
      deduction: (log.deduction && targetType !== 'Accrual' && targetType !== 'Discrete' ? log.deduction : formatNumber(log.deduction, 5) || '-'),
      newTargetValue: ((log.newTargetValue || log.newTargetValue === 0) && targetType !== 'Accrual'
        ? <div style={{ fontWeight: 600 }}>{log.newTargetValue}</div>
        : <div style={{ fontWeight: 600 }}>{formatNumber(log.newTargetValue, 5)}</div> || '-'),
    }));

  return (
    <div style={{
      padding: '20px',
      gridColumn: 'span 3 / auto',
      backgroundColor: '#ffffff',
      marginTop: '20px',
      borderRadius: '10px',
      boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.03)',
    }}
    >
      <div style={{ fontWeight: 600, fontSize: '16px', padding: '8px' }}>
        {t('target_log')}
        <span style={{ fontWeight: 300, fontSize: '12px', display: 'block' }}>{t('target_log_info')}</span>
      </div>
      <div data-testid="trades-loader-component" className={classes.tableWrapper}>
        {visibleLogsData?.length ? (
          <StyledGenericTable
            testId="preference-table"
            columns={columns}
            data={generateTableData()}
          />
        ) : (
          <div style={{ padding: '20px', backgroundColor: 'white', marginTop: '20px' }}>
            <EmptyTable
              title={t('no_target_logs')}
              subtitle={t('no_target_logs_reason')}
              className={classes.emptyTable}
              icon={clipboardCross}
              style={{ transform: 'none', position: 'unset' }}
            />
          </div>
        )}
      </div>
      {visibleLogsData?.length ? (
        <Box
          display="flex"
          flexDirection="rows"
          gridGap={16}
          justifyContent="space-between"
          marginTop="10px"
        >
          <span style={
            {
              fontWeight: 300,
              lineHeight: '26px',
            }
          }
          >
            {t('showing')}
            {' '}
            {currentItems}
            {' '}
            {t('of')}
            {' '}
            {sortedLogsData?.length}
          </span>
          <APPagination
            hasPrevious={hasPrev()}
            hasNext={hasNext()}
            handleNext={handleNext}
            handlePrevious={handlePrev}
            nextString={t('next^')}
            prevString={t('prev^')}
          />
        </Box>
      ) : null}
    </div>
  );
};

export default TargetLogTable;
