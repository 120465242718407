/* eslint-disable max-lines-per-function */
import BeneficiaryDropdownContainer from 'domain/Transactions/ManualPayments/Body/PaymentType/ManualPayment/BeneficiaryDropdown/BeneficiaryDropdownContainer';
import useForm from 'hooks/useForm';
import useLog from 'hooks/useLog';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import {
  AccountConfigurationFXDto,
  TradeDto, TradeStatus,
} from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Collapse } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBolt, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAuthorization from '../../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../../models/user';

import validation, {
  QuickDrawdownInitialValues, QuickDrawdownRequest,
} from './formData';
import useStyles from './QuickDrawdown.styles';

interface IProps {
  tradeItem: TradeDto;
  accountConfig: AccountConfigurationFXDto;
  drawerStyle: boolean;
  setBeneficiary: Function;
  selectedBeneficiary: BeneficiaryDto;
  setTrade: Function;
  quickDrawdownLoading: boolean;
  setQuickDrawdownLoading: Function;
  isTrade: boolean;
}

const QuickDrawdown: React.FC<IProps> = ({
  tradeItem,
  accountConfig,
  drawerStyle,
  setBeneficiary,
  selectedBeneficiary,
  setTrade,
  quickDrawdownLoading,
  setQuickDrawdownLoading,
  isTrade,
}: IProps) => {
  const classes = useStyles();
  const { authorized: drawdownInputter } = useAuthorization([[UserRole.DRAWDOWN_INPUTTER]]);
  const { authorized: firstPartyDrawdownInputter } = useAuthorization([[UserRole.FIRST_PARTY_DRAWDOWN_INPUTTER]]);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { logError } = useLog();

  const submitBeneficiary = async () => {
    setQuickDrawdownLoading(true);
    await FXTradeService.updateTrade(tradeItem.id, { defaultSSI: requestForm.values.beneficiary?.id });
    setTrade({ ...tradeItem, defaultSSI: requestForm.values.beneficiary?.id });
    setQuickDrawdownLoading(false);
  };

  const removeBeneficiary = async () => {
    setQuickDrawdownLoading(true);
    await FXTradeService.updateTrade(tradeItem?.id, { defaultSSI: '' });
    setTrade({ ...tradeItem, defaultSSI: '' });
    requestForm.values.beneficiary = undefined;
    setCollapsed(false);
    setQuickDrawdownLoading(false);
  };

  const getSelectedBeneficiary = async (defaultSSI: string) => {
    try {
      const bene = await BeneficiariesService.getIndividualBeneficiary(
        defaultSSI,
      );
      setBeneficiary(bene);
    } catch (error) {
      logError({ action: 'Error getting selected beneficiary', error });
    }
  };

  const requestForm = useForm<QuickDrawdownRequest>(
    QuickDrawdownInitialValues,
    validation,
    () => { },
    false,
    false,
  );

  useEffect(() => {
    getSelectedBeneficiary(tradeItem?.defaultSSI);
  }, [tradeItem]);

  if (isEmpty(tradeItem.defaultSSI)) {
    return (
      <div className={drawerStyle ? classes.drawerContainer : classes.container}>
        {quickDrawdownLoading ? <Loader testId="quickDrawdownLoader" /> : (
          <>
            <div className={classes.titleAndButton}>
              <div className={classes.titlesContainer}>
                <div className={classes.title}>
                  <div className={classes.greyIcon}><FontAwesomeIcon icon={faBolt} /></div>
                  <div className={classes.greyTitle}>{t('quick_drawdown_settings')}</div>
                </div>
                <div className={classes.greySubtitle}>
                  {t('quick_drawdown_subtitle')}
                </div>
              </div>
              <div className={drawerStyle
                ? classes.drawerBtnContainer : classes.authoriseBtnContainer}
              >
                {(drawdownInputter || firstPartyDrawdownInputter) && tradeItem.status === TradeStatus.ACTIVE && (
                  <ActionButton
                    className={classes.authoriseBtn}
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    <div>
                      <FontAwesomeIcon icon={faUser as IconProp} color="#FFFFFF" />
                      <span className={classes.buttonText}>{t('select_default_beneficiary')}</span>
                    </div>
                  </ActionButton>
                )}
              </div>

            </div>
            <Collapse in={collapsed} style={{ padding: '0 30px 0 0px' }}>
              <div className={classes.alertContainer}>
                <div className={classes.dropdownContainer}>
                  <BeneficiaryDropdownContainer
                    currencyCode={tradeItem.buyCurrencyCode}
                    beneficiary={requestForm.values.beneficiary}
                    setFieldValue={requestForm.setFieldValue}
                    dropdownHeight="44px"
                    quickDrawdown
                  />
                </div>

                <div className={classes.saveBeneficiaryButton}>
                  <ActionButton
                    onClick={submitBeneficiary}
                    disabled={!requestForm.isValid}
                  >
                    <span>{t('save')}</span>
                  </ActionButton>
                </div>

              </div>
            </Collapse>
          </>
        )}

      </div>
    );
  }

  if (!isEmpty(tradeItem.defaultSSI)) {
    return (
      <div className={drawerStyle ? classes.drawerGreenContainer : classes.greenContainer}>

        {quickDrawdownLoading ? (
          <div className={classes.deleteDrawdownLoader}>
            <Loader testId="quickDrawdownLoader" />
          </div>
        ) : (
          <>
            <div className={classes.drawdownInfo}>
              <div className={drawerStyle ? classes.drawerFlexTitles : classes.flexTitlesContainer}>
                <div className={classes.title}>
                  <div className={classes.greenPad}><FontAwesomeIcon icon={faBolt} /></div>
                  <div className={classes.greenTitle}>{t('quick_drawdown_settings')}</div>
                </div>
                <div className={classes.greenSubtitle}>
                  {t('default_beneficiary_selected')}
                </div>
              </div>
              <div className={classes.dividerContainer}>
                <div className={classes.divider} />
              </div>
              <div className={[drawerStyle ? classes.drawerApproverContainer : classes.approverContainer,
              classes.collapsedContainer,
              ].join(' ')}
              >
                <div className={classes.approverGreen}>
                  <span>{selectedBeneficiary?.name}</span>
                </div>
                <div className={classes.approverInfo}>
                  <span className={classes.namePadding}>
                    {selectedBeneficiary?.bankCountryCode || '-'}
                  </span>
                  {' | '}
                  <span className={classes.namePadding} style={{ marginLeft: '8px' }}>
                    {selectedBeneficiary?.currencyCode || '-'}
                  </span>
                  {' | '}
                  <span className={classes.namePadding} style={{ marginLeft: '8px' }}>
                    {selectedBeneficiary?.iban || selectedBeneficiary?.accountNumber || '-'}
                  </span>
                </div>
              </div>
            </div>

            <div className={drawerStyle
              ? classes.drawerBtnContainer : classes.removeBtnContainer}
            >
              {(drawdownInputter || firstPartyDrawdownInputter) && (
                <ActionButton
                  className={classes.deleteBtn}
                  onClick={removeBeneficiary}
                >
                  <div>
                    <span className={classes.buttonText}>{t('remove_default_beneficiary')}</span>
                  </div>
                </ActionButton>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
};

export default QuickDrawdown;
