import React, {
  memo,
  ReactText, useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import t from 'utils/translationHelper';
import { useSelector } from 'react-redux';

import {
  Box, Button, Card, IconButton,
} from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { useSnackbar } from '@alpha/ui-lib/ui/Snackbar';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFileExcel,
  faFilePdf,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FileType } from '../../hooks/useReportsPolling';
import { TStore } from '../../store';
import { TDownload, TDownloadQueue } from '../../store/generateReport/reducer';

import { useStyles } from './SnackMessage.styles';

interface ISnackMessageProps {
  reportType: FileType;
  reportMessage: string;
  id: ReactText;
  error?: boolean;
}

const SnackMessage: React.FC<ISnackMessageProps> = React.forwardRef(
  (props: ISnackMessageProps, ref: any) => {

    const styles = useStyles();
    const downloadRef = useRef(null);
    const {
      reportType, id, error, reportMessage,
    } = props;
    const { closeSnackbar } = useSnackbar();
    const downloadQueue = useSelector<TStore, TDownloadQueue>(
      (store: TStore) => store.generatePdf.downloadQueue,
    );
    const [downloadState, setDownloadState] = useState<Omit<TDownload, 'id' | 'downloadTime'>>({
      status: 'INITIATE',
    });

    const handleDownload = () => {
      closeSnackbar(id);
    };
    const handleDismiss = () => {
      closeSnackbar(id);
    };

    const handlePageTypeDownload = (downloadPageQueue: TDownloadQueue) => {
      downloadPageQueue.forEach((value: TDownload) => {
        if (value.id === id) {
          setDownloadState(
            { status: value.status, downloadLink: value.downloadLink },
          );
        }
      });
    };
    useEffect(() => {
      handlePageTypeDownload(downloadQueue);
    }, [downloadQueue]);

    const renderRight = () => {
      if (error || downloadState.status === 'ERROR') {
        return (
          <>
            <IconButton onClick={handleDismiss} className={styles.close}>
              <FontAwesomeIcon icon={faTimes as IconProp} />
            </IconButton>
          </>
        );
      }
      if (downloadState.status === 'INITIATE') {
        return (
          <>
            <Loader testId="loader" size={20} className={styles.loader} />
          </>
        );
      }
      if (downloadState.status === 'COMPLETED' && downloadState.downloadLink) {
        return (
          <>
            <Button
              download
              href={downloadState.downloadLink}
              target="_blank"
              ref={downloadRef}
              className={styles.download}
              onClick={handleDownload}
            >
              {t('download^')}
            </Button>
            <IconButton onClick={handleDismiss} className={styles.close}>
              <FontAwesomeIcon icon={faTimes as IconProp} />
            </IconButton>
          </>
        );
      }
      return (
        <>
          <IconButton onClick={handleDismiss} className={styles.close}>
            <FontAwesomeIcon icon={faTimes as IconProp} />
          </IconButton>
        </>
      );
    };

    const handleVariables = () => {
      if (downloadState.status === 'ERROR' || error) {
        return {
          text: `${reportMessage} ${t('download_error')}`,
          subHeader: t('there_was_an_error_generating_your_report'),
          backgroundColor: styles.error,
        };
      }
      if (downloadState.status === 'INITIATE') {
        return {
          text: `${reportMessage} ${t('download_in_progress')}`,
          subHeader: t('we_will_let_you_no_when_its_complete'),
          backgroundColor: styles.initiate,
        };
      }
      return {
        text: `${reportMessage} ${t('download_completed')}`,
        subHeader: t('click_download_to_receive_your_file'),
        backgroundColor: styles.completed,
      };
    };

    const variables = handleVariables();
    return (
      <Card
        className={clsx([styles.card, variables.backgroundColor])}
        ref={ref}
      >
        <Box className={styles.wrapper}>
          <Box className={styles.left}>
            <Box className={styles.icon}>
              <FontAwesomeIcon
                icon={(reportType === FileType.PDF ? faFilePdf : faFileExcel) as IconProp}
              />
            </Box>
            <Box className={styles.typography}>
              <Typography variant="h3" className="header">
                {variables.text}
              </Typography>
              <Typography variant="subtitle1" className="subHeader">
                {variables.subHeader}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.right}>{renderRight()}</Box>
        </Box>
      </Card>
    );
  },
);

export default memo(SnackMessage);
