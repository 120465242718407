import useAuthorization from 'hooks/useAuthorization';
import useLog from 'hooks/useLog';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { UserRole } from 'models/user';
import React, { createContext, useEffect, useState } from 'react';
import routes from 'routes.path';
import browserHistory from 'services/history/browserHistory';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';

import useStyles from '../../index.styles';

import BeneficiaryApprovals from './BeneficiaryApprovals';
import PaymentApprovals from './PaymentApprovals';
import TradeApprovals from './TradeApprovals';
import TransferApprovals from './TransferApprovals';

export type TOutstandingActionsContext = {
  pendingPaymentsCount?: number,
  setPendingPaymentsCount?: React.Dispatch<number>,
  pendingBenesCount?: number,
  setPendingBenesCount?: React.Dispatch<number>,
  pendingTransfersCount?: number,
  setPendingTransfersCount?: React.Dispatch<number>,
  pendingTradesCount?: number,
  setPendingTradesCount?: React.Dispatch<number>,
};

export const OutstandingActionsContext = createContext<TOutstandingActionsContext>({});

const OutstandingActionWidget: React.FC = () => {
  const classes = useStyles();

  const { logEvent } = useLog();
  const [pendingPaymentsCount, setPendingPaymentsCount] = useState(0);
  const [pendingBenesCount, setPendingBenesCount] = useState(0);
  const [pendingTradesCount, setPendingTradesCount] = useState(0);
  const [pendingTransfersCount, setPendingTransfersCount] = useState(0);

  const { authorized: authorisedIATApprover } = useAuthorization([[UserRole.IAT_APPROVER]]);
  const { isEMoneyDisabled } = useSwitchAccount();

  const tabTitles: string[] = [
    `${t('beneficiary_approvals')} ${pendingBenesCount > 0 ? `(${pendingBenesCount})` : ''}`,
  ];

  !isEMoneyDisabled()
    && tabTitles.unshift(`${t('payment_approvals')} ${pendingPaymentsCount > 0 ? `(${pendingPaymentsCount})` : ''}`);

  !isEMoneyDisabled()
    && tabTitles.push(`${t('trade_approvals')} ${pendingTradesCount > 0 ? `(${pendingTradesCount})` : ''}`);

  authorisedIATApprover
    && !isEMoneyDisabled()
    && tabTitles.push(`${t('transfer_approvals')} ${pendingTransfersCount > 0 ? `(${pendingTransfersCount})` : ''}`);

  const [tabIndex, setTabIndex] = useState(0);

  const contextValue = {
    pendingPaymentsCount,
    setPendingPaymentsCount,
    pendingBenesCount,
    setPendingBenesCount,
    pendingTradesCount,
    setPendingTradesCount,
    pendingTransfersCount,
    setPendingTransfersCount,
  };

  useEffect(() => {
    logEvent({ action: 'Dashboard - View Outstanding Actions' });
  }, []);

  const handleViewAllButtonClick = () => {
    let pageToGo = routes.dashboard;
    let widgetName = '';
    if (!isEMoneyDisabled()) {
      switch (tabIndex) {
        case 0:
          pageToGo = `${routes.transactions.base}?paymentStatus=SUBMITTED`;
          widgetName = 'Pending Payments';
          break;
        case 1:
          pageToGo = `${routes.beneficiaries}?tab=pending`;
          widgetName = 'Pending Beneficiaries';
          break;
        case 2:
          pageToGo = `${routes.transactions.fxtrades}?tradeStatus=SUBMITTED`;
          widgetName = t('pending_trades');
          break;
        case 3:
          pageToGo = `${routes.transactions.transfer}`;
          widgetName = 'Pending Transfers';
          break;
        default:
          break;
      }
    } else {
      pageToGo = `${routes.beneficiaries}?tab=pending`;
      widgetName = 'Pending Beneficiaries';
    }
    logEvent({ action: 'Dashboard - View All', detail: { widget: widgetName } });
    browserHistory.push(pageToGo);
  };

  return (
    <Box
      className={classes.widget}
      style={{
        gridColumn: 'span 2',
      }}
    >
      <Box className={classes.headerContainer}>
        <Typography className={classes.title}>{t('outstanding_actions')}</Typography>
        {' '}
        <ActionButton
          style={{ background: '#F7F7F7', color: '#212529' }}
          onClick={handleViewAllButtonClick}
        >
          {t('view_all~')}
        </ActionButton>

      </Box>
      <StyledTabsWrapper
        className={classes.tradesCommingToValueES}
        testId="outstanding-actions-tabs-wrapper"
        tabTitles={tabTitles}
        tabIndex={tabIndex}
        handleChange={(selectedTabIndex: number) => {
          setTabIndex(selectedTabIndex);
          logEvent({
            action: 'Dashboard - Outstanding Actions Click Tab',
            detail: {
              tab: selectedTabIndex,
              tabName: tabTitles[selectedTabIndex],
            },
          });
        }}
      />
      <div style={{ marginTop: '4px' }}>
        <OutstandingActionsContext.Provider
          value={contextValue}
        >
          {
            !isEMoneyDisabled()
              ? <Box display={tabIndex === 0 ? 'block' : 'none'}><PaymentApprovals /></Box>
              : ''
          }
          <Box display={(isEMoneyDisabled() && tabIndex === 0) || (!isEMoneyDisabled() && tabIndex === 1) ? 'block' : 'none'}><BeneficiaryApprovals /></Box>
          <Box display={(!isEMoneyDisabled() && tabIndex === 2) ? 'block' : 'none'}><TradeApprovals /></Box>
          {
            authorisedIATApprover && !isEMoneyDisabled()
              ? <Box display={tabIndex === 3 ? 'block' : 'none'}><TransferApprovals /></Box>
              : ''
          }
        </OutstandingActionsContext.Provider>
      </div>
    </Box>
  );
};

export default OutstandingActionWidget;
