import React, { useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import BackdropLoader from 'components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable from 'components/Table/EmptyTable';
import { formatCurrency, formatNumber } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import { ProgramDto, ProgramPeriodDto } from '@alpha/fx-dtos';
import { IconButton } from '@alpha/ui-lib/ui/button/IconButton';
import { Typography } from '@alpha/ui-lib/ui/external';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { faPen } from '@fortawesome/pro-light-svg-icons';

import useStyles from './HedgingSummry.styles';

const HedgingProgramSummary = ({
  program, periods, handleRowClick, handleEditForecastClick,
}:
  {
    program: ProgramDto | undefined,
    periods: ProgramPeriodDto[],
    handleRowClick?: Function,
    handleEditForecastClick?: (period: ProgramPeriodDto) => void
  }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  if (loading) { return <BackdropLoader testId="hedging-program-summary-loader" />; }

  const columns: IStyledGenericTableProps['columns'] = [
    {
      header: t('period'),
      accessor: 'period',
    },
    {
      header: t('weighted_avg_rate'),
      accessor: 'weightedAverageRate',
      align: 'right',
    },
    {
      header: t('forecast'),
      accessor: 'forecast',
      align: 'right',
    },
    {
      header: t('program'),
      accessor: 'activeHedgeRatio',
      align: 'right',
    },
    {
      header: t('target'),
      accessor: 'programAmount',
      align: 'right',
    },
    {
      header: t('hedged'),
      accessor: 'totalHedged',
      align: 'right',
    },
    {
      header: t('to_hedge'),
      accessor: 'totalToHedge',
      align: 'right',
    },
  ];

  const periodsTableData = periods.map((period) => ({
    ...period,
    weightedAverageRate: <span className={classes.greenText}>{formatNumber(period.weightedAverageRate, 6)}</span>,
    forecast:
  <span>
    {formatCurrency(program?.currencyCode || '', period.forecast)}
    {' '}
    <IconButton
      icon={faPen}
      className={classes.editButton}
      size="small"
      onClick={(e) => {
        if (handleEditForecastClick) {
          e.preventDefault();
          e.stopPropagation();
          handleEditForecastClick(period);
        }
      }}
    />
  </span>,
    activeHedgeRatio: `${period?.activeHedgeRatio}%`,
    programAmount: formatNumber(period?.programAmount, 2),
    totalHedged: formatNumber(period?.totalHedged, 2),
    totalToHedge: formatNumber(period?.totalToHedge, 2),
  }));

  return (
    <div style={{ position: 'relative' }}>
      <Typography className={classes.sectionHeader}>
        {t('hedging_summary')}
        {' '}
        <span className={classes.programName}>{program?.programName}</span>
      </Typography>
      {periods.length > 0
        ? (
          <StyledGenericTable
            testId="hedging-summary-table"
            columns={columns}
            data={periodsTableData}
            handleTableRowClick={(row: typeof periodsTableData[0]) => {
              if (handleRowClick) { handleRowClick(row.id); }
            }}
            clickable
          />
        ) : (
          <div style={{ height: '360px' }}>
            <EmptyTable
              title={t('no_program_periods')}
              subtitle={t('you_currently_do_not_have_any_program_periods')}
              icon={clipboardCross}
            />
          </div>
        )}

    </div>
  );
};

export default HedgingProgramSummary;
