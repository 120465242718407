import { useEffect, useState } from 'react';

import { AccountDto, IncomingUserData, UserAccountDto } from '@alpha/auth-dtos';

import { IOption } from '../../../components/AutocompleteDropDown/AutocompleteDropDown';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useForm from '../../../hooks/useForm';
import { TCountries } from '../../../models/countries';
import AuthorizationService from '../../../services/Authorization/authorization.service';
import BeneficiariesService from '../../../services/Beneficiaries/beneficiaries.service';
import parsePhone from '../../../utils/parsePhone';
import t from 'utils/translationHelper';

import { initialValues, TIncomingUserDataFormType, validation } from './formData';

export type Section = 'Details' | 'Entities';

export const useNewUserRequestModal = (
  handleClose: () => void,
  open: boolean,
  selectedAccount?: AccountDto,
  handleUserAdded?: () => void,
) => {
  const [section, setSection] = useState<Section>('Details');
  const [menuCloseCount, setMenuCloseCount] = useState<number>(0);
  const [currentOptions, setCurrentOptions] = useState<UserAccountDto[]>([]);
  const [textValue, setTextValue] = useState<string>('');
  const [entitiesLoading, setEntitiesLoading] = useState<boolean>(false);
  const [entities, setEntities] = useState<UserAccountDto[]>([]);
  const [countries, setCountries] = useState<TCountries>([]);
  const [userSubmitting, setUserSubmitting] = useState<boolean>(false);
  

  const [
    phoneCode,
    setPhoneCode,
  ] = useState<IOption>({ name: 'United Kingdom', code: 'GB' });

  const { userAccounts } = useAccountSettings();

  const snackbar = useAlphaSnackbar();

  const handleSetSection = (_section: Section) => setSection(_section);

  const handleSetEntities = async () => {
    setEntitiesLoading(true);
    try {
      setEntities(userAccounts.filter((account) => account.roles.includes('User Inputter')));
    } catch (error) {
      snackbar.trigger(error?.message || t('there_was_an_error_retrieving_entities'));
      console.error(error);
    }
    setEntitiesLoading(false);
    return null;
  };

  const userRequestForm = useForm<TIncomingUserDataFormType>(
    initialValues,
    validation, (userRequest: TIncomingUserDataFormType) => {
      setUserSubmitting(true);
      handleUserRequestSubmission(userRequest);
    },
  );

  // Handle the click on the next button,
  // check form validation and set phone number and username fields.
  const handleNextOnClick = async () => {
    try {
      checkAndUpdatePhoneNumber();
    } catch (e) {
      userRequestForm.setFieldError('phoneNumberWithoutExt', t('this_is_not_a_valid_phone_number'));
      return;
    }
    if (userRequestForm.isValid) {
      userRequestForm.setFieldValue('username', userRequestForm.values.email, false);
      handleSetSection('Entities');
    } else {
      userRequestForm.validateForm();
      userRequestForm.setTouched(
        {
          firstName: true,
          lastName: true,
          email: true,
          phoneNumberWithoutExt: true,
        },
      );
    }
  };

  const checkAndUpdatePhoneNumber = () => {
    const phone = parsePhone(userRequestForm.values.phoneNumberWithoutExt, phoneCode.code);
    if (!phone.isValid) {
      userRequestForm.setFieldError('phoneNumberWithoutExt', t('this_is_not_a_valid_phone_number'));
      throw new Error('invalid phone  error');
    }
    userRequestForm.setFieldValue('phoneNumber', phone.phoneNumber, false);
  };

  useEffect(() => {
    try {
      checkAndUpdatePhoneNumber();
    } catch (e) {
      userRequestForm.setFieldError('phoneNumberWithoutExt', t('this_is_not_a_valid_phone_number'));
    }
  }, [userRequestForm.values.phoneNumberWithoutExt]);

  const handleUserRequestSubmission = async (userRequest: IncomingUserData) => {
    try {
      const response = await AuthorizationService.createNewUser(userRequest);
      if (response.success) {
        snackbar.trigger(t('successfully_created_new_user'), 'success');
        if (handleUserAdded) { handleUserAdded(); }
      }
    } catch (error) {
      const errorCode = error?.response?.data?.code || '';

      switch (errorCode) {
        case 'UsernameExistsException':
          snackbar.trigger(t('user_with_the_same_email_already_exists'));
          break;
        case 'InvalidParameterException':
          snackbar.trigger(t('error_creating_new_user_please_check'));
          break;
        default:
          snackbar.trigger(t('error_creating_new_user'));
      }
      console.error(error);
    }
    handleClose();
    setUserSubmitting(false);
    userRequestForm.resetForm();
    setSection('Details');
  };

  async function getCountries() {
    try {
      setCountries(await BeneficiariesService.getCountries());
    } catch (e) {
      snackbar.trigger(e.response?.data?.error || e.message || t('there_was_an_issue_retrieving_countries'));
      console.error(e);
    }
  }

  useEffect(() => {
    if (selectedAccount) {
      const currentAccountSelected = userAccounts.filter(
        (account) => account.id === selectedAccount.id,
      );
      setCurrentOptions(currentAccountSelected);
    }
  }, [selectedAccount, open]);

  useEffect(() => {
    handleSetEntities();
  }, [userAccounts]);

  useEffect(() => {
    userRequestForm.setFieldValue('accountIds', currentOptions.map((acc) => acc.id));
  }, [currentOptions]);

  useEffect(() => {
    Promise.all([getCountries()]);
  }, []);

  return {
    handleNextOnClick,
    section,
    setSection,
    phoneCode,
    setPhoneCode,
    entities,
    menuCloseCount,
    setMenuCloseCount,
    entitiesLoading,
    setEntitiesLoading,
    currentOptions,
    setCurrentOptions,
    handleSetSection,
    textValue,
    setTextValue,
    countries,
    userRequestForm,
    userSubmitting,
  };
};

export default useNewUserRequestModal;
