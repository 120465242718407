import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    accountwrapper: {
      display: 'flex',
      borderRadius: 5,
      cursor: 'pointer',
      justifyContent: 'space-between',
      margin: '8px 0',
    },
    accountDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    accountName: {
      fontSize: 16,
      fontWeight: typography.fontWeightRegular,
    },
    selectedbox: {
      display: 'flex',
      alignItems: 'center',
    },
  }), { name: 'BeneShareDrawerSelectAccount' },
);

export default useStyles;
