import t from 'utils/translationHelper';
import * as yup from 'yup';

import { IncomingUserData } from '@alpha/auth-dtos';

export type TIncomingUserDataFormType = IncomingUserData & {
  phoneNumberWithoutExt: string;
};

export const initialValues: TIncomingUserDataFormType = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  individualId: '',
  phoneNumber: '',
  phoneNumberWithoutExt: '',
  approvalLevel: 'F', // Default level if not configured
};

export const validation = {
  firstName: yup
    .string()
    .matches(/^[aA-zZ\s]*$/, t('name_cannot_contain_numbers_or_special_characters'))
    .max(70, t('must_be_70_characters_or_less'))
    .required(t('first_name_is_required')),
  lastName: yup
    .string()
    .matches(/^[aA-zZ\s]*$/, t('name_cannot_contain_numbers_or_special_characters'))
    .required(t('last_name_is_required')),
  email: yup
    .string()
    .email(t('invalid_email_address'))
    .required(t('email_address_is_required')),
  phoneNumber: yup
    .string()
    .required(t('a_phone_number_is_required')),
  phoneNumberWithoutExt: yup
    .string()
    .matches(/^[0-9\s]*$/, t('please_input_numbers_only'))
    .required(t('a_phone_number_is_required')),
};
