import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import t from 'utils/translationHelper';

import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './UploadFile.styles';

interface IProps {
  icon: IconDefinition,
  fileType: string,
  filePath: string,
}

const DownloadTemplate: React.FC<IProps> = (props: IProps) => {
  const { icon, fileType, filePath } = props;

  const styles = useStyles();

  return (
    <Link to={filePath} target="_blank" download className={styles.downloadLinkContainer}>
      <Box className={styles.downloadTemplate}>
        <Box className={clsx(styles.downloadTemplateBox, 'fileType')}>
          <Typography className={styles.bodyText}>{t('file_type')}</Typography>
          <Typography className={styles.subHeaderText}>{fileType}</Typography>
        </Box>
        <Box className={clsx(styles.downloadTemplateBox, 'icon')}>
          <FontAwesomeIcon className={styles.icon} icon={icon as IconProp} />
        </Box>
      </Box>
    </Link>
  );
};

export default DownloadTemplate;
