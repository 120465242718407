import React from 'react';

import { PaymentRouteEnum } from '../../../../../models/payments';
import routes from '../../../../../routes.path';
import history from '../../../../../services/history/browserHistory';

import BookFxFooter from './BookFxFooter/BookFxFooter';

interface IProps {
  batchId: string;
}

const BookFxFooterContainer: React.FC<IProps> = ({ batchId }: IProps) => {
  const handleBookFxClick = () => {
    history.push(routes.transactions.batchSummaryRouteFunc(batchId, PaymentRouteEnum.BASE));
  };

  const handleClick = () => {
    history.push(routes.transactions.base);
  };

  return (
    <BookFxFooter
      handleBookFxClick={handleBookFxClick}
      handleClick={handleClick}
    />
  );
};

export default BookFxFooterContainer;
