import React, { memo } from 'react';

import { TimeoutModal } from '@alpha/ui-lib/ui/TimeoutModal';

import useInactivityTracker from './useInactivityTracker';

interface IInactivityTrackerProps {
  children: React.ReactNode;
}
const InactivityTracker: React.FC<IInactivityTrackerProps> = (
  { children }: IInactivityTrackerProps,
) => {
  const {
    expirationTimer, modalPopupThreshold, handleSignOut, open, handleResetTimer,
  } = useInactivityTracker();

  return (
    <>
      <TimeoutModal
        timeRemainingInSeconds={expirationTimer}
        maxTimeInSeconds={modalPopupThreshold}
        logoutOnClick={handleSignOut}
        open={open}
        continueOnClick={handleResetTimer}
        testId="timeout-modal"
      />
      <div onMouseMove={handleResetTimer} onScroll={handleResetTimer} data-testid="children-wrapper">
        {children}
      </div>
    </>
  );
};

export default memo(InactivityTracker);
