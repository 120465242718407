import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    container: {
      maxWidth: '600px',
    },
    wrapper: {
      padding: '0px !important',
      maxHeight: '60vh',
      overflowY: 'auto',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto 32px',
      justifyContent: 'flex-start',
      '& h2': {
        fontWeight: '600',
        fontSize: '18px',
        position: 'relative'
      },
    },
    body: {
      borderRadius: '10px',
      border: '1px solid #eaeaea',
      padding: '16px 50px',
      margin: 'auto 32px',
      marginTop: '16px',
      '&:not(:first-child)': {
        marginTop: '8px',
      },
      '& h3': {
        fontWeight: 600
      },
      '& h6': {
        color: '#222222',
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: '24px',
      },
      '& .errorMessage': {
        fontWeight: 'bold',
        paddingBottom: '20px',
      },
      '& .warning': {
        marginBottom: '0px',
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        padding: '8px 0px'
      }
    },
    message: {
      color: 'rgba(33, 37, 41, 0.5)',
      fontSize: "13px",
      margin: '0px',
    },
    buttonsContainer: {
      textAlign: 'right',
      margin: 'auto 32px',
      marginTop: '10px',
      display: 'flex',
      '& .closeButton': {
        fontWeight: 'bold',
      },
      '& .cancelButton': {
        background: '#F7F7F7',
        color: '#212529'
      },
      '& .submitButton': {
        marginLeft: '16px',
        width: 'auto',
        flex: '1',
      },
    },
    paddingBottom: {
      paddingBottom: '20px',
    },
    noDivider: {
      '& .MuiTableRow-root': {
        borderBottom: 'none'
      }
    },
    matchingTable: {
      marginTop: '24px',
    },
    floatingIcon: {
      position: 'absolute',
      marginLeft: "-24px",
      marginTop: '4px',
    },
    copWarning: {
      backgroundColor: 'rgba(207, 117, 47, 0.1) !important',
      '& .icon': {
        color: '#CF752F !important',
        fontWeight: '600 !important',
        alignSelf: 'start !important',
        marginTop: '4px',
      },
      '& .text': {
        fontWeight: '400 !important',
        color: '#CF752F !important',
      }
    }
  }),
  { name: 'PayeeConfirmationModal' },
);

export default useStyles;
