import { useCallback, useState } from 'react';
import qs, { ParsedQs } from 'qs';

const setQueryStringWithoutPageReload = (qsValue:string): void => {
  const newurl = `${window.location.protocol}//${
    window.location.host
  }${window.location.pathname
  }${qsValue}`;

  window.history.pushState({ path: newurl }, '', newurl);
};

const setQueryStringValue = (
  key:string,
  value:string,
  queryString = window.location.search,
): void => {
  const values = qs.parse(queryString, {
    ignoreQueryPrefix: true,
    parseArrays: true,
    comma: true,
  });

  const newQsValue = qs.stringify({ ...values, [key]: value }, { arrayFormat: 'comma' });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (
  key:string,
  queryString = window.location.search,
): undefined | string | string[] | ParsedQs | ParsedQs[] => {
  const values = qs.parse(queryString, {
    ignoreQueryPrefix: true,
    parseArrays: true,
    comma: true,
  });

  return values[key];
};

function useQueryString(key:string, initialValue:any): [
  typeof initialValue, (newValue:typeof initialValue) => void] {
  const queryValue = getQueryStringValue(key);

  const queryArrayValue = (Array.isArray(initialValue)
   && !Array.isArray(queryValue)) ? [queryValue] : queryValue;

  const [value, setValue] = useState<typeof initialValue>(
    queryValue ? queryArrayValue : initialValue,
  );

  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key],
  );

  return [value, onSetValue];
}

export default useQueryString;
