import React, { useEffect } from 'react';
import t from 'utils/translationHelper';

import { Card } from '@alpha/ui-lib/ui/layout/Card';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';

import useSwitchAccount from '../../hooks/useSwitchAccount';

import StatementsCtas from './StatementsCtas/StatementsCtas';
import StatementsInfoDrawer from './StatementsInfoDrawer/StatementsInfoDrawer';
import StatementsSelect from './StatementsSelect/StatementsSelect';
import StatementsSummary from './StatementsSummary/StatementsSummary';
import StatementsTable from './StatementsTable/StatementsTable';
import useStatements from './useStatements';

export const componentTestIds = {
  StatementsCtas: 'statements-ctas-component',
  StatementsSummary: 'statements-summary-component',
  StatementsTable: 'statements-table-component',
};

export enum DateFields {
  START = 'startDate',
  END = 'endDate',
}

export const Statements: React.FC = () => {

  const { homePageUrl } = useSwitchAccount();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];

  const {
    loading,
    statement,
    currencyAccountId,
    selectedDates,
    setSelectedDates,
    handlePageLoad,
    handleSetStatement,
    setStatement,
  } = useStatements();

  useEffect(() => {
    handlePageLoad();
  }, []);

  const startDateIso = selectedDates.startDate.format('yyyy-MM-DD');
  const endDateIso = selectedDates.endDate.format('yyyy-MM-DD');

  return (
    <APMainLayout
      height="167px"
      title={t('statements')}
      breadCrumbs={breadcrumbs}
      sticky
      pageTabs={(
        <StatementsSelect
          accountId={currencyAccountId}
        />
      )}
      rightAlignedCta={(
        <StatementsCtas
          componentTestId={componentTestIds.StatementsCtas}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          currencyAccountId={currencyAccountId || ''}
          startDateIso={startDateIso}
          endDateIso={endDateIso}
        />
      )}
    >
      <div style={{
        minHeight: 'calc(100vh - 310px)',
        paddingTop: '12px',
        paddingBottom: '30px',
        backgroundColor: '#fafafa',
      }}
      >
        <Card>
          <StatementsSummary
            componentTestId={componentTestIds.StatementsSummary}
            accountId={currencyAccountId}
            startDate={startDateIso}
            endDate={endDateIso}
          />
          <StatementsTable
            key={`${startDateIso}-${endDateIso}`}
            currencyAccountId={currencyAccountId}
            componentTestId={componentTestIds.StatementsTable}
            startDate={startDateIso}
            endDate={endDateIso}
            handleSetStatement={handleSetStatement}
          />

          <StatementsInfoDrawer
            loading={loading}
            statementData={statement}
            onClose={() => {
              setStatement(undefined);
            }}
          />
        </Card>
      </div>
    </APMainLayout>
  );
};
