import useActionDropdown from 'hooks/useActionDropDown';
import React from 'react';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { BeneficiaryDto, BeneficiaryStatus } from '@alpha/bene-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import useStyles from '../Body/BeneficiaryActionDropdown/BeneficiaryActionDropDown.style';

interface IProps {
  beneficiary: BeneficiaryDto;
  currentOptions?: Approver[],
  handleDelete?: (id: string) => void,
  handleMarkAsValid?: (id: string) => void,
  handleOpenDrawer?: (id: string) => void,
}

const BatchBeneficiaryActionDropDown: React.FC<IProps> = ({
  beneficiary,
  currentOptions,
  handleDelete,
  handleMarkAsValid,
  handleOpenDrawer,
}: IProps) => {
  const classes = useStyles();

  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    setOpen,
  } = useActionDropdown({
    currentOptions,
  });

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  const menuItems = [];

  if (beneficiary?.status === BeneficiaryStatus.VALIDATED) {
    menuItems.push(
      {
        content: (
          <Typography data-testid={`delete-bene-action-${beneficiary.id}`}>
            {t('delete_beneficiary')}
          </Typography>
        ),
        icon: displayIcon('Delete'),
        onClick: () => handleDelete && handleDelete(beneficiary?.id),
        underline: true,
        isReview: true,
      },
    );
  } else {
    menuItems.push(
      {
        content: (
          <Typography data-testid={`mark-valid-bene-action-${beneficiary.id}`}>
            {t('mark_bene')}
          </Typography>
        ),
        icon: displayIcon('Approve'),
        onClick: () => handleMarkAsValid && handleMarkAsValid(beneficiary.id),
        underline: true,
        isReview: true,
      },
      {
        content: (
          <Typography data-testid={`view-bene-action-${beneficiary.id}`}>
            {t('view_bene')}
          </Typography>
        ),
        icon: displayIcon('View'),
        onClick: () => handleOpenDrawer && handleOpenDrawer(beneficiary.id),
        underline: true,
        isReview: true,
      },
      {
        content: (
          <Typography data-testid={`delete-bene-action-${beneficiary.id}`}>
            {t('delete_beneficiary')}
          </Typography>
        ),
        icon: displayIcon('Delete'),
        onClick: () => handleDelete && handleDelete(beneficiary.id),
        underline: true,
        isReview: true,
      },
    );
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box data-testid="action-bene-dropdown" className={classes.wrapper}>
        <APMenu
          actions
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          onClose={handleClose}
          buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
          buttonTitle=""
          // @ts-ignore
          icon={faEllipsisH}
        >
          {menuItems.map((item) => <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>)}
        </APMenu>
      </Box>
    </ClickAwayListener>
  );
};

export default BatchBeneficiaryActionDropDown;
