import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { TAuthyState } from 'store/authy/reducer';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IApproveButtonProps {
  initiateApproveBeneficiary: (own: boolean, __batchId?: string) => void;
  own: boolean;
}
const ApproveButton: React.FC<IApproveButtonProps> = (props: IApproveButtonProps) => {
  const { initiateApproveBeneficiary, own } = props;


  const [disabled, setDisabled] = useState(false);
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  useEffect(() => {
    if (authyState.status === 'SUCCESS') {
      setDisabled(true);
    }
  }, [authyState.status]);

  return (
    <ActionButton
      testId="approve-btn"
      startIcon={<FontAwesomeIcon icon={faLock as IconProp} />}
      onClick={() => initiateApproveBeneficiary(own)}
      disabled={disabled}
    >
      {t('approver_beneficiary')}
    </ActionButton>
  );
};

export default ApproveButton;
