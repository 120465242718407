import React from 'react';

import { Box } from '@alpha/ui-lib/ui/external';

import { useStyles } from './DrawerBackdrop.styles';

interface IProps {
  display: boolean;
  children: JSX.Element[] | JSX.Element
}
const DrawerBackdrop: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  const { display, children } = props;
  if (!display) return null;
  return (
    <Box className={styles.drawerOverlay}>
      <Box className={styles.content}>
        { children }
      </Box>
    </Box>
  );
};

export default DrawerBackdrop;
