import moment from 'moment';

const getDefaultMtmDate = (): string => {
  const utcTime = moment().utcOffset(0);
  const isWeekDayAfter6PM = utcTime.hour() >= 18 && utcTime.day() <= 5 && utcTime.day() > 0;
  const [isSunday, isMonday, isSaturday] = [
    utcTime.day() === 0,
    utcTime.day() === 1,
    utcTime.day() === 6,
  ];

  if (isWeekDayAfter6PM) {
    return moment().format('YYYY-MM-DD');
  }

  if (isSaturday) {
    return moment().days(5).format('YYYY-MM-DD'); // Friday this week
  }

  if (isSunday || isMonday) {
    return moment().days(-2).format('YYYY-MM-DD'); // Friday last week
  }

  return moment().subtract(1, 'days').format('YYYY-MM-DD');
};

export default getDefaultMtmDate;
