import React from 'react';

import { DrawdownDto, TradeDto } from '@alpha/fx-dtos';
import { Button } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status/Status';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';

import useAuthorization from '../../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../../models/user';
import formatIsoDate from '../../../../../../utils/formatIsoDate';
import t from 'utils/translationHelper';

import useStyles from './PadColumnValue.styles';

interface IProps {
  padEligibleItem: TradeDto | DrawdownDto;
  handleTradeAuthorisation?: (tradeId: string) => void;
  handleDrawdownAuthorisation?: (drawdown: DrawdownDto) => void;
}

const PadColumnValue: React.FC<IProps> = (props: IProps) => {
  const {
    padEligibleItem,
    handleTradeAuthorisation,
    handleDrawdownAuthorisation,
  } = props;

  const { authorized: authorisedPadApprover } = useAuthorization([[UserRole.PAD_APPROVER]]);

  const classes = useStyles();
  if (padEligibleItem.padEligible) {
    return padEligibleItem.padApproved
      ? (
        <APTooltip
          title={(
            <div className={classes.padTooltipContainer}>
              <span>{t('pad_approved')}</span>
              <div className={classes.approvalDiv}>
                <span className={classes.approvalKey}>{t('approved_by')}</span>
                {' '}
                {padEligibleItem.padApprovedBy}
              </div>
              <div className={classes.approvalDiv}>
                <span className={classes.approvalKey}>{t('approval_date')}</span>
                {' '}
                {formatIsoDate(padEligibleItem.padApprovedDate)}
              </div>
            </div>
          )}
        >
          <div>
            <Status variant="success">{t('pad')}</Status>
          </div>
        </APTooltip>
      )
      : (
        <APTooltip
          title={(
            <div className={classes.padTooltipContainer}>
              <span>{t('pad_eligible')}</span>
              {authorisedPadApprover && (
                <Button
                  disableRipple
                  className={classes.tableButton}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    if (handleTradeAuthorisation) handleTradeAuthorisation(padEligibleItem.id);
                    if (handleDrawdownAuthorisation) handleDrawdownAuthorisation(padEligibleItem as DrawdownDto);
                  }}
                >
                  <span className={classes.tooltipText}>{t('click_here_to_authorise_pad')}</span>
                </Button>
              )}
            </div>
          )}
          interactive
        >
          <div>
            <Status variant="default">{t('pad')}</Status>
          </div>
        </APTooltip>
      );
  }
  return <></>;
};

export default PadColumnValue;
