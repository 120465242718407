/* eslint-disable max-lines-per-function */
import clipboardCross from 'assets/clipboardCross.svg';
import ActionDropDown from 'components/ActionDropDown/ActionDropDown';
import EmptyTable from 'components/Table/EmptyTable';
import useLog from 'hooks/useLog';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import browserHistory from 'services/history/browserHistory';
import { TStore } from 'store';
import { mapFacilityTermStatus } from 'utils/credit/mapFacilityTermStatus';
import { sortFacilityTerms } from 'utils/credit/sortFacilityTerms';
import { formatNumber, getCurrencySymbol } from 'utils/currency.helpers';
import t from 'utils/translationHelper';
import vmBreached from 'utils/vmBreached';

import { CreditFacilityDto, CreditFacilityTermDto, CreditFacilityTermStatus } from '@alpha/credit-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Button, Typography } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import APPagination from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination';
import { Status } from '@alpha/ui-lib/ui/Status';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './index.styles';

interface IProps {
  span?: number;
  shouldUseBrackets?: boolean
  isDashboardWidget?: boolean
}

const FacilityTermsTable: React.FC<IProps> = ({
  span = 3, shouldUseBrackets = false, isDashboardWidget = false,
}) => {
  const classes = useStyles();
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);
  const [loading, setLoading] = useState(true);
  const creditFacility = useSelector<TStore, CreditFacilityDto | undefined>(
    (state: TStore) => state.creditFacility.facility,
  );

  const [index, setIndex] = useState(0);
  const [currentData, setCurrentData] = useState<CreditFacilityTermDto[]>([]);
  const [sortedCreditFacility, setSortedCreditFacility] = useState<CreditFacilityTermDto[]>([]);
  const { logEvent } = useLog();

  const menuItems = new Map();
  menuItems.set('view_terms', 'View');

  const setUrl = (id: string): string => `/app/credit-facility/term/${id}`;

  const handleTermNumberClick = (e: string) => {
    const id = e;
    if (id) {
      browserHistory.push(`/app/credit-facility/term/${id}`);
    }
  };
  const marginStatusIcon = (mtmPercentage: number, variationMargin: number) => {
    if (mtmPercentage === undefined
      || (mtmPercentage && mtmPercentage === 0)
      || variationMargin === undefined
      || mtmPercentage == null
      || variationMargin == null) {
      return <FontAwesomeIcon icon={faExclamationCircle as IconProp} color="gray" />;
    }

    if (mtmPercentage >= 0) {
      return <FontAwesomeIcon icon={faExclamationCircle as IconProp} color="#55B171" />;
    }
    if (mtmPercentage < 0 && vmBreached(mtmPercentage, variationMargin)) {
      return <FontAwesomeIcon icon={faExclamationCircle as IconProp} color="#D55E5E" />;
    }
    if (mtmPercentage < 0 && !vmBreached(mtmPercentage, variationMargin)) {
      return (
        <FontAwesomeIcon
          icon={faExclamationCircle as IconProp}
          color="#FFC107"
        />
      );
    }
  };

  const updateTable = useCallback((facilitySummary: any, position?: number) => {
    const termsData: IStyledGenericTableProps['data'] = facilitySummary?.facilityTerms?.map(
      (term: any) => ({
        termNumber: (
          <Button
            disableRipple
            className={classes.tableButton}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              logEvent({ action: 'Clicked on term in facility terms table' });
              if (handleTermNumberClick) { handleTermNumberClick(term.id); }
            }}
          >
            <span className={term?.status === CreditFacilityTermStatus.ACTIVE ? classes.link : classes.nonActiveLink}>
              {term.displayName}
            </span>
          </Button>
        ),
        limit: <span>{`${getCurrencySymbol(facilitySummary.currencyCode)}${formatNumber(term.facilityLimit, 2)}`}</span>,
        imPercent: <span>
          {term.initialMargin !== null ? `${term.initialMargin}%` : '-'}
        </span>,
        vm: <span>
          {term.variationMargin !== null ? `${term.variationMargin}%` : '-'}
        </span>,

        tenor: <span>{term.tenor ? `${term.tenor}` : '-'}</span>,
        bespokeFeatures: term.bespokeTerms.length > 0 ? (
          <APTooltip
            placement="bottom"
            arrow
            title={
              (
                <div>
                  {term.bespokeTerms?.map((b) => (
                    <li key={b}>
                      {t(b)}
                    </li>
                  ))}
                </div>
              )
            }
          >
            <span className={classes.link}>
              {term.bespokeTerms?.length}
            </span>
          </APTooltip>
        )
          : <span>-</span>,
        marginStatus: (<div className={classes.marginStatus}>
          <span>{marginStatusIcon(term.mtmPercentage, term.variationMargin)}</span>
          <span className={classes.mtmPercentage}>{term.mtmPercentage !== null ? `${term.mtmPercentage}%` : ''}</span>
        </div>
        ),
        mtmValuation: <span>
          {term.mtmAmount !== null && term.mtmAmount !== undefined
            ? `${getCurrencySymbol(facilitySummary.currencyCode)}${formatNumber(term.mtmAmount, 2, shouldUseBrackets)}` : '-'}
        </span>,
        status: (
          <Status
            className={classes.status}
            variant={mapFacilityTermStatus(term?.status).variant}
          >
            {t(mapFacilityTermStatus(term?.status).text)}
          </Status>
        ),
        action: (
          <ActionDropDown
            displayMultiSelect={displayMultiSelect}
            setDisplayMultiSelect={setDisplayMultiSelect}
            currentOptions={[]}
            menuItems={menuItems}
            pageUrl={setUrl(term.id)}
          />
        ),
      }),
    );
    return termsData.slice(position!, position + 3);
  }, []);

  useEffect(() => {
    if (sortedCreditFacility?.facilityTerms?.length > 0) {
      const facilityTermsData = updateTable(sortedCreditFacility, index);
      setCurrentData(facilityTermsData);
    }

    if (sortedCreditFacility) {
      setLoading(false);
    }
  }, [sortedCreditFacility]);

  useEffect(() => {
    const sortedFacilityTerms = sortFacilityTerms(creditFacility?.facilityTerms);
    const sortedCreditFacility = { ...creditFacility, facilityTerms: sortedFacilityTerms };
    setSortedCreditFacility(sortedCreditFacility);
  }, [creditFacility]);

  const handleNextClick = () => {
    setIndex(index + 3);
    setCurrentData(updateTable(sortedCreditFacility, index + 3));
  };

  const handlePrevClick = () => {
    setIndex(index - 3);
    setCurrentData(updateTable(sortedCreditFacility, index - 3));
  };

  const columns: IStyledGenericTableProps['columns'] = isDashboardWidget
    ? [{
      header: (
        <>
          {' '}
          <p className={classes.columns}>{t('term')}</p>
          {' '}
        </>),
      accessor: 'termNumber',
    },
    {
      header: (
        <>
          <APTooltip
            placement="bottom"
            arrow
            padding="8px"
            title={
              (
                <>
                  <div style={{ width: '115px' }}>
                    <div className={classes.toolTip}>
                      <div style={{ marginTop: '2px' }}>
                        <FontAwesomeIcon icon={faExclamationCircle as IconProp} color="#55B171" />
                      </div>
                      <div style={{ marginTop: '2px' }} className={classes.labelContainer}>
                        <Typography className={classes.labels}>{t('positive_mtm')}</Typography>
                      </div>
                    </div>
                    <div className={classes.toolTip}>
                      <div>
                        <FontAwesomeIcon
                          icon={faExclamationCircle as IconProp}
                          color="#FFC107"
                          style={{
                            marginLeft: '3px',
                            marginTop: '4px',
                          }}
                        />
                      </div>
                      <div style={{ marginTop: '2px' }} className={classes.labelContainer}>
                        <Typography className={classes.labels}>
                          {t('negative_mtm')}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.toolTip}>
                      <div style={{ marginTop: '2px' }}>
                        <FontAwesomeIcon
                          icon={faExclamationCircle as IconProp}
                          color="#D55E5E"
                        />
                      </div>
                      <div className={classes.labelContainer}>
                        <Typography style={{ marginRight: '2px', marginTop: '2px' }} className={classes.labels}>
                          {t('breached_vm')}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.toolTip}>
                      <div style={{ marginTop: '2px' }}>
                        <FontAwesomeIcon
                          icon={faExclamationCircle as IconProp}
                          color="gray"
                        />
                      </div>
                      <div className={classes.labelContainer}>
                        <Typography style={{ marginRight: '2px', marginTop: '2px' }} className={classes.labels}>
                          {t('pending_mtm')}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
          >
            <p className={classes.columns}>
              {' '}
              {t('mtm_status')}
              {' '}
              <FontAwesomeIcon style={{ verticalAlign: '-2px' }} icon={faInfoCircle as IconProp} />
            </p>
          </APTooltip>
          {' '}
        </>),
      accessor: 'marginStatus',
    },
    {
      header: (
        <>
          {' '}
          <p className={classes.columns}>{t('mtm_valuation')}</p>
          {' '}
        </>),
      accessor: 'mtmValuation',
      align: 'center',
    },
    {
      header: (
        <>
          {' '}
          <p className={classes.columns}>{t('status')}</p>
          {' '}
        </>),
      accessor: 'status',
      align: 'center',
    },
    {
      header: (
        <>
          {' '}
          <p className={classes.columns}>{t('actions')}</p>
          {' '}
        </>),
      accessor: 'action',
      align: 'right',
    },
    ]
    : [
      {
        header: (
          <>
            {' '}
            <p className={classes.columns}>{t('term')}</p>
            {' '}
          </>),
        accessor: 'termNumber',
      },
      {
        header: (
          <>
            {' '}
            <p className={classes.columns}>{t('limit')}</p>
            {' '}
          </>),
        accessor: 'limit',
      },
      {
        header: (
          <>
            {' '}
            <p className={classes.columns}>{t('IM')}</p>
            {' '}
          </>),
        accessor: 'imPercent',
      },
      {
        header: (
          <>
            {' '}
            <p className={classes.columns}>{t('VM')}</p>
            {' '}
          </>),
        accessor: 'vm',
      },
      {
        header: (
          <>
            {' '}
            <p className={classes.columns}>{t('tennor_months')}</p>
            {' '}
          </>),
        accessor: 'tenor',
      },
      {
        header: (
          <>
            {' '}
            <p className={classes.columns}>{t('bespoke_features')}</p>
            {' '}
          </>),
        accessor: 'bespokeFeatures',
        align: 'center',
      },
      {
        header: (
          <>
            <APTooltip
              placement="bottom"
              arrow
              title={
                (
                  <>
                    <div style={{ width: '115px' }}>
                      <div className={classes.toolTip}>
                        <div style={{ marginTop: '2px' }}>
                          <FontAwesomeIcon icon={faExclamationCircle as IconProp} color="#55B171" />
                        </div>
                        <div style={{ marginTop: '2px' }} className={classes.labelContainer}>
                          <Typography className={classes.labels}>{t('positive_mtm')}</Typography>
                        </div>
                      </div>
                      <div className={classes.toolTip}>
                        <div>
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                            color="#FFC107"
                            style={{
                              marginLeft: '3px',
                              marginTop: '4px',
                            }}
                          />
                        </div>
                        <div style={{ marginTop: '2px' }} className={classes.labelContainer}>
                          <Typography className={classes.labels}>
                            {t('negative_mtm')}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.toolTip}>
                        <div style={{ marginTop: '2px' }}>
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                            color="#D55E5E"
                          />
                        </div>
                        <div className={classes.labelContainer}>
                          <Typography style={{ marginRight: '2px', marginTop: '2px' }} className={classes.labels}>
                            {t('breached_vm')}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.toolTip}>
                        <div style={{ marginTop: '2px' }}>
                          <FontAwesomeIcon
                            icon={faExclamationCircle as IconProp}
                            color="gray"
                          />
                        </div>
                        <div className={classes.labelContainer}>
                          <Typography style={{ marginRight: '2px', marginTop: '2px' }} className={classes.labels}>
                            {t('pending_mtm')}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            >
              <p className={classes.columns}>
                {' '}
                {t('mtm_status')}
                {' '}
                <FontAwesomeIcon style={{ verticalAlign: '-2px' }} icon={faInfoCircle as IconProp} />
              </p>
            </APTooltip>
            {' '}
          </>),
        accessor: 'marginStatus',
      },
      {
        header: (
          <>
            {' '}
            <p className={classes.columns}>{t('mtm_valuation')}</p>
            {' '}
          </>),
        accessor: 'mtmValuation',
        align: 'center',
      },
      {
        header: (
          <>
            {' '}
            <p className={classes.columns}>{t('status')}</p>
            {' '}
          </>),
        accessor: 'status',
        align: 'center',
      },
      {
        header: (
          <>
            {' '}
            <p className={classes.columns}>{t('actions')}</p>
            {' '}
          </>),
        accessor: 'action',
        align: 'right',
      },
    ];

  return (
    <Box
      className={[classes.widget, classes.creditWidget].join(' ')}
      style={{
        gridColumn: `span ${span}`,
        gridAutoColumns: 'auto',
        minWidth: '390px',
      }}
      display="flex"
      flexDirection="column"
    >
      <Box className={classes.termsContainer}>
        <Typography className={classes.title}>{t('facility_terms')}</Typography>
        {isDashboardWidget
          && (
            <ActionButton
              style={{ background: '#F7F7F7', color: '#212529' }}
              onClick={() => {
                logEvent({ action: 'Clicked on View all on facility terms table' });
                browserHistory.push('/app/credit-facility', { from: '/dashboard' });
              }}
            >
              {t('view_all')}
            </ActionButton>
          )}
      </Box>
      <Typography className={classes.tableSubHeading}>
        {t('facility_terms_with_data')}
      </Typography>
      <div className={classes.tableWrapper}>
        {loading && <Loader testId="terms-loading" />}
        {!loading && (currentData.length > 0
          ? (
            <StyledGenericTable
              testId="facility-terms-table"
              columns={columns}
              data={currentData}
            />
          )
          : (
            <EmptyTable
              title={t('no_facility_terms')}
              subtitle={t('you_currently_do_not_have_any_facility_terms')}
              className={classes.emptyTable}
              icon={clipboardCross}
              style={{ transform: 'none', position: 'unset' }}
            />
          )
        )}
        {!loading && (
          <div className={classes.listingContainer}>
            {creditFacility?.facilityTerms?.length > 0 ? (
              <Box display="block" textAlign="left" fontSize="13px" fontWeight={300}>
                {t('showing')}
                {' '}
                {creditFacility?.facilityTerms?.length < currentData.length ? creditFacility?.facilityTerms?.length : currentData.length}
                {' '}
                {t('of')}
                {' '}
                {creditFacility?.facilityTerms?.length}
              </Box>
            ) : null}

            <Box display="block" textAlign="right">
              <APPagination
                handleNext={() => handleNextClick()}
                hasNext={Boolean(creditFacility?.facilityTerms?.length > index + 3)}
                handlePrevious={() => handlePrevClick()}
                hasPrevious={Boolean(index > 0)}
                type="BOTH"
                prevString={t('prev^')}
                nextString={t('next^')}
              />
            </Box>
          </div>
        )}
      </div>
    </Box>
  );
};

export default FacilityTermsTable;
