import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      backgroundColor: 'rgba(0,0,0,0.9)',
      position: 'relative',
      '& .MuiCircularProgress-root': {
        color: 'white',
      },
    },
    wrapper: {
      position: 'absolute',
      width: '627px',
      maxWidth: '80%',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '48%',
      color: 'rgba(255,255,255,0.7)',
      '& button': {
        width: '100%',
      },
      outline: 'none',
    },
    mainTitle: {
      fontSize: '22px',
      marginBottom: '25px',
    },
    step: {
      width: '100%',
      marginBottom: '42px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      color: 'rgba(255,255,255,0.7)',
    },
    downloadContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    phoneBackground: {
      backgroundColor: 'rgba(255,255,255,0.03)',
      height: '47px',
      width: '406px',
      color: 'rgba(255,255,255,0.7)',
      marginTop: '16px',
      fontWeight: 'bold',
      fontSize: '18px',
    },
    paragraphContainer: {
      padding: '24px',
      fontSize: '15px',
    },
    topPara: {
      lineHeight: '23.8px',
      marginBottom: '32px',
    },
    lowerPara: {
      lineHeight: '23.8px',
    },
    title: {
      fontSize: '18px',
      color: 'white',
      fontWeight: 300,
      marginBottom: '8px',
    },
    body: {
      fontSize: '14px',
      color: 'rgba(255,255,255,0.7)',
      fontWeight: 300,
      marginBottom: '8px',
      position: 'relative',
    },
    iconBadgeContainer: {
      position: 'relative',
      right: '0',
      top: '0',
      width: '57px',
      height: '57px',
      '& img': {
        position: 'relative',
        width: '30px',
        height: '30px',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
      },
    },
    icon: {
      margin: '0 12px 0 0',
      color: '#FFFFFF',
      height: '14px',
      width: '8px',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    appImages: {
      marginTop: '8px',
    },
    authyPhoneImage: {},
  }),
  { name: 'AuthBackupMethodCard' },
);

export default useStyles;
