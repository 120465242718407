import React from 'react';
import { NavLink } from 'react-router-dom';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APInput } from '@alpha/ui-lib/ui/Input/APInput';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import t from 'utils/translationHelper';

import FormTitle from '../../../../components/Molecules/Auth/FormTitle';
import usePasswordReset from '../../../../hooks/usePasswordReset';
import routes from '../../../../routes.path';
import useFormStyles from '../../form.styles';
import LangSelectorGroup from '../../LangSelectorGroup';
interface IProps {
  setUsername: React.Dispatch<React.SetStateAction<string | undefined>>
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Request: React.FC<IProps> = ({ setUsername, setEmail }: IProps) => {
  const { sendEmailFormik, loading } = usePasswordReset(undefined, setUsername, setEmail);
  const formClasses = useFormStyles();


  return (
    <div>
      <FormTitle
        title={t(('password_reset'))}
        description={t('to_reset_your_password_please_enter_your_username_below')}
      />
      <form onSubmit={sendEmailFormik.handleSubmit}>
        <div className={formClasses.section}>
          <Typography className={formClasses.label}>{t('username')}</Typography>
          <APInput
            autoComplete="off"
            testid="username"
            id="username"
            name="username"
            placeholder={t('enter_your_username')}
            variant="filled"
            type="text"
            onChange={sendEmailFormik.handleChange}
            value={sendEmailFormik.values.username}
            error={sendEmailFormik.touched.username && Boolean(sendEmailFormik.errors.username)}
            fullWidth
            helperText={sendEmailFormik.touched.username && sendEmailFormik.errors.username}
          />
        </div>
        <ActionButton
          size="large"
          type="submit"
          testId="send-password-reset-email"
          fullWidth
          loading={loading}
          disabled={loading}
        >
          {t('send_code')}
        </ActionButton>
        <NavLink
          to={routes.auth.login}
          className={formClasses.formBacklink}
        >
          {t('back_to_sign_in')}
        </NavLink>
      </form>
      <LangSelectorGroup />
    </div>
  );
};

export default Request;
