import { MfaMethod } from '@alpha/profile-dtos';

export enum ChallengeName {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  SMS_MFA = 'SMS_MFA',
  MFA_SETUP = 'MFA_SETUP',
  CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
}

type TChallengeParam = {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;
  CODE_DELIVERY_DESTINATION: string;
  CODE_DELIVERY_ERROR?: string;
  CHALLENGE_STEP?: 'METHOD_REQUEST' | 'CODE_REQUEST' | 'CODE_ENTRY';
  PREFERRED_METHOD?: MfaMethod;
};

export type TSignIn = {
  challengeName: ChallengeName;
  challengeParam: TChallengeParam;
  session: string;
  username: string;
};

export type TSendTotp = {
  message: string,
  method: string,
}

export enum AuditAmendmentType {
  CHANGE_PASSWORD = 'Password changed',
  EDIT_OWN_ROLES = 'Role changed',
  EDIT_OTHER_USER_ROLES = 'Role changed',
  EDIT_OWN_EMAIL_SETTINGS = 'Email settings changed',
  EDIT_OTHER_USER_EMAIL_SETTINGS = 'Email settings changed',
  ADD_USER = 'User added',
  REQUEST_NEW_CURRENCY_ACCOUNT = 'New Currency account',
  DELETE_ACCOUNT_USER = 'User deleted',
  CHANGE_MFA_PREFERENCE = 'Preferred authentication method changed',
}

export const AuditAmendments = {
  CHANGE_PASSWORD: AuditAmendmentType.CHANGE_PASSWORD,
  EDIT_OWN_ROLES: AuditAmendmentType.EDIT_OWN_ROLES,
  EDIT_OTHER_USER_ROLES: AuditAmendmentType.EDIT_OTHER_USER_ROLES,
  EDIT_OWN_EMAIL_SETTINGS: AuditAmendmentType.EDIT_OWN_EMAIL_SETTINGS,
  EDIT_OTHER_USER_EMAIL_SETTINGS: AuditAmendmentType.EDIT_OTHER_USER_EMAIL_SETTINGS,
  ADD_USER: AuditAmendmentType.ADD_USER,
  REQUEST_NEW_CURRENCY_ACCOUNT: AuditAmendmentType.REQUEST_NEW_CURRENCY_ACCOUNT,
  DELETE_ACCOUNT_USER: AuditAmendmentType.DELETE_ACCOUNT_USER,
  CHANGE_MFA_PREFERENCE: AuditAmendmentType.CHANGE_MFA_PREFERENCE,
};

export type TAuditAccount = {
  actionType: string,
  fullName: string,
  logDate: string,
}
