import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  dropdownContainer: {
    position: 'relative',
  },
  dropdown: {
    '& .MuiSelect-root': {
      backgroundColor: '#f7f7f7',
      height: '44px',
      borderRadius: '5px',
      padding: '0 16px',
      paddingRight: '40px',
      marginBottom: '6px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      right: '7px',
    },
  },
  dropdownList: {
    '& .MuiList-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
      '& > li:first-of-type': { // Using first of type here as the placeholder will always be positioned as the first item
        display: 'none',
      },
      '& > *': {
        height: '49px',
      },
    },
  },
  placeholder: {
    color: '#34343473',
    fontWeight: 400,
  },
}), { name: 'PurposePaymentDropdown' });
export default useStyles;
