import { makeStyles } from '@alpha/ui-lib/ui/external';

const useStyles = makeStyles({
  contentWrapper: {
    paddingTop: '12px',
    backgroundColor: '#fafafa',
    minHeight: 'calc(100vh - 310px)',
  },
  status: {
    textAlign: 'center',
    display: 'block',
  },
  statusContainer: {
    marginLeft: '16px',
    paddingLeft: '20px',
    borderLeft: '#e6e6e6 1px solid',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  mainContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    borderRadius: '10px',
  },
  closeOutsContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    marginTop: '20px',
    marginBottom: '20px',
  },
});

export default useStyles;
