import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0',
    margin: '0',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  description: {
    width: '450px',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  item: {
    paddingLeft: '26px',
    paddingRight: '24px',
  },
  sticky: {
    paddingLeft: '34px',
    backgroundColor: '#F8F8F8',
    '& h6': {
      color: '#A0A0A0',
    },
  },
}), { name: 'PaymentPurposeDropdownItem' });
export default useStyles;
