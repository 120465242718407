import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    ctas: {
      '& > div:nth-of-type(2)': {
        display: 'flex',
        padding: '36.5px 53px 35px',
        '& > button': {
          width: '100%',
          '&:first-of-type': {
            margin: '0 8px 0 0',
          },
        },
      },
    },
  }),
  { name: 'BeneInfoDrawer' },
);

export default useStyles;
