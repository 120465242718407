import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'right',
      marginLeft: 15,
    },
  }),
  { name: 'UploadHistoryActionDropdown' },
);

export default useStyles;
