import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import routes from '../../../../../routes.path';
import history from '../../../../../services/history/browserHistory';
import { TStore } from '../../../../../store';
import { initiatePayment } from '../../../../../store/authy/actions';
import BatchSubmittedFooter from '../BatchSubmittedFooter/BatchSubmittedFooter';
import t from 'utils/translationHelper';

import ApproveRejectFooter from './ApproveRejectFooter/ApproveRejectFooter';

interface IProps {
  batchId: string;
  batchName: string;
  canApprove: boolean;
  requiresFx: boolean;
  isFinalApprover: boolean;
  uploadedById?: string;
}

const ApproveRejectContainer: React.FC<IProps> = (props: IProps) => {
  const {
    batchId,
    batchName,
    canApprove,
    requiresFx,
    isFinalApprover,
    uploadedById,
  } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const sb = useAlphaSnackbar();

  const dispatch = useDispatch();
  const userId = useSelector<TStore, string | undefined>(
    (store) => store.user.user?.Username,
  );

  const buttonTextFunc = (): string => {
    if (isFinalApprover && canApprove) {
      if (requiresFx) {
        return 'Approve Batch and Book Fx';
      }
      return 'Approve Batch and Send Payments';
    }
    return 'Approve Batch';
  };

  const handleRejectBatchButton = () => setModalOpen(true);

  const handleRejectPayment = async () => {
    try {
      // await PaymentsService.postRejectPayment(batchId, userId, uploadedById);
      sb.trigger(t('successfully_rejected_your_payment'), 'info');
      history.push(routes.transactions.base);
    } catch {
      sb.trigger(
        t('something_went_wrong_rejecting_your_payment_please_try_again'),
      );
    }
  };

  const handleApprovePayment = async () => {
    dispatch(
      initiatePayment({
        paymentIds: ['hi'],
        type: 'PAYMENT_APPROVE',
        approverOwn: Boolean(uploadedById === userId),
      }),
    );
  };

  if (canApprove) {
    return (
      <ApproveRejectFooter
        buttonText={buttonTextFunc()}
        batchName={batchName}
        modalOpen={modalOpen}
        handleClose={() => (modalOpen ? setModalOpen(false) : null)}
        handleRejectBatchButton={handleRejectBatchButton}
        handleApprovePayment={handleApprovePayment}
        handleRejectPayment={handleRejectPayment}
      />
    );
  }
  return <BatchSubmittedFooter />;
};

export default ApproveRejectContainer;
