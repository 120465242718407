import React from 'react';
import clsx from 'clsx';

import { IconButton } from '@alpha/ui-lib/ui/button/IconButton';
import {
  Box, Fade, Modal, ModalProps,
} from '@alpha/ui-lib/ui/external';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './APModal.styles';

interface IContainerProps extends ModalProps {}
const Container: React.FC<IContainerProps> = ({
  open,
  onClose,
  children,
  className,
  ...rest
}: IContainerProps) => {
  const styles = useStyles();
  return (
    <Modal
      {...rest}
      open={open}
      onClose={onClose}
      className={styles.modal}
    >
      <Fade in={open}>
        <Box className={clsx(className, styles.paper)}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};
interface IWrapperProps {
    children: JSX.Element | JSX. Element[];
    className?: string | undefined;
    style?: React.CSSProperties;
}
const Wrapper: React.FC<IWrapperProps> = ({
  children,
  className,
  style,
}: IWrapperProps) => (
  <Box padding="0 56px" className={className} style={style}>
    { children }
  </Box>
);

interface IHeaderProps {
  children: JSX.Element | JSX. Element[];
  onClose?: () => void;
  className?: string | undefined;
  style?: React.CSSProperties;
  showCloseButton?: boolean;
}
const Header: React.FC<IHeaderProps> = ({
  children,
  onClose,
  className,
  style,
  showCloseButton = true,
}: IHeaderProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.header}>
      {showCloseButton
       && <IconButton icon={faTimes} onClick={onClose} className={styles.closeButton} />}
      <Box className={className} style={style}>
        {children}
      </Box>
    </Box>
  );
};

const APModal = {
  Container,
  Wrapper,
  Header,
};

export default APModal;
