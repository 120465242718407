import SearchTable from 'components/Organisms/SearchTable';
import { TSearchBaseResponse, TSearchParams } from 'hooks/useSearch';
import { TBeneficiaryApproved, TBeneficiaryPending } from 'models/beneficiaries';
import React, { useEffect, useState } from 'react';
import { ITableColumn, ITableData } from 'services/DatabaseServices/marker.interface';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';

import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import useBeneficiaryListTable from './useBeneficiaryListTable';

interface IBeneficiaryListTable {
  tableRawData: TSearchBaseResponse | undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  loading: boolean;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | undefined,
  skip: number;
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handleTableRowClick?: (
    e: (TBeneficiaryPending | TBeneficiaryApproved | BeneficiaryDto),
  ) => void;
  handleTableSortClick?: (column: string) => void;
  emptyTitle?: string;
  emptySubTitle?: string;
  tableType: 'approved' | 'pending' | 'rejected' | 'draft' | 'batch';
  handleReviewButton?: (e: (TBeneficiaryPending | TBeneficiaryApproved | BeneficiaryDto)) => void;
  handleEditButton?: (e: (TBeneficiaryPending | TBeneficiaryApproved | BeneficiaryDto)) => void;
  testId?: string;
  selectedIds?: string[];
  setSelectedIds?: React.Dispatch<string[]>;
  setSelectedBeneficiary?: React.Dispatch<React.SetStateAction<{
    beneficiary: TBeneficiaryApproved | TBeneficiaryPending;
    approved: boolean;
  } | undefined>>
  hideSelect?: boolean;
  handleBeneficiaryShareButtonClick?: (_accountBeneId?: string | undefined) => void;
  setDeleteModalOpen?: React.Dispatch<React.SetStateAction<false | 'reject' | 'delete'>>;
  handleApprovedEditButtonClick?: (clickedBeneficiary: BeneficiaryDto) => void,
}

const BeneficiaryListTable: React.FC<IBeneficiaryListTable> = (props: IBeneficiaryListTable) => {
  const {
    tableRawData,
    hasPrevious,
    hasNext,
    loading,
    sortOrder,
    sortBy,
    handleTableRowClick,
    handleNextPage,
    handlePreviousPage,
    handleTableSortClick,
    tableType,
    emptyTitle,
    emptySubTitle,
    setSelectedIds,
    selectedIds,
    handleReviewButton,
    handleEditButton,
    testId,
    skip,
    setSelectedBeneficiary,
    hideSelect,
    handleBeneficiaryShareButtonClick,
    setDeleteModalOpen,
    handleApprovedEditButtonClick
  } = props;

  const { logError, logEvent } = useLog();
  const sb = useAlphaSnackbar();
  const getAvailablePaymentMethods = async (
    countryCurrency: { countryCode: string, currencyCode: string }[],
  ) => {
    try {
      const response = await BeneficiariesService.getCountryRequirements(countryCurrency);
      return response;
    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || t('there_was_an_error_retrieving_the_available_payment'));
      logError({ action: 'Error retrieving the available payment methods for the selected bank country', error: e });
    }
  };

  const [isLoading, setIsLoading] = useState(false)
  const [requiresAddress, setRequiresAddress] = useState<boolean[]>()
  const [tableData, setTableData] = useState<ITableData[]>([])

  const getData = async () => {
    if (tableRawData) {
      setIsLoading(true)
      const countriesData = await getAvailablePaymentMethods(
        (tableRawData?.records as unknown as BeneficiaryDto[])?.map((r: BeneficiaryDto) => (
          { countryCode: r.bankCountryCode, currencyCode: r.currencyCode }
        ))
      )
      setRequiresAddress(countriesData?.map((r) => r.requiresAddress));
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData();
  }, [tableRawData])

  const {
    displayColumns,
    generateTableData,
  } = useBeneficiaryListTable(
    tableType,
    handleReviewButton,
    handleEditButton,
    handleBeneficiaryShareButtonClick,
    setDeleteModalOpen,
    setSelectedBeneficiary,
    handleApprovedEditButtonClick,
    requiresAddress
  );

  const generateData = async () => {
    const tableData = await generateTableData(tableRawData);
    setTableData(tableData)
  }

  useEffect(() => {
    if (requiresAddress && tableRawData) {
      generateData()
    }
  }, [requiresAddress])

  return (
    <SearchTable
      table={{
        columns: displayColumns() as ITableColumn[],
        data: tableData,
        clickable: true,
        handleTableRowClick,
        handleTableSortClick,
        activeColumn: sortBy,
        sortOrder,
        dataTestId: testId || '',
        keyColumn: 'id',
        totalRowNumber: tableRawData?.total,
        setSelectedIds,
        selectedIds,
        skip,
        multiSelectable: tableType === 'pending',
        showSelectAllCheckbox: false,
        hideSelect,
      }}
      loading={loading || isLoading}
      pagination={{
        handleNext: handleNextPage as any,
        handlePrevious: handlePreviousPage as any,
        hasNext,
        hasPrevious,
      }}
      emptyTable={{
        title: emptyTitle || t('no_pending_beneficiaries'),
        subtitle: emptySubTitle || t('you_have_no_pending_beneficiaries'),
      }}
    />
  );
};

export default BeneficiaryListTable;
