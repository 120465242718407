import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 8px !important',
    width: '652px',
  },
  modalTitle: {
    fontWeight: 800,
    marginBottom: '16px',
    fontSize: '16px',
  },
  modalContent: {
    height: '100px',
    paddingTop: '20px',
  },
  buttonContainer: {
    width: '100%',
    height: '49.04px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
  },
  revokeBtn: {
    background: '#D55E5E',
    borderRadius: '4px',
  },
  cancelBtn: {
    background: '#FAFAFA',
    color: '#212529',
  },
  userInfo: {
    color: '#1E8777',
    display: 'inline',
  },
}), { name: 'DisableAccessWarningModal' });

export default useStyles;
