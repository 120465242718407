import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    rightAlignedCtas: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    cancelIcon: {
      color: '#333333',
      opacity: '50%',
    },
    submitButtonContainer: {
      marginLeft: '8px',
      '& .MuiButton-root': {
        borderRadius: '5px',
        height: '40px',
        width: '222px',
        fontFamily: typography.fontFamily,
        fontSize: '12px',
        letterSpacing: '-1px !important',
      },
    },
  }),
  { name: 'RightAlignedCtas' },
);

export default useStyles;
