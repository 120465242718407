import { useEffect, useState } from 'react';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import { startCase } from 'lodash';
import moment from 'moment';
import t from 'utils/translationHelper';
import IATService from 'services/IAT/IAT.service';
import mapFxTradeStatusDisplay from 'utils/fxTrades/mapFxTradeStatusDisplay';
import mapTransferStatusToDisplay from 'utils/transfers/mapTransferStatusToDisplay';

import { CurrencyAccountTransferStatus } from '@alpha/currency-accounts-dtos';
import { TDateRangePickerValue } from '@alpha/ui-lib/ui/DatePicker';
import { IAutocompleteSelectMultiOption } from '@alpha/ui-lib/ui/Select/MultiAutoCompleteSelect';

export interface ITransferTableFilters {
    debitingCurrencies: string[],
    fundingCurrencies: string[],
    transferStatuses: CurrencyAccountTransferStatus[],
    transferDateStart: string | undefined,
    transferDateEnd: string | undefined,
    setDebitingCurrencies: React.Dispatch<string[]>;
    setFundingCurrencies: React.Dispatch<string[]>;
    setTransferStatuses: React.Dispatch<CurrencyAccountTransferStatus[]>;
    setTransferDateStart: React.Dispatch<string | undefined>;
    setTransferDateEnd: React.Dispatch<string | undefined>;
    setDisableFilterBackdropClick: React.Dispatch<boolean>;
}

const useTransferFilter = ({
  debitingCurrencies,
  fundingCurrencies,
  transferStatuses,
  transferDateStart,
  transferDateEnd,
  setDebitingCurrencies,
  setFundingCurrencies,
  setTransferStatuses,
  setTransferDateStart,
  setTransferDateEnd,
  setDisableFilterBackdropClick,
}: ITransferTableFilters) => {
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();
  

  // Values that user selected
  const [debitingCurrenciesFilter,
    setDebitingCurrenciesFilter] = useState<IAutocompleteSelectMultiOption[]>();
  const [fundingCurrenciesFilter,
    setFundingCurrenciesFilter] = useState<IAutocompleteSelectMultiOption[]>();
  const [transferStatusFilter,
    setTransferStatusFilter] = useState<IAutocompleteSelectMultiOption[]>();
  const [valueDateFilter, setValueDateFilter] = useState<TDateRangePickerValue>();

  // Options available
  const [debitingCurrenciesFilterOptions, setDebitingCurrenciesFilterOptions] = useState<string[]>([]);
  const [fundingCurrenciesFilterOptions, setFundingCurrenciesFilterOptions] = useState<string[]>([]);
  const [transferStatusFilterOptions, setTransferStatusFilterOptions] = useState<CurrencyAccountTransferStatus[]>([]);

  const clearAllFilters = () => {
    setDebitingCurrenciesFilter([]);
    setFundingCurrenciesFilter([]);
    setTransferStatusFilter([]);
    setTransferDateStart('');
    setTransferDateEnd('');
    setValueDateFilter(undefined);
  };

  const valueDateChanged = (fieldName: string, value: TDateRangePickerValue) => {
    setDisableFilterBackdropClick(false);
    setValueDateFilter(value);
  };

  const applyChanges = () => {
    setDebitingCurrencies(debitingCurrenciesFilter?.map((filter) => filter.value as string) || []);
    setFundingCurrencies(fundingCurrenciesFilter?.map((filter) => filter.value as string) || []);
    setTransferStatuses(transferStatusFilter?.map((filter) => filter.value as CurrencyAccountTransferStatus) || []);

    if (valueDateFilter) {
      setTransferDateStart(valueDateFilter[0]?.format('YYYY-MM-DD'));
      setTransferDateEnd(valueDateFilter[1]?.format('YYYY-MM-DD'));
    }
  };

  const mapCurrencyFilterOptions = (options: string[]) => options.map((option) => (
    { value: option, label: option }
  ));

  const mapTransferStatusFilterOptions = (options: CurrencyAccountTransferStatus[]) => options.map((option) => (
    {
      value: option,
      label: t(startCase(mapTransferStatusToDisplay(option).text.toLowerCase())),
    }
  ));

  const updateFilterOptions = async () => {
    try {
      const filterOptions = await IATService.getTransferFilterOptions();
      if (filterOptions.debitingCurrencies) {
        setDebitingCurrenciesFilterOptions(filterOptions.debitingCurrencies);
      }
      if (filterOptions.fundingCurrencies) {
        setFundingCurrenciesFilterOptions(filterOptions.fundingCurrencies);
      }
      if (filterOptions.statuses) {
        setTransferStatusFilterOptions(filterOptions.statuses);
      }
    } catch (e) {
      sb.trigger(e?.message || 'Could not load transfer filter options.');
      logError({ action: 'Error loading transfer filter options', error: e });
    }
  };

  useEffect(() => {
    updateFilterOptions();
  }, []);

  useEffect(() => {
    setDebitingCurrenciesFilter(mapCurrencyFilterOptions(debitingCurrencies));
  }, [
    debitingCurrencies,
  ]);

  useEffect(() => {
    setFundingCurrenciesFilter(mapCurrencyFilterOptions(fundingCurrencies));
  }, [
    fundingCurrencies,
  ]);

  useEffect(() => {
    setTransferStatusFilter(mapTransferStatusFilterOptions(transferStatuses));
  }, [transferStatuses]);

  useEffect(() => {
    const startDate = moment(transferDateStart, 'YYYY-MM-DD');
    const endDate = moment(transferDateEnd, 'YYYY-MM-DD');
    setValueDateFilter([
      startDate.isValid() ? startDate : null,
      endDate.isValid() ? endDate : null]);
  }, [transferDateEnd, transferDateStart]);

  return {
    open,
    setOpen,
    clearAllFilters,
    applyChanges,
    mapCurrencyFilterOptions,
    mapTransferStatusFilterOptions,
    debitingCurrenciesFilterOptions,
    fundingCurrenciesFilterOptions,
    transferStatusFilterOptions,
    debitingCurrenciesFilter,
    setDebitingCurrenciesFilter,
    fundingCurrenciesFilter,
    setFundingCurrenciesFilter,
    transferStatusFilter,
    setTransferStatusFilter,
    valueDateFilter,
    setValueDateFilter,
    valueDateChanged,
  };
};

export default useTransferFilter;
