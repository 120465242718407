/* eslint-disable max-lines-per-function */
import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      padding: '28px 1.125% 28px 28px',
      backgroundColor: '#FFFFFF',
      marginBottom: '12px',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
      borderRadius: '10px',
    },
    drawerContainer: {
      padding: '22px',
      marginBottom: '12px',
      border: '1px solid rgba(112, 112, 112, .1)',
      borderRadius: '5px',
      width: 'calc(100% - 30px)',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
    },
    drawerTitle: {
      display: 'flex',
      flexDirection: 'column',
    },
    greyIcon: {
      color: '#333333',
      height: '21px',
      width: '21px',
      lineHeight: '21px',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 600,
      borderRadius: '5px',
    },
    greyTitle: {
      marginLeft: '9px',
      fontSize: '16px',
      color: '#363636',
      fontWeight: 600,
      lineHeight: '21px',
    },
    greySubtitle: {
      color: '#999999',
      fontSize: '13px',
      fontWeight: 400,
      marginTop: '9px',
    },
    titleAndButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    authoriseBtnContainer: {
      justifyContent: 'center',
      flexDirection: 'column',
      display: 'flex',
      marginLeft: 'auto',
    },
    drawerBtnContainer: {
      justifyContent: 'flex-end',
      flexDirection: 'column',
      display: 'flex',
      marginBottom: '68px',
      marginLeft: '20px',
    },
    authoriseBtn: {
      backgroundColor: '#1E8777',
      color: '#FFFFFF',
      width: '192px',
      marginBottom: '10px',
      fontWeight: 500,
      '&:hover': {
        background: '#1E8777',
        color: '#FFFFFF',
      },
      '&[disabled]': {
        color: '#FFFFFF',
        background: '#ccc',
      },
    },
    titlesContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonText: {
      marginLeft: '6px',
      fontSize: '13px',
    },
    alertContainer: {
      marginTop: '20px',
      display: 'inline-block',
    },
    greenPad: {
      color: '#00A09B',
      height: '21px',
      width: '16px',
      lineHeight: '21px',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 600,
      borderRadius: '5px',
    },
    greenTitle: {
      marginLeft: '9px',
      fontSize: '16px',
      color: '#1E8777',
      fontWeight: 600,
      lineHeight: '21px',
    },
    greenContainer: {
      backgroundColor: 'rgba(9, 131, 117, .05)',
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
      borderRadius: '10px',
    },
    drawerGreenContainer: {
      backgroundColor: 'rgba(9, 131, 117, .05)',
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 'calc(100% - 30px)',
    },
    greenSubtitle: {
      color: '#1E8777',
      fontSize: '13px',
      width: '100%',
    },
    approverContainer: {
      padding: '28px',
      flex: '1 1 30%',
    },
    drawerApproverContainer: {
      borderLeft: '1px solid rgba(112, 112, 112, .1)',
      padding: '22px',
      flex: '1 1 30%',
    },
    flexTitlesContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
      marginTop: '3px',
      minWidth: '500px',
    },
    drawerFlexTitles: {
      display: 'flex',
      flexDirection: 'column',
      padding: '22px',
      flex: '1 1 30%',
    },
    approverGreen: {
      fontSize: '16px',
      color: '#1E8777',
      fontWeight: 400,
      lineHeight: '21px',
    },
    approverInfo: {
      color: '#1E8777',
      fontSize: '13px',
    },
    namePadding: {
      paddingRight: '8px',
    },
    dropdownContainer: {
      width: '540px',
      height: '44px',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    saveBeneficiaryButton: {
      display: 'inline-block',
      height: '44px',
      marginLeft: '8px',
    },
    deleteBtn: {
      backgroundColor: '#A62B23',
      color: '#ffffff',
      width: '188px',
      '&:hover': {
        background: '#A62B23',
        color: '#FFFFFF',
      },
      '&[disabled]': {
        color: '#FFFFFF',
        background: '#ccc',
      },
    },
    removeBtnContainer: {
      justifyContent: 'center',
      flexDirection: 'column',
      display: 'flex',
      padding: '28px 1.125% 28px 28px',
    },
    drawdownInfo: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    dividerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    divider: {
      borderLeft: '0.5px solid #1E8777',
      height: '52px',
    },
    deleteDrawdownLoader: {
      color: 'red',
      width: '100%',
      paddingTop: '33px',
      paddingBottom: '33px',
    },
  }), { name: 'QuickDrawdown' },
);

export default useStyles;
