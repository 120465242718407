/* eslint-disable max-lines-per-function */
import React, { Dispatch, SetStateAction, useState } from 'react';
import mapBeneBatchStatusDisplay from 'utils/benes/mapBeneBatchStatusDisplay';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { BeneficiaryDto } from '@alpha/bene-dtos';
import { Button } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';

import { CurrencyPair, NationalityPair } from '../../../../components/International';
import Authorization from '../../../../hocs/Authorization';
import useAuthorization from '../../../../hooks/useAuthorization';
import { TSearchBaseResponse } from '../../../../hooks/useSearch';
import { TBeneficiaryPending } from '../../../../models/beneficiaries';
import { UserRole } from '../../../../models/user';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import formatIsoDate from '../../../../utils/formatIsoDate';
import NoOfApprovers from '../../../Transactions/Dashboard/FxTradeTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import BeneficiaryActionDropDown from '../BeneficiaryActionDropdown/BeneficiaryActionDropDown';

import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'utils/isEmpty';
import useStyles from './BeneficiaryListTable.styles';

type BeneTableType = 'approved' | 'pending' | 'rejected' | 'draft' | 'batch';

const useBeneficiaryListTable = (tableType: BeneTableType,
  handleReviewButton?: (param: BeneficiaryDto) => void,
  handleEditButton?: (param: BeneficiaryDto) => void,
  handleBeneficiaryShareButtonClick?: (_accountBeneId?: string | undefined) => void,
  setDeleteModalOpen?: React.Dispatch<React.SetStateAction<false | 'reject' | 'delete'>>,
  setSelectedBeneficiary?: Dispatch<SetStateAction<{
    beneficiary: TBeneficiaryPending;
    approved: boolean;
  } | undefined>>,
  handleApprovedEditButtonClick?: (clickedBeneficiary: BeneficiaryDto) => void,
  requiresAddress?: boolean[],
) => {
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);
  const [currentOptions, setCurrentOptions] = useState<Approver[]>([]);
  const [textValue, setTextValue] = useState('');

  const classes = useStyles();
  const { authorized: paymentFileInputter } = useAuthorization([[UserRole.PAYMENT_FILE_INPUTTER]]);

  const commonColumns = [
    { header: t('beneficiary_name'), accessor: 'name', sortable: true },
    { header: t('friendly_name'), accessor: 'friendlyName', sortable: true },
    { header: t('country'), accessor: 'bankCountryCode', sortable: true },
    { header: t('currency'), accessor: 'currencyCode', sortable: true },
    { header: t('bank_details'), accessor: 'bankDetails' },
    { header: t('bank_name'), accessor: 'bankName' },
  ];

  const showAlphaUniqueId = () => {
    if (paymentFileInputter) {
      return [{
        header: t('alpha_unique_id'),
        accessor: 'beneficiaryId',
        sortable: false,
        align: 'right',
      }];
    }

    return [];
  };

  const displayColumns = () => {
    switch (tableType) {
      case 'approved':
        return [
          ...commonColumns,
          {
            header: t('created_date'), accessor: 'uploadedByDate', sortable: true, align: 'right',
          },
          {
            header: t('last_paid_date'), accessor: 'lastPaidDate', sortable: true, align: 'right',
          },
          ...showAlphaUniqueId(),
          { header: t('actions'), accessor: 'action', align: 'center', width: '88px' },
        ] as ITableColumn[];
      case 'pending':
        return [
          ...commonColumns,
          {
            header: t('uploaded_date'), accessor: 'uploadedByDate', align: 'left', sortable: true,
          },
          { header: t('approvers'), accessor: 'approvers', align: 'center' },
          { header: t('actions'), accessor: 'action', align: 'center' },
        ] as ITableColumn[];
      case 'rejected':
        return [
          ...commonColumns,
          {
            header: t('uploaded_date'), accessor: 'uploadedByDate', align: 'right', sortable: true,
          },
          {
            header: t('rejected_date'), accessor: 'rejectedDate', align: 'right',
          },
        ] as ITableColumn[];
      case 'batch':
        return [
          {
            header: t('batch_id'), accessor: 'batchId', align: 'left', sortable: true,
          },
          {
            header: t('name'), accessor: 'name', align: 'left', sortable: true,
          },
          {
            header: t('upload_date'), accessor: 'uploadDate', align: 'left', sortable: true,
          },
          {
            header: t('valid_benes'), accessor: 'validBenes', align: 'center', sortable: true,
          },
          {
            header: t('rejected_benes'), accessor: 'rejectedBenes', align: 'center', sortable: true,
          },
          {
            header: t('status'), accessor: 'status', align: 'left',
          },
          { header: t('actions'), accessor: 'action', align: 'center' },
        ] as ITableColumn[];
      case 'draft':
      default:
        return [
          ...commonColumns,
          { header: t('actions'), accessor: 'resume', align: 'right' },
        ];
    }
  };

  const cadBeneCheck = (bene: BeneficiaryDto, requiresAddress: boolean, data: any, tab: string) => {

    if (tab === 'approved' && requiresAddress && (
      isEmpty(bene.address)
      || isEmpty(bene?.addressLine1) ||
      isEmpty(bene?.countryCode) ||
      isEmpty(bene?.city) ||
      isEmpty(bene?.state) ||
      isEmpty(bene?.zip))) {
      return (<span className={classes.warning}>{data}</span>)
    }
    return data;
  }

  const generateTableData = (tableData: TSearchBaseResponse | undefined) => {
    const getRequiresAddress = (index: number) => {
      if (requiresAddress) {
        return requiresAddress[index]
      }
      return false
    }
    return (
      tableData?.records as BeneficiaryDto[]
    )?.map((beneficiary, index) => {
      const commonTableData = (bene: BeneficiaryDto,) => ({
        ...bene,
        friendlyName: <div style={{ textTransform: 'capitalize' }}>{cadBeneCheck(bene, getRequiresAddress(index), bene.friendlyName || '-', tableType)}</div>,
        bankDetails: <div data-dd-privacy="mask">{cadBeneCheck(bene, getRequiresAddress(index), (bene.iban && bene.iban.toUpperCase()) || bene.accountNumber || '-', tableType)}</div>,
        bankName: <div data-dd-privacy="mask">{cadBeneCheck(bene, getRequiresAddress(index), bene.bankName || '-', tableType)}</div>,
        bankCountryCode:
          (bene.bankCountryCode && (
            <>
              {cadBeneCheck(bene, getRequiresAddress(index), (<NationalityPair countryCode={bene.bankCountryCode} />), tableType)}
            </>
          ))
          || cadBeneCheck(bene, getRequiresAddress(index), '-', tableType),
        currencyCode:
          (bene.currencyCode && (
            <>
              {cadBeneCheck(bene, getRequiresAddress(index), (<CurrencyPair currencyCode={bene.currencyCode} />), tableType)}
            </>
          ))
          || cadBeneCheck(bene, getRequiresAddress(index), '-', tableType),
      });
      switch (tableType) {
        case 'approved':
          return {
            ...commonTableData(beneficiary),
            name: (<>
              {(getRequiresAddress(index) && (isEmpty(beneficiary.address)
                || isEmpty(beneficiary?.address) ||
                isEmpty(beneficiary?.addressLine1) ||
                isEmpty(beneficiary?.countryCode) ||
                isEmpty(beneficiary?.city) ||
                isEmpty(beneficiary?.state) ||
                isEmpty(beneficiary?.zip))) ?
                (
                  <APTooltip
                    title="This Beneficiary Has Missing Data"
                    arrow
                  >
                    <div style={{ width: '14px', display: 'inline-block', marginRight: '8px', color: '#CF752F' }}>
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </div>
                  </APTooltip>
                ) : null}
              <div style={{ textTransform: 'capitalize', display: 'inline-block' }}>{cadBeneCheck(beneficiary, getRequiresAddress(index), beneficiary.name, tableType)}</div>
            </>),
            uploadedByDate: cadBeneCheck(beneficiary, getRequiresAddress(index), (formatIsoDate(beneficiary.uploadedByDate)), tableType),
            lastPaidDate: cadBeneCheck(beneficiary, getRequiresAddress(index), (formatIsoDate(beneficiary.lastPaidDate) || '-'), tableType),
            beneficiaryId: cadBeneCheck(beneficiary, getRequiresAddress(index), beneficiary.id, tableType),
            action: cadBeneCheck(beneficiary, getRequiresAddress(index), (
              <BeneficiaryActionDropDown
                beneficiary={beneficiary}
                displayMultiSelect={displayMultiSelect}
                currentOptions={currentOptions}
                setDisplayMultiSelect={setDisplayMultiSelect}
                setCurrentOptions={setCurrentOptions}
                setTextValue={setTextValue}
                handleReviewButton={handleReviewButton}
                tabTitle={tableType}
                handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
                setDeleteModalOpen={setDeleteModalOpen}
                setSelectedBeneficiary={setSelectedBeneficiary}
                handleApprovedEditButtonClick={handleApprovedEditButtonClick}
              />
            ), tableType),
          };
        case 'pending':
          return {
            ...commonTableData(beneficiary),
            name: (
              <Button
                disableRipple
                className={classes.beneNameButton}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  if (setSelectedBeneficiary) {
                    setSelectedBeneficiary({
                      beneficiary,
                      approved: false,
                    });
                  }
                }}
              >
                {beneficiary.name || '-'}
              </Button>),
            uploadedByDate: formatIsoDate(beneficiary.uploadedByDate),
            approvers: (
              <div className={classes.approvers}>
                <NoOfApprovers
                  uploadedBy={beneficiary.uploadedBy}
                  uploadedDate={beneficiary.uploadedByDate}
                  noOfApprovals={beneficiary.approvalInfo?.approvals?.length || 0}
                  approvedBy={beneficiary.approvalInfo?.approvals || []}
                  approvalsRequired={beneficiary.approvalInfo?.approvalsRequired || 0}
                />
              </div>
            )
              || '-',
            action: (
              <BeneficiaryActionDropDown
                beneficiary={beneficiary}
                displayMultiSelect={displayMultiSelect}
                currentOptions={currentOptions}
                setDisplayMultiSelect={setDisplayMultiSelect}
                setCurrentOptions={setCurrentOptions}
                setTextValue={setTextValue}
                handleReviewButton={handleReviewButton}
                tabTitle={tableType}
              />
            ),
          };
        case 'rejected':
          return {
            ...commonTableData(beneficiary),
            name: <div style={{ textTransform: 'capitalize' }}>{beneficiary.name}</div>,
            rejectedBy: beneficiary.rejectedBy || '-',
            rejectedDate: formatIsoDate(beneficiary.rejectedByDate) || '-',
            uploadedByDate: formatIsoDate(beneficiary.uploadedByDate),
          };
        case 'draft':
          return {
            ...commonTableData(beneficiary),
            name: <div style={{ textTransform: 'capitalize' }}>{beneficiary.name}</div>,
            resume: beneficiary.uploadedById && (
              <Authorization
                requiredRoles={[[UserRole.BENEFICIARY_APPROVER, UserRole.BENEFICAIRY_APPROVER_OWN]]}
                resourceCreatorUserId={beneficiary.uploadedById}
              >
                <Button
                  disableRipple
                  className={classes.button}
                  onClick={() => handleEditButton && handleEditButton(beneficiary)}
                >
                  {t('resume')}
                </Button>
              </Authorization>
            ),
          };
        case 'batch':
          return {
            ...commonTableData(beneficiary),
            name: <div style={{ textTransform: 'capitalize' }}>{beneficiary.name}</div>,
            status: (<Status className={classes.status} variant={mapBeneBatchStatusDisplay(beneficiary.status).variant}>
              {t(mapBeneBatchStatusDisplay(beneficiary.status).text) || '-'}
            </Status>),
            action: (
              <BeneficiaryActionDropDown
                beneficiary={beneficiary}
                displayMultiSelect={false}
                currentOptions={currentOptions}
                setDisplayMultiSelect={setDisplayMultiSelect}
                setCurrentOptions={setCurrentOptions}
                setTextValue={setTextValue}
                handleReviewButton={handleReviewButton}
                tabTitle={tableType}
              />
            ),
          };
        default:
          return { ...beneficiary };
      }
    })
  };
  return {
    displayColumns,
    generateTableData,
  };
};
export default useBeneficiaryListTable;
