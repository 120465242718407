import React from 'react';
/* Thumbnails */
import amendmentRequestThumbnail from 'assets/video_thumbnails/user-guides-amendment-request-thumbnail.png';
import beneficiaryInternetionalThumbnail from 'assets/video_thumbnails/user-guides-beneficiary-international-thumbnail.png';
import beneficiaryLocalThumbnail from 'assets/video_thumbnails/user-guides-beneficiary-local-thumbnail.png';
import dashboardThumbnail from 'assets/video_thumbnails/user-guides-dashboard.png';
import drawdownThumbnail from 'assets/video_thumbnails/user-guides-drawdown-thumbnail.png';
import creditLayoutThumbnail from 'assets/video_thumbnails/user-guides-layout-thumbnail.png';
import loginPrefThumbnail from 'assets/video_thumbnails/user-guides-login-preference.png';
import paymentsThumbnail from 'assets/video_thumbnails/user-guides-payments-thumbnail.png';
import spotThumbnail from 'assets/video_thumbnails/user-guides-spot-thumbnail.png';
import statementsThumbnail from 'assets/video_thumbnails/user-guides-statements-thumbnail.png';
import termsThumbnail from 'assets/video_thumbnails/user-guides-terms-thumbnail.png';
/* Captions */
import dashboardTrack from 'assets/video_tracks/dashboard.vtt';
import loginPrefTrack from 'assets/video_tracks/log-in-preference.vtt';
import t from 'utils/translationHelper';

import { Box, Typography } from '@alpha/ui-lib/ui/external';

import GuideVideo from './GuideVideo';
import useStyles from './index.styles';

const UserGuides: React.FC = () => {

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className="wrapper">
        <Box className={styles.headerContainer}>
          <Typography className={styles.header}>
            {t('welcome_to_alpha')}
          </Typography>
          <hr className={styles.divider} />
          <Typography className={styles.subHeader}>
            {t('welcome_to_alpha_user_guides')}
            <br />
            <br />
            {t('to_navigate_to_another_area_click_menu')}
          </Typography>
        </Box>
        <div className={styles.gridContainer}>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="Credit"
              url="https://userguides.rmportal.alpha.co.uk/Online_Credit_Layout.mp4"
              poster={creditLayoutThumbnail}
            />
            <Typography className={styles.videoTitle}>
              {t('credit_facility_page')}
            </Typography>
          </Box>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="Credit"
              url="https://userguides.rmportal.alpha.co.uk/Online_Amendment_Request.mp4"
              poster={amendmentRequestThumbnail}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_do_an_amendment_request')}
            </Typography>
          </Box>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="Credit"
              url="https://userguides.rmportal.alpha.co.uk/Online_Downloading_Terms.mp4"
              poster={termsThumbnail}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_download_a_term_letter')}
            </Typography>
          </Box>
        </div>
        <div className={styles.gridContainer}>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="Dashboard"
              url="https://userguides.rmportal.alpha.co.uk/Dashboard.mp4"
              poster={dashboardThumbnail}
              trackUrl={dashboardTrack}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_set_up_your_home_screen')}
            </Typography>
          </Box>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="Login Preference"
              url="https://userguides.rmportal.alpha.co.uk/mfa-preference.mp4"
              poster={loginPrefThumbnail}
              trackUrl={loginPrefTrack}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_set_up_your_login_mfa_preference')}
            </Typography>
          </Box>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="Beneficiary - local"
              url="https://userguides.rmportal.alpha.co.uk/Beneficiaries+-+Local+details.mp4"
              poster={beneficiaryLocalThumbnail}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_add_a_beneficiary_with_local_details')}
            </Typography>
          </Box>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="Beneficiary - international"
              url="https://userguides.rmportal.alpha.co.uk/Beneficiaries+-+International+details.mp4"
              poster={beneficiaryInternetionalThumbnail}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_add_a_beneficiary_with_internation_details')}
            </Typography>
          </Box>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="New drawdown"
              url="https://userguides.rmportal.alpha.co.uk/Drawdown.mp4"
              poster={drawdownThumbnail}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_create_a_new_drawdown')}
            </Typography>
          </Box>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="New payments"
              url="https://userguides.rmportal.alpha.co.uk/Payment.mp4"
              poster={paymentsThumbnail}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_create_a_new_payment')}
            </Typography>
          </Box>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="New spot"
              url="https://userguides.rmportal.alpha.co.uk/Spot.mp4"
              poster={spotThumbnail}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_book_a_new_spot_trade')}
            </Typography>
          </Box>
          <Box className={styles.videoContainer}>
            <GuideVideo
              name="View statements"
              url="https://userguides.rmportal.alpha.co.uk/Statements.mp4"
              poster={statementsThumbnail}
            />
            <Typography className={styles.videoTitle}>
              {t('how_to_view_statements')}
            </Typography>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default UserGuides;
