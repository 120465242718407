import React, { Dispatch, useState } from 'react';
import { FormikProps } from 'formik';
import t from 'utils/translationHelper';
import { useQuery } from 'react-query';

import { CurrencyAccountRequest, CurrencyDto } from '@alpha/currency-accounts-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Input } from '@alpha/ui-lib/ui/Input/Input';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import CurrencyAccountsService from '../../../services/CurrencyAccounts/currencyAccounts.service';
import AutocompleteDropDown, { IOption } from '../../AutocompleteDropDown/AutocompleteDropDown';
import APModal from '../APModal/APModal';

import useStyles from './RequestCurrencyAccountModal.styles';

type Props = {
  open: boolean;
  handleClose: () => void;
  currencyAccountForm: FormikProps<CurrencyAccountRequest>;
  submittingAccountRequest: boolean;
  accountCurrencyOption: IOption;
  setAccountCurrencyOption: Dispatch<React.SetStateAction<IOption>>
}

const RequestCurrencyAccountModal: React.FC<Props> = (props: Props) => {
  const {
    open,
    handleClose,
    currencyAccountForm,
    submittingAccountRequest,
    accountCurrencyOption,
    setAccountCurrencyOption,
  } = props;
  const [accountCurrencyTextFieldValue, setAccountCurrencyTextFieldValue] = useState<string>('');

  const classes = useStyles();

  const availableCurrencies = useQuery('getAvailableCurrencies', () => CurrencyAccountsService.getAvailableCurrencies());

  const handleSpecialCurrenciesNames = (currency: CurrencyDto) => {
    if (currency.code === 'CNY') { return 'Chinese Yuan (CNY)'; }
    if (currency.code === 'CNH') { return 'Chinese Renminbi (CNH)'; }
    return `${currency.name} (${currency.code.toUpperCase()})`;
  };

  const generateMenuOptions = (): IOption[] => {
    if (availableCurrencies.data) {
      return availableCurrencies.data
        .map((currency) => (
          {
            name: handleSpecialCurrenciesNames(currency),
            code: currency.code,
          }
        ));
    }
    return [];
  };

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <>
        <APModal.Header
          onClose={() => {
            handleClose();
            setAccountCurrencyOption({ name: '', code: '' });
          }}
          showCloseButton
        >
          <Typography className={classes.modalHeader}>
            {t('new_currency_account_request')}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={classes.container}>
          {submittingAccountRequest
            ? (
              <Box textAlign="center">
                <Loader
                  testId="loader"
                  size="50px"
                  style={{ margin: 'auto', marginTop: '100px', marginBottom: '10px' }}
                />
                <p style={{ marginBottom: '100px' }}>
                  {t('submitting')}
                  ...
                </p>
              </Box>
            )
            : (
              <>
                <div className={classes.inputContainer}>
                  <div>
                    <Typography variant="subtitle1">{t('currency')}</Typography>
                    <Typography variant="subtitle1" className={classes.ccySubtitle}>{t('the_currency_you_wish_to_create_an_account_for')}</Typography>
                    <AutocompleteDropDown
                      name="currencyCode"
                      testId="account-currency-dropdown"
                      options={generateMenuOptions()}
                      setFieldValue={currencyAccountForm.setFieldValue}
                      value={currencyAccountForm.values.currencyCode}
                      touched={currencyAccountForm.touched.currencyCode}
                      errors={currencyAccountForm.errors?.currencyCode}
                      currentOption={accountCurrencyOption}
                      setCurrentOption={setAccountCurrencyOption}
                      textFieldValue={accountCurrencyTextFieldValue}
                      setTextFieldValue={setAccountCurrencyTextFieldValue}
                    />
                  </div>
                  <div className={classes.friendlyNameContainer}>
                    <Typography variant="subtitle1">{t('friendly_name~')}</Typography>
                    <Typography variant="subtitle1" className={classes.ccySubtitle}>{t('this_is_what_you_will_see_on_the_portal')}</Typography>
                    <Input
                      fullWidth
                      variant="filled"
                      name="friendlyName"
                      id="friendlyName"
                      testId="friendly-name-input"
                      placeholder=""
                      onChange={currencyAccountForm.handleChange}
                      onBlur={currencyAccountForm.handleBlur}
                      value={currencyAccountForm.values.friendlyName || ''}
                      error={currencyAccountForm.touched.friendlyName
                        && Boolean(currencyAccountForm.errors.friendlyName)}
                      helperText={currencyAccountForm.touched.friendlyName
                        && currencyAccountForm.errors.friendlyName}
                      type="text"
                      className={classes.friendlyNameInput}
                    />
                  </div>
                </div>
                <Box className={classes.footer}>
                  <ActionButton
                    testId="request-new-currency-account-submit"
                    onClick={() => {
                      currencyAccountForm.handleSubmit();
                    }}
                    rounded
                  >
                    {t('request_new_currency_account')}
                  </ActionButton>
                </Box>
              </>
            )}
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default RequestCurrencyAccountModal;
