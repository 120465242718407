import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import t from 'utils/translationHelper';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCreditCardAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table from '../../../../../components/Table/Table';
import { ITableColumn } from '../../../../../services/DatabaseServices/marker.interface';
import { formatCurrency } from '../../../../../utils/currency.helpers';

import useStyles from './index.styles';

const columns: ITableColumn[] = [
  { header: '', accessor: 'flag', width: 70 },
  { header: '', accessor: 'accountsCount' },
  {
    header: '',
    accessor: 'availableBalanceFormatted',
    align: 'right',
  },
];

interface IProps {
  currencyAccounts: CurrencyAccountDto[];
}

const CurrencyAccounts: React.FC<IProps> = ({ currencyAccounts }) => {

  const classes = useStyles();
  const [clearedBalances, setClearedBalances] = useState<{ [x: string]: number; }[]>([]);

  const mapCurrencies = useCallback((cAccounts: CurrencyAccountDto[]) => Array.from(
    new Set(cAccounts.map((acc) => acc.currencyCode)),
  ), []);

  useEffect(() => {
    const availableCurrencies = mapCurrencies(currencyAccounts);
    const clearedBalanceSum = availableCurrencies.map((currency) => ({
      [currency]: currencyAccounts
        .filter((a) => (a.currencyCode === currency))
        .reduce((partialSum, a) => partialSum + a.clearedBalance, 0),
    }));
    setClearedBalances(clearedBalanceSum);
  }, [currencyAccounts]);

  const formattedData = clearedBalances?.map((balance) => {
    const currencyCode = Object.keys(balance)[0];
    const accountsForCurrency = currencyAccounts
      .filter((acc) => acc.currencyCode === currencyCode);
    return ({
      availableBalanceFormatted: `${formatCurrency(currencyCode, balance[currencyCode])}`,
      flag: (
        <Link
          to={`/app/currency-accounts?currency=${currencyCode}`}
          data-testid="currency-account-link"
        >
          <div className={classes.currencyFlag}>
            <Flag code={currencyCode} size="md" />
            <span className={[classes.currency].join(' ')}>
              {currencyCode}
            </span>
          </div>
        </Link>
      ),
      accountsCount: (
        <div className={classes.accountsCount}>
          <FontAwesomeIcon icon={faCreditCardAlt as IconProp} />
          {accountsForCurrency.length > 1 ? (
            <>
              <span>{accountsForCurrency.length}</span>
              <APTooltip
                arrow
                title={(
                  <div>
                    <span className={classes.tooltipTitle}>
                      {t('accounts')}
                      :
                      {' '}
                    </span>
                    {accountsForCurrency.map((a) => (
                      <li className={classes.account}>
                        {a.accountName || '-'}
                      </li>
                    ))}
                  </div>
                )}
              >
                <span>
                  {accountsForCurrency.length > 1 ? t('accounts') : t('account')}
                </span>
              </APTooltip>
            </>
          ) : (<span>{accountsForCurrency[0].accountName}</span>)}
        </div>
      ),
    });
  });

  return (
    <Table
      columns={columns}
      data={formattedData}
      skip={0}
    />
  );
};

export default CurrencyAccounts;
