import React, { useState } from 'react';
import { useMutation } from 'react-query';
import t from 'utils/translationHelper';


import { PaymentDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import Warning from '../../../../assets/warning.svg';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import instance from '../../../../services/Axios/instance';
import APModal from '../../../Modals/APModal/APModal';

import useStyles from './index.styles';

type Props = {
  payments: PaymentDto[];
  open: boolean;
  handleClose: () => void;
  handleRejected: (rejectedIds: string[]) => void;
}

const RejectPaymentModal: React.FC<Props> = ({
  open, payments, handleClose, handleRejected,
}: Props) => {
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const styles = useStyles();
  const { logEvent, logError } = useLog();
  const sb = useAlphaSnackbar();

  const paymentIds = payments.map((p) => p.id);

  const rejectMutation = useMutation((_paymentIds: string[]) => instance.post('/payments/reject', { paymentIds: _paymentIds }), {
    onSuccess: () => {
      sb.trigger(t('successfully_rejected_payment'), 'info');
      logEvent({ action: 'Successfully rejected payment' });
      handleRejected(paymentIds);
      handleClose();
    },
    onError: (error) => {
      logError({ action: 'Error rejecting payment', error });
    },
  });

  const displayModalMessage = (
    modalPayments: PaymentDto[],
  ) => (modalPayments.length > 0
    ? (
      <>
        <Typography className={styles.modalBoldText}>
          {modalPayments.length > 1 ? t('you_are_about_to_reject_these_payments') : t('you_are_about_to_reject_the_following_payment')}
        </Typography>
        <ul className={styles.confirmList}>
          {modalPayments.map((p) => (
            <li>{p.contractNumber}</li>
          ))}
        </ul>
        <Typography>{t('are_you_sure_you_want_to_perform_this_action')}</Typography>
      </>
    )
    : (
      <>
        <Typography className={styles.modalBoldText}>
          {t('there_are_no_eligible_payments_to_reject')}
        </Typography>
      </>
    )
  );

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div className={styles.modalContent}>
          <img src={Warning} className={styles.modalImage} alt="Warning" />
          <div style={{ marginBottom: '48px' }}>
            {displayModalMessage(payments)}
          </div>
          <div style={{ width: '100%' }}>
            <ActionButton
              size="medium"
              variant="outlined"
              style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }}
              disabled={rejectMutation.isLoading}
              onClick={handleClose}
            >
              {t('cancel')}
            </ActionButton>
            <ActionButton
              size="medium"
              data-testid="reject-payment-btn"
              loading={rejectMutation.isLoading}
              disabled={rejectMutation.isLoading || payments.length === 0 || rejectedClicked}
              onClick={() => {
                setRejectedClicked(true);
                rejectMutation.mutate(paymentIds);
              }}
            >
              {t('reject_payments')}
            </ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default RejectPaymentModal;
