import clipboardCross from 'assets/clipboardCross.svg';
import clsx from 'clsx';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import useQueryString from 'hooks/useQueryString';
import useReportsPolling, { DownloadType, FileType, PageType } from 'hooks/useReportsPolling';
import i18n from 'i18n/config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CreditService from 'services/Credit/credit.service';
import HedgingSummaryService from 'services/HedgingSummary/hedgingSummary.service.';
import { TStore } from 'store';
import createBarChartTicks from 'utils/createBarChartTicks';
import { formatCurrency } from 'utils/currency.helpers';
import getDefaultMtmDate from 'utils/fxTrades/getDefaultMtmDate';
import t from 'utils/translationHelper';
import vmBreached from 'utils/vmBreached';

import { CreditFacilityDto as CreditCreditFacilityDto, CreditFacilityTermDto } from '@alpha/credit-dtos';
import { CreditFacilityDto, PADFacilityDto } from '@alpha/fx-dtos';
import { DashboardWidget } from '@alpha/profile-dtos';
import { Alert } from '@alpha/ui-lib/ui/Alert';
import { ActionButton, IconActionButton } from '@alpha/ui-lib/ui/button';
import { TUtilisedDataType } from '@alpha/ui-lib/ui/Chart';
import { APHorizontalBarChart, ITick } from '@alpha/ui-lib/ui/Chart/APHorizontalBarChart';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './index.styles';
import InfoModal from './InfoModal/InfoModal';

import EmptyTable from 'components/Table/EmptyTable';
import 'moment/min/locales.min';

interface IProps {
  isCreditPage?: boolean; // this can be removed once we transitition to use these styles in ui-lib
  handleRemoveWidget?: (widget: DashboardWidget) => void;
  termId?: string;
  shouldUseBrackets?: boolean;
}
// eslint-disable-next-line max-lines-per-function
const MtmWidget: React.FC<IProps> = ({
  handleRemoveWidget, isCreditPage, termId, shouldUseBrackets = false,
}: IProps) => {

  const classes = useStyles();
  const { logEvent, logError } = useLog();
  const [mtmDate, setMtmDate] = useQueryString('date', undefined);
  const getdefaultCurrencyFromLocalStorage = (): string => localStorage.getItem('default-mtm-summary-currency')! || 'GBP';
  const [creditFacility, setCreditFacility] = useState<CreditFacilityDto | CreditCreditFacilityDto | CreditFacilityTermDto>(); // TODO: rename this
  const [padFacility, setPadFacility] = useState<PADFacilityDto>();
  const [mtmTimestamp, setMtmTimestamp] = useState<string>('-');
  const [sellCurrencies, setSellCurrency] = useState<string[]>();
  const [buyCurrencies, setBuyCurrency] = useState<string[]>();
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const defaultFacilityCurrency = 'GBP';
  const [ticks, setTicks] = useState<ITick[]>([]);
  const [minXValue, setMinXValue] = useState<number>(0);
  const [maxXValue, setMaxXValue] = useState<number>(0);
  const sb = useAlphaSnackbar();
  const [hiding, setHiding] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeMarginDiscretionWindow, setActiveMarginDiscretionWindow] = useState(false);
  const [term, setTerm] = useState<CreditFacilityTermDto>();
  const [loadingFacility, setLoadingFacility] = useState(false);

  const termSummary = useSelector<TStore, CreditFacilityTermDto>(
    (state: TStore) => state.creditFacility.termSummary,
  );

  const termLoading = useSelector<TStore, boolean>(
    (state: TStore) => state.creditFacility.termLoading,
  );

  const [creditChartData, setCreditChartData] = useState<TUtilisedDataType>({
    utilised: 0,
    remaining: 0,
    total: 0,
  });

  const [padChartData, setPadChartData] = useState<TUtilisedDataType>({
    utilised: 0,
    remaining: 0,
    total: 0,
  });

  const disableWidget = () => {
    if (handleRemoveWidget) {
      setHiding(true);
      handleRemoveWidget(DashboardWidget.MTM);
    }
  };

  const calculateAmountPercentage = (amount = 0, limit = 0) => (limit > 0 ? Math.round(amount / limit * 10000) / 100 : 0);

  const loadSummary = async (): Promise<void> => {
    try {
      const summaries = await CreditService.getMtmSummary(mtmDate, selectedCurrency);
      if (summaries.length) {
        setSellCurrency([summaries[0].sellCurrency]);
        setBuyCurrency([summaries[0].buyCurrency]);
      }
    } catch (error) {
      sb.trigger(error?.message || t('unable_to_load_mtm_summary'), 'error');
      logError({ action: 'Error loading MTM summary', error });
    }
  };

  const loadAllFacilities = async (currencyCode: string): Promise<void> => {
    try {
      const results = await CreditService.getCreditFacilitiesUtilisationGraphData(
        currencyCode,
      );
      const { creditFacilities, padFacilities } = results;

      if (creditFacilities?.length) {
        setCreditChartData({
          utilised: creditFacilities[0]?.mtmAmount,
          remaining: (creditFacilities[0]?.forwardLimit - creditFacilities[0]?.mtmAmount),
          total: creditFacilities[0]?.forwardLimit,
          customPercentage: creditFacilities[0]?.mtmPercentage,
        });
        setCreditFacility(creditFacilities[0]);
      }

      if (padFacilities?.length > 0) {
        setPadFacility(padFacilities[0]);
        setPadChartData(
          {
            utilised: padFacilities[0].utilisationAmount,
            remaining: (padFacilities[0].forwardLimit - padFacilities[0].utilisationAmount),
            total: padFacilities[0].forwardLimit,
            customPercentage: calculateAmountPercentage(padFacilities[0].utilisationAmount,
              padFacilities[0].forwardLimit),
          },
        );
      }
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_credit_facilities'), 'error');
      logError({ action: 'Error loading credit facilities', error });
    }
  };

  const loadCreditFacilities = async (): Promise<void> => {
    setLoadingFacility(true);
    try {
      const credit: CreditCreditFacilityDto = await CreditService.getFacilitySummary();
      if (credit) {
        setCreditFacility(credit as CreditCreditFacilityDto);
        setCreditChartData({
          utilised: credit.mtmAmount,
          remaining: (credit.facilityLimit - credit.mtmAmount),
          total: credit.facilityLimit,
          customPercentage: credit.totalMTMPercentage,
        });
      }
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_credit_facilities'), 'error');
      logError({ action: 'Error loading credit facilities (credit)', error });
    } finally {
      setLoadingFacility(false);
    }
  };

  const loadTerm = async () => {
    if (termSummary) {
      setCreditFacility(termSummary);
      if (termSummary.bespokeTerms?.marginDiscretionWindow) {
        setActiveMarginDiscretionWindow(Boolean(termSummary.bespokeTerms.marginDiscretionWindow));
      }
      setCreditChartData({
        utilised: termSummary.mtmAmount,
        remaining: (termSummary.facilityLimit - termSummary.mtmAmount),
        total: termSummary.facilityLimit,
        customPercentage: termSummary.mtmPercentage,
      });
    }
  };

  const { executeReportGeneration } = useReportsPolling(PageType.FX);
  const handleReportGeneration = async (
    fileType: FileType,
  ) => {
    await executeReportGeneration(
      fileType,
      '',
      undefined,
      undefined,
      DownloadType.Mtm,
      fileType,
      {
        mtmdate: mtmDate,
        mtmcurrency: selectedCurrency,
        soldcurrency: sellCurrencies,
        buycurrency: buyCurrencies,
        includeexternaltrades: 'false',
      },
    );
  };

  const getLatestMtmDate = async () => {
    try {
      const response = await HedgingSummaryService.getMtmLatestAvailableDate();
      setMtmDate(response?.latestMTMDate);
    } catch (e) {
      setMtmDate(getDefaultMtmDate());
    }
  };

  useEffect(() => {
    if (!mtmDate) {
      getLatestMtmDate();
    }
    try {
      setSelectedCurrency(getdefaultCurrencyFromLocalStorage());
    } catch (e) {
      setSelectedCurrency(defaultFacilityCurrency);
    }

    logEvent({ action: 'Dashboard - View MTM Widget' });
  }, []);

  useEffect(() => {
    if (selectedCurrency && mtmDate) {
      if (isCreditPage) {
        if (termId) {
          loadTerm();
        } else {
          loadCreditFacilities();
        }
      } else {
        loadAllFacilities(selectedCurrency);
      }
      loadSummary();
    }
  }, [selectedCurrency, mtmDate, termId]);

  useEffect(() => {
    if (creditFacility) {
      const {
        ticksToDisplay,
        minTickValue,
        maxTickValue,
      } = createBarChartTicks(creditFacility.plots, creditFacility.currencyCode, (creditFacility as CreditFacilityTermDto)?.variationMargin);
      setTicks(ticksToDisplay);
      setMinXValue(minTickValue);
      setMaxXValue(maxTickValue);
      const parsedMtmDate = moment(creditFacility.rateDate);
      parsedMtmDate.locale(i18n.language);
      if (parsedMtmDate.isValid()) {
        setMtmTimestamp(parsedMtmDate.format('MMMM Do YYYY, h:mm a'));
      }
    }
  }, [creditFacility]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const styledTitle = (
    <p>
      {t('mtm_summary')}
      {' '}
      |
      {' '}
      {' '}
      <span className={classes.title}>
        {t('mtm_as_at')}
        {' '}
        {' '}
        {mtmTimestamp}
        {' '}
      </span>
    </p>
  );

  const widgetWidth = Math.min(800, Math.max(windowWidth / 2.5, 100));
  const showVMAlert = (percentage?: number, limit?: number) => {
    if (percentage === undefined || limit === undefined) return false;
    return percentage < 0 && percentage >= -limit;
  };

  useEffect(() => {
    if (creditFacility) setTerm(creditFacility);
  }, [creditFacility]);

  const showGraph = (term && creditFacility);

  return (
    <Box
      className={clsx(classes.widget,
        { [classes.demo]: creditFacility?.isDemo })}
      style={{
        gridColumn: 'span 2',
        gridAutoColumns: 'auto',
      }}
    >
      <Box hidden={!showGraph}>
        <Box className={clsx(classes.mtmStatsContainer, isCreditPage && 'isCreditPage')}>
          {styledTitle}
          {isCreditPage
            ? (
              <IconActionButton
                testId="icon-action-button"
                icon={faCircleQuestion}
                style={{ background: '#F7F7F7', color: '#212529' }}
                onClick={() => {
                  logEvent({ action: 'Clicked on info modal mtm summary' });
                  setOpenModal(true);
                }}
              />
            )
            : (
              <ActionButton
                style={{ background: '#F7F7F7', color: '#212529' }}
                onClick={() => {
                  handleReportGeneration(FileType.PDF);
                }}
                endIcon={<FontAwesomeIcon icon={faDownload as IconProp} />}
              >
                {t('download_summary')}
              </ActionButton>
            )}
        </Box>
        <Box className={classes.mtmStatsInfo}>
          {isCreditPage
            && showVMAlert(creditChartData?.customPercentage, (creditFacility as CreditFacilityTermDto)?.variationMargin)
            && !activeMarginDiscretionWindow ? (
            <Alert text={t('please_note_your_mtm_is_negative_but_has_not_breached_the_vm_level')} variant="info" />
          ) : null}
          {isCreditPage
            && activeMarginDiscretionWindow ? (
            <Alert text={t('as_you_are_in_an_active_discresion_window_you_will_not_be_margin_called')} variant="info" />) : null}
          <Typography className={classes.mtmStat}>
            {`${t('mtm_valuation')}: ${formatCurrency(creditFacility?.currencyCode, creditFacility?.mtmAmount, shouldUseBrackets)}`}
          </Typography>
        </Box>
        <Box>
          <Box>
            {creditChartData?.customPercentage !== 0 && creditChartData?.customPercentage !== null && maxXValue && minXValue && ticks.length ? (
              <APHorizontalBarChart
                chartData={[creditChartData?.customPercentage]}
                xAxisMax={maxXValue}
                xAxisMin={minXValue}
                width="100%"
                height={200}
                ticks={ticks}
                mtmPercentage={t('mtm_percentage')}
                breachedVm={Boolean(
                  // Special bespoke handlings 1
                  !(term?.bespokeTerms?.marginDiscretionWindow
                    && (term?.variationMargin === 0 || term?.bespokeTerms?.noMarginCall))
                  // Special bespoke handlings 2
                  && !(term?.bespokeTerms?.noMarginCall)
                  && termId
                  && vmBreached(creditChartData?.customPercentage, term?.variationMargin),
                )}
                vmPercentage={termId && term?.variationMargin ? -term?.variationMargin : undefined}
              />
            ) : (
              <EmptyTable
                title={t('currently_no_data')}
                subtitle={t('if_incorrect_contact_dealer')}
                className={classes.emptyTable}
                icon={clipboardCross}
                style={{ transform: 'none', position: 'unset' }}
              />
            )}
          </Box>
          {(!creditFacility || termLoading) && (
            <Box width={widgetWidth}>
              <Loader testId="loading-creditFacility-chart" />
            </Box>
          )}
        </Box>
        {
          creditFacility?.isDemo ? (
            <Box width="100%" display="flex">
              <ActionButton
                style={{
                  zIndex: 1000,
                  margin: 'auto',
                  marginTop: '-100px',
                }}
                onClick={disableWidget}
                disabled={hiding}
                loading={hiding}
              >
                {t('hide_widget')}
              </ActionButton>
            </Box>
          ) : null
        }
        <InfoModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
        />
      </Box>

      {!showGraph && (loadingFacility || termLoading) ? (<Loader testId="loading-mtmWidget" />) : null}
    </Box>
  );
};

export default MtmWidget;
