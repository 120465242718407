import APModal from 'components/Modals/APModal/APModal';
import { t } from 'i18next';
import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/external';
import { SingleStat } from '@alpha/ui-lib/ui/StatSummary/SingleStat';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';

import useStyles from './BatchBeneficariesStyles.styles';

interface IProps {
  open: boolean;
  beneBatchSummaryFields: any;
  beneBatchName: string;
  handleClose: () => void
  handleDeleteBatch: () => void
}

const DeleteBeneficiaryBatchModal: React.FC<IProps> = (props: IProps) => {
  const {
    open,
    beneBatchSummaryFields,
    beneBatchName,
    handleClose,
    handleDeleteBatch,
  } = props;

  const classes = useStyles();
  delete beneBatchSummaryFields[1];

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <>
        <APModal.Header
          onClose={handleClose}
          showCloseButton
        >
          <Typography className={classes.modalHeader}>
            {t('are_you_sure_delete_job')}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={classes.container}>
          <div style={{
            fontWeight: 400, padding: '20px', fontSize: '13px', marginBottom: '20px',
          }}
          >
            {beneBatchName}
            <div className={classes.statsModalContainer}>
              {beneBatchSummaryFields?.map((b) => (
                <SingleStat
                  statHeader={<div style={{ minWidth: '100px', color: '#949494' }}>{b.header}</div>}
                  value={<div style={{ color: '#212529' }}>{b.value}</div>}
                />
              ))}
            </div>
          </div>
          <StyledDivider />
          <div className={classes.buttonContainer}>
            <ActionButton style={{ backgroundColor: 'rgba(253,237,237)', color: '#D55E5E', borderRadius: '4px' }} onClick={handleClose}>{t('close')}</ActionButton>
            <ActionButton onClick={handleDeleteBatch}>{t('delete')}</ActionButton>
          </div>
          {/* </Box> */}
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default DeleteBeneficiaryBatchModal;
