import React from 'react';

import { AccountDto } from '@alpha/auth-dtos';
import { Box } from '@alpha/ui-lib/ui/external';

import TeamNotificationSettings from './TeamNotificationSettings/TeamNotificationSettings';
import TeamPortalPermissions from './TeamPortalPermissions/TeamPortalPermissions';
import AccountAudits from './AccountAudits';
import useStyles from './TeamSettings.styles';

interface IProps {
  selectedAccount?: AccountDto;
  canEditUserPermission?: boolean;
  canEditNotification?: boolean;
}

const TeamSettings: React.FC<IProps> = ({
  selectedAccount,
  canEditUserPermission,
  canEditNotification,
}: IProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" gridGap="12px">
      <Box className={classes.widget}>
        <TeamPortalPermissions
          selectedAccount={selectedAccount}
          canEditUserPermission={canEditUserPermission}
        />
      </Box>
      <Box className={classes.widget}>
        <TeamNotificationSettings
          selectedAccount={selectedAccount}
          canEditNotification={canEditNotification}
        />
      </Box>
      <Box className={classes.widget}>
        <AccountAudits selectedAccount={selectedAccount} />
      </Box>

    </Box>
  );
};

export default TeamSettings;
