import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      padding: '0 8px !important',
    },
    warningStyle: {
      width: '70px',
      marginBottom: '24px',
    },
    innerContainer: {
      textAlign: 'center',
      padding: '8px',
      width: '400px',
    },
    headerText: {
      fontWeight: 800,
      marginBottom: '16px',
      fontSize: '16px',
    },
    cancelBatchButton: {
      widht: '170px',
    },
    textContainer: {
      marginBottom: '28px',
      marginTop: '10px',
    },
    buttonContainer: {
      width: '100%',
      '& .MuiButton-root': {
        borderRadius: '5px',
        height: '40px',
      },
    },
    dropdownContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '20px',
      marginLeft: '18px',
      '& .MuiInputBase-root': {
        width: '350px',
      },
    },
  }),
  { name: 'SubmitPaymentsModal' },
);

export default useStyles;
