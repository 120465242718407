import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  (theme) => ({
    greenText: {
      fontWeight: theme.typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    modalHeader: {
      paddingLeft: '56px',
      fontSize: 16,
      padding: '12px 24px 24px 24px',
    },
    modalWrapper: {
      maxWidth: '1800px',
      width: '90vw',
      minWidth: '1000px',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 290px)',
      minHeight: '300px',
    },
    semiBold: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    stickyHeaderTable: {
      marginTop: '24px',
      borderCollapse: 'separate',
      '& thead': {
        position: 'sticky',
        top: '0',
        background: 'white',
        '& th': {
          borderBottom: '1px solid #eaeaea',
          paddingRight: '20px !important',
        },
      },
      '& td': {
        paddingRight: '20px !important',
      },
    },
  }),
  { name: 'TradeBreakdownModal' },
);

export default useStyles;
