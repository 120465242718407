import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    padding: '0 8px !important',
  },
  modalWrapper: {
    textAlign: 'center', 
    padding: '0 32px', 
    width: '700px',
  },
  modalContent: {
    textAlign: 'center',
    padding: '8px',
    width: '400px',
  },
  modalBoldText: {
    fontWeight: typography.fontWeightBold,
    marginBottom: '16px',
    fontSize: '16px',
  },
  modalImage: {
    width: '70px',
    marginBottom: '24px',
  },
  confirmList: {
    listStyleType: 'none',
    lineHeight: '20px',
    marginRight: '30px',
    marginBottom: '20px',
    maxHeight: '200px',
    overflowY: 'auto',
  },
  heading: { 
    fontWeight: '800',
    marginBottom: '16px',
    fontSize: '16px' ,
  },
  textLeft: {
    textAlign: 'left',
  },
  action: {
    width: '250px', 
    marginTop: '53px',
  },
  warningImg: {
    width: '70px', 
    marginBottom: '24px',
  },
  maxWidth: {
    width: '100%',
  },
  contentMarginBottom: {
    marginBottom: '48px'
  }
}), { name: 'RejecttransferModal' });

export default useStyles;
