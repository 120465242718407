import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TPendingBeneficiaryParams = {
  batchId: string;
};

export type TBeneficiaryBatchDetails = {
  batchId?: string,
  status?: string,
  error?: string[],
  fileName?: string,
  noOfRecords?: number;
  processedRecords?: number;
  validRecords?: number;
  invalidRecords?: number;
  erroredRecords?: number;
};

export type TBeneficariesStore = {
  totalRecords: number;
  loaded: boolean,
  batchDetails?: TBeneficiaryBatchDetails,
  beneficaryIds: string[],
  uploadModal: boolean,
};

export type TBatchStatus = {
}

const initialState: TBeneficariesStore = {
  totalRecords: 0,
  loaded: false,
  beneficaryIds: [],
  uploadModal: true,
};

export const beneficariesSlice = createSlice({
  name: 'beneficiaries',
  initialState,
  reducers: {
    clear: (state, action: PayloadAction<void>): TBeneficariesStore => initialState,
    triggerBatchStatus: (state): TBeneficariesStore => state,
    updateBatchId: (state, action: PayloadAction<string>) => ({
      ...state,
      batchDetails: {
        ...state.batchDetails,
        batchId: action.payload,
      },
    }),
    updateBatchStatus: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      batchDetails: {
        ...state.batchDetails,
        status: action.payload,
      },
    }),
    updateBatchDetails: (state, action: PayloadAction<{
      batchId?: string;
      status?: string;
      fileName?: string;
      noOfRecords?: number;
      processedRecords?: number;
      validRecords?: number;
      invalidRecords?: number;
    }>) => ({ ...state, batchDetails: action.payload }),
    clearBatchDetails: (state) => ({
      ...state, batchDetails: {},
    }),
    closeUploadModal: (
      state,
      action: PayloadAction<{uploadModal: boolean}>,
    ): TBeneficariesStore => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { actions } = beneficariesSlice;
