import Warning from 'assets/warning.svg';
import APModal from 'components/Modals/APModal/APModal';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import IATService from 'services/IAT/IAT.service';
import t from 'utils/translationHelper';

import { CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import useStyles from './index.styles';

type Props = {
  transfer: CurrencyAccountTransferDto | undefined;
  open: boolean;
  handleClose: () => void;
  handleRejected: (rejectedId: string) => void;
}

const RejectTransferModal: React.FC<Props> = ({
  open, transfer, handleClose, handleRejected,
}: Props) => {
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const styles = useStyles();
  const { logEvent, logError } = useLog();
  const sb = useAlphaSnackbar();

  const transferId = transfer?.id;

  const rejectMutation = useMutation(async (_transferId: string) => {
    await IATService.rejectTransfer(_transferId);
  }, {
    onSuccess: () => {
      sb.trigger(t('successfully_rejected_transfer'), 'info');
      logEvent({ action: 'Successfully rejected transfer' });

      if (transferId) {
        handleRejected(transferId);
      }
      handleClose();
    },
    onError: (error) => {
      const reason = error.response?.data?.error || t('error_rejecting_transfer');
      sb.trigger(reason, 'error');
      logError({ action: 'Error rejecting transfer', error });
    },
  });

  const displayModalMessage = (
    modalTransfer: CurrencyAccountTransferDto,
  ) => (modalTransfer
    ? (
      <>
        <Typography className={styles.modalBoldText}>
          {t('you_are_about_to_reject_the_following_transfer')}
        </Typography>
        <ul className={styles.confirmList}>
          <li>{modalTransfer.contractNumber}</li>
        </ul>
        <Typography>{t('are_you_sure_you_want_to_perform_this_action')}</Typography>
      </>
    )
    : (
      <>
        <Typography className={styles.modalBoldText}>
          {t('there_are_no_eligible_transfer_to_reject')}
        </Typography>
      </>
    )
  );
  useEffect(() => {
    if (open) {
      setRejectedClicked(false);
    }
  }, [open]);
  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div className={styles.modalContent}>
          <img src={Warning} className={styles.modalImage} alt="Warning" />
          <div style={{ marginBottom: '48px' }}>
            {displayModalMessage(transfer)}
          </div>
          <div style={{ width: '100%' }}>
            <ActionButton
              size="medium"
              style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }}
              disabled={rejectMutation.isLoading}
              onClick={handleClose}
            >
              {t('cancel')}
            </ActionButton>
            <ActionButton
              size="medium"
              loading={rejectMutation.isLoading}
              disabled={rejectMutation.isLoading || !transfer || rejectedClicked}
              data-testid="transfer-reject-btn"
              onClick={() => {
                setRejectedClicked(true);
                rejectMutation.mutate(transferId);
              }}
            >
              {t('reject_transfer')}
            </ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default RejectTransferModal;
