import { Guid } from 'guid-typescript';
import {
  call, delay, put, select, takeLatest,
} from 'redux-saga/effects';
import t from 'utils/translationHelper';

import { PaymentBatchStatus } from '@alpha/payments-dtos';

import env from '../../env.variables';
import { TPayment, TPendingPayment } from '../../models/payments';
import { actions as notificationsActions } from '../notifications/notifications.reducer';
import { apiRequest, TAPIRequestsResult } from '../requestSaga';
import { TStore } from '..';

import { actions, TPaymentBatchDetails } from './payments.reducer';

export const loadPaymentsRequestName = 'payments';
export function* loadPaymentsSaga(action: ReturnType<typeof actions.loadPayments>) {
  try {
    const result: TAPIRequestsResult<TPayment[]> = yield call(apiRequest, {
      config: {
        method: 'GET',
        url: `${env.REACT_APP_API_URL}payments/batches`,
        params: {
          ...action.payload,
        },
      },
      name: loadPaymentsRequestName,
    });

    yield put(actions.loadedPayments({
      data: result.data,
      totalRecords: result?.headers?.['total-items'] || 0,
    }));
  } catch (err) {
    throw Error(`Error loading payment: ${err}`);
  }
}

export function* pollBatchStatus(): any {
  try {
    const batchIdFromState = yield select(
      (state: TStore): string | undefined => state.payments.batchDetails?.batchId,
    );

    if (batchIdFromState) {
      let tryCount = 0;
      while (tryCount < 60) {
        const response = yield call(apiRequest, {
          config: {
            method: 'GET',
            url: `${env.REACT_APP_API_URL}payments/batches/${batchIdFromState}`,
          },
        });
        const { status: batchStatus, error } = response.data;
        const batchDetails: TPaymentBatchDetails = { batchId: batchIdFromState, ...response.data };
        const shouldContinueToPoll = (batchStatus === PaymentBatchStatus.PENDING_UPLOAD) || (
          batchStatus === PaymentBatchStatus.VALIDATED && batchDetails
        && batchDetails.processedRecords! < batchDetails.noOfRecords!
        );

        if (!shouldContinueToPoll) yield delay(2000);
        yield put(actions.updateBatchDetails(batchDetails));

        if (shouldContinueToPoll) {
          tryCount += 1;
          yield delay(1000);
        } else {
          yield put(actions.clear());
          if (batchStatus === PaymentBatchStatus.INVALID) {
            throw new Error(t(error) || 'The file is invalid, please check again.');
          }
          return;
        }
      }
      throw new Error('Upload timeout, please try again.');
    }
  } catch (e) {
    yield put(
      notificationsActions.enqueueSnackbar({
        variant: 'error',
        key: Guid.create().toString(),
        message: e && e.message ? e.message.toString() : 'An error occurred uploading your file',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }),
    );
    yield put(actions.clear());
  }
}

export const loadPendingPaymentsRequestName = 'pendingPayments';
export const loadPendingPaymentsSaga = function* (
  action: ReturnType<typeof actions.loadPendingPayments>,
) {
  try {
    const result: TAPIRequestsResult<
    { hasMore: boolean,
      items: TPendingPayment[]
    }> = yield call(apiRequest, {
      config: {
        method: 'GET',
        url: `${env.REACT_APP_API_URL}payments/batches/${action.payload.batchId}`,
        params: {
          sellcurrencycode: action.payload.currencyPair?.sellCurrency,
          buycurrencycode: action.payload.currencyPair?.buyCurrency,
          fixedside: action.payload.currencyPair?.fixedSide,
        },
      },
      name: loadPendingPaymentsRequestName,
    });
    const response = {
      totalItems: result.headers['content-length'], // TODO: fix this, target correct total-items header, currently not avail
      data: result.data.items,
    };
    yield put(actions.loadedPendingPayments(response));
  } catch (err) {
    // TODO: handle error
  }
};

export function* paymentsSaga() {
  yield takeLatest(actions.triggerBatchStatus, pollBatchStatus);
}
