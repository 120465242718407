import React, { useEffect, useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import t from 'utils/translationHelper';
import browserHistory from 'services/history/browserHistory';
import { formatCurrency } from 'utils/currency.helpers';

import { CreditFacilityTermDto } from '@alpha/credit-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { BannerButton } from '@alpha/ui-lib/ui/button/BannerButton';
import { APUtilisationStackedBarChart, graphColorPalette } from '@alpha/ui-lib/ui/Chart/APUtilisationStackedBarChart';
import { Box, Divider, Typography } from '@alpha/ui-lib/ui/external';

import useWidgetStyles from './index.styles';

interface IProps {
  term: CreditFacilityTermDto | undefined;
  isDashboardWidget?: boolean;
}

const TermUtilisation: React.FC<IProps> = ({ term, isDashboardWidget = false }) => {

  const widgetClasses = useWidgetStyles();
  const [termUtilisationData, setTermUtilisationData] = useState([]);

  useEffect(() => {
    if (term) {
      const data = [
        {
          title: t('forwards'),
          amount:
            formatCurrency(term?.currencyCode, term?.utilisationForwards),
          openPositions: term?.forwardOpenPositions ?? '-',
          gridColor: graphColorPalette[1],
        },
        {
          title: t('options'),
          amount:
            formatCurrency(term?.currencyCode, term?.utilisationOptions),
          openPositions: term?.optionOpenPositions ?? '-',
          gridColor: graphColorPalette[2],
        },
      ];
      setTermUtilisationData(data);
    }
  }, [term]);

  const calculateAmountPercentage = (amount = 0, limit = 0) => (limit > 0 ? Math.round(amount / limit * 10000) / 100 : 0);

  return (
    <div className={widgetClasses.widget}>
      {term
        ? (
          <>
            <Box className={widgetClasses.termsContainer}>
              <Box display="flex" justifyContent="unset">
                <Typography className={widgetClasses.title}>
                  {t('term_utilisation')}
                  {' '}
                  |
                  {' '}
                </Typography>
                <Typography className={widgetClasses.titleLimit}>
                  {t('term_limit')}
                  {' '}
                  {term && formatCurrency(term?.currencyCode, term?.facilityLimit)}
                </Typography>
              </Box>
              {isDashboardWidget && (
                <ActionButton
                  style={{ background: '#F7F7F7', color: '#212529' }}
                  onClick={() => {
                    browserHistory.push('/app/credit-facility');
                  }}
                >
                  {t('view_all~')}
                </ActionButton>
              )}
            </Box>

            {/* Graph */}
            <div>
              {term ? (
                <div>
                  <Box>
                    <APUtilisationStackedBarChart
                      chartData={[
                        {
                          value: Math.ceil(Number(calculateAmountPercentage(term?.utilisationForwards, term?.facilityLimit)?.toFixed(2)) || 0),
                          label: t('forwards'),
                          color: graphColorPalette[1],
                          amount: term?.utilisationForwards,
                        },
                        {
                          value: Math.ceil(Number(calculateAmountPercentage(term?.utilisationOptions, term?.facilityLimit)?.toFixed(2)) || 0),
                          label: t('options'),
                          color: graphColorPalette[2],
                          amount: term?.utilisationOptions,
                        },
                      ].filter((item) => (item.amount !== 0))}
                      height={80}
                      width="100%"
                      limit={100}
                      remainingText={t('remaining')}
                      striped={false}
                      showTooltip
                      alwaysShowLabel
                      anchorPosition="center"
                      showLegend={false}
                      groupSmallItems
                    />
                  </Box>

                  <Divider style={{ marginBottom: '30px' }} />

                  <Box display="flex" flexDirection="column" gridGap={16}>
                    {termUtilisationData
                      ? termUtilisationData?.map((item) => (
                        <BannerButton
                          style={{ pointerEvents: 'none' }}
                          buttonTitle={item.title}
                          titleRight={item.amount}
                          subtitle={t('open_positions')}
                          subtitleRight={item.openPositions}
                          gridColor={item.gridColor}
                          fullWidth
                        />
                      )) : null}
                  </Box>
                  <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
                  <BannerButton
                    style={{ pointerEvents: 'none' }}
                    buttonTitle={t('total_open_positions')}
                    titleRight={term?.totalOpenPositions ?? '-'}
                    fullWidth
                    hideGrid
                  />
                </div>
              ) : null}
            </div>
          </>
        )
        : (
          <EmptyTable
            title={t('no_utilisation_data_available')}
            subtitle={(
              <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: '300' }}>
                {t('there_are_currently_no_term_limits_or_open_positions')}
                <br />
                {t('you_will_be_able_to_see_your_utilisation_once_these_limits_are_set')}
              </Typography>
            )}
            icon={clipboardCross}
            className={widgetClasses.emptyTableUtilisation}
          />
        )}
    </div>
  );
};

export default TermUtilisation;
