import { AccountDto } from '@alpha/auth-dtos';
import useLog from 'hooks/useLog';
import React, { useEffect, useState } from 'react';
import MassReportingService from 'services/MassReporting/massReporting.service';
import timer from 'utils/timer';
import ExportManagerWidget from './ExportManagerWidget';
import ExportsTable from './ExportsTable';
import reportStatuses from './reportStatusEnum';

interface IProps {
  loading: boolean,
  userAccounts?: AccountDto[],
}

const MassReporting: React.FC<IProps> = ({ loading, userAccounts }) => {
  const [apiData, setApiData] = useState([])
  const [getTableDataLoading, setLoading] = useState(true)
  const { logError } = useLog();
  const [currentPage, setCurrentPage] = useState<number>(0)
  const pollExports = async (delay = 2000, iteration: number) => {
    try {
      const response = await MassReportingService.getMassReporting(200);
      setApiData(response.items)
      if (iteration === 0) {
        setLoading(false)
      }
      const pollCondition = (!response.items.every((r) => r.status !== reportStatuses.IN_PROGRESS))
      if (pollCondition) {
        await timer(delay);
        pollExports(5000, iteration + 1);
      }
    } catch (e) {
      logError({ action: 'Error polling batch', e });
    }
  };

  useEffect(() => {
    pollExports(5000, 0)
  }, [])

  return (
    <>
      <ExportManagerWidget apiData={userAccounts} loading={loading} pollExports={pollExports} setCurrentPage={setCurrentPage} />
      <div style={{ marginTop: '24px' }}>
        <ExportsTable
          loading={getTableDataLoading}
          apiData={apiData}
          setPageNumber={setCurrentPage}
          pageNumber={currentPage}
        />
      </div>
    </>
  )
};

export default MassReporting;
