import i18n from 'i18n/config';
import moment, { Moment } from 'moment';
import React from 'react';
import t from 'utils/translationHelper';

import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';

import { DateFields } from '..';
import ExportMenu from '../ExportMenu/ExportMenu';

import useStyles from './StatementsCtas.styles';

export const testIds = {
  applyDatesBtn: 'apply-dates-btn',
};

export interface IProps {
  componentTestId?: string;
  setSelectedDates: any,
  selectedDates: { startDate: Moment, endDate: Moment };
  currencyAccountId: string;
  startDateIso: string;
  endDateIso: string;
}

const StatementsCtas: React.FC<IProps> = (props: IProps) => {
  const {
    currencyAccountId,
    startDateIso,
    endDateIso,
    componentTestId,
    setSelectedDates,
    selectedDates,
  } = props;

  const classes = useStyles();


  const handleDatePicker = (field: DateFields, date: Moment) => {
    setSelectedDates({
      ...selectedDates,
      [`${field}`]: date,
    });
  };

  return (
    <div data-testid={componentTestId} className={classes.rightAlignedCtas}>
      <div className={classes.dates}>
        <APDatePicker
          label={t('date_from')}
          language={i18n.language}
          onChange={(date) => handleDatePicker(DateFields.START, date)}
          value={selectedDates.startDate.format()}
          minDate={moment('2022-04-17')}
          maxDate={moment(selectedDates.endDate)}
        />
        <APDatePicker
          label={t('date_to')}
          language={i18n.language}
          onChange={(date) => handleDatePicker(DateFields.END, date)}
          value={selectedDates.endDate.format()}
          minDate={moment(selectedDates.startDate)}
          maxDate={moment()}
        />
      </div>
      <div className={classes.divider} />
      <ExportMenu
        startDate={startDateIso}
        endDate={endDateIso}
        currencyAccountId={currencyAccountId}
      />
    </div>
  );
};

export default StatementsCtas;
