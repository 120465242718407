import React, { useEffect, useState } from 'react';
import Alert from 'components/Alert';
import PersonalNotificationMatrixTable from 'components/Molecules/AccountSettings/PersonalNotificationMatrixTable/PersonalNotificationMatrixTable';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import t from 'utils/translationHelper';
import AccountSettingsService from 'services/AccountSettings/accountSettings.service';

import { AccountUserEmailSettingsDto, AccountUserEmailSettingsUpdateRequest } from '@alpha/notifications-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import {
  Box, Collapse,
  IconButton, Typography,
} from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faMessageDots } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from '../PersonalSettings.styles';

interface IProps {
  canEdit?: boolean;
}

const PersonalNotificationSettings: React.FC<IProps> = ({ canEdit = false }: IProps) => {
  const [userEmailsettings, setUserEmailsettings] = useState<AccountUserEmailSettingsDto[]>([]);
  const [originalSettings, setOriginalSettings] = useState<AccountUserEmailSettingsDto[]>([]);
  const [formTouched, setFormTouched] = useState(false);
  const [submitting, setSubitting] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const { logEvent, logError } = useLog();
  const sb = useAlphaSnackbar();


  const loadUserNotificationSettings = async () => {
    try {
      const notificationSettings = await AccountSettingsService.getPersonalEmailSettings();
      setUserEmailsettings(notificationSettings);
      setOriginalSettings(JSON.parse(JSON.stringify(notificationSettings)));
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_communication_settings'));
      logError({ action: 'Error loading personal communication settings', error });
    }
  };

  const saveUserNotificationSettings = async () => {
    const request: AccountUserEmailSettingsUpdateRequest[] = userEmailsettings.map((settings) => ({
      accountId: settings.accountId,
      enabled: settings.enabled,
      disabled: settings.disabled,
    }));

    try {
      setSubitting(true);
      await AccountSettingsService.postPersonalEmailSettings(request);
      sb.trigger(t('communication_settings_saved'), 'success');
      logEvent({ action: 'Successfully saved communication settings' });
      loadUserNotificationSettings();
    } catch (error) {
      sb.trigger(error?.message || t('could_not_update_communication_settings'));
      logError({ action: 'Error updating spot trade', error });
    } finally {
      setSubitting(false);
    }
  };

  useEffect(() => {
    loadUserNotificationSettings();
  }, []);

  useEffect(() => {
    function jsonEqual(a: any, b: any): boolean {
      return JSON.stringify(a) === JSON.stringify(b);
    }
    const isOriginal = jsonEqual(userEmailsettings.map((a) => a.enabled.sort()),
      originalSettings.map((a) => a.enabled.sort()));
    setFormTouched(!isOriginal);
  }, [userEmailsettings, originalSettings]);

  const statusBadge = canEdit ? (<Status className={classes.smallStatus} variant="info">{t('editable')}</Status>) : (<Status className={classes.smallStatus}>{t('non_editable')}</Status>);

  return (
    <Box flexDirection="rows" display="flex" gridGap="10px">
      <Box>
        <IconButton
          onClick={() => { setExpanded(!expanded); }}
          style={{ marginTop: '4px', borderRadius: 0 }}
          size="small"
        >
          <FontAwesomeIcon
            className={expanded ? classes.iconDown : classes.iconRight}
            icon={faChevronDown as IconProp}
            size="sm"
          />
        </IconButton>
      </Box>
      <Box display="flex" gridGap="10px" flexDirection="column" flexGrow={1}>
        <Box
          display="flex"
          className={classes.stickySection}
          flexDirection="row"
        >
          <Box flexGrow={1}>
            <Typography className={classes.boxHeaderText}>
              <FontAwesomeIcon className="icon" icon={faMessageDots as IconProp} color="#1E8777" size="xs" />
              {' '}
              {t('communication_settings')}
              {' '}
              {' '}
              {statusBadge}
            </Typography>
            <Typography variant="subtitle1" className={classes.subTitleText}>{t('manage_your_communication_preference_here')}</Typography>
          </Box>
          <Collapse in={expanded}>
            <Box>
              <ActionButton
                disabled={!formTouched || submitting}
                loading={submitting}
                onClick={() => { saveUserNotificationSettings(); }}
                style={{ borderRadius: '4px' }}
              >
                {t('save')}
              </ActionButton>
            </Box>
          </Collapse>
        </Box>
        <Collapse in={expanded}>
          {userEmailsettings && (
            <Box>
              <Alert variant="info" text={t('please_note_that_at_least_one_user_must_receive_the_monthly_trade_emails')} />
            </Box>
          )}
          <PersonalNotificationMatrixTable
            setUserNotificationSettings={setUserEmailsettings}
            userNotificationSettings={userEmailsettings}
            readonly={submitting}
          />
        </Collapse>
      </Box>
    </Box>
  );
};

export default PersonalNotificationSettings;
