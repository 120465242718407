import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    root: {
      paddingTop: '24px',
      paddingBottom: '30px',
      backgroundColor: '#fafafa',
      minHeight: 'calc(100vh - 310px)',
    },
    panel: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '24px',
      maxWidth: '100%',
      '@media (max-width: 800px)': {
        gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr));',
      },
      paddingBottom: '40px',
      margin: 'auto',
    },
    widget: {
      padding: '20px',
      background: '#ffffff',
      borderRadius: '4px',
      boxShadow: '0px 3px 6px #00000005',
      '@media (max-width: 800px)': {
        gridColumn: '1/1 !important',
      },
    },
    header: {
      color: '#1E8777',
      display: 'inline',
      fontSize: '28px',
      fontWeight: 400,
    },
    title: {
      margin: 0,
    },
    buttons: {
      marginTop: 0,
      marginBottom: '-10px',
    },
    flag: {
      lineHeight: '27px',
      '& div': {
        verticalAlign: 'middle',
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '3px',
      },
    },
    emptyTable: {
      position: 'relative',
      marginBottom: '20px',
      '& .image': {
        height: '120px',
        width: '120px',
        '& img': {
          height: '60',
          width: '60px',
        },
      },
    },
    status: {
      textAlign: 'center',
      display: 'block',
    },
    statusContainer: {
      marginLeft: '16px',
      paddingLeft: '20px',
      borderLeft: '#e6e6e6 1px solid',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  }),
  { name: 'FacilityTerm' },
);

export default useStyles;
