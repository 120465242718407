import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ typography, palette }) => ({
    dropdown: {
      width: '509px',
      borderRadius: '5px',
      '& .MuiFormControl-root': {
        '& .MuiInputBase-root': {
          backgroundColor: '#F7F7F7',
          borderRadius: 5,
          '&::before': {
            borderBottom: 'none',
          },
          '&::after': {
            display: 'none',
          },
          height: 'auto',
          padding: '15px 65px 15px 13px',
          ' & .MuiInputBase-input': {
            fontSize: '16px',
            padding: 0,
            height: 'auto',
          },
          '& .MuiAutocomplete-endAdornment': {
            right: '10px',
          },
        },
      },
    },
    hideFlag: {
      '& .MuiInputBase-root': {
        paddingLeft: '16px !important',
      },
    },
    outputText: {
      fontSize: '16px',
    },
    labelWrapper: {
      display: 'flex',
      alignitems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    chip: {
      '& .MuiChip-root': {
        backgroundColor: 'rgba(9, 131, 117, .2)',
        color: '#1E8777',
        fontWeight: typography.fontWeightRegular,
        fontFamiy: typography.fontFamily,
        fontSize: 12,
      },
      '& .MuiSvgIcon-root': {
        color: '#1E8777',
      },
    },
    selectedCheck: {
      color: palette.primary.main,
      marginLeft: 'auto',
      alignSelf: 'center',
    },
  }),
  { name: 'MultiSelectEntityDropDown' },
);

export default useStyles;
