import React from 'react';
import useLog from 'hooks/useLog';
import t from 'utils/translationHelper';
import { useLocation } from 'react-router-dom';

import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import APFilterMenu from '@alpha/ui-lib/ui/molecules/Menu/FilterMenu';
import { MultiAutoCompleteSelect } from '@alpha/ui-lib/ui/Select/MultiAutoCompleteSelect/MultiAutoCompleteSelect';
import { faSlidersH } from '@fortawesome/pro-light-svg-icons';

import useStyles from './index.styles';
import useMtmFilter from './useMtmFilter';

interface IProps {
  mtmDate: string;
  termId?: string;
  buyCurrencies?: string[];
  sellCurrencies?: string[];
  termsIds?: string[];
  setBuyCurrencies?: React.Dispatch<React.SetStateAction<string[]>>;
  setSellCurrencies?: React.Dispatch<React.SetStateAction<string[]>>;
  setTermsIds?: React.Dispatch<React.SetStateAction<string[]>>;
}

const MtmFilter: React.FC<IProps> = ({
  mtmDate, termId, buyCurrencies, sellCurrencies, termsIds, setBuyCurrencies, setSellCurrencies, setTermsIds,
}) => {
  const {
    mapCurrencyFilterOptions,
    mapTermFilterOptions,
    setBuyCurrenciesFilter,
    buyCurrenciesFilter,
    sellCurrenciesFilter,
    setSellCurrenciesFilter,
    termsIdFilter,
    setTermsIdFilter,
    applyChanges,
    setFilterMenuOpen,
    clearAllFilters,
    filterMenuOpen,
    buyCurrenciesFilterOptions,
    soldCurrenciesFilterOptions,
    termsIdsFilterOptions,
  } = useMtmFilter(
    mtmDate,
    termId,
    setBuyCurrencies,
    setSellCurrencies,
    setTermsIds,
  );


  const classes = useStyles();
  const location = useLocation();
  const { logEvent } = useLog();

  const menuItems = [
    location.pathname.includes('credit') && termsIds
      ? {
        content: (
          <MultiAutoCompleteSelect
            options={mapTermFilterOptions(termsIdsFilterOptions)}
            label={t('terms')}
            placeholder={t('select_term')}
            filterSelectedOptions
            getOptionSelected={(option, value) => option.value === value.value}
            style={{
              width: '100%',
            }}
            multiple={undefined}
            getOptionLabel={(option) => option.label}
            className={classes.alphaInput}
            value={termsIdFilter}
            onChange={(_, value) => {
              setTermsIdFilter(value);
            }}
          />
        ),
        arrow: false,
        disableHoverEffect: true,
        disableClick: true,
        underline: false,
        key: 'term-options',
      } : [],
    {
      content: (
        <MultiAutoCompleteSelect
          options={mapCurrencyFilterOptions(buyCurrenciesFilterOptions)}
          noOptionsText={t('no_options')}
          label={t('buy_currencies')}
          placeholder={t('select_buy_currency')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{
            width: '100%',
          }}
          className={classes.alphaInput}
          value={buyCurrenciesFilter || []}
          onChange={(_, value) => {
            setBuyCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'buy-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={mapCurrencyFilterOptions(soldCurrenciesFilterOptions)}
          label={t('sell_currencies')}
          noOptionsText={t('no_options')}
          placeholder={t('select_sell_currency')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={classes.alphaInput}
          value={sellCurrenciesFilter || []}
          onChange={(_, value) => {
            setSellCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'sell-currency-options',
    },
    {
      content: (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="flex-end"
          gridGap={8}
        >
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              clearAllFilters();
            }}
          >
            {t('clear')}
          </ActionButton>
          <ActionButton
            onClick={() => {
              applyChanges();
              setFilterMenuOpen(null);
            }}
          >
            {t('apply')}
          </ActionButton>
        </Box>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      key: 'action-buttons',
    },
  ];

  const filterCount = buyCurrencies?.length + sellCurrencies?.length + (termsIds ?? []).length;

  const header = (
    <p style={{ padding: '0px 20px', margin: '8px auto' }}>
      {t('filters')}
      {' '}
      {filterCount ? `(${filterCount})` : ''}
    </p>
  );

  return (
    <div className={classes.filterButtonContainer}>
      <APFilterMenu
        onButtonClick={() => {
          logEvent({ action: 'Clicked on Forwards: Position valuation by currency pair filter' });
        }}
        open={Boolean(filterMenuOpen)}
        anchorEl={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        header={header}
        // @ts-ignore
        icon={faSlidersH}
        buttonTitle={(
          <>
            {t('filters')}
            {filterCount ? `(${filterCount})` : ''}
          </>
        )}
        key="filter-button"
        menuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          className: classes.menuCustom,
        }}
      >
        {menuItems.filter((i) => i !== undefined).map((item) => (
          <MenuDropdownItem
            key={item.key}
            setOpen={setFilterMenuOpen}
          >
            {item}
          </MenuDropdownItem>
        ))}
      </APFilterMenu>
    </div>
  );
};

export default MtmFilter;
