import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import queryString from 'querystring';
import t from 'utils/translationHelper';

import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useLog from '../../../hooks/useLog';
import { TProgramData } from '../../../services/HedgingPrograms/hedgingPrograms.interfaces';
import HedgingProgramsService from '../../../services/HedgingPrograms/hedgingPrograms.service';
import browserHistory from '../../../services/history/browserHistory';

import Dropdown from './Dropdown/Dropdown';

export interface IProps {
  programId?: string;
  setProgramId: Dispatch<SetStateAction<string | undefined>>;
}

const HedgingProgramsSelect: React.FC<IProps> = (props: IProps) => {
  const { programId, setProgramId } = props;
  const [options, setOptions] = useState<TProgramData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);



  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const retrieveProgramOptions = async () => {
    try {
      setLoading(true);
      const programData = await HedgingProgramsService.getHedgingProgramsData();
      setOptions(programData);
      const filterParams = browserHistory.location.search.substring(1);
      const filtersFromParams = queryString.parse(filterParams);
      if (filtersFromParams.id) {
        setProgramId(String(filtersFromParams.id));
      } else if (programData.length > 0) {
        setProgramId(programData[0].programId);
      }
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_hedging_programs'));
      logError({ action: 'Error loading hedging programs', error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    retrieveProgramOptions();
  }, []);

  return (
    <div style={{ maxWidth: 'fit-content' }}>
      <Dropdown
        data={options}
        handleChange={(e) => setProgramId(e.target.value)}
        selectValue={programId}
        testId="hedging-programs-dropdown"
        isLoading={loading}
      />
    </div>
  );
};

export default HedgingProgramsSelect;
