import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    alignItems: 'center',
  },
  imageHolder: {
    display: 'flex',
    overflow: 'hidden',
    marginRight: '8px',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
  },
  friendlyName: {},
  verticalLine: {
    height: '19px',
    position: 'relative',
    margin: '0 8px',
    borderRight: '1px solid #70707026',
  },
  balance: {
    color: '#34343473',
  },
}), { name: 'InterAccountTransferDropdownItem' });
export default useStyles;
