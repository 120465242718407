/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import BeneficiaryInfoDrawer from 'domain/Transactions/BatchSummary/PaymentSummary/BeneficiaryInfoDrawer/BeneficiaryInfoDrawer';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useAuthorization from 'hooks/useAuthorization';
import useDownloadFile from 'hooks/useDownloadFile';
import useLog from 'hooks/useLog';
import { UserRole } from 'models/user';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BeneficiaryService, { BeneficiariesService } from 'services/Beneficiaries/beneficiaries.service';
import browserHistory from 'services/history/browserHistory';
import { TStore } from 'store';
import { initiateBeneficiary } from 'store/authy/actions';
import { actions } from 'store/authy/reducer';
import { TBeneficariesStore } from 'store/beneficiaries/beneficiaries.reducer';
import t from 'utils/translationHelper';

import {
  BeneficiaryBatchStatus,
  BeneficiaryBatchSummaryDto, BeneficiaryDto, BeneficiaryStatus,
} from '@alpha/bene-dtos';
import { Alert } from '@alpha/ui-lib/ui/Alert/Alert';
import { ActionButton } from '@alpha/ui-lib/ui/button/ActionButton';
import { Box } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import APPagination from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination';
import { SingleStat } from '@alpha/ui-lib/ui/StatSummary/SingleStat';
import { IStyledGenericTableProps, StyledGenericTable, StyledScrollableTable } from '@alpha/ui-lib/ui/table';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowLeft, faCheckCircle,
  faCircleXmark, faDoNotEnter, faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useSwitchAccount from '../../../hooks/useSwitchAccount';
import routes from '../../../routes.path';

import useStyles from './BatchBeneficariesStyles.styles';
import BatchBeneficiaryActionDropDown from './BatchBeneficaryActionDropDown';
import DeleteBeneficiaryBatchModal from './DeleteBeneficiaryBatchModal';
import RightAlignedCta from './RightAlignedCTA';
import useBeneBatchSummary from './useBeneBatchSummary';

const BatchBeneficiariesSummary: React.FC = () => {
  const styles = useStyles();
  const [batchBeneSummary, setBatchBeneSummary] = useState<BeneficiaryBatchSummaryDto>({
    beneficiaries: [],
    noOfRecords: 0,
    processedRecords: 0,
    friendlyId: '',
    fileName: '',
    id: '',
    fileType: '',
    uploadDate: '',
    status: BeneficiaryBatchStatus.VALIDATING,
    validRecords: 0,
    invalidRecords: 0,
    erroredRecords: 0,
    uploadedBy: '',
    uploadedById: '',
  });
  const { id } = useParams();
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState<string>();
  const [openDeleteBeneficiaryBatch, setOpenDeleteBeneficiaryBatch] = useState(false);
  const [validBenes, setValidBenes] = useState<BeneficiaryDto[]>([]);
  const [warningBenes, setWarningBenes] = useState<BeneficiaryDto[]>([]);
  const [erroredBenes, setErroredBenes] = useState<BeneficiaryDto[]>([]);
  const beneBatchSummaryFields = useBeneBatchSummary(batchBeneSummary);
  const beneApproverOwn = useAuthorization([[UserRole.BENEFICAIRY_APPROVER_OWN]]);
  const beneInputter = useAuthorization([[UserRole.BENEFICIARY_INPUTTER]]).authorized;

  const { homePageUrl } = useSwitchAccount();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];
  const sb = useAlphaSnackbar();
  const { logError } = useLog();
  const { downloadFile } = useDownloadFile();
  const dispatch = useDispatch();
  const beneficiaries = useSelector<TStore, TBeneficariesStore>((state) => state.beneficiaries);

  const [validPageNumber, setValidPageNumber] = useState(0);
  const [warningPageNumber, setWarningPageNumber] = useState(0);
  const [invalidPageNumber, setInvalidPageNumber] = useState(0);

  const totalValidPages = Math.ceil(validBenes?.length / 10);
  const totalWarningPages = Math.ceil(warningBenes?.length / 10);
  const totalInValidPages = Math.ceil(erroredBenes?.length / 10);

  const currentValidData = validBenes.slice(validPageNumber * 10, (validPageNumber + 1) * 10);
  const currentWarningData = warningBenes.slice(warningPageNumber * 10, (warningPageNumber + 1) * 10);
  const currentInValidData = erroredBenes.slice(invalidPageNumber * 10, (invalidPageNumber + 1) * 10);

  const hasValidPrev = () => validPageNumber > 0;
  const hasValidNext = () => (validPageNumber + 1) < totalValidPages;
  const hasWarningPrev = () => warningPageNumber > 0;
  const hasWarningNext = () => (warningPageNumber + 1) < totalWarningPages;
  const hasInvalidPrev = () => invalidPageNumber > 0;
  const hasInvalidNext = () => (invalidPageNumber + 1) < totalInValidPages;

  const currentValidItems = hasValidNext() ? (validPageNumber + 1) * 10 : validBenes.length;
  const currentWarningItems = hasWarningNext() ? (warningPageNumber + 1) * 10 : warningBenes.length;
  const currentErroredItems = hasInvalidNext() ? (invalidPageNumber + 1) * 10 : erroredBenes.length;

  const handleNext = (type: string) => {
    if (type === 'valid') {
      setValidPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
    if (type === 'warning') {
      setWarningPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
    if (type === 'invalid') {
      setInvalidPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const handlePrev = (type: string) => {
    if (type === 'valid') {
      setValidPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
    if (type === 'warning') {
      setWarningPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
    if (type === 'invalid') {
      setInvalidPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const validColumns: IStyledGenericTableProps['columns'] = [{
    header: t('beneficiary_name'),
    accessor: 'name',
  },
  {
    header: t('friendly_name'),
    accessor: 'friendlyName',
  },
  {
    header: t('beneficiary_type'),
    accessor: 'type',
  },
  {
    header: t('country'),
    accessor: 'country',
  },
  {
    header: t('currency'),
    accessor: 'currency',
  },
  {
    header: t('bank_name'),
    accessor: 'bankName',
  },
  {
    header: t('bank_details'),
    accessor: 'bankDetails',
  },
  {
    header: t('actions'),
    accessor: 'actions',
    align: 'center',
  },
  ] as IStyledGenericTableProps['columns'];

  const warningColumns: IStyledGenericTableProps['columns'] = [
    {
      header: t('row'),
      accessor: 'fileRow',
      width: 100,
    },
    {
      header: t('beneficiary_name'),
      accessor: 'name',
      width: 320,
    },
    {
      header: t('warning'),
      accessor: 'errors',
      width: 350,
    },
    {
      header: t('friendly_name'),
      accessor: 'friendlyName',
      width: 220,
    },
    {
      header: t('beneficiary_type'),
      accessor: 'type',
      width: 180,
    },
    {
      header: t('country'),
      accessor: 'countryCode',
      width: 180,
    },
    {
      header: t('currency'),
      accessor: 'currencyCode',
      width: 180,
    },
    {
      header: t('bank_name'),
      accessor: 'bankName',
      width: 250,
    },
    {
      header: t('bank_details'),
      accessor: 'accountNumber',
      width: 250,
    },
    {
      header: t('actions'),
      accessor: 'actions',
      width: 100,
    },
  ] as IStyledGenericTableProps['columns'];

  const rejectedColumns: IStyledGenericTableProps['columns'] = [
    {
      header: t('row'),
      accessor: 'fileRow',
      width: 100,
    },
    {
      header: t('beneficiary_name'),
      accessor: 'name',
      width: 200,
    },
    {
      header: t('errors'),
      accessor: 'errors',
      width: 350,
    },
    {
      header: t('friendly_name'),
      accessor: 'friendlyName',
      width: 220,
    },
    {
      header: t('beneficiary_type'),
      accessor: 'type',
      width: 180,
    },
    {
      header: t('country'),
      accessor: 'countryCode',
      width: 180,
    },
    {
      header: t('currency'),
      accessor: 'currencyCode',
      width: 180,
    },
    {
      header: t('bank_name'),
      accessor: 'bankName',
      width: 250,
    },
    {
      header: t('bank_details'),
      accessor: 'accountNumber',
      width: 250,
    },
  ] as IStyledGenericTableProps['columns'];

  const handleDelete = async (beneficiaryId: string) => {
    try {
      await BeneficiariesService.hardDeleteBeneficiary(
        beneficiaryId,
      );
      loadBatchRecord();
    } catch (e) {
      sb.trigger(error?.message || t('error_deleting_bene'));
      logError({ action: 'Error deleting batch bene', error });
    }
  };

  const handleDeleteBatch = async () => {
    try {
      await BeneficiariesService.deleteBatchBeneficiary(
        id,
      );
      browserHistory.push(`${routes.beneficiaries}?tab=batch`);
    } catch (error) {
      sb.trigger(error?.message || t('error_deleting_bene_batch'));
      logError({ action: 'Error deleting batch bene upload', error });
    }
  };

  const handleOpenDrawer = async (beneficiaryId: string) => {
    setSelectedBeneficiaryId(beneficiaryId);
  };

  const submitValidBeneficiaries = async () => {
    try {
      if (beneApproverOwn.authorized) {
        // handleMFA
        dispatch(initiateBeneficiary({
          approverOwn: true, batchId: id, submission: true, type: 'BENEFICIARY_BATCH_UPLOAD',
        }));
      } else {
        await BeneficiariesService.submitBeneficiaryBatch(
          id,
        );
        dispatch(actions.updateStatus('SUCCESS'));
        sb.trigger(t('successfully_submitted_batch_upload'), 'success');
      }
      browserHistory.push(`${routes.beneficiaries}?tab=batch`);
    } catch (error) {
      sb.trigger(error?.message || t('error_submit_bene_batch'));
      logError({ action: 'Error submitting valid beneficiaries', error });
    }
  };

  const handleMarkAsValid = async (beneficiaryId: string) => {
    try {
      const result = await BeneficiariesService.putUpdateBatchBeneStatus(
        id,
        beneficiaryId,
        BeneficiaryStatus.VALIDATED,
      );
      if (result === 200) {
        loadBatchRecord();
      }
    } catch (error) {
      sb.trigger(error?.message || t('error_marking_as_valid'));
      logError({ action: 'Error marking as valid', error });
    }
  };

  const loadBatchRecord = async () => {
    try {
      const batchSummary = await BeneficiaryService.getBatchBeneSummary(id);
      setBatchBeneSummary(batchSummary);
    } catch (error) {
      if (error?.response?.status === 404) {
        browserHistory.push(`${routes.beneficiaries}?tab=batch`);
      }
      logError({ action: 'Error loading upload summary', error });
    }
  };

  const downloadBatchFile = async (batchId: string) => {
    try {
      const url = await BeneficiariesService.getBatchBeneFileUrl(batchId);
      await downloadFile(url, 'beneficiary batch file');
    } catch (error) {
      sb.trigger(t('error_downloading_bene_file'));
      logError({ action: 'Error getting bene batch url ', error });
    }
  };

  const mapPartyAndType = (party: string, type: string) => {
    let beneType;
    let beneParty;
    if (type === 'INDIVIDUAL') {
      beneType = `${t('Individual')}`;
    }
    if (type === 'ENTITY') {
      beneType = `${t('Business')}`;
    }
    if (party === 'First') {
      beneParty = `${t('1party')}`;
    }
    if (party === 'Third') {
      beneParty = `${t('3party')}`;
    }
    const result = (
      <span>
        <span style={{ fontWeight: 400, marginRight: '2px' }}>
          {beneParty}
        </span>
        |
        <span style={{ fontWeight: 200 }}>
          {' '}
          {beneType}
        </span>
      </span>
    );
    return result;
  };

  const validBenesTableData = () => currentValidData?.map((bene) => ({
    ...bene,
    name: <span>{bene.name}</span>,
    friendlyName: <span>{bene.friendlyName}</span>,
    type: bene.party && bene.type ? mapPartyAndType(bene.party, bene.type) : <></>,
    country: (
      <span>
        <span style={{ marginRight: '2px' }}>
          <Flag code={bene.countryCode} size="sm" />
        </span>
        {bene.countryCode}
      </span>),
    currency: (
      <span>
        <span style={{ marginRight: '2px' }}>
          <Flag code={bene.currencyCode} size="sm" />
        </span>
        {bene.currencyCode}
      </span>),
    bankName: <span>{bene.bankName}</span>,
    bankDetails: <span>{bene.accountNumber}</span>,
    actions: batchBeneSummary.status !== BeneficiaryBatchStatus.COMPLETED && beneInputter
      ? (
        <BatchBeneficiaryActionDropDown
          beneficiary={bene}
          handleDelete={handleDelete}
        />
      )
      : <></>,
  }));

  useEffect(() => {
    if (beneficiaries) {
      loadBatchRecord();
    }
  }, [beneficiaries?.batchDetails]);

  useEffect(() => {
    if (batchBeneSummary) {
      setValidBenes(batchBeneSummary?.beneficiaries.filter((b) => b.status === BeneficiaryStatus.VALIDATED
        || b.status === BeneficiaryStatus.SUBMITTED || b.status === BeneficiaryStatus.CLIENT_APPROVED));

      const warningBeneficiaries = batchBeneSummary?.beneficiaries.filter((b) => b.status === BeneficiaryStatus.WARNING);
      const decoratedWarningBeneficiaries = warningBeneficiaries.map((wb) => ({
        ...wb,
        countryCode: (
          <span>
            <span style={{ marginRight: '2px' }}>
              { wb.countryCode !== '' ? <Flag code={wb.countryCode} size="sm" /> : null}
            </span>
            {wb.countryCode}
          </span>),
        currencyCode: (
          <span>
            <span style={{ marginRight: '2px' }}>
              { wb.currencyCode !== '' ? <Flag code={wb.currencyCode} size="sm" /> : null}
            </span>
            {wb.currencyCode}
          </span>),
        type: wb.party && wb.type ? mapPartyAndType(wb.party, wb.type) : null,
        actions: batchBeneSummary.status !== BeneficiaryBatchStatus.COMPLETED && beneInputter
          ? (
            <BatchBeneficiaryActionDropDown
              beneficiary={wb}
              handleMarkAsValid={handleMarkAsValid}
              handleOpenDrawer={handleOpenDrawer}
              handleDelete={handleDelete}
            />
          )
          : <></>,
      }));
      setWarningBenes(decoratedWarningBeneficiaries);

      const erroredBeneficiaries = batchBeneSummary?.beneficiaries.filter((b) => b.status === BeneficiaryStatus.INVALID);
      const decoratedErroredBeneficiaries = erroredBeneficiaries.map((eb) => ({
        ...eb,
        countryCode: (
          <span>
            <span style={{ marginRight: '2px' }}>
              { eb.countryCode !== '' ? <Flag code={eb.countryCode} size="sm" /> : null}
            </span>
            {eb.countryCode}
          </span>),
        currencyCode: (
          <span>
            <span style={{ marginRight: '2px' }}>
              { eb.currencyCode !== '' ? <Flag code={eb.currencyCode} size="sm" /> : null}
            </span>
            {eb.currencyCode}
          </span>),
        type: eb.party && eb.type ? mapPartyAndType(eb.party, eb.type) : null,
      }));

      setErroredBenes(decoratedErroredBeneficiaries);
    }
  }, [batchBeneSummary]);

  return (
    <APMainLayout
      sticky
      height="165px"
      data-id="beneficiaries"
      title={(
        <>
          <ActionButton
            startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
            testId="batch-summary-back-btn"
            size="large"
            onClick={() => browserHistory.push(`${routes.beneficiaries}?tab=batch`)}
            style={{
              minWidth: '33px', minHeight: '33px', height: '33px', marginTop: '-5px', background: '#fdfdfd', color: '#212529',
            }}
          />
          {`${t('upload_summary')}`}
        </>
      )}
      breadCrumbs={breadcrumbs}
      rightAlignedCta={(
        <RightAlignedCta
          batchBeneficiarySummary={batchBeneSummary}
          submitValidBeneficiaries={submitValidBeneficiaries}
          handleDeleteUpload={() => { setOpenDeleteBeneficiaryBatch(true); }}
          hideSubmit={batchBeneSummary.validRecords === 0 || batchBeneSummary?.status === BeneficiaryBatchStatus.COMPLETED}
        />
      )}
    >
      <Box className={styles.root}>
        <Box className={styles.summary}>
          <Box className={styles.title}>
            <FontAwesomeIcon
              icon={faCircleInfo as IconProp}
              className={styles.icon}
            />
            {t('summary')}
            {' '}
            |
            <span style={{ fontWeight: 300, marginLeft: '10px' }}>
              {batchBeneSummary?.fileName}
            </span>
            <ActionButton
              startIcon={<FontAwesomeIcon icon={faDownload} className={styles.downloadIcon} />}
              testId="batch-file-download-btn"
              size="small"
              onClick={() => {
                downloadBatchFile(id);
              }}
              style={{
                minWidth: '33px', minHeight: '33px', height: '33px', marginTop: '-5px', background: '#fdfdfd', color: '#212529',
              }}
            />
          </Box>
          <span style={{ paddingLeft: '20px', color: '#999999' }}>{t('summary_info_text')}</span>
          <div className={styles.statsContainer}>
            {beneBatchSummaryFields?.map((b) => (
              <SingleStat
                statHeader={<div style={{ minWidth: '120px' }}>{b.header}</div>}
                value={b.value}
              />
            ))}
          </div>
        </Box>
        <Box className={styles.panel}>
          <Box className={styles.title}>
            <FontAwesomeIcon
              className={styles.tickIcon}
              icon={faCheckCircle as IconProp}
            />
            { t('valid_benes') }
            {' '}
            (
            {validBenes.length}
            )
          </Box>
          <span style={{ paddingLeft: '20px', color: '#999999' }}>{t('valid_info')}</span>
          <div className={styles.tableWrapper}>
            {currentValidData?.length > 0 ? <StyledGenericTable columns={validColumns} data={validBenesTableData()} testId="batch-valid-benes" />
              : (
                <EmptyTable
                  title={t('no_valid_benes')}
                  icon={clipboardCross}
                  style={{ transform: 'none', position: 'unset' }}
                />
              )}
          </div>
          {currentValidData?.length
            ? (
              <Box
                display="flex"
                flexDirection="rows"
                gridGap={16}
                justifyContent="space-between"
                marginTop="10px"
                marginLeft="20px"
                marginRight="20px"
              >
                <span style={
              {
                fontWeight: 400,
                lineHeight: '26px',
                color: '#999999',
              }
            }
                >
                  {t('showing')}
                  {' '}
                  {currentValidItems}
                  {' '}
                  {t('of')}
                  {' '}
                  {validBenes?.length}
                </span>
                <APPagination
                  hasPrevious={hasValidPrev()}
                  hasNext={hasValidNext()}
                  handleNext={() => handleNext('valid')}
                  handlePrevious={() => handlePrev('valid')}
                  nextString={t('next^')}
                  prevString={t('prev^')}
                />
              </Box>
            ) : null}
        </Box>
        <Box className={styles.panel}>
          <Box className={styles.title}>
            <FontAwesomeIcon
              className={styles.warningIcon}
              icon={faDoNotEnter as IconProp}
            />
            {t('Warnings')}
            {' '}
            (
            {warningBenes.length}
            )
          </Box>
          <span style={{ paddingLeft: '20px', color: '#999999' }}>{t('warnings_info')}</span>
          {currentWarningData?.length > 0 && warningBenes ? (
            <>
              {' '}
              <div className={styles.alert}>
                <Alert
                  variant="warning"
                  subText={t('Please_note')}
                  text={t('alert_warning')}
                />
              </div>
              <div className={styles.tableWrapper}>
                <StyledScrollableTable
                  testId="warning-table"
                  columns={warningColumns}
                  data={currentWarningData}
                  sectionWidths={{
                    left: 2,
                    middle: warningColumns.length - 3,
                    right: 1,
                  }}
                  characterLength={30}
                  sidebarWidth={60}
                />
              </div>
            </>
          ) : (
            <EmptyTable
              title={t('no_warning_benes')}
              icon={clipboardCross}
              style={{ transform: 'none', position: 'unset' }}
            />
          )}
          {currentWarningData?.length
            ? (
              <Box
                display="flex"
                flexDirection="rows"
                gridGap={16}
                justifyContent="space-between"
                marginTop="10px"
                marginLeft="20px"
                marginRight="20px"
              >
                <span style={
              {
                fontWeight: 400,
                lineHeight: '26px',
                color: '#999999',
              }
            }
                >
                  {t('showing')}
                  {' '}
                  {currentWarningItems}
                  {' '}
                  {t('of')}
                  {' '}
                  {warningBenes?.length}
                </span>
                <APPagination
                  hasPrevious={hasWarningPrev()}
                  hasNext={hasWarningNext()}
                  handleNext={() => handleNext('warning')}
                  handlePrevious={() => handlePrev('warning')}
                  nextString={t('next^')}
                  prevString={t('prev^')}
                />
              </Box>
            ) : null}
        </Box>
        <Box className={styles.panel}>
          <Box className={styles.title}>
            <FontAwesomeIcon
              className={styles.rejectedIcon}
              icon={faCircleXmark as IconProp}
            />
            {t('rejected_benes')}
            {' '}
            (
            {erroredBenes.length}
            )
          </Box>
          <span style={{ paddingLeft: '20px', color: '#999999' }}>{t('rejected_info')}</span>
          {currentInValidData?.length > 0 ? (
            <>
              {' '}
              <div className={styles.alert}>
                <Alert
                  variant="error"
                  subText={t('Please_note')}
                  text={t('alert_rejected')}
                />
              </div>
              <div className={styles.tableWrapper}>
                <StyledScrollableTable
                  testId="error-table"
                  columns={rejectedColumns}
                  data={currentInValidData}
                  sectionWidths={{
                    left: 2,
                    middle: rejectedColumns.length,
                    right: 0,
                  }}
                  characterLength={30}
                  sidebarWidth={60}
                />
              </div>
            </>
          ) : (
            <EmptyTable
              title={t('no_rejected_benes')}
              icon={clipboardCross}
              style={{ transform: 'none', position: 'unset' }}
            />
          )}
          {currentInValidData?.length
            ? (
              <Box
                display="flex"
                flexDirection="rows"
                gridGap={16}
                justifyContent="space-between"
                marginTop="10px"
                marginLeft="20px"
                marginRight="20px"
              >
                <span style={
              {
                fontWeight: 400,
                lineHeight: '26px',
                color: '#999999',
              }
            }
                >
                  {t('showing')}
                  {' '}
                  {currentErroredItems}
                  {' '}
                  {t('of')}
                  {' '}
                  {erroredBenes?.length}
                </span>
                <APPagination
                  hasPrevious={hasInvalidPrev()}
                  hasNext={hasInvalidNext()}
                  handleNext={() => { handleNext('invalid'); }}
                  handlePrevious={() => { handlePrev('invalid'); }}
                  nextString={t('next^')}
                  prevString={t('prev^')}
                />
              </Box>
            ) : null}
        </Box>
      </Box>
      <BeneficiaryInfoDrawer
        onClose={() => setSelectedBeneficiaryId(undefined)}
        open={Boolean(selectedBeneficiaryId)}
        setPaymentSummaryBeneficiaryId={setSelectedBeneficiaryId}
        paymentSummaryBeneficiaryId={selectedBeneficiaryId}
      />
      <DeleteBeneficiaryBatchModal
        beneBatchName={batchBeneSummary.fileName}
        beneBatchSummaryFields={beneBatchSummaryFields!}
        open={openDeleteBeneficiaryBatch}
        handleClose={() => setOpenDeleteBeneficiaryBatch(false)}
        handleDeleteBatch={handleDeleteBatch}
      />
    </APMainLayout>

  );
};

export default BatchBeneficiariesSummary;
