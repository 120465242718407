import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    '& > div > div:first-of-type': {
      paddingTop: '70px',
    },
    '& .stepper': {
      '& h6': {
        color: '#a0a0a0',
        fontSize: '16px',
        marginTop: '16px',
      },
      '& .active': {
        color: 'black',
      },
    },
  },
}), { name: 'CreateDrawdownDrawer' });

export default useStyles;
