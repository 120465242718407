import React, { memo } from 'react';

import { PaymentStatus } from '@alpha/payments-dtos';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getBatchStatusColorVariant, getClientFriendlyBatchStatus } from '../../utils/batchStatus.helpers';

import { useStyles } from './Title.styles';

interface IProps {
  title: string;
  batchFileName: string;
  check?: boolean;
  batchStatus?: PaymentStatus | string;
}

const Title: React.FC<IProps> = (props: IProps) => {
  const {
    title, batchFileName, check = false, batchStatus,
  } = props;
  const classes = useStyles();

  return (
    <span className={classes.wrapper}>
      {check && <FontAwesomeIcon icon={faCheckCircle as IconProp} />}
      <span className="bold">
        {title}
      </span>
      {' '}
      -
      {' '}
      {batchFileName}
      {batchStatus
      && <StyledDivider vertical />}
      {batchStatus
      && (
      <Status
        variant={getBatchStatusColorVariant(batchStatus)}
      >
        {getClientFriendlyBatchStatus(batchStatus)}
      </Status>
      )}
    </span>
  );
};

Title.defaultProps = {
  check: false,
  batchStatus: undefined,
};

export default memo(Title);
