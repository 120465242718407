import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import t from 'utils/translationHelper';

import { DrawdownDto, TradeDto } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Collapse } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from '../../../../../../components/Alert';
import useAuthorization from '../../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../../models/user';
import * as authyActions from '../../../../../../store/authy/actions';
import formatIsoDate from '../../../../../../utils/formatIsoDate';

import useStyles from './PadEligible.styles';

interface IProps {
  padEligibleItem: TradeDto | DrawdownDto;
  setAuthoriseTradePadOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  drawerStyle: boolean;
  isTrade: boolean;
}

const PadEligible: React.FC<IProps> = ({
  padEligibleItem,
  setAuthoriseTradePadOpen,
  drawerStyle,
  isTrade,
}: IProps) => {
  const classes = useStyles();
  const { authorized: authorisedPadApprover } = useAuthorization([[UserRole.PAD_APPROVER]]);
  const dispatch = useDispatch();

  const [collapsed, setCollasped] = useState<boolean>(true);

  const authoriseDrawdownPad = (drawdown: DrawdownDto) => {
    dispatch(
      authyActions.initiateDrawdownPADApprove({
        type: 'DRAWDOWN_PAD_APPROVE',
        drawdownId: drawdown.id,
        approverOwn: false, // PAD approval does not care
        paymentIds: [], // PAD approval does not care
      }),
    );
  };

  if (!padEligibleItem.padApproved) {
    return (
      <div className={drawerStyle ? classes.drawerContainer : classes.container}>
        <div className={classes.titleAndButton}>
          <div className={classes.titlesContainer}>
            <div className={classes.title}>
              <div className={classes.greyPad}>P</div>
              <div className={classes.greyTitle}>{t('pad_eligible')}</div>
            </div>
            <div className={classes.greySubtitle}>
              {isTrade ? t('this_trade_is_eligible_for_pad_settlement') : ` ${t('this_trade_is_drawdown_for_pad_settlement')} `}
              {' '}
              {t('funds_will_be_drawn_from_approved_account_on_value_date')}
              {' '}
              {t('to_arrange_click_button_complete_authorisation')}
            </div>
          </div>
          <APTooltip title={!authorisedPadApprover ? t('you_do_not_have_permission_to_authorise_pad') : ''}>
            <div className={drawerStyle
              ? classes.drawerBtnContainer : classes.authoriseBtnContainer}
            >
              <ActionButton
                className={classes.authoriseBtn}
                disabled={!authorisedPadApprover}
                onClick={() => {
                  if (setAuthoriseTradePadOpen && isTrade) {
                    setAuthoriseTradePadOpen(true);
                  }
                  if (!isTrade && padEligibleItem) {
                    authoriseDrawdownPad(padEligibleItem as DrawdownDto);
                  }
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faLockKeyhole as IconProp} color="#FFFFFF" />
                  <span className={classes.buttonText}>{t('authorise_pad')}</span>
                </div>
              </ActionButton>
            </div>
          </APTooltip>
        </div>
        <Collapse in={!collapsed} style={{ padding: '0 30px 0 30px' }}>
          <div className={classes.alertContainer}>
            <Alert
              variant="info"
              text={t('2fa_required_before_pad')}
            />
          </div>
        </Collapse>
      </div>
    );
  }

  if (padEligibleItem.padApproved && padEligibleItem.padSettled !== false) {
    return (
      <div className={drawerStyle ? classes.drawerGreenContainer : classes.greenContainer}>
        <div className={classes.authorisedInfo}>
          <div className={drawerStyle ? classes.drawerFlexTitles : classes.flexTitlesContainer}>

            <div className={classes.title}>
              <div className={classes.greenPad}>P</div>
              <div className={classes.greenTitle}>{t('pad_authorised')}</div>
            </div>
            <div className={classes.greenSubtitle}>
              {t('this')}
              {isTrade ? ` ${t('trade~')} ` : ` ${t('drawdown~')} `}
              {t('has_been_authorised_for_pad_collection')}
            </div>
          </div>
          <div className={classes.dividerContainer}>
            <div className={classes.divider} />
          </div>
          <div className={[drawerStyle ? classes.drawerApproverContainer : classes.approverContainer,
          collapsed ? classes.collapsedContainer : '',
          ].join(' ')}
          >
            <div className={classes.approverGreen}>{t('approver')}</div>
            <div className={classes.approverInfo}>
              <span className={classes.namePadding}>{padEligibleItem.padApprovedBy || '-'}</span>
              {formatIsoDate(padEligibleItem.padApprovedDate) || '-'}
            </div>
          </div>
        </div>

        {(padEligibleItem.padSettled) && (
          <div className={
            [drawerStyle ? classes.drawerApproverContainer : classes.approverContainer,
            collapsed ? classes.collapsedContainer : '',
            ].join(' ')
          }
          >
            <Collapse in={!collapsed}>
              <div className={drawerStyle ? classes.drawerTitle : classes.title}>
                <div className={classes.approverGreen}>{t('pad_collected')}</div>
                <div className={
                  drawerStyle ? classes.drawerCollected : classes.collected
                }
                >
                  {t('collected')}
                </div>
              </div>
              <div className={classes.approverInfo}>
                {t('pad_collection_for_this')}
                {isTrade ? ` ${t('trade~')} ` : ` ${t('drawdown~')} `}
                {t('was_successful')}
              </div>
            </Collapse>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={
      drawerStyle ? classes.drawerNotCollectedContainer : classes.notCollectedContainer
    }
    >
      <div className={drawerStyle ? classes.drawerFlexTitles : classes.flexTitlesContainer}>
        <div className={classes.title}>
          {collapseButton}
          <div className={classes.redPad}>P</div>
          <div className={classes.redTitle}>{t('pad_collection_unsuccessful')}</div>
        </div>
        <Collapse in={!collapsed}>
          <div className={classes.notCollectedSubtitle}>
            {t('this')}
            {isTrade ? ` ${t('trade~')} ` : ` ${t('drawdown~')} `}
            {t('was_authorised_for_pad_collection')}
            {' '}
            {t('but_we_were_unable_to_pull_funds_from_bank')}
          </div>
        </Collapse>
      </div>

      <div className={[classes.approverContainer, collapsed ? classes.collapsedContainer : ''].join(' ')}>
        <Collapse in={!collapsed}>
          <div className={classes.notCollectedApproverTitle}>{t('approver')}</div>
          <div className={classes.notCollectedApproverInfo}>
            <span className={classes.namePadding}>{padEligibleItem.padApprovedBy || '-'}</span>
            {formatIsoDate(padEligibleItem.padApprovedDate) || '-'}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default PadEligible;
