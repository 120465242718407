import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    drawerBody: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
    },
  }),
  { name: 'BeneInfoDrawer' },
);

export default useStyles;
