import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    container: {
      width: '673px',
      height: '580px',
    },
    body: {
      padding: '24px 0',
      '& h6': {
        color: '#222222',
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: '24px',
      },
      '& .errorMessage': {
        fontWeight: 'bold',
        paddingBottom: '20px',
      },
    },
    buttonsContainer: {
      textAlign: 'right',
    },
    amountContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    fixedCcyDropdown: {
      backgroundColor: '#F7F7F7',
      height: '44px',
      width: '148px',
      borderRadius: '5px',
      marginTop: '5px',
      '& .MuiSelect-select.MuiSelect-select': {
        background: '#F7F7F7',
        paddingLeft: '16px',
      },
    },
    fixedAmountStyle: {
      width: '445px',
      height: '50px',
    },
    modalHeader: {
      fontSize: 16,
      padding: '12px 24px 24px 24px',
    },
    modalWrapper: {
      margin: '0px -20px',
    },
  }),
  { name: 'UploadTradeModal' },
);

export default useStyles;
