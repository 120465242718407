import { useEffect, useState } from 'react';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import useQueryString from 'hooks/useQueryString';
import { TCurrencyTab } from 'models/currencyAccounts';
import CurrencyAccountsService from 'services/CurrencyAccounts/currencyAccounts.service';

const useCurrencyAccount = (tabShowLimit? = 6) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [allTabs, setAllTabs] = useState<TCurrencyTab[]>([]);
  const [tabs, setTabs] = useState<TCurrencyTab[]>([]);
  const [dropdownItems, setDropdownItems] = useState<TCurrencyTab[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeCurrency, setActiveCurrency] = useQueryString('currency', '');
  const { logError } = useLog();

  const sb = useAlphaSnackbar();

  useEffect(() => {
    populateCurrencyTabs();
  }, []);

  const populateCurrencyTabs = async () => {
    try {
      const allCurrencies = await CurrencyAccountsService.getCurrencyTabs();
      setAllTabs(allCurrencies);
      setTabs(allCurrencies.slice(0, tabShowLimit));
      setDropdownItems(allCurrencies.slice(tabShowLimit, allCurrencies.length));

      if (activeCurrency) {
        handleSetSelectedCurrencyCode(activeCurrency);
      }
    } catch (error) {
      sb.trigger(error?.message || 'There was an error loading your accounts');
      logError({
        action: 'Error loading currency accounts',
        error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSetSelectedCurrencyCode = (currencyCode: string) => {
    const index = allTabs.findIndex(
      (item) => item.currencyCode === currencyCode,
    );

    if (index !== -1) {
      updateTabAndDropdown(currencyCode);
      handleSetSelectedTabIndex(index);
    }
  };

  const handleSetSelectedTabIndex = (tabIndex: number) => {
    setActiveCurrency(allTabs[tabIndex].currencyCode);
  };

  const updateTabAndDropdown = (selectedCurrency: string) => {
    if (!selectedCurrency) return;
    const isAlreadyInTabs = tabs.findIndex(
      (t) => selectedCurrency === t.currencyCode,
    );

    if (isAlreadyInTabs !== -1) {
      setSelectedTabIndex(isAlreadyInTabs);
      return;
    }

    const updatedTabs = [...tabs];
    const lastTab = updatedTabs.pop();
    const selectedItem = dropdownItems.find(
      (t) => t.currencyCode === selectedCurrency,
    );

    const removeSelectedItemInDropdown = dropdownItems.filter(
      (t) => t.currencyCode !== selectedCurrency,
    );
    if (lastTab) {
      removeSelectedItemInDropdown.unshift(lastTab);
    }
    if (selectedItem) {
      updatedTabs.push(selectedItem);
    }
    setTabs(updatedTabs);
    setDropdownItems(removeSelectedItemInDropdown);

    const reorderedTabs = [...updatedTabs, ...removeSelectedItemInDropdown];
    setAllTabs(reorderedTabs);

    const matchedTab = reorderedTabs.findIndex(
      (t) => t.currencyCode === selectedCurrency,
    );

    if (matchedTab !== -1) {
      setSelectedTabIndex(matchedTab);
    }
  };

  useEffect(() => {
    if (!activeCurrency || loading) return;
    handleSetSelectedCurrencyCode(activeCurrency);
  }, [activeCurrency, loading]);

  return {
    selectedTabIndex,
    tabs,
    loading,
    handleSetSelectedTabIndex,
    handleSetSelectedCurrencyCode,
    dropdownItems,
    allTabs,
  };
};

export default useCurrencyAccount;
