import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useLog from '../../../hooks/useLog';
import { TCurrencyResponse } from '../../../models/currencyAccounts';
import CurrencyAccountsService from '../../../services/CurrencyAccounts/currencyAccounts.service';

const useCurrencyStats = (currencyCode: string) => {
  const [stats, setStats] = useState<{response: TCurrencyResponse, currencyCode?: string}>();
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const populateCurrencyAccountInformation = useCallback(debounce(async (_currencyCode: string) => {
    try {
      setLoading(true);
      const response = await CurrencyAccountsService.getCurrencyStats(_currencyCode);
      setStats({ response, currencyCode: _currencyCode });
    } catch (error) {
      sb.trigger(error?.message || 'There was an error retrieving your currency account information');
      logError({ action: 'Error loading currency account information', error });
    } finally {
      setLoading(false);
    }
  }, 200), []);

  useEffect(() => {
    populateCurrencyAccountInformation(currencyCode);
  }, [currencyCode]);

  return {
    stats,
    loading,
  };
};

export default useCurrencyStats;
