/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { PaymentDto } from '@alpha/payments-dtos';
import { Collapse, IconButton, Typography } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Status } from '@alpha/ui-lib/ui/Status';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './RejectedPayments.styles';

interface IRejectedPaymentProps {
  payments: PaymentDto[];
  loading: boolean;
}

const RejectedPayments: React.FC<IRejectedPaymentProps> = ({
  payments, loading,
}: IRejectedPaymentProps) => {
  const styles = useStyles();


  const [paymentsTableData, setPaymentsTableData] = useState<any[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);

  const columns: IStyledGenericTableProps['columns'] = [
    {
      header: t('row'),
      accessor: 'row',
    },
    {
      header: t('description'),
      accessor: 'description',
    },
  ];

  const updateRejectedPaymentsTableData = (rejectedPayments: PaymentDto[]) => {
    const paymentsWithRow = rejectedPayments.map((p) => ({
      ...p,
      row: (p.row === undefined || p.row === null) ? 0 : p.row + 1,
    }));

    const sortedPayments = paymentsWithRow.sort(
      (a, b) => (a.row < b.row ? -1 : 0),
    );

    const resultTableData = sortedPayments.map((payment) => ({
      ...payment,
      row: payment.row || '-',
      description: payment.errors.length > 1 ? (
        <APTooltip
          placement="bottom-start"
          title={(
            <ul>
              {payment.errors.map((item) => (
                <li key={item}>
                  {item}
                </li>
              ))}
            </ul>
          )}
        >
          <strong>
            {payment.errors.length}
            {' '}
            {t('errors')}
            {' '}
            <FontAwesomeIcon icon={faInfoCircle as IconProp} size="sm" />
          </strong>
        </APTooltip>
      ) : t(payment.errors[0]),
    }));

    setPaymentsTableData(resultTableData);
  };

  useEffect(() => {
    updateRejectedPaymentsTableData(payments);
  }, [payments]);

  return (
    <div className={styles.rejectedBox}>
      {loading
        ? (
          <Loader testId="rejected-payments-loader" size={24} className={styles.loader} />
        ) : (
          <>
            <div className={styles.rejectedTitleContainer}>
              <IconButton
                onClick={() => setShowTable(!showTable)}
                className={styles.chevron}
                size="small"
              >
                <FontAwesomeIcon
                  className={showTable ? styles.iconUp : styles.iconDown}
                  icon={faChevronDown as IconProp}
                  size="sm"
                />
              </IconButton>
              <FontAwesomeIcon
                icon={faCircleExclamation as IconProp}
                className={styles.rejectedIcon}
              />
              <Typography className={styles.rejectedTitle}>{`${t('rejected_payments')} (${payments.length || '-'})`}</Typography>
              <Status variant="error" className={styles.status}>{t('requires_review')}</Status>
            </div>
            <Typography className={styles.subtitle}>
              {t('these_are_the_payments_that_have_failed_validation')}
              {' '}
              {t('and_will_not_be_processed')}
            </Typography>
            <Collapse in={showTable}>
              <div className={styles.tableContainer}>
                <StyledGenericTable
                  testId="rejected-payments-table"
                  columns={columns}
                  data={paymentsTableData}
                  className={styles.rejectedTable}
                />
              </div>
            </Collapse>
          </>
        )}
    </div>
  );
};

export default RejectedPayments;
