import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    transition: '0.5s ease in out',
    opacity: 1,
    '& .assetImage': {
      width: '149px', height: '305px', marginBottom: '16px',
    },
    '& .assetTitle': {
      color: 'white', fontWeight: 100, fontSize: '18px', marginBottom: '16px',
    },
    '& .marginRight': {
      marginRight: '8px',
    },
  },
  fullOpacity: {
    opacity: 1,
  },
  title: {
    fontWeight: 200,
    fontSize: '22px',
    paddingBottom: '40px',
    color: '#FFFFFF',
    textAlign: 'left',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '26.5px 65px 184.203px 24px',
    gap: '33.8px',
    width: '463.79px',
    height: '328.5px',
    color: '#FFFFFF',
    textAlign: 'left',
  },
  content: {
    fontWeight: 400,
    fontSize: '14px',
    width: '343px',
    height: '48px',
  },
}), { name: 'VerifyHelperScreen' });

export default useStyles;
