import React, { memo } from 'react';

import { Flag } from '@alpha/ui-lib/ui/Flag';

import { useStyles } from './NationalityPair.styles';

interface IProps {
  countryCode: string;
}

const NationalityPair: React.FC<IProps> = (props: IProps) => {
  const { countryCode } = props;
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <Flag showCodeLabel code={countryCode} />
    </span>
  );
};

export default memo(NationalityPair);
