import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
    gap: 36,
  },
}), { name: 'TermSummary' });

export default useStyles;
