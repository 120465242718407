import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 8px !important',
    width: '600px',
  },
  modalHeader: {
    padding: '12px 24px 24px 24px',
    fontWeight: 800,
    fontSize: '18px',
  },
  alert: {
    color: '#FFC107'
  },
  inputContainer: {
    padding: '15px',
  },
  footer: {
    padding: '27px 10px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(112, 112, 112, .1)',
  },
  radioButtons: {
    fontWeight: 600,
    height: "160px",
    width: "100%",
    marginLeft: "20px"
  },
  labels: {
    padding: '8px',
  },
  flagText: {
    fontFamily: 'Source Sans Pro',
    lineHeight: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#212529',
    fontSize: '14px',
    verticalAlign: 'middle',
    marginLeft: '4px',
  },
  flagCombine: {
    width: '100%',
    height: '17px',
    lineHeight: '20px',
    flexShrink: 0
  },
  flag: {
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '15px'
  }
}), { name: 'LanguagePreferenceModal' });

export default useStyles;
