import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  subHeading: {
    fontFamily: typography.fontFamilySecondary,
    fontSize: '14px',
  },
  subHeading2: {
    fontFamily: typography.fontFamilySecondary,
    fontSize: '12px',
    fontWeight: typography.fontWeightSemiBold,
    marginTop: '2.4em',
    marginBottom: '0.2em',
  },
  drawdownDetailTable: {
    padding: '10px',
    background: '#f7f7f7',
    borderRadius: '5px',

    '& .MuiTableBody-root tr': {
      borderBottom: 'none',
    },
  },
  drawdownFundingTable: {
    padding: '10px',
    background: '#f7f7f7',
    borderRadius: '5px',
    marginTop: '12px',
  },
  wireTransferGuide: {
    marginTop: '12px',
  },
  accountDetailTable: {
    background: 'transparent',
    marginTop: '12px',
    '& .MuiTableBody-root tr': {
      borderBottom: 'none',
    },
    '& .MuiTableBody-root tr td': {
      padding: '8px 0px !important',
    },
  },
}), { name: 'DrawdownConfirmationStyles' });

export default useStyles;
