import {
  Dispatch, SetStateAction,
  useEffect, useState,
} from 'react';

import { IATDraftResponse } from '@alpha/iat-dtos';

import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useLog from '../../../hooks/useLog';
import { TCurrencyAccountDrawer } from '../../../models/currencyAccounts';
import InterAccountTransferService from '../../../services/InterAccountTransfer/interAccountTransfer.service';

import { ISelectedAccount } from './AccountInfoDrawer';

const useAccountInfoDrawer = (open: boolean,
  selectedAccount: ISelectedAccount | undefined,
  handleOpenDrawer: (drawer: TCurrencyAccountDrawer) => void,
  setSelectedDraftIAT?: Dispatch<SetStateAction<IATDraftResponse | undefined>>,
  tabIndex?: number) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [pendingTransfers, setPendingTransfers] = useState<IATDraftResponse[]>([]);
  const [IATRejectModalOpen, setIATRejectModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  useEffect(() => {
    setSelectedTabIndex(tabIndex || 0);
    // if (open) {
    //   getAllPendingTransfers(selectedAccount?.account.id);
    // }
  }, [open, selectedAccount]);

  const getAllPendingTransfers = async (_currencyAccountId: string | undefined) => {
    try {
      setLoading(true);
      if (!_currencyAccountId) throw Error('Can not find currency account');
      const iatDraftResponse = await InterAccountTransferService.getStatements(
        _currencyAccountId,
      );
      setPendingTransfers(iatDraftResponse);
    } catch (e) {
      sb.trigger(e.response?.data.error
        || e.message
        || 'There was an error retrieving transfers for this account');
      logError({ action: 'Error loading transfers for account', error: e });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenIATDrawer = (iatDraftResponse: IATDraftResponse): void => {
    try {
      if (setSelectedDraftIAT) setSelectedDraftIAT(iatDraftResponse);
      handleOpenDrawer('IAT');
    } catch (error) {
      sb.trigger(error?.message || 'There was an error loading your chosen transfer');
      logError({ action: 'Error loading currency accounts', error });
    }
  };

  const handleOpenIATRejectModal = (iatDraftResponse: IATDraftResponse): void => {
    if (setSelectedDraftIAT) setSelectedDraftIAT(iatDraftResponse);
    setIATRejectModalOpen(true);
  };

  return {
    setSelectedTabIndex,
    selectedTabIndex,
    pendingTransfers,
    IATRejectModalOpen,
    loading,
    handleChange: (newValue: number) => setSelectedTabIndex(newValue),
    handleIATRejectModalClose: () => setIATRejectModalOpen(false),
    handleOpenIATDrawer,
    handleOpenIATRejectModal,
    getAllPendingTransfers,
  };
};

export default useAccountInfoDrawer;
