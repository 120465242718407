import t from 'utils/translationHelper';
import * as yup from 'yup';

import { CurrencyAccountRequest } from '@alpha/currency-accounts-dtos';

export const initialValues: CurrencyAccountRequest = {
  currencyCode: '',
  friendlyName: '',
};

export const validation = {
  currencyCode: yup.string().required(t('please_select_a_currency')),
  friendlyName: yup.string().required(t('please_specify_a_friendly_name')),
};

export default validation;
