import React, { memo, useState } from 'react';

import { Backdrop, Fade, Modal } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import AlphaWhite from '../../../../assets/alphaWhite.svg';
import BackdropLoader from '../../../Molecules/Loaders/BackdropLoader/BackdropLoader';
import t from 'utils/translationHelper';
import useStyles from './index.styles';

interface IProps {
  name?: string,
  completedOnboarding?: boolean,
}

const WelcomeLoader: React.FC<IProps> = ({ name, completedOnboarding }: IProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(true);
  const [displayWelcome, setDisplayWelcome] = useState<boolean>(Boolean(completedOnboarding));

  setTimeout(() => {
    setDisplayWelcome(true);
  }, 3000);

  setTimeout(() => {
    setOpen(false);
  }, 8000);

  return (
    <Modal
      className={classes.modal}
      open={open}
      aria-labelledby="onboarding-modal"
      aria-describedby="onboarding-modal"
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      BackdropComponent={Backdrop}
    >
      <Fade in={open}>
        <div className={classes.wrapper}>
          {
            displayWelcome
              ? (
                <Fade in={displayWelcome} timeout={2000}>
                  <div className={classes.welcomeText}>
                    <img src={AlphaWhite} alt="Alpha logo in white" />
                    <Typography variant="h2">
                      {completedOnboarding && name ? `${t('thank_you_for_completing_onboarding')} ${name}` : t('welcome_to_alpha')}
                    </Typography>
                    <Typography variant="subtitle1">
                      {completedOnboarding && name ? t('logging_you_in_now')
                        : t('we_are_just_going_to_get_your_account_set_up_in_a_few_quick_steps')}
                    </Typography>
                  </div>
                </Fade>
              )
              : (
                <BackdropLoader
                  testId="backdrop-loader"
                  thickness={5}
                  size={60}
                  className={classes.loader}
                />
              )
          }
        </div>
      </Fade>
    </Modal>
  );
};

export default memo(WelcomeLoader);
