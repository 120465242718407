import { AxiosResponse } from 'axios';
import { QuoteNoTradeRequest } from 'models/platformRequests';

import {
  AccountConfigurationFXDto,
  AllowedCurrenciesDto,
  ApprovalRequest,
  AvailableSpotDatesDto,
  DrawdownDto,
  DrawdownRequest,
  DrawdownSubmissionRequest,
  DrawdownValidationDto,
  ExecuteTradeRequest,
  ExecuteTradeResultDto,
  ExternalTradeRequest,
  IndicativeRateResponse,
  MarketOrderDto,
  QuoteResponse,
  TradeCurrencyPairSummary,
  TradeDto,
  TradeFilterOptionsDto,
  TradeJournal,
  TradeRequest,
  TradeSubmissionDto,
  TradeUpdateRequest,
  TradeVerificationDto,
} from '@alpha/fx-dtos';
import { PaymentDto, PaymentInitiationDto, VerificationMethod } from '@alpha/payments-dtos';

import { FileType, TTradeSummaryParams } from '../../hooks/useReportsPolling';
import { TReportGenerationResponse } from '../../models/currencyAccounts';
import { TDate } from '../../models/payments';
import AuthyService from '../Authy/Authy.service';
import instance from '../Axios/instance';
import PaymentsService from '../Payments/payments.service';

export class FXTradeService {
  public static async getTradeData(
    tradeId: string,
  ): Promise<TradeDto | undefined> {
    const tradeResponse = await this.getTradeDataAsync(tradeId);
    return tradeResponse;
  }

  public static async getTradeClouseOuts(
    tradeId: string,
  ): Promise<TradeJournal[]> {
    const closeOutTradeJournals = await this.getTradeCloseOutsAsync(tradeId);
    return closeOutTradeJournals;
  }

  private static async getTradeDataAsync(
    tradeId: string,
  ): Promise<TradeDto | undefined> {
    const response = await instance.get(
      `/fx/trades/search?tradeids=${tradeId}`,
    );
    const singleTradeData = (response.data.total >= 1) ? response.data.records[0] : undefined;
    return singleTradeData;
  }

  private static async getTradeCloseOutsAsync(
    tradeId: string,
  ): Promise<TradeJournal[]> {
    const response = await instance.get(
      `/fx/trades/${tradeId}/close-outs`,
    );
    return response.data;
  }

  public static async getDrawdownsData(
    tradeId: string,
  ): Promise<DrawdownDto[]> {
    const drawdownResponse = await this.getDrawdownsDataAsync(tradeId);
    return drawdownResponse;
  }

  private static async getDrawdownsDataAsync(
    tradeId: string,
  ): Promise<DrawdownDto[]> {
    const response = await instance.get(
      `/fx/drawdowns/${tradeId}`,
    );
    return response.data;
  }

  public static async getTradePaymentsData(
    tradeId: string,
  ): Promise<PaymentDto[]> {
    const paymentsResponse = await this.getTradePaymentsDataAsync(tradeId);
    return paymentsResponse;
  }

  private static async getTradePaymentsDataAsync(
    tradeId: string,
  ): Promise<PaymentDto[]> {
    const response = await instance.get(
      `/payments/read/${tradeId}`,
    );
    return response.data;
  }

  public static async getMarketOrderData(): Promise<MarketOrderDto[]> {
    const marketOrderResponse = await this.getMarketOrderDataAsync();
    return marketOrderResponse;
  }

  private static async getMarketOrderDataAsync() : Promise<MarketOrderDto[]> {
    const response: AxiosResponse<MarketOrderDto[]> = await instance.get('/fx/market-orders');
    return response.data;
  }

  public static async getTradeFilterOptions(): Promise<TradeFilterOptionsDto> {
    const filterOptionsResponse = await this.getTradeFilterOptionsAsync();
    return filterOptionsResponse;
  }

  private static async getTradeFilterOptionsAsync() : Promise<TradeFilterOptionsDto> {
    const response: AxiosResponse<TradeFilterOptionsDto> = await instance.get('/fx/trades/search/filter-options');
    return response.data;
  }

  private static async postDrawdownSummaryGenerationAsync(
    type: FileType,
    drawdownId: string,
  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `reports/drawdown/${drawdownId}?type=${type}`,
    );
    return response.data;
  }

  public static async postDrawdownSummaryGeneration(
    type: FileType,
    drawdownId: string,
  ): Promise<string> {
    return (await this.postDrawdownSummaryGenerationAsync(type, drawdownId)).executionArn;
  }

  private static async postTradeSummaryWithDrawdownsGenerationAsync(
    type: FileType,
    tradeId: string,
  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/trade-drawdowns/${tradeId}?type=${type}`,
    );
    return response.data;
  }

  public static async postTradeSummaryWithDrawdownsGeneration(
    type: FileType,
    tradeId: string,
  ): Promise<string> {
    return (await this.postTradeSummaryWithDrawdownsGenerationAsync(type, tradeId)).executionArn;
  }

  private static async postTradeSummaryWithPaymentsGenerationAsync(
    type: FileType,
    tradeId: string,
  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/trade-payments/${tradeId}?type=${type}`,
    );
    return response.data;
  }

  public static async postTradeSummaryWithPaymentsGeneration(
    type: FileType,
    tradeId: string,
  ): Promise<string> {
    return (await this.postTradeSummaryWithPaymentsGenerationAsync(type, tradeId)).executionArn;
  }

  public static async getAccountConfigurationWithCcys(
    sellCurrency: string,
    buyCurrency: string,
  ): Promise<AccountConfigurationFXDto> {
    return this.getAccountConfigurationWithCcysAsync(sellCurrency, buyCurrency);
  }

  private static async getAccountConfigurationWithCcysAsync(
    sellCurrency: string,
    buyCurrency: string,
  ): Promise<AccountConfigurationFXDto> {
    const response: AxiosResponse<AccountConfigurationFXDto> = await instance.get(`/fx/account-configuration?sellcurrency=${sellCurrency}&buycurrency=${buyCurrency}`);
    return response.data;
  }

  public static async getAvailableDrawdownDates(
    tradeId: string,
  ): Promise<TDate> {
    return this.getAvailableDrawdownDatesAsync(
      tradeId,
    );
  }

  private static async getAvailableDrawdownDatesAsync(
    tradeId: string,
  ): Promise<TDate> {
    const response: AxiosResponse<TDate> = await instance.get(
      `/fx/trades/${tradeId}/available-drawdown-dates`,
    );
    return response.data;
  }

  public static async getAllowedFXCurrencies(): Promise<AllowedCurrenciesDto> {
    return this.getAllowedFXCurrenciesAsync();
  }

  private static async getAllowedFXCurrenciesAsync(): Promise<AllowedCurrenciesDto > {
    const response: AxiosResponse<AllowedCurrenciesDto > = await instance.get(
      '/fx/allowed-currencies',
    );
    return response.data;
  }

  public static async getSpotTradeIndicativeRate(sellCurrencyCode: string,
    buyCurrencyCode: string): Promise<IndicativeRateResponse > {
    return this.getSpotTradeIndicativeRateAsync(sellCurrencyCode, buyCurrencyCode);
  }

  private static async getSpotTradeIndicativeRateAsync(
    sellCurrencyCode: string,
    buyCurrencyCode: string,
  ): Promise<IndicativeRateResponse > {
    const response: AxiosResponse<IndicativeRateResponse > = await instance.get(
      '/fx/indicative-rate', {
        params: {
          buycurrency: buyCurrencyCode,
          sellcurrency: sellCurrencyCode,
        },
      },
    );
    return response.data;
  }

  public static async getAvailableSpotDates(
    sellCurrencyCode: string,
    buyCurrencyCode: string,
  ): Promise<AvailableSpotDatesDto> {
    const response: AxiosResponse<AvailableSpotDatesDto> = await instance.get(
      '/fx/trades/available-spot-dates', {
        params: {
          buycurrency: buyCurrencyCode,
          sellcurrency: sellCurrencyCode,
        },
      },
    );
    return response.data;
  }

  public static async getSpotTradeLiveQuote(tradeId: string): Promise<QuoteResponse> {
    return this.getSpotTradeLiveQuoteAsync(tradeId);
  }

  private static async getSpotTradeLiveQuoteAsync(tradeId: string): Promise<QuoteResponse > {
    const response: AxiosResponse<QuoteResponse > = await instance.get(
      `/fx/trades/${tradeId}/quote`,
    );
    return response.data;
  }

  private static async postMtmReportGenerationAsync(
    type: FileType,
    mtmdate: string,
    mtmcurrency: string,
    includeexternaltrades: string,
    searchtext?: string,
    soldcurrency?: string[],
    buycurrency?: string[],

  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `reports/trade-mtm/?type=${type}&mtmdate=${mtmdate}&mtmcurrency=${mtmcurrency}&includeexternaltrades=${includeexternaltrades}`,
      {
        params: {
          searchtext,
          soldcurrency: soldcurrency?.join(','),
          buycurrency: buycurrency?.join(','),
        },
      },
    );
    return response.data;
  }

  public static async postMtmReportGeneration(
    type: FileType,
    mtmdate: string,
    mtmcurrency: string,
    includeexternaltrades: string,
    searchtext?: string,
    soldcurrency?: string[],
    buycurrency?: string[],
  ): Promise<string> {
    return (await this.postMtmReportGenerationAsync(
      type,
      mtmdate,
      mtmcurrency,
      includeexternaltrades,
      searchtext,
      soldcurrency,
      buycurrency,
    )).executionArn;
  }

  public static calculateSpotTradeAmount(
    fixedSide: 'buy' | 'sell',
    amount: number,
    rate?: number,
  ): number {
    if (!rate) return amount * 1;
    if (fixedSide === 'sell') {
      return +(amount * rate).toFixed(2);
    }
    return (amount !== 0) ? +(amount / rate).toFixed(2) : 0;
  }

  public static async postValidateTradeRequest(
    tradeRequest: TradeRequest,
  ): Promise<TradeSubmissionDto> {
    return this.postValidateTradeRequestAsync(tradeRequest);
  }

  private static async postValidateTradeRequestAsync(
    tradeRequest: TradeRequest,
  ): Promise<TradeSubmissionDto > {
    const response: AxiosResponse<TradeSubmissionDto> = await instance.post(
      '/fx/trades',
      {
        fundingMethod: tradeRequest.fundingMethod,
        sellCurrencyCode: tradeRequest.sellCurrencyCode,
        buyCurrencyCode: tradeRequest.buyCurrencyCode,
        fixedCurrencyCode: tradeRequest.fixedCurrencyCode,
        fixedAmount: tradeRequest.fixedAmount,
        valueDate: tradeRequest.valueDate,
      },
    );
    return response.data;
  }

  public static async postQuoteNoTradeRequestAsync(
    quoteNoTradeRequest: QuoteNoTradeRequest,
  ): Promise<any> {
    const response: AxiosResponse<any> = await instance.post(
      '/fx/trades/quote-no-trade',
      {
        currencyPair: quoteNoTradeRequest.currencyPair,
        amount: quoteNoTradeRequest.amount ? quoteNoTradeRequest.amount : 0,
        indicativeRate: quoteNoTradeRequest.indicativeRate,
        quoteRate: quoteNoTradeRequest.quoteRate,
      },
    );
    return response.data;
  }

  public static async getTradeVerification(
    tradeId: string,
    method?: VerificationMethod,
  ):Promise<TradeVerificationDto> {
    return this.getTradeVerificationAsync({ tradeId, method });
  }

  private static async getTradeVerificationAsync({
    tradeId,
    method,
  }: {
    tradeId: string,
    method?: VerificationMethod
  }) : Promise<TradeVerificationDto> {
    let finalVM = method;
    if (!finalVM) {
      finalVM = await AuthyService.selectDefaultAuthenticationMethod();
    }
    const response = await instance.get(
      `/fx/trades/${tradeId}/verification?method=${finalVM}`,
    );

    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }

    return response.data;
  }

  public static async getTradePADVerification({
    tradeId,
    method,
  }: {
     tradeId: string,
    method?: VerificationMethod
  }) : Promise<TradeVerificationDto> {
    let finalVM = method;
    if (!finalVM) {
      finalVM = await AuthyService.selectDefaultAuthenticationMethod();
    }
    const response = await instance.get(
      `/fx/trades/${tradeId}/pad-verification?method=${finalVM}`,
    );

    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }

    return response.data;
  }

  public static async getDrawdownPADVerification({
    drawdownId,
    method,
  }: {
    drawdownId: string,
    method?: VerificationMethod
  }) : Promise<TradeVerificationDto> {
    let finalVM = method;
    if (!finalVM) {
      finalVM = await AuthyService.selectDefaultAuthenticationMethod();
    }
    const response = await instance.get(
      `/fx/drawdowns/pad-verification/${drawdownId}/?method=${finalVM}`,
    );

    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }

    return response.data;
  }

  public static async postExecuteTradeBooking(
    executeTradeRequest: ExecuteTradeRequest,
  ):Promise<ExecuteTradeResultDto> {
    return this.postExecuteTradeBookingAsync(executeTradeRequest);
  }

  private static async postExecuteTradeBookingAsync(
    executeTradeRequest: ExecuteTradeRequest,
  ) : Promise<ExecuteTradeResultDto & Partial<PaymentInitiationDto>> {
    const response = await instance.post(
      '/fx/trades/execute',
      executeTradeRequest,
    );

    if (response.status !== 201) {
      throw Error(`${response.data.error}`);
    }

    return response.data;
  }

  public static async postValidateDrawdown(
    drawdownRequest: DrawdownRequest,
  ): Promise<DrawdownValidationDto> {
    const response = await instance.post('/fx/drawdowns/validate', drawdownRequest);
    return response.data;
  }

  public static async postValidateFirstPartyDrawdown(
    drawdownRequest: DrawdownRequest,
  ): Promise<DrawdownValidationDto> {
    const response = await instance.post('/fx/drawdowns/validate/first-party', drawdownRequest);
    return response.data;
  }

  public static async postSubmitDrawdownAndApprovePayments(
    params : {
      drawdownId: string,
      paymentIds: string[],
      dynamicLinkingId: string,
      totp?: string,
      approvalRequestId?: string
      softToken: boolean,
    },
    canApproveOwn: boolean,
    firstPartyDrawdown?: boolean,
  ):Promise<{ pending: boolean, success: boolean }> {
    const drawdownResponse = await this.postSubmitDrawdownAsync(
      params.drawdownId,
      params.paymentIds,
      params.dynamicLinkingId,
      params.softToken,
      params.totp,
      params.approvalRequestId,
      firstPartyDrawdown,
    );

    if (canApproveOwn
      && drawdownResponse
      && drawdownResponse.success
      && params.paymentIds.length > 0) {
      try {
        await PaymentsService.approvePayment({
          paymentIds: params.paymentIds,
          dynamicLinkingId: params.dynamicLinkingId,
          totp: params.totp,
          approvalRequestId: params.approvalRequestId,
          softToken: params.softToken,
        });
      } catch (e) {
        console.error(e);
      }
    }

    return drawdownResponse;
  }

  private static async postSubmitDrawdownAsync(
    drawdownId: string,
    paymentIds: string[],
    dynamicLinkingId: string,
    softToken: boolean,
    totp?: string,
    approvalRequestId?: string,
    firstPartyDrawdown?: boolean,
  ): Promise<{ pending: boolean, success: boolean }> {
    let response;
    if (firstPartyDrawdown) {
      response = await instance.post<DrawdownSubmissionRequest, any>(
        `/fx/drawdowns/submit/first-party/${drawdownId}`, {
          paymentIds,
          dynamicLinkingId,
          totp,
          approvalRequestId,
          softToken,
        },
      );
    } else {
      response = await instance.post<DrawdownSubmissionRequest, any>(
        `/fx/drawdowns/submit/${drawdownId}`, {
          paymentIds,
          dynamicLinkingId,
          totp,
          approvalRequestId,
          softToken,
        },
      );
    }
    return response.data;
  }

  public static async updateTrade(
    tradeId: string,
    params: TradeUpdateRequest,
  ): Promise<any> {
    const response = await instance.put(
      `/fx/trades/${tradeId}/`,
      params,
    );

    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }
    return response;
  }

  public static async submitTrade(params: {
    tradeId: string,
    code?: string,
    approvalRequestId: string,
    dynamicLinkingId: string,
    softToken: boolean, }):Promise<{ pending: boolean, success: boolean, message?: string }> {
    return this.submitTradeAsync(params);
  }

  private static async submitTradeAsync(
    params: {
      tradeId: string,
      code?: string,
      approvalRequestId: string,
      dynamicLinkingId: string
      softToken: boolean, },
  ) : Promise<{ pending: boolean, success: boolean, message?: string }> {
    const queryParams = {
      approvalRequestId: params.approvalRequestId,
      dynamicLinkingId: params.dynamicLinkingId,
      totp: params.code,
      softToken: params.softToken,
    };
    const response = await instance.post(
      `/fx/trades/${params.tradeId}/verification`,
      queryParams,
    );

    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }

    if (!response.data) {
      return { success: true, pending: false };
    }

    return response.data;
  }

  public static async putSubmitTradePADApproval(
    tradeId: string,
    approvalRequest: ApprovalRequest,
  ) : Promise<{ pending: boolean, success: boolean, message?: string }> {
    const response = await instance.put(
      `/fx/trades/${tradeId}/pad-approve`,
      approvalRequest,
    );

    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }

    if (!response.data) {
      return { success: true, pending: false };
    }

    return response.data;
  }

  public static async putSubmitDrawdownPADApproval(
    drawdownId: string,
    approvalRequest: ApprovalRequest,
  ) : Promise<{ pending: boolean, success: boolean, message?: string }> {
    const response = await instance.put(
      `/fx/drawdowns/pad-approve/${drawdownId}`,
      approvalRequest,
    );

    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }

    if (!response.data) {
      return { success: true, pending: false };
    }

    return response.data;
  }

  public static async postTradeSummaryGeneration(
    params: TTradeSummaryParams,
  ): Promise<string> {
    return (await this.postTradeSummaryGenerationAsync(
      params,
    )).executionArn;
  }

  private static async postTradeSummaryGenerationAsync(
    params: TTradeSummaryParams,
  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      '/reports/trades', { params },
    );
    return response.data;
  }

  public static async postExternalTrade(
    tradeRequests: ExternalTradeRequest[],
  ) : Promise<{ pending: boolean, success: boolean, message?: string }> {
    const response = await instance.post(
      '/fx/external-trades',
      tradeRequests,
    );
    if (response.status !== 201) {
      throw Error(`${response.data.error}`);
    }
    if (!response.data) {
      return { success: true, pending: false };
    }
    return response.data;
  }

  public static async getTradesSummary(): Promise<TradeCurrencyPairSummary[]> {
    const response: AxiosResponse<TradeCurrencyPairSummary[]> = await instance.get('/fx/trades/summary');
    return response.data;
  }

  public static async getAccountConfiguration(): Promise<AccountConfigurationFXDto> {
    const response: AxiosResponse<AccountConfigurationFXDto> = await instance.get('/fx/account-configuration');
    return response.data;
  }

  public static async getPendingTrades(): Promise<{total: number, records: TradeDto[]}> {
    const params = {
      sortby: 'tradeDate',
      sortorder: 'asc',
      take: 5,
      skip: 0,
      status: 'SUBMITTED,VERIFIED',
    };
    const response:AxiosResponse<{
      total: number,
      records: TradeDto[]
    }> = await instance.get(
      '/fx/trades/search',
      { params },
    );

    return response.data;
  }

  public static async getTransfersFXData(
    transferId: string,
  ): Promise<TradeDto> {
    const transferResponse = await this.getTransfersFXDataAsync(transferId);
    return transferResponse;
  }

  private static async getTransfersFXDataAsync(
    transferId: string,
  ): Promise<TradeDto> {
    const response = await instance.get(
      `/fx/trades/by-transfer-id/${transferId}`,
    );
    return response.data;
  }
}

export default FXTradeService;
