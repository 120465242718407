import React, { useEffect, useState } from 'react';

import Dropdown, { TData } from '../../../components/InterAccountTransfer/Dropdown/Dropdown';
import history from '../../../services/history/browserHistory';
import StatementsService from '../../../services/Statements/statements.service';
import { mapAccountsToData } from '../../../utils/mapDropdown';

export interface IProps {
  accountId?: string;
}

const StatementsSelect: React.FC<IProps> = (props: IProps) => {
  const { accountId } = props;

  const [options, setOptions] = useState<TData[]>([]);

  useEffect(() => {
    (async () => {
      const statementAccounts = await StatementsService.getStatementAccounts();
      const data = mapAccountsToData(statementAccounts);
      setOptions(data);
    })();
  }, []);

  return (
    <div style={{ maxWidth: 'fit-content' }}>
      <Dropdown
        data={options}
        handleChange={(e) => history.push(`/app/statements/${e.target.value}`)}
        selectValue={accountId}
        hideBalance
        testId="statements-dropdown"
      />
    </div>
  );
};

export default StatementsSelect;
