import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      height: '100%',
      position: 'relative',
      '& .MuiBox-root > table > tbody > tr > td': {
        fontSize: '16px',
        padding: '7px 0',
        '&:last-child': {
          fontWeight: 'bold',
        },
      },
    },
    stats: {
      width: '100%',
      padding: '40px 0 24px 0',
      borderBottom: '1px solid #efefef',
      margin: '0 0 20px 0',
      height: '120px',
    },
    pullRight: {
      float: 'right',
      '& div': {
        margin: '0 0 0 80px',
      },
      '& > div:nth-of-type(3)': {
        fontWeight: 'bold',
      },
    },
  }),
  { name: 'CurrencyAccounts' },
);

export default useStyles;
