import React from 'react';
import TransferConfirmDetails from 'components/Molecules/Trade/CreateSpotDrawer/ConfirmTrade/TransferConfirmDetails';
import { formatCurrency } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import t from 'utils/translationHelper';

import { CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';
import { TradeDto } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Typography } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './index.styles';

interface IProps {
  trade: TradeDto,
  transferSubmitResponse: CurrencyAccountTransferDto,
  onClose?: () => void,
}

const TransferSpotDraftConfirmation: React.FC<IProps> = ({ trade, transferSubmitResponse, onClose }) => {

  const styles = useStyles();
  return (
    <>
      <BaseDrawer.Header style={{ paddingTop: '60px', lineHeight: '30' }}>
        <Typography className={styles.heading}>
          <FontAwesomeIcon icon={faCheckSquare as IconProp} className={styles.icon} />
          {t('draft_trade_submitted')} - {t('inter_account_transfer')}
        </Typography>
      </BaseDrawer.Header>
      <div style={{ margin: '30px 0' }}>
        <BaseDrawer.LineBreak />
      </div>
      <BaseDrawer.Body>
        <>
          <div className={styles.transfersTitle}>
            <strong>{t('fx_trade_summary')}</strong>
          </div>
          <div className={styles.tradeContainer}>
            <div className={styles.detailContainer}>
              <Typography className={styles.textStyle}>{t('sell_currency')}</Typography>
              <Typography className={styles.textStyle}>
                <strong>{`${trade?.soldCurrencyCode}` || '-'}</strong>
                {` - ${trade?.soldCurrencyCode || '-'}`}
              </Typography>
            </div>
            <div className={styles.detailContainer}>
              <Typography className={styles.textStyle}>{t('sell_amount')}</Typography>
              <Typography className={styles.textStyle}>
                <strong>{formatCurrency(trade?.soldCurrencyCode, trade?.soldAmount) || '-'}</strong>
              </Typography>
            </div>
            <div className={styles.detailContainer}>
              <Typography className={styles.textStyle}>{t('buy_currency')}</Typography>
              <Typography className={styles.textStyle}>
                <strong>{`${trade?.buyCurrencyCode}` || '-'}</strong>
                {` - ${trade?.buyCurrencyCode || '-'}`}
              </Typography>
            </div>
            <div className={styles.detailContainer}>
              <Typography className={styles.textStyle}>Fixed Amount</Typography>
              <Typography className={styles.textStyle}>
                <strong>{formatCurrency(trade?.fixedCurrencyCode, trade?.fixedAmount) || '-'}</strong>
              </Typography>
            </div>
            <div className={styles.detailContainer}>
              <Typography className={styles.textStyle}>{t('value_date')}</Typography>
              <Typography className={styles.textStyle}>
                {formatIsoDate(trade?.valueDate) || '-'}
              </Typography>
            </div>
          </div>
          <TransferConfirmDetails bookedTransfer={transferSubmitResponse} />
          <div className={styles.buttonContainer}>
            <ActionButton onClick={onClose} style={{ width: '100%' }}>{t('exit')}</ActionButton>
          </div>
        </>
      </BaseDrawer.Body>
    </>
  );
};

export default TransferSpotDraftConfirmation;
