import { AxiosError } from 'axios';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TRequestsStore = {
  requests: {
    [key: string]: boolean;
  };
  errors: {
    [key: string]: AxiosError | null;
  };
};

const initialState: TRequestsStore = {
  requests: {},
  errors: {},
};

export const requestsSlice = createSlice({
  initialState,
  name: 'requests',
  reducers: {
    finish: {
      reducer: (state, action: PayloadAction<string, string, AxiosError | undefined>): TRequestsStore => ({
        ...state,
        requests: {
          ...state.requests,
          [action.payload]: false,
        },
        errors: action.meta
          ? {
            ...state.errors,
            [action.payload]: action.meta,
          }
          : state.errors,
      }),
      prepare(payload: string, error?: AxiosError) {
        return {
          payload,
          meta: error,
        };
      },
    },
    start: (state, action: PayloadAction<string>): TRequestsStore => ({
      ...state,
      requests: {
        ...state.requests,
        [action.payload]: true,
      },
      errors: {
        ...state.errors,
        [action.payload]: null,
      },
    }),
  },
});

export const { actions } = requestsSlice;
