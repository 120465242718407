import React, { memo } from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import t from 'utils/translationHelper';

import warningIcon from '../../../assets/warning.svg';
import APModal from '../../../components/Modals/APModal/APModal';

import useStyles from './AccountRequestDupeModal.styles';

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleActionButton: () => void;

}
const AccountRequestDupeModal: React.FC<IProps> = (props: IProps) => {
  const {
    open,
    handleClose,
    handleActionButton,
  } = props;

  const classes = useStyles();


  return (
    <APModal.Container
      open={open}
      onClose={handleClose}
      className={classes.container}
    >
      <APModal.Wrapper>
        <Box className={classes.header}>
          <img src={warningIcon} alt="Warning icon" />
          <Typography variant="h2">{t('duplicate_submission')}</Typography>
        </Box>
        <Box className={classes.body}>
          <Typography className={classes.paddingBottom} variant="subtitle1">
            {t('it_looks_like_you_have_submitted_a_currency_account_request_in_this_currency_already')}
            {' '}
            {t('please_only_submit_if_this_additional_account_is_required')}
          </Typography>
          <Typography variant="subtitle1">
            {t('all_new_currency_account_requests_can_be_viewed_within_account_management')}
          </Typography>
        </Box>
        <div className={classes.buttonsContainer}>
          <ActionButton style={{ background: '#F7F7F7', color: '#212529' }} onClick={handleClose}>
            {t('cancel')}
          </ActionButton>
          <div className={classes.continueBtn}>
            <ActionButton
              testId="continue-dupe-modal-btn"
              onClick={handleActionButton}
            >
              {t('continue')}
            </ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default memo(AccountRequestDupeModal);
