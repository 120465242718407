import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import t from 'utils/translationHelper';
import { useSelector } from 'react-redux';

import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';

import Search, { TFilterChipData } from '../../../../components/Organisms/Search';
import browserHistory from '../../../../services/history/browserHistory';
import { TStore } from '../../../../store';
import { TAuthyState } from '../../../../store/authy/reducer';
import formatIsoDate from '../../../../utils/formatIsoDate';
import mapPaymentStatusDisplay from '../../../../utils/payments/mapPaymentStatusDisplay';
import { TFilter } from '../FxTradeTable/FxTradeTable';

import PaymentActionBar from './PaymentActionBar/PaymentActionBar';
import PaymentTable from './PaymentTable/PaymentTable';
import useStyles from './PaymentTableContainer.styles';
import usePaymentTable, { PaymentEvents, TPaymentSearchFilterTypes } from './usePaymentTable';

interface IPaymentTableContainerProps {
  selectedPayment?: PaymentDto;
  setSelectedPayment: React.Dispatch<React.SetStateAction<PaymentDto | undefined>>;
  multiSelectedPayments?: PaymentDto[];
  setMultiSelectedPayments: React.Dispatch<React.SetStateAction<PaymentDto[]>>;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  createPaymentOpen: boolean;
  setCreatePaymentOpen: React.Dispatch<React.SetStateAction<boolean>>,
  selectedPaymentBeneficiary: string | undefined,
  setSelectedPaymentBeneficiary: React.Dispatch<React.SetStateAction<string | undefined>>,
  handleOpenApprovalDrawer: () => void;
  rejectedPaymentIds?: string[];
  handleRejectModalOpen: () => void;
  filters?: TFilter[],
  filtersByField?: TPaymentSearchFilterTypes,
  handleRemoveFilterItem?(item: TFilter): void;
  handleApprovePayments?: (payments: PaymentDto[]) => void;
  handleRejectPayments?: (payments: PaymentDto[]) => void;
}

// eslint-disable-next-line max-lines-per-function
const PaymentTableContainer: React.FC<IPaymentTableContainerProps> = ({
  setSelectedPayment,
  multiSelectedPayments,
  setMultiSelectedPayments,
  selectedIds,
  setSelectedIds,
  handleRejectModalOpen,
  rejectedPaymentIds,
  handleOpenApprovalDrawer,
  setSelectedPaymentBeneficiary,
  filters,
  filtersByField,
  handleRemoveFilterItem,
  handleApprovePayments,
  handleRejectPayments,
}: IPaymentTableContainerProps) => {


  const [currentSortBy, setCurrentSortBy] = useState<string>('contractNumber');
  const [currentSortOrder, setCurrentSortOrder] = useState<'asc' | 'desc'>('desc');

  const {
    table,
    handleInputChange,
    searchParams,
    handleTableSortClick,
    reloadCurrentQuery,
  } = usePaymentTable(filtersByField, currentSortBy, currentSortOrder);
  const styles = useStyles();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  const [filterChips, setFilterChips] = useState<TFilterChipData[]>([]);

  useEffect(() => {
    mapPaymentObjectWithSelectedIds(selectedIds);
  }, [selectedIds]);

  useEffect(() => {
    if (
      authyState.type?.type === 'PAYMENTS'
    ) {
      setTimeout(() => {
        table.handleInitialSearch(searchParams);
        setSelectedIds([]);
        setSelectedPayment(undefined);
      }, 1000);
    } else if (authyState.type?.type === 'PAYMENT_APPROVE' && authyState.status === 'SUCCESS') {
      setTimeout(() => {
        reloadCurrentQuery();
        setSelectedIds([]);
        setSelectedPayment(undefined);
      }, 1000);
    }
  }, [authyState.status, authyState.type, browserHistory.push]);

  useEffect(() => {
    if (rejectedPaymentIds && rejectedPaymentIds.length > 0) {
      reloadCurrentQuery();
      setSelectedIds([]);
      setSelectedPayment(undefined);
    }
  }, [rejectedPaymentIds]);

  useEffect(() => {
    setFilterChips(mapFiltersToDisplay(filters || []));
  }, [filters]);

  useEffect(() => {
    window.addEventListener(PaymentEvents.RefreshPaymentTable, () => {
      reloadCurrentQuery();
    });
    return () => {
      window.removeEventListener(PaymentEvents.RefreshPaymentTable, () => {
        reloadCurrentQuery();
      });
    };
  }, [table]);

  const mapFiltersToDisplay = (filtersToMap:
    TFilter[]): TFilterChipData[] => filtersToMap.map((filter) => {
      let filterValue = filter.value as string;
      switch (filter.field) {
        case 'paymentStatus':
          filterValue = startCase(mapPaymentStatusDisplay(
            filter.value as PaymentStatus,
          ).text.toLowerCase());
          break;
        case 'paymentValueStartDate':
        case 'paymentValueEndDate':
          filterValue = formatIsoDate(filter.value);
          break;
        default:
          filterValue = filter.value;
          break;
      }

      return {
        label: (
          <span>
            <b>
              {t(startCase(filter.field))}
              :
            </b>
            {' '}
            {t(filterValue)}
          </span>),
        value: filter.value,
        field: filter.field,
      };
    });

  const mapPaymentObjectWithSelectedIds = (selectedPaymentIds: string[]): void => {
    const resultPayments: PaymentDto[] = multiSelectedPayments?.filter(
      (payment) => selectedPaymentIds?.includes(payment.id),
    ) || [];

    const multiSelectedPaymentsIds = multiSelectedPayments?.map((payment) => payment.id);
    const newlySelectedIds = selectedPaymentIds?.filter(
      (paymentId) => !multiSelectedPaymentsIds?.includes(paymentId),
    );

    const paymentData: PaymentDto[] = (table.items?.items?.records as PaymentDto[]) || [];
    const newlySelected: PaymentDto[] = paymentData.filter(
      (item) => newlySelectedIds.includes(item.id),
    );

    resultPayments.push(...newlySelected);

    setMultiSelectedPayments(resultPayments);
  };

  const handleClearButtonClick = () => {
    setSelectedIds([]);
  };

  const handleBeneficiaryButton = (beneficiaryId: string) => {
    setSelectedPaymentBeneficiary(beneficiaryId);
  };

  const handleRemoveFilter = (item: TFilterChipData): void => {
    const { field, value } = item;
    if (handleRemoveFilterItem) { handleRemoveFilterItem({ field, value }); }
  };

  const showApproval = multiSelectedPayments && multiSelectedPayments.length > 0;

  return (
    <div className={[styles.paymentTable, showApproval ? 'show-action-bar' : null].join(' ')}>
      <div className={styles.searchBar}>
        <Search
          testId="search-payment"
          value={table.searchText}
          totalItems={table.items?.items.total}
          placeholder={t('search_payment')}
          loading={table.loading}
          onChange={handleInputChange}
          filters={filterChips}
          handleRemoveFilter={handleRemoveFilter}
        />
      </div>
      <PaymentTable
        hasNext={table.items?.hasNext || false}
        hasPrevious={table.items?.hasPrevious || false}
        handleNextPage={() => table.handleNextPage(searchParams)}
        handlePreviousPage={() => table.handlePreviousPage(searchParams)}
        loading={table.loading}
        tableRawData={table.items?.items}
        handleTableSortClick={(column: string) => {
          handleTableSortClick(
            column,
          );
          setCurrentSortBy(column);
          let newOrder: 'asc' | 'desc';
          if (column === currentSortBy) {
            newOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';
          } else {
            newOrder = 'desc';
          }
          setCurrentSortOrder(newOrder);
        }}
        sortOrder={table.sortOrder}
        sortBy={table.sortBy}
        skip={table.skip}
        handleBeneficiaryButton={handleBeneficiaryButton}
        testId="payment-table"
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        setSelectedPayment={setSelectedPayment}
        emptyTitle={t('no_payments')}
        emptySubTitle={t('you_currently_do_not_have_any_payments')}
        handleReloadTable={reloadCurrentQuery}
        handleApprovePayments={handleApprovePayments}
        handleRejectPayments={handleRejectPayments}
      />
      <PaymentActionBar
        selectedPayments={multiSelectedPayments}
        handleClearButtonClick={handleClearButtonClick}
        handleOpenApprovalDrawer={handleOpenApprovalDrawer}
        handleRejectModalOpen={handleRejectModalOpen}
        show={showApproval}
      />
    </div>

  );
};
export default PaymentTableContainer;
