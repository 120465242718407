import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  trackerContainer: {
    margin: '30px 40px 0px 25px',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '14px',
  },
  textStyle: {
    fontSize: 14,
    fontFamily: typography.fontFamilySecondary,
  },
  statsContainer: {
    backgroundColor: '#F4F4F4',
    padding: '8px 17px 15px 20px',
    margin: '0px 35px 0px 25px',
  },
  standardContainer: {
    margin: '20px 35px 0px 25px',
  },
  amountToAssignText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '20px',
  },
  amountToAssignContainer: {
    paddingTop: '20px',
  },
}), { name: 'TradePayments' });

export default useStyles;
