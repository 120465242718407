import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'left',
      marginLeft: 15,
    },
  }),
  { name: 'DisableAccessActionDropDown' },
);

export default useStyles;
