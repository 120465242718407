import { FirstPartyDrawdownType } from 'components/Molecules/Drawdowns/FirstPartyCreateDrawdownDrawer/formData';
import { FormikProps } from 'formik';
import React from 'react';
import t from 'utils/translationHelper';

import { DrawdownFundingMethod, DrawdownRequest } from '@alpha/fx-dtos';
import {
  FormControlLabel, Radio, RadioGroup, Typography,
} from '@alpha/ui-lib/ui/external';

import { formatNumber } from '../../../../../../utils/currency.helpers';
import Alert from '../../../../../Alert';

import useStyles from './index.styles';
import { DrawdownRequestForm } from '../../formData';

type Props = {
  drawdownForm: FormikProps<DrawdownRequestForm> | FormikProps<FirstPartyDrawdownType>,
  padAllowed?: boolean,
  defaultAccountName?: string,
  defaultAccountBalance?: number,
  defaultAccountCurrencyCode?: string,
  insufficientFunds: boolean,
}

const FundingMethod: React.FC<Props> = ({
  drawdownForm,
  padAllowed,
  defaultAccountName,
  defaultAccountBalance,
  defaultAccountCurrencyCode,
  insufficientFunds,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography style={{ marginTop: '48px', fontWeight: '600' }}>{t('funding_method')}</Typography>
      <Typography style={{ marginTop: '16px', opacity: '80%' }}>{t('please_select_how_you_would_like_to_fund_this_drawdown')}</Typography>
      <RadioGroup
        row
        value={drawdownForm.values.fundingMethod}
        onChange={(e) => drawdownForm.setFieldValue('fundingMethod', e.target.value)}
        style={{ flexDirection: 'column', marginTop: '20px' }}
      >
        <FormControlLabel value={DrawdownFundingMethod.CURRENCY_ACCOUNT_BALANCE} control={<Radio color="primary" />} label={t('use_funds_on_account')} data-testid="funds-on-account-drawdown" />
        <FormControlLabel value={DrawdownFundingMethod.WIRE_TRANSFER} control={<Radio color="primary" />} label={t('wire_transfer_funds')} data-testid="wire-transfer-drawdown" />
        {padAllowed
          && <FormControlLabel value={DrawdownFundingMethod.PAD} control={<Radio color="primary" />} label={t('settle_with_pad')} data-testid="pad-drawdown" />}
      </RadioGroup>
      {drawdownForm.values.fundingMethod === DrawdownFundingMethod.WIRE_TRANSFER
        && (
          <div style={{ marginTop: '16px' }}>
            <Alert
              variant="info"
              text={t('details_of_where_to_send_the_settlement_will_be_displayed_on_the_final_screen')}
            />
          </div>
        )}
      {drawdownForm.values.fundingMethod === DrawdownFundingMethod.PAD
        && (
          <div style={{ marginTop: '16px' }}>
            <Alert
              variant="info"
              text={t('pad_approved_drawdown')}
            />
          </div>
        )}
      {drawdownForm.values.fundingMethod === DrawdownFundingMethod.CURRENCY_ACCOUNT_BALANCE
        && (
          <>
            {insufficientFunds
              && (
                <div style={{ marginTop: '16px' }}>
                  <Alert
                    variant="warning"
                    text={t('insufficient_funds')}
                    subText={t('weve_noticed_insufficient_funds_to_perform_payment')}
                  />
                </div>
              )}
            <div className={classes.accountDetails}>
              <div className={classes.accountDetailsLine}>
                <Typography>{t('account_name')}</Typography>
                <Typography>{defaultAccountName || '-'}</Typography>
              </div>
              <div className={classes.accountDetailsLine}>
                <Typography>{t('account_balance')}</Typography>
                {defaultAccountBalance
                  && (
                    <Typography style={{ color: defaultAccountBalance > 0 ? '#1E8777' : '#E63737', fontWeight: '600' }}>
                      {formatNumber(defaultAccountBalance, 2) || '-'}
                      {' '}
                      {defaultAccountCurrencyCode || '-'}
                    </Typography>
                  )}
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default FundingMethod;
