import OptionTradeStatus from 'models/optionTrades';

export const mapOptionTradeStatusDisplay = (
  tradeStatus: OptionTradeStatus,
): {variant:'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined, text:
    string} => {
  switch (tradeStatus) {
    case OptionTradeStatus.ACTIVE:
      return { variant: 'info', text: 'ACTIVE' };
    case OptionTradeStatus.TERMINATED:
      return { variant: 'error', text: 'TERMINATED' };
    case OptionTradeStatus.EXPIRED:
      return { variant: 'orange', text: 'EXPIRED' };
    default:
      return { variant: 'warning', text: '-' };
  }
};

export default mapOptionTradeStatusDisplay;
