import { combineReducers } from '@reduxjs/toolkit';

import { authySlice } from './authy/reducer';
import { beneficariesSlice } from './beneficiaries/beneficiaries.reducer';
import { clientNotificationsSlice } from './clientNotifications/clientNotifications.reducer';
import { creditFacilitySlice } from './creditFacility/reducer';
import { currenciesSlice } from './currencies/currencies.reducer';
import { featureFlagsSlice } from './featureFlags/reducer';
import { generatePdfSlice } from './generateReport/reducer';
import { slice as initialPathSlice } from './initialPath/reducer';
import { notificationsSlice } from './notifications/notifications.reducer';
import { optionTradeSlice } from './optionTrades/reducer';
import { paymentsSlice } from './payments/payments.reducer';
import { requestsSlice } from './requests/requests.reducer';
import { userSlice } from './user/user.reducer';

export default combineReducers({
  [userSlice.name]: userSlice.reducer,
  [paymentsSlice.name]: paymentsSlice.reducer,
  [requestsSlice.name]: requestsSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [currenciesSlice.name]: currenciesSlice.reducer,
  [authySlice.name]: authySlice.reducer,
  [generatePdfSlice.name]: generatePdfSlice.reducer,
  [initialPathSlice.name]: initialPathSlice.reducer,
  [clientNotificationsSlice.name]: clientNotificationsSlice.reducer,
  [featureFlagsSlice.name]: featureFlagsSlice.reducer,
  [creditFacilitySlice.name]: creditFacilitySlice.reducer,
  [optionTradeSlice.name]: optionTradeSlice.reducer,
  [beneficariesSlice.name]: beneficariesSlice.reducer,
});
