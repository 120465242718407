import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ palette, typography }) => ({
    accountWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '14px 30px',
      borderRadius: 5,
      border: '1px solid rgb(227, 227, 227)',
      '&:not(:last-child)': {
        marginBottom: 10,
      },
    },
    currentAccountWrapper: {
      backgroundColor: 'rgb(244, 244, 244)',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '14px 30px',
      borderRadius: 5,
      border: '1px solid rgb(227, 227, 227)',
      '&:not(:last-child)': {
        marginBottom: 10,
      },
    },
    accountDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    accountName: {
      fontSize: 16,
      fontWeight: typography.fontWeightSemiBold,
    },
    accountDescription: {
      fontSize: 14,
      fontWeight: typography.fontWeightRegular,
      color: 'rgba(51, 51, 51, 0.5)',
    },
    button: {
      fontSize: 12,
      letterSpacing: 1.5,
      fontWeight: typography.fontWeightSemiBold,
      color: 'rgb(105, 105, 105)',
    },
    buttonIcon: {
      marginRight: 13,
    },
    selectedAccount: {
      marginRight: 18,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        fontSize: 14,
        marginRight: 18,
        color: 'rgb(51, 51, 51)',
      },
    },
    tickIcon: {
      fontSize: 15,
      color: palette.primary.light,
    },
  }), { name: 'SwitchAccountDrawerAccount' },
);

export default useStyles;
