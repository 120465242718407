import React from 'react';
import clsx from 'clsx';
import t from 'utils/translationHelper';

import { Box } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';

import PhoneAuthy from '../../../../assets/phoneAuthy.svg';

import useStyles from './index.style';

const HelperScreen: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, classes.fullOpacity)}>
      <Typography className={classes.title}>
        {t('do_you_authenticate_with_the_authy_app')}
      </Typography>
      <div style={{ display: 'flex' }}>
        <img
          src={PhoneAuthy}
          alt="mobile phone"
          className="assetImage"
        />
        <Box className={classes.contentContainer}>
          <Typography className={classes.content}>
            {t('unfortunately_you_will_no_longer_recieve_push_notifications')}
          </Typography>
          <Typography className={classes.content}>
            {t('you_will_now_need_to_open_the_app_to_view_your_verification_code')}
          </Typography>
        </Box>
      </div>
    </div>
  );
};
export default HelperScreen;
