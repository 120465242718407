import React, { memo } from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button/ActionButton';
import { Button } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import FooterWrapper from '../../../../../components/Payments/FooterWrapper/FooterWrapper';

interface IProps {
  buttonText: string;
  disabled?: boolean;
  handleOnClick: () => void;
  handleExitClick: () => void;
}

const BatchSubmissionFooter: React.FC<IProps> = (props: IProps) => {
  const {
    buttonText, disabled, handleOnClick, handleExitClick,
  } = props;


  return (
    <FooterWrapper>
      <Button
        onClick={handleExitClick}
        startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
      >
        {t('exit')}
      </Button>
      <ActionButton testId="submitPaymentButton" size="large" disabled={disabled} onClick={handleOnClick}>{buttonText}</ActionButton>
    </FooterWrapper>
  );
};

export default memo(BatchSubmissionFooter);
