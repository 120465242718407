import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    column: {
      paddingTop: 12,
      display: 'flex',
      flexDirection: 'column',
      borderTop: '1px solid rgba(208, 208, 208, 0.2)',
      '& > b': {
        paddingBottom: 12,
      },
    },
    spaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > b, & > span': {
        paddingBottom: 7,
      },
    },
    topSection: {
      display: 'flex',
      paddingBottom: 5,
    },
    contentWrapper: {
      paddingTop: 8,
      minWidth: 200,

    },
  }), { name: 'TooltipContent' },
);

export default useStyles;
