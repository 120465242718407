import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    inputField: {
      marginTop: '5px',
    },
    input: {
      marginTop: '24px',
    },
    phoneInput: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '5px',
      '& > div': {
        width: '48%',
      },
    },
    phoneInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '24px',
    },
  }),
  { name: 'UserDetails' },
);

export default useStyles;
