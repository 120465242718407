import { PaymentOrigin } from '@alpha/payments-dtos';

import { FormikPaymentType } from '../../CreatePaymentDrawer/formData';

export const initialValues: FormikPaymentType = {
  emailAddresses: [],
  paymentOrigin: PaymentOrigin.STANDALONE,
  date: null,
};

export default initialValues;
