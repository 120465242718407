import { IAutocompleteSelectOption } from '@alpha/ui-lib/ui/Select';
import { ESortDir } from '@alpha/ui-lib/ui/TableGrid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TPayment, TPendingPayment, TPendingPaymentParams } from '../../models/payments';

export type TNewPayment = {
  id: string;
  name: string;
  sellCurrency: IAutocompleteSelectOption | null;
  buyAmount: string;
  purposeOfPayment: IAutocompleteSelectOption | null;
  paymentReference: string;
  buyCurrency: IAutocompleteSelectOption | null;
  date: string | null;
  country?: string | null;
  iban?: string | null;
  bankCode?: string | null;
  bankName?: string | null;
  swift?: string | null;
  accountNumber?: string | null;
  creditName?: string | null;
  credit?: string | null;
  email?: string | null;
  uploadBy?: string | null;
  uploadDate?: string | null;
};

export type TPaymentsStatus = 'COMPLETED' | 'ERROR' | 'INITIATE';

export type TPaymentsUploadedItemsType = 'VALID' | 'INVALID' | 'REJECTED';

export type TPaymentsUploadedItems = {
  id: string;
  name: string;
  date: number;
  sellCurrency: string;
  currency: string;
  amount: string;
  type: TPaymentsUploadedItemsType;
}

export type TPaymentBatchDetails = {
  batchId?: string,
  status?: string,
  error?: string[],
  fileName?: string,
  noOfRecords?: number;
  processedRecords?: number;
  validRecords?: number;
  invalidRecords?: number;
};

export type TPaymentsStore = {
  data: TPayment[];
  totalRecords: number;
  newItems: TNewPayment[];
  uploadedItems: TPaymentsUploadedItems[];
  loaded: boolean,
  pendingPayments: {
    totalItems: number,
    data: TPendingPayment[],
  },
  batchDetails?: TPaymentBatchDetails,
  paymentIds: string[],
};

export type TPaymentQuery = {
  state?: string;
  orderBy?: string;
  direction?: ESortDir;
  top?: number;
  skip?: number;
  search?: string;
};

export type TBatchStatus = {
}

const initialState: TPaymentsStore = {
  data: [],
  newItems: [],
  totalRecords: 0,
  uploadedItems: [],
  loaded: false,
  pendingPayments: {
    totalItems: 0,
    data: [],
  },
  paymentIds: [],
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    loadPayments: (state, action: PayloadAction<TPaymentQuery>): TPaymentsStore => state,
    loadedPayments: (state, action: PayloadAction<{ data: TPayment[]; totalRecords: number }>): TPaymentsStore => ({ ...state, data: action.payload.data, totalRecords: action.payload.totalRecords }),
    addNewItems: (state, action: PayloadAction<TNewPayment | TNewPayment[]>): TPaymentsStore => ({
      ...state,
      newItems: [...state.newItems, ...(Array.isArray(action.payload) ? action.payload : [action.payload])],
    }),
    updateNewItems: (state, action: PayloadAction<TNewPayment>): TPaymentsStore => ({
      ...state,
      newItems: state.newItems.map((item) => (item.id === action.payload.id ? action.payload : item)),
    }),
    clearNewItems: (state): TPaymentsStore => ({
      ...state,
      newItems: [],
    }),
    removeNewItem: (state, action: PayloadAction<TNewPayment>): TPaymentsStore => ({
      ...state,
      newItems: state.newItems.filter((item) => item.id !== action.payload.id),
    }),
    clear: (state, action: PayloadAction<void>): TPaymentsStore => initialState,
    triggerBatchStatus: (state): TPaymentsStore => state,
    updateBatchId: (state, action: PayloadAction<string>) => ({
      ...state,
      batchDetails: {
        ...state.batchDetails,
        batchId: action.payload,
      },
    }),
    updateBatchStatus: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      batchDetails: {
        ...state.batchDetails,
        status: action.payload,
      },
    }),
    updateBatchDetails: (state, action: PayloadAction<{
      batchId?: string;
      status?: string;
      fileName?: string;
      noOfRecords?: number;
      processedRecords?: number;
      validRecords?: number;
      invalidRecords?: number;
    }>) => ({ ...state, batchDetails: action.payload }),
    clearBatchDetails: (state) => ({
      ...state, batchDetails: {},
    }),
    reuploadPayment: (state) => initialState,
    loadPendingPayments: (state, action: PayloadAction<TPendingPaymentParams>): TPaymentsStore => ({ ...state, loaded: false }),
    loadedPendingPayments: (state, action: PayloadAction<{ totalItems: number, data: TPendingPayment[] }>): TPaymentsStore => ({
      ...state,
      loaded: true,
      pendingPayments: {
        totalItems: action.payload.totalItems,
        data: action.payload.data,
      },
    }),
  },
});

export const { actions } = paymentsSlice;
