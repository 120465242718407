import React from 'react';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';

import Alert from '../../../components/Alert';

import Form from './Form';
import useStyles from './styles';

interface IProps {
  open: boolean;
  firstPartyFlow: boolean;
  handleDrawerClose: () => void;
  header?: string;
  draftBeneficiary?: BeneficiaryDto,
  updateDrawer?: boolean,
  refetch?: any,
}

const CreateBeneficiary: React.FC<IProps> = (
  {
    open,
    handleDrawerClose,
    draftBeneficiary,
    header,
    firstPartyFlow,
    updateDrawer,
    refetch,
  }: IProps,
) => {
  const styles = useStyles();

  return (
    <BaseDrawer.Drawer
      open={Boolean(open)}
      anchor="right"
      onClose={handleDrawerClose}
      className={styles.drawer}
    >
      <BaseDrawer.Header headerTitle={header || t('create_beneficiary')} headerSubtitle="">
        <div style={{ marginTop: '16px', display: updateDrawer ? 'none' : undefined }}>
          {firstPartyFlow ? (
            <Alert
              text={t('beneficiary_first_party_only_warning')}
              variant="info"
            />
          ) : (
            <Alert
              text={t('beneficiary_authorisation_warning')}
              variant="info"
            />
          )}
        </div>
      </BaseDrawer.Header>
      <div className={styles.lineBreakMargin}>
        <BaseDrawer.LineBreak />
      </div>
      <div className={styles.body}>
        <Form
          handleDrawerClose={handleDrawerClose}
          selectedBeneficiary={draftBeneficiary}
          firstPartyFlow={firstPartyFlow}
          updateDrawer={updateDrawer}
          refetch={refetch}
        />
      </div>
    </BaseDrawer.Drawer>

  );
};

export default CreateBeneficiary;
