import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import Warning from '../../../../../../../assets/warning.svg';
import APModal from '../../../../../../Modals/APModal/APModal';
import useStyles from '../../../../../Payments/RejectPaymentModal/index.styles';
import t from 'utils/translationHelper';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const PaymentIncompleteWarningModal: React.FC<Props> = (
  { open, handleSubmit, handleClose }: Props,
) => {
  const styles = useStyles();


  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div style={{ textAlign: 'center', padding: '8px', width: '400px' }}>
          <img src={Warning} style={{ width: '70px', marginBottom: '24px' }} alt="Warning" />
          <div style={{ marginBottom: '48px' }}>
            <Typography style={{ fontWeight: '800', marginBottom: '16px', fontSize: '16px' }}>{t('payment_incomplete')}</Typography>
            <Typography>
              {t('you_have_an_incomplete_payment_option')}
            </Typography>
          </div>
          <div style={{ width: '100%' }}>
            <ActionButton style={{ width: '170px' }} onClick={handleSubmit}>{t('acknowledge')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default PaymentIncompleteWarningModal;
