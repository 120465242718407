import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    rightAlignedCtas: {
      display: 'flex',
      alignItems: 'flex-start',
    },
  }),
  { name: 'HedgingProgramsCtas' },
);

export default useStyles;
