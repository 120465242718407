import React from 'react';

import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { Box, Typography } from '@alpha/ui-lib/ui/external';

import useStyles from './index.styles';

interface IProps {
  text: string;
  id: string;
  checked: boolean;
  disabled?: boolean;
  handleChange: () => void;
}

const SelectAccount: React.FC<IProps> = ({
  id,
  text,
  disabled,
  checked,
  handleChange,
}: IProps) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.accountwrapper}
    >
      <Box className={classes.accountDetails}>
        <Checkbox
          onChange={handleChange}
          checked={checked}
          testId={`test-id-${id}`}
          label={<Typography className={classes.accountName}>{text}</Typography>}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

export default SelectAccount;
