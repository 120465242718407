/* eslint-disable max-lines-per-function */
import clipboardCross from 'assets/clipboardCross.svg';
import IATInfoDrawer from 'components/Drawer/IATDrawer/IATInfo/IATInfo';
import ApprovalsDrawer from 'components/Molecules/Transfer/ApprovalDrawer';
import RejectTransferModal from 'components/Molecules/Transfer/RejectTransferModal';
import EmptyTable from 'components/Table/EmptyTable';
import TransferActionDropDown from 'domain/Transactions/Dashboard/FxTradeTable/DisplayTable/ActionDropDown/TransferActionDropDown';
import NoOfApprovers from 'domain/Transactions/Dashboard/FxTradeTable/DisplayTable/IATNoOfApprovers/NoOfApprovers';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useSwitchAccount from 'hooks/useSwitchAccount';
import React, {
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import browserHistory from 'services/history/browserHistory';
import IATService from 'services/IAT/IAT.service';
import { TStore } from 'store';
import { TAuthyState } from 'store/authy/reducer';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapTransferStatusToDisplay from 'utils/transfers/mapTransferStatusToDisplay';
import t from 'utils/translationHelper';

import { CurrencyAccountTransferDto, CurrencyAccountTransferStatus } from '@alpha/currency-accounts-dtos';
import { Box, Button } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { Status } from '@alpha/ui-lib/ui/Status';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { datadogLogs } from '@datadog/browser-logs';

import { OutstandingActionsContext } from '..';
import useStyles from '../index.styles';
import useOutstandingActionCache from '../useOutstandingActionCache';

const TransferApprovals: React.FC = () => {
  const classes = useStyles();
  const { currentAccount } = useSwitchAccount();
  const CACHE_KEY = `${currentAccount.id}-pending-transfers`;

  const [pendingApprovalTransfers, setPendingApprovalTransfers] = useState<CurrencyAccountTransferDto[]>([]);
  const [transfer, setTransfer] = useState<CurrencyAccountTransferDto>();

  const { saveResponse, getResponse } = useOutstandingActionCache();

  const { pendingTransfersCount, setPendingTransfersCount } = useContext(OutstandingActionsContext);

  const [transferToReject, setTransferToReject] = useState<CurrencyAccountTransferDto>();
  const [rejectedTransferId, setRejectedTransferId] = useState<string>();
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);

  const [transferToApprove, setTransferToApprove] = useState<CurrencyAccountTransferDto>();
  const [approvalDrawerOpen, setApprovalDrawerOpen] = useState<boolean>(false);
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);
  const [textValue, setTextValue] = useState('');

  const SHOW_LIMIT = 5;

  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  const sb = useAlphaSnackbar();

  const loadPendingTransfers = async () => {
    try {
      const {
        total,
        records: pendingTransfers,
      } = await IATService.getPendingApprovalTransfers();

      setPendingApprovalTransfers(pendingTransfers);
      if (setPendingTransfersCount) { setPendingTransfersCount(total); }
      saveResponse(CACHE_KEY, pendingTransfers);
      saveResponse(`${CACHE_KEY}_count`, total);
    } catch (e) {
      if (typeof e === 'string') {
        datadogLogs.logger.error(e);
      } else if (e instanceof Error) {
        datadogLogs.logger.error(e.message);
      }
    }
  };

  // Handle reload data after actions
  useEffect(() => {
    if (
      authyState.type?.type === 'TRANSFERS'
    ) {
      setTimeout(() => {
        loadPendingTransfers();
        setTransfer(undefined);
      }, 1000);
    } else if (authyState.type?.type === 'TRANSFER_APPROVE' && authyState.status === 'SUCCESS') {
      sb.trigger(t('your_transfer_was_successfully_approved'), 'success');
      setTimeout(() => {
        loadPendingTransfers();
        setTransfer(undefined);
      }, 1000);
    }
  }, [authyState.status, authyState.type, browserHistory.push]);

  useEffect(() => {
    if (rejectedTransferId) {
      setTimeout(() => {
        loadPendingTransfers();
      }, 1000);
      setTransfer(undefined);
    }
  }, [rejectedTransferId]);
  // End Handle reload data after actions

  useEffect(() => {
    try {
      const cachedTransfers = getResponse<CurrencyAccountTransferDto[]>(CACHE_KEY);
      const cachedCount = getResponse<number>(`${CACHE_KEY}_count`);
      if (cachedTransfers) {
        setPendingApprovalTransfers(cachedTransfers as CurrencyAccountTransferDto[]);
      }
      if (cachedCount) {
        if (setPendingTransfersCount) { setPendingTransfersCount(cachedCount); }
      }
    } finally {
      loadPendingTransfers();
    }
  }, []);

  const handleTransferButton = (transfer: CurrencyAccountTransferDto) => {
    setTransfer(transfer);
  };

  const handleApproveTransfer = (transfer: CurrencyAccountTransferDto): void => {
    setTransferToApprove(transfer);
    setApprovalDrawerOpen(true);
  };

  const handleRejectTransfer = (transfer: CurrencyAccountTransferDto): void => {
    setTransferToReject(transfer);
    setRejectModalOpen(true);
  };

  const columns: IStyledGenericTableProps['columns'] = [{
    header: t('id'),
    accessor: 'contractNumber',
    width: 100,
  },
  {
    header: t('account'),
    accessor: 'account',
    align: 'left',
  },
  {
    header: t('upload_date'),
    accessor: 'uploadedDate',
    align: 'left',
  },
  {
    header: t('amount'),
    accessor: 'amount',
    align: 'left',
  },
  {
    header: t('currency'),
    accessor: 'currency',
    align: 'left',
  },
  {
    header: t('approvals'),
    accessor: 'approvals',
    align: 'left',
  },
  {
    header: t('status'),
    accessor: 'status',
    align: 'left',
  }, {
    header: t('actions'),
    accessor: 'actions',
    align: 'left',
  }];

  const data = useMemo((): IStyledGenericTableProps['data'] => pendingApprovalTransfers?.slice(0, SHOW_LIMIT)?.map((transfer) => ({
    ...transfer,
    contractNumber: (
      <Button
        disableRipple
        className={classes.tableButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          handleTransferButton(transfer);
        }}
      >
        {transfer.contractNumber || '-'}
      </Button>),
    amount: (
      <span className={classes.amount}>
        {formatNumber(transfer.instructedAmount, 2)}
      </span>),
    account: transfer.fundingCurrencyAccountName || '-',
    uploadedDate: formatIsoDate(transfer.submittedDate),
    currency: (
      <div className={classes.currencyFlag}>
        <Flag code={transfer.instructedCurrency} size="sm" />
        {' '}
        {transfer.instructedCurrency}
      </div> || '-'),
    approvals: (transfer
      && (
        <NoOfApprovers
          uploadedBy={transfer.submittedBy}
          uploadedDate={transfer.submittedDate}
          noOfApprovals={transfer.approvalInfo?.approvals.length}
          approvedBy={transfer.approvalInfo?.approvals || []}
          approvalsRequired={transfer.approvalInfo?.approvalsRequired.reduce((sum, curr) => sum + curr.number, 0)}
        />
      )
    ) || '-',
    status: (
      <Status
        className={classes.status}
        variant={mapTransferStatusToDisplay(transfer.transferStatus).variant}
      >
        {t(mapTransferStatusToDisplay(transfer.transferStatus).text) || '-'}
      </Status>),
    actions: (
      <TransferActionDropDown
        transfer={transfer}
        setDisplayMultiSelect={setDisplayMultiSelect}
        displayMultiSelect={displayMultiSelect}
        setSelectedTransfer={setTransfer}
        handleReloadTransferTable={undefined}
        handleApproveTransfer={handleApproveTransfer}
        handleRejectTransfer={handleRejectTransfer}
        currentOptions={undefined}
        setTextValue={setTextValue}
      />
    ),
  }
  )), [pendingApprovalTransfers]);

  return (
    <Box style={{
      overflow: 'auto',
      height: '230px',
    }}
    >
      {data?.length > 0 ? (
        <>
          <StyledGenericTable
            className={classes.stickyHeaderTable}
            testId="pending-transfers-table"
            columns={columns}
            data={data}
          />

          {pendingTransfersCount && pendingTransfersCount > SHOW_LIMIT ? (
            <div className={classes.showAllMessage}>
              <span>
                {t('showing_top')}
                {' '}
                {SHOW_LIMIT}
                {' '}
                {t('items, please click view all to view all items.')}
              </span>
            </div>
          ) : null}
        </>
      ) : (
        <EmptyTable
          title={t('no_pending_transfer_approvals')}
          subtitle={t('any_pending_transfer_approval_requests_will_appear_here')}
          className={classes.emptyTable}
          icon={clipboardCross}
        />
      )}

      <IATInfoDrawer
        selectedTransfer={transfer}
        open={Boolean(transfer)}
        handleDrawerClose={() => setTransfer(undefined)}
      />
      {
        (
          transferToApprove
          && transferToApprove.transferStatus === CurrencyAccountTransferStatus.SUBMITTED)
        && (
          <ApprovalsDrawer
            open={approvalDrawerOpen}
            onClose={() => setApprovalDrawerOpen(false)}
            selectedTransfer={transferToApprove}
          />
        )
      }
      {
        (
          transferToReject
          && transferToReject.transferStatus === CurrencyAccountTransferStatus.SUBMITTED)
        && (
          <RejectTransferModal
            open={rejectModalOpen}
            handleClose={() => setRejectModalOpen(false)}
            transfer={transferToReject}
            handleRejected={(rejectedId: string) => {
              setRejectedTransferId(rejectedId);
            }}
          />
        )
      }
    </Box>
  );
};
export default TransferApprovals;
