import React from 'react';
import t from 'utils/translationHelper';

import { UserAccountDto } from '@alpha/auth-dtos';
import { Typography } from '@alpha/ui-lib/ui/external';

import MultiSelectEntityDropDown from '../../../../../components/MultiSelectDropDown/MultiSelectEntityDropdown/MultiSelectEntityDropdown';

import useStyles from './DropDownWrapper.styles';

interface IProps {
  loading: boolean;
  menuCloseCount: number;
  setMenuCloseCount: React.Dispatch<React.SetStateAction<number>>;
  currentOptions: UserAccountDto[];
  setCurrentOptions: React.Dispatch<React.SetStateAction<UserAccountDto[]>>;
  setTextValue: React.Dispatch<React.SetStateAction<string>>;
  eligibleEntities: UserAccountDto[];
  id?: string;
}

const DropDownWrapper = ({
  loading,
  menuCloseCount,
  setMenuCloseCount,
  eligibleEntities,
  setTextValue,
  currentOptions,
  setCurrentOptions,
  id,
}: IProps) => {
  const classes = useStyles();


  return (
    <div className={classes.container}>
      <Typography>{t('entities')}</Typography>
      <MultiSelectEntityDropDown
        onClose={() => setMenuCloseCount(menuCloseCount + 1)}
        onOpen={() => setMenuCloseCount(0)}
        loading={loading}
        options={eligibleEntities}
        currentOptions={currentOptions}
        setCurrentOptions={setCurrentOptions}
        setTextValue={setTextValue}
        id={id}
      />
    </div>
  );
};

export default DropDownWrapper;
