import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import t from 'utils/translationHelper';

interface IProps {
  handleOpenAccountRequestModal: () => void;
}

const RightAlignedCta: React.FC<IProps> = ({
  handleOpenAccountRequestModal,
}: IProps) => {

  return (
    <Box flex="column">
      <ActionButton
        testId="request-currency-account-btn"
        size="medium"
        rounded
        style={{ marginRight: '10px' }}
        onClick={handleOpenAccountRequestModal}
      >
        {t('request_new_currency_account')}
      </ActionButton>
    </Box>
  );
}

export default RightAlignedCta;
