import React from 'react';
import t from 'utils/translationHelper';

import { BaseDrawer, LineBreak } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { StyledKeyValueTable } from '@alpha/ui-lib/ui/table/StyledKeyValueTable';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import { formatCurrency } from '../../../../utils/currency.helpers';
import { formatCurrencyAccountBankCode } from '../../../../utils/formatCurrencyAccountBankCode';
import { ISelectedAccount } from '../AccountInfoDrawer';

interface IBody {
  selectedAccount: ISelectedAccount,
  eMoneyDisabled?: boolean;
}

const CurrencyAccountInfo: React.FC<IBody> = (props: IBody) => {
  const { selectedAccount, eMoneyDisabled } = props;

  const details = [
    {
      key: t('currency'),
      value: selectedAccount.account ? selectedAccount.account.currencyCode : '-',
      boldValue: true,
    },
    { key: t('bank_name'), value: selectedAccount.account.bankName || '-' },
    { key: t('swift/bic'), value: selectedAccount.account.swift || '-' },
    {
      key: t('iban'),
      value: selectedAccount.account.iban
        ? selectedAccount.account.iban.toUpperCase()
        : '-',
    },
    { key: t('account_number'), value: selectedAccount.account.accountNumber || '-' },
    { key: t('national_bank_code'), value: selectedAccount.account.sortCode ? formatCurrencyAccountBankCode(selectedAccount.account.sortCode, selectedAccount.account.countryCode) : '-' },
    { key: t('account_name'), value: selectedAccount.account.accountName || '-' },
    selectedAccount.account.type === 'Wallet'
      ? { key: t('reference_IT_'), value: selectedAccount.account.reference || '-' }
      : { key: '', value: '' },
  ];
  return (
    <div>
      {!eMoneyDisabled && (
        <>
          <div style={{ paddingTop: '40px', paddingBottom: '32px' }}>
            <BaseDrawer.Body>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '32px',
              }}
              >
                <Typography variant="h4">
                  {t('cleared_balance')}
                </Typography>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  {formatCurrency(selectedAccount?.account.currencyCode,
                    selectedAccount?.account.clearedBalance)}
                </Typography>
              </div>
            </BaseDrawer.Body>
            <LineBreak />
          </div>
        </>
      )}
      <BaseDrawer.Body>
        <>
          <div style={{ paddingTop: '32px' }}>
            <Typography variant="h3" style={{ paddingBottom: '5px' }}>
              {t('alpha_fx_bank_details')}
            </Typography>
            <Typography variant="h4">
              {t('details_to_pay_into_for_settlement_of_trades')}
            </Typography>
          </div>
          <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>
            <StyledKeyValueTable testId="account-drawer-body" data={details} />
          </div>
        </>
      </BaseDrawer.Body>
    </div>
  );
};

export default CurrencyAccountInfo;
