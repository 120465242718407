import React, { useEffect } from 'react';

import { Typography } from '@alpha/ui-lib/ui/external';
import t from 'utils/translationHelper';

import routes from '../../../../routes.path';
import browserHistory from '../../../../services/history/browserHistory';
import useFormStyles from '../../form.styles';

const Success = () => {
  const formClasses = useFormStyles();

  const redirectUserToDashboard = () => setInterval(() => {
    browserHistory.push(routes.auth.login);
  }, 4000);

  useEffect(() => {
    const interval = redirectUserToDashboard();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Typography className={formClasses.title}>
        {t('password_successfully_reset')}
      </Typography>
      <Typography className={formClasses.subtitle}>
        {t('redirecting_you_back_to_the_login_page')}
      </Typography>
    </div>
  );
};

export default Success;
