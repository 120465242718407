import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    titleContent: {
      display: 'flex',
      alignItems: 'center',
      '& > span:first-child': {
        position: 'relative',
        '&:before': {
          position: 'absolute',
          right: '-20px',
          content: '""',
          height: 40,
          border: '1px solid rgba(112, 112, 112, 0.1)',
        },
      },
    },
  }), { name: 'TitleContainer' },
);

export default useStyles;
