import { useEffect, useState } from 'react';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import { useLocation } from 'react-router-dom';
import CreditService from 'services/Credit/credit.service';
import t from 'utils/translationHelper';

import { IAutocompleteSelectMultiOption } from '@alpha/ui-lib/ui/Select/MultiAutoCompleteSelect';

const useMtmFilter = (
  mtmDate: string,
  termId?: string,
  setBuyCurrencies?: React.Dispatch<React.SetStateAction<string[]>>,
  setSellCurrencies?: React.Dispatch<React.SetStateAction<string[]>>,
  setTermsIds?: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  const sb = useAlphaSnackbar();
  const { logError } = useLog();


  const [filterMenuOpen, setFilterMenuOpen] = useState<null | HTMLElement>(null);
  const [buyCurrenciesFilter,
    setBuyCurrenciesFilter] = useState<IAutocompleteSelectMultiOption[]>();
  const [sellCurrenciesFilter,
    setSellCurrenciesFilter] = useState<IAutocompleteSelectMultiOption[]>();
  const [termsIdFilter,
    setTermsIdFilter] = useState<IAutocompleteSelectMultiOption>();
  const [soldCurrenciesFilterOptions, setSoldCurrenciesFilterOptions] = useState<string[]>([]);
  const [buyCurrenciesFilterOptions, setBuyCurrenciesFilterOptions] = useState<string[]>([]);
  const [termsIdsFilterOptions, setTermsIdsFilterOptions] = useState<string[]>([]);
  const location = useLocation();

  const getFilterOptions = async (requestMtmDate: string) => {
    let res;
    if (location.pathname.includes('hedging') || termId) {
      res = await CreditService.getMtmFilterOptions(requestMtmDate, termId, true);
    } else if (!termId && location.pathname.includes('credit')) {
      res = await CreditService.getFacilityMtmFilterOptions(requestMtmDate, true);
    }
    return res;
  };

  const updateFilterOptions = async (requestMtmDate: string): Promise<void> => {
    try {
      const filterOptions = await getFilterOptions(requestMtmDate);

      if (filterOptions?.soldCurrencies) {
        setSoldCurrenciesFilterOptions(filterOptions.soldCurrencies);
      }

      if (filterOptions?.buyCurrencies) {
        setBuyCurrenciesFilterOptions(filterOptions.buyCurrencies);
      }
      if (filterOptions?.terms) {
        setTermsIdsFilterOptions(filterOptions.terms);
      }
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_filter_options'));
      logError({ action: 'Error loading filter options', error });
    }
  };

  const clearAllFilters = (): void => {
    setBuyCurrenciesFilter([]);
    setSellCurrenciesFilter([]);
    setTermsIdFilter(undefined);
    if (setBuyCurrencies) { setBuyCurrencies([]); }
    if (setSellCurrencies) { setSellCurrencies([]); }
    if (setTermsIds) { setTermsIds([]); }
  };

  const applyChanges = (): void => {
    if (setBuyCurrencies && buyCurrenciesFilter) {
      setBuyCurrencies(buyCurrenciesFilter.map(
        (filter) => String(filter.value),
      ));
    }
    if (setSellCurrencies && sellCurrenciesFilter) {
      setSellCurrencies(sellCurrenciesFilter.map(
        (filter) => String(filter.value),
      ));
    }
    if (setTermsIds && termsIdFilter) {
      setTermsIds([String(termsIdFilter?.value)]);
    }
  };

  const mapCurrencyFilterOptions = (options: string[]) => options.map((option) => (
    { value: option, label: option }
  ));

  const mapTermFilterOptions = (options: any) => options.map((option) => (
    { value: option?.id, label: option.displayName }
  ));

  useEffect(() => {
    if (mtmDate) {
      updateFilterOptions(mtmDate);
    }
    clearAllFilters();
  }, [mtmDate]);

  return {
    mapCurrencyFilterOptions,
    mapTermFilterOptions,
    setBuyCurrenciesFilter,
    buyCurrenciesFilter,
    sellCurrenciesFilter,
    setSellCurrenciesFilter,
    termsIdFilter,
    setTermsIdFilter,
    applyChanges,
    setFilterMenuOpen,
    clearAllFilters,
    filterMenuOpen,
    buyCurrenciesFilterOptions,
    soldCurrenciesFilterOptions,
    termsIdsFilterOptions,
  };
};

export default useMtmFilter;
