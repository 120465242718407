/* eslint-disable max-lines-per-function */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import useSwitchAccount from 'hooks/useSwitchAccount';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BeneficiaryService from 'services/Beneficiaries/beneficiaries.service';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import { TStore } from 'store';
import { actions, TBeneficariesStore } from 'store/beneficiaries/beneficiaries.reducer';
import mapBeneBatchStatusDisplay from 'utils/benes/mapBeneBatchStatusDisplay';
import timer from 'utils/timer';
import t from 'utils/translationHelper';

import { BeneficiaryBatchDto, BeneficiaryBatchStatus, BeneficiaryDto } from '@alpha/bene-dtos';
import { Box, CircularProgress } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import APPagination from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { TabContent } from '@alpha/ui-lib/ui/Tabs';

import Search from '../../../components/Organisms/Search';
import useShareBeneficiaryDrawer from '../../../hooks/useShareBeneficiaryDrawer';
import browserHistory from '../../../services/history/browserHistory';
import CreateBeneficiary from '../CreateBeneficiary';

import BeneActionBar from './BeneActionBar/BeneActionBar';
import BeneficiaryActionDropDown from './BeneficiaryActionDropdown/BeneficiaryActionDropDown';
import BeneficiaryListTable from './BeneficiaryListTable/BeneficiaryListTable';
import { BeneInfoDrawer } from './BeneInfoDrawer/BeneInfoDrawer';
import { BeneShareDrawer } from './BeneShareDrawer/index';
import useStyles from './Body.styles';
import DeleteOrRejectModal from './DeleteOrRejectModal';
import useBody from './useBody';

interface IBodyProps {
  selectedTabIndex: number;
  createBeneficiaryOpen: boolean,
  selectedDraftBeneficiary?: BeneficiaryDto;
  setSelectedDraftBeneficiary?: React.Dispatch<React.SetStateAction<BeneficiaryDto | undefined>>,
  setCreateBeneficiaryOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  multiSelectedBenes?: BeneficiaryDto[];
  setMultiSelectedBenes: React.Dispatch<React.SetStateAction<BeneficiaryDto[]>>;
  handleOpenApprovalDrawer: () => void;
  handleApproveBenes?: (benes: BeneficiaryDto[]) => void;
}

enum TabName {
  APPROVED = 0,
  PENDING = 1,
  REJECTED = 2,
  DRAFT = 3,
  BATCH = 4,
}

export type ModalType = 'reject' | 'delete'

const defaultSortBy = 'uploadedByDate';
const defaultSortOrder = 'desc';

const Body: React.FC<IBodyProps> = ({
  createBeneficiaryOpen,
  selectedTabIndex,
  setSelectedTabIndex,
  setCreateBeneficiaryOpen,
  selectedIds,
  setSelectedIds,
  selectedDraftBeneficiary,
  setSelectedDraftBeneficiary,
  setMultiSelectedBenes,
  handleOpenApprovalDrawer,
  multiSelectedBenes,
  handleApproveBenes,
}: IBodyProps) => {
  const {
    searchParams,
    tableSearch,
    selectedBeneficiary,
    setSelectedBeneficiary,
    location,
    authyState,
    loading,
    modalOpen,
    beneToDelete,
    setBeneToDelete,
    setModalOpen,
    calculateTabFromUrl,
    handleInputChange,
    handleOnDeleteClick,
    handleApprovedTableRowClick,
    handleCloseModal,
    handleOnRejectionClick,
    handleRejectTableRowClick,
    handlePendingTableRowClick,
  } = useBody(selectedTabIndex);
  const {
    accountBeneId,
    availableAccounts,
    handleBeneficiaryShareButtonClick,
  } = useShareBeneficiaryDrawer(setSelectedBeneficiary);
  const benesUploadState = useSelector<TStore, TBeneficariesStore>((state) => state.beneficiaries);

  const styles = useStyles();
  const { isEMoneyDisabled } = useSwitchAccount();
  const showApproval = multiSelectedBenes && multiSelectedBenes.length > 0;
  const [beneBatchRecords, setBeneBatchRecords] = useState<BeneficiaryBatchDto[]>([]);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const dispatch = useDispatch();
  const [updateDrawer, setUpdateDrawer] = useState<boolean>(false);

  useLayoutEffect(() => {
    setSelectedTabIndex(calculateTabFromUrl());
  }, [location.search]);

  const [pageNumber, setPageNumber] = useState(0);
  const totalPages = Math.ceil(beneBatchRecords?.length / 10);
  const currentData = beneBatchRecords.slice(pageNumber * 10, (pageNumber + 1) * 10);
  const hasPrev = () => pageNumber > 0;
  const hasNext = () => (pageNumber + 1) < totalPages;
  const currentItems = hasNext() ? (pageNumber + 1) * 10 : beneBatchRecords.length;

  const handleNext = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handlePrev = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const batchColumns = [
    {
      header: t('batch_id'), accessor: 'batchId', align: 'left', sortable: true,
    },
    {
      header: t('name'), accessor: 'name', align: 'left', sortable: true,
    },
    {
      header: t('upload_date'), accessor: 'uploadDate', align: 'left', sortable: true,
    },
    {
      header: t('valid_benes'), accessor: 'validRecords', align: 'center', sortable: true,
    },
    {
      header: t('Warnings'), accessor: 'invalidRecords', align: 'center', sortable: true,
    },
    {
      header: t('rejected_benes'), accessor: 'erroredRecords', align: 'center', sortable: true,
    },
    {
      header: t('status'), accessor: 'status', align: 'left',
    },
    { header: t('actions'), accessor: 'actions', align: 'center' },
  ] as ITableColumn[];

  useEffect(() => {
    mapBeneObjectWithSelectedIds(selectedIds);
  }, [selectedIds]);

  const mapBeneObjectWithSelectedIds = (selectedBeneIds: string[]): void => {
    const resultBenes: BeneficiaryDto[] = multiSelectedBenes?.filter(
      (bene) => selectedBeneIds?.includes(bene.id),
    ) || [];

    const multiSelectedBeneIds = multiSelectedBenes?.map((bene) => bene.id);
    const newlySelectedIds = selectedBeneIds?.filter(
      (beneId) => !multiSelectedBeneIds?.includes(beneId),
    );

    const beneData: BeneficiaryDto[] = (tableSearch.items?.items?.records as BeneficiaryDto[]) || [];
    const newlySelected: BeneficiaryDto[] = beneData.filter(
      (item) => newlySelectedIds.includes(item.id),
    );

    resultBenes.push(...newlySelected);

    setMultiSelectedBenes(resultBenes);
  };

  useEffect(() => {
    tableSearch.setItems(undefined);
    searchParams.queryParams.sortby = defaultSortBy;
    searchParams.queryParams.sortorder = defaultSortOrder;
    tableSearch.handleInitialSearch(searchParams);
  }, [selectedTabIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        authyState.type?.type === 'BENEFICIARY'
        && authyState.status === 'SUCCESS'
      ) {
        setSelectedBeneficiary(undefined);
        tableSearch.handleNewSearch(searchParams);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [authyState.status, authyState.type, browserHistory.push]);

  const handleDraftedEditButtonClick = (clickedBeneficiary: BeneficiaryDto) => {
    if (setCreateBeneficiaryOpen) { setCreateBeneficiaryOpen(true); }
    if (setSelectedDraftBeneficiary) { setSelectedDraftBeneficiary(clickedBeneficiary); }
  };

  const handleApprovedEditButtonClick = (clickedBeneficiary: BeneficiaryDto) => {
    if (setCreateBeneficiaryOpen) { setCreateBeneficiaryOpen(true); }
    if (setSelectedDraftBeneficiary) { setSelectedDraftBeneficiary(clickedBeneficiary); }
    setSelectedBeneficiary(undefined);
    setUpdateDrawer(true);
  };

  const handlePendingReviewButtonClick = (clickedBeneficiary: BeneficiaryDto) => {
    handlePendingTableRowClick(clickedBeneficiary, false);
  };

  const handleCreateBeneficiaryDrawerClose = () => {
    if (setCreateBeneficiaryOpen) { setCreateBeneficiaryOpen(false); }
    if (setSelectedDraftBeneficiary) { setSelectedDraftBeneficiary(undefined); }
    setUpdateDrawer(false);
  };

  const handleClearButtonClick = () => {
    setSelectedIds([]);
  };

  const tableData = () => currentData.map((uploadRecord) => ({
    ...uploadRecord,
    batchId: <Link onClick={() => { dispatch(actions.updateBatchDetails(uploadRecord)); }} to={`/app/beneficiaries/upload-summary/${uploadRecord.id}`}>
      <div className={styles.underlinedGreenText}>{uploadRecord.friendlyId}</div>
             </Link>,
    name: <span>{uploadRecord.fileName}</span>,
    uploadDate: uploadRecord.uploadedDate ? moment(uploadRecord.uploadedDate).format('DD/MM/YYYY') : '-',
    validRecords: <div style={{ color: 'green' }}>
      {uploadRecord.processedRecords < uploadRecord.noOfRecords && uploadRecord.status !== BeneficiaryBatchStatus.COMPLETED ? <CircularProgress size={14} className={styles.spinner} /> : uploadRecord.validRecords}
                  </div>,
    invalidRecords: <div style={{ color: '#A62B23' }}>
      {uploadRecord.processedRecords < uploadRecord.noOfRecords && uploadRecord.status !== BeneficiaryBatchStatus.COMPLETED ? <CircularProgress size={14} className={styles.spinner} /> : uploadRecord.invalidRecords}
                    </div>,
    erroredRecords: <div style={{ color: '#A62B23' }}>
      {uploadRecord.processedRecords < uploadRecord.noOfRecords && uploadRecord.status !== BeneficiaryBatchStatus.COMPLETED ? <CircularProgress size={14} className={styles.spinner} /> : uploadRecord.erroredRecords}
                    </div>,
    status: (<Status variant={mapBeneBatchStatusDisplay(uploadRecord.status).variant}>
      {t(mapBeneBatchStatusDisplay(uploadRecord.status).text) || '-'}
             </Status>),
    actions: (
      <BeneficiaryActionDropDown
        beneficiary={uploadRecord}
        setSelectedBeneficiary={setSelectedBeneficiary}
        tabTitle="batch"
      />
    ),
  }));

  const pollIndividualBatch = async (delay = 5000, id?: string) => {
    try {
      if (id) {
        const batch = await BeneficiaryService.getBatchBeneSummary(id);
        const statusNotToPoll = [BeneficiaryBatchStatus.FAILED, BeneficiaryBatchStatus.COMPLETED, BeneficiaryBatchStatus.INVALID];
        const pollCondition = ((batch.processedRecords === 0 && batch.noOfRecords === 0 && !statusNotToPoll.includes(batch.status))
          || (batch.processedRecords < batch.noOfRecords && !statusNotToPoll.includes(batch.status)));
        if (pollCondition) {
          await timer(delay);
          loadUploadHistory();
          pollIndividualBatch(5000, batch.id);
          dispatch(actions.updateBatchDetails([]));
        }
      } else {
        loadUploadHistory();
        dispatch(actions.updateBatchDetails([]));
      }
    } catch (e) {
      logError({ action: 'Error polling batch', e });
    }
  };

  const loadUploadHistory = async () => {
    try {
      const beneUploadHistory = await BeneficiaryService.getAllBatches();
      setBeneBatchRecords(beneUploadHistory);
    } catch (error) {
      sb.trigger(error?.message || t('error_loading_upload_history'));
      logError({ action: 'Error loading upload history', error });
    }
  };

  useEffect(() => {
    loadUploadHistory();
  }, [authyState?.status, benesUploadState?.batchDetails?.batchId]);

  useEffect(() => {
    if (selectedTabIndex === 4 || benesUploadState.batchDetails) {
      pollIndividualBatch(5000, benesUploadState?.batchDetails?.batchId);
    }
  }, [selectedTabIndex, benesUploadState.batchDetails?.batchId]);

  if (tableSearch.loading && selectedTabIndex === 4) {
    return (<Loader testId="loader" size="50px" style={{ margin: '20%' }} />);
  }

  return (
    <>
      <div className={styles.searchBar}>
        {selectedTabIndex !== 4 ? (
          <Search
            testId="search-beneficiaries"
            value={tableSearch.searchText}
            totalItems={tableSearch.items?.items.total}
            placeholder={t('search_beneficiary_name')}
            loading={tableSearch.loading}
            onChange={handleInputChange}
          />
        ) : null}
      </div>
      <div className={styles.tabContainer}>
        <TabContent index={TabName.APPROVED} value={selectedTabIndex} data-testid="approved-tab">
          <BeneficiaryListTable
            hasNext={tableSearch.items?.hasNext || false}
            hasPrevious={tableSearch.items?.hasPrevious || false}
            handleNextPage={() => tableSearch.handleNextPage(searchParams)}
            handlePreviousPage={() => tableSearch.handlePreviousPage(searchParams)}
            loading={tableSearch.loading}
            tableRawData={tableSearch.items?.items}
            handleTableSortClick={(column: string) => {
              tableSearch.handleTableSortClick(
                searchParams, column,
              );
            }}
            sortOrder={tableSearch.sortOrder}
            sortBy={tableSearch.sortBy}
            handleTableRowClick={
              (e: BeneficiaryDto) => handleApprovedTableRowClick(e, true)
            }
            skip={tableSearch.skip}
            tableType="approved"
            testId="bene-approved-table"
            emptyTitle={t('no_approved_beneficiaries')}
            emptySubTitle={t('you_currently_do_not_have_any_approved_beneficiaries')}
            handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
            setDeleteModalOpen={setModalOpen}
            setSelectedBeneficiary={setBeneToDelete}
            handleApprovedEditButtonClick={handleApprovedEditButtonClick}
          />
        </TabContent>
        <TabContent index={TabName.PENDING} value={selectedTabIndex} data-testid="pending-tab">
          <BeneficiaryListTable
            hasNext={tableSearch.items?.hasNext || false}
            hasPrevious={tableSearch.items?.hasPrevious || false}
            handleNextPage={() => tableSearch.handleNextPage(searchParams)}
            handlePreviousPage={() => tableSearch.handlePreviousPage(searchParams)}
            loading={tableSearch.loading}
            tableRawData={tableSearch.items?.items}
            handleTableSortClick={(column: string) => {
              tableSearch.handleTableSortClick(
                searchParams, column,
              );
            }}
            sortOrder={tableSearch.sortOrder}
            sortBy={tableSearch.sortBy}
            skip={tableSearch.skip}
            handleReviewButton={handlePendingReviewButtonClick}
            tableType="pending"
            testId="bene-pending-table"
            emptyTitle={t('no_pending_beneficiaries')}
            emptySubTitle={t('you_have_no_pending_beneficiaries')}
            setSelectedBeneficiary={setSelectedBeneficiary}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            hideSelect
          />
          <BeneActionBar
            selectedBenes={multiSelectedBenes}
            handleClearButtonClick={handleClearButtonClick}
            handleOpenApprovalDrawer={handleOpenApprovalDrawer}
            show={showApproval}
          />
        </TabContent>
        <TabContent index={TabName.REJECTED} value={selectedTabIndex} data-testid="rejected-tab">
          <BeneficiaryListTable
            hasNext={tableSearch.items?.hasNext || false}
            hasPrevious={tableSearch.items?.hasPrevious || false}
            handleNextPage={() => tableSearch.handleNextPage(searchParams)}
            handlePreviousPage={() => tableSearch.handlePreviousPage(searchParams)}
            loading={tableSearch.loading}
            tableRawData={tableSearch.items?.items}
            handleTableSortClick={(column: string) => {
              tableSearch.handleTableSortClick(
                searchParams, column,
              );
            }}
            skip={tableSearch.skip}
            sortOrder={tableSearch.sortOrder}
            sortBy={tableSearch.sortBy}
            handleTableRowClick={
              (e: BeneficiaryDto) => handleRejectTableRowClick(e, false)
            }
            tableType="rejected"
            testId="bene-rejected-table"
            emptyTitle={t('no_rejected_beneficiaries')}
            emptySubTitle={t('you_currently_do_not_have_any_rejected_beneficiaries')}
          />
        </TabContent>
        <TabContent index={TabName.DRAFT} value={selectedTabIndex} data-testid="draft-tab">
          <BeneficiaryListTable
            hasNext={tableSearch.items?.hasNext || false}
            hasPrevious={tableSearch.items?.hasPrevious || false}
            handleNextPage={() => tableSearch.handleNextPage(searchParams)}
            handlePreviousPage={() => tableSearch.handlePreviousPage(searchParams)}
            loading={tableSearch.loading}
            tableRawData={tableSearch.items?.items}
            handleTableSortClick={(column: string) => {
              tableSearch.handleTableSortClick(
                searchParams, column,
              );
            }}
            skip={tableSearch.skip}
            sortOrder={tableSearch.sortOrder}
            sortBy={tableSearch.sortBy}
            handleEditButton={handleDraftedEditButtonClick}
            tableType="draft"
            testId="bene-draft-table"
            emptyTitle={t('no_draft_beneficiaries')}
            emptySubTitle={t('you_currently_do_not_have_any_draft_benes')}
          />
        </TabContent>
        <TabContent index={TabName.BATCH} value={selectedTabIndex} data-testid="batch-tab">
          {currentData.length > 0 && !tableSearch.loading
            ? (<StyledGenericTable columns={batchColumns} data={tableData()} />) : (
              <EmptyTable
                icon={clipboardCross}
                title={[t('no_batch_beneficiaries')]}
                subtitle={t('you_currently_do_not_have_any_batch_job_benes')}
                className={styles.emptyTable}
              />
            )}
          {currentData?.length
            ? (
              <Box
                display="flex"
                flexDirection="rows"
                gridGap={16}
                justifyContent="space-between"
                marginTop="40px"
              >
                <span style={{ fontWeight: 300, lineHeight: '26px' }}>
                  {t('showing')}
                  {' '}
                  {currentItems}
                  {' '}
                  {t('of')}
                  {' '}
                  {beneBatchRecords?.length}
                </span>
                <APPagination
                  hasPrevious={hasPrev()}
                  hasNext={hasNext()}
                  handleNext={handleNext}
                  handlePrevious={handlePrev}
                  nextString={t('next^')}
                  prevString={t('prev^')}
                />
              </Box>
            ) : null}
        </TabContent>
      </div>
      <BeneInfoDrawer
        selectedBeneficiary={selectedBeneficiary}
        beneDrawerOpen={Boolean(selectedBeneficiary)}
        closeDrawer={() => {
          setSelectedBeneficiary(undefined);
          setUpdateDrawer(false);
        }}
        handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
        setModalOpen={setModalOpen}
        canShareBeneficiary={
          Boolean(availableAccounts.length && selectedTabIndex === TabName.APPROVED)
        }
        handleApprovedEditButtonClick={handleApprovedEditButtonClick}
        setAddressOnly={setUpdateDrawer}
      />
      <BeneShareDrawer
        availableAccounts={availableAccounts}
        accountBeneId={accountBeneId}
        handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
      />
      <CreateBeneficiary
        firstPartyFlow={isEMoneyDisabled()}
        open={createBeneficiaryOpen}
        draftBeneficiary={selectedDraftBeneficiary}
        handleDrawerClose={handleCreateBeneficiaryDrawerClose}
        updateDrawer={updateDrawer}
        header={updateDrawer ? t('update_bene') : undefined}
        refetch={() => tableSearch.handleNewSearch(searchParams)}
      />
      {beneToDelete && (
        <DeleteOrRejectModal
          modalType={modalOpen}
          loading={loading}
          modalOpen={Boolean(modalOpen)}
          handleCloseModal={handleCloseModal}
          handleOnDeleteClick={handleOnDeleteClick}
          handleOnRejectionClick={handleOnRejectionClick}
        />
      )}
    </>
  );
};

export default Body;
