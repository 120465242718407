import React, { useState } from 'react';
import useLog from 'hooks/useLog';
import t from 'utils/translationHelper';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions } from 'store/creditFacility/reducer';
import { formatCurrency } from 'utils/currency.helpers';

import { CreditFacilityDto } from '@alpha/credit-dtos';
import { BannerButton } from '@alpha/ui-lib/ui/button';
import { graphColorPalette } from '@alpha/ui-lib/ui/Chart/APUtilisationStackedBarChart';
import { Box } from '@alpha/ui-lib/ui/external';
import APPagination from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination';

interface IFacilityUtilisationTerms {
  terms: CreditFacilityDto['facilityTerms'];
  currenyCode: string;
  showTermLimit?: number;
}

const FacilityUtilisationTerms: React.FC<IFacilityUtilisationTerms> = ({
  terms, currenyCode, showTermLimit = 3,
}) => {

  const [pageNumber, setPageNumber] = useState(0);
  const { logEvent } = useLog();
  const dispatch = useDispatch();
  const facilityUtilisationData = terms?.map((term, index) => ({
    id: term.id,
    title: (
      <>
        <b>{`${t('term')} ${index + 1}`}</b>
        {' '}
        (
        {term.displayName}
        )
      </>),
    amount: formatCurrency(currenyCode, term.utilisationAmount),
    openPositions: term.totalOpenPositions,
  }));

  const hasPrev = () => pageNumber > 0;
  const hasNext = () => ((pageNumber + 1) * showTermLimit) < facilityUtilisationData.length;

  const dataToDisplay = facilityUtilisationData
    ? facilityUtilisationData
      .map((term, index) => ( // map data to display
        <Link
          to={`/app/credit-facility/term/${term.id}`}
          data-testid="term-link"
        >
          <BannerButton
            buttonTitle={term.title}
            titleRight={term.amount}
            subtitle={t('open_positions')}
            subtitleRight={term.openPositions}
            gridColor={graphColorPalette[index % graphColorPalette.length]}
            fullWidth
            onButtonClick={() => {
              logEvent({ action: 'Clicked on term on facility utilisation' });
              dispatch(actions.loadTerm({ termId: term.id }));
            }}
          />
        </Link>
      )).filter(
        (_, index) => ( // if in page range, show items
          index >= pageNumber * showTermLimit) && (index < (pageNumber + 1) * showTermLimit
          ),
      ) : null;

  const pageItems = dataToDisplay ? dataToDisplay.length : 0;

  const handleNext = () => {
    setPageNumber(pageNumber + 1);
  };
  const handlePrev = () => {
    setPageNumber(pageNumber - 1);
  };

  return (
    <Box display="flex" flexDirection="column" gridGap={16} justifyContent="space-between">
      <Box display="flex" flexDirection="column" gridGap={16} minHeight="174px">
        {dataToDisplay}
      </Box>
      <Box
        display="flex"
        flexDirection="rows"
        gridGap={16}
        justifyContent="space-between"
        marginTop="10px"
      >
        <span style={
          {
            fontWeight: 300,
            lineHeight: '26px',
          }
        }
        >
          {t('showing')}
          {' '}
          {pageItems}
          {' '}
          {t('of')}
          {' '}
          {facilityUtilisationData.length}
        </span>
        <APPagination
          hasPrevious={hasPrev()}
          hasNext={hasNext()}
          handleNext={handleNext}
          handlePrevious={handlePrev}
          nextString={t('next^')}
          prevString={t('prev^')}
        />
      </Box>
    </Box>
  );
};

export default FacilityUtilisationTerms;
