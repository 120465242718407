import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    statsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      flexWrap: 'wrap',
      gap: 36,
    },
  }),
  { name: 'CreditSummary' },
);

export default useStyles;
