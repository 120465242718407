import React, { useEffect } from 'react';
import clsx from 'clsx';
import Alert from 'components/Alert';
import { FormikProps } from 'formik';
import i18n from 'i18n/config';
import moment, { Moment } from 'moment';
import { formatNumber } from 'utils/currency.helpers';
import mapTradeFundingMethodToFriendlyName from 'utils/fxTrades/mapTradeFundingMethodToFriendlyName';
import t from 'utils/translationHelper';

import { TradeFundingMethod } from '@alpha/fx-dtos';
import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';
import {
  Box, FormControlLabel, Radio,
  RadioGroup, Typography,
} from '@alpha/ui-lib/ui/external';
import { StyledKeyValueTable, TKeyValue, TR } from '@alpha/ui-lib/ui/table';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TSpotFXRequestForm } from '../../formData';
import { useStyles } from '../index.styles';

interface IProps {
  form: FormikProps<TSpotFXRequestForm>;
  padAllowed: boolean;
  show: boolean;
  earliestValueDate?: Date;
  editable: boolean;
  insufficientFunds: boolean;
  availableValueDates: string[];
  valueDatesLoading: boolean;
  isTransfer: boolean;
  firstPartyFlow: boolean;
}

const FundingMethod: React.FC<IProps> = ({
  form,
  padAllowed = false,
  show = false,
  editable = true,
  earliestValueDate,
  insufficientFunds,
  availableValueDates,
  valueDatesLoading,
  isTransfer,
  firstPartyFlow,
}) => {
  const styles = useStyles();

  useEffect(() => {
    if (firstPartyFlow) {
      form.setFieldValue('fundingMethod', TradeFundingMethod.WIRE_TRANSFER);
    }
  }, [firstPartyFlow]);

  const tableData = (fundingMethod: TradeFundingMethod): TKeyValue[] => {
    const data: TKeyValue[] = [];

    if (!editable) {
      data.push({
        key: t('value_date'),
        value: moment(form.values.valueDate).format('DD/MM/YYYY'),
      });
      data.push({
        key: t('funding_method'),
        value: t(mapTradeFundingMethodToFriendlyName(form.values.fundingMethod)),
      });
    }

    if (fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE && editable) {
      data.push({
        key: `${t('account_name')}:`,
        value: form.values.defaultSellCurrencyAccount?.friendlyName || form.values.defaultSellCurrencyAccount?.accountName || '-',
      },
      {
        key: `${t('account_balance')}:`,
        value: `${formatNumber(form.values.defaultSellCurrencyAccount?.clearedBalance || 0, 2)} ${form.values.sellCurrencyCode}` || '-',
        boldValue: true,
      });
    }
    return data;
  };

  const calculateDisableDates = (_date: Moment) => {
    const formattedDate = _date.format('YYYY-MM-DD');

    return (!availableValueDates.includes(formattedDate)
      || moment(formattedDate).isBefore(earliestValueDate));
  };

  const handleDateChange = (_date: Moment) => {
    if (_date) {
      form.setFieldValue('valueDate', _date.format('YYYY-MM-DD'));
    }
  };

  if (show) {
    return (
      <>
        {editable && !firstPartyFlow && (
          <Box style={{ padding: '10px 30px' }}>
            <Typography style={{ fontWeight: '600' }}>{t('funding_method')}</Typography>
            <Typography style={{ marginTop: '16px', opacity: '80%' }}>{t('please_select_how_you_would_like_to_fund_this_trade')}</Typography>
            <RadioGroup
              row
              value={form.values.fundingMethod}
              onChange={(e) => form.setFieldValue('fundingMethod', e.target.value)}
              style={{ flexDirection: 'column', marginTop: '20px' }}
              id="spot-funding-method"
            >
              <FormControlLabel
                value={TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE}
                control={<Radio color="primary" />}
                label={t('use_funds_on_account')}
                data-testid="funds-on-account-drawdown"
                name="spot-funding-method-account"
              />
              {!isTransfer
                ? (
                  <FormControlLabel
                    value={TradeFundingMethod.WIRE_TRANSFER}
                    control={<Radio color="primary" />}
                    label={t('wire_transfer_funds')}
                    data-testid="wire-transfer-drawdown"
                    name="spot-funding-method-wired"
                  />
                ) : null}
              {padAllowed && !isTransfer
                && (
                  <FormControlLabel
                    value={TradeFundingMethod.PAD}
                    control={<Radio color="primary" />}
                    label={t('settle_with_pad')}
                    data-testid="pad-drawdown"
                    name="spot-funding-method-pad"
                  />
                )}
            </RadioGroup>
          </Box>
        )}

        {editable
          && (
            <Box className={styles.valueDateContainer}>
              <Box className={styles.valueDateTitle}>
                <Typography style={{ fontWeight: '600' }}>{t('value_date')}</Typography>
                <APTooltip
                  placement="right"
                  title={t('you_can_select_the_value_for_your_spot_trade_up_to_2_days_into_the_future')}
                >
                  <Box display="flex"><FontAwesomeIcon icon={faCircleInfo as IconProp} className={styles.icon} /></Box>
                </APTooltip>
              </Box>
              <APDatePicker
                fullWidth
                label=""
                language={i18n.language}
                className="datePicker"
                onChange={handleDateChange}
                value={form.values.valueDate || null} // null to fix error message shown when empty
                disabled={valueDatesLoading}
                placeholder={t('value_date~')}
                shouldDisableDate={calculateDisableDates}
              />
            </Box>
          )}

        {tableData(form.values.fundingMethod).length > 0 && (
          <Box className={styles.accountInfoContainer}>
            <StyledKeyValueTable
              testId="trade-funding-method-table"
              data={tableData(form.values.fundingMethod)}
              className={clsx({
                [styles.insufficientFundsTable]: insufficientFunds
                  && form.values.fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE
                  && editable,
                [styles.sufficientFundsTable]: !insufficientFunds
                  && form.values.fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE
                  && editable,
              })}
            />
          </Box>
        )}

        {form.values.fundingMethod === TradeFundingMethod.WIRE_TRANSFER && !firstPartyFlow
          && editable && (
            <Box style={{ padding: '4px 20px' }}>
              <div>
                <Alert
                  variant="info"
                  text={t('details_of_where_to_send_the_settlement_will_be_displayed_on_the_final_screen')}
                />
              </div>
            </Box>
        )}

        {form.values.fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE
          && insufficientFunds
          && (
            <Box style={{ padding: '4px 20px' }}>
              <div style={{ marginTop: '16px' }}>
                <Alert
                  variant="warning"
                  text={t('insufficient_funds')}
                  subText={t('unable_to_select_this_method_due_to_insufficient_funds_on_your_account')}
                />
              </div>
            </Box>
          )}

        {form.values.fundingMethod === TradeFundingMethod.PAD
          && editable && (
            <Box style={{ padding: '4px 20px' }}>
              <div style={{ marginTop: '16px' }}>
                <Alert
                  variant="info"
                  text={t('please_note_that_by_submitting_you_are_authorising_alpha_fx_to_collect_funds')}
                />
              </div>
            </Box>
        )}
      </>
    );
  }

  return <> </>;
};

export default FundingMethod;
