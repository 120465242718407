import React from 'react';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import useProfile from 'hooks/useProfile';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import browserHistory from 'services/history/browserHistory';
import { actions as creditActions } from 'store/creditFacility/reducer';
import { actions } from 'store/user/user.reducer';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { Box, Button } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './Account.styles';

interface IProps {
  account: AccountDto;
  filterAccounts: string;
  setFilterAccounts?: (val: string) => void;
  onClose?: (event: any) => void;
  setLoading?: (val: boolean) => void;
}

const Account: React.FC<IProps> = ({
  account, filterAccounts, onClose, setFilterAccounts, setLoading,
}: IProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { updateUserProfile } = useProfile();
  const { name, id } = account;
  const {
    updateCurrentAccount, currentAccount,
    mapPageToPath,
  } = useSwitchAccount();
  const sb = useAlphaSnackbar();
  const { logEvent } = useLog();
  const classes = useStyles();

  const getBasePath = (fullPath: string): string => {
    try {
      const newPath = fullPath.split('/', 3);
      return newPath.join('/');
    } catch {
      return '/app';
    }
  };

  const handleButtonClick = (): void => {
    (async () => {
      if (setLoading) { setLoading(true); }
      await updateCurrentAccount(account);
      await updateUserProfile();

      browserHistory.push(mapPageToPath('DASHBOARD')
        || getBasePath(location.pathname));

      if (setFilterAccounts) { setFilterAccounts(''); }
      if (onClose) { onClose(false); }
      dispatch(actions.updateKey());
      dispatch(creditActions.clearCreditFacility());
      dispatch(creditActions.loadCreditFacility());
      if (setLoading) { setLoading(false); }
      if (localStorage.getItem('i18nextLng') !== 'de') {
        sb.trigger(`${t('switched_accounts_to')} ${account.name}`, 'success');
      } else {
        sb.trigger(`${t('switched_accounts_to1')} ${account.name} ${t('switched_accounts_to2')}`, 'success');
      }
      logEvent({ action: 'Switched account' });
    })();
  };

  return (
    <>
      {
        name.toLowerCase().includes(filterAccounts.toLowerCase())
          && currentAccount
          ? (
            <Box className={id === currentAccount.id
              ? classes.currentAccountWrapper : classes.accountWrapper}
            >
              <Box className={classes.accountDetails}>
                <Typography className={classes.accountName}>{name}</Typography>
                <Typography className={classes.accountDescription}>{t('admin')}</Typography>
              </Box>
              {
                currentAccount
                  && id === currentAccount.id
                  ? (
                    <Box className={classes.selectedAccount}>
                      <span>{t('selected')}</span>
                      <FontAwesomeIcon
                        className={classes.tickIcon}
                        icon={faCheckCircle as IconProp}
                      />
                    </Box>
                  )
                  : (
                    <Button
                      data-testid={`test-account-${id}`}
                      onClick={() => { handleButtonClick(); }}
                      className={classes.button}
                    >
                      <span>{t('view')}</span>
                    </Button>
                  )
              }
            </Box>
          )
          : <></>
      }
    </>
  );
};

export default Account;
