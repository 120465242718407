import React from 'react';
import ReactMarkdown from 'react-markdown';

import { UserNotificationDto } from '@alpha/notifications-dtos';
import { Button, Typography } from '@alpha/ui-lib/ui/external';
import t from 'utils/translationHelper';

import formatIsoDate from '../../../../utils/formatIsoDate';

import useStyles from './NotificationDrawerItem.styles';

interface IProps {
  notification: UserNotificationDto;
  handleClick?: (notification: UserNotificationDto) => void;
}

const NotificationDrawerItem: React.FC<IProps> = ({
  notification,
  handleClick,
}: IProps) => {
  const classes = useStyles();


  return (
    <div className={classes.itemContainer} data-testid="notification-drawer-item">
      <div className={notification.acknowledged ? classes.readMarker : classes.unreadMarker} />
      <div className={classes.contentContainer}>
        <Typography className={classes.title}>{notification.title}</Typography>
        <div className={classes.body}>
          <ReactMarkdown skipHtml>
            {notification.body}
          </ReactMarkdown>
        </div>
        <div className={classes.footerContainer}>
          <Typography className={classes.date}>{formatIsoDate(notification.date)}</Typography>
          <Button
            className={classes.viewBtn}
            onClick={() => {
              if (handleClick) {
                handleClick(notification);
              }
            }}
          >
            {t('view')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotificationDrawerItem;
