import { ChangeEvent, useEffect } from 'react';
import useSearch from 'hooks/useSearch';

import { PaymentStatus } from '@alpha/payments-dtos';

export enum PaymentEvents {
  RefreshPaymentTable = 'RefreshPaymentTable',
}

export type TPaymentSearchFilterTypes = {
    paymentCurrencies: string[],
    paymentStatuses: PaymentStatus[],
    paymentValueStartDate: string| undefined;
    paymentValueEndDate: string| undefined;
}

type TPaymentSearchParams = {
  baseUrl: string,
  queryParams: {
    searchtext?: string,
    take: number,
    skip: number,
    sortby?: string,
    sortorder?: 'asc' | 'desc',
    currency?: string,
    status?: string,
    datefrom?: string,
    dateto?: string,
  } & Record<string, string | number>,
  body?: Record<string, unknown>,
}

export const createSearchParams = (
  skip: number,
  sortBy?: string,
  sortOrder: 'asc' | 'desc' = 'desc',
  searchText = '',
  filters?: TPaymentSearchFilterTypes,
): TPaymentSearchParams => {
  const searchParams: TPaymentSearchParams = {
    baseUrl: '/payments/search',
    queryParams: {
      skip: 0,
      take: 10,
      sortby: sortBy || '',
      sortorder: sortOrder,
    },
  };
  if (searchText) {
    searchParams.queryParams.searchtext = searchText;
  }
  if (filters) {
    searchParams.queryParams.currency = filters?.paymentCurrencies.join(',');
    searchParams.queryParams.status = filters?.paymentStatuses.join(',');
    searchParams.queryParams.datefrom = filters?.paymentValueStartDate;
    searchParams.queryParams.dateto = filters?.paymentValueEndDate;
  }
  return searchParams;
};

const usePaymentTable = (
  filters?: TPaymentSearchFilterTypes,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc',
) => {
  const table = useSearch();
  const defaultSortBy = sortBy ?? 'contractNumber';
  const defaultSortOrder = sortOrder ?? 'desc';

  const searchParams = createSearchParams(
    table.skip,
    defaultSortBy,
    defaultSortOrder,
    table.searchText,
    filters,
  );

  const handleTableSortClick = (column: string) => {
    table.handleTableSortClick(
      searchParams, column,
    );
  };

  const reloadCurrentQuery = () => {
    searchParams.queryParams.sortby = table.sortBy;
    searchParams.queryParams.sortorder = table.sortOrder;
    searchParams.queryParams.skip = table.skip;
    if (table.searchText) { searchParams.queryParams.searchtext = table.searchText; }
    table.handleNewSearch(searchParams);
  };

  useEffect(() => {
    table.setItems(undefined);
    searchParams.queryParams.sortby = defaultSortBy;
    searchParams.queryParams.sortorder = defaultSortOrder;
    table.handleInitialSearch(searchParams);
  }, []);

  useEffect(() => {
    searchParams.queryParams.currency = filters?.paymentCurrencies.join(',');
    searchParams.queryParams.status = filters?.paymentStatuses.join(',');
    searchParams.queryParams.datefrom = filters?.paymentValueStartDate;
    searchParams.queryParams.dateto = filters?.paymentValueEndDate;

    table.handleNewSearch(searchParams);
  }, [filters]);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
      | HTMLTextAreaElement>) => {
    table.setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.skip = 0;
      searchParams.queryParams.searchtext = e.target.value;
    } else {
      delete searchParams.queryParams.searchtext;
    }
    await table.handleNewSearch(searchParams);
  };

  return {
    table,
    searchParams,
    handleInputChange,
    handleTableSortClick,
    reloadCurrentQuery,
  };
};

export default usePaymentTable;
