/* eslint-disable max-lines-per-function */
import clipboardCross from 'assets/clipboardCross.svg';
import clsx from 'clsx';
import Alert from 'components/Alert';
import EmptyTable from 'components/Table/EmptyTable';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import useReportsPolling, { DownloadType, FileType, PageType } from 'hooks/useReportsPolling';
import i18n from 'i18n/config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CreditService from 'services/Credit/credit.service';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import { formatNumber } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import { AccountConfigurationFXDto, CurrencyPairMTMDto, TradeMTMDto } from '@alpha/fx-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';
import { Box, MenuItem, Select } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { faFileExcel, faFilePdf } from '@fortawesome/pro-light-svg-icons';

import MtmFilter from '../MtmFilter';

import ExternalTradesModal from './ExternalTradesModal/index';
import useStyles from './MTMSummary.styles';

interface IProps {
  title: string;
  mtmDate: string;
  setMtmDate: React.Dispatch<React.SetStateAction<string>>;
  selectedCurrency: string;
  setSelectedCurrency?: React.Dispatch<React.SetStateAction<string>>;
  searchText?: string;
  alertText?: string;
  soldCurrency?: string[];
  buyCurrency?: string[];
  fullWidth?: boolean;
  termId?: string;
  hideCurrencyDropdown?: boolean;
  buyCurrencies?: string[];
  sellCurrencies?: string[];
  termsIds?: string[];
  setBuyCurrencies?: React.Dispatch<React.SetStateAction<string[]>>;
  setSoldCurrencies?: React.Dispatch<React.SetStateAction<string[]>>;
  setTermsIds?: React.Dispatch<React.SetStateAction<string[]>>;
  enableFilter?: boolean;
  shouldUseBrackets?: boolean;
  hasExternalTrades?: boolean;
  facilityMode?: string;
}

const MTMSummary: React.FC<IProps> = ({
  title,
  mtmDate, setMtmDate, selectedCurrency, setSelectedCurrency,
  searchText, alertText, soldCurrency, buyCurrency, fullWidth, termId,
  hideCurrencyDropdown = false,
  buyCurrencies,
  sellCurrencies,
  setBuyCurrencies,
  setSoldCurrencies,
  setTermsIds,
  termsIds,
  enableFilter = false,
  shouldUseBrackets = false,
  hasExternalTrades = false,
  facilityMode = 'single',
}) => {
  const classes = useStyles();

  const { logError, logEvent } = useLog();
  const sb = useAlphaSnackbar();

  const [columns, setColumns] = useState<IStyledGenericTableProps['columns']>([]);
  const [data, setData] = useState<IStyledGenericTableProps['data']>([]);
  const [openExportMenu, setOpenExportMenu] = useState<null | HTMLElement>(null);
  const [openExternalTradesModal, setOpenExternalTradesModal] = useState<boolean>(false);
  const [selectedFileType, setSelectedFileType] = useState<FileType>(FileType.PDF);
  const [externalTradeMtms, setExternalTradeMtms] = useState<TradeMTMDto[]>([]);
  const [accountConfiguration, setAccountConfiguration] = useState<AccountConfigurationFXDto | undefined>();
  const location = useLocation();

  useEffect(() => {
    getAccountConfiguration();
  }, []);

  const loadSummary = async (): Promise<void> => {
    try {
      const summaries = location.pathname.includes('credit') && !location.pathname.includes('term')
        ? await CreditService.getFacilityMTMSummary(mtmDate, selectedCurrency, soldCurrency, buyCurrency, false)
        : await CreditService.getMtmSummary(mtmDate, selectedCurrency, termId, buyCurrency, soldCurrency, false);
      updateTable(summaries);
    } catch (error) {
      sb.trigger(error?.message || t('unable_to_load_mtm_summary'), 'error');
      logError({ action: 'Error loading MTM summary', error });
      setData([]);
    }
  };

  const calculateMtmTotal = (currencyPairs: CurrencyPairMTMDto[]): number => {
    const sum = currencyPairs.reduce(
      (previousValue, currentValue) => previousValue + currentValue.convertedMTM, 0,
    );
    return sum;
  };

  const defaultColumns: IStyledGenericTableProps['columns'] = [{
    header: t('currency_pair'),
    accessor: 'currencyPair',
  },
  {
    header: t('total_sold_amount'),
    accessor: 'totalSoldAmount',
  },
  {
    header: t('total_buy_amount'),
    accessor: 'totalBuyAmount',
  },
  {
    header: t('avg._rate^'),
    accessor: 'averageRate',
  },
  {
    header: t('mtm_value'),
    accessor: 'mtmValue',
  }];

  const updateTable = (currencyPairs: CurrencyPairMTMDto[]) => {
    setColumns([
      ...defaultColumns,
      {
        header: `${selectedCurrency} ${t('rate')}`,
        accessor: 'rate',
        align: 'right',
      },
      {
        header: `${t('total_mtm')} (${selectedCurrency})`,
        accessor: 'totalMtm',
        align: 'right',
      },
    ]);

    const summaryData: IStyledGenericTableProps['data'] = currencyPairs?.map(
      (currencyPair: CurrencyPairMTMDto) => ({
        averageRate: (<span className={classes.rate}>{currencyPair.averageRate ? currencyPair.averageRate.toFixed(4) : '-'}</span>),
        currencyPair: currencyPair.currencyPair ? currencyPair.currencyPair : '-',
        mtmValue: (
          <span className={classes.mtmValue}>
            {`${formatNumber(currencyPair.mtm, 2, shouldUseBrackets)} ${currencyPair.mtmCurrencyCode}`}
          </span>),
        rate: currencyPair.conversionRate ? currencyPair.conversionRate.toFixed(4) : t('na'),
        totalMtm: currencyPair.convertedMTM ? `${formatNumber(currencyPair.convertedMTM, 2, shouldUseBrackets)} ${selectedCurrency}` : '-',
        totalBuyAmount: `${formatNumber(currencyPair.totalBuyAmount, 2)} ${currencyPair.buyCurrency || ''}`,
        totalSoldAmount: `${formatNumber(currencyPair.totalSellAmount, 2)} ${currencyPair.sellCurrency || ''}`,
      }),
    );

    if (summaryData.length > 0) {
      summaryData.push({
        currencyPair: '',
        mtmValue: '',
        rate: 'Total:',
        totalMtm: `${formatNumber(calculateMtmTotal(currencyPairs), 2, true)} ${selectedCurrency}`,
      });
    }

    setData(summaryData);
  };

  useEffect(() => {
    if (mtmDate && selectedCurrency) {
      loadSummary();
      getMtms();
    }
  }, [mtmDate, selectedCurrency, buyCurrency, soldCurrency]);

  const handleCurrencyChange = (e: React.ChangeEvent<{ value: string | unknown }>): void => {
    logEvent({ action: 'Clicked on currency selector' });
    if (e.target.value && setSelectedCurrency) setSelectedCurrency(String(e.target.value));
  };

  const getAccountConfiguration = async () => {
    try {
      const result = await FXTradeService.getAccountConfiguration();
      setAccountConfiguration(result);
    } catch (e) {
      sb.trigger(e?.message || t('could_not_load_account_configuration_data'));
      logError({ action: 'Error loading account configurations', error: e });
    }
  };

  const { executeReportGeneration } = useReportsPolling(PageType.FX);
  const handleReportGeneration = async (
    fileType: FileType,
    id?: string,
    includeExternalTrades?: boolean,
  ) => {
    const idForTerm = (id) || '';
    const isCredit = location.pathname.includes('credit');
    const downloadType = isCredit ? DownloadType.TermMtm : DownloadType.Mtm;
    const isFacilityLevel = !idForTerm || facilityMode === 'single';
    const termMtmParams = {
      mtmdate: mtmDate,
      mtmcurrency: selectedCurrency,
      termId: idForTerm,
      searchtext: searchText,
      soldcurrency: soldCurrency,
      buycurrency: buyCurrency,
      includeOptions: isCredit,
      isFacilityLevel,
      includeexternaltrades: includeExternalTrades,
    };
    const mtmParams = {
      mtmdate: mtmDate,
      mtmcurrency: selectedCurrency,
      includeexternaltrades: includeExternalTrades,
      searchtext: searchText,
      soldcurrency: soldCurrency,
      buycurrency: buyCurrency,
    };
    await executeReportGeneration(
      fileType,
      idForTerm,
      undefined,
      undefined,
      downloadType,
      fileType,
      isCredit ? termMtmParams : mtmParams,
    );
  };

  const getMtms = async () => {
    try {
      const mtms = await CreditService.getAllMtms(mtmDate);
      const externalMtms = mtms.filter((mtm) => mtm.externalTrade);
      setExternalTradeMtms(externalMtms);
    } catch (e) {
      logError({ action: 'Error loading Mtms', error: e });
    }
  };
  const externalTradesIncludedForCredit = ((facilityMode === 'single' && hasExternalTrades) || hasExternalTrades);
  const showExternalTradesModal = (accountConfiguration?.externalTradesAllowed && externalTradeMtms.length) || externalTradesIncludedForCredit;

  const menuItems = [
    {
      content: t('export_pdf'),
      icon: faFilePdf,
      onClick: () => {
        setSelectedFileType(FileType.PDF);
        if (showExternalTradesModal) {
          setOpenExternalTradesModal(true);
        } else if (termId) {
          handleReportGeneration(FileType.PDF, termId);
        } else if (!termId) {
          handleReportGeneration(FileType.PDF, undefined);
        } else {
          handleReportGeneration(FileType.PDF);
        }
      },
      underline: true,
    },
    {
      content: t('export_excel'),
      icon: faFileExcel,
      onClick: () => {
        setSelectedFileType(FileType.EXCEL);
        if (showExternalTradesModal) {
          setOpenExternalTradesModal(true);
        } else if (termId) {
          handleReportGeneration(FileType.EXCEL, termId);
        } else if (!termId) {
          handleReportGeneration(FileType.EXCEL, undefined);
        } else {
          handleReportGeneration(FileType.EXCEL);
        }
      },
    },
  ];

  return (
    <div className={clsx(classes.root, fullWidth && 'fullWidth')}>
      <Box display="flex" flexGrow={1} flexDirection="row" justifyContent="space-between" flexWrap="wrap">
        <Box alignItems="center" display="flex">
          <Typography variant="h6" style={{ fontWeight: 600 }}>{title}</Typography>
          <div className={classes.verticalLine} />
          <APDatePicker
            label=""
            language={i18n.language}
            onChange={(date) => {
              logEvent({ action: `Clicked on date picker for Forwards: Position valuation by currency pair ${date}` });
              setMtmDate(moment(date).format('YYYY-MM-DD'));
            }}
            value={mtmDate}
            minDate={moment(mtmDate).subtract(366, 'days')}
            maxDate={moment().format('YYYY-MM-DD')}
            autoOk
            shouldDisableDate={(day) => {
              const isSundayOrSaturday = moment(day).day() % 6 === 0;
              return isSundayOrSaturday;
            }}
          />
        </Box>
        <Box alignItems="center" display="flex">
          {alertText && (
            <Alert
              text={alertText}
              variant="info"
              className={classes.averageRateAlert}
            />
          )}
          <APMenu
            onButtonClick={
              () => {
                logEvent({ action: 'Clicked on Export Forwards: Position valuation by currency pair' });
              }
            }
            open={Boolean(openExportMenu)}
            setOpen={setOpenExportMenu}
            anchorEl={openExportMenu}
            buttonTitle={t('export')}
          >
            {menuItems.map(
              (item) => (
                <MenuDropdownItem
                  setOpen={setOpenExportMenu}
                >
                  {item}
                </MenuDropdownItem>
              ),
            )}
          </APMenu>
          {!hideCurrencyDropdown && (
            <Select
              labelId="currency-select-label"
              id="currency-select"
              value={selectedCurrency}
              label={t('currency')}
              onChange={handleCurrencyChange}
              className={classes.currencyButton}
              style={{
                marginLeft: '8px',
              }}
            >
              <MenuItem value="GBP">GBP</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
              <MenuItem value="AUD">AUD</MenuItem>
              <MenuItem value="CAD">CAD</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
            </Select>
          )}

          {enableFilter && (
            <MtmFilter
              mtmDate={mtmDate}
              termId={termId}
              setBuyCurrencies={setBuyCurrencies}
              setSellCurrencies={setSoldCurrencies}
              buyCurrencies={buyCurrencies}
              sellCurrencies={sellCurrencies}
              setTermsIds={setTermsIds}
              termsIds={termsIds}
            />
          )}
        </Box>
      </Box>

      {data.length > 0 ? (
        <StyledGenericTable
          testId="mtm-summary-table"
          columns={columns}
          data={data}
        />
      ) : (
        <Box className={classes.emptyTable}>
          <EmptyTable
            title={t('no_open_positions')}
            subtitle={mtmDate === moment().format('YYYY-MM-DD')
              ? t('please_note_that_todays_rates_are_not_available')
              : t('you_do_not_have_any_open_positions_on_the_selected_date')}
            icon={clipboardCross}
          />
        </Box>
      )}
      <ExternalTradesModal
        open={openExternalTradesModal}
        selectedFileType={selectedFileType}
        handleClose={() => setOpenExternalTradesModal(false)}
        handleReportGeneration={handleReportGeneration}
        termId={termId}
      />
    </div>
  );
};

export default MTMSummary;
