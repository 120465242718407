import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography, palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '12px',
    paddingBottom: '30px',
    flexDirection: 'row',
    minWidth: '300px',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      gap: 10,
    },
  },
  downloadLinkContainer: {
    display: 'flex',
    width: '48%',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  downloadTemplate: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '15px 20px 5px 20px',
    borderRadius: '10px',
    borderColor: palette.grey[300],
    border: '1px solid',
    marginRight: '0px',
  },
  templatesContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    marginBottom: '10px',
    gap: 10,

    '@media (max-width: 600px)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  headerText: {
    fontSize: '15px',
    textAlign: 'left',
    fontWeight: typography.fontWeightBold,
    color: palette.primary.main,
    marginBottom: '20px',
  },
  subHeaderText: {
    fontSize: '15px',
    textAlign: 'left',
    fontWeight: typography.fontWeightBold,
    marginBottom: '10px',
  },
  bodyGreenText: {
    fontSize: '12px',
    textAlign: 'left',
    color: palette.primary.main,
    marginBottom: '20px',
    marginTop: '15px',
  },
  bodyText: {
    fontSize: '12px',
    textAlign: 'left',
    color: palette.grey[500],
    marginBottom: '15px',
  },
  dragnDropText: {
    fontSize: '13px',
    marginBottom: '0px',
    '&.unHappyPath': {
      fontColor: '#A62B23',
    },
    '&.happyPath': {
      fontColor: '#1E8777',
    },
  },
  icon: {
    color: '#CEE6E3',
    height: '30px',
    width: '15px',
    marginBottom: '10px',
  },
  downloadTemplateBox: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    '&.fileType': {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    '&.icon': {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
  },
  underlinedGreenText: {
    textDecoration: 'underline',
    fontSize: '13px',
    color: palette.primary.main,
    marginBottom: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dragnDropBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'width 0.3s linear',
    minHeight: '340px',
    '&.small': {
      width: '50%',
      '@media (max-width: 600px)': {
        width: '100%',
      },
    },
    '&.large': {
      width: '100%',
    },
  },
  guideBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    transition: 'all 0.3s linear',
    '&.hidden': {
      width: '0%',
      height: '0px',
      opacity: '0',
    },
    '&.display': {
      width: '50%',
      height: '360px',
      opacity: '1',
      '@media (max-width: 600px)': {
        width: '100%',
        height: 'auto',
        padding: '20px',
      },
    },
  },
}), { name: 'UploadFile' });

export default useStyles;
