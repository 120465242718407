import { PhoneNumberFormat as PNF, PhoneNumberUtil } from 'google-libphonenumber';

export interface IPhoneResult {
  phoneNumber: string;
  isValid: boolean;
}

const phoneUtil = PhoneNumberUtil.getInstance();

export const parsePhone = (number: string, countryCode: string): IPhoneResult => {
  try {
    const fullNumber = phoneUtil.parse(number, countryCode);
    return {
      phoneNumber: phoneUtil.format(fullNumber, PNF.E164),
      isValid: phoneUtil.isValidNumber(fullNumber),
    };
  } catch (error) {
    throw new Error('Can not parse phone');
  }
};

export default parsePhone;
