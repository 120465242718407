import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useDownloadFile from 'hooks/useDownloadFile';
import useLog from 'hooks/useLog';
import i18n from 'i18n/config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CreditService from 'services/Credit/credit.service';
import t from 'utils/translationHelper';

import { TermLetterDto } from '@alpha/credit-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import {
  Box, Divider,
} from '@alpha/ui-lib/ui/external';
import { FileDownloadItem } from '@alpha/ui-lib/ui/FileDownloadItem';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './TermsLetterDrawer.styles';

interface IProps {
  termId?: string;
  open: boolean;
  onClose: () => void;
}

const TermsLetterDrawer: React.FC<IProps> = ({ termId, open, onClose }) => {
  const [termsLetters, setTermsLetters] = useState<TermLetterDto[]>([]);
  const [loading, setLoading] = useState(false);
  const { logError, logEvent } = useLog();
  const sb = useAlphaSnackbar();
  const { downloadFile } = useDownloadFile();

  const styles = useStyles();
  const downloadTermsLetter = async (fileName: string, versionName?: string) => {
    try {
      const url = await CreditService.getTermsLetterGeneration(fileName);
      await downloadFile(url, `${t('terms_letter')} ${versionName ? `(${versionName})` : ''}`);
    } catch (error) {
      sb.trigger(t('error_downloading_terms_letter_file'));
      logError({ action: 'Error getting terms letter url ', error });
    }
  };

  const loadTermsLetters = async (termIdToLoad?: string) => {
    try {
      setLoading(true);
      const termsLetterResult = await CreditService.getTermsLetterHistory(termIdToLoad);
      setTermsLetters(termsLetterResult);
    } catch (error) {
      sb.trigger(t('could_not_download_terms_letters'), 'error');
      logError({ action: 'Could not load terms letters.', error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      loadTermsLetters(termId);
    }
  }, [
    termId, open,
  ]);

  const termsLettersList = () => {
    if (termsLetters && termsLetters.length === 0 && !loading) {
      return (
        <EmptyTable
          icon={clipboardCross}
          title={t('no_letters_available')}
          subtitle={t('please_contact_client_services_if_you_believe_this_to_be_incorrect')}
          style={{
            minHeight: '200px',
            position: 'relative',
            marginBottom: '40px',
            top: '200px',
          }}
        />
      );
    }
    return (
      <>
        {termsLetters.map((letter, index) => (
          <>
            {index === 1 && <Divider style={{ marginBottom: '5px', marginTop: '5px' }} />}
            <FileDownloadItem
              isCurrentVersion={letter.isLatestVersion}
              update={t('update')}
              currentVersion={t('current_version')}
              attributes={[
                {
                  key: t('uploaded_date'),
                  value: moment(letter.updatedDate).format('DD/MM/YYYY'),
                },
                { key: t('description'), value: letter.reasonForChange || '-' }]}
              title={letter.description}
              onDownloadButtonClick={() => {
                logEvent({ action: 'Clicked on download term in terms letter drawer' });
                downloadTermsLetter(
                  letter.fileName,
                  !letter.isLatestVersion ? moment(letter.updatedDate).format('DD/MM/YYYY') : undefined,
                );
              }}
            />
          </>
        ))}
        <p style={{ fontSize: '10px', color: '#999', fontWeight: 400 }}>
          {t('showing_all')}
          {' '}
          {i18n.language === 'it' ? `(${termsLetters.length})` : `${termsLetters.length}`}
          {' '}
          {t('items')}
        </p>
      </>
    );
  };

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={onClose}
        anchor="right"
      >
        <div className={styles.drawer}>
          <Box marginTop="22px">
            <FontAwesomeIcon icon={faClockRotateLeft} style={{ height: '32px', width: '32px' }} height="32px" />
          </Box>
          <Box>
            <p style={{ fontSize: '22px', fontWeight: 400 }}>
              {t('term_history')}
              {' '}
              <br />
              <span style={{
                color: '#949494',
                fontSize: '13px',
                fontWeight: 400,
              }}
              >
                {t('full_amendemnt_log_available_for_download')}
              </span>
            </p>
          </Box>
        </div>

        <BaseDrawer.Body>
          <Box display="flex" gridGap={10} flexDirection="column">
            <Divider style={{ marginBottom: '5px' }} />

            {loading ? (
              <>
                <Loader testId="terms-letters-loader" style={{ marginTop: '100px' }} />
              </>
            ) : (
              termsLettersList()
            )}
          </Box>

        </BaseDrawer.Body>
      </BaseDrawer.Drawer>
    </>
  );
};

export default TermsLetterDrawer;
