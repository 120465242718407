import EmptyTable from 'components/Table/EmptyTable';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { AccountConfigurationFXDto, ProgramPeriodDto } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APHedgingProgramBarChart, THedgingPeriod } from '@alpha/ui-lib/ui/Chart/APHedgingProgramBarChart';
import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { datadogLogs } from '@datadog/browser-logs';

import clipboardCross from '../../../../../assets/clipboardCross.svg';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import routes from '../../../../../routes.path';
import browserHistory from '../../../../../services/history/browserHistory';
import HedgingProgramsSelect from '../../../../HedgingPrograms/HedgingProgramsSelect/HedgingProgramsSelect';
import useHedgingPrograms from '../../../../HedgingPrograms/useHedgingPrograms';
import useStyles from '../../index.styles';

const HedgingProgramWidget: React.FC = () => {
  const { logEvent } = useLog();
  const [programId, setProgramId] = useState<string>();
  const [, setOpenExternalTradeModal] = useState<boolean>(false);
  const [
    setAccountConfiguration,
  ] = useState<AccountConfigurationFXDto>();
  const [loading, setLoading] = useState<boolean>(true);
  const [period, setPeriod] = useState<THedgingPeriod[]>([]);
  const sb = useAlphaSnackbar();

  const {
    getProgramPeriodsData,
    checkUrlForProgramId,
    periodsToShowInChart,
    program,
  } = useHedgingPrograms(
    setOpenExternalTradeModal,
    programId,
    setProgramId,
    setAccountConfiguration,
  );

  const classes = useStyles();

  const handleEditForecastButton = () => {
    sb.trigger(t('please_edit_forecast_in_the_hedging_program_page'), 'info');
  };

  useEffect(() => {
    checkUrlForProgramId();
    logEvent({ action: 'Dashboard - View Hedging Quarter Widget' });
  }, []);

  useEffect(() => {
    if (programId) {
      getProgramPeriodsData(programId);
      setLoading(false);
    }
  }, [programId]);

  useEffect(() => {
    if (periodsToShowInChart) {
      setCurrentQuarter(periodsToShowInChart);
    }
  }, [periodsToShowInChart]);

  // Default to current quarter
  const setCurrentQuarter = async (programPeriods: ProgramPeriodDto[]) => {
    const currentDate = moment().utcOffset(0);

    try {
      programPeriods.forEach((periodToShow) => {
        const startDate = moment(periodToShow.startDate);
        const endDate = moment(periodToShow.endDate);

        if (currentDate.isAfter(startDate) && currentDate.isBefore(endDate)) {
          setPeriod([periodToShow]);
        }

        if (currentDate.isBefore(startDate) && currentDate.isBefore(endDate)) {
          setPeriod([programPeriods[0]]);
        }
      });
    } catch (e) {
      datadogLogs.logger.error(e);
    }
  };

  return (
    <Box
      className={classes.widget}
      style={{
        gridColumn: 'auto',
        gridAutoColumns: 'auto',
      }}
    >
      <Box className={classes.hedgingContainer}>
        <HedgingProgramsSelect
          programId={programId}
          setProgramId={setProgramId}
        />
        <ActionButton
          style={{ background: '#F7F7F7', color: '#212529' }}
          onClick={() => {
            browserHistory.push(`${routes.hedgingPrograms}?id=${programId || ''}`);
          }}
        >
          {t('view_all~')}
        </ActionButton>
      </Box>
      {loading ? (
        periodsToShowInChart.length > 0 && (
          <Loader
            testId="hedging-program-chart-loader"
            style={{
              height: 600, width: '100%', margin: 'auto', textAlign: 'center',
            }}
          />
        )
      ) : (
        null)}

      <Box paddingTop="20px">
        {!loading && periodsToShowInChart.length > 0 && period ? (
          <APHedgingProgramBarChart
            isWidget
            data={period}
            text={{
              actuals: t('actuals'),
              overhedged: t('overhedged'),
              underhedged: t('underhedged'),
              forecasted: t('forecasted'),
              program: t('program'),
              editForecast: t('edit_forecast'),
            }}
            currencyCode={program?.currencyCode || ''}
            width={533}
            height={330}
            id="hedging-bar-chart-widget"
            editCallback={handleEditForecastButton}
          />
        ) : (
          <EmptyTable
            title={t('no_programs_available')}
            className={classes.emptyTable}
            icon={clipboardCross}
          />
        )}
      </Box>
    </Box>
  );
};

export default HedgingProgramWidget;
