import i18n from 'i18n/config';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APInput } from '@alpha/ui-lib/ui/Input';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCommentAltDots, faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthyIcon from '../../../assets/authyIcon.png';
import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import useCustomMultifactorAuth from '../../../hooks/useCustomMultifactorAuth';
import routes from '../../../routes.path';
import useFormStyles from '../form.styles';
import LangSelectorGroup from '../LangSelectorGroup';
import { IOnboarding, useOnboardingContext } from '../Onboarding';
import { IReturning } from '../Returning';

interface IProps extends IOnboarding, IReturning {
  mfaImage?: 'authy' | 'sms',
}

const onboardingDescription = (mfaImage?: 'authy' | 'sms', phoneNumber?: string): string | JSX.Element => {

  const description = t('finally_please_use_your_selected_authentication_method');
  if (!mfaImage) return description;
  return (
    <>
      {description}
      <br />
      <div style={{ marginTop: '16px' }}>
        {
          mfaImage === 'sms' ? <FontAwesomeIcon icon={faCommentAltDots as IconProp} style={{ marginRight: '8px' }} />
            : (
              <img
                src={AuthyIcon}
                alt="Authy Icon"
                style={{
                  borderRadius: '50%',
                  width: '18px',
                  position: 'relative',
                  top: '4px',
                  marginRight: '8px',
                }}
              />
            )
        }
        {i18n.language !== 'de'
          ? `${t('please_enter_the_2fa_code_sent_to')} ${phoneNumber}`
          : `${t('please_enter_the_2fa_code_sent_to1')} ${phoneNumber} ${t('please_enter_the_2fa_code_sent_to2')}`}
      </div>
    </>
  );
};

const VerifyMfa: React.FC<IProps> = ({ onboarding, userObject, mfaImage }: IProps) => {
  const formClasses = useFormStyles();

  const { formik, loading } = useCustomMultifactorAuth(userObject, onboarding, mfaImage);
  const { handleSetLoaderPercentage } = useOnboardingContext();

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(100);
    }
  }, []);

  return (
    <div className={formClasses.wrapperDiv}>
      <div>
        <FormTitle
          number={onboarding ? 4 : undefined}
          title={t('verify_your_mobile_device')}
          description={onboarding && mfaImage
            ? onboardingDescription(mfaImage, userObject.challengeParam?.CODE_DELIVERY_DESTINATION)
            : t('please_verify_your_mobile_device')}
        />
        <form onSubmit={formik.handleSubmit}>
          <div className={formClasses.section} data-dd-privacy="hidden">
            <Typography className={formClasses.label}>{t('2fa_code')}</Typography>
            <APInput
              autoComplete="off"
              testid="mfa"
              autoFocus
              id="mfa"
              name="mfa"
              placeholder={t('enter_your_2fa_code')}
              variant="filled"
              type="text"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.mfa}
              error={formik.touched.mfa && Boolean(formik.errors.mfa)}
              helperText={formik.touched.mfa && formik.errors.mfa}
              style={{ marginBottom: '0px' }}
            />
            <NavLink
              to={onboarding ? routes.auth.onboarding.mfaOptions : routes.auth.mfaOptions}
              className={formClasses.formBacklink}
            >
              {t('havent_received_your_code_yet')}
            </NavLink>
          </div>
          <ActionButton
            type="submit"
            testId="submit-mfa"
            fullWidth
            loading={loading}
            disabled={loading}
            size="large"
            startIcon={<FontAwesomeIcon icon={faLockAlt as IconProp} />}
          >
            {t('authorise^')}
          </ActionButton>
          <div className={formClasses.formBacklink}>
            {
              !onboarding && <NavLink to={routes.auth.login}>{t('back_to_sign_in')}</NavLink>
            }
          </div>
        </form>
      </div>
      <LangSelectorGroup />
    </div>
  );
};

export default VerifyMfa;
