import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0',
    margin: '0',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  isExternal: {
    justifyContent: 'space-between',
  },
  clientAccountName: {
    color: '#1E8777',
    width: '75px',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  item: {
    paddingLeft: '26px',
    paddingRight: '24px',
  },
  friendlyName: {
    width: '75px',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  bankInfo: {
    marginLeft: '8px',
    fontWeight: 300,
    color: '#34343473',
    width: '135px',
    fontStyle: 'italic',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  sticky: {
    paddingLeft: '34px',
    backgroundColor: '#F8F8F8',
    '& h6': {
      color: '#A0A0A0',
    },
  },
  imageHolder: {
    display: 'flex',
    width: '20px',
    height: '20px',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    padding: '5px 15px 5px 0',
  },
  externalBadge: {
    alignSelf: 'center',
    paddingLeft: '10px',
  },
}), { name: 'InterAccountTransferDropdownItem' });
export default useStyles;
