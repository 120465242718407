import { AxiosResponse } from 'axios';


import instance from '../Axios/instance';

export default class MassReportingService {
  public static async postMassReporting(
    massReportingRequest: any
  ): Promise<any> {
    const response: AxiosResponse = await instance.post(
      '/reports/multi-report',
      massReportingRequest
    );
    if (response.status !== 202) {
      throw Error('Something went wrong');
    }
    return response.data;
  }

  public static async getMassReporting(take: number): Promise<any> {
    const response = await instance.get(
      `/reports/history?take=${take}&order=desc`,
    );
    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }
    return response.data;
  }
}
