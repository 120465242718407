import { makeStyles } from '@alpha/ui-lib/theme';

import Tick from '../../../assets/tick.svg';

const useStyles = makeStyles(
  (theme) => ({
    widget: {
      background: '#FFFFFF',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
      opacity: 1,
      borderRadius: '10px',
      padding: '40px',
      transition: 'all 0.6s linear',
    },
    smallStatus: {
      letterSpacing: '1px',
      marginTop: '8px',
      padding: '2px 4px',
      fontSize: '10px',
    },
    avatar: {
      height: '62px',
      width: '62px',
      background: 'rgba(30, 135, 119, 0.1)',
      color: '#00a09b',
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: '1.6rem',
    },
    userName: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    lockIcon: {
      color: theme.palette.primary.main,
      marginRight: '5px',
      alignSelf: 'center',
    },
    eyeIconStyles: {
      fontSize: '15px',
      width: '5px',
      transform: 'translate(-40px, 22px)',
      color: '#afafaf',
      position: 'relative',
      backgroundColor: 'transparent',
      border: 0,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    passwordInput: {
      backgroundColor: '#fafafa',
      width: '90%',
      marginTop: '5px',
      marginBottom: '20px',
      borderRadius: '10px',
    },
    boxHeaderText: {
      fontSize: '16px',
      alignSelf: 'center',
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    subTitleText: {
      fontSize: '12px',
      color: '#999999',
    },
    boxText: {
      fontSize: '14px',
      alignSelf: 'left',
    },
    headerWithIcon: {
      display: 'flex',
      flexDirection: 'row',
      height: '30px',
      justifyItems: 'center',
      marginBottom: '20px',
    },
    list: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      fontWeight: theme.typography.fontWeightRegular,
      color: 'rgba(255, 255, 255, 0.4)',
      paddingLeft: 0,
      marginLeft: '40px',
      marginTop: 0,
      marginBottom: 0,
      '& li:not(:last-child)': {
        marginBottom: 5,
      },
    },
    bulletListItem: {
      position: 'relative',
      fontSize: '14px',
      alignSelf: 'left',
      color: theme.palette.grey[900],
      '&::marker': {
        size: 'lg',
        color: theme.palette.primary.main,
      },
      '&.contains': {
        '&::before': {
          position: 'absolute',
          content: `url(${Tick})`,
          left: '-20px',
          top: '2px',
        },
      },
    },
    passwordCriteriaContainer: {
      width: '50%',
      minWidth: '300px',
    },
    passwordHeaderText: {
      fontSize: '16px',
      alignSelf: 'center',
      fontWeight: theme.typography.fontWeightSemiBold,
      marginLeft: '20px',
    },
    expandWidgetBox: {
      display: 'flex',
      flexDirection: 'row',
      height: '375px',
    },
    stickySection: {
      position: 'sticky',
      top: '172px',
      backgroundColor: '#fff',
      zIndex: 100,
    },
    iconRight: {
      transform: 'rotate(-90deg)',
      transition: 'all 0.1s linear',
    },
    iconDown: {
      transition: 'all 0.1s linear',
    },
    preferencesBtn: {
      textDecorationLine: 'underline',
      fontSize: '11px',
      color: '#1E8777',
      padding: '0',
      textTransform: 'none',
    },
    labels: {
      padding: '8px',
    },
    flagText: {
      fontFamily: 'Source Sans Pro',
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#212529',
      fontSize: '14px',
      verticalAlign: 'middle',
      marginLeft: '4px',

    },
    flagCombine: {
      width: '100%',
      height: '17px',
      lineHeight: '20px',
      flexShrink: 0,
    },
    flag: {
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '15px',
    },
  }),
  { name: 'PersonalSettings' },
);

export default useStyles;
