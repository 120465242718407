import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@alpha/ui-lib/theme';
import { IconButton } from '@alpha/ui-lib/ui/button/IconButton';
import {
  Box, Button, Card, Typography,
} from '@alpha/ui-lib/ui/external';
import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileExcel, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import useLog from './useLog';

export const useStyles = makeStyles(
  () => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'right',
      marginLeft: 15,
    },
    card: {
      minWidth: 500,
    },
    completed: {
      backgroundColor: '#1E8777',
    },
    cardWrapper: {
      padding: '24px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    icon: {
      fontSize: '28px',
      fontWeight: 'bold',
      color: 'white',
      marginRight: '24px',
    },
    cardTypography: {
      color: 'white',
      '& h3': {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '2px',
      },
      '& h6': {
        fontSize: '13px',
        fontWight: '200',
      },
    },
    right: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    loader: {
      color: 'white',
    },
    download: {
      color: 'white',
      fontWeight: 'bold',
      letterSpacing: '1.4px',
    },
    close: {
      color: 'white',
    },
  }),
  { name: 'useDownloadFile' },
);

const useDownloadFile = () => {

  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = originalUseSnackbar();
  const { logError } = useLog();
  const downloadFile = async (url: string, fileDescription: string) => {
    try {
      const handleDismiss = (id: string) => {
        closeSnackbar(id);
      };
      enqueueSnackbar(null, {
        autoHideDuration: 10000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (key: string) => (
          <Card
            className={clsx([classes.card, classes.completed])}
          >
            <Box className={classes.cardWrapper}>
              <Box className={classes.left}>
                <Box className={classes.icon}>
                  <FontAwesomeIcon
                    icon={faFileExcel as IconProp}
                  />
                </Box>
                <Box className={classes.cardTypography}>
                  <Typography variant="h3" className="header">
                    {t('download')}
                    {' '}
                    {fileDescription}
                  </Typography>
                  <Typography variant="subtitle1" className="subHeader">
                    {t('click_download_to_receive_your_file')}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.right}>
                <Button
                  download
                  href={url}
                  target="_blank"
                  className={classes.download}
                  onClick={() => { handleDismiss(key); }}
                >
                  {t('download^')}
                </Button>
                <IconButton
                  onClick={() => {
                    handleDismiss(key);
                  }}
                  className={classes.close}
                  icon={faTimes}
                />
              </Box>
            </Box>
          </Card>
        ),
      });
    } catch (error) {
      enqueueSnackbar(error?.message || `Error downloading ${fileDescription} file`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });

      logError({ action: `Error downloading ${fileDescription} file`, error });
    }
  };

  return {
    downloadFile,
  };
};

export default useDownloadFile;
