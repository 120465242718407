import { UserNotificationsResponseDto } from '@alpha/notifications-dtos';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TClientNotificationsStore = {
  data?: UserNotificationsResponseDto;
  loaded: boolean;
  showModal: boolean;
};

const initialState: TClientNotificationsStore = {
  data: undefined,
  loaded: false,
  showModal: false,
};

export const clientNotificationsSlice = createSlice({
  name: 'clientNotifications',
  initialState,
  reducers: {
    load: (state): TClientNotificationsStore => state,
    loaded: (
      state, action: PayloadAction<UserNotificationsResponseDto>,
    ): TClientNotificationsStore => ({
      ...state,
      loaded: true,
      data: action.payload,
    }),
    show: (
      state,
    ): TClientNotificationsStore => ({
      ...state,
      showModal: true,
    }),
    showed: (
      state,
    ): TClientNotificationsStore => ({
      ...state,
      showModal: false,
    }),
    clearClientNotificationDetails: () => initialState,
  },
});

export const { actions } = clientNotificationsSlice;
