import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    container: {
      padding: '0 8px !important',
    },
    modalBox: {
      textAlign: 'center',
      padding: '0 32px',
      width: '315px',
      height: '292px',
    },
    modalHeading: {
      fontSize: '14px',
      fontWeight: typography.fontWeightSemiBold,
      fontFamily: typography.fontFamilySecondary,
      padding: '30px 0px 23px 0px',
    },
    modalSubtitle: {
      fontSize: '12px',
      fontFamily: typography.fontFamilySecondary,
    },
  }),
  { name: 'RequestDrawdownModal' },
);

export default useStyles;
