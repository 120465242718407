import React from 'react';
import clsx from 'clsx';

import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCommentAltDots } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthyIcon from '../../../../assets/authyIcon.png';
import PhoneAuthy from '../../../../assets/phoneAuthy.svg';
import PhoneSMS from '../../../../assets/phoneSms.png';

import useStyles from './index.style';

interface IMfaImage {
  mfaImage: 'authy' | 'sms' | undefined,
}

const HelperAsset: React.FC<IMfaImage> = ({ mfaImage }: IMfaImage) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, mfaImage ? classes.fullOpacity : classes.noOpacity)}>
      <img
        src={mfaImage === 'authy' ? PhoneAuthy : PhoneSMS}
        alt="Onboarding email being displayed on a laptop and a mobile phone"
        className="assetImage"
      />
      <Typography className="assetTitle">
        {
          mfaImage === 'sms' ? <FontAwesomeIcon icon={faCommentAltDots as IconProp} className="marginRight" />
            : (
              <img
                src={AuthyIcon}
                alt="Authy Icon"
                className="marginRight authyIcon"
              />
            )
        }
        {mfaImage === 'authy' ? 'Authy sent to device' : 'SMS sent to device'}
      </Typography>
    </div>
  );
};
export default HelperAsset;
