import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { NotificationContentType, UserNotificationDto } from '@alpha/notifications-dtos';
import theme from '@alpha/ui-lib/theme';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Button, MobileStepper } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import formatIsoDate from '../../../utils/formatIsoDate';
import APModal from '../APModal/APModal';

import useStyles from './NotificationsModal.styles';

type Props = {
  open: boolean;
  handleClose?: () => void;
  handleAcknowledge?: (notification: UserNotificationDto) => void;
  notifications: UserNotificationDto[];
  selectedNotification?: UserNotificationDto;
  isAcknowledging?: boolean;
}

const NotificationModal: React.FC<Props> = (props: Props) => {
  const {
    open,
    handleAcknowledge,
    handleClose,
    notifications,
    selectedNotification,
    isAcknowledging,
  } = props;
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState<number>(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const notificationsToDisplay = selectedNotification ? [selectedNotification] : notifications;
  const maxSteps = notificationsToDisplay.length;

  useEffect(() => {
    if (!open) {
      setActiveStep(0);
    }
  }, [open]);

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <>
        <APModal.Header
          onClose={handleClose}
          showCloseButton
        >
          <Typography className={classes.modalHeader}>
            {t('whats_new')}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={classes.container}>
          {notificationsToDisplay && notificationsToDisplay.length > 0
            && notificationsToDisplay[activeStep] ? (
            <>
              <Box className={classes.modalContent}>
                <Typography className={classes.notificationDate}>
                  {notificationsToDisplay[activeStep].date ? formatIsoDate(notificationsToDisplay[activeStep].date) : ''}
                </Typography>
                <Box display="flex">
                  {!notificationsToDisplay[activeStep].acknowledged
                    && <div className={classes.unreadMarker} />}
                  <Typography className={classes.notificationTitle}>
                    {notificationsToDisplay[activeStep].title}
                  </Typography>
                </Box>
                <div className={classes.notificationSubtitle}>
                  <ReactMarkdown skipHtml>
                    {notificationsToDisplay[activeStep].body}
                  </ReactMarkdown>
                </div>
                {notificationsToDisplay[activeStep].content && (
                  <div>
                    {notificationsToDisplay[activeStep].content?.type
                      === NotificationContentType.VIDEO
                      ? (
                        <>
                          <video controls width="100%" preload="metadata" className={classes.videoPlayer}>
                            <source src={notificationsToDisplay[activeStep].content?.url} type="video/mp4" />
                            <track kind="captions" />
                          </video>
                        </>
                      )
                      : (
                        <img
                          className={classes.noticationImg}
                          src={notificationsToDisplay[activeStep].content?.url}
                          alt="Notification information"
                          onError={(e) => { e.currentTarget.style.display = 'none'; }}
                        />
                      )}
                  </div>
                )}
              </Box>

              {(notificationsToDisplay.length > 1) && (
                <MobileStepper
                  className={classes.stepper}
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  nextButton={(
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >

                      {theme.direction === 'rtl' ? (
                        <FontAwesomeIcon
                          icon={faChevronLeft as IconProp}
                          size="sm"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronRight as IconProp}
                          size="sm"
                        />
                      )}
                    </Button>
                  )}
                  backButton={(
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                      {theme.direction === 'rtl' ? (
                        <FontAwesomeIcon
                          icon={faChevronRight as IconProp}
                          size="sm"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronLeft as IconProp}
                          size="sm"
                        />
                      )}

                    </Button>
                  )}
                />
              )}

              <Box className={classes.footer}>
                <ActionButton
                  onClick={() => {
                    if (handleAcknowledge) {
                      handleAcknowledge(notificationsToDisplay[activeStep]);
                    }
                  }}
                  disabled={isAcknowledging}
                  loading={isAcknowledging}
                >
                  {t('acknowledge')}
                </ActionButton>
              </Box>
            </>
          )
            : <></>}
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default NotificationModal;
