import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Button } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import FooterWrapper from '../../../../../components/Payments/FooterWrapper/FooterWrapper';
import routes from '../../../../../routes.path';
import history from '../../../../../services/history/browserHistory';

const BatchSubmittedFooter = () => {
  const handleClick = () => history.push(routes.transactions.base);


  return (
    <FooterWrapper>
      <Button
        onClick={handleClick}
        startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
      >
        {t('exit')}
      </Button>
      <ActionButton style={{ background: '#F7F7F7', color: '#212529' }} onClick={handleClick}>Close</ActionButton>
    </FooterWrapper>
  );
};

export default BatchSubmittedFooter;
