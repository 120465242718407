import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    tableWrapper: {
      paddingBottom: '30px',
      '& .MuiTableHead-root': {
        borderTop: '#EAEAEA solid 1px',
      },
      '& .MuiTableCell-root': {
        'padding-right': '30px !important',
      },
      '@media (max-width: 1200px)': {
        '& .MuiTableCell-root': {
          'padding-right': '20px !important',
        },
        '& th.MuiTableHead-root, & td.MuiTableCell-root': {
          fontSize: '12px',
          '& span': {
            fontSize: '11px',
          },
        },
      },
      '& td': {
        padding: '10px 0px !important',
      },
      '& th': {
        paddingTop: '20px !important',
      },
    },
    tableButton: {
      textTransform: 'none',
      fontSize: 14,
      padding: 0,
      fontWeight: 600,
      color: 'rgb(9, 131, 117)',
      textDecoration: 'none',
      textAlign: 'left',
      justifyContent: 'left',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
    amount: {
      fontWeight: 600,
    },
    status: {
      minWidth: '154px',
      textAlign: 'center',
    },
  }),
  { name: 'BatchPaymentsTable' },
);

export default useStyles;
