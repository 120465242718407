import React from 'react';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APModal from '../../../../../components/Modals/APModal/APModal';
import MultiSelectDropDown from '../../../../../components/MultiSelectDropDown/MultiSelectDropDown';

import useStyles from './SubmitPaymentsModal.styles';

interface ISubmitPayentsModalProps {
  open: boolean;
  handleClose?: () => void;
  handleSubmitPayments?: () => void;
  setCurrentOptions?: React.Dispatch<React.SetStateAction<any[]>>;
  setTextValue?: React.Dispatch<React.SetStateAction<string>>;
  approvers: Approver[];
  dropdownLoading: boolean;
}

const SubmitPaymentsModal: React.FC<ISubmitPayentsModalProps> = (
  props: ISubmitPayentsModalProps,
) => {
  const {
    open,
    handleClose,
    handleSubmitPayments,
    setCurrentOptions,
    setTextValue,
    approvers,
    dropdownLoading,
  } = props;
  const styles = useStyles();


  const filterEligibleApprovers = (): Approver[] => approvers.filter(
    (approver: Approver) => approver.eligible,
  );

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div className={styles.innerContainer}>
          <FontAwesomeIcon icon={faCircleCheck as IconProp} size="lg" color="#88C165" />
          <div className={styles.textContainer}>
            <Typography className={styles.headerText}>
              {t('submit_payments_for_approval')}
            </Typography>
            <div className={styles.dropdownContainer}>
              <MultiSelectDropDown
                placeholder={t('select_approvers_to_email')}
                options={filterEligibleApprovers()}
                disabled={filterEligibleApprovers().length < 1}
                loading={dropdownLoading}
                setCurrentOptions={setCurrentOptions}
                setTextValue={setTextValue}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <ActionButton
              size="medium"
              style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }}
              onClick={handleClose}
            >
              {t('exit')}
            </ActionButton>
            <ActionButton size="medium" style={{ width: '170px' }} onClick={handleSubmitPayments}>{t('submit_payments')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default SubmitPaymentsModal;
