import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import { OptionsLayoutDto, OptionsStripDto, OptionsStripsStatus } from 'models/optionTrades';
import React, { useState } from 'react';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapOptionsStripsStatusDisplay from 'utils/mapOptionsStripsStatusToDisplay';
import t from 'utils/translationHelper';

import { Box } from '@alpha/ui-lib/ui/external';
import APPagination from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';

import useStyles from '../../OptionsTable.styles';

import { mapOptionsStripDtoToColumns } from './getTableColumns';

interface IProps {
  stripsData: OptionsStripDto[];
  layout: OptionsLayoutDto,
}

const StripsTable: React.FC<IProps> = ({ stripsData, layout }) => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);
  const totalPages = Math.ceil(stripsData.length / 10);
  const sortedStripsData = stripsData
    ? stripsData.slice().sort((a, b) => {
      const individualNumberA = a?.individualNumber ? parseInt(a.individualNumber, 10) : 1;
      const individualNumberB = b?.individualNumber ? parseInt(b.individualNumber, 10) : 0;

      return individualNumberA - individualNumberB;
    })
    : [];

  const visibleStripsData = sortedStripsData.slice(pageNumber * 10, (pageNumber + 1) * 10);
  const hasPrev = () => pageNumber > 0;
  const hasNext = () => (pageNumber + 1) < totalPages;

  const currentItems = hasNext() ? (pageNumber + 1) * 10 : sortedStripsData.length;

  const handleNext = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handlePrev = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const formatStringNumbers = (value: string) => {
    if (value) {
      const numberValue = parseInt(value?.split(' ')[0], 10);
      const formattedValue = numberValue ? formatNumber(numberValue, 2) : '-';
      const currency = value?.split(' ')?.[1] ?? '';
      return `${formattedValue}  ${currency}`;
    }
    return '-';
  };

  const generateTableData = () => visibleStripsData.map((strip) => ({
    ...strip,
    id: strip.individualNumber ? <div style={{ fontWeight: 600 }}>{strip.individualNumber}</div> : '-',
    name: strip.name || '-',
    individualNumber: strip.individualNumber || '-',
    status: (
      <Status
        className={classes.status}
        variant={mapOptionsStripsStatusDisplay(strip.status as OptionsStripsStatus).variant}
      >
        {t(mapOptionsStripsStatusDisplay(strip.status as OptionsStripsStatus).text) || '-'}
      </Status>),
    notionalCurrency: strip?.notionalCurrency || '-',
    barrierStart: strip?.barrierStart ? formatIsoDate(strip?.barrierStart) : '-',
    barrierEnd: strip?.barrierEnd ? formatIsoDate(strip?.barrierEnd) : '-',
    deliveryDate: strip?.deliveryDate ? formatIsoDate(strip?.deliveryDate) : '-',
    paymentDate: strip?.paymentDate ? formatIsoDate(strip?.paymentDate) : '-',
    expiryDate: strip?.expiryDate ? formatIsoDate(strip?.expiryDate) : '-',
    observationDate: strip?.observationDate ? formatIsoDate(strip?.observationDate) : '-',
    notionalAmount: formatStringNumbers(strip?.notionalAmount),
    committedAmount: formatStringNumbers(strip?.committedAmount),
    leveragedAmount: formatStringNumbers(strip?.leveragedAmount),
    digitalAmount: formatStringNumbers(strip?.leveragedAmount),
    partialAmount: formatStringNumbers(strip?.partialAmount),
    strikeRate: formatNumber(parseFloat(strip?.strikeRate), 4),
    capLevel: formatNumber(parseFloat(strip?.capLevel), 4),
    plusRate: formatNumber(parseFloat(strip?.plusRate), 4),
    resetRate: formatNumber(parseFloat(strip?.resetRate), 4),
    trackingLevel: formatNumber(parseFloat(strip?.trackingLevel), 4),
    barrierLevel: formatNumber(parseFloat(strip?.barrierLevel), 4),
    kiBarrier: formatNumber(parseFloat(strip?.kiBarrier), 4),
    koBarrier: formatNumber(parseFloat(strip?.koBarrier), 4),
  }));
  return (
    <div style={{
      padding: '20px',
      gridColumn: 'span 3 / auto',
      backgroundColor: '#ffffff',
      marginTop: '20px',
      borderRadius: '10px',
      boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.03)',
    }}
    >
      <div style={{ fontWeight: 600, fontSize: '16px', padding: '8px' }}>
        {t('strips')}
        <span style={{ fontWeight: 300, fontSize: '12px', display: 'block' }}>{t('strip_level_breakdown')}</span>
      </div>
      <div data-testid="trades-loader-component" className={classes.tableWrapper}>
        {visibleStripsData?.length ? (
          <StyledGenericTable
            testId="preference-table"
            columns={mapOptionsStripDtoToColumns(stripsData, layout)}
            data={generateTableData()}
          />
        ) : (
          <div style={{ padding: '20px', backgroundColor: 'white', marginTop: '20px' }}>
            <EmptyTable
              title={t('currently_no_data')}
              subtitle={t('if_incorrect_contact_dealer')}
              className={classes.emptyTable}
              icon={clipboardCross}
              style={{ transform: 'none', position: 'unset' }}
            />
          </div>
        )}
      </div>
      {visibleStripsData?.length
        ? (
          <Box
            display="flex"
            flexDirection="rows"
            gridGap={16}
            justifyContent="space-between"
            marginTop="10px"
          >
            <span style={
              {
                fontWeight: 300,
                lineHeight: '26px',
              }
            }
            >
              {t('showing')}
              {' '}
              {currentItems}
              {' '}
              {t('of')}
              {' '}
              {sortedStripsData?.length}
            </span>
            <APPagination
              hasPrevious={hasPrev()}
              hasNext={hasNext()}
              handleNext={handleNext}
              handlePrevious={handlePrev}
              nextString={t('next^')}
              prevString={t('prev^')}
            />
          </Box>
        ) : null}
    </div>
  );
};

export default StripsTable;
