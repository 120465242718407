import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    padding: '0 8px !important',
  },
  modalContent: {
    textAlign: 'right',
    padding: '20px',
    width: '700px',
  },
  modalMessageBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  headerBox: {
    width: '700px',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #E2E2E2',
    padding: '10px',
    marginBottom: '15px',
  },
  headerText: {
    fontWeight: typography.fontWeightBold,
    marginBottom: '16px',
    marginLeft: '10px',
    fontSize: '18px',
  },
  modalBoldText: {
    fontWeight: typography.fontWeightBold,
    marginBottom: '16px',
    fontSize: '14px',
  },
  modalText: {
    marginBottom: '16px',
    fontSize: '14px',
    textAlign: 'left',
  },
  modalImage: {
    width: '70px',
    marginBottom: '24px',
  },
  customStyledStat: {
    '& div[class^="StyledStatSummary-statBox"]': {
      display: 'flex',
      width: '100%',
      marginBottom: '25px',
      marginTop: '10px',
      '& div:last-child': { background: '#F2F2F2' },
    },
  },
}), { name: 'ApprovePaymentModal' });

export default useStyles;
