import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    guidesHeaderContainer: {
      '& header': {
        position: 'absolute',
        '&~svg': {
          color: 'white'
        },
        '& .MuiButton-label span div svg': {
          color: 'white!important',
        },
         '& .MuiButton-label span:first-of-type': {
          color: 'white!important',
        },
        '& * svg': {
          color: 'white!important',
        },
        '& .PortalHeaderLight-accountName': {
          color: 'white',
        }
      },
    },
      dashboardHeaderContainer: {
      '& header': {
        position: 'absolute',
      }
    },
    drawerWrapper: {

    },
    navButtonEnabled: {
    },
    navButtonDisabled: {
      '& > header': {
        '& > div:nth-of-type(1)': {
          '& > div:nth-of-type(1)': {
            pointerEvents: 'none',
            '& > span': {
              background: '#707070',
            },
          },
        },
      },
    },
  }),
  { name: 'Header' },
);
