import { Loader } from "@alpha/ui-lib/ui/Loader";
import { Status } from "@alpha/ui-lib/ui/Status";
import { faCancel, faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import formatIsoDate from "utils/formatIsoDate";
import formatString from "utils/formatString";
import t from "utils/translationHelper";
import reportStatuses from "./reportStatusEnum";

type existingReport = {
  id: string,
  type: string,
  date: string,
  status: string,
  url: string,
  fileName: string,
}[]

const useExportsTable = (apiData: existingReport, pageNumber: number) => {
  const columns = [
    { header: t('report_type'), accessor: 'reportType', width: '25%' },
    { header: t('export_run_date'), accessor: 'exportRunDate', width: '25%' },
    { header: t('status'), accessor: 'status', width: '25%' },
    { header: t('download_link'), accessor: 'downloadLink', align: 'right', width: '25%' },
  ];

  const mapReportType = (reportType?: string) => {
    if (reportType === 'STATEMENTS') {
      return t('currency_account_statement');
    }
    if (reportType === 'MTM') {
      return t('mtm_report');
    }
    if (reportType === 'SINGLE_STATEMENTS_LIST') {
      return t('single_statements_list');
    }
    return '';
  };

  const getDisplayStatus = (status: string, dateTime: string) => {
    if (expired(dateTime) && status === 'COMPLETE') {
      return t('expired').toUpperCase();
    }
    return t(status);
  };

  const expired = (dateTime: string) => {
    const dateTime1 = moment(dateTime);
    const currentDateTime = moment();
    const diff = currentDateTime.diff(dateTime1, 'days', true)
    return diff >= 0.5; // LINK EXPIRES IN 12 HOURS === 0.5 DAYS
  };

  const mapStatus = (status: string, dateTime: string) => {
    if (expired(dateTime)) {
      return 'error';
    }
    if (status === reportStatuses.IN_PROGRESS) {
      return 'info';
    }
    if (status === reportStatuses.ERRORED) {
      return 'error';
    }
    if (status === reportStatuses.COMPLETE) {
      return 'success';
    }
    return '';
  };

  const mappedData = apiData.slice(pageNumber * 20, (pageNumber + 1) * 20).map((r, index: number) => {
    return {
      reportType: (
        <div>
          {formatString(mapReportType(r.type))}
        </div>
      ),
      exportRunDate: (
        <div>
          {formatIsoDate(r.date, 'dd/MM/yyyy | HH:mm')}
        </div>
      ),
      status: (
        <div>
          <Status variant={mapStatus(r.status, r.date)}>
            {getDisplayStatus(r.status, r.date)}
          </Status>
        </div>
      ),
      downloadLink: r.status !== reportStatuses.IN_PROGRESS ? (
        <>
          {!expired(r.date) && r.status !== reportStatuses.ERRORED ? (<a href={r.url}>
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '44px' }} />
          </a >) : (
            <FontAwesomeIcon icon={faCancel} style={{ color: 'rgb(185, 86, 86)', marginRight: '44px' }} />
          )}
 
        </>
      ) : (
        <div style={{ float: 'right', marginRight: '44px' }}>
          <Loader testId={`download-link-loader-${index}`} style={{ height: '13px', width: '13px' }} />
        </div>)
    }
  })

  return { columns, data: mappedData }
}

export default useExportsTable