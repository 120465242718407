import React, {
  FC, PropsWithChildren, useContext, useState,
} from 'react';

const ThemeContext = React.createContext(false);
const ThemeUpdateContext = React.createContext((showSidebar: boolean) => { });

export const useTheme = () => useContext(ThemeContext);

export const useThemeUpdate = () => useContext(ThemeUpdateContext);

export const NavSidebarContext: FC = (props: PropsWithChildren<unknown>) => {
  const [sidebar, setSidebar] = useState(true);

  const toggleSidebar = (showSidebar: boolean) => {
    setSidebar(showSidebar);
  };

  return (
    <ThemeContext.Provider value={sidebar}>
      <ThemeUpdateContext.Provider value={toggleSidebar}>
        {props.children}
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
};
