import React from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import t from 'utils/translationHelper';

import { UserNotificationDto } from '@alpha/notifications-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Box, Button, Typography } from '@alpha/ui-lib/ui/external';

import BackdropLoader from '../../Molecules/Loaders/BackdropLoader/BackdropLoader';

import NotificationDrawerItem from './NotificationDrawerItem/NotificationDrawerItem';
import useStyles from './NotificationDrawer.styles';

interface IProps {
  open: boolean;
  loading?: boolean;
  handleClose?: () => void;
  handleClick?: (notification: UserNotificationDto) => void;
  disableBackdropClick?: boolean;
  notifications: UserNotificationDto[];
  unreadNotifications: UserNotificationDto[];
  handleOpenUnread?: () => void;
}

const NotificationDrawer: React.FC<IProps> = ({
  open,
  handleClose,
  handleClick,
  loading,
  disableBackdropClick = true,
  notifications,
  unreadNotifications,
  handleOpenUnread,
}: IProps) => {
  const classes = useStyles();

  return (
    <BaseDrawer.Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      disableBackdropClick={disableBackdropClick}
      PaperProps={{ style: { minWidth: '458px', width: '458px' } }}
    >
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{t('whats_new')}</Typography>
        {unreadNotifications.length > 0
          ? (
            <Typography
              className={classes.unreadSubtitle}
            >
              <Button
                className={classes.unreadSubtitleButton}
                onClick={() => {
                  if (handleOpenUnread) { handleOpenUnread(); }
                }}
              >
                {`${unreadNotifications.length} ${t('unread')}`}
              </Button>
            </Typography>
          )
          : <Typography className={classes.noNewSubtitle}>{t('no_new_notifications')}</Typography>}
      </div>
      {loading && <BackdropLoader testId="notification-drawer-loader" />}
      {notifications.length > 0 && !loading
        && notifications.map(
          (notification) => (
            <NotificationDrawerItem
              notification={notification}
              handleClick={handleClick}
            />
          ),
        )}

      {notifications.length === 0 && (
        <Box
          className={classes.emptyPlaceholder}
          display="flex"
          flexDirection="column"
          gridGap="10px"
        >
          <Box className={classes.emptyImage}>
            <img src={clipboardCross} alt="empty-table" />
          </Box>
          <Typography>{t('there_are_no_new_notifications')}</Typography>
        </Box>
      )}
    </BaseDrawer.Drawer>
  );
};

export default NotificationDrawer;
