import { FormikProps } from 'formik';
import React, { memo, useState } from 'react';
import t from 'utils/translationHelper';

import { ValidateBeneficiaryRequest } from '@alpha/bene-dtos';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';

import InputAndLabel from '../../../../../components/Inputs/Beneficiary/InputAndLabel';

import useStyles from './styles';

interface IProps {
  form: FormikProps<ValidateBeneficiaryRequest>;
  required?: boolean,
  disabled?: boolean,
}

const ExtraBankDetails: React.FC<IProps> = ({ form, required = false, disabled }: IProps) => {
  const styles = useStyles();
  const [checked, setChecked] = useState<boolean>(false);


  return (
    <div>
      <Checkbox
        onChange={() => setChecked(!checked)}
        testId="testId"
        checked={required || checked}
        disabled={required || disabled}
        label={
          required ? (
            <Typography variant="subtitle1">{t('corresponding_bank_details_are_required')}</Typography>
          ) : (
            <Typography variant="subtitle1">{t('does_this_beneficiary_have_correspondent_bank_details')}</Typography>
          )
        }
      />
      {
        (checked || required)
        && (
          <div className={styles.extraInformationWrapper} data-dd-privacy="mask-user-input">
            <InputAndLabel
              label={t('correspondent_account_number')}
              name="correspondentAccountNumber"
              id="correspondentAccountNumber"
              testId="correspondentAccountNumber"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.correspondentAccountNumber}
              error={
                form.touched.correspondentAccountNumber
                && Boolean(form.errors.correspondentAccountNumber)
              }
              helperText={
                form.touched.correspondentAccountNumber
                && form.errors.correspondentAccountNumber
              }
              disabled={disabled}
            />
            <InputAndLabel
              label={t('correspondent_swift')}
              name="correspondentSwift"
              id="correspondentSwift"
              testId="correspondentSwift"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.correspondentSwift}
              error={
                form.touched.correspondentSwift
                && Boolean(form.errors.correspondentSwift)
              }
              helperText={
                form.touched.correspondentSwift
                && form.errors.correspondentSwift
              }
              disabled={disabled}
            />
            <InputAndLabel
              label={t('further_to_account_number')}
              name="furtherToAccountNumber"
              id="furtherToAccountNumber"
              testId="furtherToAccountNumber"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.furtherToAccountNumber}
              error={
                form.touched.furtherToAccountNumber
                && Boolean(form.errors.furtherToAccountNumber)
              }
              helperText={
                form.touched.furtherToAccountNumber
                && form.errors.furtherToAccountNumber
              }
              disabled={disabled}
            />
            <InputAndLabel
              label={t('further_to_swift')}
              name="furtherToSwift"
              id="furtherToSwift"
              testId="furtherToSwift"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.furtherToSwift}
              error={
                form.touched.furtherToSwift
                && Boolean(form.errors.furtherToSwift)
              }
              helperText={
                form.touched.furtherToSwift
                && form.errors.furtherToSwift
              }
              disabled={disabled}
            />
          </div>
        )
      }
    </div>
  );
};

export default memo(ExtraBankDetails);
