import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ palette, typography }) => ({
    amount: {
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'row',
      lineHeight: '14px',
      justifyContent: 'flex-end',
    },
    tableLink: {
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    tableButton: {
      textTransform: 'none',
      fontSize: 14,
      padding: 0,
      fontWeight: 600,
      color: 'rgb(9, 131, 117)',
      textDecoration: 'none',
      textAlign: 'left',
      justifyContent: 'left',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
    status: {
      minWidth: '130px',
      textAlign: 'center',
    },
    emptyTable: {
      height: '200px',
      position: 'relative',
      marginBottom: '20px',
      '& .image': {
        height: '120px',
        width: '120px',
        '& img': {
          height: '60',
          width: '60px',
        },
      },
    },
    currencyFlag: {
      marginLeft: '5px',
    },
    action: {
      textAlign: 'center',
      paddingRight: 20,
    },
    link: {
      color: palette.primary.light,
    },
    showAllMessage: {
      color: palette.secondary.light,
      marginTop: '12px',
      fontSize: '12px',
    },
    stickyHeaderTable: {
      marginTop: '0px',
      borderCollapse: 'separate',
      '& thead': {
        position: 'sticky',
        top: '0',
        background: 'white',
        zIndex: 10,
        '& th': {
          borderBottom: '1px solid #eaeaea',
          paddingRight: '20px !important',
          background: 'white',
        },
      },
      '& tbody': {
        zIndex: 9,
        '& td': {
          paddingRight: '20px !important',
        },
      },
    },
    beneCurrency: {
      '& > div': {
        flexDirection: 'row',
        '& > div': {
          paddingRight: '5px',
        },
      },
    },
  }), { name: 'OutstandingActionsWidget' },
);

export default useStyles;
