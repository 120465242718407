import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 0,
      padding: "4px 8px",
      textTransform: "none",
      background: "#F7F7F7",
      borderRadius: "4px",
    },
    buttonText: {
      marginLeft: "10px",
      color: "#212529",
      fontWeight: 200,
    }
  }),
  { name: 'Buttons' },
);

export default useStyles;
