import { FeatureFlag } from '@alpha/feature-flags';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TFeatureFlagsStore = {
    flags: FeatureFlag[];
}

const getFlagsFromLocalStorage = (): FeatureFlag[] => JSON.parse(localStorage.getItem('feature-flags'))! || [];

const initialState:TFeatureFlagsStore = {
  flags: getFlagsFromLocalStorage(),
};

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlags: (state, action: PayloadAction<{
        flags: FeatureFlag[]
    }>): TFeatureFlagsStore => (
      {
        ...state,
        ...action.payload,
      }),
  },
});

export const { actions } = featureFlagsSlice;
