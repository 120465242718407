import Axios, { AxiosResponse } from 'axios';

import { AccountDto, IncomingUserData, PortalPagesConfigurationDto } from '@alpha/auth-dtos';

import environmentVars from '../../env.variables';
import AuthService from '../Auth/auth.service';
import instance from '../Axios/instance';

export class AuthorizationService {
  public static getUserAccounts = async (): Promise<AccountDto[]> => {
    const sessionId = AuthService.getSessionIdFromLocalStorage();

    const response: AxiosResponse<AccountDto[]> = await Axios.get(
      `${environmentVars.REACT_APP_API_URL}auth/user-accounts`,
      {
        headers: {
          'account-id': 'null',
          Authorization: await AuthService.getAccessToken(),
          'session-id': sessionId,
        },
      },
    );
    if (response.status === 200) {
      return response.data;
    }
    throw Error(`There was an issue with axios request:${response.statusText}`);
  }

  public static getPortalPagesConfigurations = async (): Promise<PortalPagesConfigurationDto> => {
    const response: AxiosResponse<PortalPagesConfigurationDto> = await instance.get('/auth/portal-pages-configuration');
    return response.data;
  }

  public static async createNewUser(
    userRequest: IncomingUserData,
  ):
  Promise<{ pending: boolean, success: boolean, message?: string }> {
    const response = await instance.post(
      '/auth/users/create',
      userRequest,
    );
    if (response.status !== 201) {
      throw Error(`${response.data.error}`);
    }

    if (!response.data) {
      return { success: true, pending: false };
    }

    return response.data;
  }
}

export default AuthorizationService;
