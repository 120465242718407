import React from 'react';
import clsx from 'clsx';

import { Box } from '@alpha/ui-lib/ui/external';

import useStyles from './EmptyTable.styles';

/* eslint-disable react/require-default-props */
export interface IEmptyTable {
  text?: string,
  title?: string,
  subtitle?: string,
  icon?: string,
  width?: string,
  style?: React.CSSProperties;
  className?: string;
  isCredit?: boolean;
}

const EmptyTable: React.FC<IEmptyTable> = ({
  text, title, subtitle, icon, width, style, className, isCredit,
}: IEmptyTable) => {
  const styles = useStyles({ isCredit });

  return (
    <Box className={clsx(styles.parent, className)} width={width} textAlign="center" style={style}>
      {text && <p className={styles.text}>{text}</p>}
      <Box className={styles.multiLine}>
        {icon && (
          <Box className={clsx([styles.image, 'image', isCredit].join(' '))}>
            <img src={icon} alt="empty-table" />
          </Box>
        )}
        {title && <p>{title}</p>}
        {subtitle && <p>{subtitle}</p>}
      </Box>
    </Box>
  );
};

export default EmptyTable;
