import { OptionTradeDto } from 'models/optionTrades';
import React from 'react';
import formatIsoDate from 'utils/formatIsoDate';
import t from 'utils/translationHelper';

import { Typography } from '@alpha/ui-lib/ui/external';
import { SingleStat } from '@alpha/ui-lib/ui/StatSummary/SingleStat';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './index.styles';
import { formatNumber } from 'utils/currency.helpers';

interface IProps {
    summary: OptionTradeDto;
  }

const TradeSummary: React.FC<IProps> = ({ summary }) => {
  const tarfTrade = summary.type === 'TARF';
  const accrualTargetType = summary.targetType === 'Accrual';
  const styles = useStyles({ tarfTrade });

  const statHeaders = [{
    header: t('trade_date'), value: summary.tradeDate ? formatIsoDate(summary.tradeDate) : '-',
  }, {
    header: t('ccy_pair'), value: summary.currencyPair ? summary.currencyPair : '-',
  },
  {
    header: t('direction'), value: summary.direction ? summary.direction : '-',
  },
  {
    header: t('strip_frequency'), value: summary.stripFrequency ? summary.stripFrequency : '-',
  },
  {
    header: t('strips_remaining'), value: summary.stripsRemaining ? summary.stripsRemaining : '-',
  },
  {
    header: t('next_expiry'), value: summary.nextExpiry ? formatIsoDate(summary.nextExpiry) : '-',
  },
  {
    header: t('final_expiry'), value: summary.finalExpiry ? formatIsoDate(summary.finalExpiry) : '-',
  }];

  tarfTrade && statHeaders.push({
    header: t('original_target'), value: summary.originalTarget && !accrualTargetType ? summary.originalTarget : formatNumber(summary.originalTarget, 5) || '-',
  }, {
    header: t('target_remaining'), value: summary.targetRemaining && !!accrualTargetType ? summary.targetRemaining : formatNumber(summary.targetRemaining, 5) || '-',
  });


  const styledSummary = (
    <p>
      <FontAwesomeIcon style={{ verticalAlign: '-2px', display: 'inline', marginRight: '8px' }} icon={faInfoCircle as IconProp} />
      {t('summary')}
      {' '}
      |

      <p style={{ fontWeight: 300, fontSize: '16px', display: 'inline' }}>
        {' '}
        {summary.productName}
        <span className={styles.summaryTitle}>
          {t('option_trade_summary_info')}
        </span>
      </p>
    </p>
  );

  return (
    <div className={styles.summaryHeader}>
      <div style={{
        display: 'flex',
        paddingRight: '30px',
      }}
      >
        <Typography className={styles.summary}>{styledSummary}</Typography>
      </div>
      <div className={styles.statsContainer}>
        {statHeaders?.map((item) => (
          <SingleStat
            statHeader={(
              <div style={{
                minWidth: '90px', fontWeight: 300, fontSize: '12px',
              }}
              >
                {item.header}
              </div>
                )}
            value={<div style={{ fontSize: '14px' }}>{item.value}</div>}
          />
        ))}

      </div>
      <div style={{ justifyContent: 'end', display: 'flex' }}>
        <span className={styles.lastUpdatedAt}>
          {t('last_updated')}
          {' '}
          {' '}
          {formatIsoDate(summary.lastUpdatedAt, 'HH:mm dd/MM/yyyy')}
        </span>
      </div>
    </div>
  );
};

export default TradeSummary;
