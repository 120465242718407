import clsx from 'clsx';
import BackdropLoader from 'components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import Search from 'components/Organisms/Search';
import SearchTable from 'components/Organisms/SearchTable';
import React, { useState } from 'react';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import t from 'utils/translationHelper';

import { ExternalTradeMTMDto } from '@alpha/credit-dtos';
import {
  Chip, Typography,
} from '@alpha/ui-lib/ui/external';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './ExternalTradesMtm.styles';
import useExternalTradesMtmTable from './useExternalTradesMtmTable';

interface IExternalTradesMtm {
  mtmDate: string;
  setHasExternalTrades: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCurrency?: string;
}

const ExternalTradesMtm: React.FC<IExternalTradesMtm> = ({
  mtmDate,
  setHasExternalTrades,
  selectedCurrency
}) => {

  const classes = useStyles();
  const [currentSortBy, setCurrentSortBy] = useState<string>('tradeDate');
  const [currentSortOrder, setCurrentSortOrder] = useState<'asc' | 'desc'>('desc');

  const {
    table,
    searchParams,
    handleInputChange,
    handleTableSortClick,
  } = useExternalTradesMtmTable(
    mtmDate,
    currentSortBy,
    currentSortOrder,
    selectedCurrency
  );

  const columns: ITableColumn[] = [
    { header: t('trade_number'), accessor: 'contractNumber', sortable: true },
    { header: t('trade_date'), accessor: 'tradeDate', sortable: true },
    { header: t('value_date'), accessor: 'valueDate', sortable: true },
    {
      header: t('sold_amount'), accessor: 'soldAmount', align: 'right', sortable: true,
    },
    {
      header: t('rate'), accessor: 'rate', align: 'right', sortable: true,
    },
    {
      header: t('buy_amount'), accessor: 'buyAmount', align: 'right', sortable: true,
    },
    { header: t('mtm_value'), accessor: 'mtmValue', align: 'right' },
    { header: `${selectedCurrency} ${t('rate')}`, accessor: 'reportingRate', align: 'right' },
    { header: `${t('mtm_value')} (${selectedCurrency})`, accessor: 'facilityCurrencyMTMValue', align: 'right' },
  ];

  const generateTableData = () => {
    const tradeMtms = table?.items?.items?.records as ExternalTradeMTMDto[] || [];
    if (tradeMtms.length > 0 && setHasExternalTrades) {
      setHasExternalTrades(true);
    }
    const extTag = (
      <Chip
        label={<FontAwesomeIcon icon={faExternalLink} fontSize="6px" />}
        style={{
          height: '15px',
          width: '20px',
          backgroundColor: '#F7F7F7',
          color: '#1473E6',
          fontSize: '6px',
          borderRadius: '2px',
          padding: '4px 9px 4px 4px',
          marginRight: '4px',
        }}
      />
    );

    return tradeMtms.map((tradeMtm) => ({
      ...tradeMtm as ExternalTradeMTMDto,
      contractNumber: (
        <>
          <>{extTag}</>
          {tradeMtm.contractNumber}
        </>
      ),
      rate: <span className={classes.rateLink}>{tradeMtm.rate ? tradeMtm.rate.toFixed(4) : '-'}</span>,
      tradeDate: formatIsoDate(tradeMtm.tradeDate),
      valueDate: formatIsoDate(tradeMtm.valueDate),
      soldAmount: <span className={classes.amount}>{[formatNumber(tradeMtm.soldAmount || 0, 2), tradeMtm.soldCurrencyCode].join(' ')}</span>,
      buyAmount: <span className={classes.amount}>{[formatNumber(tradeMtm.buyAmount || 0, 2), tradeMtm.buyCurrencyCode].join(' ')}</span>,
      mtmValue: (
        <span className={classes.amount}>
          {[formatNumber(tradeMtm.mtmValue || 0, 2), tradeMtm.mtmCurrencyCode].join(' ')}
        </span>
      ),
      reportingRate: <span className={classes.rateLink}>{tradeMtm.facilityCurrencyMTMRate ? tradeMtm.facilityCurrencyMTMRate.toFixed(4) : '-'}</span>,
      facilityCurrencyMTMValue: (
        <span className={classes.amount}>
          {[formatNumber(tradeMtm.facilityCurrencyMTMValue || 0, 2), tradeMtm.facilityCurrencyMTMCurrencyCode].join(' ')}
        </span>
      ),
    }));
  };

  return (
    <div data-testid="external-trades-mtm-loader-component" className={clsx(classes.tableWrapper, 'fullWidth')}>
      {
        table.loading
        && <BackdropLoader testId="loader" />
      }
      <div style={{ display: 'flex', flexGrow: '1', alignItems: 'center' }}>
        <div style={{ marginRight: '24px' }}>
          <Typography variant="h6" style={{ fontWeight: 600 }}>{t('external_trades')}</Typography>
          <span style={{ color: '#999999', fontSize: '12px' }}>{t('summary_of_external_trades_and_mtm_positions')}</span>
        </div>
        <div className={classes.verticalLine} />
        <Search
          testId="search-trades-mtm"
          value={table.searchText}
          totalItems={table.items?.total}
          placeholder={t('search_by_trade_id')}
          onChange={handleInputChange}
          loading={table.loading}
        />
      </div>
      <SearchTable
        table={{
          columns,
          data: generateTableData(),
          activeColumn: table.sortBy,
          sortOrder: table.sortOrder,
          skip: table.skip,
          dataTestId: 'external-trade-mtm-table',
          keyColumn: 'id',
          totalRowNumber: table.items?.total,
          handleTableSortClick: (column: string) => {
            handleTableSortClick(
              column,
            );
            setCurrentSortBy(column);
            let newOrder: 'asc' | 'desc';
            if (column === currentSortBy) {
              newOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';
            } else {
              newOrder = 'desc';
            }
            setCurrentSortOrder(newOrder);
          },
        }}
        loading={table.loading}
        pagination={{
          handleNext: () => table.handleNextPage(searchParams),
          handlePrevious: () => table.handlePreviousPage(searchParams),
          hasNext: Boolean(table.items?.hasNext),
          hasPrevious: Boolean(table.items?.hasPrevious),
        }}
        emptyTable={{
          title: t('no_external_trades_mtm'),
          subtitle: t('you_currently_dont_have_external_trades'),
          className: classes.emptyTable,
        }}
      />
    </div>
  );
};

export default ExternalTradesMtm;
