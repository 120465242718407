import React from 'react';

import { MarginAccountDto } from '@alpha/currency-accounts-dtos';
import t from 'utils/translationHelper';

import { Table } from '../../../components/Table/Table';
import { TCurrencyAccountDrawer } from '../../../models/currencyAccounts';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import { formatCurrency } from '../../../utils/currency.helpers';
import Buttons from '../CurrencyAccountsTable/Buttons/Buttons';

interface IProps {
  marginAccounts: MarginAccountDto[];
  handleOpenDrawer: (_drawer: TCurrencyAccountDrawer) => void
}

const MarginAccountsTable: React.FC<IProps> = ({ marginAccounts, handleOpenDrawer }: IProps) => {


  const columns: ITableColumn[] = [
    { header: t('type'), accessor: 'type', width: 180 },
    { header: '', accessor: 'buttons', width: 600 },
    {
      header: t('margin_held'),
      accessor: 'marginHeldFormatted',
      align: 'right',
    },
  ];


  const formattedData = marginAccounts.map((ma) => ({
    type: ma.friendlyName,
    marginHeldFormatted: formatCurrency(ma.currencyCode, ma.marginHeld),
    buttons: (
      <Buttons
        handleOpenDrawer={handleOpenDrawer}
        accountId={ma.id}
        hideAccountInfoBtn
        accountType={ma.accountType}
        hideCreatePaymentBtn
        hideIATBtn
      />
    ),
  }));

  return (
    <Table
      columns={columns}
      data={formattedData}
      dataTestId="margin-accounts-table"
      skip={0}
    />
  );
};

export default MarginAccountsTable;
