import { PaymentBatchStatus } from '@alpha/payments-dtos';

export const mapPaymentBatchStatusDisplay = (
  paymentBatchStatus: PaymentBatchStatus,
): {variant:'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined, text:
  string} => {
  switch (paymentBatchStatus) {
    case PaymentBatchStatus.PENDING_UPLOAD:
      return { variant: 'default', text: 'DRAFT' };
    case PaymentBatchStatus.VALIDATED:
      return { variant: 'info', text: 'SUBMITTED' };
    case PaymentBatchStatus.INVALID:
      return { variant: 'error', text: 'INVALID' };
    default:
      return { variant: 'info', text: 'DRAFT' };
  }
};

export default mapPaymentBatchStatusDisplay;
