import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    menuButton: {
      '& button': {
        width: '140px',
        height: '90px',
        fontWeight: typography.fontWeightSemiBold,
        padding: '16px',
        background: '#94949422',
        borderRadius: '10px',
        textTransform: 'none',
        boxShadow: '0px 3px 6px #00000008',
        color: '#949494',
        '&.MuiButton-root:hover': {
          background: '#9494948',
          color: '9494948',
        },
      },
    },
    editButtonIcon: {
      color: '#949494',
    },
    title: {
      fontSize: '18px',
      padding: '0px 6px 6px 0px',
      fontWeight: typography.fontWeightMedium,
      color: '#333333',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingLeft: '160px',
      gap: '10px',
    },
    editDashboardMenu: {
      '& .MuiInput-underline:before': {
        border: '0',
      },
      '& .MuiList-root li': {
        padding: '4px 16px 4px 16px',
      },
    },
    loading: {
      display: 'inline-block',
    },
  }),
  { name: 'RightAlignedCta' },
);

export default useStyles;
