import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { AllowedCurrenciesDto, TradeDto, TradeStatus } from '@alpha/fx-dtos';
import { TStyledStatSummaryData } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../../../hooks/useAuthorization';
import useLog from '../../../../../hooks/useLog';
import { TSearchParams } from '../../../../../hooks/useSearch';
import { UserRole } from '../../../../../models/user';
import CurrencyAccountsService from '../../../../../services/CurrencyAccounts/currencyAccounts.service';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';
import SearchService from '../../../../../services/Search/SearchService';
import { formatCurrency } from '../../../../../utils/currency.helpers';

import useStyles from './PaymentBatchFunding.styles';

const usePaymentBatchFunding = (
  paymentCurrencyCode: string | undefined,
  totalValidAmount: number | undefined,
) => {

  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const [defaultCurrencyAccount, setDefaultCurrencyAccount] = useState<CurrencyAccountDto>();
  const [insufficientFunds, setInsufficientFunds] = useState<boolean>(false);
  const [fxEligible, setFxEligible] = useState<boolean>(false);
  const [trades, setTrades] = useState<TradeDto[]>([]);
  const { authorized: drawdownInputter } = useAuthorization([[UserRole.DRAWDOWN_INPUTTER]]);
  const { authorized: spotOrSpotInputter } = useAuthorization(
    [[UserRole.SPOT, UserRole.SPOT_INPUTTER]],
  );
  const [allowedCurrencies, setAllowedCurrencies] = useState<AllowedCurrenciesDto>();

  const loadActiveTradesData = async (_currencyCode: string) => {
    const searchParams: TSearchParams = {
      baseUrl: 'fx/trades/search',
      queryParams: {
        skip: 0,
        take: 1000,
        sortby: 'contractNumber',
        sortorder: 'asc',
        excludeexternaltrades: 'true',
        buycurrency: _currencyCode,
        status: TradeStatus.ACTIVE,
      },
    };

    try {
      const result: {
        total: number,
        records: TradeDto[]
      } = await SearchService.GetTableData(searchParams);
      const drawdownableTrades = result.records?.filter((t) => t.drawdownAvailable === true);
      setTrades(drawdownableTrades);
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_trades'));
      logError({ action: 'Error loading trades', error });
    }
  };

  const getDefaultCurrencyAccount = async (_currencyCode: string) => {
    const currencyAccounts = await CurrencyAccountsService.getCurrencyStats(
      _currencyCode,
    );

    const defaultCA = currencyAccounts.currencyAccounts?.find(
      (ca) => ca.default,
    );

    return defaultCA;
  };

  const currencyAccountDetails: TStyledStatSummaryData[] = [
    {
      key: t('currency_account'),
      value: <span className={styles.statValue}>{defaultCurrencyAccount?.accountName || '-'}</span>,
    },
    {
      key: t('currency'),
      value: <span className={styles.statValue}>{defaultCurrencyAccount?.currencyCode || '-'}</span>,
    },
    {
      key: t('available_balance'),
      value: (
        <>
          <span className={styles.statValue} style={{ marginRight: '5px' }}>
            {formatCurrency(
              defaultCurrencyAccount?.currencyCode || '',
              defaultCurrencyAccount?.clearedBalance || 0,
            )}
          </span>
          <FontAwesomeIcon className={styles.tickIcon} icon={faCheckCircle as IconProp} />
        </>
      ),
    },
  ];

  const getAvailableCurriencies = async (): Promise<void> => {
    try {
      const resultAllowedCurrencies = await FXTradeService.getAllowedFXCurrencies();
      setAllowedCurrencies(resultAllowedCurrencies);
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_available_currencies'), 'error');
      logError({ action: 'Error loading available currencies', error });
    }
  };

  const alphaBankDetails: TStyledStatSummaryData[] = [
    {
      key: t('account_number'),
      value: <span className={styles.statValue}>{defaultCurrencyAccount?.accountNumber || '-'}</span>,
    },
    {
      key: t('national_bank_code'),
      value: <span className={styles.statValue}>{defaultCurrencyAccount?.sortCode || '-'}</span>,
    },
    {
      key: t('iban'),
      value: <span className={styles.statValue}>{defaultCurrencyAccount?.iban || '-'}</span>,
    },
    {
      key: t('reference'),
      value: <span className={styles.statValue}>{defaultCurrencyAccount?.reference || '-'}</span>,
    },
  ];

  useEffect(() => {
    getAvailableCurriencies();
  }, []);

  useEffect(() => {
    if (paymentCurrencyCode && allowedCurrencies) {
      setFxEligible(allowedCurrencies.buyAllowed.map(
        (item) => item.code,
      ).includes(paymentCurrencyCode));
    }
  }, [allowedCurrencies, paymentCurrencyCode, spotOrSpotInputter]);

  useEffect(() => {
    const updateDefaultPaymentCA = async () => {
      if (paymentCurrencyCode) {
        let defaultCA: CurrencyAccountDto | undefined;
        try {
          defaultCA = await getDefaultCurrencyAccount(paymentCurrencyCode);
          if (!defaultCA) {
            sb.trigger(`${t('you_do_not_have_a_default_currency_account_for')} ${paymentCurrencyCode}`);
            logError({
              action: 'User has no default currency account for selected currency',
              detail: {
                currencyCode: paymentCurrencyCode,
              },
            });
          }
          setDefaultCurrencyAccount(defaultCA);
        } catch (error) {
          sb.trigger(error?.message || t('there_was_an_error_retrieving_your_currency_accounts'));
          logError({ action: 'Error loading currency accounts', error });
        }
      }
    };

    updateDefaultPaymentCA();
  }, [paymentCurrencyCode]);

  useEffect(() => {
    const getDrawdownableTrades = async () => {
      if (paymentCurrencyCode) await loadActiveTradesData(paymentCurrencyCode);
    };

    getDrawdownableTrades();
  }, [paymentCurrencyCode]);

  useEffect(() => {
    if (defaultCurrencyAccount) {
      if ((defaultCurrencyAccount?.clearedBalance || 0) < (totalValidAmount || 0)) {
        setInsufficientFunds(true);
      } else {
        setInsufficientFunds(false);
      }
    }
  }, [defaultCurrencyAccount]);

  return {
    drawdownInputter,
    spotOrSpotInputter,
    defaultCurrencyAccount,
    insufficientFunds,
    trades,
    fxEligible,
    setDefaultCurrencyAccount,
    setInsufficientFunds,
    setTrades,
    currencyAccountDetails,
    alphaBankDetails,
    allowedCurrencies,
  };
};

export default usePaymentBatchFunding;
