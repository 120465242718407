import React, { useEffect, useRef, useState } from 'react';
import t from 'utils/translationHelper';

import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box } from '@alpha/ui-lib/ui/external';
import { Card } from '@alpha/ui-lib/ui/layout/Card';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';

import { CurrencyPair } from '../../components/International';
import RequestCurrencyAccountModal from '../../components/Modals/RequestCurrencyAccountModal/RequestCurrencyAccountModal';
import useCurrencyAccountRequest from '../../hooks/useCurrencyAccountRequest';
import useSwitchAccount from '../../hooks/useSwitchAccount';
import { TCurrencyTab } from '../../models/currencyAccounts';
import AccountRequestDupeModal from '../AccountSettings/AccountRequestDupeModal/AccountRequestDupeModal';

import Body from './Body/Body';
import RightAlignedCta from './RightAlignedCTA/RightAlignedCTA';
import useCurrencyAccount from './useCurrencyAccount';

export const testIds = {
  screenTabs: 'screen-tabs',
};
const CurrencyAccounts: React.FC = () => {

  const [openAccountRequestModal, setOpenAccountRequestModal] = useState<boolean>(false);
  const windowWidth = useRef(window.innerWidth);

  const {
    selectedTabIndex, tabs, allTabs,
    loading, handleSetSelectedTabIndex, handleSetSelectedCurrencyCode, dropdownItems,
  } = useCurrencyAccount(windowWidth.current < 600 ? 2 : 6);
  const {
    currencyAccountForm,
    submittingAccountRequest,
    accountCurrencyOption,
    setAccountCurrencyOption,
    openDupeCheckModal,
    setOpenDupeCheckModal,
    handleDupeActionButton,
  } = useCurrencyAccountRequest({ setOpenAccountRequestModal });
  const {
    homePageUrl,
  } = useSwitchAccount();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];

  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<{ content: JSX.Element, onClick: () => void }[]>([]);

  useEffect(() => {
    setMenuItems(dropdownItems.map((item) => ({
      content: <CurrencyPair currencyCode={item.currencyCode} />,
      onClick: () => {
        handleSetSelectedCurrencyCode(item.currencyCode);
      },
    })));
  }, [dropdownItems]);

  const dropdown = (
    <div style={{ maxWidth: 'fit-content', margin: 'auto 0', marginBottom: '14px' }}>
      <APMenu
        open={Boolean(open)}
        setOpen={setOpen}
        anchorEl={open}
        buttonTitle={`+ ${dropdownItems.length}`}
      >
        {
          menuItems.map((item) => (
            <MenuDropdownItem setOpen={setOpen}>
              {item}
            </MenuDropdownItem>
          ))
        }
      </APMenu>
    </div>
  );

  return (
    <APMainLayout
      sticky
      height="170px"
      data-id="currencyAccounts"
      title={t('accounts')}
      breadCrumbs={breadcrumbs}
      rightAlignedCta={(
        <RightAlignedCta
          handleOpenAccountRequestModal={() => setOpenAccountRequestModal(true)}
        />
      )}
      pageTabs={(
        <Box display="flex" flexDirection="row" gridGap={32}>
          <StyledTabsWrapper
            testId={testIds.screenTabs}
            tabTitles={tabs.map((tab: TCurrencyTab) => (
              <CurrencyPair currencyCode={tab.currencyCode} />
            ))}
            tabIndex={selectedTabIndex}
            handleChange={handleSetSelectedTabIndex}
            iconTitles={false}
          />
          {' '}
          {dropdownItems.length ? dropdown : null}
        </Box>
      )}
    >
      <div style={{
        minHeight: 'calc(100vh - 310px)',
        paddingTop: '12px',
        paddingBottom: '30px',
        backgroundColor: '#fafafa',
      }}
      >
        <Card>
          <Body
            tabs={allTabs}
            selectedTabIndex={selectedTabIndex}
            loading={loading}
          />
          <RequestCurrencyAccountModal
            open={openAccountRequestModal}
            handleClose={() => {
              setOpenAccountRequestModal(false);
              currencyAccountForm.resetForm();
            }}
            currencyAccountForm={currencyAccountForm}
            submittingAccountRequest={submittingAccountRequest}
            accountCurrencyOption={accountCurrencyOption}
            setAccountCurrencyOption={setAccountCurrencyOption}
          />
          <AccountRequestDupeModal
            open={openDupeCheckModal}
            handleClose={() => {
              setOpenDupeCheckModal(false);
              setAccountCurrencyOption({ name: '', code: '' });
              currencyAccountForm.resetForm();
            }}
            handleActionButton={handleDupeActionButton}
          />
        </Card>
      </div>
    </APMainLayout>
  );
};

export default CurrencyAccounts;
