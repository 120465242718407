// Includes basic widget styles for reuse
import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    paddingTop: '20px',
  },
  tradesCommingToValueES: {
    '& button:nth-child(3)': {
      maxWidth: '285px',
    },
  },
  title: {
    fontFamily: typography.fontFamily,
    fontSize: '16px',
    fontWeight: typography.fontWeightSemiBold,
    padding: '10px',
    paddingLeft: '0px',
  },
  panel: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '24px',
    maxWidth: '100%',
    '@media (max-width: 800px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr));',
    },
    paddingBottom: '40px',
    margin: 'auto',
  },
  emptyTable: {
    marginTop: '20px',
    '& .image': {
      height: '120px',
      width: '120px',
      '& img': {
        height: '60',
        width: '60px',
      },
    },
  },
  widget: {
    padding: '20px',
    background: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
    '@media (max-width: 800px)': {
      gridColumn: '1/1 !important',
    },
    overflowY: 'auto',
    height: '394px',
  },
  hedgingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '10px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ctaButton: {
    backgroundColor: '#fafafa',
    height: '40px',
    borderRadius: '5px',
  },
  loading: {
    width: '100%',
    marginTop: '10px',
    gridColumn: '1/4',
    gridAutoColumns: 'auto',
    textAlign: 'center',
    lineHeight: '30px',
  },

  demo: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      padding: '0',
      margin: '0',
      textAlign: 'center',
      verticalAlign: 'middle',
      background: 'rgba(255,255,255, 0.9)',
      zIndex: '2',
      content: '" "',
    },
    '&::after': {
      position: 'absolute',
      top: '49%',
      left: '0',
      bottom: '0',
      right: '0',
      padding: '0px 10px',
      textAlign: 'center',
      margin: 'auto',
      marginTop: '-20px',
      maxWidth: '300px',
      content: '"No data to display as this widget is not applicable."',
      whitespace: 'pre',
      zIndex: '3',
      fontSize: '16px',
      fontWeight: '200',
      color: '#666666',
    },
  },
}), { name: 'Widget' });

export default useStyles;
