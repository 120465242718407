import React, { useEffect } from 'react';

import { Box } from '@alpha/ui-lib/ui/external';

import useLog from 'hooks/useLog';
import useStyles from '../../index.styles';
import t from 'utils/translationHelper';

const liveRateDomain = 'https://liverates.rmportal.alpha.co.uk';
const liveRateChartPath = `${liveRateDomain}/dashboard-widget.html`;

const LiveRatesWidget: React.FC = () => {
  const classes = useStyles();
  const { logEvent } = useLog();


  useEffect(() => {
    function heightChangeListener(e: MessageEvent) {
      if (!e.origin.match(liveRateDomain)) { return; }
      const message = e.data;
      const iframe = document.querySelector('#live-rate-widget-iframe') as HTMLIFrameElement;
      if (iframe) {
        iframe.style.height = `${message.height}px`;
      }
    }
    window.addEventListener('message', heightChangeListener);
    logEvent({ action: 'Dashboard - View Live Rates Widget' });

    return () => window.removeEventListener('message', heightChangeListener);
  }, []);

  return (

    <Box
      className={classes.widget}
      style={{
        gridColumn: 'span 2',
        gridAutoColumns: 'auto',
        paddingTop: '0px',
        paddingBottom: '20px',
        height: 'auto',
        minHeight: '394px',
      }}
    >

      <iframe
        src={liveRateChartPath}
        frameBorder="0"
        width="1600"
        height="500"
        scrolling="no"
        title={t('alpha_market_information')}
        style={{ margin: 'auto', maxWidth: '100%', minWidth: '600px' }}
        allowTransparency
        id="live-rate-widget-iframe"
      />

    </Box>
  );
};

export default LiveRatesWidget;
