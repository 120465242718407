import React, { Dispatch, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import t from 'utils/translationHelper';

import { Currency } from '@alpha/bene-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import {
  FormControlLabel, Radio, RadioGroup,
} from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import { FundingOption, Section } from '..';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../../../hooks/useAuthorization';
import useLog from '../../../../../hooks/useLog';
import { UserRole } from '../../../../../models/user';
import CurrencyAccountsService from '../../../../../services/CurrencyAccounts/currencyAccounts.service';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';
import StatementsService from '../../../../../services/Statements/statements.service';
import { formatCurrency } from '../../../../../utils/currency.helpers';
import { mapCurrencyAccountToData } from '../../../../../utils/mapDropdown';
import Alert from '../../../../Alert';
import AutocompleteDropDown, { IOption } from '../../../../AutocompleteDropDown/AutocompleteDropDown';
import Dropdown from '../../../../InterAccountTransfer/Dropdown/Dropdown';
import DrawerBackdropLoader from '../../../Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import { FormikValueProps } from '../formData';

type Props = {
  handleSetSection: (_section: Section) => void;
  fundingMethod: FundingOption;
  setFundingMethod: Dispatch<React.SetStateAction<FundingOption>>;
  currentSellCurrencyOption: IOption;
  currentPaymentCurrencyOption: IOption;
  allowedSellCurrencies: Currency[];
  allowedPaymentCurrencies: Currency[];
  allowedSellCurrenciesOption: IOption[];
  allowedPaymentCurrenciesOption: IOption[];
  setCurrentSellCurrencyOption: Dispatch<React.SetStateAction<IOption>>;
  setCurrentPaymentCurrencyOption: Dispatch<React.SetStateAction<IOption>>;
  currencyAccountId?: string;
} & FormikValueProps;

// eslint-disable-next-line max-lines-per-function
const Funding: React.FC<Props> = ({
  values,
  setFieldValue,
  handleSetSection,
  currencyAccountId,
  fundingMethod,
  setFundingMethod,
  currentSellCurrencyOption,
  currentPaymentCurrencyOption,
  allowedSellCurrencies,
  allowedPaymentCurrencies,
  allowedSellCurrenciesOption,
  allowedPaymentCurrenciesOption,
  setCurrentSellCurrencyOption,
  setCurrentPaymentCurrencyOption,
  touched,
  errors,
}) => {
  const { logError } = useLog();
  const {
    isLoading, isError, data,
  } = useQuery('getCurrencyAccounts', StatementsService.getStatementAccounts);
  const { authorized: authorizedSpot } = useAuthorization(
    [[UserRole.SPOT, UserRole.SPOT_INPUTTER]],
  );
  const sb = useAlphaSnackbar();


  const accountConfigQuery = useQuery('getAccountConfig', () => FXTradeService.getAccountConfigurationWithCcys(
    values?.sellCurrencyCode || '',
    values?.paymentCurrencyCode || '',
  ), {
    enabled: false,
    onError: (e) => {
      sb.trigger(t('cannot_load_spot_information'), 'error');
      logError({ action: 'Error loading Spot information', error: e });
    },
  });

  const [sellCurrencyTextFieldValue, setSellCurrencyTextFieldValue] = useState<string>('');
  const [paymentCurrencyTextFieldValue, setPaymentCurrencyTextFieldValue] = useState<string>('');

  const getAndSetDefaultPaymentCurrencyAccount = async (
    paymentCurrencyCode: string,
  ): Promise<void> => {
    setFieldValue('paymentCurrencyAccount', undefined);
    const defaultPaymentCurrencyAccount = await getDefaultCurrencyAccount(
      paymentCurrencyCode,
    );

    if (!defaultPaymentCurrencyAccount) {
      sb.trigger(`${t('you_do_not_have_a_default_currency_account_for')} ${values.paymentCurrencyCode}`);
      logError({ action: 'No default currency account', detail: { currencyCode: values.paymentCurrencyCode } });
      return;
    }

    setFieldValue('paymentCurrencyAccount', defaultPaymentCurrencyAccount);
  };

  const getAndSetDefaultSellCurrencyAccount = async (
    sellCurrencyCode: string,
  ): Promise<void> => {
    setFieldValue('sellCurrencyAccount', undefined);
    const defaultSellCurrencyAccount = await getDefaultCurrencyAccount(
      sellCurrencyCode,
    );

    if (!defaultSellCurrencyAccount) {
      sb.trigger(`${t('you_do_not_have_a_default_currency_account_for')} ${values.sellCurrencyCode}`);
      logError({ action: 'No default currency account' });
      return;
    }

    setFieldValue('sellCurrencyAccount', defaultSellCurrencyAccount);
  };

  useEffect(() => {
    if (currencyAccountId && data) {
      const currencyAccount = data?.currencyAccounts.find((ca) => ca.id === currencyAccountId);
      setFieldValue('paymentCurrencyAccount', currencyAccount);
      if (currencyAccount) { setFieldValue('paymentCurrencyCode', currencyAccount?.currencyCode); }
    }
  }, [currencyAccountId, data]);

  useEffect(() => {
    (async () => {
      if (values.sellCurrencyCode
        && values.paymentCurrencyCode
        && fundingMethod === 'Spot'
        && accountConfigQuery) {
        accountConfigQuery.refetch();
      }

      if (values.paymentCurrencyCode && fundingMethod === 'Spot') {
        getAndSetDefaultPaymentCurrencyAccount(values.paymentCurrencyCode);
      }

      if (values.sellCurrencyCode && fundingMethod === 'Spot') {
        getAndSetDefaultSellCurrencyAccount(values.sellCurrencyCode);
      }
    })();
  }, [values.sellCurrencyCode, values.paymentCurrencyCode, fundingMethod]);

  useEffect(() => {
    // If non-spot trade: sellcurrency should be the same as payment currency
    if (values.paymentCurrencyAccount && fundingMethod === 'Account') {
      setFieldValue('sellCurrencyAccount', values.paymentCurrencyAccount);
    }
  }, [fundingMethod, values.paymentCurrencyAccount]);

  if (isLoading) return <DrawerBackdropLoader display text="Retrieving your currency accounts" />;
  if (isError || (!isLoading && !data)) {
    sb.trigger(t('there_was_an_error_retrieving_your_currency_accounts'));
    logError({ action: 'Error getting currency accounts' });
    return null;
  }

  const handleSetSelectedDebitingAccount = (e: any) => {
    setFieldValue('paymentCurrencyAccount', data?.currencyAccounts.find((ca) => ca.id === e.target.value));
  };

  const getDefaultCurrencyAccount = async (currencyCode: string) => {
    const currencyAccounts = await CurrencyAccountsService.getCurrencyStats(
      currencyCode,
    );

    const defaultCA = currencyAccounts.currencyAccounts?.find(
      (ca) => ca.default,
    );

    return defaultCA;
  };

  const generateFundingSelectOptions = () => {
    const fundingOptions = [
      <FormControlLabel value="Account" control={<Radio color="primary" />} label={t('money_on_account')} data-testid="funding-money-on-account" />,
    ];
    if (authorizedSpot) {
      fundingOptions.push(
        <FormControlLabel value="Spot" control={<Radio color="primary" />} label={t('new_spot_trade')} data-testid="funding-by-spot" />,
      );
    } else {
      fundingOptions.push(
        <APTooltip title="You do not have permission to fund a payment this way">
          <div>
            <FormControlLabel value="Spot" control={<Radio color="primary" disabled />} label={t('new_spot_trade')} data-testid="funding-by-spot" />
          </div>
        </APTooltip>,
      );
    }
    return fundingOptions;
  };

  const customFundingValidation = () => !((fundingMethod === 'Account' && Boolean(values.paymentCurrencyAccount?.id))
    || (fundingMethod === 'Spot' && values.sellCurrencyCode && values.paymentCurrencyCode && Boolean(values.paymentCurrencyAccount?.id) && Boolean(values.sellCurrencyAccount?.id)));

  const getSpotLimit = () => {
    if (values.paymentCurrencyCode
      && accountConfigQuery.data?.minimumFX?.buyAmount
      && accountConfigQuery.data?.maximumFX?.buyAmount) {
      const minLimitStr = formatCurrency(
        values.paymentCurrencyCode,
        accountConfigQuery.data?.minimumFX?.buyAmount,
      );
      const maxLimitStr = formatCurrency(
        values.paymentCurrencyCode,
        accountConfigQuery.data?.maximumFX.buyAmount,
      );
      return `${values.paymentCurrencyCode} ${minLimitStr} - ${maxLimitStr}`;
    }

    return 'Loading...';
  };
  return (
    <>
      <div>
        <Typography variant="subtitle1" className="label" style={{ marginBottom: '8px' }}>
          {t('how_do_you_want_to_fund_this_payment')}
        </Typography>

        <RadioGroup
          value={fundingMethod}
          onChange={(e) => setFundingMethod(e.target.value as FundingOption)}
          data-testid="funding-select"
        >
          {[generateFundingSelectOptions()]}
        </RadioGroup>

        {fundingMethod === 'Account' && (
          <>
            <Typography variant="subtitle1" style={{ marginTop: '20px' }}>{t('please_select_which_account_to_send_payment_from')}</Typography>
            <div style={{ marginTop: '16px' }}>
              <Typography variant="subtitle1" className="label" style={{ marginBottom: '8px' }}>
                {t('currency_account')}
              </Typography>
              <Dropdown
                data={mapCurrencyAccountToData(data!.currencyAccounts, false)}
                handleChange={(e) => handleSetSelectedDebitingAccount(e)}
                helperText=""
                placeholderText={t('please_select_a_currency_account')}
                data-testid="debiting-account"
                selectName="debiting-account"
                selectValue={values.paymentCurrencyAccount?.id || 'Debit Account'}
                hideBalance
              />
            </div>
          </>
        )}

        {fundingMethod === 'Spot' && (
          <>
            <div style={{ marginTop: '16px' }}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>{t('sell_currency')}</Typography>
              <AutocompleteDropDown
                name="sellCurrencyCode"
                testId="sellCurrencyDropdown"
                type="CODE-NAME"
                options={currentPaymentCurrencyOption ? allowedSellCurrenciesOption.filter(
                  (item) => item.code !== currentPaymentCurrencyOption?.code,
                ) : allowedSellCurrenciesOption}
                currentOption={currentSellCurrencyOption}
                setFieldValue={setFieldValue}
                value={values.sellCurrencyCode}
                touched={touched.sellCurrencyCode}
                setTextFieldValue={setSellCurrencyTextFieldValue}
                textFieldValue={sellCurrencyTextFieldValue}
                setCurrentOption={setCurrentSellCurrencyOption}
                placeholder={t('select_sell_currency')}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>{t('payment_currency')}</Typography>
              <AutocompleteDropDown
                name="paymentCurrencyCode"
                testId="paymentCurrencyDropdown"
                type="CODE-NAME"
                options={currentSellCurrencyOption ? allowedPaymentCurrenciesOption.filter(
                  (item) => item.code !== currentSellCurrencyOption?.code,
                ) : allowedPaymentCurrenciesOption}
                currentOption={currentPaymentCurrencyOption}
                value={values.paymentCurrencyCode}
                setFieldValue={setFieldValue}
                touched={touched.paymentCurrencyCode}
                setTextFieldValue={setPaymentCurrencyTextFieldValue}
                textFieldValue={paymentCurrencyTextFieldValue}
                setCurrentOption={setCurrentPaymentCurrencyOption}
                placeholder={t('select_payment_currency')}
              />
            </div>
          </>
        )}

        {fundingMethod === 'Spot' && values.paymentCurrencyCode && values.sellCurrencyCode && (
          <div style={{ marginTop: '20px' }}>
            <Alert
              text={`${t('limit_for_spot_trade')} ${getSpotLimit()}`}
              variant="info"
            />
          </div>
        )}
      </div>
      <div style={{
        display: 'flex', flexDirection: 'row', padding: '92px 0 42px 0', justifyContent: 'space-between',
      }}
      >
        <div style={{
          position: 'absolute', transform: 'translateY(-42px)', width: '100%', left: 0,
        }}
        >
          <BaseDrawer.LineBreak />
        </div>
        <ActionButton style={{ background: '#F7F7F7', color: '#212529' }} disabled>{t('prev')}</ActionButton>
        <ActionButton disabled={customFundingValidation()} onClick={() => handleSetSection('Beneficiaries')}>
          {t('next_IT_')}
        </ActionButton>
      </div>
    </>
  );
};

export default Funding;
