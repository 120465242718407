import cls from 'classnames';
import React, {
  FC, useEffect, useState,
} from 'react';
import {
  useLocation,
} from 'react-router-dom';

import { SidebarContextProvider } from '@alpha/ui-lib/ui/layout/PersistentSidebar/';

import useSwitchAccount from '../../../hooks/useSwitchAccount';
import { Footer } from '../Footer';
import Header from '../Header/Header';
import { Sidebar } from '../Sidebar';

import { IPageProps } from './Page.interfaces';
import useStyles from './Page.styles';

export const Page: FC<IPageProps> = ({ children }: IPageProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [userGuideTheme, setUserGuideTheme] = useState<boolean>(false);
  const [viewablePages, setViewablePages] = useState<string[]>([]);

  useEffect(() => {
    setUserGuideTheme(location.pathname === '/app/user-guides');
  }, [location.pathname]);

  const {
    currentPagesConfiguration,
    getPortalPagesConfiguration,
    getAccountConfigIsMoneyDisabled
  } = useSwitchAccount();

  const onSidebarLoaded = (): void => {
    (async () => {
      try {
        await getPortalPagesConfiguration();
        setViewablePages(currentPagesConfiguration()?.viewablePages as string[]);
        await getAccountConfigIsMoneyDisabled();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const isDashboard = location.pathname.includes('dashboard');
  const newStyle = isDashboard ? classes.newMainDashboard : classes.newMain;

  useEffect(() => {
    onSidebarLoaded();
  }, []);

  return (
    <SidebarContextProvider onOpen={onSidebarLoaded}>
      <Header />
      <Sidebar
        viewableTabs={viewablePages}
      />

      <main className={cls(
        newStyle,
        classes.newSideBar,
        false ? classes.expired : '',
        userGuideTheme ? classes.userGuideTheme : '',
      )}
      >
        {children}
      </main>
      {!userGuideTheme && <Footer />}
    </SidebarContextProvider>
  );
};

export default Page;
