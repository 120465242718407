import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import t from 'utils/translationHelper';

import { AccountAuditDto, AccountDto } from '@alpha/auth-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import {
  Box, Collapse,
  IconButton, Typography,
} from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import APPaginationButton from '@alpha/ui-lib/ui/Pagination/APPaginationButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faMessageDots } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table from '../../../../components/Table/Table';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import useReportsPolling, { FileType, PageType } from '../../../../hooks/useReportsPolling';
import { AuditAmendments, TAuditAccount } from '../../../../models/auth';
import AccountSettingsService from '../../../../services/AccountSettings/accountSettings.service';

import useStyles from './index.styles';

interface IProps {
  selectedAccount: AccountDto;
}

const AccountAudits: React.FC<IProps> = ({ selectedAccount }: IProps) => {

  const classes = useStyles();
  const { logError } = useLog();
  const sb = useAlphaSnackbar();
  const [audits, setAudits] = useState<AccountAuditDto[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [rowCount, setRowCount] = useState(10);
  const [loading, setLoading] = useState(true);
  const [limitedAudits, setLimitedAudits] = useState<TAuditAccount[]>([]);
  const [open, setOpen] = useState<null | HTMLElement>(null);

  const getDataWithLimit = useCallback((records: AccountAuditDto[], limit: number) => {
    const mappedData = records?.map(
      (record) => {
        const type = record.actionType;
        const details = record.details ? `(${record.details})` : '';
        return ({
          actionType: `${t(AuditAmendments[type])} ${details}`,
          logDate: DateTime.fromISO(record.logDate!)?.toFormat('dd/MM/yy')?.toLocaleString() || '-',
          fullName: record.fullName,
        });
      },
    );
    const maxRecords = records.length < limit ? records.length : limit;
    return mappedData.splice(0, maxRecords);
  }, []);

  const loadAccountAudits = async (accountId: string) => {
    try {
      const auditRecords = await AccountSettingsService.getAccountAudits(accountId);
      setAudits(auditRecords);
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_account_amendments_audit_records'));
      logError({ action: 'Error loading Account Amendments Audit records', error });
    } finally {
      setLoading(false);
    }
  };

  const accountColumn = [{
    header: t('request_submitted'),
    accessor: 'actionType',
  },
  {
    header: t('date'),
    accessor: 'logDate',
  },
  {
    header: t('submitted_by'),
    accessor: 'fullName',
  }];

  useEffect(() => {
    if (selectedAccount) {
      loadAccountAudits(selectedAccount.id);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (audits?.length) {
      const data = getDataWithLimit(audits, rowCount);
      setLimitedAudits(data);
    }
  }, [rowCount, audits]);

  const menuItems = [
    {
      content: t('export_pdf'),
      icon: faFilePdf,
      onClick: () => {
        handleReportGeneration(FileType.PDF);
      },
      underline: true,
    },
  ];

  const { executeReportGeneration } = useReportsPolling(PageType.AuditReport);
  const handleReportGeneration = async (fileType: FileType) => {
    const { id } = selectedAccount;
    await executeReportGeneration(
      fileType,
      id,
      undefined,
      undefined,
      undefined,
      fileType,
    );
  };

  return (
    <Box flexDirection="rows" display="flex" gridGap="10px">
      <Box>
        <IconButton
          onClick={() => {
            setExpanded(!expanded);
            if (!expanded) setRowCount(10);
          }}
          style={{ marginTop: '16px', borderRadius: 0 }}
          size="small"
        >
          <FontAwesomeIcon
            className={expanded ? classes.iconDown : classes.iconRight}
            icon={faChevronDown as IconProp}
            size="sm"
          />
        </IconButton>
      </Box>
      <Box display="flex" gridGap="10px" flexDirection="column" flexGrow={1}>
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1}>
            <Typography variant="h6" className={classes.boxHeaderText}>
              <FontAwesomeIcon className="icon" icon={faMessageDots as IconProp} color="#1E8777" />
              <span>{t('account_amendments_audit_log')}</span>
            </Typography>
            <Typography variant="subtitle1" className={classes.subTitleText}>
              {t('audit_log_of_all_account_requests_and_changes_made')}
            </Typography>
          </Box>
          <Collapse in={expanded}>
            <Box>
              <div className={classes.menuContainer}>
                <APMenu
                  open={Boolean(open)}
                  setOpen={setOpen}
                  anchorEl={open}
                  buttonTitle={t('export')}
                >
                  {
                    menuItems.map((item) => (
                      <MenuDropdownItem setOpen={setOpen}>
                        {item}
                      </MenuDropdownItem>
                    ))
                  }
                </APMenu>
              </div>
            </Box>
          </Collapse>
        </Box>
        <Collapse in={expanded} className={limitedAudits.length > 0 && expanded ? classes.table : ''}>
          {loading && <Loader testId="loader-account-audits" />}
          {!loading && limitedAudits.length > 0 && (
            <Table
              columns={accountColumn}
              data={limitedAudits}
              skip={0}
            />
          )}
          {!loading && (
            <div className={classes.listingContainer}>
              {audits.length > 0 ? (
                <span>
                  {t('showing')}
                  {' '}
                  {rowCount < audits.length ? rowCount : audits.length}
                  {' '}
                  {t('of')}
                  {' '}
                  {audits.length}
                </span>
              ) : <span>{t('no_audit_records')}</span>}
              {audits.length ? <APPaginationButton label={t('next_10')} onClick={() => setRowCount(rowCount + 10)} disabled={rowCount > audits.length} /> : null}
            </div>
          )}
        </Collapse>
      </Box>
    </Box>
  );
};
export default AccountAudits;
