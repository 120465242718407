import React, { useState } from 'react';

import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faFileExcel, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import t from 'utils/translationHelper';

import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../../hooks/useReportsPolling';

interface IExportMenuProps {
  downloadType: DownloadType;
  tradeId: string;
  disabled?: boolean;
}
const ExportMenu: React.FC<IExportMenuProps> = (props: IExportMenuProps) => {

  const [open, setOpen] = useState<null | HTMLElement>(null);
  const {
    downloadType, tradeId, disabled,
  } = props;
  const { executeReportGeneration } = useReportsPolling(PageType.FX);
  const handleReportGeneration = async (fileType: FileType) => {
    await executeReportGeneration(
      fileType,
      tradeId,
      undefined,
      undefined,
      downloadType,
      fileType,
    );
  };
  const menuItems = [
    {
      content: t('export_pdf'),
      icon: faFilePdf,
      onClick: () => handleReportGeneration(FileType.PDF),
      underline: true,
    },
    {
      content: t('export_excel'),
      icon: faFileExcel,
      onClick: () => handleReportGeneration(FileType.EXCEL),
    },
  ];
  return (
    <APMenu
      open={Boolean(open)}
      setOpen={setOpen}
      anchorEl={open}
      buttonProps={{ disabled }}
      buttonTitle={t('export_data')}
    >
      {
        menuItems.map((item) => <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>)
      }
    </APMenu>
  );
};

export default ExportMenu;
