import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { UpdateUsersRolesRequest, User } from '@alpha/profile-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import {
  Box, Collapse,
  IconButton, Typography,
} from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TeamPermissionsMatrixTable from '../../../../components/Molecules/AccountSettings/TeamPermissionsMatrixTable/TeamPermissionsMatrixTable';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import AccountSettingsService from '../../../../services/AccountSettings/accountSettings.service';
import useStyles from '../TeamSettings.styles';

interface IProps {
  selectedAccount?: AccountDto;
  canEditUserPermission?: boolean;
}

const TeamPortalPermissions: React.FC<IProps> = (
  { selectedAccount, canEditUserPermission = false }: IProps,
) => {
  const classes = useStyles();

  const sb = useAlphaSnackbar();
  const { logEvent, logError } = useLog();
  const [accountUsers, setAccountUsers] = useState<User[]>([]);
  const [originalSettings, setOriginalSettings] = useState<User[]>([]);
  const [formTouched, setFormTouched] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [padAllowed, setPadAllowed] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const loadTeamPermissionSettings = async (accountId: string) => {
    try {
      const userPermissions = await AccountSettingsService.getTeamPermissionSettings(accountId);

      setAccountUsers(userPermissions);
      setOriginalSettings(JSON.parse(JSON.stringify(userPermissions)));
    } catch (error) {
      console.error(error);
      logError({ action: 'Error loading team permission settings', error });
    }
  };

  const saveTeamAccountSettings = async () => {
    if (selectedAccount) {
      try {
        setSubmitting(true);
        const request: UpdateUsersRolesRequest = {
          accountId: selectedAccount.id,
          users: accountUsers.map((u) => (
            {
              id: u.id,
              roles: u.roles,
            })) as UpdateUsersRolesRequest['users'],
        };
        await AccountSettingsService.postUserRoles(request);
        sb.trigger(t('saved'), 'success');
        logEvent({ action: 'Successfully saved team permission settings' });
        loadTeamPermissionSettings(selectedAccount.id);
      } catch (error) {
        console.error(error);
        sb.trigger(t('could_not_submit_user_roles'));
        logError({ action: 'Error submitting team permission settings', error });
      } finally {
        setSubmitting(false);
      }
    }
  };

  const loadAccountConfigurations = async (accountId: string) => {
    try {
      const accountConfig = await AccountSettingsService.getAccountConfigurationForAccount(
        accountId,
      );
      setPadAllowed(accountConfig.padAllowed);
    } catch (error) {
      console.error(error);
      logError({ action: 'Error loading account configurations for account', error });
    }
  };

  useEffect(() => {
    if (selectedAccount && canEditUserPermission) {
      loadTeamPermissionSettings(selectedAccount.id);
      loadAccountConfigurations(selectedAccount.id);
    }
  }, [selectedAccount, canEditUserPermission]);

  useEffect(() => {
    function jsonEqual(a: any, b: any): boolean {
      return JSON.stringify(a) === JSON.stringify(b);
    }
    const isOriginal = jsonEqual(accountUsers.map((a) => a.roles.sort()),
      originalSettings.map((a) => a.roles.sort()));
    setFormTouched(!isOriginal);
  }, [accountUsers, originalSettings]);

  if (canEditUserPermission) {
    return (
      <Box flexDirection="rows" display="flex" gridGap="10px">
        <Box>
          <IconButton
            onClick={() => { setExpanded(!expanded); }}
            style={{ marginTop: '16px', borderRadius: 0 }}
            size="small"
          >
            <FontAwesomeIcon
              className={expanded ? classes.iconDown : classes.iconRight}
              icon={faChevronDown as IconProp}
              size="sm"
            />
          </IconButton>
        </Box>
        <Box flexGrow={1}>
          <Box
            display="flex"
            className={classes.stickySection}
          >
            <Box flexGrow={1}>
              <Typography variant="h6" className={classes.boxHeaderText}>
                <FontAwesomeIcon className="icon" icon={faList as IconProp} color="#1E8777" size="xs" />
                <span>{t('portal_permissions')}</span>
              </Typography>
              <Typography variant="subtitle1" className={classes.subTitleText}>
                {t('here_you_can_manage_your_teams_access_rights')}
              </Typography>
            </Box>
            <Collapse in={expanded}>
              <Box>
                <ActionButton
                  disabled={!formTouched || submitting}
                  loading={submitting}
                  onClick={() => { saveTeamAccountSettings(); }}
                  style={{ borderRadius: '4px' }}
                >
                  {t('save')}
                </ActionButton>
              </Box>
            </Collapse>
          </Box>
          <Collapse in={expanded}>
            <div style={{ paddingTop: '20px' }}>
              <TeamPermissionsMatrixTable
                selectedAccount={selectedAccount}
                accountUsers={accountUsers}
                setAccountUsers={setAccountUsers}
                readonly={submitting}
                padAllowed={padAllowed}
              />
            </div>
          </Collapse>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {t('you_do_not_have_access_to_edit_role_permissions')}
    </>
  );
};

export default TeamPortalPermissions;
