import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    padding: {
      padding: '50px 60px',
    },
    header: {
      fontWeight: 'normal',
      fontSize: 26,
      margin: '24px 0 24px 0',
    },
    mfaInputFields: {
      display: 'inline-block',
      width: '100%',
      margin: '32px 0 24px 0',
      '& > div': {
        position: 'relative',
        display: 'block',
        width: '100% !important',
        textAlign: 'center',
      },
    },
    authContainer: {
      margin: '0 auto',
      '& input[type=tel]': {
        caretColor: '#fff !important',
        borderRadius: '4px !important',
        border: 'none',
        color: '#fff',
        boxShadow: '0 0 4px rgb(0,0,0)',
        backgroundColor: '#272727',
        fontFamily: 'inherit',
        fontSize: 14,
        marginRight: '10px',
      },
    },
    info: {
      lineHeight: 2,
      fontSize: '16px',
      margin: 0,
      whiteSpace: 'pre-line',
    },
    cta: {
      fontSize: '16px',
      display: 'inline-block',
      color: '#00A09B',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
  { name: 'Authy' },
);

export default useStyles;
