import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography, palette }) => ({
  root: {
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    marginBottom: '20px',
    '@media (max-width: 800px)': {
      flexWrap: 'wrap',
      justifyContent: 'start-end',
      gap: '10px',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    gap: '10px',
    '@media (max-width: 800px)': {
      justifyContent: 'start-end',
    },
  },
  title: {
    alignSelf: 'start',
    color: '#1E8777',
    fontSize: '40px',
    fontWeight: typography.fontWeightLight,
  },
  newTitle: {
    alignSelf: 'start',
    color: palette.text.primary,
    fontSize: '40px',
    fontWeight: typography.fontWeightSemiBold,
  },
  actionButton: {
    height: '90px',
    width: '140px',
    background: '#09837522',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000008',
    padding: '16px',
    color: '#098375',
    display: 'block',
    textTransform: 'none',
    fontWeight: typography.fontWeightSemiBold,
    '&.MuiButton-root:hover': {
      background: '#1E8777',
      color: 'white',
    },
    '@media (max-width: 800px)': {
      padding: '10px',
    },

  },
  paymentUploadActionButtonWrapper: {
    '&> div': { height: '100%' },
    '& button': {
      textTransform: 'none',
      width: '140px',
      height: '90px',
      background: '#09837522',
      borderRadius: '10px',
      boxShadow: '0px 3px 6px #00000008',
      padding: '16px',
      color: '#098375',
      display: 'block',
      fontWeight: typography.fontWeightSemiBold,
      '&.MuiButton-root:hover': {
        background: '#1E8777',
        color: 'white',
      },
      '&.MuiButton-root:hover svg': {
        color: 'white',
      },
      '@media (max-width: 800px)': {
        padding: '10px',
      },
    },
  },

}), { name: 'QuickActions' });

export default useStyles;
