/* eslint-disable import/prefer-default-export */
import { BeneficiaryDto } from '@alpha/bene-dtos';
import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';

export const shouldShowApprovalButtons = (
  canApproveOthers: boolean,
  canApproveOwn: boolean,
  records: PaymentDto [] | BeneficiaryDto [],
  userId: string,
  approveButton: boolean,
): boolean => {
  let containsOwn = false;
  let containsOthers = false;

  for (let i = 0; i <= records.length - 1; i += 1) {
    const alreadyApprovedByUser = approveButton
      ? records[i].approvalInfo?.approvals?.some((a) => a.userId === userId)
      : false;
    if (records[i].status !== PaymentStatus.SUBMITTED || alreadyApprovedByUser) { return false; }
    if (records[i].submittedById === userId) {
      containsOwn = true;
    } else {
      containsOthers = true;
    }
  }

  if (containsOwn && !canApproveOwn) { return false; } // has own item, but cannot approve-> false
  if (containsOthers && !canApproveOthers) { return false; }

  return true;
};
