import { useState } from 'react';
import moment, { Moment as MomentType } from 'moment';
import { useParams } from 'react-router-dom';

import { LedgerDto } from '@alpha/currency-accounts-dtos';

import useAlphaSnackbar from '../../hooks/useAlphaSnackbar';
import { TStatementData } from '../../models/statements';
import history from '../../services/history/browserHistory';
import StatementsService from '../../services/Statements/statements.service';

const useStatements = () => {
  const { currencyAccountId }: { currencyAccountId: string } = useParams();
  const [loading, setLoading] = useState(false);
  const [statement, setStatement] = useState<TStatementData>();

  const [selectedDates, setSelectedDates] = useState<{
    startDate: MomentType;
    endDate: MomentType;
  }>({
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
  });

  const snackbar = useAlphaSnackbar();

  const handleSetStatement = async (clickedTableRowStatement: LedgerDto): Promise<void> => {
    try {
      setLoading(true);
      setStatement({
        paymentId: clickedTableRowStatement.friendlyId,
        valueDate: clickedTableRowStatement.date,
        type: clickedTableRowStatement.creditAmount === 0 ? 'debit' : 'credit',
        creditAmount: clickedTableRowStatement.creditAmount,
        debitAmount: clickedTableRowStatement.debitAmount,
        reference: clickedTableRowStatement.reference,
        debitCurrencyCode: clickedTableRowStatement.currencyCode,
        creditCurrencyCode: clickedTableRowStatement.currencyCode,
        creditFriendlyName: clickedTableRowStatement.details,
        debitFriendlyName: clickedTableRowStatement.details,
      });
    } catch (e) {
      snackbar.trigger(e?.message || 'There was an error retrieving your statement data');
      setStatement(undefined);
    } finally {
      setLoading(false);
    }
  };

  const handlePageLoad = async (): Promise<void> => {
    if (currencyAccountId) { return; }

    try {
      const { currencyAccounts } = await StatementsService.getStatementAccounts();
      if (currencyAccounts.length > 0) {
        history.push(`/app/statements/${currencyAccounts[0].id}`);
      } else {
        history.push('/app/statements');
        snackbar.trigger('There was an error retrieving your statement data');
      }
    } catch (e) {
      snackbar.trigger(e.message || e.response?.data?.error || 'Error retrieving currency accounts');
    }
  };

  return {
    loading,
    statement,
    selectedDates,
    currencyAccountId,
    setSelectedDates,
    setStatement,
    handlePageLoad,
    handleSetStatement,
  };
};
export default useStatements;
