/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useDownloadFile from 'hooks/useDownloadFile';
import useLog from 'hooks/useLog';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { OptionTradeDto } from 'models/optionTrades';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes.path';
import browserHistory from 'services/history/browserHistory';
import OptionTradeService from 'services/OptionTrades/optionTrades.service';
import { TStore } from 'store';
import { actions as optionTradeActions } from 'store/optionTrades/reducer';
import mapOptionTradeStatusDisplay from 'utils/mapOptionTradeStatusToDisplay';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { Status } from '@alpha/ui-lib/ui/Status';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StripsTable from './Strips/StripsTable';
import useStyles from './index.styles';
import TargetLogTable from './TargetLogTable';
import TradeSummary from './TradeSummary';

const Trade: React.FC = () => {
  const styles = useStyles();
  const { homePageUrl } = useSwitchAccount();
  const dispatch = useDispatch();
  const { logError } = useLog();
  const { downloadFile } = useDownloadFile();
  const sb = useAlphaSnackbar();

  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];

  useEffect(() => {
    const tradeId = browserHistory.location.pathname.split('/')?.slice(-1);
    dispatch(optionTradeActions.loadTrade({ tradeId }));
  }, []);

  const optionTrade = useSelector<TStore, OptionTradeDto>(
    (state: TStore) => state.optionTrade.trade,
  );
  const tradeLoading = useSelector<TStore, boolean>(
    (state: TStore) => state.optionTrade.tradeLoading,
  );

  const downloadTermSheet = async (tradeId: string) => {
    try {
      const url = await OptionTradeService.getTermSheetDownloadUrl(tradeId);
      await downloadFile(url, `${t('term_sheet')}`);
    } catch (error) {
      sb.trigger(t('error_downloading_term_sheet_file'));
      logError({ action: 'Error getting term sheet url ', error });
    }
  };

  const styledTitle = (
    <p className={styles.title}>
      <ActionButton
        startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
        testId="trade-back-btn"
        size="large"
        onClick={() => browserHistory.push(routes.transactions.optionTrades)}
        style={{
          minWidth: '33px', minHeight: '33px', height: '33px', marginTop: '0px', background: '#fdfdfd', color: '#212529',
        }}
      />
      {optionTrade && !tradeLoading && (
        <p className={styles.header}>
          {optionTrade?.originalTradeTicketId ?? optionTrade?.name}
          <span style={{ color: '#E6E6E6', margin: '0px 10px 0px 10px' }}> |</span>
          <span style={{ marginBottom: '10px', marginTop: '5px' }}>
            <Status
              className={styles.status}
              variant={mapOptionTradeStatusDisplay(optionTrade?.status).variant}
            >
              {t(mapOptionTradeStatusDisplay(optionTrade?.status).text) || '-'}
            </Status>
          </span>
        </p>
      )}
    </p>
  );

  const rightAlignedCta = () => (
    <Box display="flex" gridGap={10}>
      <>
        <ActionButton
          size="medium"
          startIcon={<FontAwesomeIcon icon={faDownload} />}
          style={{ background: '#F7F7F7', color: '#212529' }}
          onClick={() => {
            downloadTermSheet(
              optionTrade.id,
            );
          }}
        >
          {t('download_term_sheet')}
        </ActionButton>
      </>
    </Box>
  );
  return (
    <APMainLayout
      data-id="option-trade"
      title={styledTitle}
      breadCrumbs={breadcrumbs}
      height="167px"
      rightAlignedCta={rightAlignedCta()}
      sticky
    >
      <Box className={styles.root}>
        {tradeLoading ? (
          <div style={{ width: '100%' }}>
            <Loader testId="loading-option-trade" />
          </div>
        ) : (
          <Box className={styles.panel}>
            <TradeSummary summary={optionTrade} />
            <StripsTable stripsData={optionTrade?.optionsStrips ?? []} layout={optionTrade.layouts} />
            {optionTrade?.type === 'TARF' ? <TargetLogTable logs={optionTrade?.targetLog ?? []} /> : null}
          </Box>
        )}
      </Box>
    </APMainLayout>
  );
};

export default Trade;
