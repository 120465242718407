import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  titleContainer: {
    fontWeight: 600,
    fontSize: '16px'
  },
  titleText: {
    display: 'inline-block',
  },
  iconContainer: {
    display: 'inline-block',
    marginRight: '8px'
  },
  subtitle: {
    fontWeight: 300,
    color: '#999999',
    fontSize: '12px'
  },
  select: {
    backgroundColor: '#F7F7F7',
    borderRadius: '10px',
    height: '44px',
    marginTop: '4px',
    '& .MuiSelect-root': {
      paddingLeft: '16px',
      '&:focus': {
        backgroundColor: '#F7F7F7'
      }
    }
  },
  labelledInput: {
    display: 'inline-block',
    '& .smallDate': {
      width: '180px',
      '& .MuiFormControl-root': {
        width: '180px'
      }
    },
    '& .largeDate': {
      width: '376px',
      '& .MuiFormControl-root': {
        width: '376px'
      }
    }
  },
  inputRow: {
    marginTop: '16px',
    width: '376px'
  },
  longSelect: {
    width: '240px'
  },
  shortSelect: {
    width: '120px'
  },
  selectEntitesContainer: {
    display: 'inline-block',
    float: 'right',
    width: '343px'
  },
  expandWidgetBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  widget: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
    opacity: 1,
    borderRadius: '10px',
    transition: 'all 0.6s linear',
    overflow: 'hidden',
    padding: '24px',
    height: '280px'
  },
  collapsedWidget: {
    width: '424px'
  },
  expandedWidget: {
    width: '816px'
  },
  entityName: {
    display: 'inline-block',
    fontWeight: 400
  },
  checkbox: {
    display: 'inline-block',
    float: 'right',
    marginTop: '-10px'
  },
  entityNameRow: {
    height: '24px',
    marginTop: '8px'
  },
  entitesContainer: {
    marginTop: '8px',
    height: '135px',
    overflowY: 'scroll',
    maxHeight: '200px',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#D9D9D933',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D9D9D9',
      borderRadius: '10px',
    },
  },
  divider: {
    width: '1px',
    borderLeft: '1px solid #EAEAEA',
    margin: '0px 24px'
  }
}), { name: 'ExportManagerWidget' });

export default useStyles;
