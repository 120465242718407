import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    padding: '0 8px !important',
  },
  modalContent: {
    textAlign: 'center',
    padding: '8px',
    width: '400px',
  },
  modalBoldText: {
    fontWeight: typography.fontWeightBold,
    marginBottom: '16px',
    fontSize: '16px',
  },
  modalImage: {
    width: '70px',
    marginBottom: '24px',
  },
  confirmList: {
    listStyleType: 'none',
    lineHeight: '20px',
    marginRight: '30px',
    marginBottom: '20px',
    maxHeight: '200px',
    overflowY: 'auto',
  },
}), { name: 'RejectPaymentModal' });

export default useStyles;
