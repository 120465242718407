import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    confirmationDrawerContainer: {
      '& h2': {
        fontSize: '22px',
      },
    },
    drawerBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      fontWeight: 900,
      minHeight: 'calc(100vh - 310px)',
    },
    chip: {
      '& fieldset': {
        border: 'none',
      },
      '& .MuiOutlinedInput-root': {
        border: 'none',
        backgroundColor: '#f5f5f5',
        padding: '10px 30px 5px 15px',
        borderRadius: '4px',
        marginTop: '6px',
      },
      '& .MuiFormHelperText-root': {
        position: 'relative',
        left: '-13px',
        top: '3px',
      },
    },
    IATDetailsContainer: {
      padding: '20px',
      background: '#f7f7f7',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
  { name: 'IATConfirmation' },
);

export default useStyles;
