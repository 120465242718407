import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  es: {
    width: '630px',
  },
  de: {
    width: '770px',
  },
  it: {
    width: '600px',
  },
  en: {
    width: '600px',
  },
  fr: {
    width: '690px',
  },
}), { name: 'MarketOrders' });

export default useStyles;
