import t from 'utils/translationHelper';
import * as yup from 'yup';

import { PaymentOrigin } from '@alpha/payments-dtos';

import { FormikPaymentType } from '../../../../Payments/CreatePaymentDrawer/formData';

export const initialValues: FormikPaymentType = {
  emailAddresses: [],
  paymentOrigin: PaymentOrigin.DRAWDOWN,
  date: null,
};

export const validation = {
  beneficiary: yup
    .object({
      id: yup.string().required(),
    })
    .required(t('please_enter_a_beneficiary_amount')),
  amount: yup.number().typeError(t('please_enter_numbers_only'))
    .required(t('please_select_an_amount'))
    .positive(t('please_enter_an_amount_greater_than_zero')),
  date: yup.string().required(t('please_specify_a_payment_date')),
  reference: yup.string().nullable().test('len', t('must_be_255_characters_or_less'), (val) => {
    if (!val) return true;
    return val?.length <= 255;
  }),
  purpose: yup.string().test('len', t('must_be_255_characters_or_less'), (val) => {
    if (!val) return true;
    return val?.length <= 255;
  }),
  emailAddresses: yup.array().of(yup.string().email(t('invalid_email_address'))),
};

export default validation;
