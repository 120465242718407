import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { User } from '@alpha/profile-dtos';
import t from 'utils/translationHelper';

import ProfileService from '../services/Profile/profile.service';
import { TStore } from '../store';
import { actions } from '../store/user/user.reducer';

import useAlphaSnackbar from './useAlphaSnackbar';
import useLog from './useLog';

const useProfile = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const userProfile = useSelector<TStore, User | undefined>((store) => store.user.profileDetails);
  const dispatch = useDispatch();


  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const updateUserProfile = async () => {
    try {
      setLoading(true);
      const response = await ProfileService.getUserProfile();
      dispatch(actions.updateProfileDetails(response));
    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || t('error_getting_user_profile'));
      logError({ action: 'Error loading user profile', error: e });
    } finally {
      setLoading(false);
    }
  };

  return {
    updateUserProfile,
    userProfile,
    loading,
  };
};

export default useProfile;
