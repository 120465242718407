import React from 'react';
import clsx from 'clsx';

import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';

import PhoneAuthy from '../../../../assets/phoneAuthy.svg';
import PhoneSMS from '../../../../assets/phoneSms.png';
import AppDownload from '../../../../assets/playStoreAppStore.svg';
import t from 'utils/translationHelper';

import useStyles from './index.style';

interface IMfaImage {
  mfaImage: 'authy' | 'sms' | undefined,
}

const HelperAsset: React.FC<IMfaImage> = ({ mfaImage }: IMfaImage) => {

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={clsx('assetWrapper', mfaImage === 'sms' ? classes.fullOpacity : classes.noOpacity)}>
        <img
          className="assetImage"
          src={PhoneSMS}
          alt="Onboarding email being displayed on a laptop and a mobile phone"
        />
        <div className="assetContentWrapper">
          <Typography className="assetTitle">
            {t('sms')}
          </Typography>
          <Typography className="assetDescription">
            {t('two_factor_authentication_codes_will_be_delivered_via_sms')}
          </Typography>
        </div>
      </div>
      <div className={clsx('assetWrapper', mfaImage === 'authy' ? classes.fullOpacity : classes.noOpacity)}>
        <img
          className="assetImage"
          src={PhoneAuthy}
          alt="Onboarding email being displayed on a laptop and a mobile phone"
        />
        <div className="assetContentWrapper">
          <Typography className="assetTitle">
            Authy
          </Typography>
          <Typography className="assetDescription">
            {t('the_fastest_and_most_secure_way_to_authenticate_yourself_within_our_application')}
          </Typography>
          <img src={AppDownload} alt="Playstore and appstore" className="assetFooter" />
        </div>
      </div>
    </div>
  );
};

export default HelperAsset;
