import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginRight: 15,
    },
  }),
  { name: 'BeneficiaryActionDropDown' },
);

export default useStyles;
