import { CreditFacilityDto, CreditFacilityTermDto } from '@alpha/credit-dtos';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TCreditFacilityStore = {
    facility: CreditFacilityDto;
    facilityMode: string;
    termId: string;
    termSummary: CreditFacilityTermDto;
    facilityLoading: boolean,
    termLoading: boolean,
}

const initialState:TCreditFacilityStore = {
  facility: {} as CreditFacilityDto,
  facilityMode: '',
  termId: '',
  termSummary: {} as CreditFacilityTermDto,
  facilityLoading: false,
  termLoading: false,
};

export const creditFacilitySlice = createSlice({
  name: 'creditFacility',
  initialState,
  reducers: {
    loadCreditFacility: (state) => state,
    loadTerm: (state, action: PayloadAction<{termId: string}>) => ({ ...state }),
    setCreditFacility: (state, action: PayloadAction<{
        creditFacility: CreditFacilityDto
    }>): TCreditFacilityStore => (
      {
        ...state,
        ...action.payload,
      }),
    setFacilityMode: (
      state,
      action: PayloadAction<{facilityMode: string}>,
    ): TCreditFacilityStore => ({
      ...state,
      ...action.payload,
    }),
    setTermId: (
      state,
      action: PayloadAction<{termId: string}>,
    ): TCreditFacilityStore => ({
      ...state,
      ...action.payload,
    }),
    setTermSummary: (
      state,
      action: PayloadAction<{termSummary: CreditFacilityTermDto}>,
    ): TCreditFacilityStore => ({
      ...state,
      ...action.payload,
    }),
    setFacilityLoading: (
      state,
      action: PayloadAction<{facilityLoading: boolean}>,
    ): TCreditFacilityStore => ({
      ...state,
      ...action.payload,
    }),
    setTermLoading: (
      state,
      action: PayloadAction<{termLoading: boolean}>,
    ): TCreditFacilityStore => ({
      ...state,
      ...action.payload,
    }),
    clearCreditFacility: () => initialState,
  },
});

export const { actions } = creditFacilitySlice;
