import { ChangeEvent, useEffect } from 'react';
import useSearch from 'hooks/useSearch';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import t from 'utils/translationHelper';

export type TOptionTradesSearchFilterTypes = {
  startDate?: string,
  endDate?: string,
}

type TOptionTradesSearchParams = {
  baseUrl: string,
  queryParams: {
    searchtext?: string,
    take: number,
    skip: number,
    includeStrips: boolean,
    includeLogs: boolean,
  } & Record<string, string | number>,
  body?: Record<string, unknown>,
}

export const createSearchParams = (
  skip: number,
  searchText = '',
  sortOrder: 'asc' | 'desc' = 'desc',
  filters?: TOptionTradesSearchFilterTypes,
): TOptionTradesSearchParams => {
  const searchParams: TOptionTradesSearchParams = {
    baseUrl: 'fx/options-trades-read',
    queryParams: {
      skip,
      take: 10,
      includeStrips: false,
      includeLogs: false,
      sortorder: sortOrder,
    },
  };
  if (searchText) {
    searchParams.queryParams.searchtext = searchText;
  }
  if (filters) {
    if (filters?.startDate) searchParams.queryParams.startdate = filters?.startDate;
    if (filters?.endDate) searchParams.queryParams.enddate = filters?.endDate;
  }
  return searchParams;
};

const useOptionTradesTable = (
  filters?: TOptionTradesSearchFilterTypes,
) => {
  const table = useSearch();
  const defaultSortOrder = 'desc';

  const searchParams = createSearchParams(
    table.skip,
    table.searchText,
    defaultSortOrder,
    filters,
  );

  const handleTableSortClick = (column: string) => {
    table.handleTableSortClick(
      searchParams, column,
    );
  };

  const reloadTableData = (): void => {
    table.handleNewSearch(searchParams);
  };

  const columns: ITableColumn[] = [
    {
      header: t('trade_details'), accessor: 'tradeDetails', width: 220,
    },
    {
      header: t('trade_date'), accessor: 'tradeDate', sortable: true, width: 130,
    },
    {
      header: t('ccy_pair'), accessor: 'currencyPair', width: 120,
    },
    {
      header: t('direction'), accessor: 'direction', width: 100,
    },
    {
      header: t('strip_frequency'), accessor: 'stripFrequency', width: 200,
    },
    {
      header: t('strips_remaining'), accessor: 'stripsRemaining', width: 200, align: 'left',
    },
    {
      header: t('target_remaining'), accessor: 'targetRemaining', width: 200, align: 'left',
    },
    {
      header: t('next_expiry'), accessor: 'nextExpiry', width: 120,
    },
    {
      header: t('status'), accessor: 'status', width: 120,
    },
    { header: t('actions'), accessor: 'actions', align: 'center' },
  ];

  useEffect(() => {
    table.handleInitialSearch(searchParams);
  }, [filters]);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    table.setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.skip = 0;
      searchParams.queryParams.searchtext = e.target.value;
    } else {
      delete searchParams.queryParams.searchtext;
    }
    await table.handleNewSearch(searchParams);
  };

  return {
    columns,
    table,
    handleInputChange,
    reloadTableData,
    handleTableSortClick,
    searchParams,
  };
};

export default useOptionTradesTable;
