import React, { memo } from 'react';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import warningIcon from '../../../../../../assets/warning.svg';
import APModal from '../../../../../../components/Modals/APModal/APModal';

import useStyles from './RejectModal.styles';

interface IProps {
  open: boolean;
  batchName: string;
  subtitle1: string;
  subtitle2?: string;
  subtitle3?: string;
  actionButton: string;
  handleClose: () => void;
  handleActionButton: () => Promise<void> | void;

}
const RejectModal: React.FC<IProps> = (props: IProps) => {
  const {
    open,
    batchName,
    subtitle1,
    subtitle2,
    subtitle3,
    actionButton,
    handleClose,
    handleActionButton,
  } = props;


  const styles = useStyles();
  return (
    <APModal.Container
      open={open}
      onClose={handleClose}
      className={styles.container}
    >
      <APModal.Wrapper>
        <Box className={styles.header}>
          <img src={warningIcon} alt="Warning icon" />
          <Typography variant="h2">
            {batchName}
          </Typography>
        </Box>
        <Box className={styles.body}>
          <Typography className={styles.paddingBottom} variant="subtitle1">
            {subtitle1}
          </Typography>
          <Typography className="errorMessage" variant="subtitle1">
            {subtitle2}
          </Typography>
          <Typography variant="subtitle1">
            {subtitle3}
          </Typography>
        </Box>
        <Box className={styles.buttonsContainer}>
          <ActionButton style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }} onClick={handleClose}>
            {t('edit_details')}
          </ActionButton>
          <ActionButton
            testId="modal-reject-button"
            onClick={handleActionButton}
            className="rejectButton"
          >
            {actionButton}
          </ActionButton>
        </Box>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default memo(RejectModal);
