import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ palette, typography }) => ({
    tableWrapper: {
      '& .MuiTableHead-root': {
        borderTop: '#EAEAEA solid 1px',
      },
      '& .MuiTableCell-root': {
        'padding-right': '20px !important',
      },
      '@media (max-width: 1200px)': {
        '& .MuiTableCell-root': {
          'padding-right': '10px !important',
        },
        '& th.MuiTableHead-root, & td.MuiTableCell-root': {
          fontSize: '12px',
          '& span': {
            fontSize: '11px',
          },
        },
      },
      '& td': {
        padding: '10px 0px !important',
      },
    },
    amount: {
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'row',
      lineHeight: '14px',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    },
    tableLink: {
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    status: {
      minWidth: '130px',
      textAlign: 'center',
    },
    approvalIcon: {
      marginRight: 5,
    },
    noOfApprovers: {
      margin: 'auto',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    icon: {
      color: 'rgba(0, 0, 0, 0.45)',
      marginRight: 8,
    },
    tradeLinkIcon: {
      backgroundColor: palette.grey[100],
      borderRadius: '5px',
    },
    textAlignRight: {
      margin: 'auto',
      textAlign: 'right',
    },
    textAlignLeft: {
      margin: 'auto',
      textAlign: 'left',
    },
    payments: {
      marginRight: 'auto',
      textAlign: 'right',
    },
    approval: {
      margin: 'auto',
      textAlign: 'left',
    },
    processing: {
      textAlign: 'center',
      paddingRight: 20,
      color: 'rgb(170, 170, 170)',
    },
    action: {
      textAlign: 'center',
      paddingRight: 20,
    },
    link: {
      color: palette.primary.light,
    },
    tradeTableButton: {
      textTransform: 'none',
      fontSize: 14,
      padding: 0,
      fontWeight: 600,
      color: 'rgb(9, 131, 117)',
      textDecoration: 'none',
      textAlign: 'left',
      justifyContent: 'left',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
    exportMenu: {
      padding: '17px 0px 0px 0px',
    },
    emptyTable: {
      height: '300px',
      position: 'relative',
      marginBottom: '41px',
    },
    padTooltipContainer: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '375px',
      minHeight: '50px',
      '&.mui-tooltip:hover': {
        visibility: 'visible',
        opacity: 1,
      },
    },
    tooltipButton: {
      justifyContent: 'left',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
    tooltipText: {
      marginTop: '10px',
      textTransform: 'none',
      fontSize: '13px',
      fontWeight: 200,
      color: '#00A09B',
      textDecoration: 'underline',
      textAlign: 'left',
    },
    approvalKey: {
      color: '#FFFFFF',
      opacity: '56%',
      paddingRight: '5px',
    },
    approvalDiv: {
      marginTop: '10px',
    },
    currencyFlag: {
      marginLeft: '5px',
      lineHeight: '10px',
      '& > div': {
        flexDirection: 'row',
        padding: '2px',
        '& > span': {
          paddingLeft: '3px',
        },
      },

    },
  }), { name: 'FxTradeTable' },
);

export default useStyles;
