import React from 'react';
import { useDispatch } from 'react-redux';

import { PaymentDto } from '@alpha/payments-dtos';

import Authorization from '../../../../../hocs/Authorization';
import useAuthorization from '../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../models/user';
import routes from '../../../../../routes.path';
import history from '../../../../../services/history/browserHistory';
import * as authyActions from '../../../../../store/authy/actions';
import { actions as paymentActions } from '../../../../../store/payments/payments.reducer';
import BatchSubmissionFooter from '../BatchSubmissionFooter/BatchSubmissionFooter';
import BatchSubmittedFooter from '../BatchSubmittedFooter/BatchSubmittedFooter';

interface IProps {
  batchDetails: PaymentDto;
}

const BatchSubmissionFooterContainer: React.FC<IProps> = (props: IProps) => {
  const { batchDetails } = props;
  const dispatch = useDispatch();
  const { authorized } = useAuthorization([[UserRole.PAYMENTS_APPROVER_OWN]]);

  const buttonTextFunc = (): string => {
    if (
      authorized
      && batchDetails.approvalInfo.approvalsRequired === 1
    ) {
      return 'Send Payments';
    }
    return 'Submit for approval';
  };

  const handleExitClick = () => {
    dispatch(paymentActions.clear());
    history.push(routes.transactions.base);
  };

  const handleOnClick = () => {
    dispatch(
      authyActions.initiatePayment({
        paymentIds: ['hi'],
        type: 'PAYMENTS',
        approverOwn: authorized,
      }),
    );
  };

  return (
    <Authorization
      requiredRoles={[[UserRole.PAYMENTS_INPUTTER]]}
      fallback={<BatchSubmittedFooter />}
    >
      <BatchSubmissionFooter
        handleExitClick={handleExitClick}
        handleOnClick={handleOnClick}
        buttonText={buttonTextFunc()}
      />
    </Authorization>
  );
};

export default BatchSubmissionFooterContainer;
