import React, { Dispatch, useState } from 'react';

import { CurrencyAccountRequest } from '@alpha/currency-accounts-dtos';
import { IconButton } from '@alpha/ui-lib/ui/button/IconButton';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { IOption } from '../components/AutocompleteDropDown/AutocompleteDropDown';
import validation, { initialValues } from '../components/Modals/RequestCurrencyAccountModal/formData';
import CurrencyAccountsService from '../services/CurrencyAccounts/currencyAccounts.service';

import useAlphaSnackbar from './useAlphaSnackbar';
import useForm from './useForm';
import useLog from './useLog';
import t from 'utils/translationHelper';


interface IProps {
  setOpenAccountRequestModal: Dispatch<React.SetStateAction<boolean>>;
}

const useCurrencyAccountRequest = (props: IProps) => {

  const { setOpenAccountRequestModal } = props;
  const [submittingAccountRequest, setSubmittingAccountRequest] = useState<boolean>(false);
  const [openDupeCheckModal, setOpenDupeCheckModal] = useState<boolean>(false);
  const [
    accountCurrencyOption,
    setAccountCurrencyOption,
  ] = useState<IOption>({ name: '', code: '' });

  const sb = useAlphaSnackbar();
  const { logEvent, logError } = useLog();

  const handleAccountRequestSubmission = async (
    accountRequest: CurrencyAccountRequest,
    skipDupecheck: boolean,
  ) => {
    setSubmittingAccountRequest(true);
    try {
      await CurrencyAccountsService.postCurrencyAccountRequest(
        accountRequest,
        skipDupecheck,
      );
      setSubmittingAccountRequest(false);
      setOpenAccountRequestModal(false);
      currencyAccountForm.resetForm();
      setAccountCurrencyOption({ name: '', code: '' });
      sb.trigger((
        <>
          {t('currency_account_request_submitted')}
          <br />
          {t('please_allow_up_to_10_minutes_for_it_to_appear_within_accounts')}
        </>),
        'info',
        {
          persist: true,
          action: (key: string) => (
            <IconButton
              icon={faTimes}
              onClick={() => {
                sb.close(key);
              }}
              style={{ color: '#fff', fontSize: '12px', display: 'inline-block' }}
            />
          ),
        });
      logEvent({
        action: 'Successfully submitted currency account request',
        detail: {
          accountRequest,
        },
      });
    } catch (error) {
      // If it's a dupe check failure open modal to allow user to force skip the dupe check
      if (error.response.status === 409) {
        setSubmittingAccountRequest(false);
        setOpenAccountRequestModal(false);
        setOpenDupeCheckModal(true);
        logEvent({ action: 'Duplicated currency account request' });
      } else {
        sb.trigger(t('error_submitting_currency_account_request'), 'error');
        logError({ action: 'Error submitting currency account request', error });
        setSubmittingAccountRequest(false);
        setOpenAccountRequestModal(false);
        currencyAccountForm.resetForm();
        setAccountCurrencyOption({ name: '', code: '' });
      }
    }
  };

  const currencyAccountForm = useForm<CurrencyAccountRequest>(
    initialValues,
    validation, (accountRequest: CurrencyAccountRequest) => {
      setSubmittingAccountRequest(true);
      handleAccountRequestSubmission(accountRequest, false);
    },
  );

  const handleDupeActionButton = () => {
    setOpenDupeCheckModal(false);
    handleAccountRequestSubmission(
      {
        currencyCode: currencyAccountForm.values.currencyCode,
        friendlyName: currencyAccountForm.values.friendlyName,
      }, true,
    );
  };

  return {
    currencyAccountForm,
    submittingAccountRequest,
    accountCurrencyOption,
    setAccountCurrencyOption,
    openDupeCheckModal,
    setOpenDupeCheckModal,
    handleDupeActionButton,
  };
};

export default useCurrencyAccountRequest;
