import React from 'react';

import { Box } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';

import useStyles from './DrawerHeaderFlag.styles';

interface IProps {
    countryCode: string
}

const DrawerHeaderFlag:React.FC<IProps> = ({ countryCode }: IProps) => {
  const styles = useStyles();
  return (
    <>
      <Box className={styles.imageHolder}>
        <Flag code={countryCode} size="md" />
      </Box>
      {countryCode}
    </>
  );
};

export default DrawerHeaderFlag;
