import { OptionsStripsStatus } from 'models/optionTrades';

export const mapOptionsStripsStatusDisplay = (
  status: OptionsStripsStatus,
): {
  variant: 'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined, text:
  string
} => {
  switch (status) {
    case OptionsStripsStatus.ACTIVE:
      return { variant: 'info', text: 'ACTIVE' };
    case OptionsStripsStatus.EXERCISED:
      return { variant: 'success', text: 'EXERCISED' };
    case OptionsStripsStatus.CANCELLED:
      return { variant: 'error', text: 'CANCELLED' };
    case OptionsStripsStatus.EXPIRED:
      return { variant: 'orange', text: 'EXPIRED' };
    default:
      return { variant: 'warning', text: '-' };
  }
};

export default mapOptionsStripsStatusDisplay;
