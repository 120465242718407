import React from 'react';
import { DateTime } from 'luxon';

import { Box } from '@alpha/ui-lib/ui/external';
import { Stat } from '@alpha/ui-lib/ui/Stat';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TextBoldLight from '../../../../../components/TextBoldLight/TextBoldLight';
import { testIds } from '../BatchDetails';

import useStyles from './CutOffDetails.styles';

interface IProps {
  cutOffDate: string;
  cutOffTime: string;
  cutOffExpired: boolean;
}

const CutOffDetails: React.FC<IProps> = (props: IProps) => {
  const { cutOffDate, cutOffTime, cutOffExpired } = props;
  const classes = useStyles();

  const calculateLocalEarliestProcessDate = (format: string) => {
    const date = DateTime.fromISO(`${cutOffDate}T${cutOffTime}`, { zone: 'utc' }).toLocal();

    if (!date.isValid) {
      return '-';
    }

    return date.toFormat(format);
  };

  return (
    <>
      <Box marginRight="45px">
        <Stat
          testId={testIds.cutOffTime}
          title="Cut-off time"
          value={(
            <Box className={classes.cutOffTime}>
              {cutOffExpired
                ? <FontAwesomeIcon className={classes.crossIcon} icon={faTimesCircle as IconProp} />
                : <FontAwesomeIcon className={classes.tickIcon} icon={faCheckCircle as IconProp} />}
              <TextBoldLight
                boldText={!cutOffExpired ? (
                  <span>
                    {calculateLocalEarliestProcessDate('HH:mm ZZZZ')}
                  </span>
                ) : ''}
                lightText={cutOffExpired ? (
                  <span>
                    {calculateLocalEarliestProcessDate('HH:mm ZZZZ')}
                  </span>
                ) : ''}
              />
            </Box>
          )}
        />
      </Box>
      <Stat
        testId={testIds.cutOffTime}
        title="earliest process date"
        value={<TextBoldLight lightText="" boldText={calculateLocalEarliestProcessDate('D')} />}
      />
    </>
  );
};

export default CutOffDetails;
