import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  drawer: {
    '& .MuiDrawer-paper': {
      '& > .MuiBox-root': {
        paddingTop: '60px',
      },
    },
  },
  lineBreakMargin: {
    marginBottom: '30px',
  },
  body: {
    height: '100%',
    '& > .MuiBox-root': {
      height: '100%',
    },
  },
  footer: {
    margin: '32px 0',
  },
}), { name: 'CreateBeneficiaryDrawer' });

export default useStyles;
