import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    rightAlignedCtas: {
      display: 'flex',
      alignItems: 'flex-start',
      position: 'absolute',
      '& > div': {
        '& > div': {
          marginRight: '8px',
          position: 'relative',
          top: '-17px',
        },
      },
      '& > div:last-of-type': {
        maxHeight: '50px',
      },
      '& > button:first-of-type': {
        fontSize: '12px',
      },
    },
    divider: {
      width: 1,
      height: 50,
      backgroundColor: 'rgba(112, 112, 112, 0.2)',
      margin: '0px 20px',
    },
    dates: {
      display: 'flex',
    },
    width: {
      minWidth: 110,
    },
  }),
  { name: 'StatementsCtas' },
);

export default useStyles;
