import React from 'react';
import { TStatItem } from 'components/Molecules/Credit/CreditSummary/CreditSummary';
import MarginHeldAmounts from 'components/Molecules/Credit/MarginHeldAmounts/MarginHeldAmounts';
import { formatNumber, getCurrencySymbol } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import { CreditFacilityDto } from '@alpha/credit-dtos';
import { Flag } from '@alpha/ui-lib/ui/Flag';

import useStyles from './index.styles';

const useCreditSummary = (facility: CreditFacilityDto): TStatItem[] | undefined => {

  const styles = useStyles();

  if (facility?.facilityTerms) {
    const { currencyCode } = facility;
    const marginHeldDetails = facility?.marginHeldAmounts?.length ? <MarginHeldAmounts amounts={facility?.marginHeldAmounts} /> : null;

    const result = [
      {
        header: t('base_currency'),
        value: (
          <span className={styles.flag}>
            <Flag code={currencyCode || ''} size="md" showCodeLabel />
            {' '}
          </span>),
        helpMessage: t('all_bespoke_terms_converted_to_base_currency_for_summary_format'),
      },
      {
        header: t('facility_limit'),
        value: currencyCode && (facility.facilityLimit || facility.facilityLimit === 0)
          ? `${getCurrencySymbol(currencyCode)}${formatNumber(facility.facilityLimit, 2, true)}`
          : '-',
      },
      {
        header: t('utilised'),
        value: currencyCode && (facility.utilisationAmount || facility.utilisationAmount === 0)
          ? `${getCurrencySymbol(currencyCode)}${formatNumber(facility.utilisationAmount, 2)}`
          : '-',
      },
      {
        header: t('margin_held'),
        value: currencyCode && (facility.marginHeld || facility.marginHeld === 0)
          ? `${getCurrencySymbol(currencyCode)}${formatNumber(facility?.marginHeld, 2, true)}`
          : '-',
        helpMessage: marginHeldDetails,
      },
      {
        header: t('max_tenor_(months)'),
        value: facility.maxTenor ? `${facility.maxTenor}` : '-',
      },
    ];

    if (facility.marginCallDelay) {
      result.push({
        header: t('margin_call_delay'),
        value: facility.marginCallDelay ? `${facility.marginCallDelay} Days` : '-',
      });
    }

    if (facility.marginCallCap) {
      result.push({
        header: t('margin_call_cap'),
        value: facility.marginCallCap ? `${getCurrencySymbol(currencyCode)}${formatNumber(facility.marginCallCap, 2, true)}` : '-',
      });
    }

    result.push({
      header: t('mtm_valuation'),
      value: currencyCode && facility.mtmAmount ? `${getCurrencySymbol(currencyCode)}${formatNumber(facility.mtmAmount, 2, true)}` : '-',
    });

    return result;
  }
};

export default useCreditSummary;
