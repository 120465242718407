import React from 'react';

import { Status } from '@alpha/ui-lib/ui/Status';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import useStyles from './PadInfoTooltip.styles';

const PadInfoTooltip: React.FC = () => {

  const classes = useStyles();

  return (
    <APTooltip
      title={(
        <div className={classes.tooltipContainer}>
          <div className={classes.title}>{t('pre_authorised_debit_(pad)_eligibility')}</div>
          <div className={classes.topSubtitle}>
            {t('you_can_authorise_eligible_trades_for_pad_settlement')}
          </div>
          <div className={classes.statusContainer}>
            <Status variant="default" className={classes.statusDefault}>P</Status>
            <div>
              <div className={classes.title}>{t('pad_eligible')}</div>
              <div className={classes.subtitle}>
                {t('this_trade_is_eligible_to_be_paid_for_via_pad')}
              </div>
            </div>
          </div>
          <div className={classes.statusContainer}>
            <Status variant="success" className={classes.statusSuccess}>P</Status>
            <div>
              <div className={classes.title}>{t('pad_authorised')}</div>
              <div className={classes.subtitle}>{t('this_trade_has_been_pad_authorised')}</div>
            </div>
          </div>
        </div>
      )}
    >
      <div>
        <FontAwesomeIcon icon={faCircleInfo as IconProp} className={classes.icon} />
      </div>
    </APTooltip>
  );
};

export default PadInfoTooltip;
