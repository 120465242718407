import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserNotificationDto, UserNotificationsResponseDto } from '@alpha/notifications-dtos';

import NotificationsService from '../services/Notifications/notifications.service';
import { TStore } from '../store';
import { actions as clientNotificationsActions } from '../store/clientNotifications/clientNotifications.reducer';
import t from 'utils/translationHelper';

import useAlphaSnackbar from './useAlphaSnackbar';
import useLog from './useLog';

const useNotificationCenter = () => {
  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();
  const { logEvent, logError } = useLog();


  const [showNotificationsDrawer, setShowNotificationsDrawer] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] = useState<UserNotificationDto>();

  const [isAcknowledgingNotification, setIsAcknowledgingNotification] = useState<boolean>(false);

  const handleCloseNotificationModal = () => {
    setShowNotificationModal(false);
    setTimeout(
      // Prevent content changes when fading out
      () => setSelectedNotification(undefined), 200,
    );
  };

  const handleAcknowledgeNotification = async (notification: UserNotificationDto) => {
    try {
      setIsAcknowledgingNotification(true);
      if (!notification.acknowledged) {
        const result = await NotificationsService.putNotificationAcknowledge(notification.id);
      }
    } catch (error) {
      sb.trigger(error?.message || t('there_is_an_error_please_try_again'));
      logError({ action: 'Error acknowledging notifications', error });
    }

    setIsAcknowledgingNotification(false);
    dispatch(clientNotificationsActions.load());
    sb.trigger(t('notificiation_acknowledged'), 'success');
    logEvent({ action: 'Acknowledged notification' });
    handleCloseNotificationModal();
  };

  const notificationsResponse = useSelector<TStore, UserNotificationsResponseDto | undefined>((
    state: TStore,
  ) => state.clientNotifications.data);

  const shouldInitialShowModal = useSelector<TStore, boolean>((
    state: TStore,
  ) => state.clientNotifications.showModal);

  useEffect(() => {
    if (shouldInitialShowModal && notificationsResponse
      && notificationsResponse?.promptNotifications?.length > 0) {
      setShowNotificationModal(true);
      dispatch(clientNotificationsActions.showed());
    }
  }, [notificationsResponse?.promptNotifications, shouldInitialShowModal]);

  return {
    showNotificationsDrawer,
    setShowNotificationsDrawer,
    showNotificationModal,
    setShowNotificationModal,
    selectedNotification,
    setSelectedNotification,
    isAcknowledgingNotification,
    handleAcknowledgeNotification,
    handleCloseNotificationModal,
    notificationsResponse,
  };
};

export default useNotificationCenter;
