import React from 'react';

import { Button } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../hooks/useReportsPolling';

import useStyles from './HeaderButtons.styles';

interface IProps {
  recordId: string | undefined;
  showDownloadButton: boolean;
  isTransfer?: boolean;
}

const HeaderButtons: React.FC<IProps> = (
  { recordId, showDownloadButton, isTransfer }: IProps,
) => {
  const classes = useStyles();


  const pageType = isTransfer ? PageType.Transfers : PageType.Payments;
  const downloadType = isTransfer ? DownloadType.TransferSummary : DownloadType.Summary;
  const { executeReportGeneration } = useReportsPolling(pageType);

  const handleReportGeneration = async (type: FileType, downloadType: DownloadType) => {
    if (recordId) {
      await executeReportGeneration(type, recordId, undefined, undefined, downloadType, type);
    }
  };

  return (
    <div>
      {showDownloadButton && (
        <APTooltip title={!isTransfer ? t('download_payment_summary~') : t('download_transfer_summary~')}>
          <Button
            data-testid="payment-summary-download"
            onClick={() => handleReportGeneration(FileType.PDF, downloadType)}
            className={classes.button}
          >
            <FontAwesomeIcon icon={faDownload as IconProp} />
          </Button>
        </APTooltip>
      )}
    </div>
  );
};

export default HeaderButtons;
