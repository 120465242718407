import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}), { name: 'CreateBeneficiaryDrawer' });

export default useStyles;
