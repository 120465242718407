import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import APModal from '../../../../components/Modals/APModal/APModal';
import { ModalType } from '../Body';

import useStyles from './index.styles';

interface IProps {
  modalType: ModalType | false;
  modalOpen: boolean;
  loading: boolean;
  handleCloseModal: () => void;
  handleOnDeleteClick: () => void;
  handleOnRejectionClick?: () => void;
}

const DeleteOrRejectModal: React.FC<IProps> = (props: IProps) => {
  const {
    loading,
    modalOpen,
    modalType,
    handleCloseModal,
    handleOnRejectionClick,
    handleOnDeleteClick,
  } = props;
  const classes = useStyles();
  const modalTypeText = modalType === 'reject' ? 'reject' : 'delete';


  return (
    <APModal.Container
      open={modalOpen}
      onBackdropClick={handleCloseModal}
      className={classes.modalWrapper}
    >
      <APModal.Wrapper>
        <div className={classes.container}>
          <FontAwesomeIcon
            className={classes.icon}
            size="lg"
            icon={faExclamationTriangle as IconProp}
          />
          <div className={classes.title}>
            <Typography className={classes.header}>
              {modalTypeText === 'delete' ? t('are_you_sure_you_want_to_delete_this_bene') : t('are_you_sure_you_want_to_reject_this_bene')}
            </Typography>
          </div>
          <Typography className={classes.subHeader}>
            {modalTypeText === 'reject' ? t('rejecting_this_beneficiary_will_remove') : t('deleting_this_beneficiary_will_remove')}
          </Typography>
        </div>
        <div className={classes.buttons}>
          <ActionButton
            size="medium"
            disabled={loading}
            variant="outlined"
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={handleCloseModal}
            testId="confirm-button"
          >
            {t('no_keep_beneficiary')}
          </ActionButton>
          <ActionButton
            loading={loading}
            disabled={loading}
            variant="outlined"
            size="medium"
            id="rejectButton"
            onClick={modalType === 'reject' ? handleOnRejectionClick : handleOnDeleteClick}
            testId="reject-button"
          >
            {modalType === 'reject' ? t('yes_reject_bene') : t('yes_delete_bene')}
          </ActionButton>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default DeleteOrRejectModal;
