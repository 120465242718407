import t from 'utils/translationHelper';
import * as yup from 'yup';

import { ExternalTradeRequest } from '@alpha/fx-dtos';

export const initialValues: ExternalTradeRequest = {
  programId: '',
  fixedCurrencyCode: '',
  fixedAmount: 0,
  valueDate: new Date(Date.now()).toLocaleDateString('en-CA'),
  rate: 0,
  reference: '',
};

export const validation = () => {
  const validationReturn = {
    fixedCurrencyCode: yup.string().required(t('please_select_a_currency')),
    fixedAmount: yup
      .number()
      .typeError(t('please_enter_numbers_only'))
      .required(t('please_enter_a_valid_amount'))
      .positive(t('please_enter_an_amount_greater_than_zero')),
    valueDate: yup.string().required(t('please_specify_a_value_date')),
    rate: yup
      .number()
      .typeError(t('please_enter_numbers_only'))
      .required(t('please_specify_a_rate'))
      .positive(t('please_enter_an_amount_greater_than_zero')),
    reference: yup.string().required(t('please_specify_a_trade_reference')),
  };
  return validationReturn;
};

export default validation;
