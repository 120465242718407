import i18n from 'i18n/config';

const t = (message?: string | undefined, config?: Record<string, string>): string => {
  const currentLang = i18n.language;
  const languages = ['_EN_', '_IT_', '_DE_', '_FR_', '_ES_'];
  let messageContainsCurrentLang = -1;
  if (message) {
    for (let i = 0; i < languages.length; i++) {
      if (message.includes(languages[i])) {
        message = message.replace(languages[i], '');
        if (languages[i].slice(1, -1).toLowerCase() === currentLang) {
          messageContainsCurrentLang = i;
        }
      }
    }
    if (messageContainsCurrentLang !== -1) {
      message += languages[messageContainsCurrentLang];
    }
    return i18n.t(message, config);
  }
  return i18n.t('');
};

export default t;
