import { useState } from 'react';
import IATService from 'services/IAT/IAT.service';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import {
  faArrowDown,
  faBan,
  faCheckCircle,
  faCirclePlay,
  faCommentAltDots,
  faDownload,
  faEnvelope,
  faEye,
  faQuestionSquare,
  faTimesCircle,
  faTrashCan,
  faTriangleExclamation,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { faP } from '@fortawesome/pro-solid-svg-icons';

import BeneficiariesService from '../services/Beneficiaries/beneficiaries.service';
import PaymentsService from '../services/Payments/payments.service';

import useAlphaSnackbar from './useAlphaSnackbar';

export type TActionDropdown = 'PaymentInfo' | 'TradeInfo' | 'TransferInfo' | 'DeleteTrade'
  | 'BookTrade' | 'DownloadPayment' | 'DownloadTrade' | 'DownloadPaymentUploadFile' | 'DownloadTransfer'
  | 'RequestDrawdown' | 'NewDrawdown' | 'ContinuePayment'
  | 'DeletePayment' | 'ApprovePayment' | 'RejectPayment' | 'CancelTrade'
  | 'ApproveTransfer' | 'RejectTransfer';

export type TDropdownIcon = 'View' | 'Download' | 'Request'
  | 'Drawdown' | 'Delete' | 'Continue' | 'Approve' | 'Reject' | 'Email' | 'Pad' | 'LeftBusiness' | 'NoAccess';

interface IProps {
  currentOptions?: Approver[],
}

const useActionDropdown = ({
  currentOptions,
}: IProps = {}) => {
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const [approvers, setApprovers] = useState<Approver[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [menuCloseCount, setMenuCloseCount] = useState<number>(0);


  const snackbar = useAlphaSnackbar();

  const displayIcon = (dropdownIcon: TDropdownIcon): IconDefinition => {
    switch (dropdownIcon) {
      case 'View': {
        return faEye;
      }
      case 'Download': {
        return faDownload;
      }
      case 'Request': {
        return faCommentAltDots;
      }
      case 'Drawdown': {
        return faArrowDown;
      }
      case 'Delete': {
        return faTrashCan;
      }
      case 'Continue': {
        return faCirclePlay;
      }
      case 'Approve': {
        return faCheckCircle;
      }
      case 'Reject': {
        return faBan;
      }
      case 'Email': {
        return faEnvelope;
      }
      case 'Pad': {
        return faP;
      }
      case 'LeftBusiness': {
        return faTimesCircle;
      }
      case 'NoAccess': {
        return faTriangleExclamation;
      }
      default: {
        return faQuestionSquare;
      }
    }
  };

  const handleRenderDropdown = async (
    displayMultiSelect: boolean,
    id: string,
    type: string,
    setDisplayMultiSelect?: (val: boolean) => void,
  ) => {
    if (!displayMultiSelect) {
      if (setDisplayMultiSelect) {
        setDisplayMultiSelect(true);
      }
      try {
        if (type === 'Payment') {
          return setApprovers(await PaymentsService.getApprovers(id));
        } if (type === 'Transfer') {
          return setApprovers(await IATService.getIATApprovers(id));
        } if (type === 'Beneficiary') return setApprovers(await BeneficiariesService.getBeneficiaryApprovers(id));
      } catch (e) {
        snackbar.trigger(e?.message || t('there_was_an_error_retrieving_your_approvers'));
      }
      setLoading(false);
    }
    return null;
  };

  const submitApproverEmails = async (
    id: string,
    type: string,
  ): Promise<boolean> => {
    const currentApproverIds: string[] = [];
    if (currentOptions) {
      currentOptions.forEach((option) => currentApproverIds.push(option.userId));
    }
    try {
      if (type === 'Payment') {
        await PaymentsService.postApproverEmails(id, { approverIds: currentApproverIds });
      } else if (type === 'Transfer') {
        await IATService.postIATApproverEmails(id, currentApproverIds);
      } else if (type === 'Beneficiary') {
        await BeneficiariesService.postApproverEmails(id, { approverIds: currentApproverIds });
      }
      snackbar.trigger(t('successfully_sent_email_reminders'), 'success');
      return true;
    } catch (e) {
      snackbar.trigger(t('unable_to_send_email_reminders'));
      return false;
    }
  };

  return {
    open,
    approvers,
    loading,
    menuCloseCount,
    setMenuCloseCount,
    setLoading,
    setApprovers,
    setOpen,
    displayIcon,
    handleRenderDropdown,
    submitApproverEmails,
  };
};

export default useActionDropdown;
