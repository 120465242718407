import { AxiosResponse } from 'axios';
import { FileType } from 'hooks/useReportsPolling';
import { TReportGenerationResponse } from 'models/currencyAccounts';
import AuthyService from 'services/Authy/Authy.service';

import { Approver } from '@alpha/auth-dtos';
import {
  CurrencyAccountTransferDto,
  CurrencyAccountTransferStatus,
  TransferApprovalRequest,
  TransferApprovalResponseDto,
  TransferFilterOptionsDto,
  TransferVerificationResponseDto,
  VerificationMethod,
} from '@alpha/currency-accounts-dtos';

import instance from '../Axios/instance';

export type CurrencyAccountTransferRequest = {
  fundingCurrencyAccountId: string,
  debitingCurrencyAccountId: string,
  debitingAccountId: string,
  instructedAmount: number,
  instructedCurrencyCode: string,
  reference?: string,
  valueDate: string,
};

export default class IATService {
  public static async postTransferRequest(
    body: CurrencyAccountTransferRequest,
  ): Promise<any> {
    const queryString = '/ca/transfers';
    const response = await instance.post(
      queryString,
      body,
    );
    return response.data;
  }

  public static async getIATApprovers(id?: string) {
    return this.getIATApproversAsync(id);
  }

  private static async getIATApproversAsync(id?: string): Promise<Approver[]> {
    const response: AxiosResponse<Approver[]> = await instance.get(`/ca/transfers/${id}/approvers`);
    return response.data;
  }

  public static async postIATApproverEmails(
    id: string,
    approverIds: string[],
  ): Promise<number> {
    return this.postIATApproverEmailsAsync(id, approverIds);
  }

  private static async postIATApproverEmailsAsync(
    id: string,
    approverIds: string[],
  ): Promise<number> {
    const response: AxiosResponse<number> = await instance.post(`/ca/transfers/${id}/approvers/notify`, approverIds);
    return response.status;
  }

  public static async getTransferVerification({
    transferId,
    method,
  }: {
    transferId: string,
    method?: VerificationMethod
  }): Promise<TransferVerificationResponseDto> {
    return this.getTransferVerificationAsync({ transferId, method });
  }

  private static async getTransferVerificationAsync({
    transferId,
    method,
  }: {
    transferId: string,
    method?: VerificationMethod
  }): Promise<TransferVerificationResponseDto> {
    let finalVM = method;
    if (!finalVM) {
      finalVM = await AuthyService.selectDefaultAuthenticationMethod();
    }
    const response = await instance.get(
      `/ca/transfers/${transferId}/verification/approve?method=${finalVM}`,
    );

    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }

    return response.data;
  }

  public static async approveTransfer(
    payload: TransferApprovalRequest,
    transferId: string,
  ): Promise<TransferApprovalResponseDto> {
    return this.postApproveTransferAsync(payload, transferId);
  }

  private static async postApproveTransferAsync(
    params: TransferApprovalRequest,
    transferId: string,
  ): Promise<any> {
    const response: AxiosResponse<any> = await instance.post(
      `/ca/transfers/${transferId}/approve`,
      {
        totp: params.totp,
        approvalRequestId: params.approvalRequestId,
        dynamicLinkingId: params.dynamicLinkingId,
        softToken: params.softToken,
      },
    );
    return response.data;
  }

  public static async rejectTransfer(
    id: string,
  ): Promise<any> {
    const response: AxiosResponse<any> = await instance.put(`/ca/transfers/${id}/reject`, id);
    return response.status;
  }

  public static async getTransferSummaryGeneration(
    type: FileType,
    transferId: string,
  ): Promise<string> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/currency-account-transfers/${transferId}?type=${type}`,
    );
    return response.data.executionArn;
  }

  public static async getPendingApprovalTransfers(
  ): Promise<{ total: number, records: CurrencyAccountTransferDto[] }> {
    const params = {
      skip: 0,
      take: 5,
      sortby: 'valueDate',
      sortorder: 'desc',
      status: CurrencyAccountTransferStatus.SUBMITTED,
    };
    const response: AxiosResponse<{
      total: number,
      records: CurrencyAccountTransferDto[]
    }> = await instance({
      method: 'GET',
      url: '/ca/transfers/search',
      params,
    });
    return response.data;
  }

  public static async getTransferFilterOptions(): Promise<TransferFilterOptionsDto> {
    const response: AxiosResponse<TransferFilterOptionsDto> = await instance.get('/ca/transfers/filter-options');
    return response.data;
  }
}
