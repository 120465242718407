import { useCallback, useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { DateTime } from 'luxon';
import { FeatureFlagName } from 'services/Config/config.service';

const TEN_HOURS = 36000;
const TEN_MINUTES = 600;

const useInactivityTracker = () => {
  const { isFlagActive } = useGetFeatureFlags();

  const [open, setOpen] = useState<boolean>(false);
  const maxInactivityTime = isFlagActive(FeatureFlagName.LONGER_TOKEN_TIME)
    ? TEN_HOURS : TEN_MINUTES;
  const modalPopupThreshold = 60;
  const logoutThreshold = 0;
  const { handleSignOut } = useAuth();
  const [initiatedTime, setInitiatedDateTime] = useState<DateTime>(DateTime.utc());
  const [expirationTimer, setExpirationTimer] = useState<number>(maxInactivityTime);

  const handleResetTimer = useCallback(() => {
    if (open) setOpen(false);
    setInitiatedDateTime(DateTime.utc());
    setExpirationTimer(maxInactivityTime);
  }, [open]);

  const logoutTimeInterval = () => {
    const interval = setInterval(async () => {
      const newExpirationTimer = expirationTimer - 1;

      // We need this for cases where the user closes their laptop and reopens it again
      if (DateTime.utc().diff(initiatedTime, 'seconds').seconds >= maxInactivityTime) {
        clearInterval(interval);
        await handleSignOut();
      }

      if (newExpirationTimer > modalPopupThreshold && open) {
        setOpen(false);
      } else if (newExpirationTimer === modalPopupThreshold) {
        setOpen(true);
      } else if (newExpirationTimer <= logoutThreshold) {
        clearInterval(interval);
        await handleSignOut();
      }
      setExpirationTimer(newExpirationTimer);
    }, 1000);

    return interval;
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    interval = logoutTimeInterval();

    return () => {
      clearInterval(interval);
    };
  });

  return {
    expirationTimer,
    modalPopupThreshold,
    handleSignOut,
    open,
    handleResetTimer,
  };
};

export default useInactivityTracker;
