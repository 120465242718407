import { FormikProps } from 'formik';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import {
  DrawdownFundingMethod,
  DrawdownRequest,
  DrawdownSubmissionRequest,
} from '@alpha/fx-dtos';
import { PaymentOrigin, PaymentPurposeDto } from '@alpha/payments-dtos';

import {
  FormikDrawdownPaymentType,
  FormikPaymentType,
} from '../../Payments/CreatePaymentDrawer/formData';

export const initialValues: DrawdownRequest = {
  tradeId: '',
  fixCurrencyCode: '',
  valueDate: '',
  amount: 0,
  fundingMethod: DrawdownFundingMethod.CURRENCY_ACCOUNT_BALANCE,
  sellCurrencyCode: '',
  buyCurrencyCode: '',
  rate: 0,
};

export type FirstPartyDrawdownType = {
  fixCurrencyCode: string;
  amount: number;
  valueDate: string;
  fundingMethod: DrawdownFundingMethod;
  sellCurrencyCode: string;
  buyCurrencyCode: string;
  rate: number;
  id: string;
  beneficiary?: BeneficiaryDto;
  date?: string;
  purpose?: PaymentPurposeDto | string;
  reference?: string;
  emailAddresses: string[];
  singlePayment: boolean;
  paymentOrigin: PaymentOrigin;
  tradeId?: string; // For adding payment AFTER booking Trade
  payments: FormikPaymentType[];
};

export const firstPartyDrawdownInitialValues: FirstPartyDrawdownType = {
  tradeId: '',
  fixCurrencyCode: '',
  valueDate: '',
  amount: 0,
  fundingMethod: DrawdownFundingMethod.WIRE_TRANSFER,
  sellCurrencyCode: '',
  buyCurrencyCode: '',
  rate: 0,
  payments: [],
};

export const drawdownSubmissionInitialValues: DrawdownSubmissionRequest = {
  paymentIds: [],
  dynamicLinkingId: '',
};

export const paymentsInitialValues: FormikType = {
  payments: [],
};

export type FormikValueProps = FormikProps<FormikType>;
export type FormikType = {
  payments?: FormikPaymentType[];
};

const replaceCommaSeperator = (o: string, v: string) =>
  parseFloat(v ? v.replace(/,/g, '') : '');

export const validation = {
  amount: yup
    .number()
    .transform(replaceCommaSeperator)
    .typeError(t('please_enter_numbers_only'))
    .required(t('please_enter_a_valid_amount'))
    .positive(t('please_enter_a_positive_amount')),
  fixCurrencyCode: yup.string().required(t('please_select_a_currency')),
  valueDate: yup.string().required(t('please_specify_a_value_date')),
};

export const submissionformValidation = {
  paymentIds: yup.array().typeError(t('please_enter_an_id_array')),
};

export default validation;
