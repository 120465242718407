import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    drawer: {
      position: 'relative',
      minHeight: '100vh',
      '& .MuiDrawer-paper': {
        '& > button:first-of-type': {
          zIndex: 1,
        },
      },
    },
    container: {
      padding: '20px',
      minHeight: 'calc(100% - 55px)',
    },
    footer: {
      margin: '32px 0',
    },
    icon: {
      color: '#00B241',
      width: 26,
      height: 26,
      verticalAlign: 'middle',
    },
    heading: {
      fontSize: '22px',
      fontWeight: typography.fontWeightSemiBold,
      paddingLeft: '20px',
    },
  }),
  { name: 'CreateSpotDrawer' },
);

export default useStyles;
