import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  (theme) => ({
    greenText: {
      fontWeight: theme.typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    editButton: {
      fontSize: '10px',
      color: '#999999',
      padding: '6px',
    },
    sectionHeader: {
      fontSize: 16,
      weight: theme.typography.fontWeightSemiBold,
      marginBottom: '30px',
    },
    programName: {
      color: '#808080',
      marginLeft: '4px',
    },
  }),
  { name: 'HedgingSummary' },
);

export default useStyles;
