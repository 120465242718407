import React from 'react';

import { Box } from '@alpha/ui-lib/ui/external';

import BackdropLoader from '../Molecules/Loaders/BackdropLoader/BackdropLoader';

interface FullPageLoaderProps {
  testId: string;
}
const FullPageLoader: React.FC<FullPageLoaderProps> = ({
  testId,
}: FullPageLoaderProps) => (
  <Box width="100%" height="calc(100vh - 117px)" position="relative">
    <BackdropLoader testId={testId} />
  </Box>
);

export default FullPageLoader;
