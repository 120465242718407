import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  returnButton: {
    width: '150px ',
    height: '14px',
    margin: '-54px -50px 0px 80px',
    color: '#222222',
    opacity: '40%',
    fontSize: '12px',
  },
  uploadSummaryTitle: {
    fontSize: '18px',
    marginLeft: '84px',
    marginTop: '5px',
    color: '#333333',
    letterSpacing: '0px',
    paddingRight: '24px',
    lineHeight: '18px',
  },
  contentWrapper: {
    paddingTop: '12px',
    paddingBottom: '99px',
    backgroundColor: '#fafafa',
    minHeight: 'calc(100vh - 310px)',
    overflow: 'auto',
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  lineBreak: {
    color: '#707070',
    opacity: '10%',
  },
  statsContainers: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginTop: '24px',
    marginLeft: '84px',
    minWidth: '800px',
    overflow: 'auto',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  statValue: {
    fontSize: '18px',
    fontWeight: typography.fontWeightSemiBold,
  },
  batchSummaryStats: {
    marginRight: '20px',
  },
  verticalLine: {
    borderLeft: '1px solid #707070',
    height: '32px',
    marginRight: '16px',
    opacity: '10%',
  },
  tabs: {
    marginLeft: '84px',
  },
  titleContainer: {
    height: '55px',
  },
  passedIcon: {
    marginRight: '5px',
    color: '#88C165',
  },
  rejectedIcon: {
    marginRight: '5px',
    color: '#E77B7B',
  },
  invalidWarning: {
    marginLeft: '84px',
    marginTop: '33px',
    marginRight: '48px',
    minWidth: '800px',
  },
  alert: {
    padding: '19px 16px 19px 21px',
    marginLeft: '84px',
    marginBottom: 0,
    maxWidth: '1920px',
    '& .text': {
      color: 'rgb(217 0 25)!important',
    },
  },
}), { name: 'UploadSummary' });

export default useStyles;
