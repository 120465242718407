import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    container: {
      zIndex: '1302 !important',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.9)',
      position: 'relative',
      '& .MuiCircularProgress-root': {
        color: 'white',
      },
    },
    wrapper: {
      margin: '50px 0',
      textAlign: 'center',
      color: '#EAEAEA',
    },
    noOutline: {
      outline: 'none',
    },
    closeButton: {
      position: 'absolute',
      right: 20,
      top: 14,
      color: 'rgba(255,255,255,0.9)',
    },
  }),
  { name: 'Authy' },
);

export default useStyles;
