import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    root: {
      paddingTop: '12px',
      paddingBottom: '30px',
      backgroundColor: '#fafafa',
      minHeight: 'calc(100vh - 310px)',
    },
    statBox: {
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
      background: '#FFFFFF',
      border: '1px solid #E2E2E2',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '600px',
    },
    statBoxItem: {
      borderRight: '1px solid #E2E2E2',
      textAlign: 'left',
      padding: '20px',
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
    },
    statBoxItemName: {
      color: '#121315',
      fontSize: '16px',
    },
    statBoxTitle: {
      color: '#8D8D94',
      marginBottom: '8px',
    },
    tableContainer: {
      backgroundColor: '#ffffff',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
      padding: '20px',
      marginTop: '20px',
      minWidth: '600px',
      borderRadius: '10px',
    },
    summaryContainer: {
      backgroundColor: '#ffffff',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
      padding: '20px',
      marginTop: '20px',
      minWidth: '600px',
      borderRadius: '10px',
    },
  }),
  { name: 'HedgingPrograms' },
);

export default useStyles;
