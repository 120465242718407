import React from 'react';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import APModal from '../../../Modals/APModal/APModal';

import useStyles from './index.styles';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  firstName: string;
  lastName: string;
  leftTheBusiness: boolean;
}

const DisableAccessWarningModal: React.FC<Props> = (
  {
    open, handleSubmit, handleClose, firstName, lastName, leftTheBusiness,
  }: Props,
) => {

  const styles = useStyles();

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <Box padding="8px">
          <div style={{ marginBottom: '48px' }}>
            <Typography className={styles.modalTitle}>
              {t('are_you_sure_you_wish_to_revoke_access_for')}
              {' '}
              <p className={styles.userInfo}>
                {firstName}
                {' '}
                {lastName}
              </p>
              ?
            </Typography>
            <StyledDivider />
            {leftTheBusiness ? (
              <Typography className={styles.modalContent}>
                {t('by_selecting')}
                {' '}
                <strong>{t('left_the_business')}</strong>
                {' '}
                {t('the_following_persons_access_will_be_revoked1')}
              </Typography>
            ) : (
              <Typography className={styles.modalContent}>
                {t('by_selecting')}
                {' '}
                <strong>{t('user_no_longer_requires_access')}</strong>
                {' '}
                {t('the_following_persons_access_will_be_revoked2')}
              </Typography>
            )}
          </div>
          <StyledDivider />

          <div className={styles.buttonContainer}>
            <ActionButton style={{ backgroundColor: '#FAFAFA', borderRadius: '4px', color: '#212529' }} onClick={handleClose}>{t('cancel')}</ActionButton>
            <ActionButton style={{ backgroundColor: '#D55E5E', borderRadius: '4px' }} onClick={handleSubmit}>{t('revoke_access')}</ActionButton>
          </div>
        </Box>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default DisableAccessWarningModal;
