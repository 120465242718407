import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import { DrawdownFundingMethod, TradeDto } from '@alpha/fx-dtos';
import {
  Box, FormControlLabel, Radio,
  RadioGroup, Typography,
} from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import t from 'utils/translationHelper';

import Alert from '../../../../../components/Alert';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';

import ForwardContractSelectDropdown from './ForwardContractSelectDropdown/ForwardContractSelectDropdown';
import useStyles from './PaymentBatchFunding.styles';

interface IFundingOptionDrawdownProps {
  trades: TradeDto[];
  selectedForward: TradeDto | undefined;
  setSelectedForward: (value?: TradeDto) => void;
  selectedDrawdownFundingMethod: DrawdownFundingMethod;
  setSelectedDrawdownFundingMethod: (value: DrawdownFundingMethod) => void;
  buyAmount: number;
}

const FundingOptionDrawdown: React.FC<IFundingOptionDrawdownProps> = (
  {
    trades,
    selectedForward,
    setSelectedForward,
    selectedDrawdownFundingMethod,
    setSelectedDrawdownFundingMethod,
    buyAmount,
  }: IFundingOptionDrawdownProps,
) => {

  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const accountConfigQuery = useQuery('getAccountConfig', () => FXTradeService.getAccountConfigurationWithCcys(
    selectedForward?.soldCurrencyCode || '',
    selectedForward?.buyCurrencyCode || '',
  ), {
    enabled: false,
  });

  useEffect(() => {
    if (selectedForward) {
      accountConfigQuery.refetch().catch((error) => {
        sb.trigger(error?.message || `${t('error_loading_account_configurations')}.`);
        logError({ action: 'Error loading account configuration', error });
      });
    }
  }, [selectedForward]);

  const insufficientDrawdownFund = (selectedForward && selectedForward.buyBalance < buyAmount);

  return (
    <Box className={styles.radioButtonsContainer}>
      <div className={styles.boxHeading}>
        <Typography className={styles.boxHeaderText}>
          {t('fund_via_drawdown')}
        </Typography>
      </div>
      <Typography className={styles.boxText}>
        {t('please_select_an_eligible_forward_contract_to_drawdown_from')}
      </Typography>
      <Typography className={styles.boxText} style={{ marginTop: '30px', marginBottom: '10px' }}>
        {t('contract')}
      </Typography>
      <ForwardContractSelectDropdown
        data={trades.map((t) => ({
          id: t.id,
          contractNumber: t.contractNumber,
          fundingCurrency: t.soldCurrencyCode,
          buyCurrency: t.buyCurrencyCode,
          buyBalance: t.buyBalance,
        }))}
        isLoading={false}
        handleChange={(e) => setSelectedForward(trades.find((item) => item.id === e.target.value))}
        selectValue={selectedForward?.id}
      />

      {selectedForward && (
        <>
          <Typography style={{ marginTop: '48px', fontWeight: '600' }}>{t('drawdown_funding_method')}</Typography>
          <Typography style={{ marginTop: '16px' }}>{t('please_select_how_you_would_like_to_fund_this_drawdown')}</Typography>

          {accountConfigQuery.isFetching ? <Loader style={{ marginTop: '20px' }} testId="loading-drawdown-funding-method" />
            : (
              <RadioGroup
                row
                value={selectedDrawdownFundingMethod}
                onChange={(e, value) => {
                  setSelectedDrawdownFundingMethod(
                    value as DrawdownFundingMethod,
                  );
                }}
                style={{ flexDirection: 'column', marginTop: '20px' }}
              >
                <FormControlLabel value={DrawdownFundingMethod.CURRENCY_ACCOUNT_BALANCE} control={<Radio color="primary" />} label={t('use_funds_on_account')} data-testid="funds-on-account-drawdown" />
                <FormControlLabel value={DrawdownFundingMethod.WIRE_TRANSFER} control={<Radio color="primary" />} label={t('wire_transfer_funds')} data-testid="wire-transfer-drawdown" />
                {accountConfigQuery.data?.padAllowed && accountConfigQuery.data?.padEligible
                  && <FormControlLabel value={DrawdownFundingMethod.PAD} control={<Radio color="primary" />} label={t('settle_with_pad')} data-testid="pad-drawdown" />}
              </RadioGroup>
            )}
        </>
      )}

      {insufficientDrawdownFund
        && (
          <div style={{ marginTop: '16px' }}>
            <Alert
              variant="warning"
              text={t('insufficient_funds')}
              subText={t('weve_noticed_that_there_are_insufficient_funds_for_the_drawdown_you_have_selected')}
            />
          </div>
        )}
    </Box>
  );
};

export default FundingOptionDrawdown;
