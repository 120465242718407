import React from 'react';

import { Stat } from '@alpha/ui-lib/ui/Stat';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import InverseFxRateTooltip from '../../../../../components/Molecules/InverseFxRateTooltip/InverseFxRateTooltip';
import { IBatchCurrencyPairDecorated } from '../../BatchSummary.interface';

import useStyles from './Rate.styles';

interface IProps {
  currencyPairTab: IBatchCurrencyPairDecorated
}

const Rate = (props: IProps) => {

  const classes = useStyles();
  const {
    currencyPairTab,
  } = props;
  return (
    <div className={classes.rateContainer}>
      <Stat
        testId="total-payments-stat"
        title={(
          <InverseFxRateTooltip
            text={(
              <div className={classes.rateWrapper}>
                <span>{t('rate')}</span>
                <FontAwesomeIcon className={classes.rateIcon} icon={faInfoCircle as IconProp} />
              </div>
            )}
          />
        )}
        value={(
          <div className={classes.rateValues}>
            <b>{currencyPairTab.rate ? currencyPairTab.rate.toFixed(4) : '-'}</b>
            <span
              className={classes.counterRate}
            >
              {currencyPairTab.rate ? (1 / currencyPairTab.rate).toFixed(4) : '-'}
            </span>
          </div>
        )}
      />
    </div>
  );
};

export default Rate;
