import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    drawer: {
      margin: '40px 40px 0px 40px',
      display: 'flex',
      flexDirection: 'row',
      gridGap: 10,
    },
    header: {
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '30px',
      color: '#212529',
    },
    subHeader: {
      color: '#949494',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '19px',

    },
  }),
  { name: 'TermsLetterDrawer' },
);

export default useStyles;
