import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 8px !important',
  },
  modalHeader: {
    padding: '12px 24px 24px 24px',
    fontWeight: 800,
    fontSize: '18px',
  },
  inputContainer: {
    padding: '15px',
  },
  footer: {
    padding: '27px 10px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(112, 112, 112, .1)',
  },
  radioButtons: {
    paddingTop: '30px',
    fontWeight: 600,
    paddingBottom: '20px',
  },
  icons: {
    borderRadius: '4px',
  },
  labels: {
    padding: '8px',
  },
}), { name: 'AuthMethodModal' });

export default useStyles;
