import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  container: {
    '& .MuiTextField-root > label': {
      color: '#212529',
      fontSize: '19px',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '25px',
    },
    '& .datePicker': {
      width: '100%',
    },
    '&  .MuiFormControl-fullWidth': {
      width: '100% !important',
    },
  },
  chip: {
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      border: 'none',
      backgroundColor: '#f5f5f5',
      padding: '10px 30px 5px 15px',
      borderRadius: '4px',
      marginTop: '6px',
    },
    '& .MuiFormHelperText-root': {
      position: 'relative',
      left: '-13px',
      top: '3px',
    },
  },
  card: {
    color: '#CF752F',
    backgroundColor: '#CF752F1A',
    borderRadius: '10px',
    textAlign: 'center',
    paddingTop: '1px',
    paddingBottom: '24px'
  },
  warningIcon: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '60px',
    fontWeight: 400,
    marginTop: '24px',
  },
  header: {
    fontWeight: 600,
    fontSize: '16px',
    marginTop: '8px',
  },
  subHeader: {
    fontWeight: 400,
  },
  missingFields: {
    fontWeight: 600,
    marginLeft: '4px',
  },
  missingInfoTest: {
    fontWeight: 400,
    marginTop: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  alert: {
    marginTop: '10px',
    width: '100%',
    '& .text': {
      color: '#3E8CC1 !important',
      fontWeight: 400,
    }
  },
}), { name: 'createpaymentdrawer' });

export default useStyles;
