import { Dispatch, SetStateAction, useState } from 'react';
import t from 'utils/translationHelper';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';

import routes from '../routes.path';
import AuthService from '../services/Auth/auth.service';
import history from '../services/history/browserHistory';

const initialValueSendEmail = {
  username: '',
};

const initialValueResetPassword = {
  verificationCode: '',
  password: '',
  confirmPassword: '',
};

const validationSendEmail = yup.object({
  username: yup.string().required(t('username_is_required')),
});

const validationSubmit = yup.object({
  verificationCode: yup.string().required(t('verification_code_is_required')),
  password: yup.string().required(t('password_is_required')),
});

const usePasswordReset = (
  username?: string,
  setUsername?: Dispatch<SetStateAction<string | undefined>>,
  setEmail?: Dispatch<SetStateAction<string | undefined>>,
) => {
  const snackbar = originalUseSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handleResetPasswordEmail = async (submittedUsername: string, callback: Function) => {
    try {
      setLoading(true);
      if (!setUsername || !setEmail) throw Error();
      const response = await AuthService.forgotPassword(submittedUsername);
      callback(`${t('verification_code_sent_to')} ${response.CodeDeliveryDetails.Destination}`, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
      setUsername(submittedUsername);
      setEmail(response.CodeDeliveryDetails.Destination);
      history.push({
        pathname: routes.auth.passwordReset.verifyMfa,
        state: { submittedUsername },
      });
    } catch (e) {
      snackbar.enqueueSnackbar(t('there_was_an_error_resetting_password'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResetPasswordSubmit = async (verificationCode: string,
    submittedUsername: string, password: string) => {
    try {
      setLoading(true);
      await AuthService.forgotPasswordSubmit(submittedUsername, verificationCode, password);
      history.push(routes.auth.passwordReset.success);
    } catch (e: any) {
      const errorMessage = e?.response?.data?.error;
      snackbar.enqueueSnackbar(errorMessage ?? t('error_submitting_reset_password_request'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const sendEmailFormik = useFormik({
    initialValues: initialValueSendEmail,
    onSubmit: async (values) => {
      try {
        await handleResetPasswordEmail(values.username.trim(), snackbar.enqueueSnackbar);
      } catch (e) {
        let errorMessage: string = e.message;
        if (errorMessage.indexOf('validation') > -1) {
          errorMessage = t('invalid_username');
        }
        snackbar.enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
        });
      }
    },
    validationSchema: validationSendEmail,
  });

  const submitNewPassword = useFormik({
    initialValues: initialValueResetPassword,
    onSubmit: async (values) => {
      try {
        await handleResetPasswordSubmit(values.verificationCode, username!, values.password);
      } catch (e) {
        snackbar.enqueueSnackbar(e.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
        });
      }
    },
    validationSchema: validationSubmit,
  });

  return {
    sendEmailFormik,
    submitNewPassword,
    loading,
  };
};

export default usePasswordReset;
