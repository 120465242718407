/* eslint-disable max-lines-per-function */
import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      padding: '28px 1.125% 28px 28px',
      backgroundColor: '#FFFFFF',
      marginBottom: '12px',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
      borderRadius: '10px',
    },
    drawerContainer: {
      padding: '22px',
      marginBottom: '12px',
      border: '1px solid rgba(112, 112, 112, .1)',
      borderRadius: '5px',
      width: 'calc(100% - 30px)',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
    },
    drawerTitle: {
      display: 'flex',
      flexDirection: 'column',
    },
    greyPad: {
      color: '#333333',
      backgroundColor: 'rgba(51, 51, 51, .2)',
      height: '21px',
      width: '21px',
      lineHeight: '21px',
      textAlign: 'center',
      fontSize: '11px',
      fontWeight: 600,
      borderRadius: '5px',
    },
    greyTitle: {
      marginLeft: '9px',
      fontSize: '16px',
      color: '#363636',
      fontWeight: 600,
      lineHeight: '21px',
    },
    greySubtitle: {
      color: '#999999',
      fontSize: '13px',
      fontWeight: 400,
      marginTop: '9px',
    },
    titleAndButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    authoriseBtnContainer: {
      justifyContent: 'flex-end',
      flexDirection: 'column',
      display: 'flex',
      marginLeft: 'auto',
    },
    drawerBtnContainer: {
      justifyContent: 'flex-end',
      flexDirection: 'column',
      display: 'flex',
      marginBottom: '68px',
      marginLeft: '20px',
    },
    authoriseBtn: {
      backgroundColor: '#1E8777',
      color: '#FFFFFF',
      width: '148px',
      marginBottom: '10px',
      fontWeight: 500,
      '&:hover': {
        background: '#1E8777',
        color: '#FFFFFF',
      },
      '&[disabled]': {
        color: '#FFFFFF',
        background: '#ccc',
      },
    },
    titlesContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonText: {
      marginLeft: '6px',
    },
    alertContainer: {
      marginTop: '20px',
    },
    greenPad: {
      color: '#00A09B',
      backgroundColor: 'rgba(0, 160, 155, .2)',
      height: '21px',
      width: '21px',
      lineHeight: '21px',
      textAlign: 'center',
      fontSize: '11px',
      fontWeight: 600,
      borderRadius: '5px',
    },
    greenTitle: {
      marginLeft: '9px',
      fontSize: '16px',
      color: '#1E8777',
      fontWeight: 600,
      lineHeight: '21px',
    },
    greenContainer: {
      backgroundColor: 'rgba(9, 131, 117, .05)',
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
      borderRadius: '10px',
    },
    drawerGreenContainer: {
      backgroundColor: 'rgba(9, 131, 117, .05)',
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 'calc(100% - 30px)',
    },
    greenSubtitle: {
      color: '#1E8777',
      fontSize: '13px',
      marginLeft: '30px',
    },
    approverContainer: {
      borderLeft: '1px solid rgba(112, 112, 112, .1)',
      padding: '28px',
      flex: '1 1 30%',
    },
    drawerApproverContainer: {
      borderLeft: '1px solid rgba(112, 112, 112, .1)',
      padding: '22px',
      flex: '1 1 30%',
    },
    flexTitlesContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
      marginTop: '3px',
      minWidth: '500px',
    },
    drawerFlexTitles: {
      display: 'flex',
      flexDirection: 'column',
      padding: '22px',
      flex: '1 1 30%',
    },
    approverGreen: {
      fontSize: '16px',
      color: '#1E8777',
      fontWeight: 500,
      lineHeight: '21px',
    },
    approverInfo: {
      color: '#1E8777',
      fontSize: '13px',
    },
    collected: {
      backgroundColor: 'rgba(85, 177, 113, .2)',
      color: '#55B171',
      fontSize: '10px',
      textTransform: 'uppercase',
      marginLeft: '17px',
      lineHeight: '21px',
      padding: '1px 16px',
      borderRadius: '5px',
      fontWeight: 600,
      letterSpacing: '1.5px',
    },
    drawerCollected: {
      backgroundColor: 'rgba(85, 177, 113, .2)',
      color: '#55B171',
      fontSize: '10px',
      textTransform: 'uppercase',
      lineHeight: '21px',
      padding: '1px 16px',
      borderRadius: '5px',
      fontWeight: 600,
      letterSpacing: '1.5px',
      width: '100px',
      margin: '5px 0px',
    },
    notCollectedContainer: {
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    },
    drawerNotCollectedContainer: {
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(112, 112, 112, .1)',
      borderRadius: '5px',
      width: 'calc(100% - 30px)',
    },
    redTitle: {
      marginLeft: '9px',
      fontSize: '16px',
      color: '#D55E5E',
      fontWeight: 500,
      lineHeight: '21px',
    },
    notCollectedSubtitle: {
      color: '#343434',
      fontSize: '13px',
      fontWeight: 400,
      marginTop: '9px',
      marginLeft: '30px',
    },
    notCollectedApproverInfo: {
      color: '#343434',
      fontSize: '13px',
      fontWeight: 400,
      marginTop: '6px',
    },
    notCollectedApproverTitle: {
      color: '#121315',
      fontSize: '16px',
      fontWeight: 600,
    },
    redPad: {
      color: '#D55E5E',
      backgroundColor: 'rgba(213, 94, 94, .2)',
      height: '21px',
      width: '21px',
      lineHeight: '21px',
      textAlign: 'center',
      fontSize: '11px',
      fontWeight: 600,
      borderRadius: '5px',
    },
    namePadding: {
      paddingRight: '8px',
    },
    chevron: {
      color: '#7E7E7E',
      backgroundColor: 'transparent',
      borderRadius: '5px',
      marginLeft: '0px',
      marginRight: '14px',
    },
    iconUp: {
      transform: 'rotate(180deg)',
      transition: 'all 0.1s linear',
    },
    iconRight: {
      transform: 'rotate(-90deg)',
      transition: 'all 0.1s linear',
    },
    iconDown: {
      transition: 'all 0.1s linear',
    },
    collapsedContainer: {
      borderLeft: 0,
    },
    authorisedInfo: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    dividerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    divider: {
      borderLeft: '0.5px solid #1E8777',
      height: '52px',
    },
  }), { name: 'PadEligible' },
);

export default useStyles;
