import AlphaLogo from 'assets/alphaGreenLogo.svg';
import { FormikProps } from 'formik';
import React, {
  useEffect, useState,
} from 'react';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapDrawdownFundingMethodToFriendlyName from 'utils/fxTrades/mapDrawdownFundingMethodToFriendlyName';
import t from 'utils/translationHelper';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import {
  DrawdownFundingMethod,
  DrawdownSubmissionRequest, DrawdownValidationDto, TradeDto,
} from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Typography } from '@alpha/ui-lib/ui/external';
import {
  StyledKeyValueTable,
  TKeyValue,
} from '@alpha/ui-lib/ui/table';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Section } from '..';
import { FirstPartyDrawdownType } from '../formData';

import useStyles from './index.styles';

type Props = {
  trade: TradeDto;
  drawdownRequestForm: FirstPartyDrawdownType;
  validatedDrawdown: DrawdownValidationDto | undefined;
  submissionForm: FormikProps<DrawdownSubmissionRequest> | undefined;
  handleFormSubmit: (e: any) => void;
  handleSetSection: (_section: Section) => void;
  sellCurrencyAccount: CurrencyAccountDto;
  buyCurrencyAccount: CurrencyAccountDto;
};

const FirstPartyDrawdownConfirmation: React.FC<Props> = ({
  trade,
  handleFormSubmit,
  handleSetSection,
  sellCurrencyAccount,
  buyCurrencyAccount,
  drawdownRequestForm,
  validatedDrawdown,
  submissionForm,
}) => {
  const classes = useStyles();
  const [drawdownData, setDrawdownData] = useState<TKeyValue[]>([]);
  const [fundingData, setFundingData] = useState<TKeyValue[]>([]);
  const [accountDetailData, setAccountDetailData] = useState<TKeyValue[]>([]);

  useEffect(() => {
    setDrawdownData([
      { key: t('trade_number'), value: trade.contractNumber || '-' },
      { key: t('sell_currency'), value: sellCurrencyAccount.currency ? `${sellCurrencyAccount.currencyCode}` : trade.soldCurrencyCode || '-', boldValue: true },
      {
        key: t('sell_amount'), value: validatedDrawdown?.sellAmount || 0, currency: trade.soldCurrencyCode, boldValue: true,
      },
      {
        key: t('rate'), value: formatNumber(trade.rate, 4) || '-', currency: '', boldValue: true,
      },
      { key: t('buy_currency'), value: buyCurrencyAccount?.currency ? `${buyCurrencyAccount?.currency}` : trade.buyCurrencyCode || '-', boldValue: true },
      {
        key: t('buy_amount'), value: validatedDrawdown?.buyAmount, currency: trade.buyCurrencyCode, boldValue: true,
      },
      { key: t('value_date'), value: formatIsoDate(drawdownRequestForm?.valueDate) || '-' },
    ]);

    const currentFundingData: TKeyValue[] = [
      { key: t('funding_method'), value: t(mapDrawdownFundingMethodToFriendlyName(drawdownRequestForm.fundingMethod)) },
      { key: t('margin_released'), value: validatedDrawdown?.marginReleased || 0, currency: validatedDrawdown?.initialMarginCurrencyCode },
    ];

    if (validatedDrawdown?.initialMarginCurrencyCode === drawdownRequestForm?.buyCurrencyCode) {
      currentFundingData.push({
        key: t('final_remitted_amount'),
        value: validatedDrawdown?.finalRemittedAmount || '-',
        currency: validatedDrawdown.initialMarginCurrencyCode,
      });
    } else {
      currentFundingData.push({
        key: t('final_sold_amount_due'),
        value: validatedDrawdown?.finalSoldAmountDue || '-',
        currency: drawdownRequestForm.sellCurrencyCode,
      });
    }

    setFundingData(currentFundingData);
  }, [drawdownRequestForm, validatedDrawdown]);

  const updateAccountDetail = (currencyAccount: CurrencyAccountDto) => {
    const currentAccountDetailData = [];
    currentAccountDetailData.push({ key: t('account_name'), value: currencyAccount.accountName || '-', boldValue: true });
    if (currencyAccount.accountNumber) { currentAccountDetailData.push({ key: t('account_number'), value: currencyAccount.accountNumber || '-', boldValue: true }); }
    if (currencyAccount.sortCode) { currentAccountDetailData.push({ key: t('national_bank_code'), value: currencyAccount.sortCode || '-', boldValue: true }); }
    if (currencyAccount.iban) { currentAccountDetailData.push({ key: t('iban'), value: currencyAccount.iban || '-', boldValue: true }); }
    if (currencyAccount.swift) { currentAccountDetailData.push({ key: 'SWIFT', value: currencyAccount.swift || '-', boldValue: true }); }
    if (currencyAccount.reference) { currentAccountDetailData.push({ key: t('reference'), value: currencyAccount.reference || '-', boldValue: true }); }

    setAccountDetailData(currentAccountDetailData);
  };

  useEffect(() => {
    updateAccountDetail(sellCurrencyAccount);
  }, [sellCurrencyAccount]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.drawdownDetailTable}>
          <StyledKeyValueTable
            testId="drawdown-data-table"
            data={drawdownData}
          />
        </div>

        <div className={classes.drawdownFundingTable}>
          <StyledKeyValueTable
            testId="drawdown-funding-table"
            data={fundingData}
          />
        </div>

        {drawdownRequestForm.fundingMethod === DrawdownFundingMethod.WIRE_TRANSFER && (
        <div className={classes.wireTransferGuide}>
          <Typography className={classes.subHeading2}>{t('wire_transfer_details')}</Typography>
          <span>
            {t('please_ensure_funds_are_remitted_to_alpha_bank')}
          </span>

          <Typography className={classes.subHeading2} component="div">
            <img
              alt="Alpha Account"
              src={AlphaLogo}
              style={{ verticalAlign: 'top', marginRight: '8px' }}
            />
            {' '}
            {`${sellCurrencyAccount.currencyCode} - ${sellCurrencyAccount.friendlyName || sellCurrencyAccount.accountName || ''}`}

            <div className={classes.accountDetailTable}>
              <StyledKeyValueTable
                testId="account-detail-table"
                data={accountDetailData}
              />
            </div>

          </Typography>
        </div>

        )}
      </div>
      <div style={{
        display: 'flex', flexDirection: 'row', padding: '30px 0 42px 0', justifyContent: 'space-between',
      }}
      >
        <div style={{
          position: 'absolute', transform: 'translateY(-42px)', width: '100%', left: 0,
        }}
        >
          <BaseDrawer.LineBreak />
        </div>
        <ActionButton
          style={{ background: '#F7F7F7', color: '#212529' }}
          onClick={() => {
            handleSetSection('Drawdown');
          }}
        >
          {t('back_to_drawdown')}
        </ActionButton>
        <ActionButton
          disabled={!submissionForm?.isValid || submissionForm?.isSubmitting}
          onClick={handleFormSubmit}
          size="medium"
        >
          <FontAwesomeIcon icon={faLock as IconProp} />
          {'  '}
          {t('submit_drawdown')}
        </ActionButton>
      </div>
    </>
  );
};

export default FirstPartyDrawdownConfirmation;
