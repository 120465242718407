import { DateTime } from 'luxon';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';

export type TTransferRequestForm = {
  debitingCurrencyAccountId: string,
  fundingCurrencyAccountId: string,
  instructedCurrency: string,
  valueDate: string,
  reference: string,
  instructedAmount: number,
  fundingCurrencyAccount: CurrencyAccountDto,
  debitingCurrencyAccount: CurrencyAccountDto,
  accountId?: string;
};

const date = new Date().toISOString();
const dateTime = DateTime.fromISO(date, { zone: 'Europe/London' }).toFormat('yyyy-MM-dd');

export const initialValues = {
  debitingCurrencyAccountId: '',
  fundingCurrencyAccountId: '',
  instructedCurrency: '',
  valueDate: dateTime,
  reference: '',
  instructedAmount: 0,
  fundingCurrencyAccount: {} as CurrencyAccountDto,
  debitingCurrencyAccount: {} as CurrencyAccountDto,
};

export type CurrencyAccountTransferRequest = {
  fundingCurrencyAccountId: string,
  debitingCurrencyAccountId: string,
  debitingAccountId: string,
  instructedAmount: number,
  instructedCurrencyCode: string,
  reference?: string,
  valueDate: string,
};
