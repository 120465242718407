import i18n from 'i18n/config';
import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { ProgramDto, ProgramPeriodDto } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import InputAndLabel from '../../../components/Inputs/Beneficiary/InputAndLabel';
import APModal from '../../../components/Modals/APModal/APModal';
import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useLog from '../../../hooks/useLog';
import HedgingProgramsService from '../../../services/HedgingPrograms/hedgingPrograms.service';

import useStyles from './EditForecastModal.styles';

interface IProps {
  program: ProgramDto | undefined;
  period: ProgramPeriodDto | undefined;
  handleClose?: () => void;
  open: boolean;
}

const EditForecastModal = ({
  program, period, handleClose, open,
}: IProps) => {
  const classes = useStyles();
  const { logEvent, logError } = useLog();
  const sb = useAlphaSnackbar();
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const validateInput = (amountValue: number): boolean => {
    if (amountValue < 0) {
      setError('Please input a positive amount.');
      return false;
    }
    setError(undefined);
    return true;
  };

  const submitUpdate = async (): Promise<void> => {
    if (validateInput(amount) && program && period) {
      try {
        setSubmitting(true);
        await HedgingProgramsService.putUpdatePeriodForecast({
          programId: program?.id,
          periodId: period?.id,
          forecastAmount: amount,
        });

        sb.trigger(t('new_forecast_amount_submitted'), 'success');
        logEvent({ action: 'Successfully submit forecast amount' });
      } catch (e) {
        sb.trigger(t('error_submitting_new_forecast'));
        logError({ action: 'Error submitting new forecast account', error: e });
      } finally {
        if (handleClose) {
          handleClose();
        }
        setSubmitting(false);
      }
    }
  };

  const handleAmountChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const amountValue = parseFloat(e.target.value.replace(/,/g, ''));
    setAmount(amountValue);
    validateInput(amountValue);
  };

  useEffect(() => {
    if (period) {
      setAmount(period.forecast);
    }
  }, [period]);

  let editForecastText = (
    <>
      {t('edit')}
      {' '}
      {period ? period.period : ''}
      {' '}
      {t('forecast')}
    </>
  );
  if (i18n.language === 'de') {
    editForecastText = (
      <>
        {period ? period.period : ''}
        {' '}
        {t('edit_forecast_quater')}
      </>
    );
  }

  if (i18n.language === 'fr') {
    editForecastText = (
      <>
        {t('edit_forecast_quater')}
        {' '}
        {period ? period.period : ''}
      </>
    );
  }

  return (
    <APModal.Container
      open={open}
      onClose={() => { if (handleClose) { handleClose(); } }}
    >
      <>
        <APModal.Header
          onClose={handleClose}
          showCloseButton={!submitting}
        >
          <Typography className={classes.modalHeader}>
            {editForecastText}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={classes.modalWrapper}>
          {submitting ? (
            <Box textAlign="center">
              <Loader
                testId="loader"
                size="50px"
                style={{ margin: 'auto', marginTop: '20px', marginBottom: '20px' }}
              />
              {t('submitting')}
              ...
            </Box>
          )
            : (
              <Box
                display="flex"
                flexDirection="column"
                alignContent="start-end"
                justifyContent="space-between"
                height={120}
              >
                <Box flexGrow={1}>
                  <InputAndLabel
                    label={t('amount')}
                    type="amount"
                    name="amount"
                    id="amount"
                    testId="foreastAmount"
                    onChange={handleAmountChange}
                    value={amount}
                    helperText={error || ''}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" marginTop="24px">
                  <ActionButton
                    style={{ background: '#F7F7F7', color: '#212529' }}
                    onClick={() => { if (handleClose) handleClose(); }}
                  >
                    {t('cancel')}
                  </ActionButton>
                  {' '}
                  <ActionButton
                    onClick={submitUpdate}
                    style={{ marginLeft: '6px', borderRadius: '5px' }}
                  >
                    {t('confirm')}
                  </ActionButton>
                </Box>
              </Box>
            )}
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default EditForecastModal;
