import { useEffect, useState } from 'react';
import moment from 'moment';

import { TDateRangePickerValue } from '@alpha/ui-lib/ui/DatePicker';

export interface IOptionTradesTableFilters {
    optionTradeStartDate: string | undefined,
    optionTradeEndDate: string | undefined,
    setOptionTradeStartDate: React.Dispatch<string | undefined>;
    setOptionTradeEndDate: React.Dispatch<string | undefined>;
    setDisableFilterBackdropClick: React.Dispatch<boolean>;
}

const useOptionTradesFilter = ({
  optionTradeStartDate,
  optionTradeEndDate,
  setOptionTradeStartDate,
  setOptionTradeEndDate,
  setDisableFilterBackdropClick,
}: IOptionTradesTableFilters) => {
  const [open, setOpen] = useState<null | HTMLElement>(null);
  // Values that user selected
  const [optionTradeDateFilter, setOptionTradeDateFilter] = useState<TDateRangePickerValue>();

  const clearAllFilters = () => {
    setOptionTradeStartDate('');
    setOptionTradeEndDate('');
    setOptionTradeDateFilter(undefined);
  };

  const optionTradeDateChanged = (fieldName: string, value: TDateRangePickerValue) => {
    setDisableFilterBackdropClick(false);
    setOptionTradeDateFilter(value);
  };

  useEffect(() => {
    setOptionTradeDateFilter([optionTradeStartDate ? moment(optionTradeStartDate) : null,
      optionTradeEndDate ? moment(optionTradeEndDate) : null]);
  }, [optionTradeStartDate, optionTradeEndDate]);

  useEffect(() => {
    const optionStartDate = moment(optionTradeStartDate, 'YYYY-MM-DD');
    const optionEndDate = moment(optionTradeEndDate, 'YYYY-MM-DD');
    setOptionTradeDateFilter([
      optionStartDate.isValid() ? optionStartDate : null,
      optionEndDate.isValid() ? optionEndDate : null]);
  }, [optionTradeStartDate, optionTradeEndDate]);

  const applyChanges = () => {
    if (optionTradeDateFilter) {
      setOptionTradeStartDate(optionTradeDateFilter[0]?.format('YYYY-MM-DD'));
      setOptionTradeEndDate(optionTradeDateFilter[1]?.format('YYYY-MM-DD'));
    }
  };

  return {
    open,
    setOpen,
    clearAllFilters,
    applyChanges,
    optionTradeDateChanged,
    optionTradeDateFilter,
  };
};

export default useOptionTradesFilter;
