import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { makeStyles } from '@alpha/ui-lib/theme';

import AlphaLogoWhite from '../../../assets/logo.svg';
import AuthLayout from '../../../components/Layout/Auth';
import { TSignIn } from '../../../models/auth';
import routes from '../../../routes.path';
import Login from '../Login';
import VerifyMfaHelperScreen from '../VerifyMfa/HelperScreen';
import { IAuthProps } from '..';

import InvalidSession from './InvalidSession';
import PostLogin from './PostLogin';

export interface IReturning {
  userObject: TSignIn;
}

const useStyles = makeStyles(() => ({
  leftContent: {
    backgroundColor: 'unset',
    paddingTop: 0,
    '& .alphaLogo': {
      marginBottom: '40px',
    },
  },
  rightContent: {
    padding: 0,
  },
}), { name: 'Returning' });

const Returning: React.FC<IAuthProps> = ({ userObject, userInfo }: IAuthProps) => {
  const styles = useStyles();

  return (
    <AuthLayout.Container>
      <AuthLayout.LeftContent className={styles.leftContent}>
        <img
          className="alphaLogo"
          src={AlphaLogoWhite}
          alt="Alpha Group Logo"
        />
        <Switch>
          <Route path={routes.invalidSession}>
            <InvalidSession />
          </Route>
          <Route path={routes.auth.login}>
            <Login />
          </Route>
          <PostLogin userObject={userObject} userInfo={userInfo} />
        </Switch>
      </AuthLayout.LeftContent>
      <AuthLayout.RightContent className={styles.rightContent}>
        <Route path={routes.auth.totp}>
          <VerifyMfaHelperScreen />
        </Route>
      </AuthLayout.RightContent>
    </AuthLayout.Container>
  );
};

export default Returning;
