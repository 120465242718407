import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ typography }) => ({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      color: '#818181',
      letterSpacing: -0.5,
      fontWeight: typography.fontWeightLight,
      '& > div': {
        margin: '0px 16px 0px 12px',
        minHeight: '36px',
      },
      '& > span.bold': {
        color: '#333333',
        fontWeight: typography.fontWeightSemiBold,
        margin: '0 5px 0 0',
      },
      '& > svg': {
        color: '#1E8777',
        height: '23px',
        width: '23px',
        margin: '0 11px 0 0',
      },
    },
  }),
  { name: 'BatchSummary' },
);

export default useStyles;
