import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      padding: '0 8px !important',
    },
    warningStyle: {
      width: '70px',
      marginBottom: '24px',
    },
    innerContainer: {
      textAlign: 'center',
      padding: '8px',
      width: '400px',
    },
    headerText: {
      fontWeight: 800,
      marginBottom: '16px',
      fontSize: '16px',
    },
    cancelBatchButton: {
      widht: '170px',
    },
    textContainer: {
      marginBottom: '48px',
    },
    buttonContainer: {
      width: '100%',
    },
  }),
  { name: 'CancelBatchModal' },
);

export default useStyles;
