import React from 'react';
import { FormikProps } from 'formik';
import i18n from 'i18n/config';
import t from 'utils/translationHelper';

import {
  QuoteResponse,
  TradeDto, TradeFundingMethod, TradeSubmissionDto,
} from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRotateForward, faLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from '../../../../../Alert';
import { TFxBookingState } from '../..';
import { TSpotFXRequestForm } from '../../formData';

interface IProps {
  form: FormikProps<TSpotFXRequestForm>;
  setValidatedTrade: React.Dispatch<React.SetStateAction<
    TradeSubmissionDto | TradeDto | undefined>>;
  setMfaVerified: React.Dispatch<React.SetStateAction<boolean>>;
  setLiveRate: React.Dispatch<React.SetStateAction<QuoteResponse | undefined>>;
  mfaVerified: boolean;
  handleValidateTrade: () => void;
  liveRateStatus: TFxBookingState;
  pageLoading: boolean;
  rateLoading: boolean;
  valueDateLoading: boolean;
  insufficientFunds: boolean;
  submittingDraft: boolean;
  handleBookTrade: () => void;
  quoteLiveRate: () => void;
  validatedTrade: TradeSubmissionDto | TradeDto | undefined;
  handleClearAll: () => void;
}

const Actions: React.FC<IProps> = ({
  form,
  setValidatedTrade,
  setMfaVerified,
  mfaVerified,
  handleValidateTrade,
  liveRateStatus,
  setLiveRate,
  insufficientFunds,
  submittingDraft,
  rateLoading,
  valueDateLoading,
  pageLoading,
  handleBookTrade,
  quoteLiveRate,
  validatedTrade,
  handleClearAll,
}: IProps) => (
  <>
    {!(validatedTrade && mfaVerified) ? (
      <Box style={{ width: '100%', padding: '10px' }} display="flex" gridGap={10}>
        <ActionButton
          style={{
            width: '20%',
            color: form.dirty ? '#D55E5E' : '#D55E5E33',
            backgroundColor: '#d55e5e1a',
          }}
          disabled={!form.dirty}
          onClick={handleClearAll}
        >
          {t('clear_all')}
        </ActionButton>
        <ActionButton
          onClick={handleValidateTrade}
          style={i18n.language === 'de' ? { width: '94%' } : { width: '100%' }}
          disabled={pageLoading || rateLoading || valueDateLoading || !form.isValid
              || (insufficientFunds
                && form.values.fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE)}
        >
          {!submittingDraft && (<FontAwesomeIcon icon={faLock as IconProp} />)}
          {' '}
          {submittingDraft ? t('submit_draft_trade') : t('request_live_rate')}
        </ActionButton>
      </Box>
    ) : (
      <>
        <Box style={{ padding: '4px 20px' }}>
          <Alert variant="warning" text={t('if_you_click_book_fx_you_enter_contract_with_alpha')} />
        </Box>
        <Box style={{ padding: '20px' }} gridGap={10} display="flex">
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              setValidatedTrade(undefined);
              setMfaVerified(false);
              setLiveRate(undefined);
            }}
            disabled={liveRateStatus === 'initiatedBookTrade'}
          >
            {t('cancel')}
          </ActionButton>
          {(liveRateStatus === 'success'
              || liveRateStatus === 'initiated'
              || liveRateStatus === 'initiatedBookTrade') ? (
                <ActionButton
                  style={{ flexGrow: 1 }}
                  onClick={handleBookTrade}
                  loading={liveRateStatus === 'initiatedBookTrade'}
                  disabled={liveRateStatus !== 'success'}
                >
                  <FontAwesomeIcon icon={faLock as IconProp} />
                  {' '}
                  {t('book_fx')}
                </ActionButton>
            ) : (
              <ActionButton
                style={{ flexGrow: 1 }}
                onClick={quoteLiveRate}
                loading={liveRateStatus === 'loading'}
                disabled={liveRateStatus === 'loading'}
              >
                <FontAwesomeIcon icon={faArrowRotateForward as IconProp} />
                {' '}
                {t('refresh_rate')}
              </ActionButton>
            )}
        </Box>
      </>
    )}
  </>
);

export default Actions;
