import React from 'react';
import moment from 'moment';

import { ExpiredPayment } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import t from 'utils/translationHelper';

import Warning from '../../../../assets/warning.svg';
import APModal from '../../../Modals/APModal/APModal';
import useStyles from '../RejectPaymentModal/index.styles';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleAcknowledge: () => void;
  expiredPayments: ExpiredPayment[];
}

const ValueDateChangedModal: React.FC<Props> = ({
  open, expiredPayments, handleClose, handleAcknowledge,
}: Props) => {

  const styles = useStyles();

  const columns: IStyledGenericTableProps['columns'] = [{
    header: t('friendly_id'),
    accessor: 'friendlyId',
    width: 200,
  },
  {
    header: t('value_date'),
    accessor: 'valueDate',
    width: 80,
    align: 'left',
  },
  {
    header: '',
    accessor: 'arrow',
    width: 30,
    align: 'left',
  },
  {
    header: t('earliest_settlement_date'),
    accessor: 'earliestSettlementDate',
    align: 'left',
  },
  ];

  const data: IStyledGenericTableProps['data'] = expiredPayments.map(
    (expiredPayment: ExpiredPayment) => ({
      friendlyId: expiredPayment.friendlyId || '-',
      valueDate: moment(expiredPayment.valueDate).format('DD/MM/yyyy') || '-',
      arrow: '\u279D',
      earliestSettlementDate: moment(expiredPayment.earliestSettlementDate).format('DD/MM/yyyy') || '-',
    }),
  );

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div style={{ textAlign: 'center', padding: '0 32px', width: '700px' }}>
          <img src={Warning} style={{ width: '70px', marginBottom: '24px' }} alt="Warning" />
          <div style={{ marginBottom: '48px' }}>
            <Typography style={{ fontWeight: '800', marginBottom: '16px', fontSize: '16px' }}>
              {t('please_note_that_your_original_payment_date_is_no_longer_available')}
            </Typography>
            <Typography>
              {t('the_payment_date_has_been_amended_to_the_next_earliest_payment_date_as_following')}:
            </Typography>
          </div>

          <div style={{ width: '500px', margin: 'auto' }}>
            <StyledGenericTable
              testId="expired-payments-table"
              columns={columns}
              data={data}
            />

          </div>

          <div style={{ width: '100%' }}>
            <ActionButton size="large" style={{ width: '250px', marginTop: '53px' }} onClick={handleAcknowledge}>{t('acknowledge')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default ValueDateChangedModal;
