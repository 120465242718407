import axios, { AxiosResponse } from 'axios';
import { IGetPresignedUrlInstanceParams, TBatchUploadLinkDto } from 'models/payments';

import { AccountDto, Approver } from '@alpha/auth-dtos';
import {
  ApproversNotifyRequest,
  BeneCopyRequestDto,
  BeneCopyResponseDto, BeneficiaryBatchDto, BeneficiaryBatchSummaryDto, BeneficiaryDto,
  BeneficiaryStatus,
  CountryRequirements, CurrencyList, ValidateBeneficiaryRequest, ValidateBeneficiaryResponse,
} from '@alpha/bene-dtos';
import { BatchUploadRequest } from '@alpha/payments-dtos';

import { FileType } from '../../hooks/useReportsPolling';
import {
  TBeneficiaryApproved,
  TBeneficiaryPending,
} from '../../models/beneficiaries';
import { TCountries } from '../../models/countries';
import { TReportGenerationResponse } from '../../models/currencyAccounts';
import instance from '../Axios/instance';

export class BeneficiariesService {
  public static async getCurrencies(): Promise<CurrencyList> {
    const response: AxiosResponse<CurrencyList> = await instance.get(
      '/bene/currencies',
    );
    return response.data;
  }

  public static async getCountries(): Promise<TCountries> {
    return this.getCountriesAsync();
  }

  public static async getPresignedUrl(
    batchUploadRequest: BatchUploadRequest,
  ): Promise<TBatchUploadLinkDto> {
    const requestParams: IGetPresignedUrlInstanceParams = {
      method: 'post',
      url: 'bene/batches',
      data: batchUploadRequest,
    };
    const response: AxiosResponse<TBatchUploadLinkDto> = await instance(
      requestParams,
    );
    return response.data;
  }

  public static async putBeneficiaryFile(
    presignedUrl: string,
    file: File,
  ): Promise<number> {
    return axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
  }

  public static async postValidatePaymentBene(
    beneficiaryValidateRequest: ValidateBeneficiaryRequest,
  ): Promise<BeneficiaryDto> {
    const response = await instance.post(
      '/bene/beneficiaries/validate',
      beneficiaryValidateRequest,
    );

    if (response.data.applyFinancialErrored
      && !response.data.userIgnoresApplyFinancialErrors) {
      return response.data;
    }

    if (response.status !== 200) {
      throw Error('Something went wrong');
    }

    if (response.data.errors?.length) {
      const errorString = response.data.errors.join('\r\n');
      throw Error(errorString);
    }

    return response.data;
  }

  public static async getAllManualPaymentBeneficiaries(
    searchParams?: string,
    filterCurrency?: string,
  ): Promise<BeneficiaryDto[]> {
    return this.getAllManualPaymentBeneficiariesAsync(
      searchParams,
      filterCurrency,
    );
  }

  private static async getAllManualPaymentBeneficiariesAsync(
    searchText?: string,
    filterCurrency?: string,
  ): Promise<BeneficiaryDto[]> {
    const searchParamsQuery = searchText && `searchtext=${searchText}`;
    const filterCurrencyQuery = filterCurrency && `currencycode=${filterCurrency}`;

    let url = '/bene/beneficiaries?skip=0&take=100&status=CLIENT_APPROVED';

    if (searchParamsQuery) {
      url = [url, searchParamsQuery].join('&');
    }

    if (filterCurrencyQuery) {
      url = [url, filterCurrencyQuery].join('&');
    }

    const response = await instance.get(url);
    return response.data.records;
  }

  public static async getCountryRequirements(
    countryCurrency: { countryCode: string, currencyCode: string }[],
  ): Promise<CountryRequirements[]> {
    return this.getCountryRequirementsAsync(countryCurrency);
  }

  public static async validateBeneficiary(
    beneficiaryCreateRequest: ValidateBeneficiaryRequest,
  ): Promise<ValidateBeneficiaryResponse> {
    const response: AxiosResponse<ValidateBeneficiaryResponse> = await instance.post(
      '/bene/beneficiaries/validate',
      beneficiaryCreateRequest,
    );
    return response.data;
  }

  public static async checkBeneStatus(
    batchId: string,
  ): Promise<BeneficiaryDto[]> {
    const response: AxiosResponse<BeneficiaryDto[]> = await instance.get(
      `/bene/batches/${batchId}`,
    );
    return response.data;
  }

  public static async releaseBeneficiary(batchId: string): Promise<void> {
    return this.releaseBeneficiaryAsync(batchId);
  }

  public static async rejectBeneficiary(
    beneficiary: TBeneficiaryApproved | TBeneficiaryPending,
  ): Promise<void> {
    return this.rejectBeneficiaryAsync(beneficiary);
  }

  public static async deleteBeneficiary(
    beneficiary: TBeneficiaryApproved,
  ): Promise<void> {
    return this.deleteBeneficiaryAsync(beneficiary);
  }

  public static async getIndividualBeneficiary(accountBeneId: string) {
    return this.getIndividualBeneficiaryAsync(accountBeneId);
  }

  public static async getAvailableAccountsToShareBeneficiary() {
    const response: AxiosResponse<AccountDto[]> = await instance.get(
      '/bene/copy/available-accounts',
    );
    return response.data;
  }

  public static async shareBeneficiaryWithSelectedAccounts(
    shareAccountsRequest: BeneCopyRequestDto,
  ): Promise<BeneCopyResponseDto> {
    const response = await instance.post('/bene/copy', shareAccountsRequest);
    return response.data;
  }

  public static async approveBeneficiaryShareRequest(
    bachId: string,
    totp: number,
    softToken: boolean,
  ): Promise<void> {
    const response = await instance.post(`/bene/copy/${bachId}/approve`, { totp, softToken });

    return response.data;
  }

  private static async getCountriesAsync(): Promise<TCountries> {
    const response: AxiosResponse<TCountries> = await instance.get(
      '/bene/countries',
    );
    return response.data;
  }

  private static async getCountryRequirementsAsync(
    countryCurrency: { countryCode: string, currencyCode: string }[],
  ): Promise<CountryRequirements[]> {
    const response: AxiosResponse<CountryRequirements[]> = await instance.post(
      '/bene/country-requirements',
      countryCurrency,
    );
    return response.data;
  }

  private static async releaseBeneficiaryAsync(batchId: string): Promise<void> {
    await instance.post('/bene/batches/released', {
      batchId,
    });
  }

  private static async rejectBeneficiaryAsync(
    beneficiary: TBeneficiaryApproved | TBeneficiaryPending,
  ): Promise<void> {
    await instance.post('/bene/beneficiaries/reject', {
      beneficiaryIds: [beneficiary.id],
    });
  }

  private static async deleteBeneficiaryAsync(
    beneficiary: TBeneficiaryApproved,
  ): Promise<void> {
    await instance.delete(`/bene/beneficiaries/${beneficiary.id}`);
  }

  private static async getIndividualBeneficiaryAsync(
    accountBeneId: string,
  ): Promise<BeneficiaryDto> {
    const response: AxiosResponse<BeneficiaryDto> = await instance.get(
      `/bene/beneficiaries/${accountBeneId}`,
    );

    return response.data;
  }

  public static async hardDeleteBeneficiary(
    id: string,
  ): Promise<number> {
    const response = await instance.delete(`/bene/batches/beneficiaries/${id}?hardDelete=true`);
    return response.data;
  }

  public static async deleteBatchBeneficiary(
    id: string,
  ): Promise<number> {
    const response = await instance.delete(`/bene/batches/${id}/`);
    return response.data;
  }

  public static async submitBeneficiaryBatch(
    id: string,
  ): Promise<number> {
    const response = await instance.post(`/bene/batches/${id}/submit`);
    return response.data;
  }

  public static async getBatchBeneFileUrl(
    batchId: string,
  ): Promise<string> {
    const response: AxiosResponse<{ url: string }> = await instance.get(`/bene/batches/${batchId}/file`);
    return response.data.url;
  }

  public static async getBeneficiaryApprovers(
    id: string,
  ): Promise<Approver[]> {
    return this.getBeneficiaryApproversAsync(id);
  }

  private static async getBeneficiaryApproversAsync(
    id: string,
  ): Promise<Approver[]> {
    const response: AxiosResponse<Approver[]> = await instance.get(`/bene/beneficiaries/${id}/approvers`);

    return response.data;
  }

  public static async getAllBatches(): Promise<BeneficiaryBatchDto[]> {
    const response = await instance.get(
      '/bene/beneficiaries/batches',
    );
    return response.data;
  }

  public static async getBatchBeneSummary(
    batchId: string,
  ): Promise<BeneficiaryBatchSummaryDto> {
    const response: AxiosResponse<BeneficiaryBatchSummaryDto> = await instance.get(`/bene/beneficiaries/batches/${batchId}`);
    return response.data;
  }

  public static async postApproverEmails(
    id: string,
    approvers: ApproversNotifyRequest,
  ): Promise<number> {
    return this.postApproverEmailsAsync(id, approvers);
  }

  public static async putUpdateBatchBeneStatus(
    batchId: string,
    beneId: string,
    status: BeneficiaryStatus,
  ): Promise<number> {
    const response: AxiosResponse<number> = await instance.put(`/bene/batches/${batchId}/beneficiaries/${beneId}/status`, { status });
    return response.status;
  }

  private static async postApproverEmailsAsync(
    id: string,
    approvers: ApproversNotifyRequest,
  ): Promise<number> {
    const response: AxiosResponse<number> = await instance.post(`/bene/beneficiaries/${id}/approvers/notify`, approvers);
    return response.status;
  }

  public static async postBeneficiariesGeneration(
    type: FileType,
  ): Promise<string> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/beneficiaries?type=${type}`,
    );
    return response.data?.executionArn;
  }

  public static async putUpdateBeneficiary(
    beneId: string,
    beneficiary: BeneficiaryDto,
  ): Promise<any> {
    const response: AxiosResponse<number> = await instance.put(`/bene/beneficiaries/${beneId}`, beneficiary);
    return response.status;
  }

  public static async getPendingApprovalBeneficiaries(
  ): Promise<{ total: number, records: BeneficiaryDto[] }> {
    const params = {
      skip: 0,
      take: 5,
      sortby: 'uploadedByDate',
      sortorder: 'desc',
      status: [BeneficiaryStatus.SUBMITTED, BeneficiaryStatus.REQUESTED, BeneficiaryStatus.UPLOADED].join(','),
    };
    const response: AxiosResponse<{
      total: number,
      records: BeneficiaryDto[]
    }> = await instance({
      method: 'GET',
      url: '/bene/beneficiaries',
      params,
    });
    return response.data;
  }
}

export const getIndividualBeneficiary = async (accountBeneId: string): Promise<BeneficiaryDto> => {
  const response: AxiosResponse<BeneficiaryDto> = await instance.get(
    `/bene/beneficiaries/${accountBeneId}`,
  );

  return response.data;
};

export default BeneficiariesService;
