import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import t from 'utils/translationHelper';

import { AllowedCurrenciesDto } from '@alpha/fx-dtos';
import { Box, Typography } from '@alpha/ui-lib/ui/external';

import Alert from '../../../../../components/Alert';
import AutocompleteDropDown, { IOption } from '../../../../../components/AutocompleteDropDown/AutocompleteDropDown';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';
import PaymentsService from '../../../../../services/Payments/payments.service';
import { formatCurrency } from '../../../../../utils/currency.helpers';

import useStyles from './PaymentBatchFunding.styles';

type IProps = {
  buyCurrencyCode: string | undefined;
  buyAmount: number;
  allowedCurrencies: AllowedCurrenciesDto | undefined;
  fxFundingCurrencyCode: string | undefined;
  isValidFxLimit: boolean;
  setIsValidFxLimit: (isValid: boolean) => void;
  setFxFundingCurrencyCode?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const FundingOptionFx: FC<IProps> = ({
  buyCurrencyCode,
  buyAmount,
  allowedCurrencies,
  fxFundingCurrencyCode,
  isValidFxLimit,
  setIsValidFxLimit,
  setFxFundingCurrencyCode,
}: IProps) => {

  const styles = useStyles();
  const { logError } = useLog();

  const [currencyPair, setCurrencyPair] = useState('EUR/GBP');
  const [valueDate, setValueDate] = useState('TBD');

  const [sellCurrencyTextFieldValue, setSellCurrencyTextFieldValue] = useState<string>('');

  const [
    currentSellCurrencyOption,
    setCurrentSellCurrencyOption,
  ] = useState<IOption>({ name: '', code: '' });

  const [
    allowedSellCurrenciesOption,
    setAllowedSellCurrenciesOption,
  ] = useState<IOption[]>([]);

  const sb = useAlphaSnackbar();

  const accountConfigQuery = useQuery('getAccountConfig', () => FXTradeService.getAccountConfigurationWithCcys(
    fxFundingCurrencyCode || '',
    buyCurrencyCode || '',
  ), {
    enabled: false,
    onError: (error) => {
      sb.trigger(t('cannot_load_spot_information'), 'error');
      logError({ action: 'Error loading spot information', error });
    },
  });

  const updateAvailableCurriencies = async (): Promise<void> => {
    try {
      if (allowedCurrencies) {
        setAllowedSellCurrenciesOption(buyCurrencyCode ? allowedCurrencies.sellAllowed.filter(
          (item) => item.code !== buyCurrencyCode,
        ) : allowedCurrencies.sellAllowed);
      }
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_available_currencies'), 'error');
      logError({ action: 'Error loading available currencies', error });
    }
  };

  const getPaymentValueDate = async (buyCcy: string, sellCcy: string) => {
    try {
      const paymentDateOption = await PaymentsService.getAvailablePaymentDate(
        sellCcy,
        buyCcy,
      );

      setValueDate(paymentDateOption.earliestDate);
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_value_date'), 'error');
      logError({ action: 'Error loading value date', error });
    }
  };

  const updateSpotLimit = () => {
    if (buyAmount
      && accountConfigQuery.data?.minimumFX?.buyAmount
      && accountConfigQuery.data?.maximumFX?.buyAmount) {
      setIsValidFxLimit(buyAmount >= (accountConfigQuery.data?.minimumFX?.buyAmount || 0)
        && buyAmount <= accountConfigQuery.data?.maximumFX?.buyAmount);
    }
  };

  useEffect(() => {
    updateAvailableCurriencies();
  }, [allowedCurrencies]);

  useEffect(() => {
    if (fxFundingCurrencyCode && buyCurrencyCode) {
      setCurrencyPair(`${fxFundingCurrencyCode}/${buyCurrencyCode}`);
      setValueDate('');
      getPaymentValueDate(buyCurrencyCode, fxFundingCurrencyCode);
      accountConfigQuery.refetch();
    }
  }, [buyCurrencyCode, fxFundingCurrencyCode]);

  useEffect(() => {
    updateSpotLimit();
  }, [accountConfigQuery.data?.maximumFX.buyAmount,
  accountConfigQuery.data?.minimumFX.buyAmount]);

  const minLimitStr = formatCurrency(
    buyCurrencyCode,
    accountConfigQuery.data?.minimumFX?.buyAmount,
  );
  const maxLimitStr = formatCurrency(
    buyCurrencyCode,
    accountConfigQuery.data?.maximumFX.buyAmount,
  );

  return (
    <Box className={styles.radioButtonsContainer}>
      <div className={styles.boxHeading}>
        <Typography className={styles.boxHeaderText}>
          {t('fund_via_fx')}
        </Typography>
      </div>

      <>
        <Typography className={styles.boxText}>
          {t('a_draft_trade_will_be_set_up_on_submissing_of_the_batch')}
        </Typography>

        <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>{t('sell_currency')}</Typography>
        <Typography className={styles.boxText}>
          <AutocompleteDropDown
            name="sellCurrencyCode"
            testId="sellCurrencyDropdown"
            type="CODE-NAME"
            options={allowedSellCurrenciesOption}
            currentOption={currentSellCurrencyOption}
            setFieldValue={(name: string, value?: string) => {
              if (setFxFundingCurrencyCode) {
                setFxFundingCurrencyCode(value);
              }
            }}
            value={fxFundingCurrencyCode}
            setTextFieldValue={setSellCurrencyTextFieldValue}
            textFieldValue={sellCurrencyTextFieldValue}
            setCurrentOption={setCurrentSellCurrencyOption}
            placeholder={t('select_sell_currency')}
          />
        </Typography>
        {isValidFxLimit && fxFundingCurrencyCode
          && (
            <Alert
              text={`${t('draft_${currencyPair}_trade_will_be_scheduled_with_BUY_AMOUNT_for1')} ${currencyPair} ${t('draft_${currencyPair}_trade_will_be_scheduled_with_BUY_AMOUNT_for2')} ${formatCurrency(buyCurrencyCode, buyAmount)} 
          ${t('and_value_date_at')} ${valueDate
                  ? moment(valueDate).format('DD/MM/yyyy')
                  : '...'}`}
              variant="info"
            />
          )}

        {!isValidFxLimit && fxFundingCurrencyCode
          && <Alert text={`${t('please_note_that_the_buy_amount_is_not_within_your_fx_limit')} ${minLimitStr} - ${maxLimitStr}. ${t('please_select_a_different_funding_option')}`} variant="error" />}
      </>

    </Box>
  );
};

export default FundingOptionFx;
