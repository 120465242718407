import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Button } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import FooterWrapper from '../../../../../../components/Payments/FooterWrapper/FooterWrapper';

interface IProps {
  handleBookFxClick: () => void;
  handleClick: () => void;
}

const BookFxFooter: React.FC<IProps> = ({ handleClick, handleBookFxClick }: IProps) => {



  return (
    <FooterWrapper>
      <Button
        onClick={handleClick}
        startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
      >
        {t('exit')}
      </Button>
      <ActionButton size="large" onClick={handleBookFxClick}>BOOK FX</ActionButton>
    </FooterWrapper>
  );
}
export default BookFxFooter;
