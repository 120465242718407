import React, { memo } from 'react';

import { Flag } from '@alpha/ui-lib/ui/Flag';

import { useStyles } from './CurrencyPair.styles';

interface IProps {
  currencyCode: string;
  displayCode?: boolean;
  size?: string;
}

const CurrencyPair: React.FC<IProps> = (props: IProps) => {
  const { currencyCode, displayCode = true, size = 'sm' } = props;
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <Flag code={currencyCode} showCodeLabel={displayCode} labelLeft={false} size={size} />
    </span>
  );
};

export default memo(CurrencyPair);
