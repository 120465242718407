import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      '& > .MuiPaper-root': {
        width: '65%',
        maxWidth: '800px',
      },
      '& > div > div:first-of-type': {
        paddingTop: '70px',
      },
      '& .stepper': {
        '& h6': {
          color: '#a0a0a0',
          fontSize: '16px',
          marginTop: '16px',
        },
        '& .active': {
          color: 'black',
        },
      },
    },
  }),
  { name: 'ApprovalDrawer' },
);

export default useStyles;
