import React from 'react';

import { UserAccountDto } from '@alpha/auth-dtos';

import DropDownWrapper from './DropDownWrapper/DropDownWrapper';

interface IProps {
  loading: boolean;
  entities: UserAccountDto[],
  menuCloseCount: number,
  setMenuCloseCount: React.Dispatch<React.SetStateAction<number>>
  currentOptions: UserAccountDto[],
  setCurrentOptions: React.Dispatch<React.SetStateAction<UserAccountDto[]>>,
  setTextValue: React.Dispatch<React.SetStateAction<string>>,
  id?: string,
}

const MultiSelectEntities = ({
  id,
  entities,
  loading,
  menuCloseCount,
  setMenuCloseCount,
  currentOptions,
  setCurrentOptions,
  setTextValue,
}: IProps) => (
  <>
    <DropDownWrapper
      setMenuCloseCount={setMenuCloseCount}
      menuCloseCount={menuCloseCount}
      loading={loading}
      eligibleEntities={entities}
      setTextValue={setTextValue}
      currentOptions={currentOptions}
      setCurrentOptions={setCurrentOptions}
      id={id}
    />

  </>
);

export default MultiSelectEntities;
