import { TradeType } from '@alpha/fx-dtos';

const mapTradeTypeToFriendlyName = (tradeType: TradeType): string => {
  switch (tradeType) {
    case TradeType.SPOT:
      return 'Spot';
    case TradeType.SPOT_OPTION:
      return 'Spot Option';
    case TradeType.FORWARD_FIXED:
      return 'Forward Fixed';
    case TradeType.FORWARD_OPEN:
      return 'Forward Open';
    case TradeType.FORWARD_WINDOW:
      return 'Forward Window';
    case TradeType.FORWARD_VARIABLE:
      return 'Forward Variable';
    case TradeType.MARKET_ORDER:
      return 'Market Order';
    default:
      return 'N/A';
  }
};

export default mapTradeTypeToFriendlyName;
