import React, { useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import BackdropLoader from 'components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable from 'components/Table/EmptyTable';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import t from 'utils/translationHelper';

import { ProgramDto, TradeDto } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';

import ExternalTradeActionDropdown from '../ExternalTradeActionDropdown/ExternalTradeActionDropdown';

import useStyles from './ExternalTrades.styles';

export interface IExternalTradesProps {
  trades: TradeDto[],
  program: ProgramDto,
  addExternalTradesAllowed: boolean,
  handleReloadExternalTradesTable?: () => void,
  handleAddTrade?: () => void,
  handleReloadPeriodsData?: () => void,
}

const ExternalTrades: React.FC<IExternalTradesProps> = ({
  trades,
  program,
  addExternalTradesAllowed,
  handleReloadExternalTradesTable,
  handleAddTrade,
  handleReloadPeriodsData,
}) => {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  if (loading) { return <BackdropLoader testId="hedging-program-summary-loader" />; }

  const columns: IStyledGenericTableProps['columns'] = [
    {
      header: t('buy_currency'),
      accessor: 'buyCurrency',
    },
    {
      header: t('buy_amount'),
      accessor: 'buyAmount',
    },
    {
      header: t('sell_currency'),
      accessor: 'sellCurrency',
    },
    {
      header: t('sell_amount'),
      accessor: 'sellAmount',
    },
    {
      header: t('rate'),
      accessor: 'rate',
      align: 'right',
    },
    {
      header: t('value_date'),
      accessor: 'valueDate',
      align: 'right',
    },
  ];

  if (addExternalTradesAllowed) {
    columns.push({
      header: t('action'),
      accessor: 'action',
      align: 'right',
    });
  }

  const externalTradesTableData = trades.map((trade) => ({
    ...trade,
    valueDate: formatIsoDate(trade.valueDate) || '-',
    rate: <span className={classes.tableLink}>{formatNumber(trade.rate, 4)}</span> || '-',
    sellAmount: <span className={classes.amount}>{formatNumber(trade.soldAmount, 2)}</span> || '-',
    sellCurrency: trade.soldCurrencyCode || '-',
    buyAmount: <span className={classes.amount}>{formatNumber(trade.buyAmount, 2)}</span> || '-',
    buyCurrency: trade.buyCurrencyCode || '-',
    action: (
      <ExternalTradeActionDropdown
        trade={trade}
        handleReloadExternalTradesTable={handleReloadExternalTradesTable}
        handleReloadPeriodsData={handleReloadPeriodsData}
      />
    ),
  }));

  return (
    <div>
      <Box justifyContent="space-between" flexDirection="row" alignContent="space-between" display="flex">
        <Typography className={classes.sectionHeader}>
          <span>{t('uploaded_external_trades')}</span>
          {' '}
          <span className={classes.programName}>{program?.programName}</span>
        </Typography>
        {addExternalTradesAllowed && (
          <ActionButton
            style={{ marginLeft: '8px' }}
            onClick={handleAddTrade}
          >
            {t('add_trade_to')}
            {' '}
            {program.programName}
          </ActionButton>
        )}
      </Box>
      {trades.length ? (
        <StyledGenericTable
          testId="genericTable"
          columns={columns}
          data={externalTradesTableData}
        />
      ) : (
        <Box className={classes.emptyTable}>
          <EmptyTable
            title={t('no_external_trades')}
            subtitle={t('you_currently_dont_have_external_trades')}
            icon={clipboardCross}
          />
        </Box>
      )}
    </div>
  );
};

export default ExternalTrades;
