import React, { ReactNode, useState } from 'react';
import useLog from 'hooks/useLog';

import { Box, Collapse } from '@alpha/ui-lib/ui/external';
import { SingleStat } from '@alpha/ui-lib/ui/StatSummary/SingleStat';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import t from 'utils/translationHelper';

import useWidgetStyles from '../index.styles';

import useStyles from './TermSummary.styles';

export type TStatItem = {
  header: string | ReactNode;
  value: string | ReactNode;
  helpMessage?: string | ReactNode;
};

interface IProps {
  summaries: TStatItem[];
  totalBespokeItems: number;
  bespokeItems?: TStatItem[];
}

const TermSummary: React.FC<IProps> = ({ summaries, totalBespokeItems, bespokeItems }) => {

  const classes = useStyles();
  const widgetClasses = useWidgetStyles();
  const [openBespoke, setOpenBespoke] = useState(false);
  const { logEvent } = useLog();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={10}
      style={{
        gridColumn: 'span 3',
        gridAutoColumns: 'auto',
      }}
    >
      <div
        className={widgetClasses.termHeader}
      >
        <div className={classes.statsContainer}>
          {summaries?.map((item: TStatItem) => (
            <SingleStat
              statHeader={<div style={{ minWidth: '120px' }}>{item.header}</div>}
              value={item.value}
              helpMessage={item.helpMessage}
            />
          ))}
          {/* Bespoke Item */}
          {bespokeItems && totalBespokeItems > 0 && (
            <SingleStat
              statHeader={(
                <div style={{ minWidth: '120px' }}>
                  {t('bespoke_features')}
                </div>
              )}
              value={totalBespokeItems}
              // @ts-ignore
              icon={faChevronDown}
              onIconClick={() => {
                logEvent({ action: 'Clicked on bespoke features' });
                setOpenBespoke(!openBespoke);
              }}
              iconStyle={{
                background: '#f7f7f7',
                color: '#949494',
                transform: openBespoke ? 'rotate(180deg)' : '',
                transition: 'all 0.1s linear',
              }}
            />
          )}
        </div>
      </div>
      {/* Bespoke container */}
      <Collapse
        in={openBespoke}
        style={{
          gridColumn: 'span 3',
        }}
        onExited={(node) => {
          node.hidden = true; // hide the element to prevent extra gaps
        }}
        onEnter={(node) => {
          node.hidden = false;
        }}
        hidden="true"
      >
        <div
          className={widgetClasses.termHeader}
          style={{
            gridColumn: 'span 3',
            gridAutoColumns: 'auto',
          }}
        >
          <div className={classes.statsContainer}>
            {bespokeItems?.map((item) => (
              <SingleStat
                statHeader={<div style={{ minWidth: '120px' }}>{item.header}</div>}
                helpMessage={item.helpMessage}
                value={item.value}
              />
            ))}
          </div>
        </div>
      </Collapse>
    </Box>
  );
};

export default TermSummary;
