import React from 'react';
import { DateTime } from 'luxon';

import { TStatementData } from '../../../../models/statements';
import { formatCurrency } from '../../../../utils/currency.helpers';
import t from 'utils/translationHelper';

import BodyDetails from './BodyDetails/BodyDetails';

interface IBody {
  statement: TStatementData;
}

const Body: React.FC<IBody> = ({ statement }: IBody) => {

  const beneficiaryDetails = [
    {
      key: t('beneficiary_name'),
      value: statement.debitFriendlyName || '-',
    },
  ];

  const senderDetails = [
    {
      key: t('sender_name'),
      value: statement.creditFriendlyName || '-',
    },
  ];

  const referenceDetails = [
    {
      key: t('payment_reference'),
      value: statement.reference || '-',
    },
  ];

  const valueDateDetails = [
    {
      key: t('value_date'),
      value: DateTime.fromISO(statement.valueDate!)?.toLocaleString() || '-',
    },
  ];

  const amountToDisplay = statement.creditAmount === 0
    ? statement.debitAmount
    : statement.creditAmount;
  const amountDetails = [
    {
      key: statement.type === 'debit' ? t('debit_amount') : t('credit_amount'),
      value: formatCurrency(statement.creditCurrencyCode, amountToDisplay),
    },
  ];

  return (
    <BodyDetails
      referenceDetails={referenceDetails}
      valueDateDetails={valueDateDetails}
      beneficiaryDetails={statement.type === 'debit' ? beneficiaryDetails : senderDetails}
      amountDetails={amountDetails}
    />
  );
};

export default Body;
