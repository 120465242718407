import React from 'react';
import useAuthorization from 'hooks/useAuthorization';
import { UserRole } from 'models/user';
import t from 'utils/translationHelper';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { shouldShowApprovalButtons } from 'utils/payments/shouldShowApprovalButtons';

import { PaymentDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Button } from '@alpha/ui-lib/ui/external';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';

import useStyles from './PaymentActionBar.styles';

interface IPaymentActionBar {
  selectedPayments: PaymentDto[] | undefined;
  handleClearButtonClick: () => void;
  handleOpenApprovalDrawer: () => void;
  handleRejectModalOpen: () => void;
  show?: boolean;
}

const PaymentActionBar: React.FC<IPaymentActionBar> = (props: IPaymentActionBar) => {
  const {
    selectedPayments,
    handleRejectModalOpen,
    handleClearButtonClick,
    handleOpenApprovalDrawer,
    show = false,
  } = props;

  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);
  const canApproveOthers = useAuthorization([[UserRole.PAYMENTS_APPROVER]]);
  const canApproveOwn = useAuthorization([[UserRole.PAYMENTS_APPROVER_OWN]]);

  const classes = useStyles();
  const rootClasses = [classes.root, show ? null : 'hide']?.join(' ');

  if (!selectedPayments?.length || !userId) return <></>;

  const showApproveButton = shouldShowApprovalButtons(
    canApproveOthers.authorized,
    canApproveOwn.authorized,
    selectedPayments,
    userId,
    true,
  );
  const showRejectButton = shouldShowApprovalButtons(
    canApproveOthers.authorized,
    canApproveOwn.authorized,
    selectedPayments,
    userId,
    false,
  );

  return (
    <div className={rootClasses}>
      <div className={classes.leftContent}>
        <span className={classes.selectedPayments}>
          {selectedPayments ? selectedPayments.length : 0}
          {' '}
          {selectedPayments && selectedPayments.length > 1 ? t('payments~') : t('payment~')}
          {' '}
          {t('selected')}
        </span>
        <StyledDivider vertical />
        <Button
          disableRipple
          className={classes.clearButton}
          onClick={handleClearButtonClick}
        >
          {t('clear_selection')}
        </Button>
      </div>

      <div className={classes.rightContent}>
        {(showApproveButton || showRejectButton) ? (
          <>
            {showRejectButton && (
              <ActionButton
                style={{ background: '#F7F7F7', color: '#212529' }}
                size="medium"
                testId="reject-payments-btn"
                onClick={handleRejectModalOpen}
                className={classes.rejectButton}
              >
                {t('reject_payments')}
              </ActionButton>
            )}
            {showApproveButton && (
              <ActionButton
                size="medium"
                testId="approve-btn"
                className={classes.approveButton}
                onClick={handleOpenApprovalDrawer}
              >
                {t('approve_payments')}
              </ActionButton>
            )}
          </>
        ) : <p>{t('no_actions_available_for_the_selected_payments')}</p>}
      </div>

    </div>
  );
};
export default PaymentActionBar;
