import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    root: {
      paddingTop: '24px',
      paddingBottom: '30px',
      backgroundColor: '#fafafa',
      minHeight: 'calc(100vh - 310px)',
    },
    styledTitle: {
      fontSize: '28px',
      fontWeight: 400,
      marginTop: '5px',
      marginBottom: '5px',
    },
    positionValuationTableContainer: {
      backgroundColor: '#ffffff',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
      padding: '20px',
      marginTop: '20px',
    },
    panel: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '24px',
      maxWidth: '100%',
      '@media (max-width: 800px)': {
        gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr));',
      },
      paddingBottom: '40px',
      margin: 'auto',
    },
    widget: {
      padding: '20px',
      background: '#ffffff',
      borderRadius: '4px',
      boxShadow: '0px 3px 6px #00000005',
      '@media (max-width: 800px)': {
        gridColumn: '1/1 !important',
      },
    },
    header: {
      color: '#1E8777',
      display: 'inline',
    },
    flag: {
      lineHeight: '27px',
      '& div': {
        verticalAlign: 'middle',
        paddingRight: '3px',
        display: 'flex',
        flexDirection: 'row',
      },
    },
    emptyTableUtilisation: {
      position: 'relative!important',
      fontSize: '21px!important',
      marginBottom: '20px',
      '& .image': {
        height: '120px',
        width: '120px',
        '& img': {
          height: '60',
          width: '60px',
        },
      },
    },
  }),
  { name: 'CreditFacility' },
);

export default useStyles;
