import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  () => ({
    container: {
      maxWidth: '600px',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      '& h2': {
        fontWeight: '400',
      },
      '& img': {
        width: '25px',
        marginRight: '8px',
      },
    },
    body: {
      padding: '24px 0',
      '& h6': {
        color: '#222222',
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: '24px',
      },
      '& .errorMessage': {
        fontWeight: 'bold',
        paddingBottom: '20px',
      },
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    paddingBottom: {
      paddingBottom: '20px',
    },
    continueBtn: {
      marginLeft: '10px',
    },
  }),
  { name: 'AccountRequestDupeModal' },
);

export default useStyles;
