import React from 'react';

import { Button } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../../../../../hooks/useReportsPolling';

import useStyles from './HeaderButtons.styles';

const HeaderButtons: React.FC<{ paymentId?: string }> = (
  { paymentId },
) => {
  const classes = useStyles();

  const { executeReportGeneration } = useReportsPolling(PageType.Payments);

  const handleReportGeneration = async (type: FileType, downloadType: DownloadType) => {
    if (paymentId) {
      await executeReportGeneration(type, paymentId, undefined, undefined, downloadType);
    }
  };

  return (
    <APTooltip title={t('download_payment_summary~')}>
      <Button
        data-testid="payment-summary-download"
        onClick={() => handleReportGeneration(FileType.PDF, DownloadType.Summary)}
        className={classes.button}
      >
        <FontAwesomeIcon icon={faDownload as IconProp} />
      </Button>
    </APTooltip>
  );
};

export default HeaderButtons;
