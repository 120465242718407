import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    menuContainer: {
      '& button': {
        backgroundColor: '#F4F4F4',
        borderColor: '#F4F4F4',
        borderRadius: '5px',
      },
    },
    iconRight: {
      transform: 'rotate(-90deg)',
      transition: 'all 0.1s linear',
    },
    iconDown: {
      transition: 'all 0.1s linear',
    },
    subTitleText: {
      fontSize: '12px',
      color: '#999999',
    },
    boxHeaderText: {
      fontSize: '16px',
      fontWeight: typography.fontWeightSemiBold,
      '& span': {
        paddingLeft: '10px',
      },
    },
    table: {
      '& td:first-of-type': {
        color: '#088375!important',
        fontWeight: typography.fontWeightSemiBold,
      },
      marginTop: '25px',
    },
    listingContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
  { name: 'AccountAudits' },
);

export default useStyles;
