import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  (theme) => ({
    widget: {
      background: '#FFFFFF',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
      opacity: 1,
      borderRadius: '10px',
      padding: '40px',
    },
    smallStatus: {
      letterSpacing: '1px',
      marginTop: '8px',
      padding: '2px 4px',
      fontSize: '10px',
    },
    boxHeaderText: {
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightSemiBold,
      '& span': {
        paddingLeft: '10px',
      },
    },
    subTitleText: {
      fontSize: '12px',
      color: '#999999',
    },
    stickySection: {
      position: 'sticky',
      top: '172px',
      backgroundColor: '#ffffff',
      zIndex: 100,
      paddingTop: '14px',
    },
    iconRight: {
      transform: 'rotate(-90deg)',
      transition: 'all 0.1s linear',
    },
    iconDown: {
      transition: 'all 0.1s linear',
    },
  }),
  { name: 'TeamSettings' },
);

export default useStyles;
