/* eslint-disable max-lines-per-function */
import { FormikProps } from 'formik';
import i18n from 'i18n/config';
import { Moment } from 'moment';
import { MuiChipsInput } from 'mui-chips-input';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import t from 'utils/translationHelper';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { DrawdownRequest } from '@alpha/fx-dtos';
import { PaymentPurposeDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';
import { Typography } from '@alpha/ui-lib/ui/external';
import { Input } from '@alpha/ui-lib/ui/Input';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreateBeneficiary from '../../../../../../domain/Beneficiaries/CreateBeneficiary';
import BeneficiaryDropdownContainer from '../../../../../../domain/Transactions/ManualPayments/Body/PaymentType/ManualPayment/BeneficiaryDropdown/BeneficiaryDropdownContainer';
import Authorization from '../../../../../../hocs/Authorization';
import useAlphaSnackbar from '../../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../../hooks/useLog';
import { UserRole } from '../../../../../../models/user';
import PaymentsService from '../../../../../../services/Payments/payments.service';
import InputAndLabel from '../../../../../Inputs/Beneficiary/InputAndLabel';
import { useStyles as useDropdownStyles } from '../../../../../InterAccountTransfer/Dropdown/Dropdown.styles';
import PaymentContext from '../../../../Payments/Beneficiaries/AddPayment/paymentContext';
import { FormikPaymentType } from '../../../../Payments/CreatePaymentDrawer/formData';
import { FormikType } from '../../formData';

import PaymentPurposeDropdown from 'components/Molecules/Payments/CreatePaymentDrawer/PaymentPurposeDropdown/PaymentPurposeDropdown';
import useStyles from './index.styles';

type Props = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  payments: FormikType;
  drawdownForm?: DrawdownRequest;
  singlePaymentForm: FormikProps<FormikPaymentType>;
  buyCurrencyAccount?: CurrencyAccountDto;
};

const AddPayment: React.FC<Props> = ({
  drawdownForm, singlePaymentForm, buyCurrencyAccount,
}) => {
  const styles = useStyles();
  const { logError } = useLog();
  const [beneficiaryDrawerOpen, setBeneficiaryDrawerOpen] = useState<boolean>(false);
  const dropdownStyles = useDropdownStyles();
  const sb = useAlphaSnackbar();
  const availableDateQuery = useQuery('getPaymentDates', () => {
    PaymentsService.getAvailablePaymentDate(
      singlePaymentForm.values.beneficiary?.currencyCode || '',
      drawdownForm?.sellCurrencyCode || '',
    );
  }, {
    enabled: false,
    onError: () => {
      sb.trigger(t('could_not_load_payment_available_date'), 'error');
      logError({ action: 'Could not load payment available date' });
    },
  });
  const paymentPurposesQuery = useQuery('getPaymentPurpose', () => PaymentsService.getPaymentPurpose(
    singlePaymentForm.values.beneficiary?.bankCountryCode || '',
  ), {
    enabled: false,
  });

  const handleDateChange = (_date: Moment) => {
    if (_date) {
      singlePaymentForm.setFieldValue('date', _date.format('YYYY-MM-DD'));
    }
  };

  const handleAmountChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const amount = parseFloat(event.target.value.replace(/,/g, ''));
    singlePaymentForm.setFieldValue('amount', amount, true);
    singlePaymentForm.setFieldTouched('amount', true, true);
  };

  const handleDropdownChange = (event: any) => {
    if (paymentPurposesQuery.data) {
      const selectedPaymentPurpose = paymentPurposesQuery.data.purposes.find(
        (purpose) => purpose.description === event.target.value,
      );
      if (selectedPaymentPurpose) {
        singlePaymentForm.setFieldValue('purpose', selectedPaymentPurpose);
      }
    }
  };

  useEffect(() => {
    if (singlePaymentForm.values.beneficiary?.currencyCode) {
      availableDateQuery.refetch();
      paymentPurposesQuery.refetch();
      singlePaymentForm.setFieldValue('date', drawdownForm?.valueDate);
    }
  }, [singlePaymentForm.values.beneficiary]);

  useEffect(() => {
    singlePaymentForm.setFieldValue('date', drawdownForm?.valueDate);
  }, [drawdownForm?.valueDate]);

  return (
    <form className={styles.container} onSubmit={singlePaymentForm.handleSubmit}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1">{t('beneficiary')}</Typography>
          <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
            <Typography
              variant="subtitle1"
              style={{ color: '#1E8777', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setBeneficiaryDrawerOpen(true)}
            >
              {t('create_new?')}
            </Typography>
          </Authorization>
        </div>
        <BeneficiaryDropdownContainer
          currencyCode={drawdownForm?.buyCurrencyCode || ''}
          beneficiary={singlePaymentForm.values.beneficiary}
          setFieldValue={singlePaymentForm.setFieldValue}
        />
      </div>
      <InputAndLabel
        label={`${t('amount_currency')} ${drawdownForm?.buyCurrencyCode}`}
        name="amount"
        id="amount"
        type="amount"
        testId="furtherToAccountNumber"
        onChange={handleAmountChange}
        onBlur={singlePaymentForm.handleBlur}
        value={singlePaymentForm.values.amount}
        error={
          singlePaymentForm.touched.amount
          && Boolean(singlePaymentForm.errors.amount)
        }
        helperText={
          singlePaymentForm.touched.amount
          && singlePaymentForm.errors.amount
        }
      />
      <div style={{ marginTop: '20px', width: '100%' }}>
        <APDatePicker
          fullWidth
          label={t('payment_date')}
          language={i18n.language}
          className="datePicker"
          onChange={handleDateChange}
          value={singlePaymentForm.values.date}
          disabled
          placeholder={t('payment_date~')}
          emptyLabel={t('select_date')}
          autoOk
        />
      </div>
      <div style={{ marginTop: '20px', width: '100%', marginBottom: '20px' }}>
        <InputAndLabel
          label={t('reference_(drawdown)')}
          name="reference"
          id="reference"
          testId="reference"
          onChange={singlePaymentForm.handleChange}
          onBlur={singlePaymentForm.handleBlur}
          value={singlePaymentForm.values.reference}
          error={
            singlePaymentForm.touched.reference
            && Boolean(singlePaymentForm.errors.reference)
          }
          helperText={
            singlePaymentForm.touched.reference
            && singlePaymentForm.errors.reference
          }
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Typography variant="subtitle1" className="label">
          {t('purpose_of_payment')}
        </Typography>
        {paymentPurposesQuery?.data && !paymentPurposesQuery.data?.canBeFreeText ? (
          <PaymentPurposeDropdown
            data={paymentPurposesQuery.data ? paymentPurposesQuery.data?.purposes : []}
            handleChange={handleDropdownChange}
            placeholderText={t('please_select_a_purpose_of_payment')}
            data-testid="purpose"
            selectName="purpose"
            selectValue={(singlePaymentForm.values.purpose as PaymentPurposeDto)?.description}
          />
        ) : (
          <Input
            onChange={singlePaymentForm.handleChange}
            fullWidth
            id="purpose"
            variant="filled"
            value={singlePaymentForm.values.purpose && typeof singlePaymentForm.values.purpose === 'string' ? singlePaymentForm.values.purpose : ''}
            testId="purposeOfPayment-input"
            helperText={singlePaymentForm.errors.purpose}
            error={!!singlePaymentForm.errors.purpose}
          />
        )}
      </div>
      <div>
        <Typography variant="subtitle1">{t('optional_email')}</Typography>
        <MuiChipsInput
          data-testid="bene-email-addresses"
          className={styles.chip}
          value={singlePaymentForm.values?.emailAddresses ?? []}
          fullWidth
          onChange={(chips: string[]) => {
            singlePaymentForm.setFieldValue('emailAddresses', chips);
          }}
          error={Boolean(singlePaymentForm.errors.emailAddresses)}
          helperText={
            singlePaymentForm.errors.emailAddresses?.length
              ? singlePaymentForm.errors.emailAddresses : ''
          }
          placeholder={t('enter_save_email')}
        />
      </div>
      <div style={{ textAlign: 'right', marginTop: '32px' }}>
        <ActionButton
          onClick={() => {
            singlePaymentForm.setFieldValue('singlePayment', false);
            singlePaymentForm.submitForm();
          }}
          style={{
            color: !singlePaymentForm.isValid ? '#B2B2B2' : 'black',
            textTransform: 'none',
            textDecoration: 'underline',
            background: '#F7F7F7',
          }}
          disabled={!singlePaymentForm.isValid}
          startIcon={<FontAwesomeIcon icon={faPlusCircle as IconProp} />}
        >
          {t('save_payment_and_add_another')}
        </ActionButton>
      </div>
      <PaymentContext.Provider value={{
        currencyCode: buyCurrencyAccount!.currencyCode,
        currency: buyCurrencyAccount!.currency,
        ...singlePaymentForm,
      }}
      >
        <CreateBeneficiary
          open={beneficiaryDrawerOpen}
          header="Create Drawdown / New Beneficiary"
          handleDrawerClose={() => setBeneficiaryDrawerOpen(false)}
        />
      </PaymentContext.Provider>
    </form>
  );
};

export default AddPayment;
