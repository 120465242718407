import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ typography }) => ({
    drawerBody: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      padding: '40px 0px 0px 40px',
    },
    bodyHeader: {
      fontWeight: typography.fontWeightSemiBold,
      fontSize: '13',
      paddingLeft: '15px',
    },
    associatedPayments: {
      border: '1px solid #eeeeee',
      borderRadius: '5px',
      padding: '20px',
      width: 'calc(100% - 30px)',
      marginBottom: '8px',
    },
    paymentContractNumber: {
      color: '#1E8777',
      fontWeight: typography.fontWeightSemiBold,
      fontSize: '18px',
    },
    paymentAmount: {
      color: '#141414',
      fontSize: '18px',
    },
    paymentInfo: {
      color: '#999999',
    },
  }),
  { name: 'DrawdownInfoDrawer' },
);

export default useStyles;
