import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  validIcon: {
    marginRight: '10px',
    marginTop: '5px',
    color: '#88C165',
  },
  table: {
    borderCollapse: 'separate',
    '& thead': {
      position: 'sticky',
      top: '0',
      background: 'white',
      '& th': {
        borderBottom: '1px solid #eaeaea',
        paddingRight: '20px !important',
      },
    },
    '& .MuiTableCell-root': {
      'padding-right': '40px !important',
    },
  },
  chevron: {
    color: '#7E7E7E',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    marginLeft: '20px',
    marginRight: '14px',
  },
  validTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '31px',
    paddingTop: '42px',
  },
  validTitle: {
    fontSize: '16px',
    fontWeight: typography.fontWeightSemiBold,
    marginRight: '18px',
  },
  validBox: {
    background: '#FFFFFF',
    borderRadius: '5px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    margin: '24px 48px 0px 84px',
    minWidth: '800px',
  },
  iconUp: {
    transform: 'rotate(180deg)',
    transition: 'all 0.1s linear',
  },
  iconDown: {
    transition: 'all 0.1s linear',
  },
  status: {
    marginTop: '-3px',
  },
  subtitle: {
    fontSize: '14px',
    color: '#343434',
    opacity: '80%',
    margin: '18px 0px 30px 59px',
  },
  greenValue: {
    fontSize: 14,
    fontWeight: typography.fontWeightSemiBold,
    color: '#1E8777',
  },
  statValue: {
    fontSize: '18px',
    fontWeight: typography.fontWeightSemiBold,
  },
  statContainer: {
    margin: '0px 750px 60px 59px',
  },
  tableContainer: {
    margin: '0px 50px 24px 54px',
  },
  loader: {
    padding: '15px 0px',
  },
}), { name: 'ValidPayments' });

export default useStyles;
