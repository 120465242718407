import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ palette, typography }) => ({
    column: {
      paddingBottom: 10,
      display: 'flex',
      flexDirection: 'column',
      '& > b': {
        paddingBottom: 4,
        fontWeight: typography.fontWeightMedium,
        fontSize: 12,
      },
      '& > span': {
        paddingBottom: 6,
        fontWeight: typography.fontWeightBold,
        fontSize: 15,
      },
    },
    topSection: {
      display: 'flex',
      paddingBottom: 5,
    },
    infoText: {
      display: 'flex',
      paddingTop: 10,
      color: palette.primary.light,
    },
    contentWrapper: {
      paddingTop: 8,
      minWidth: 200,
    },
  }), { name: 'TooltipContent' },
);

export default useStyles;
