import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ typography }) => ({
    wrapper: {
      color: '#989898',
      fontWeight: typography.fontWeightSemiBold,
      '& > span.bold': {
        color: '#222222',
      },
    },
  }),
  { name: 'BatchSummary' },
);

export default useStyles;
