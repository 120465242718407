import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  titleContainer: {
    fontSize: '16px',
    fontWeight: 600
  },
  card: {
    borderRadius: '10px',
    padding: '24px'
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: 300,
    color: '#999999'
  },
  iconRight: {
    transform: 'rotate(-90deg)',
    transition: 'all 0.1s linear',
  },
  iconDown: {
    transition: 'all 0.1s linear',
  },
  infoCard: {
    marginLeft: '16px',
    display: 'inline-block',
    color: '#3E8CC1',
    backgroundColor: '#3E8CC11A',
    height: '44px',
    paddingLeft: '21px',
    paddingRight: '21px',
    borderRadius: '5px',
    alignContent: 'center'
  }
}), { name: 'ExportsTable' });

export default useStyles;
