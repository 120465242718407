import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import React, { useEffect, useState } from 'react';
import routes from 'routes.path';
import browserHistory from 'services/history/browserHistory';
import StatementsService from 'services/Statements/statements.service';
import t from 'utils/translationHelper';

import { CurrencyAccountDto, MarginAccountDto } from '@alpha/currency-accounts-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';

import CurrencyAccounts from './CurrencyAccounts';
import HedgingCashAccounts from './HedgingCashAccounts';
import useStyles from './index.styles';
import MarginAccounts from './MarginAccounts';

const BalanceWidget: React.FC = () => {

  const classes = useStyles();
  const sb = useAlphaSnackbar();
  const { logEvent, logError } = useLog();
  const [currencyAccounts, setCurrencyAccounts] = useState<CurrencyAccountDto[]>([]);
  const [marginAccounts, setMarginAccounts] = useState<MarginAccountDto[]>([]);
  const [hedgingCashAccounts, setHedgingCashAccounts] = useState<CurrencyAccountDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const tabTitles = hedgingCashAccounts.length > 0 ? [t('accounts'), t('margin_accounts'), t('hedging_cash')] : [t('accounts'), t('margin_accounts')];

  const handleViewAllBtnClick = () => {
    browserHistory.push(routes.currencyAccounts);
    logEvent({
      action: 'Dashboard - View All', detail: { widget: 'Balances' },
    });
  };

  const loadBalances = async (): Promise<void> => {
    try {
      const balances = await StatementsService.getStatementAccounts();
      setCurrencyAccounts(balances.currencyAccounts);
      setMarginAccounts(balances.marginAccounts);
      setHedgingCashAccounts(balances.hedgingCashAccounts);
    } catch (e) {
      sb.trigger(e?.message || t('unable_to_load_balances_summary'), 'error');
      logError({ action: 'Error loading balances summary', error: e });
      setCurrencyAccounts([]);
      setMarginAccounts([]);
      setHedgingCashAccounts([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBalances();
    logEvent({ action: 'Dashboard - View Balance Widget' });
  }, []);

  return (
    <Box
      className={classes.widget}
      style={{
        gridColumn: 'span 1',
        gridAutoColumns: 'auto',
        minWidth: '390px',
      }}
    >
      <Box className={classes.balancesContainer}>
        <Typography className={classes.title}>{t('balances')}</Typography>
        <ActionButton style={{ background: '#F7F7F7', color: '#212529' }} onClick={handleViewAllBtnClick}>
          {t('view_all~')}
        </ActionButton>
      </Box>
      <StyledTabsWrapper
        testId="balances-tabs-wrapper"
        tabTitles={tabTitles}
        tabIndex={tabIndex}
        handleChange={(selectedTabIndex: number) => {
          setTabIndex(selectedTabIndex);
          logEvent({
            action: 'Dashboard - Balances Click Tab',
            detail: {
              tab: selectedTabIndex,
              tabName: tabTitles[selectedTabIndex],
            },
          });
        }}
      />
      <div className={classes.accountsContainer}>
        {loading && <Loader testId="balances-loading" />}
        <Box display={tabIndex === 0 ? 'block' : 'none'}>
          {currencyAccounts.length > 0 && !loading
            ? <CurrencyAccounts currencyAccounts={currencyAccounts} />
            : (
              <EmptyTable
                title={t('no_currency_accounts')}
                subtitle={(
                  <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: '300' }}>
                    {t('you_currently_do_not_have_any_currency_accounts')}
                  </Typography>
                )}
                className={classes.emptyTable}
                icon={clipboardCross}
              />
            )}
        </Box>
        <Box display={tabIndex === 1 ? 'block' : 'none'}>
          {marginAccounts.length > 0 && !loading
            ? <MarginAccounts marginAccounts={marginAccounts} />
            : (
              <EmptyTable
                title={t('no_margin_accounts')}
                subtitle={(
                  <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: '300' }}>
                    {t('you_currently_do_not_have_any_margin_accounts')}
                  </Typography>
                )}
                className={classes.emptyTable}
                icon={clipboardCross}
              />
            )}
        </Box>
        <Box display={tabIndex === 2 ? 'block' : 'none'}>
          {hedgingCashAccounts.length > 0 && !loading
            ? <HedgingCashAccounts hedgingCashAccounts={hedgingCashAccounts} />
            : (
              <EmptyTable
                title={t('no_hedging_cash_accounts')}
                subtitle={(
                  <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: '300' }}>
                    {t('you_currently_do_not_have_any_hedging_cash_accounts')}
                  </Typography>
                )}
                className={classes.emptyTable}
                icon={clipboardCross}
              />
            )}
        </Box>
      </div>
    </Box>
  );
};

export default BalanceWidget;
