import React, { memo } from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Button } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import FooterWrapper from '../../../../../../components/Payments/FooterWrapper/FooterWrapper';
import routes from '../../../../../../routes.path';
import history from '../../../../../../services/history/browserHistory';
import RejectModal from '../RejectModal/RejectModal';

interface IProps {
  buttonText: string;
  batchName: string;
  modalOpen: boolean;
  handleClose: () => void;
  handleRejectBatchButton: () => void;
  handleApprovePayment: () => Promise<void>;
  handleRejectPayment: () => Promise<void>;
}

const ApproveRejectFooter: React.FC<IProps> = (props: IProps) => {
  const {
    buttonText,
    batchName,
    modalOpen,
    handleClose,
    handleApprovePayment,
    handleRejectBatchButton,
    handleRejectPayment,
  } = props;

  const handleClick = () => {
    history.push(routes.transactions.base);
  };

  return (
    <>
      <FooterWrapper>
        <Button
          onClick={handleClick}
          startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
        >
          {t('exit')}
        </Button>
        <Box>
          <ActionButton
            onClick={handleRejectBatchButton}
            style={{ background: '#F7F7F7', color: '#212529', marginRight: '16px' }}
            data-testid="reject-batch-button"
          >
            {t('reject_batch')}
          </ActionButton>
          <ActionButton data-testid="approve-batch-button" size="large" onClick={handleApprovePayment}>
            {buttonText}
          </ActionButton>
        </Box>
      </FooterWrapper>
      <RejectModal
        batchName={`${t('reject')} ${batchName.length > 35 ? ` ${t('batch')} ` : `${batchName}`}`}
        open={modalOpen}
        subtitle1={t('your_about_to_reject_this_batch')}
        actionButton={t('yes_reject_this_batch')}
        handleActionButton={handleRejectPayment}
        handleClose={handleClose}
      />
    </>
  );
};

export default memo(ApproveRejectFooter);
