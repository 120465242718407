import React from 'react';

import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import { TAuthyStatus } from '../../../store/authy/reducer';

import { useStyles } from './AuthyResponse.styles';

interface IAuthyResponseProps {
  status: TAuthyStatus
}
const AuthyResponse: React.FC<IAuthyResponseProps> = (
  { status }: IAuthyResponseProps,
) => {
  const styles = useStyles();

  return (
    <Box className={styles.wrapper}>
      <FontAwesomeIcon
        icon={(status === 'SUCCESS' ? faCheckCircle : faTimesCircle) as IconProp}
        className={status === 'SUCCESS' ? styles.green : styles.red}
      />
      {status === 'SUCCESS' && (
        <Typography style={{
          fontWeight: '400', fontSize: '18px', color: 'white', marginTop: '16px',
        }}
        >
          {t('authentication_successful_processing_your_request')}
        </Typography>
      )}
    </Box>
  );
};
export default AuthyResponse;
