import { AxiosResponse } from 'axios';

import {
  TradeDto, TradeStatus, TradeType,
} from '@alpha/fx-dtos';
import {
  ExecuteQuoteRequest,
  IATApprovalResponse, IATDraftResponse, IATQuoteDto, IATRequest,
} from '@alpha/iat-dtos';
import { IATResponse } from '@alpha/iat-dtos/lib/response/IATResponse';

import { TInterAccountTransferForm } from '../../domain/InterAccountTransfer/FormValues';
import {
  TCurrencyAccount,
  TCurrencyAccounts,
} from '../../models/currencyAccounts';
import {
  TIATAccountTypes,
  TIATQuote,
} from '../../models/interAccountTransfer';
import { TStatementData } from '../../models/statements';
import instance from '../Axios/instance';

export class InterAccountTransferService {
  public static mapiatDraftResponseToTInterAccountTransferForm(
    iatDraftResponse: IATDraftResponse,
  ): TInterAccountTransferForm {
    const formValues = {
      creditingAccount: {
        amount: iatDraftResponse.creditAmount,
        reference: iatDraftResponse.reference,
        currencyAccount: iatDraftResponse.creditCurrencyAccount,
      },
      debitingAccount: {
        amount: iatDraftResponse.debitAmount,
        currencyAccount: iatDraftResponse.debitCurrencyAccount,
      },
      fixedSide: iatDraftResponse.creditAmount
        ? iatDraftResponse.creditCurrencyAccount.currencyCode
        : iatDraftResponse.debitCurrencyAccount.currencyCode,
      iatDraftId: iatDraftResponse.id,
    };

    if (!iatDraftResponse.requiresFx) {
      formValues.creditingAccount.amount = iatDraftResponse.creditAmount
        || iatDraftResponse.debitAmount;
      formValues.debitingAccount.amount = iatDraftResponse.creditAmount
        || iatDraftResponse.debitAmount;
    }

    return formValues;
  }

  public static async submitInterAccountTransfer(
    values: TInterAccountTransferForm,
  ): Promise<string> {
    const iatRequest: IATRequest = {
      reference: values.creditingAccount.reference,
      creditCurrencyAccountId: values.creditingAccount.currencyAccount!.id,
      debitCurrencyAccountId: values.debitingAccount.currencyAccount!.id,
      creditAmount: null,
      debitAmount: null,
    } as IATRequest;

    if (values.fixedSide === values.creditingAccount.currencyAccount?.currencyCode) {
      iatRequest.creditAmount = +values.creditingAccount.amount!;
    } else {
      iatRequest.debitAmount = +values.debitingAccount.amount!;
    }

    return (await instance.post('/iat', iatRequest)).data.id;
  }

  public static async getQuoteRequest(iatId: string): Promise<IATQuoteDto> {
    return (await instance.post('/iat/quotes', {
      iatDraftId: iatId,
    })).data;
  }

  public static async executeQuoteRequest(instructionId: string): Promise<{
    tradeFriendlyId: string,
    tradeId: string,
  }> {
    return instance.post('/iat/trades', {
      instructionId,
    } as ExecuteQuoteRequest);
  }

  public static async releaseSameCurrencyIAT(
    iatId: string,
  ): Promise<void> {
    return instance.post(`/iat/drafts/${iatId}/release`);
  }

  public static async approveInterAccountTransfer(
    iatId: string,
    approveOwn: boolean,
  ): Promise<IATApprovalResponse> {
    return (await instance.post(`/iat/drafts/${iatId}/${approveOwn ? 'approve-own' : 'approve'}`)).data;
  }

  public static async rejectTransfer(
    iatId: string,
    rejectingOwn: boolean,
  ): Promise<void> {
    return instance.post(`/iat/drafts/${iatId}/${rejectingOwn ? 'reject-own' : 'reject'}`);
  }

  public static async getRate(
    debitingCurrencyCode: string,
    creditingCurrencyCode: string,
  ): Promise<TradeDto> {
    const response = await this.getRateAsync(debitingCurrencyCode, creditingCurrencyCode);
    return { ...response, rate: +response.rate.toFixed(4) };
  }

  public static async postFxQuote(
    soldCurrencyCode: string,
    buyCurrencyCode: string,
    buyAmount: number,
    fixedCurrencyCode: string,
  ): Promise<TIATQuote> {
    const quoteRequest: TradeDto = {
      buyAmount,
      soldCurrencyCode,
      buyCurrencyCode,
      id: '',
      status: TradeStatus.ACTIVE,
      drawdownAvailable: false,
      type: TradeType.SPOT,
      contractNumber: '',
      date: '',
      valueDate: '',
      soldAmount: 0,
      sellBalance: 0,
      rate: 0,
      buyBalance: 0,
      accessDate: '',
      fixedAmount: 0,
      fixedCurrencyCode,
      origin: 'ONLINE',
      padEligible: false,
    };
    const response = await this.postFxQuoteAsync(quoteRequest);
    return {
      ...response,
      valueDate: response.valueDate.toString(),
      rate: +response.rate.toFixed(4),
    };
  }

  public static async postBookFx(
    instructionId: string,
    clientReference?: string,
  ): Promise<any & { status: number }> {
    return this.postBookFxAsync({ instructionId, clientReference });
  }

  public static calculateAmount(
    type: TIATAccountTypes,
    amount: number,
    rate?: number,
  ): number {
    if (!rate) return amount * 1;
    if (type === 'DEBITING') {
      return +(amount * rate).toFixed(2);
    }
    return +(amount / rate).toFixed(2);
  }

  private static async postInterAccountTransferAsync(
    values: any, // Was IATRequest, converted to any for the time being
  ): Promise<void> {
    return instance.post('/iat/iat', {
      ...values,
    });
  }

  public static async getStatementData(
    iatId: string,
  ): Promise<TStatementData> {
    const iatResponse = await this.getIatDataAsync(iatId);
    return this.mapIatDataToStatementData(iatResponse);
  }

  public static async getStatements(
    currencyAccountId: string,
  ): Promise<IATDraftResponse[]> {
    return (await instance.get(
      `/iat/drafts?debitcurrencyaccountid=${currencyAccountId}`,
    )).data;
  }

  private static async getIatDataAsync(iatId: string): Promise<IATResponse> {
    const response = await instance.get(`/iat/${iatId}`);
    return response.data;
  }

  private static mapIatDataToStatementData = (
    iatResponse: IATResponse,
  ): TStatementData => ({
    debitFriendlyName: iatResponse.debitCurrencyAccount.friendlyName,
    creditFriendlyName: iatResponse.creditCurrencyAccount.friendlyName,
    arrivalDate: iatResponse.arrivalDate,
    reference: iatResponse.reference,
    creditAmount: iatResponse.creditAmount,
    debitAmount: iatResponse.debitAmount,
    debitReference: iatResponse.debitCurrencyAccount.reference,
    creditReference: iatResponse.creditCurrencyAccount.reference,
    debitIban: iatResponse.debitCurrencyAccount.iban,
    creditIban: iatResponse.creditCurrencyAccount.iban,
    debitType: iatResponse.debitCurrencyAccount.type,
    creditType: iatResponse.creditCurrencyAccount.type,
    debitCurrencyCode: iatResponse.debitCurrencyAccount.currencyCode,
    creditCurrencyCode: iatResponse.creditCurrencyAccount.currencyCode,
  });

  public static removeCurrencyAccountById = (
    currencyAccounts: TCurrencyAccounts,
    caId: string | undefined,
  ): TCurrencyAccounts => {
    if (!caId) return [...currencyAccounts];
    return currencyAccounts.filter((ca: TCurrencyAccount) => ca.id !== caId);
  };

  // To be used in book trade
  private static async getRateAsync(
    debitingCurrencyCode: string,
    creditingCurrencyCode: string,
  ): Promise<any> {
    const response: AxiosResponse<any> = await instance.get(
      `/fx/indicative-rate?sellcurrency=${debitingCurrencyCode}&buycurrency=${creditingCurrencyCode}`,
    );

    return response.data;
  }

  private static async postFxQuoteAsync(
    quoteRequest: any, // QuoteRequest
  ): Promise<any> {
    const response: AxiosResponse<any> = await instance.post(
      '/fx/quote',
      quoteRequest,
    );

    return response.data;
  }

  private static async postBookFxAsync(
    executeTradeRequest: any,
  ): Promise<any & { status: number }> {
    const response: AxiosResponse = await instance.post(
      '/fx',
      executeTradeRequest,
    );
    return { ...response.data, status: response.status };
  }
}

export default InterAccountTransferService;
