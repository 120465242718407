import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    padding: '0 8px !important',
  },
  modalContent: {
    textAlign: 'right',
    padding: '20px',
    width: '450px',
  },
  headerBox: {
    width: '450px',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #E2E2E2',
    padding: '10px',
    marginBottom: '15px',
  },
  footerBox: {
    width: '450px',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #E2E2E2',
    padding: '20px 20px 0px 20px',
  },
  headerText: {
    fontWeight: typography.fontWeightBold,
    marginBottom: '16px',
    marginLeft: '10px',
    fontSize: '18px',
  },
  modalText: {
    marginBottom: '16px',
    fontSize: '14px',
    textAlign: 'left',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'left',
    marginBottom: '36px',
  },
}), { name: 'ExternalTradesModal' });

export default useStyles;
