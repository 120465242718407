/* eslint-disable react/destructuring-assignment */
import React from 'react';
import moment from 'moment';
import t from 'utils/translationHelper';

import { PaymentDto } from '@alpha/payments-dtos';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';

import NoOfApprovers from '../../../../../domain/Transactions/Dashboard/FxTradeTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import { formatCurrency } from '../../../../../utils/currency.helpers';

type Props = {
  values: PaymentDto[];
};

export function cutString(value: string | undefined, limit = 10): JSX.Element {
  if (!value) return <></>;
  if (value.length > limit) {
    return (
      <APTooltip title={value} placement="bottom-start">
        <div>{`${value.substr(0, limit)}...`}</div>
      </APTooltip>
    );
  }

  return <>{value}</>;
}
const ApprovalSummary: React.FC<Props> = ({ values }) => {

  const rows = values.map((p: PaymentDto) => ({
    beneficiaryName: p.beneficiaryName,
    amount: p.amount,
    reference: p.reference,
    purpose: p.purpose,
    date: p.date,
    currencyCode: p.currency,
    approvalInfo: p.approvalInfo,
    uploadedBy: p.uploadedBy,
    uploadedDate: p.uploadedDate,
  }));
  return (
    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <tr>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('beneficiary')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('amount')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('reference')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('purpose')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('date')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('approvals_required')}
        </td>
      </tr>
      <tr style={{ borderBottom: '1px solid rgba(112,112,112,0.3)', height: '8px' }} />
      <tr style={{ height: '16px' }} />
      {rows.map((r, i) => (
        <>
          {
            i > 0 && (
              <>
                <tr style={{ borderBottom: '1px solid rgba(112,112,112,0.3)', height: '8px' }} />
                <tr style={{ height: '8px' }} />
              </>
            )
          }
          <tr>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(r.beneficiaryName)}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(formatCurrency(r.currencyCode, r.amount))}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(r.reference)}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(r.purpose)}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(moment(r.date!).format('DD/MM/YY').toString())}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>
              <NoOfApprovers
                uploadedBy={r.uploadedBy}
                uploadedDate={r.uploadedDate}
                noOfApprovals={r.approvalInfo.approvals?.length}
                approvedBy={r.approvalInfo.approvals || []}
                approvalsRequired={r.approvalInfo.approvalsRequired}
              />
            </td>
          </tr>
        </>
      ))}
    </table>
  );
};

export default ApprovalSummary;
