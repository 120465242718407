import { useDispatch, useSelector } from 'react-redux';

import { TStore } from '../store';
import * as actions from '../store/authy/actions';
import { TAuthyBeneficiaryType, TAuthyStatus } from '../store/authy/reducer';

import useAlphaSnackbar from './useAlphaSnackbar';

const useBeneficiary = () => {
  const snackbar = useAlphaSnackbar();
  const authyStatus = useSelector<TStore,
    TAuthyStatus | undefined>((store: TStore) => store.authy.status);
  const dispatch = useDispatch();

  const approveBeneficiary = (payload: TAuthyBeneficiaryType) => {
    dispatch(actions.initiateBeneficiary({ ...payload }));
  };

  return {
    snackbar,
    authyStatus,
    approveBeneficiary,
  };
};

export default useBeneficiary;
