import React, { Dispatch, SetStateAction } from 'react';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Typography } from '@alpha/ui-lib/ui/external';

import useAuthorization from '../../../../hooks/useAuthorization';
import { UserRole } from '../../../../models/user';
import CurrencyPair from '../../../International/CurrencyPair/CurrencyPair';
import { FundingOption, Section } from '../CreatePaymentDrawer';
import { FormikType } from '../CreatePaymentDrawer/formData';
import Summary from '../Summary';

type Props = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  values: FormikType,
  handleFormSubmit: (e: any) => void;
  handleSetSection: (_section: Section) => void;
  sendEmailNotification: boolean;
  setSendEmailNotification: Dispatch<SetStateAction<boolean>>;
  fundingMethod: FundingOption;
};

const SendPayments: React.FC<Props> = ({
  setFieldValue,
  values,
  handleFormSubmit,
  handleSetSection,
  sendEmailNotification,
  setSendEmailNotification,
  fundingMethod,
}) => {

  const { authorized } = useAuthorization([[UserRole.PAYMENTS_APPROVER_OWN]]);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography style={{
          fontWeight: '1000',
          fontSize: '14px',
          color: 'black',
          marginBottom: '32px',
        }}
        >
          {t('summary')}
        </Typography>
        {fundingMethod === 'Account' && (
          <>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('funding_method')}</Typography>
              <Typography style={{ display: 'flex' }}>
                <CurrencyPair currencyCode={values.paymentCurrencyAccount!.currencyCode || ''} displayCode={false} />
                {values.paymentCurrencyAccount?.accountName
                  || values.paymentCurrencyAccount?.friendlyName}
              </Typography>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('currency')}</Typography>
              <Typography style={{ fontWeight: 'bold' }}>{values.paymentCurrencyAccount!.currencyCode}</Typography>
            </div>
          </>
        )}

        {fundingMethod === 'Spot' && (
          <>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('funding_method')}</Typography>
              <Typography style={{ display: 'flex', fontWeight: 600 }}>
                {t('spot_trade')}
              </Typography>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('payment_currency(buy)')}</Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                <CurrencyPair currencyCode={values.paymentCurrencyCode || ''} displayCode />
              </Typography>
            </div>

            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
            }}
            >
              <Typography>{t('sell_currency')}</Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                <CurrencyPair currencyCode={values.sellCurrencyCode || ''} displayCode />
              </Typography>
            </div>
          </>
        )}

        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px',
        }}
        >
          <Typography>{t('number_of_payments')}</Typography>
          <Typography style={{ fontWeight: 'bold' }}>{values.payments?.length || 0}</Typography>
        </div>
        <div>
          <div style={{
            position: 'relative', transform: 'translateY(-8px)', width: '100%', left: 0, margin: '32px 0',
          }}
          >
            <BaseDrawer.LineBreak />
          </div>
          <Typography style={{
            fontWeight: '1000',
            fontSize: '14px',
            color: 'black',
            marginBottom: '40px',
          }}
          >
            {t('payment_details')}
          </Typography>
          <div style={{ marginTop: '24px' }}>
            <Summary values={values} />
          </div>
        </div>
      </div>
      {!authorized && (
        <div style={{ marginTop: '24px' }}>
          <Checkbox
            testId="send-email-notifcation"
            label={t('send_an_email_notification_to_all_approvers')}
            checked={sendEmailNotification}
            onChange={(event) => { setSendEmailNotification(event.target.checked); }}
          />
        </div>
      )}
      <div style={{
        display: 'flex', flexDirection: 'row', padding: '36px 0 42px 0', justifyContent: 'space-between',
      }}
      >
        <div style={{
          position: 'absolute', transform: 'translateY(-42px)', width: '100%', left: 0,
        }}
        >

          <BaseDrawer.LineBreak />
        </div>
        <ActionButton
          style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }}
          onClick={() => {
            if (values?.isDraftPayment) {
              // Reset payments if editing draft payment, otherwise it adds a new payment
              // every time you go Back to Payments, even though it should be just one
              setFieldValue('payments', []);
            }
            handleSetSection('Beneficiaries');
          }}
        >
          {t('back_to_payments')}
        </ActionButton>
        <ActionButton
          disabled={!values.payments?.length}
          onClick={() => handleFormSubmit(values)}
          size="medium"
        >
          {t('confirm_and_send_payments')}
        </ActionButton>
      </div>
    </>
  );
};

export default SendPayments;
