import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    root: {
      paddingTop: '24px',
      paddingBottom: '30px',
      backgroundColor: '#fafafa',
      minHeight: 'calc(100vh - 310px)',
    },
    styledTitle: {
      fontSize: '28px',
      fontWeight: 400,
      marginTop: '5px',
      marginBottom: '5px',
    },
    panel: {
      gridGap: '24px',
      paddingBottom: '40px',
    },
    lastUpdatedAt: {
      alignItems: 'right',
      color: '#212529',
      fontSize: '10px',
      fontWeight: 300,
    },
    status: {
      minWidth: '150px',
      textAlign: 'center',
    },
    summaryHeader: (props: any) => ({
      width: props.tarfTrade ? '1200px' : '928px',
      padding: '20px',
      background: '#ffffff',
      borderRadius: '10px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
      '@media (max-width: 400px)': {
        marginTop: '10px',
        gridColumn: '1/1 !important',
      },
      fontWeight: typography.fontWeightSemiBold,
      fontSize: '16px',
      minWidth: '390px',
      '& > span': {
        paddingRight: '3px',
      },
    }),
    summaryTitle: {
      display: 'block',
      marginLeft: '23px',
      fontSize: '12px',
      fontWeight: 300,
      color: '#212529',
    },
    header: {
      display: 'flex',
      fontSize: '28px',
      fontWeight: 400,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      height: '9px',
      alignItems: 'center',
      justifyContent: 'left',
      display: 'flex',
    },
    summary: {
      fontWeight: 600,
      fontSize: '16px',
      marginLeft: '8px',
    },
    statsContainer: {
      marginLeft: '30px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'start',
      gap: 36,
    },
    emptyTable: {
      position: 'relative',
      marginBottom: '20px',
      '& .image': {
        height: '120px',
        width: '120px',
        '& img': {
          height: '60',
          width: '60px',
        },
      },
    },
  }),
  { name: 'Trade' },
);

export default useStyles;
