import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ palette }) => ({
    container: {
      width: '558px',
      boxSizing: 'content-box',
      padding: '0px',
      position: 'relative',
      height: '86px !important',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#272727',
      '& .MuiButton-label': {
        height: 'inherit',
      },
      '&.MuiButtonBase-root:hover': {
        backgroundColor: '#272727',

      },
    },
    contentWrapper: {
      height: '100%',
      width: 'calc(100% - 86px)',
      padding: '17px 23px 23px 20px',
    },
    title: {
      color: '#FFFFFF',
      textAlign: 'left',
      fontSize: '18px',
      marginBottom: '8px',
      textTransform: 'capitalize',
    },
    body: {
      '& p': {
        textTransform: 'none',
        textAlign: 'left',
        color: '#FFFFFF',
        fontSize: '12px',
        fontWeight: 300,
        marginBottom: '8px',
      },
    },
    badge: {
      zIndex: 2,
      height: '29px',
      display: 'flex',
      flexDirection: 'row',
      textTransform: 'none',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: palette.primary.main,
      borderRadius: '6px',
      padding: '8px',
      color: 'white',
      position: 'absolute',
      marginRight: '16px',
      top: '0px',
      right: '0px',
      transform: 'translateY(-50%)',
      '& h6': {
        marginLeft: '8px',
        fontWeight: 700,
      },
    },
    cursor: {
      cursor: 'default',
    },
    loadingState: {
      zIndex: 1,
      position: 'absolute',
      width: 558,
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }),
  { name: 'AuthBackupMethodCard' },
);

export default useStyles;
