import clipboardCross from 'assets/clipboardCross.svg';
import BackdropLoader from 'components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable from 'components/Table/EmptyTable';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import useReportsPolling, { DownloadType, FileType, PageType } from 'hooks/useReportsPolling';
import React, { useEffect, useState } from 'react';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapDrawdownStatusDisplay from 'utils/fxTrades/mapDrawdownStatusDisplay';
import t from 'utils/translationHelper';

import { AccountConfigurationFXDto, DrawdownDto } from '@alpha/fx-dtos';
import { Box, Button, Divider } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table/StyledGenericTable';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PadInfoTooltip from '../../PadInfoTooltip/PadInfoTooltip';
import ExportMenu from '../ExportMenu/ExportMenu';
import PadColumnValue from '../PadColumnValue/PadColumnValue';

import DrawdownActionDropdown from './DrawdownActionDropdown/DrawdownActionDropdown';
import useStyles from './Drawdowns.styles';

interface IProps {
  tradeId: string;
  drawdowns: DrawdownDto[];
  loading?: boolean;
  handleDrawdownContractNumberClick?: (drawdown: DrawdownDto) => void;
}

const Drawdowns: React.FC<IProps> = ({
  tradeId,
  drawdowns,
  loading,
  handleDrawdownContractNumberClick,
}: IProps) => {

  const classes = useStyles();
  const [accountConfiguration, setAccountConfiguration] = useState<AccountConfigurationFXDto>();
  const [columns, setColumns] = useState<ITableColumn[]>([]);
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);
  const isEmpty = drawdowns.length === 0;
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const { executeReportGeneration } = useReportsPolling(PageType.FX);
  const handleDownloadButton = async (drawdownId: string) => {
    if (drawdownId) {
      await executeReportGeneration(
        FileType.PDF, drawdownId, undefined, undefined, DownloadType.Drawdown,
      );
    }
  };

  const createTableData = () => drawdowns.map((column: DrawdownDto) => ({
    ...column,
    soldAmount: <span className={classes.amount}>{[formatNumber(column.soldAmount, 2), column.soldCurrencyCode].join(' ')}</span>,
    buyAmount: <span className={classes.amount}>{[formatNumber(column.buyAmount, 2), column.buyCurrencyCode].join(' ')}</span>,
    status: (<Status className={classes.status} variant={t(mapDrawdownStatusDisplay(column.status).variant)}>{t(mapDrawdownStatusDisplay(column.status).text) || '-'}</Status>),
    valueDate: formatIsoDate(column.valueDate) || '-',
    date: formatIsoDate(column.date) || '-',
    rate: <span className={classes.tableLink}>{column.rate.toFixed(4)}</span>,
    contractNumber: (
      <Button
        disableRipple
        className={classes.tableButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (handleDrawdownContractNumberClick) { handleDrawdownContractNumberClick(column); }
        }}
      >
        {t(column.contractNumber) || '-'}
      </Button>
    ),
    recipient: column.payments.length > 0 ? t('payments') : t('back_to_account'),

    pad: <PadColumnValue
      padEligibleItem={column}
      handleDrawdownAuthorisation={handleDrawdownContractNumberClick}
    />,
    // eslint-disable-next-line no-nested-ternary
    padCollected: column.padSettled
      ? <FontAwesomeIcon icon={faCircleCheck as IconProp} className={classes.settledIcon} />
      : (column.padSettled === false ? <FontAwesomeIcon icon={faCircleXmark as IconProp} className={classes.notSettledIcon} /> : ''),
    action: (
      <DrawdownActionDropdown
        drawdown={column}
        handleViewDrawdown={handleDrawdownContractNumberClick}
        handleDrawdownDownload={handleDownloadButton}
        displayMultiSelect={displayMultiSelect}
        setDisplayMultiSelect={setDisplayMultiSelect}
      />
    ),
  }));

  const generateColumns = () => {
    const cols: ITableColumn[] = [
      {
        header: t('trade_number'),
        accessor: 'contractNumber',
      },
      {
        header: t('trade_date'),
        accessor: 'date',
      },
      {
        header: t('sold_amount'),
        accessor: 'soldAmount',
      },
      {
        header: t('rate'),
        accessor: 'rate',
      },
      {
        header: t('buy_amount'),
        accessor: 'buyAmount',
      },
      {
        header: t('value_date'),
        accessor: 'valueDate',
      },
      {
        header: t('recipient_IT_'),
        accessor: 'recipient',
      },
      {
        header: t('status'),
        accessor: 'status',
      },
      {
        header: t('action'),
        accessor: 'action',
      },
    ];
    if (accountConfiguration?.padAllowed) {
      cols.splice(7, 0, {
        header: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {t('pad')}
            {' '}
            <PadInfoTooltip />
          </div>
        ),
        accessor: 'pad',
      });
      cols.splice(8, 0, { header: t('pad_collected'), accessor: 'padCollected' });
    }
    setColumns(cols);
  };

  const getAccountConfiguration = async () => {
    try {
      const result = await FXTradeService.getAccountConfiguration();
      setAccountConfiguration(result);
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_account_configuration_data'));
      logError({ action: 'Error loading account configuration', error });
    }
  };

  useEffect(() => {
    getAccountConfiguration();
  }, []);

  useEffect(() => {
    generateColumns();
  }, [accountConfiguration, drawdowns]);

  return (
    <div className={classes.root}>
      <div className={classes.drawdownTable}>
        <Typography variant="subtitle1" className={classes.header}>
          {t('drawdowns')}
          {' '}
          {loading ? '' : `(${drawdowns.length})`}
        </Typography>
        <ExportMenu
          downloadType={DownloadType.TradeWithDrawdowns}
          tradeId={tradeId}
        />
      </div>
      <Divider />

      {loading && (
        <Box className={classes.emptyTable}>
          <BackdropLoader testId="loader" />
        </Box>
      )}

      {!loading && (isEmpty ? (
        <Box className={classes.emptyTable}>
          <EmptyTable
            title={t('no_drawdowns')}
            subtitle={t('you_do_not_have_any_drawdowns_currently')}
            icon={clipboardCross}
          />
        </Box>
      ) : (
        <StyledGenericTable
          testId="trade-drawdown-table"
          columns={columns}
          data={createTableData()}
        />
      ))}
    </div>
  );
};

export default Drawdowns;
