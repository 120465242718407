import React from 'react';

import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs/StyledTabsWrapper';

import { ISelectedAccount } from '../AccountInfoDrawer';

import HeaderButtons from './HeaderButtons/HeaderButtons';
import HeaderText from './HeaderText/HeaderText';
import useStyles from './styles';

interface IProps {
  selectedAccount: ISelectedAccount | undefined;
  viewOnly?: boolean,
  eMoneyDisabled?: boolean;
}

const Header: React.FC<IProps> = ({
  selectedAccount,
  viewOnly,
  eMoneyDisabled,
}: IProps) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <HeaderText
        selectedAccount={selectedAccount}
      />
      <div className={styles.tabs}>
        <StyledTabsWrapper
          testId="header-tabs"
          tabTitles={['info']}
          tabIndex={0}
          iconTitles={false}
          disabledTabs={[1]}
          handleChange={() => {}}
        />
        {
          !viewOnly
          && (
            <HeaderButtons
              currencyAccountId={selectedAccount?.account.id || '-'}
              eMoneyDisabled={eMoneyDisabled}
            />
          )
        }
      </div>
    </div>
  );
};

export default Header;
