import { makeStyles } from '@alpha/ui-lib/theme';

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  greenText: {
    fontWeight: theme.typography.fontWeightSemiBold,
    color: 'rgb(9, 131, 117)',
  },
  table: {
    '& td:not(:nth-child(1)), & th:not(:nth-child(1))': { paddingLeft: '10px !important', paddingRight: '4px !important' },
    '& td:nth-child(1), & tr:not(:nth-child(1)) th:nth-child(1)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(5), & tr:not(:nth-child(1)) th:nth-child(5)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(8), & tr:not(:nth-child(1)) th:nth-child(8)': { borderRight: '#eee solid 1px !important' },

    '& thead tr:nth-child(1) th:not(:last-child)': { borderRight: '#eee solid 1px !important' },

    borderCollapse: 'separate',
    '& thead': {
      position: 'sticky',
      top: '221px',
      marginTop: '-55px',
      zIndex: '100',
    },

    '& thead tr th': {
      backgroundColor: '#ffffff !important',
    },
  },
  tableWithIAT: {
    '& td:nth-child(5), & tr:not(:nth-child(1)) th:nth-child(5)': { borderRight: 'none !important' },
    '& td:nth-child(6), & tr:not(:nth-child(1)) th:nth-child(6)': { borderRight: '#eee solid 1px !important' },
  },
}), { name: 'PersonalPermissionMatrixTable' });

export default useStyles;
