import React from 'react';
import t from 'utils/translationHelper';
import { formatCurrency } from 'utils/currency.helpers';

import { CreditFacilityDto } from '@alpha/credit-dtos';
import { Box, Divider } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';

import useStyles from './MarginHeldAmounts.styles';

interface IProps {
  amounts: CreditFacilityDto['marginHeldAmounts'];
}

const MarginHeldAmounts: React.FC<IProps> = ({ amounts }) => {

  const classes = useStyles();
  return (
    <>
      {amounts.map((amount, index) => (
        <>
          {index !== 0 && <Divider className={classes.divider} />}
          <Box display="flex" gridGap={8}>
            <Box display="flex" alignItems="center">
              <Flag
                code={amount.currencyCode}
                size={20}
              />
              <span className={classes.currencyName}>
                {amount.currencyCode}
              </span>
            </Box>

            <Divider className={classes.verticalDivider} />

            <Box gridGap={8} display="flex" alignItems="center" width="200px" flexDirection="column">
              <Box
                marginTop="8px"
                marginLeft="8px"
                gridGap={8}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
                width="100%"
              >
                <Box className={classes.marginName}>{t('initial_margin')}</Box>
                <Box className={classes.marginAmount}>{formatCurrency(amount.currencyCode, amount.initialMargin)}</Box>
              </Box>
              <Box marginLeft="8px" gridGap={8} display="flex" alignItems="center" justifyContent="space-between" flexDirection="row" width="100%">
                <Box className={classes.marginName}>{t('variation_margin')}</Box>
                <Box className={classes.marginAmount}>{formatCurrency(amount.currencyCode, amount.variationMargin)}</Box>
              </Box>
            </Box>
          </Box>
        </>
      ))}
    </>
  );
};

export default MarginHeldAmounts;
