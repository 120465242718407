import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography, palette }) => ({
    termHeader: {
      padding: '20px',
      background: '#ffffff',
      borderRadius: '10px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
      '@media (max-width: 400px)': {
        marginTop: '10px',
        gridColumn: '1/1 !important',
      },
      fontWeight: typography.fontWeightSemiBold,
      fontSize: '16px',
      minWidth: '390px',
      '& > span': {
        paddingRight: '3px',
      },
    },
    listingContainer: {
      marginTop: '32px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    widget: {
      overflowY: 'auto',
      height: '394px',
      padding: '20px',
      background: '#ffffff',
      borderRadius: '10px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
      '@media (max-width: 400px)': {
        marginTop: '10px',
        gridColumn: '1/1 !important',
      },
      fontWeight: typography.fontWeightSemiBold,
      fontSize: '16px',
      minWidth: '390px',
      '& > span': {
        paddingRight: '3px',
      },
    },
    creditWidget: {
      minHeight: '440px',
    },
    labels: {
      margin: '0px 0px 0px 8px',
    },
    labelContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    mtmPercentage: {
      margin: '0px 5px 5px 5px',
    },
    marginStatus: {
      justifyContent: 'left',
      marginLeft: '37px',
      marginRight: '5px',
      marginTop: '4px',
      display: 'flex',
    },
    toolTip: {
      display: 'flex',
      justifyContent: 'center',
      padding: '2px',
    },
    tableButton: {
      textTransform: 'none',
      fontSize: 14,
      padding: 0,
      fontWeight: 600,
      color: 'rgb(9, 131, 117)',
      textDecoration: 'none',
      textAlign: 'left',
      justifyContent: 'left',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
    termsContainer: {
      fontWeight: typography.fontWeightSemiBold,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    termId: {
      textTransform: 'none',
      fontSize: 14,
      padding: 0,
      textAlign: 'left',
      justifyContent: 'left',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'transparent',
      },
    },
    title: {
      fontFamily: typography.fontFamily,
      fontSize: '16px',
      fontWeight: 600,
      padding: '10px',
      paddingLeft: '0px',
    },
    titleLimit: {
      fontWeight: 100,
      padding: '10px',
      fontSize: '16px',
      paddingLeft: '0px',
    },
    tableSubHeading: {
      fontSize: '12px',
      alignSelf: 'left',
      color: palette.grey[500],
    },
    tableWrapper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '0px 0px 8px 0px',
      justifyContent: 'space-between',
      '& .MuiTableCell-root': {
        'padding-right': '20px !important',
      },
      '& .MuiTableHead-root': {
        color: '#949494',
      },
      '@media (max-width: 1200px)': {
        '& .MuiTableCell-root': {
          'padding-right': '10px !important',
        },
        '& th.MuiTableHead-root, & td.MuiTableCell-root': {
          fontSize: '12px',
          '& span': {
            fontSize: '11px',
          },
        },
      },
      '& td': {
        padding: '10px 0px !important',
      },
    },
    emptyTable: {
      fontWeight: 100,
      '& .image': {
        height: '120px',
        width: '120px',
        '& img': {
          height: '60',
          width: '60px',
        },
      },
    },
    emptyTableUtilisation: {
      position: 'relative!important',
      marginBottom: '20px',
      '& .image': {
        height: '120px',
        width: '120px',
        '& img': {
          height: '60',
          width: '60px',
        },
      },
    },
    columns: {
      color: '#9A9A9A',
    },
    graphTable: {
      marginTop: '20px',
      marginBottom: '20px',
      '& tr': {
        border: 'none',
      },
    },
    termOpenPositionTable: {
      '& tr': {
        border: 'none',
      },
    },
    link: {
      color: '#1E8777',
      textDecoration: 'underline',
    },
    nonActiveLink: {
      color: '#9A9A9A',
      textDecoration: 'underline',
    },
    status: {
      textAlign: 'center',
      display: 'block',
    },
    statusContainer: {
      marginLeft: '16px',
      paddingLeft: '20px',
      borderLeft: '#e6e6e6 1px solid',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  }), { name: 'FacilityTermsTable' },
);

export default useStyles;
