import { AccountsTotalDto } from '@alpha/currency-accounts-dtos/lib/currencyAccounts/CurrencyAccountTotalDto';

import { TData } from '../components/InterAccountTransfer/Dropdown/Dropdown';
import { TCurrencyAccounts } from '../models/currencyAccounts';

export function mapAccountsToData(accountTotals: AccountsTotalDto): TData[] {
  const data: TData[] = [];
  accountTotals.currencyAccounts.forEach((currencyAccount) => {
    const currencyData: TData = {
      id: currencyAccount.id,
      friendlyName: currencyAccount.friendlyName,
      balance: currencyAccount.clearedBalance,
      accountType: currencyAccount.accountType,
      currencyCode: currencyAccount.currencyCode,
      iban: currencyAccount.iban,
      sortCode: currencyAccount.sortCode,
      swift: currencyAccount.swift,
      accountNumber: currencyAccount.accountNumber,
      reference: currencyAccount.reference,
    };
    data.push(currencyData);
  });
  accountTotals.marginAccounts.forEach((marginAccount) => {
    const marginData: TData = {
      id: marginAccount.id,
      friendlyName: marginAccount.friendlyName,
      balance: marginAccount.marginHeld,
      accountType: marginAccount.accountType,
      currencyCode: marginAccount.currencyCode,
      reference: marginAccount.reference,
    };
    data.push(marginData);
  });
  return data;
}

export function mapCurrencyAccountToData(
  currencyAccounts: TCurrencyAccounts,
  isTransfer: boolean,
  isExternal = false,
): TData[] {
  const data: TData[] = currencyAccounts.map((currencyAccount) => ({
    id: currencyAccount.id,
    friendlyName: currencyAccount.friendlyName,
    balance: !isTransfer ? currencyAccount.clearedBalance : currencyAccount.availableBalance,
    accountType: currencyAccount.accountType,
    currencyCode: currencyAccount.currencyCode,
    iban: currencyAccount.iban,
    sortCode: currencyAccount.sortCode,
    swift: currencyAccount.swift,
    accountNumber: currencyAccount.accountNumber,
    reference: currencyAccount.reference,
    externalAccount: isExternal,
    clientAccountName: currencyAccount.clientAccountName,
  }));
  return data;
}
