import React from 'react';
import clsx from 'clsx';
import t from 'utils/translationHelper';

import { IATDraftResponse } from '@alpha/iat-dtos';
import { Status } from '@alpha/ui-lib/ui/Status';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatNumber } from '../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../utils/formatIsoDate';

import Buttons from './Buttons';
import useStyles from './styles';

interface IProps {
  iatDraftResponse: IATDraftResponse;
  handleOpenIATDrawer: (iatDraftResponse: IATDraftResponse) => void
  handleOpenIATRejectModal: (iatDraftResponse: IATDraftResponse) => void
}

const Transfer: React.FC<IProps> = ({
  iatDraftResponse,
  handleOpenIATDrawer,
  handleOpenIATRejectModal,
}: IProps) => {

  const styles = useStyles();
  const transferAmount = iatDraftResponse.creditAmount
    ? `${formatNumber(iatDraftResponse.creditAmount, 2)} ${iatDraftResponse.creditCurrencyAccount.currencyCode}`
    : `${formatNumber(iatDraftResponse.debitAmount, 2)} ${iatDraftResponse.debitCurrencyAccount.currencyCode}`;

  return (
    <div className={styles.container}>
      <div className={clsx('referenceAndAmount')}>
        <div className="reference">
          <Typography className={styles.lightText} variant="subtitle1">{t('reference')}</Typography>
          <Typography className={styles.mainText} variant="subtitle1">{iatDraftResponse.reference || '-'}</Typography>
        </div>
        <Typography className={styles.mainText} variant="subtitle1">
          {transferAmount}
        </Typography>
      </div>
      <div className="transferAccounts">
        <div className="account">
          <Typography className={styles.mainText} variant="subtitle1">{iatDraftResponse.debitCurrencyAccount.friendlyName}</Typography>
          <Typography className={styles.lightText} variant="subtitle1">{iatDraftResponse.debitCurrencyAccount.iban || iatDraftResponse.debitCurrencyAccount.swift}</Typography>
        </div>
        <FontAwesomeIcon icon={faArrowRight as IconProp} />
        <div className="account">
          <Typography className={styles.mainText} variant="subtitle1">{iatDraftResponse.creditCurrencyAccount.friendlyName}</Typography>
          <Typography className={styles.lightText} variant="subtitle1">{iatDraftResponse.creditCurrencyAccount.iban || iatDraftResponse.creditCurrencyAccount.swift}</Typography>
        </div>
      </div>
      <div className={clsx('approvalInfoAndButtons', styles.borderBottom)}>
        <div className="approvalInfo">
          <div className="info">
            <Typography className={styles.lightText} variant="subtitle1">{formatIsoDate(iatDraftResponse.submittedByDateTime)}</Typography>
            <Typography className={styles.lightText} variant="subtitle1">{iatDraftResponse.submittedBy || ''}</Typography>
          </div>
          <div>
            <Status variant={iatDraftResponse.status === 'APPROVED' ? 'info' : 'default'}>
              {`${iatDraftResponse.approvals.length}/${iatDraftResponse.approvalsRequired}`}
            </Status>
          </div>
        </div>
        <div id={`buttons-${iatDraftResponse.id}`} className="buttons">
          <Buttons
            handleOpenIATDrawer={handleOpenIATDrawer}
            iatDraftResponse={iatDraftResponse}
            handleOpenIATRejectModal={handleOpenIATRejectModal}
          />
        </div>
      </div>
    </div>
  );
};

export default Transfer;
