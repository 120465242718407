/* eslint-disable max-lines-per-function */
import CreateBeneficiary from 'domain/Beneficiaries/CreateBeneficiary';
import { FormikProps } from 'formik';
import i18n from 'i18n/config';
import moment, { Moment } from 'moment';
import { MuiChipsInput } from 'mui-chips-input';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import isEmpty from 'utils/isEmpty';
import t from 'utils/translationHelper';

import { PaymentDto, PaymentPurposeDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';
import { Typography } from '@alpha/ui-lib/ui/external';
import { Input } from '@alpha/ui-lib/ui/Input';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BeneficiaryDropdownContainer from '../../../../../domain/Transactions/ManualPayments/Body/PaymentType/ManualPayment/BeneficiaryDropdown/BeneficiaryDropdownContainer';
import Authorization from '../../../../../hocs/Authorization';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import { UserRole } from '../../../../../models/user';
import PaymentsService from '../../../../../services/Payments/payments.service';
import InputAndLabel from '../../../../Inputs/Beneficiary/InputAndLabel';
import { FormikPaymentType, FormikType } from '../../CreatePaymentDrawer/formData';
import PaymentPurposeDropdown from '../../CreatePaymentDrawer/PaymentPurposeDropdown/PaymentPurposeDropdown';

import useStyles from './index.styles';
import PaymentContext from './paymentContext';
import Alert from 'components/Alert';

type Props = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  values: FormikType;
  singlePaymentForm: FormikProps<FormikPaymentType>;
  needMoreInfo: boolean;
  setNeedMoreInfo: React.Dispatch<React.SetStateAction<boolean>>;
  paymentDraft?: PaymentDto;
  setRefetch?: Function;
};

const AddPayment: React.FC<Props> = ({
  values,
  singlePaymentForm,
  needMoreInfo,
  setNeedMoreInfo,
  paymentDraft,
  setRefetch,
}) => {
  const styles = useStyles();
  const [beneficiaryDrawerOpen, setBeneficiaryDrawerOpen] = useState<boolean>(false);
  const [showInvoiceFields, setShowInvoiceFields] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();
  const [requiresAddress, setRequiresAddress] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [beneficiariesIsLoading, setBeneficiariesIsLoading] = useState<boolean>(false);

  const availableDateQuery = useQuery('getPaymentDates', () => PaymentsService.getAvailablePaymentDate(
    singlePaymentForm.values.beneficiary?.currencyCode || '',
    values.paymentCurrencyAccount?.currencyCode || '',
  ), {
    enabled: false,
    onError: () => {
      sb.trigger(t('could_not_load_payment_available_date'), 'error');
      logError({ action: 'Error loading payment available date' });
    },
  });
  const paymentPurposesQuery = useQuery('getPaymentPurpose', () => PaymentsService.getPaymentPurpose(
    singlePaymentForm.values.beneficiary?.bankCountryCode || '',
  ), {
    enabled: false,
    onError: () => {
      sb.trigger(t('could_not_load_payment_purpose'), 'error');
      logError({ action: 'Error loading payment purpose' });
    },
  });

  const handleDateChange = (_date: Moment) => {
    if (_date) {
      singlePaymentForm.setFieldValue('date', _date.format('YYYY-MM-DD'));
    }
  };

  const handleInvoiceDateChange = (_date: Moment) => {
    if (_date) {
      singlePaymentForm.setFieldValue('invoiceDate', _date.format('YYYY-MM-DD'));
    }
  };

  const handleInvoiceNumberChange = (e: any) => {
    singlePaymentForm.setFieldValue('invoiceNumber', e.target.value);
  };

  const calculateDisableDates = (_date: Moment) => {
    if (availableDateQuery.data) {
      const dates = availableDateQuery.data;
      const formattedDate = _date.format('YYYY-MM-DD');
      const todayDate = moment().format('YYYY-MM-DD');
      return (
        moment(formattedDate).isBefore(todayDate)
        || moment(formattedDate).isAfter(dates.latestDate)
        || dates.holidays.some((holiday) => moment(holiday.date).format('YYYY-MM-DD') === formattedDate)
      );
    }
    return false;
  };

  useEffect(() => {
    if (paymentDraft) {
      singlePaymentForm.setFieldValue('id', paymentDraft.id);
      singlePaymentForm.setFieldValue('amount', paymentDraft.amount);
      singlePaymentForm.setFieldTouched('amount', true);
      singlePaymentForm.setFieldValue('reference', paymentDraft.reference);
      singlePaymentForm.setFieldTouched('reference', true);
      singlePaymentForm.setFieldValue('purpose', paymentDraft.purpose);
      singlePaymentForm.setFieldTouched('purpose', true);
      singlePaymentForm.setFieldValue('date', paymentDraft.valueDate);
      singlePaymentForm.setFieldTouched('date', true);
      singlePaymentForm.setFieldValue('emailAddresses', paymentDraft.emailAddresses);
      singlePaymentForm.setFieldTouched('emailAddresses', true);
    }
  }, []);

  useEffect(() => {
    singlePaymentForm.setFieldValue('paymentOrigin', values.paymentOrigin);
  }, [values.paymentOrigin]);

  useEffect(() => {
    if (paymentDraft && paymentPurposesQuery.data) {
      const selectedPaymentPurpose = paymentPurposesQuery.data.purposes.find(
        (purpose) => purpose.code === paymentDraft.purpose,
      );
      if (selectedPaymentPurpose) {
        singlePaymentForm.setFieldValue('purpose', selectedPaymentPurpose);
      }
    }
  }, [paymentPurposesQuery.data]);

  useEffect(() => {
    if (availableDateQuery.error) sb.trigger(availableDateQuery.error);
  }, [
    availableDateQuery.error,
  ]);

  const handleDropdownChange = (event: any) => {
    if (paymentPurposesQuery.data) {
      const selectedPaymentPurpose = paymentPurposesQuery.data.purposes.find(
        (purpose) => purpose.description === event.target.value,
      );

      if (selectedPaymentPurpose) {
        singlePaymentForm.setFieldValue('purpose', selectedPaymentPurpose);
      }

      if (selectedPaymentPurpose?.type === 'Service') {
        setShowInvoiceFields(true);
        singlePaymentForm.setFieldValue('purposeType', 'Service');
        singlePaymentForm.setFieldTouched('invoiceDate', true);
      } else {
        setShowInvoiceFields(false);
        singlePaymentForm.setFieldValue('purposeType', 'Unspecified');
        singlePaymentForm.setFieldValue('invoiceNumber', null);
        singlePaymentForm.setFieldValue('invoiceDate', null);
      }
    }
  };

  const handleAmountChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const amount = parseFloat(event.target.value.replace(/,/g, ''));
    singlePaymentForm.setFieldValue('amount', amount, true);
    singlePaymentForm.setFieldTouched('amount', true, true);
  };

  useEffect(() => {
    if (singlePaymentForm.values.beneficiary?.currencyCode) {
      availableDateQuery.refetch();
      paymentPurposesQuery.refetch();
    }
    if (singlePaymentForm.values.beneficiary?.bankCountryCode && singlePaymentForm.values.beneficiary?.currencyCode) {
      getAvailablePaymentMethods(singlePaymentForm.values.beneficiary?.bankCountryCode, singlePaymentForm.values.beneficiary?.currencyCode);
    }
    if (requiresAddress
      && (isEmpty(singlePaymentForm.values.beneficiary?.addressLine1)
        || isEmpty(singlePaymentForm.values.beneficiary?.city)
        || isEmpty(singlePaymentForm.values.beneficiary?.state)
        || isEmpty(singlePaymentForm.values.beneficiary?.zip)
        || isEmpty(singlePaymentForm.values.beneficiary?.countryCode)
      )) {
      setNeedMoreInfo(true);
    } else {
      setNeedMoreInfo(false);
    }
  }, [singlePaymentForm.values.beneficiary, requiresAddress]);

  useEffect(() => {
    if (values.tradeDraftId) {
      singlePaymentForm.setFieldValue('tradeDraftId', values.tradeDraftId);
    }
  }, []);

  const getAvailablePaymentMethods = async (
    _bankCountryCode?: Readonly<string>,
    _currencyCode?: Readonly<string>,
  ) => {
    try {
      setIsLoading ? setIsLoading(true) : null;
      const response = await BeneficiariesService.getCountryRequirements(_bankCountryCode && _currencyCode
        ? [{ countryCode: _bankCountryCode, currencyCode: _currencyCode }] : []);
      setRequiresAddress(response[0].requiresAddress || false);
      setIsLoading ? setIsLoading(false) : null;
    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || t('there_was_an_error_retrieving_the_available_payment'));
      logError({ action: 'Error retrieving the available payment methods for the selected bank country', error: e });
    }
  };

  return (
    <form className={styles.container} onSubmit={singlePaymentForm.handleSubmit}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1">{t('beneficiary')}</Typography>
          <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
            <Typography
              variant="subtitle1"
              style={{ color: '#1E8777', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setBeneficiaryDrawerOpen(true)}
            >
              {t('create_new?')}
            </Typography>
          </Authorization>
        </div>
        <BeneficiaryDropdownContainer
          currencyCode={values.paymentCurrencyAccount?.currencyCode || ''}
          beneficiary={singlePaymentForm.values.beneficiary}
          setFieldValue={singlePaymentForm.setFieldValue}
          beneficiaryId={paymentDraft?.beneficiaryId || singlePaymentForm?.values?.beneficiary?.id}
          setRefetch={setRefetch}
          setIsLoading={setBeneficiariesIsLoading}
        />
      </div>
      {isLoading || beneficiariesIsLoading ? (<Loader testId="beneficiar-loader" style={{ marginTop: '120px', marginBottom: '120px' }} />) : (
        <>
          {needMoreInfo ? (
            <div className={styles.card}>
              <FontAwesomeIcon icon={faExclamationCircle} className={styles.warningIcon} />
              <div className={styles.header}>
                {t('payment_to_this_bene_cannot_be_made')}
              </div>
              <div className={styles.subHeader}>
                {t('this_bene_is_missing')}
                :
                <span className={styles.missingFields}>
                  {t('address')}
                  .
                </span>
              </div>
              <div className={styles.missingInfoTest}>
                {t('to_proceed_with_making_payments_to_this_beneficiary_you_must_update_the_required_information_and_save')}
              </div>
            </div>
          ) : (
            <>
              <InputAndLabel
                label={`${t('amount_currency')} ${values.paymentCurrencyAccount?.currencyCode}`}
                type="amount"
                name="amount"
                id="amount"
                testId="furtherToAccountNumber"
                onChange={handleAmountChange}
                onBlur={singlePaymentForm.handleBlur}
                value={!singlePaymentForm.touched.amount && singlePaymentForm.values.amount === 0 ? '' : singlePaymentForm.values.amount}
                error={
                  singlePaymentForm.touched.amount
                  && Boolean(singlePaymentForm.errors.amount)
                }
                helperText={
                  singlePaymentForm.touched.amount
                  && singlePaymentForm.errors.amount
                }
              />
              <div style={{ marginTop: '20px', width: '100%' }}>
                <APDatePicker
                  fullWidth
                  language={i18n.language}
                  label={t('payment_date')}
                  className="datePicker"
                  onChange={handleDateChange}
                  value={singlePaymentForm.values.date}
                  disabled={!availableDateQuery.data}
                  placeholder={t('payment_date~')}
                  shouldDisableDate={calculateDisableDates}
                  emptyLabel={t('select_date')}
                  autoOk
                />
              </div>
              <div style={{ margin: '10px auto', width: '100%' }}>
                <Alert
                  className={styles.alert}
                  variant="info"
                  text={t('payment_date_adjustment')}
                />
              </div>
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                <Typography variant="subtitle1" className="label">
                  {t('purpose_of_payment')}
                </Typography>
                {(paymentPurposesQuery?.data && !paymentPurposesQuery.data?.canBeFreeText) ? (
                  <PaymentPurposeDropdown
                    data={paymentPurposesQuery.data ? paymentPurposesQuery.data?.purposes : []}
                    handleChange={handleDropdownChange}
                    placeholderText={t('please_select_a_purpose_of_payment')}
                    data-testid="purpose"
                    selectName="purpose"
                    selectValue={(singlePaymentForm.values.purpose as PaymentPurposeDto)?.description}
                  />
                ) : (
                  <Input
                    onChange={singlePaymentForm.handleChange}
                    fullWidth
                    id="purpose"
                    variant="filled"
                    value={singlePaymentForm.values.purpose && typeof singlePaymentForm.values.purpose === 'string' ? singlePaymentForm.values.purpose : ''}
                    testId="purposeOfPayment-input"
                    helperText={singlePaymentForm.errors.purpose}
                    error={!!singlePaymentForm.errors.purpose}
                  />
                )}
              </div>
              <div style={{ marginTop: '20px', width: '100%', marginBottom: '20px' }}>
                <InputAndLabel
                  label={t('reference_(optional)')}
                  name="reference"
                  id="reference"
                  testId="reference"
                  onChange={singlePaymentForm.handleChange}
                  onBlur={singlePaymentForm.handleBlur}
                  value={singlePaymentForm.values.reference}
                  error={
                    singlePaymentForm.touched.reference
                    && Boolean(singlePaymentForm.errors.reference)
                  }
                  helperText={
                    singlePaymentForm.touched.reference
                    && singlePaymentForm.errors.reference
                  }
                />
              </div>
              {showInvoiceFields && (
                <div style={{ marginTop: '20px', width: '100%', marginBottom: '20px' }}>
                  <InputAndLabel
                    label={t('invoice_number')}
                    name="invoiceNumber"
                    id="invoiceNumber"
                    testId="invoiceNumber"
                    onChange={handleInvoiceNumberChange}
                    onBlur={singlePaymentForm.handleBlur}
                    value={singlePaymentForm.values.invoiceNumber}
                    error={
                      singlePaymentForm.touched.invoiceNumber
                      && Boolean(singlePaymentForm.errors.invoiceNumber)
                    }
                    helperText={
                      singlePaymentForm.touched.invoiceNumber
                      && singlePaymentForm.errors.invoiceNumber
                    }
                  />
                </div>
              )}
              {showInvoiceFields && (
                <div style={{ marginTop: '20px', width: '100%' }}>
                  <APDatePicker
                    fullWidth
                    language={i18n.language}
                    label={t('invoice_date')}
                    className="datePicker"
                    onChange={handleInvoiceDateChange}
                    value={singlePaymentForm.values.date}
                    placeholder={t('invoice_date')}
                  />
                </div>
              )}
              <div style={{ marginTop: '20px' }}>
                <Typography variant="subtitle1">{t('optional_email')}</Typography>
                <MuiChipsInput
                  data-testid="payment-bene-email-addresses"
                  className={styles.chip}
                  value={singlePaymentForm.values?.emailAddresses ?? []}
                  fullWidth
                  onChange={(chips: string[]) => {
                    singlePaymentForm.setFieldValue('emailAddresses', chips);
                  }}
                  error={Boolean(singlePaymentForm.errors.emailAddresses)}
                  helperText={
                    singlePaymentForm.errors.emailAddresses?.length
                      ? singlePaymentForm.errors.emailAddresses : ''
                  }
                  placeholder={t('enter_save_email')}
                />
              </div>
              {!paymentDraft && (
                <div style={{ textAlign: 'right', marginTop: '32px' }}>
                  <ActionButton
                    onClick={() => {
                      singlePaymentForm.setFieldValue('singlePayment', false);
                      singlePaymentForm.submitForm();
                    }}
                    style={{
                      color: !singlePaymentForm.isValid ? '#B2B2B2' : 'black',
                      background: '#F7F7F7',
                    }}
                    disabled={!singlePaymentForm.isValid}
                    startIcon={<FontAwesomeIcon icon={faPlusCircle as IconProp} />}
                  >
                    {t('save_payment_and_add_another')}
                  </ActionButton>
                </div>
              )}
              <PaymentContext.Provider value={{
                currencyCode: values.paymentCurrencyAccount!.currencyCode,
                currency: values.paymentCurrencyAccount!.currency,
                ...singlePaymentForm,
              }}
              >
                <CreateBeneficiary
                  open={beneficiaryDrawerOpen}
                  header={`${t('create_payment')} / ${t('new_beneficiary')}`}
                  handleDrawerClose={() => setBeneficiaryDrawerOpen(false)}
                  firstPartyFlow={false}
                />
              </PaymentContext.Provider>
            </>
          )}
        </>
      )}
    </form>
  );
};

export default AddPayment;
