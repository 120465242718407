export interface ICountryMapping {
  [countryCode: string]: { separatorInsertionIndex: number, separator: string}
}

export const countryMapping: ICountryMapping = {
  GB: { separatorInsertionIndex: 2, separator: '-' },
  AU: { separatorInsertionIndex: 3, separator: ' ' },
  ZA: { separatorInsertionIndex: 3, separator: ' ' },
};

const insertSeparator = (
  bankCode: string,
  index: number,
  separator: string,
) => {
  const newBankCode = bankCode.split('');
  let seperatorsAdded = 0;
  let i = index;
  while (seperatorsAdded < 2 && i < bankCode.length) {
    newBankCode.splice(i, 0, separator);
    seperatorsAdded += 1;
    i += (index + 1);
  }

  return newBankCode.join('');
};

export const formatCurrencyAccountBankCode = (
  bankCode: string,
  countryCode: keyof ICountryMapping,
): string => {
  if (countryCode in countryMapping) {
    return insertSeparator(bankCode, countryMapping[countryCode].separatorInsertionIndex,
      countryMapping[countryCode].separator);
  }
  return bankCode;
};
export default formatCurrencyAccountBankCode;
