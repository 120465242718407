import React, { useEffect, useState } from 'react';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { PaymentDto } from '@alpha/payments-dtos';
import t from 'utils/translationHelper';

import clipboardCross from '../../../../assets/clipboardCross.svg';
import BackdropLoader from '../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import Search from '../../../../components/Organisms/Search';
import EmptyTable from '../../../../components/Table/EmptyTable';
import useSearch, { TSearchParams } from '../../../../hooks/useSearch';
import AccountInfoDrawer, { ISelectedAccount } from '../../../CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';

import BeneficiaryInfoDrawer from './BeneficiaryInfoDrawer/BeneficiaryInfoDrawer';
import Table from './Table/Table';

interface IProps {
  batchId: string;
  currencyPairId?: string;
  postApproval: boolean;
  sellCurrencyCode: string;
  buyCurrencyCode: string;
  fixedSide: string;
}
export type PaymentSummaryBeneficiary = BeneficiaryDto | undefined;

const createSearchParams = (
  batchId: string,
  sellCurrencyCode: string,
  buyCurrencyCode: string,
  fixedSide: string,
  skip: number,
  currencyPairId?: string,
): TSearchParams => {
  if (currencyPairId) {
    return {
      baseUrl: `/payments/batches/released/${batchId}/payments/search`,
      queryParams: {
        batchcurrencyid: currencyPairId,
        skip,
        take: 10,
      },
    };
  }
  return {
    baseUrl: `/payments/batches/${batchId}/payments/search`,
    queryParams: {
      sellcurrencycode: sellCurrencyCode,
      buycurrencycode: buyCurrencyCode,
      fixedside: fixedSide,
      skip,
      take: 10,
    },
  };
};

const PaymentSummary: React.FC<IProps> = (props: IProps) => {
  const {
    batchId,
    sellCurrencyCode,
    buyCurrencyCode,
    fixedSide,
    currencyPairId,
    postApproval,
  } = props;
  const {
    items,
    loading,
    searchText,
    skip,
    handleNewSearch,
    handleInitialSearch,
    handleNextPage,
    handlePreviousPage,
    setSearchText,
  } = useSearch();


  const [beneficiaryId, setBeneficiaryId] = useState<string>();
  const [selectedCurrencyAccount, setSelectedCurrencyAccount] = useState<ISelectedAccount>();

  const searchParams: TSearchParams = createSearchParams(
    batchId,
    sellCurrencyCode,
    buyCurrencyCode,
    fixedSide,
    skip,
    currencyPairId,
  );

  useEffect(() => {
    handleInitialSearch(searchParams);
  }, []);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.searchtext = e.target.value;
    }
    await handleNewSearch(searchParams);
  };

  return (
    <div style={{ position: 'relative', minHeight: '350px' }}>
      <div style={{ width: '400px' }}>
        <Search
          testId="random"
          totalItems={items?.items.total || 0}
          placeholder={t('search_beneficiary_name_or_payment_ref')}
          onChange={handleInputChange}
        />
      </div>
      {
        !items?.items.records.length && !loading
          ? (
            <div style={{ height: '341px', position: 'relative', marginBottom: '41px' }}>
              <EmptyTable
                icon={clipboardCross}
                title={t('no_payment_summary')}
                subtitle={
                  !searchText ? t('we_were_unable_to_retrieve_your_payment_summary_for_this_current_pair')
                    : t('no_results_found_for_this_search_term')
                }
              />
            </div>
          )
          : (
            <>
              <Table
                setSelectedCurrencyAccount={setSelectedCurrencyAccount}
                data={(items?.items.records as PaymentDto[]) || []}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                hasNext={items?.hasNext || false}
                hasPrevious={items?.hasPrevious || false}
                isSameCurrency={Boolean(sellCurrencyCode === buyCurrencyCode)}
                postApproval={postApproval}
                setBeneficiary={setBeneficiaryId}

              />
              <BeneficiaryInfoDrawer
                paymentSummaryBeneficiaryId={beneficiaryId}
                onClose={() => setBeneficiaryId(undefined)}
                open={Boolean(beneficiaryId)}
                setPaymentSummaryBeneficiaryId={setBeneficiaryId}
              />
              <AccountInfoDrawer
                open={Boolean(selectedCurrencyAccount)}
                selectedAccount={selectedCurrencyAccount}
                viewOnly
                handleOpenDrawer={() => setSelectedCurrencyAccount(undefined)}
              />
            </>
          )
      }
      {
        loading
        && <BackdropLoader testId="loader" />
      }
    </div>
  );
};

export default PaymentSummary;
