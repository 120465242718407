import React from 'react';

import { IATDraftResponse } from '@alpha/iat-dtos';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import clipboardCross from '../../../../assets/clipboardCross.svg';
import DrawerBackdropLoader from '../../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import EmptyTable from '../../../../components/Table/EmptyTable';

import useStyles from './styles';
import Transfer from './Transfer';

interface IProps {
    pendingTransfers: IATDraftResponse[];
    loading: boolean;
    handleOpenIATDrawer: (iatDraftResponse: IATDraftResponse) => void
    handleOpenIATRejectModal: (iatDraftResponse: IATDraftResponse) => void
}

const Transfers: React.FC<IProps> = ({
  pendingTransfers,
  loading,
  handleOpenIATDrawer,
  handleOpenIATRejectModal,
}: IProps) => {
  const styles = useStyles();

  if (loading) {
    return <DrawerBackdropLoader display={loading} text="Retrieving pending transactions" />;
  }

  if (pendingTransfers.length) {
    return (
      <div className={styles.container}>
        <div className={styles.pending}>
          <Typography variant="subtitle1">
            Pending (
            { pendingTransfers.length }
            )
          </Typography>
        </div>
        {pendingTransfers.map((iatDraftResponse) => (
          <Transfer
            iatDraftResponse={iatDraftResponse}
            handleOpenIATDrawer={handleOpenIATDrawer}
            handleOpenIATRejectModal={handleOpenIATRejectModal}
          />
        ))}
      </div>
    );
  }

  return (
    <EmptyTable title="No Pending Transfers" icon={clipboardCross} />
  );
};

export default Transfers;
