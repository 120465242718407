import PersonalPermissionsMatrixTable from 'components/Molecules/AccountSettings/PersonalPermissionsMatrixTable/PersonalPermissionsMatrixTable';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import { UserRole } from 'models/user';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AccountSettingsService from 'services/AccountSettings/accountSettings.service';
import { TStore } from 'store';
import t from 'utils/translationHelper';

import { UserAccountDto } from '@alpha/auth-dtos';
import { UpdateUsersRolesRequest } from '@alpha/profile-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import {
  Box, Collapse,
  IconButton, Typography,
} from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useSwitchAccount from 'hooks/useSwitchAccount';
import useStyles from '../PersonalSettings.styles';

const PersonalPortalPermissions: React.FC = () => {
  const [userAccounts, setUserAccounts] = useState<UserAccountDto[]>([]);
  const [originalSettings, setOriginalSettings] = useState<UserAccountDto[]>([]);
  const [formTouched, setFormTouched] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);
  const [canEdit, setCanEdit] = useState(false);
  const [padAllowedInAnyAccount, setPadAllowedInAnyAccount] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { isEMoneyDisabled } = useSwitchAccount();

  const { logError, logEvent } = useLog();
  const sb = useAlphaSnackbar();
  const classes = useStyles();


  const loadUserAccountSettings = async () => {
    try {
      const response = await AccountSettingsService.getUserAccountRoles();
      setUserAccounts(response);

      const allRoles = response.map((userAccount) => userAccount.roles);
      const allRolesFlatMap = allRoles.flatMap((role) => role);
      setCanEdit(allRolesFlatMap.includes(UserRole.USER_PERMISSION_MANAGER));

      const padAllowedConfigs = response.map((userAccount): boolean => userAccount.padAllowed);
      setPadAllowedInAnyAccount(padAllowedConfigs.includes(true));

      setOriginalSettings(JSON.parse(JSON.stringify(response)));
    } catch (error) {
      logError({ action: 'Error loading user account settings', error });
    }
  };

  const saveUserAccountSettings = async () => {
    const allSaveTasks: Promise<void>[] = [];
    for (let i = 0; i < userAccounts.length; i += 1) {
      const account = userAccounts[i];
      if (account.roles.includes(UserRole.USER_PERMISSION_MANAGER)) {
        try {
          const roles = isEMoneyDisabled() ? account.roles.filter(role => {
            const restrictedRoles = ['Payment', 'IAT'];
            if (restrictedRoles.find(r => role.includes(r))) {
              return false;
            } else {
              return true;
            }
          }) : account.roles;
          setSubmitting(true);
          const task = AccountSettingsService.postUserRoles({
            accountId: account.id,
            users: [{
              id: userId || '',
              roles,
            }] as UpdateUsersRolesRequest['users'],
          });

          allSaveTasks.push(task);
        } catch (e) {
          console.error(e);
          sb.trigger(t('could_not_update_permissions'));
          logError({ action: 'Error updating portal permissions' });
        }
      }
    }
    Promise.all(allSaveTasks).then(() => {
      loadUserAccountSettings();
      setSubmitting(false);
      sb.trigger(t('saved'), 'success');
      logEvent({ action: 'Successfully saved portal permissions' });
    }).catch((e: any) => {
      console.error(e);
      setSubmitting(false);
      sb.trigger(t('could_not_update_permissions'));
      logError({ action: 'Error updating portal permissions' });
    });
  };

  useEffect(() => {
    loadUserAccountSettings();
  }, []);

  useEffect(() => {
    function jsonEqual(a: any, b: any): boolean {
      return JSON.stringify(a) === JSON.stringify(b);
    }

    const isOriginal = jsonEqual(userAccounts.map((a) => a.roles.sort()),
      originalSettings.map((a) => a.roles.sort()));
    setFormTouched(!isOriginal);
  }, [userAccounts, originalSettings]);

  const statusBadge = canEdit ? (<Status className={classes.smallStatus} variant="info">{t('editable')}</Status>) : (<Status className={classes.smallStatus}>{t('non_editable')}</Status>);

  return (
    <Box flexDirection="rows" display="flex" gridGap="10px">
      <Box>
        <IconButton
          onClick={() => { setExpanded(!expanded); }}
          style={{ marginTop: '5px', borderRadius: 0 }}
          size="small"
        >
          <FontAwesomeIcon
            className={expanded ? classes.iconDown : classes.iconRight}
            icon={faChevronDown as IconProp}
            size="sm"
          />
        </IconButton>
      </Box>
      <Box minWidth="700px" flexGrow={1}>
        <Box
          display="flex"
          className={classes.stickySection}
        >
          <Box flexGrow={1}>
            <Typography className={classes.boxHeaderText}>
              <FontAwesomeIcon className="icon" icon={faList as IconProp} color="#1E8777" size="xs" />
              {' '}
              {t('portal_permissions')}
              {' '}
              {' '}
              {statusBadge}
            </Typography>

            <Typography variant="subtitle1" className={classes.subTitleText}>{t('manage_your_online_access_rights_here')}</Typography>
          </Box>
          <Collapse in={expanded}>
            <Box>
              <ActionButton
                disabled={!formTouched || submitting}
                loading={submitting}
                onClick={() => { saveUserAccountSettings(); }}
                style={{ borderRadius: '4px' }}
              >
                {t('save')}
              </ActionButton>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Collapse in={expanded}>
            <PersonalPermissionsMatrixTable
              setUserAccounts={setUserAccounts}
              userAccounts={userAccounts}
              readonly={submitting}
              padAllowed={padAllowedInAnyAccount}
            />
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalPortalPermissions;
