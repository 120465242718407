import clsx from 'clsx';
import useSwitchAccount from 'hooks/useSwitchAccount';
import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { User } from '@alpha/profile-dtos';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAccountSettings from '../../../../hooks/useAccountSettings';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import AccountSettingsService from '../../../../services/AccountSettings/accountSettings.service';
import DisableAccessActionDropDown from '../DisableAccessActionDropDown/DisableAccessActionDropdown';

import useStyles from './TeamPermissionsMatrixTable.styles';

interface ITeamPermissionsMatrixProps {
  accountUsers: User[];
  setAccountUsers?: React.Dispatch<React.SetStateAction<User[]>>,
  readonly?: boolean;
  padAllowed?: boolean;
  selectedAccount?: AccountDto;
}

const TeamPermissionsMatrixTable: React.FC<ITeamPermissionsMatrixProps> = (
  {
    accountUsers, setAccountUsers, readonly = false, padAllowed = false, selectedAccount,
  }: ITeamPermissionsMatrixProps,
) => {
  const classes = useStyles();
  const {
    portalPermissionColumns,
    portalPermissionColumnGroups: columnGroups,
    portalPermissionColumnGroupsWithoutPad: columnGroupsWithoutPad,
  } = useAccountSettings();
  const { isEMoneyDisabled } = useSwitchAccount();
  const [data, setData] = useState<any[]>([]);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const accountColumn: IStyledGenericTableProps['columns'] = [{
    header: t('user'),
    accessor: 'user',
    width: 150,
  }];

  const actionColumn: IStyledGenericTableProps['columns'] = [{
    header: t('action'),
    accessor: 'action',
    width: 100,
    align: 'center',
  }];

  const permissionColumns = portalPermissionColumns.flatMap((column) => {
    if (column.hidden || (column.forPadOnly && !padAllowed)) {
      return [];
    }
    if (isEMoneyDisabled() && (column.role.includes('Payments') || column.role.includes('IAT') || column.role.includes('Spot Inputter'))) {
      column.editable = false;
      column.caption = t('please_contact_alpha_fx_to_edit_this_permission');
    }
    const icon = column.caption ? (
      <APTooltip
        placement="bottom-start"
        title={column.caption ?? ''}
      >
        <Box display="inline-block"><FontAwesomeIcon icon={faInfoCircle as IconProp} /></Box>
      </APTooltip>
    ) : <></>;
    return {
      header:
        (
          <>
            {column.headerText || column.role}
            {' '}
            {icon}
          </>),
      accessor: column.role as string,
      width: column.width || 100,
    };
  });

  const columns = accountColumn.concat(permissionColumns);
  const columnsWithAction = columns.concat(actionColumn);

  const getCheckbox = (
    user: User,
    name: string,
    value?: boolean,
    setValue?: React.Dispatch<React.SetStateAction<boolean>>,
    disabled = false,
  ) => (
    <Checkbox
      testId={`team-user-permission-${name}`}
      label=""
      checked={value}
      disabled={disabled}
      onChange={(event) => {
        if (event.target.checked) {
          user.roles.push(name);
        } else {
          const index = user.roles.indexOf(name);
          user.roles.splice(index, 1);
        }

        const tableData = mapAccountDetailToData(accountUsers);
        setData(tableData);
        if (setAccountUsers) setAccountUsers([...accountUsers]);
      }}
    />
  );

  const loadTeamPermissionSettings = async (accountId: string) => {
    try {
      const userPermissions = await AccountSettingsService.getTeamPermissionSettings(accountId);

      const tableData = mapAccountDetailToData(userPermissions);
      setData(tableData);
    } catch (error) {
      sb.trigger(t('could_not_load_team_permissions'));
      logError({ action: 'Error loading team permissions', error });
    }
  };

  const mapAccountDetailToData = (users: User[]) => {
    // Put locked users at the bottom per business requirement
    const sortedUsers = [
      ...users.filter((u) => !u.locked),
      ...users.filter((u) => u.locked),
    ];

    return sortedUsers.map(
      (user) => {
        const result: {
          [key: string]: any,
        } = {
          user: (
            <APTooltip title={user.locked ? 'This user’s access has been revoked' : user.email}>
              <span className={user.locked ? classes.crossedOut : classes.greenText}>
                {user.firstName}
                {' '}
                {user.lastName}
              </span>
            </APTooltip>
          ),
          action: !user.locked ? (
            <DisableAccessActionDropDown
              firstName={user.firstName}
              lastName={user.lastName}
              userId={user.id}
              handleUserRevoked={() => {
                loadTeamPermissionSettings(selectedAccount!.id);
              }}
            />
          ) : null,
        };

        for (let i = 0; i < portalPermissionColumns.length; i += 1) {
          const col = portalPermissionColumns[i];
          const accessor = col.role as string;
          result[accessor] = getCheckbox(
            user,
            accessor,
            user.roles.includes(portalPermissionColumns[i].role),
            undefined,
            !col.editable || readonly || user.locked,
          );
        }
        return result;
      },
    );
  };

  useEffect(() => {
    const tableData = mapAccountDetailToData(accountUsers);
    setData(tableData);
  }, [accountUsers, readonly]);

  const withOrWithoutPADColumnGroups = padAllowed ? columnGroups : columnGroupsWithoutPad;

  if (data.length > 0) {
    return (
      <StyledGenericTable
        testId="preference-table"
        columns={columnsWithAction as any}
        data={data}
        className={clsx(classes.table, { [classes.padDisabledTable]: !padAllowed })}
        columnGroups={withOrWithoutPADColumnGroups}
      />
    );
  }

  return (
    <Loader testId="permission-table-loading" />
  );
};

export default TeamPermissionsMatrixTable;
