/* eslint-disable max-lines-per-function */
/* eslint-disable react/button-has-type */
import useForm from 'hooks/useForm';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { TBeneficariesStore } from 'store/beneficiaries/beneficiaries.reducer';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import { Alert } from '@alpha/ui-lib/ui/Alert';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Input } from '@alpha/ui-lib/ui/Input/Input';
import { StepsModal } from '@alpha/ui-lib/ui/Modal/StepsModal';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UploadFile from '../../Transactions/PaymentFileUpload/UploadFile/UploadFile';

import useStyles from './UploadModal.styles';

interface IProps {
  open: boolean;
  setOpen: () => void;
}

export type UploadForm = {
  name?: string,
};

const UploadModal: React.FC<IProps> = (props: IProps) => {
  const {
    open,
    setOpen,
  } = props;

  const initialFormValues = {
    name: '',
  };

  const classes = useStyles();
  const [currentSection, setCurrentSection] = useState(t('upload_name'));
  const benesUploadState = useSelector<TStore, TBeneficariesStore>((state) => state.beneficiaries);

  const validation = {
    name: yup.string()
      .required(t('Name is required'))
      .max(50, t('must_be_50_characters_or_less')),
  };

  useEffect(() => {
    if (benesUploadState.uploadModal === false) {
      handleClose();
    }
  }, [benesUploadState.uploadModal]);

  // UI FIELDS
  const tabs = [t('upload_name'), t('upload_box')];

  const handleClose = () => {
    form.setFieldValue('name', '');
    setCurrentSection(t('upload_name'));
    setOpen(false);
  };

  const form = useForm<UploadForm>(
    initialFormValues,
    validation,
    () => { },
    true,
    false,
  );

  useEffect(() => {
    if (form.values.name) {
      form.setFieldValue('name', form.values.name);
    }
  }, [form.values.name]);

  return (
    <StepsModal
      setOpen={setOpen}
      title={t('new_upload')}
      currentSection={currentSection}
      setCurrentSection={setCurrentSection}
      open={open}
      tabs={tabs}
      onClose={() => handleClose()}
    >
      <>
        {
          currentSection === t('upload_name')
            ? (
              <>
                <Box padding="24px">
                  <Box display="flex" justifyContent="space-between">
                    <Box width="100%">
                      <span className={classes.formLabel}>Name</span>
                      <br />
                      <Input
                        variant="outlined"
                        testId="batch-upload-name"
                        value={form.values.name}
                        onChange={(e) => {
                          form.setFieldValue('name', e.target.value);
                        }}
                        error={Boolean(form.errors.name)}
                        helperText={form.errors.name}
                        placeholder={t('enter_name')}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
                <ActionButton
                  className={classes.newButton}
                  onClick={() => {
                    form.handleSubmit();
                    setCurrentSection(t('upload_box'));
                  }}
                  disabled={!form.isValid}
                >
                  {t('next')}
                  <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '8px' }} />
                </ActionButton>

              </>
            ) : (
              <>
                <Box padding="24px">
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <Alert
                        className={classes.alert}
                        variant="info"
                        subText={t('please_note')}
                        text={t('batch_upload_info')}
                      />
                      <Alert
                        className={classes.alert}
                        variant="warning"
                        subText={t('please_note')}
                        text={t('batch_upload_limit')}
                      />
                      <UploadFile showGuide={false} beneBatchUpload batchName={form.values.name} />
                    </Box>
                  </Box>
                </Box>
              </>
            )
        }
      </>
    </StepsModal>
  );
};

export default UploadModal;
