import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import useStyles from './RightAlignedCta.styles';

export interface IProps {
  componentTestId?: string;
  numberOfValidPayments?: number;
  submitPayments?: () => void;
  cancelUpload?: () => void;
  isValidFxLimit?: boolean;
  fundingMethodValid?: boolean;
  isSubmitting?: boolean;
  isInvalid?: boolean;
}

const RightAlignedCta: React.FC<IProps> = (props: IProps) => {
  const {
    componentTestId,
    numberOfValidPayments,
    submitPayments,
    cancelUpload,
    isValidFxLimit,
    fundingMethodValid = false,
    isSubmitting = false,
    isInvalid = false,
  } = props;


  const styles = useStyles();

  return (
    <div data-testid={componentTestId} className={styles.rightAlignedCtas}>
      <ActionButton
        onClick={cancelUpload}
        endIcon={<FontAwesomeIcon icon={faTrash as IconProp} className={styles.cancelIcon} />}
        size="medium"
        style={{ background: '#F7F7F7', color: '#212529' }}
        disabled={isSubmitting}
      >
        {t('cancel_upload')}
      </ActionButton>
      {(numberOfValidPayments && numberOfValidPayments > 0) ? (
        <div className={styles.submitButtonContainer}>
          <ActionButton
            className={styles.submitButton}
            onClick={submitPayments}
            disabled={!fundingMethodValid || isSubmitting || !isValidFxLimit || isInvalid}
            loading={isSubmitting}
          >
            {`${t('submit_valid_payment')} (${numberOfValidPayments || '-'})`}
          </ActionButton>
        </div>
      ) : null}
    </div>
  );
};

export default RightAlignedCta;
