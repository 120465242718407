import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import useSwitchAccount from '../../../../hooks/useSwitchAccount';
import DrawerBackdropLoader from '../../../Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';

import Body from './Body/Body';
import Header from './Header/Header';
import useStyles from './SwitchAccountDrawer.styles';

export interface IAccounts {
  id: string;
  name: string
}

interface IProps {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const SwitchAccountDrawer: React.FC<IProps> = (props: IProps) => {
  const { getUserAccounts } = useSwitchAccount();
  const { logError } = useLog();
  const [accounts, setAccounts] = useState<AccountDto[]>([]);
  const sb = useAlphaSnackbar();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const { open, onClose } = props;
  const [filterAccounts, setFilterAccounts] = useState<string>('');
  const handleKeyUp = (e: any): void => {
    setFilterAccounts(e.target.value);
  };

  const getAllAccounts = async (): Promise<void> => {
    try {
      setLoading(true);
      setAccounts(await getUserAccounts());
    } catch (error) {
      sb.trigger(error?.message || t('there_was_an_error_retrieving_your_accounts_for_your_user'));
      logError({ action: 'Error getting user accounts', error });
    } finally {
      setTimeout(() => setLoading(false), 1000);
    }
  };

  useEffect(() => {
    if (open) getAllAccounts();
  }, [open]);

  const handleOnClose = (): void => {
    onClose(false);
    setFilterAccounts('');
  };

  return (
    <BaseDrawer.Drawer
      className={classes.drawerWrapper}
      open={open}
      onClose={() => handleOnClose()}
      anchor="right"
    >
      <Header handleKeyUp={handleKeyUp} />
      {
        accounts.length > 0
        && (
          <Body
            onClose={onClose}
            setFilterAccounts={setFilterAccounts}
            accounts={accounts}
            filterAccounts={filterAccounts}
          />
        )
      }
      <DrawerBackdropLoader display={loading} text={t('retrieving_accounts_for_your_user')} />
    </BaseDrawer.Drawer>
  );
};

export default SwitchAccountDrawer;
