import React, { useState } from 'react';

import { MfaMethod } from '@alpha/profile-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { RadioButtonGroup } from '@alpha/ui-lib/ui/RadioButton';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import authyIcon from '../../../../assets/authyIcon.svg';
import smsIcon from '../../../../assets/smsIcon.png';
import APModal from '../../../../components/Modals/APModal/APModal';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import AuthService from '../../../../services/Auth/auth.service';
import t from 'utils/translationHelper';

import useStyles from './index.styles';

type Props = {
  open: boolean;
  handleClose: () => void;
  mfaMethod?: MfaMethod;
  loadProfile?: () => void;
}

const AuthMethodModal: React.FC<Props> = (
  {
    open, handleClose, mfaMethod, loadProfile,
  }: Props,
) => {

  const styles = useStyles();
  const [mfaMethodPreference, setMfaMethodPreference] = useState(mfaMethod as MfaMethod);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const updateMethod = async () => {
    try {
      await AuthService.updateMfaMethod(mfaMethodPreference);
      if (loadProfile) { loadProfile(); }
      handleClose();
    } catch (e) {
      sb.trigger(t('could_not_update_mfa_method'));
      logError({ action: 'Error updating Mfa method', error: e });
    }
  };

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <>
        <APModal.Header
          onClose={() => {
            handleClose();
          }}
          showCloseButton
        >
          <Typography className={styles.modalHeader}>
            {t('select_a_preferred_authentication_method')}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={styles.container}>
          <div className={styles.inputContainer}>
            <div>
              <Typography>
                {t('please_select_your_default_method_of_authentication')}
                {' '}
              </Typography>
              <Typography>
                {t('if_your_preference_changes_this_can_be_edited_through_the_account_settings_page')}
              </Typography>
              <RadioButtonGroup
                className={styles.radioButtons}
                testId="auth-method"
                orientation="vertical"
                onChange={(event) => { setMfaMethodPreference(event.target.value as any); }}
                value={mfaMethodPreference}
                items={[
                  {
                    label: <Box display="flex">
                      <img src={authyIcon} alt="authyIcon" height="30px" width="30px" className={styles.icons} />
                      <Typography className={styles.labels}>Authy</Typography>
                    </Box>,
                    value: 'METHOD_AUTHY',
                    testId: 'auth-method-authy',
                  },
                  {
                    label: <Box display="flex">
                      <img src={smsIcon} alt="smsIcon" height="30px" width="30px" className={styles.icons} />
                      <Typography className={styles.labels}>SMS</Typography>
                    </Box>,
                    value: 'METHOD_SMS',
                    testId: 'auth-method-sms',
                  },
                ]}
              />

            </div>
          </div>

          <Box className={styles.footer}>
            <ActionButton style={{ borderRadius: '4px' }} onClick={updateMethod}>{t('save')}</ActionButton>
          </Box>
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default AuthMethodModal;
