import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { first } from 'lodash';

import { Approver } from '@alpha/auth-dtos';
import { ApproversNotifyRequest } from '@alpha/bene-dtos';
import {
  AccountConfigurationPaymentDto,
  BatchPaymentsUpdateRequest,
  BatchUploadRequest,
  CountryPaymentPurposesDto,
  ExpiredPaymentsResponse,
  PaymentBatchDto,
  PaymentDto,
  PaymentFilterOptionsDto,
  PaymentsApprovalRequest,
  PaymentsApprovalResponse,
  PaymentsSubmissionRequest,
  PaymentStatus,
} from '@alpha/payments-dtos';

import { FileType } from '../../hooks/useReportsPolling';
import { TReportGenerationResponse } from '../../models/currencyAccounts';
import {
  IGetPresignedUrlInstanceParams,
  PaymentRouteEnum,
  TBatchUploadLinkDto,
  TDate,
  TPaymentSubmissionResponse,
} from '../../models/payments';
import { TStatementData } from '../../models/statements';
import instance from '../Axios/instance';

export class PaymentsService {
  public static async getPresignedUrl(
    batchUploadRequest: BatchUploadRequest,
  ): Promise<TBatchUploadLinkDto> {
    const requestParams: IGetPresignedUrlInstanceParams = {
      method: 'post',
      url: 'payments/batches',
      data: batchUploadRequest,
    };
    const response: AxiosResponse<TBatchUploadLinkDto> = await instance(
      requestParams,
    );
    return response.data;
  }

  public static async getSinglePayment(
    id: string,
  ): Promise<PaymentDto> {
    const response = await instance.get(
      `/payments/payments/${id}`,
    );
    return response.data;
  }

  public static async putPaymentFile(
    presignedUrl: string,
    file: File,
  ): Promise<void> {
    return axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
  }

  public static async postReportBatchGeneration(
    type: FileType,
    batchId: string,
  ): Promise<string> {
    return (await this.postReportBatchGenerationAsync(type, batchId)).executionArn;
  }

  private static async postReportBatchGenerationAsync(
    type: FileType,
    batchId: string,
  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/payment-batch/${batchId}?type=${type}`,
    );

    return response.data;
  }

  public static async postReportSingleGeneration(
    type: FileType,
    batchId: string,
  ): Promise<string> {
    return (await this.postReportSingleGenerationAsync(type, batchId)).executionArn;
  }

  private static async postReportSingleGenerationAsync(
    type: FileType,
    batchId: string,
  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/payments/${batchId}?type=${type}`,
    );
    return response.data;
  }

  public static async postReportFxGeneration(
    type: FileType,
    tradeId: string,
  ): Promise<string> {
    return (await this.postReportFxGenerationAsync(type, tradeId)).executionArn;
  }

  private static async postReportFxGenerationAsync(
    type: FileType,
    tradeId: string,
  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/fx/${tradeId}?type=${type}`,
    );
    return response.data;
  }

  public static async postPaymentSummaryGeneration(
    type: FileType,
    paymentId: string,
  ): Promise<string> {
    return (await this.postPaymentSummaryGenerationAsync(type, paymentId)).executionArn;
  }

  private static async postPaymentSummaryGenerationAsync(
    type: FileType,
    paymentId: string,
  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `reports/payments/${paymentId}?type=${type}`,
    );
    return response.data;
  }

  public static async postTradeContactNoteGeneration(
    type: FileType,
    tradeId: string,
  ): Promise<string> {
    return (await this.postTradeContactNoteGenerationAsync(type, tradeId)).executionArn;
  }

  private static async postTradeContactNoteGenerationAsync(
    type: FileType,
    tradeId: string,
  ): Promise<TReportGenerationResponse> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `reports/fx/${tradeId}?type=${type}`,
    );
    return response.data;
  }

  public static async getBatch(
    batchId: string,
  ): Promise<PaymentBatchDto> {
    // doing the below casting because the return of getBatchDetailsAsync is wrong and
    // I don't want to break anything
    return this.getBatchDetailsAsync(batchId) as any as PaymentBatchDto;
  }

  public static async getBatchDetails(
    batchId: string,
    routeState: PaymentRouteEnum,
  ): Promise<PaymentDto> {
    if (
      routeState === PaymentRouteEnum.BASE
    ) {
      return this.getReleasedBatchDetailsAsync(batchId);
    }
    return this.getBatchDetailsAsync(batchId);
  }

  public static async submitAndApprovePayment(
    payload: any,
    canApproveOwn: boolean,
    firstPartyFlow?: boolean,
  ): Promise<TPaymentSubmissionResponse> {
    const response = await this.postSubmitPayment(payload, firstPartyFlow);
    if (canApproveOwn && response.success && !response.pending) {
      this.postApprovePaymentAsync(payload, firstPartyFlow);
    }

    return response;
  }

  public static async approvePayment(
    payload: PaymentsApprovalRequest,
    firstPartyFlow?: boolean,
  ): Promise<PaymentsApprovalResponse> {
    return this.postApprovePaymentAsync(payload, firstPartyFlow);
  }

  public static async postRejectPayment(
    paymentIds: string[],
  ): Promise<void> {
    return instance.post('/payments/reject', { paymentIds });
  }

  public static async verifyPaymentsValueDate(
    paymentIds: string[],
  ): Promise<ExpiredPaymentsResponse> {
    const response: AxiosResponse<ExpiredPaymentsResponse> = await instance.post('/payments/filter-expired', { paymentIds });

    return response.data;
  }

  public static async postReleasePaymentBatch(batchId: string) {
    return this.postReleasePaymentBatchAsync(batchId);
  }

  public static async getPaymentPurpose(
    currencyCode: string,
  ): Promise<CountryPaymentPurposesDto> {
    return this.getPaymentPurposeAsync(currencyCode);
  }

  public static async getPaymentData(
    paymentId: string,
  ): Promise<TStatementData> {
    const paymentResponse = await this.getPaymentDataAsync(paymentId);
    return this.mapPaymentDataToStatementData(paymentResponse);
  }

  public static async getAccountConfiguration(
    buyCurrency: string,
    sellCurrency: string,
  ): Promise<AccountConfigurationPaymentDto> {
    return this.getAccountConfigurationAsync(buyCurrency, sellCurrency);
  }

  private static async getAccountConfigurationAsync(
    buyCurrency: string,
    sellCurrency: string,
  ): Promise<AccountConfigurationPaymentDto> {
    const response: AxiosResponse<AccountConfigurationPaymentDto> = await instance.get(`/payments/account-configuration?buycurrency=${buyCurrency}&sellcurrency=${sellCurrency}`);
    return response.data;
  }

  public static async getAvailablePaymentDate(
    debitingCurrencyCode: string,
    fundingCurrencyCode: string,
  ): Promise<TDate> {
    return this.getAvailablePaymentDateAsync(
      debitingCurrencyCode,
      fundingCurrencyCode,
    );
  }

  public static async postGenerateBatchId(): Promise<{batchId: string}> {
    return this.postGenerateBatchIdAsync();
  }

  public static async postValidateManualPayment(
    batchId: string,
    payments: any,
  ): Promise<number> {
    return this.postValidateManualPaymentAsync(
      batchId,
      payments,
    );
  }

  public static async getBatchStatus(batchId: string): Promise<any> {
    return (await instance.get(`payments/batches/${batchId}/status`)).data;
  }

  public static async getBatchInvalid(batchId: string) {
    const response = await instance.get(`/payments/batches/${batchId}/invalid`);
    return response.data.items;
  }

  public static async getApprovers(id?: string) {
    return this.getApproversAsync(id);
  }

  private static async getApproversAsync(paymentId?: string): Promise<Approver[]> {
    const response: AxiosResponse<Approver[]> = await instance.get('/payments/approvers',
      {
        params: {
          paymentid: paymentId,
        },
      });
    return response.data;
  }

  public static async getFxApprovers(batchId: string) {
    return this.getFxApproversAsync(batchId);
  }

  private static async getFxApproversAsync(batchId: string): Promise<any[]> {
    const response: AxiosResponse<any[]> = await instance.get(`/payments/batches/${batchId}/fx-bookers`);
    return response.data;
  }

  public static async postApproverEmails(
    id: string,
    approverIds: ApproversNotifyRequest,
  ): Promise<number> {
    return this.postApproverEmailsAsync(id, approverIds);
  }

  public static async getPaymentErrors(
    batchId: string,
  ): Promise<string> {
    return this.getPaymentErrorsAsync(batchId);
  }

  private static async getPaymentErrorsAsync(
    batchId: string,
  ): Promise<string> {
    const response: AxiosResponse<string> = await instance.get(`/payments/batches/${batchId}/payments/invalid/file`);
    return response.data;
  }

  private static async postApproverEmailsAsync(
    id: string,
    approverIds: ApproversNotifyRequest,
  ): Promise<number> {
    const response: AxiosResponse<number> = await instance.post(`/payments/${id}/approvers/notify`, approverIds);
    return response.status;
  }

  private static async postValidateManualPaymentAsync(
    batchId: string,
    payments: any,
  ): Promise<number> {
    const response: AxiosResponse<number> = await instance.post(
      `/payments/batches/submit-manual/${batchId}`,
      payments,
    );
    return response.status;
  }

  private static async postGenerateBatchIdAsync(): Promise<{batchId: string}> {
    const response: AxiosResponse<{batchId: string}> = await instance.post(
      '/payments/batches/manual',
    );
    return response.data;
  }

  public static async deleteDraftPayment(
    batchId: string,
  ): Promise<number> {
    return this.deleteDraftPaymentAsync(batchId);
  }

  private static async deleteDraftPaymentAsync(batchId: string): Promise<number> {
    const response = await instance.delete(
      `/payments/batches/${batchId}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw Error();
    }
    return response.status;
  }

  private static async getPaymentDataAsync(
    paymentId: string,
  ): Promise<PaymentDto> {
    const response = await instance.get(
      `/payments/released/${paymentId}/payment`,
    );
    return response.data;
  }

  private static async getBatchDetailsAsync(
    batchId: string,
  ): Promise<PaymentDto> {
    const requestParams: AxiosRequestConfig = {
      method: 'get',
      url: `/payments/batches/${batchId}`,
    };
    const response = await instance(requestParams);
    return response.data;
  }

  public static async getAllBatches(): Promise<PaymentBatchDto[]> {
    const response = await instance.get(
      '/payments/batches',
    );
    return response.data;
  }

  private static async getReleasedBatchDetailsAsync(
    batchId: string,
  ): Promise<PaymentDto> {
    const response = await instance.get(
      `/payments/batches/released/${batchId}`,
    );
    return response.data;
  }

  public static async postSubmitPayment(
    payload: PaymentsSubmissionRequest,
    firstPartyFlow?: boolean,
  ): Promise<TPaymentSubmissionResponse> {
    let response;
    if (firstPartyFlow) {
      response = await instance.post('/payments/submit/all-roles', {
        ...payload,
      });
    } else {
      response = await instance.post('/payments/submit', {
        ...payload,
      });
    }

    if (response.status !== 200) {
      throw Error(`${response.data.error}`);
    }

    if (!response.data) {
      return { success: true, pending: false };
    }
    return response.data;
  }

  private static async postRejectPaymentOwnAsync(
    batchId: string,
  ): Promise<void> {
    const response: AxiosResponse<string> = await instance.post(
      `/payments/batches/${batchId}/reject-own`,
    );

    if (response.status !== 201 && response.status !== 200) throw Error();
  }

  private static async postApprovePaymentAsync(
    params: PaymentsApprovalRequest,
    firstPartyFlow?: boolean,
  ): Promise<any> {
    let response: AxiosResponse<any>;
    if (firstPartyFlow) {
      response = await instance.post(
        '/payments/approve/all-roles',
        {
          paymentIds: params.paymentIds,
          totp: params.totp,
          approvalRequestId: params.approvalRequestId,
          dynamicLinkingId: params.dynamicLinkingId,
          softToken: params.softToken,
        },
      );
    } else {
      response = await instance.post(
        '/payments/approve',
        {
          paymentIds: params.paymentIds,
          totp: params.totp,
          approvalRequestId: params.approvalRequestId,
          dynamicLinkingId: params.dynamicLinkingId,
          softToken: params.softToken,
        },
      );
    }
    return response.data;
  }

  private static async postReleasePaymentBatchAsync(
    batchId: string,
  ): Promise<void> {
    const response = await instance.post('/payments/batches/released', {
      batchId,
    });

    if (response.status !== 200 && response.status !== 201) {
      throw Error(`There was an error releasing your payment batch ${batchId}`);
    }
  }

  private static mapPaymentDataToStatementData = (
    paymentResponse: PaymentDto,
  ): TStatementData => ({
    creditFriendlyName: paymentResponse.beneficiaryName,
    // beneficiary: paymentResponse.beneficiary,
    valueDate: paymentResponse.valueDate,
    arrivalDate: paymentResponse.date,
    reference: paymentResponse.reference,
    creditAmount: paymentResponse.amount,
    // creditIban: paymentResponse.beneficiaryiban,
    // creditSwift: paymentResponse.beneficiary.swift,
    creditCurrencyCode: paymentResponse.fundingCurrency,
  });

  private static async getPaymentPurposeAsync(
    currencyCode: string,
  ): Promise<CountryPaymentPurposesDto> {
    const response: AxiosResponse<CountryPaymentPurposesDto> = await instance.get(
      `/payments/country-payment-purposes?countrycode=${currencyCode}`,
    );
    return response.data;
  }

  private static async getAvailablePaymentDateAsync(
    debitingCurrencyCode: string,
    fundingCurrencyCode: string,
  ): Promise<TDate> {
    const response: AxiosResponse<TDate> = await instance.get(
      `/payments/currency-holidays?debitingcurrency=${debitingCurrencyCode}&fundingcurrency=${fundingCurrencyCode}`,
    );
    return response.data;
  }

  public static async getPaymentFilterOptions(): Promise<PaymentFilterOptionsDto> {
    const filterOptionsResponse = await this.getPaymentFilterOptionsAsync();
    return filterOptionsResponse;
  }

  private static async getPaymentFilterOptionsAsync() : Promise<PaymentFilterOptionsDto> {
    const response: AxiosResponse<PaymentFilterOptionsDto> = await instance.get('/payments/search/filter-options');
    return response.data;
  }

  public static async postGenericPaymentApproverEmail(
    approverIds: ApproversNotifyRequest,
  ): Promise<number> {
    return this.postGenericPaymentApproverEmailAsync(approverIds);
  }

  private static async postGenericPaymentApproverEmailAsync(
    approverIds: ApproversNotifyRequest,
  ): Promise<number> {
    const response: AxiosResponse<number> = await instance.post('/payments/approvers/notify-generic', approverIds);
    return response.status;
  }

  public static async getBatchPaymentsSummary(
    batchId: string,
  ): Promise<PaymentBatchDto> {
    const response: AxiosResponse<PaymentBatchDto> = await instance.get(`/payments/batches/${batchId}`);
    return response.data;
  }

  public static async getBatchPaymentsUploadHistory(): Promise<PaymentBatchDto[]> {
    const response: AxiosResponse<PaymentBatchDto[]> = await instance.get('/payments/batches');
    return response.data;
  }

  public static async getBatchPaymentFileUrl(batchId:string): Promise<string> {
    const response: AxiosResponse<{url: string}> = await instance.get(`/payments/batches/${batchId}/file`);

    return response.data.url;
  }

  public static async putUpdateBatchPayment(
    batchId: string,
    updateRequest: BatchPaymentsUpdateRequest,
  ): Promise<void> {
    const response = await instance.put(`/payments/batches/${batchId}/payments`,
      updateRequest);

    if (response.status !== 200 && response.status !== 201) {
      throw Error('There was an error updating your payment funding method');
    }
  }

  public static async postBatchPaymentsApproversEmail(
    batchId: string,
    approverIds: ApproversNotifyRequest,
  ): Promise<number> {
    const response: AxiosResponse<number> = await instance.post(`/payments/batches/${batchId}/approvers/notify`, approverIds);
    return response.status;
  }

  public static async getPaymentBatch(
    batchId: string,
  ): Promise<PaymentBatchDto> {
    const response: AxiosResponse<PaymentBatchDto> = await instance.get(`/payments/batches/${batchId}`);
    return response.data;
  }

  public static async getPendingApprovalPayments(
  ): Promise<{total: number, records: PaymentDto[]}> {
    const params = {
      skip: 0,
      take: 5,
      sortby: 'inputDate',
      sortorder: 'desc',
      status: PaymentStatus.SUBMITTED,
    };
    const response: AxiosResponse<{
      total: number,
      records: PaymentDto[]
    }> = await instance({
      method: 'GET',
      url: '/payments/search',
      params,
    });
    return response.data;
  }
}

export default PaymentsService;
