import { CurrencyAccountTransferStatus } from '@alpha/currency-accounts-dtos';

export const mapTransferStatusToDisplay = (
  status: CurrencyAccountTransferStatus,
): {
  variant: 'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined,
  text: string
} => {
  switch (status) {
    case CurrencyAccountTransferStatus.PROCESSING:
      return { variant: 'info', text: 'PROCESSING' };
    case CurrencyAccountTransferStatus.CANCELLED:
      return { variant: 'error', text: 'CANCELLED' };
    case CurrencyAccountTransferStatus.COMPLETED:
      return { variant: 'success', text: 'COMPLETED' };
    case CurrencyAccountTransferStatus.CLIENT_REJECTED:
      return { variant: 'error', text: 'REJECTED' };
    case CurrencyAccountTransferStatus.SUBMITTED:
      return { variant: 'info', text: 'PENDING_APPROVAL' };
    case CurrencyAccountTransferStatus.CLIENT_APPROVED:
      return { variant: 'success', text: 'APPROVED' };
    default:
      return { variant: 'warning', text: '-' };
  }
};

export default mapTransferStatusToDisplay;
