import { ChangeEvent, useEffect } from 'react';

import { LedgerDto } from '@alpha/currency-accounts-dtos';

import browserSearchSVG from '../../../assets/browserSearch.svg';
import noNotesSVG from '../../../assets/noNotes.svg';
import { IEmptyTable } from '../../../components/Table/EmptyTable';
import useSearch, { TSearchParams } from '../../../hooks/useSearch';
import t from 'utils/translationHelper';
/**
 * Create search parameters for statements summary request
 * @returns {TSearchParams}
 */
export const createSearchParams = (
  skip: number,
  startDate: string,
  endDate: string,
  currencyAccountId?: string,
  sortBy?: string,
  sortOrder: 'asc' | 'desc' = 'desc',
  searchText: string = '',
): TSearchParams => {
  const searchParams: TSearchParams = {
    baseUrl: `/ca/currency-accounts/${currencyAccountId}/ledgers`,
    queryParams: {
      skip,
      take: 10,
      sortby: sortBy || '',
      sortorder: sortOrder,
      from: startDate,
      to: endDate,
    },
  };

  if (searchText) {
    searchParams.queryParams.searchtext = searchText;
  }

  return searchParams;
};

export const generateEmptyTableText = (currencyAccountId?: string): IEmptyTable => {
  
  if (!currencyAccountId) {
    return {
      title: t('please_select_an_account_to_view_associated_statements'),
      subtitle: t('account_selector_is_located_in_the_page_header'),
      icon: browserSearchSVG,
    };
  }

  return {
    title: t('no_statements_recorded_between_these_dates'),
    icon: noNotesSVG,
  };
};

const useStatementsTable = (
  startDate: string,
  endDate: string,
  handleSetStatement: (clickedTableRow: LedgerDto) => Promise<void>,
  currencyAccountId?: string,
) => {
  const table = useSearch();

  const searchParams = createSearchParams(
    table.skip,
    startDate,
    endDate,
    currencyAccountId,
    'ledgerTimestamp',
    'desc',
    table.searchText,
  );

  useEffect(() => {
    table.handleInitialSearch(searchParams);
  }, [currencyAccountId]);

  const handleTableRowClick = (clickedRow: LedgerDto) => {
    handleSetStatement((table.items?.items.records as LedgerDto[]).find(
      (item: LedgerDto) => item.id === clickedRow.id,
    )!);
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    table.setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.skip = 0;
      searchParams.queryParams.searchtext = e.target.value;
    } else {
      delete searchParams.queryParams.searchtext;
    }
    await table.handleNewSearch(searchParams);
  };

  return {
    table,
    searchParams,
    handleInputChange,
    handleTableRowClick,
    generateEmptyTableText,
  };
};

export default useStatementsTable;
