import { DrawdownFundingMethod } from '@alpha/fx-dtos';

export const mapDrawdownFundingMethodToFriendlyName = (
  fundingMethod: DrawdownFundingMethod,
): string => {
  switch (fundingMethod) {
    case DrawdownFundingMethod.CURRENCY_ACCOUNT_BALANCE:
      return 'Currency Account Balance';
    case DrawdownFundingMethod.WIRE_TRANSFER:
      return 'Wire Transfer';
    case DrawdownFundingMethod.PAD:
      return 'PAD';
    default:
      return '-';
  }
};

export default mapDrawdownFundingMethodToFriendlyName;
