import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: '60px 55px 30px 55px',
    },
    status: {
      minWidth: '154px',
      textAlign: 'center',
    },
  }),
  { name: 'HeaderButtons' },
);

export default useStyles;
