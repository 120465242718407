import React, { memo } from 'react';
import clsx from 'clsx';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APModal from '../../../components/Modals/APModal/APModal';

import MultiSelectEntities from './MultiSelectEntities/MultiSelectEntities';
import UserDetails from './UserDetails/UserDetails';
import useStyles from './NewUserRequestModal.styles';
import useNewUserRequestModal, { Section } from './useNewUserRequestModal';

interface IProps {
  open: boolean;
  handleClose: () => void;
  selectedAccount?: AccountDto;
  handleUserAdded?: () => void;
}

const mapSectionToPercentage = (section: Section): '5%' | '95%' => {
  switch (section) {
    default:
    case 'Details':
      return '5%';
    case 'Entities':
      return '95%';
  }
};

const NewUserRequestModal: React.FC<IProps> = (props: IProps) => {
  const {
    open,
    handleClose,
    selectedAccount,
    handleUserAdded,
  } = props;

  const classes = useStyles();

  const {
    handleNextOnClick,
    section,
    setSection,
    phoneCode,
    setPhoneCode,
    entities,
    entitiesLoading,
    menuCloseCount,
    setMenuCloseCount,
    currentOptions,
    setCurrentOptions,
    handleSetSection,
    setTextValue,
    countries,
    userRequestForm,
    userSubmitting,
  } = useNewUserRequestModal(handleClose, open, selectedAccount, handleUserAdded);

  return (
    <APModal.Container
      open={open}
      onClose={() => {
        handleClose();
        userRequestForm.resetForm();
        setSection('Details');
      }}
      className={classes.container}
    >
      <APModal.Wrapper>
        {userSubmitting
          ? (
            <Box textAlign="center">
              <div className={classes.headerContainer}>
                <div className={classes.title}>{t('creating_new_user')}</div>
              </div>
              <Loader
                testId="loader"
                size="50px"
                style={{ margin: 'auto', marginTop: '50px', marginBottom: '40px' }}
              />
              {t('submitting')}
              ...
            </Box>
          ) : (
            <div>
              <div className={classes.headerContainer}>
                <div className={classes.title}>{t('create_new_user')}</div>
                <div style={{ position: 'relative' }} className="stepper">
                  <div style={{
                    marginLeft: mapSectionToPercentage(section), width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#0C8375', position: 'absolute', zIndex: '1', top: '-5px', left: '-2px',
                  }}
                  />
                  <div style={{
                    backgroundColor: '#EFEFEF', height: '5px', borderRadius: '5px', width: '100%', position: 'absolute',
                  }}
                  />
                  <div style={{
                    backgroundColor: '#0C8375', height: '5px', borderRadius: '5px', width: mapSectionToPercentage(section), position: 'absolute',
                  }}
                  />
                  <div style={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '16px',
                  }}
                  >
                    <Typography variant="subtitle1" className={clsx(section === 'Details' && 'active')}>
                      1.
                      {' '}
                      {t('details')}
                    </Typography>
                    <Typography variant="subtitle1" className={clsx(section === 'Entities' && 'active')}>
                      2.
                      {' '}
                      {t('entities')}
                    </Typography>
                  </div>
                </div>
              </div>
              {section === 'Details' && (
                <>
                  <UserDetails
                    userRequestForm={userRequestForm}
                    phoneCode={phoneCode}
                    setPhoneCode={setPhoneCode}
                    countries={countries}
                  />
                  <div className={classes.nextButton}>
                    <ActionButton
                      size="medium"
                      onClick={() => handleNextOnClick()}
                      endIcon={(
                        <FontAwesomeIcon
                          icon={faArrowRight as IconProp}
                          size="xs"
                          fontSize={10}
                        />
                      )}
                    >
                      {t('next')}
                    </ActionButton>
                  </div>
                </>
              )}
              {section === 'Entities' && (
                <div>
                  <Typography
                    className={classes.entitiesSubtitle}
                  >
                    {t('do_you_wish_to_add_user_to_other_entities')}
                  </Typography>
                  <MultiSelectEntities
                    entities={entities}
                    id={selectedAccount?.id}
                    setMenuCloseCount={setMenuCloseCount}
                    menuCloseCount={menuCloseCount}
                    currentOptions={currentOptions}
                    setCurrentOptions={setCurrentOptions}
                    setTextValue={setTextValue}
                    loading={entitiesLoading}
                  />
                  <div className={classes.buttonContainer}>
                    <ActionButton
                      size="medium"
                      style={{ background: '#F7F7F7', color: '#212529' }}
                      onClick={() => handleSetSection('Details')}
                      startIcon={(
                        <FontAwesomeIcon
                          icon={faArrowLeft as IconProp}
                          size="xs"
                          fontSize={10}
                          color="rgba(51, 51, 51, .5)"
                        />
                      )}
                    >
                      {t('prev')}
                    </ActionButton>
                    {currentOptions.length < 1
                      ? (
                        <APTooltip title={t('you_must_select_at_least_one_entity')} placement="top">
                          <div>
                            <ActionButton
                              onClick={() => {
                                userRequestForm.handleSubmit();
                              }}
                              disabled
                              rounded
                              size="medium"
                            >
                              {t('create_user')}
                            </ActionButton>
                          </div>
                        </APTooltip>
                      )
                      : (
                        <ActionButton
                          onClick={() => {
                            userRequestForm.handleSubmit();
                          }}
                          rounded
                          size="medium"
                        >
                          {t('create_user')}
                        </ActionButton>
                      )}
                  </div>
                </div>
              )}
            </div>
          )}
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default memo(NewUserRequestModal);
