import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography, palette }) => ({
    headerContent: {
      display: 'flex',
      margin: '0px 40px',
      padding: '32px 0px',
      zIndex: 1,
      height: '120px',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    headerTitle: {
      fontSize: 24,
      color: palette.primary.light,
      fontFamily: typography.fontFamily,
    },
    headerSubtitle: {
      fontSize: 12,
      fontFamily: typography.fontFamilySecondary,
      fontWeight: 500,
    },
    button: {
      marginLeft: 'auto',
      alignSelf: 'flex-end',
      backgroundColor: 'rgba(240, 240, 240, 1)',
      minWidth: 0,
      width: 50,
      height: 50,
      '&:hover': {
        backgroundColor: 'rgba(250, 250, 250, 0.5)',
      },
    },
    icon: {
      color: '#848484',
    },
  }),
  { name: 'Header' },
);

export default useStyles;
