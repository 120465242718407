import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Guid } from 'guid-typescript';
import { call, cancelled, put } from 'redux-saga/effects';

import API from '../services/Axios/instance';

import { actions as notificationsActions, TNotification } from './notifications/notifications.reducer';
import { actions as requestsActions } from './requests/requests.reducer';

export type TAPIRequestsResult<T> = AxiosResponse<T> & { message?: string };

export type TApiRequestOptions = {
  config: AxiosRequestConfig;
  handleUnauthorized?: boolean;
  name?: string;
  notification?:
    | boolean
    | {
        error?: boolean | TNotification;
        success?: boolean | TNotification;
      };
};

export function* apiRequest(options: TApiRequestOptions): any {
  const { name, notification = true } = options;
  const source = yield axios.CancelToken.source();

  if (name) {
    yield put(requestsActions.start(name));
  }

  try {
    const response: AxiosResponse = yield call(API.request, {
      ...options.config,
      headers: {
        ...options.config.headers,
        'X-Correlation-ID': Guid.create().toString(),
      },
      cancelToken: source.token,
    });

    // we might receive login page html in data instead of 401
    if (typeof response.data === 'string' && response.data.includes('<body class="login-body">')) {
      // we have to move to local catch block because it might be 401 response
      throw response;
    }

    if (name) {
      yield put(requestsActions.finish(name));
    }

    if (
      notification
      && (typeof notification !== 'object' || notification.success !== false)
      && options.config.method !== 'GET'
    ) {
      yield put(
        notificationsActions.enqueueSnackbar({
          variant: 'success',
          key: Guid.create().toString(),
          message: response?.data?.message ?? 'Success',
          ...(typeof notification === 'object' && typeof notification.success === 'object' ? notification.success : {}),
        }),
      );
    }

    return response;
  } catch (err) {
    if (name) {
      yield put(requestsActions.finish(name, err));
    }

    if (
      !options.handleUnauthorized
      && ((!err?.response?.data && err?.response?.status === 401 && err?.response?.statusText === 'Unauthorized')
        || (typeof err?.data === 'string' && err?.data.includes('<body class="login-body">')))
    ) {
      yield;
    }
    let message = 'Something went wrong';

    if (err?.response?.data?.publicMessage ?? false) {
      message = err.response.data.publicMessage;
    } else if (err) {
      message = err?.response?.data?.friendlyMessage ?? err?.response?.data?.message ?? err?.message;
    }

    if (notification && (typeof notification !== 'object' || notification.error !== false)) {
      yield put(
        notificationsActions.enqueueSnackbar({
          message,
          variant: 'error',
          preventDuplicate: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          key: message,
          ...(typeof notification === 'object' && typeof notification.error === 'object' ? notification.error : {}),
        }),
      );
    }

    throw err;
  } finally {
    if (yield cancelled()) {
      if (name) {
        yield put(requestsActions.finish(name));
      }

      yield source.cancel();
    }
  }
}
