import React, { useState } from 'react';
import t from 'utils/translationHelper';

import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faDownload, faFileCsv, faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Authorization from '../../../hocs/Authorization';
import { UserRole } from '../../../models/user';

interface IProps {
  handleOpenCreateBeneficiaryDrawer?: () => void;
  handleExportButtonClick?: () => void;
  setOpen?: () => void;
  isBatchJobTab?: boolean;
  hasBeneInputterRole?: boolean;
}

const RightAlignedCta: React.FC<IProps> = ({
  handleOpenCreateBeneficiaryDrawer,
  handleExportButtonClick,
  setOpen,
  isBatchJobTab,
  hasBeneInputterRole,
}: IProps) => {
  const templateMenuOptions = [
    {
      content: t('download_Xlsx_template'),
      icon: faFileExcel,
      onClick: () => {
        window.location.href = '/static/files/ALPHA_BENEFICIARIES_TEMPLATE.xlsx';
      },
    },
    {
      content: t('download_csv_template'),
      icon: faFileCsv,
      onClick: () => {
        window.location.href = '/static/files/ALPHA_BENEFICIARIES_TEMPLATE.csv';
      },
    },
  ];
  const [openExportMenu, setOpenExportMenu] = useState<null | HTMLElement>(null);

  return (
    <Box flex="column">
      {!isBatchJobTab ? (
        <>
          {' '}
          <Authorization requiredRoles={[[UserRole.PAYMENT_FILE_INPUTTER]]}>
            <ActionButton
              testId="create-beneficiary-btn"
              size="medium"
              style={{ marginRight: '10px', background: '#F7F7F7', color: '#212529' }}
              onClick={handleExportButtonClick}
            >
              {t('export')}
            </ActionButton>
          </Authorization>
          <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
            <ActionButton
              testId="create-beneficiary-btn"
              size="medium"
              onClick={handleOpenCreateBeneficiaryDrawer}
            >
              {t('create_beneficiary')}
            </ActionButton>
          </Authorization>
        </>
      ) : (
        <>
          <Box style={{ gap: '10px', display: 'flex' }}>
            <APMenu
              open={Boolean(openExportMenu)}
              anchorEl={openExportMenu}
              setOpen={setOpenExportMenu}
              disableBackdropClick={false}
              buttonTitle={(
                <>
                  <FontAwesomeIcon icon={faDownload} style={{ marginRight: '8px' }} />
                  {t('download_template')}

                </>
              )}
            >
              {templateMenuOptions.map((
                item,
              ) => <MenuDropdownItem setOpen={setOpenExportMenu}>{item}</MenuDropdownItem>)}
            </APMenu>
            <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
              <ActionButton
                testId="new-upload-beneficiary-btn"
                size="medium"
                onClick={setOpen}
              >
                {t('new_upload')}
              </ActionButton>
            </Authorization>
          </Box>
        </>
      )}
    </Box>
  );
};
export default RightAlignedCta;
