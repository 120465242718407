import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { DeleteAccountUsersRequest } from '@alpha/profile-dtos';
import MenuDropdownItem, { IMenuItem } from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import useActionDropdown from '../../../../hooks/useActionDropDown';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import AccountSettingsService from '../../../../services/AccountSettings/accountSettings.service';
import DisableAccessWarningModal from '../DisableAccessWarningModal';

import useStyles from './DisableAccessActionDropdown.style';

interface IProps {
  userId: string;
  firstName: string;
  lastName: string;
  handleUserRevoked?: () => void;
}

const DisableAccessActionDropDown: React.FC<IProps> = ({
  userId,
  firstName,
  lastName,
  handleUserRevoked,
}: IProps) => {
  const classes = useStyles();
  const { logEvent, logError } = useLog();
  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [leftTheBusiness, setHasLeftTheBusiness] = useState<boolean>(false);
  const sb = useAlphaSnackbar();

  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    setOpen,
  } = useActionDropdown();

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  const openDisableWarningModal = async (hasLeftTheBusiness: boolean) => {
    setHasLeftTheBusiness(hasLeftTheBusiness);
    setOpenModal(true);
  };

  const handleDisableAccess = async (user: string, leftBusiness: boolean) => {
    const request: DeleteAccountUsersRequest = [{
      userId: user,
      leftTheBusiness: leftBusiness,
    }];
    try {
      const response = await AccountSettingsService.deleteUser(request);
      if (response === 200) {
        sb.trigger(t('successfully_revoked_access_for_user'), 'success');
        logEvent({ action: 'Successfully revoked user' });
        if (handleUserRevoked) { handleUserRevoked(); }
      }
      setOpenModal(false);
    } catch (error) {
      sb.trigger(t('could_not_revoke_users_access'));
      logError({ action: 'Error revoking user access', error });
    }
  };

  const generateMenuItems = () => {
    const items = [];
    items.push({
      content: (
        <Typography>
          {t('user_has_left_the_business')}
        </Typography>
      ),
      icon: displayIcon('LeftBusiness'),
      onClick: () => openDisableWarningModal && openDisableWarningModal(true),
    },
    {
      content: (
        <Typography>
          {t('user_no_longer_requires_access')}
        </Typography>
      ),
      icon: displayIcon('NoAccess'),
      onClick: () => openDisableWarningModal && openDisableWarningModal(false),
    });
    setMenuItems(items);
  };

  useEffect(() => {
    generateMenuItems();
  }, []);

  return (
    <Box data-testid="action-disable-access-dropdown" className={classes.wrapper}>
      <APMenu
        actions
        open={Boolean(open)}
        setOpen={setOpen}
        anchorEl={open}
        disableBackdropClick={menuCloseCount === 1}
        onClose={handleClose}
        buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
        buttonTitle=""
        icon={faEllipsisH as IconDefinition}
      >
        {
          menuItems.map((item) => <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>)
        }
      </APMenu>
      <DisableAccessWarningModal
        handleClose={() => setOpenModal(false)}
        open={openModal}
        handleSubmit={() => handleDisableAccess(userId, leftTheBusiness)}
        firstName={firstName}
        lastName={lastName}
        leftTheBusiness={leftTheBusiness}
      />
    </Box>
  );
};

export default DisableAccessActionDropDown;
