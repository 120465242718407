import React from 'react';

import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';

import DrawerBackdrop from '../../../Drawer/DrawerBackdrop/DrawerBackdrop';

interface IProps {
  display: boolean;
  text: string
}
const DrawerBackdropLoader: React.FC<IProps> = (props: IProps) => {
  const { display, text } = props;
  return (
    <DrawerBackdrop display={display}>
      <Loader testId="loader" />
      <Typography variant="subtitle1">{ text }</Typography>
    </DrawerBackdrop>
  );
};

export default DrawerBackdropLoader;
