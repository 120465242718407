import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { MfaMethod } from '@alpha/profile-dtos';
import { theme } from '@alpha/ui-lib/theme/themes/default';
import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCommentAltDots, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthyIcon from '../../../assets/authyIcon.png';
import AuthBackupMethodCard from '../../../components/AuthBackupMethodCard';
import AuthSetupModal from '../../../components/AuthSetupModal';
import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import useAuth from '../../../hooks/useAuth';
import useLog from '../../../hooks/useLog';
import { TSignIn } from '../../../models/auth';
import routes from '../../../routes.path';
import AuthService from '../../../services/Auth/auth.service';
import browserHistory from '../../../services/history/browserHistory';
import useFormStyles from '../form.styles';
import { IOnboarding, useOnboardingContext } from '../Onboarding';
import { IReturning } from '../Returning';
import t from 'utils/translationHelper';
import LangSelectorGroup from '../LangSelectorGroup';
interface IProps extends IOnboarding, IReturning {
  setup?: boolean,
  setMfaImage?: React.Dispatch<React.SetStateAction<'authy' | 'sms' | undefined>>,
}

const SetupMFA: React.FC<IProps> = ({
  onboarding, userObject, setup, setMfaImage,
}: IProps) => {
  const formClasses = useFormStyles();


  const [showAuthyModal, setShowAuthyModal] = useState<boolean>(false);
  const [loadingCard, setLoadingCard] = useState<MfaMethod | false>(false);
  const { challengeRedirect } = useAuth();
  const { logError } = useLog();
  const { enqueueSnackbar } = originalUseSnackbar();

  const { handleSetLoaderPercentage } = useOnboardingContext();

  const onboardingDescription = t('two_factor_authentication_must_be_enabled');
  const onboardingTitle = t('set_up_2fa');


  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(75);
    }
  }, []);

  const handleTriggerMfa = async (methodRequest: MfaMethod): Promise<void> => {
    try {
      setLoadingCard(methodRequest);
      const res = await AuthService.sendCustomChallengeAnswer(userObject, methodRequest);
      challengeRedirect(res as TSignIn, enqueueSnackbar, methodRequest);
      browserHistory.push(onboarding ? routes.auth.onboarding.verifyMfa : routes.auth.totp);
    } catch (e) {
      const snackbarMessage = e.response?.data?.error || e.message === 'Invalid session for the user.'
        ? t('your_session_has_expired')
        : t('there_was_an_error_confirming_your_token');
      enqueueSnackbar(snackbarMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
      logError({ action: snackbarMessage, error: e });
    } finally {
      setLoadingCard(false);
    }
  };

  return (
    <div className={formClasses.wrapperDiv}>
      <div>
        <FormTitle
          number={onboarding ? 3 : undefined}
          title={(onboarding && setup) ? onboardingTitle : t('havent_received_your_code_yet')}
          description={(onboarding && setup) ? onboardingDescription : `${t('please_select_which_backup_method_you_would_like')}.`}
        />
        <div
          onFocus={() => (setMfaImage && setMfaImage('authy'))}
          onMouseEnter={() => (setMfaImage && setMfaImage('authy'))}
        >
          <AuthBackupMethodCard
            loading={Boolean(loadingCard === MfaMethod.METHOD_AUTHY)}
            disabled={Boolean(loadingCard)}
            icon={{
              backgroundColor: '#EC1C24',
              element: <img src={AuthyIcon} alt="Authy icon" />,
            }}
            content={{
              title: 'Authy',
              body: t('download_the_authy_app'),
            }}
            handleOnClick={() => setShowAuthyModal(true)}
            displaySecureBadge
          />
        </div>
        <div style={{ padding: '16px 0' }} />
        <div
          onFocus={() => (setMfaImage && setMfaImage('sms'))}
          onMouseEnter={() => (setMfaImage && setMfaImage('sms'))}
        >
          <AuthBackupMethodCard
            loading={Boolean(loadingCard === MfaMethod.METHOD_SMS)}
            disabled={Boolean(loadingCard)}
            icon={{
              backgroundColor: theme.palette?.primary?.main as string,
              element: <FontAwesomeIcon icon={faCommentAltDots as IconProp} />,
            }}
            content={{
              title: (onboarding && setup) ? t('sms') : t('resend_sms'),
              body: t('receive_an_sms_to_your_registered_device'),
            }}
            handleOnClick={() => handleTriggerMfa(MfaMethod.METHOD_SMS)}
          />
        </div>
        {
          (!onboarding || !setup)
          && (
            <>
              <div style={{ padding: '16px 0' }} />
              <AuthBackupMethodCard
                loading={Boolean(loadingCard === MfaMethod.METHOD_CALL)}
                disabled={Boolean(loadingCard)}
                icon={{
                  backgroundColor: theme.palette?.primary?.main as string,
                  element: <FontAwesomeIcon icon={faPhone as IconProp} />,
                }}
                content={{
                  title: t('call'),
                  body: t('receive_a_phone_call_to_your_registered_device1'),
                }}
                handleOnClick={() => handleTriggerMfa(MfaMethod.METHOD_CALL)}
              />
            </>
          )
        }
        <AuthSetupModal
          open={showAuthyModal}
          phoneNumber={userObject.challengeParam?.CODE_DELIVERY_DESTINATION || ''}
          handleModalClose={() => setShowAuthyModal(false)}
          handleSuccessButtonClick={() => handleTriggerMfa(MfaMethod.METHOD_AUTHY)}
        />
        {
          (!onboarding || !setup)
          && (
            <NavLink
              to={onboarding ? routes.auth.onboarding.verifyMfa : routes.auth.totp}
              className={clsx(formClasses.formBacklink, formClasses.white)}
            >
              {t('go_back')}
            </NavLink>
          )
        }
      </div>
      <LangSelectorGroup />
    </div>
  );
};

export default SetupMFA;
