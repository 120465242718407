import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    container: {
      textAlign: 'center',
      '& svg': {
        width: '40px !important',
        height: '40px',
        marginBottom: '16px',
      },
    },
    modalWrapper: {
      padding: '70px 20px 50px 20px !important',
      border: 'none',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    header: {
      fontSize: 22,
      fontWeight: typography.fontWeightSemiBold,
    },
    subHeader: {
      marginBottom: 50,
      marginTop: 8,
      fontSize: 14,
    },
    icon: {
      color: 'rgb(255, 199, 0)',
      marginRight: 10,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      '& > button:last-child': {
        marginLeft: 8,
      },
    },
  }),
  { name: 'RejectModal' },
);

export default useStyles;
