import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    divider: { borderBottom: '1px solid #EAEAEA', margin: '10px auto' },
    verticalDivider: {
      borderLeft: '1px solid #EAEAEA',
      margin: '10px auto',
      height: '40px',
    },
    currencyName: {
      lineHeight: '18px',
      marginBottom: '4px',
      paddingLeft: '10px',
      fontWeight: 600,
    },
    marginName: { fontWeight: 400 },
    marginAmount: { fontWeight: 300 },
  }),
  { name: 'MarginHeldAmounts' }
);

export default useStyles;
