import React from 'react';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useDownloadFile from 'hooks/useDownloadFile';
import t from 'utils/translationHelper';

import { PaymentBatchDto } from '@alpha/payments-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import {
  Box, ClickAwayListener, Typography,
} from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import useActionDropdown from '../../../../../hooks/useActionDropDown';
import useLog from '../../../../../hooks/useLog';
import PaymentsService from '../../../../../services/Payments/payments.service';

import useStyles from './UploadHistoryActionDropdown.style';

interface IProps {
  paymentUploadRecord: PaymentBatchDto;
}

const UploadHistoryActionDropdown: React.FC<IProps> = ({
  paymentUploadRecord,
}: IProps) => {
  const classes = useStyles();

  const { logEvent, logError } = useLog();

  const sb = useAlphaSnackbar();
  const { downloadFile } = useDownloadFile();

  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    setOpen,
  } = useActionDropdown();

  const downloadPaymenFile = async (batchId: string) => {
    try {
      const url = await PaymentsService.getBatchPaymentFileUrl(batchId);
      await downloadFile(url, 'payment');
    } catch (error) {
      sb.trigger(t('error_downloading_payment_file'));
      logError({ action: 'Error getting payment url ', error });
    }
  };

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setMenuCloseCount(0);
  };

  const menuItems = [];

  menuItems.push(
    {
      content: (
        <Typography data-testid={`upload-history-action-${paymentUploadRecord.id}`}>
          {t('download_payment_upload_file')}
        </Typography>
      ),
      icon: displayIcon('Download'),
      onClick: () => {
        downloadPaymenFile(paymentUploadRecord.id);
        logEvent({ action: 'Click donwload payment file' });
      },
      underline: false,
    },
  );

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box data-testid="action-upload-history-dropdown" className={classes.wrapper}>
          <APMenu
            open={Boolean(open)}
            setOpen={setOpen}
            anchorEl={open}
            disableBackdropClick={menuCloseCount === 1}
            disableHoverEffect={false}
            onClose={handleClose}
            buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
            buttonTitle=""
            // @ts-ignore
            icon={faEllipsisH as IconDefinition}
            actions
          >
            {
              menuItems.map((item) => <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>)
            }
          </APMenu>
        </Box>
      </ClickAwayListener>

    </>
  );
};

export default UploadHistoryActionDropdown;
