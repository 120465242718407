import React from 'react';
import moment from 'moment';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/external';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import t from 'utils/translationHelper';

import Warning from '../../../../../../assets/warning.svg';
import { formatNumber } from '../../../../../../utils/currency.helpers';
import APModal from '../../../../../Modals/APModal/APModal';

interface IProps {
  open: boolean;
  onClose: () => void;
  handleAcknowledgeDuplicate: () => void;
  potentialDuplicates: {
    executionDateTime: string;
    rate: number;
    bookedBy: string;
  }[];
}

const DuplicatedTradeModal: React.FC<IProps> = ({
  open,
  onClose,
  potentialDuplicates,
  handleAcknowledgeDuplicate,
}) => {


  const columns: IStyledGenericTableProps['columns'] = [
    { header: t('execute_date_time'), accessor: 'executionDateTime' },
    { header: t('rate'), accessor: 'rate' },
    { header: t('booked_by'), accessor: 'bookedBy' },
  ];

  const potentialDuplicatesData = potentialDuplicates.map((duplicatedRecord) => ({
    ...duplicatedRecord,
    rate: formatNumber(duplicatedRecord?.rate, 2),
    executionDateTime: moment(duplicatedRecord.executionDateTime).format('hh:mma DD/MM/YYYY'),
  }));

  return (
    <APModal.Container open={open} onClose={onClose}>
      <APModal.Wrapper>
        <div style={{ textAlign: 'center', padding: '8px', width: '400px' }}>
          <img src={Warning} style={{ width: '50px', marginBottom: '24px' }} alt="Warning" />
          <div style={{ marginBottom: '48px' }}>
            <Typography style={{
              fontWeight: '800', marginBottom: '16px', fontSize: '20px', color: '#b95656',
            }}
            >
              Transaction Error
            </Typography>
            <Typography style={{ marginBottom: '16px', fontSize: '16px', color: '#b95656' }}>
              {t('potential_duplicated_trades_with_similar_details_have_recently_been_submitted')}
            </Typography>

            <StyledGenericTable
              testId="duplicate-records-table"
              columns={columns}
              data={potentialDuplicatesData}
            />
            <Typography style={{ marginTop: '16px', fontSize: '14px', color: '#b95656' }}>
              {t('if_you_are_confident_that_this_trades_arent_duplicates_continue')}
            </Typography>

          </div>
          <div style={{ width: '100%' }}>
            <ActionButton style={{ width: '170px' }} onClick={handleAcknowledgeDuplicate}>{t('acknowledge')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};
export default DuplicatedTradeModal;
