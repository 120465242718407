import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    itemContainer: {
      padding: '17px 40px 21px 21px',
      display: 'flex',
      flexDirection: 'row',
    },
    unreadMarker: {
      marginTop: '6px',
      width: '8px',
      height: '8px',
      borderRadius: '4px',
      backgroundColor: '#1473E6',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: '9px',
      borderBottom: '1px solid rgba(112, 112, 112, .1)',
    },
    readMarker: {
      width: '8px',
      height: '8px',
      borderRadius: '4px',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: '20px',
    },
    title: {
      color: '#2C2C2C',
      fontSize: '16px',
      fontWeight: typography.fontWeightBold,
      fontFamily: typography.fontFamily,
      lineHeight: '19px',
    },
    body: {
      color: '#9A9A9A',
      fontSize: '14px',
      margin: '24px 0',
      lineHeight: '22px',
      maxWidth: '100%',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',

    },
    date: {
      color: '#9A9A9A',
      alignSelf: 'center',
    },
    viewBtn: {
      fontSize: '12px',
      color: '#2C2C2C',
      backgroundColor: 'rgba(154, 154, 154, .1)',
      textTransform: 'capitalize',
    },
  }),
  { name: 'NotificationDrawerItem' },
);

export default useStyles;
