enum AvailableWidgets {
  CURRENCY_ACCOUNT_BALANCES = 'account_balances',
  OUTSTANDING_ACTIONS = 'outstanding_actions',
  MTM = 'mtm_summary',
  HEDGING_PROGRAMS = 'hedging_quarter',
  LIVE_RATES = 'live_rates',
  UTILISATION = 'utilisation',
  FACILITY_TERMS = 'facility_terms',
}
export default AvailableWidgets;
