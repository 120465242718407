import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import t from 'utils/translationHelper';

import { Box } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';
import routes from '../../../../routes.path';

import useStyles from './index.style';

const InvalidSession: React.FC = () => {

  const classes = useStyles();
  return (
    <div className={clsx(classes.container, classes.fullOpacity)}>
      <Typography className={classes.title}>
        {t('you_have_been_logged_out_of_this_session')}
      </Typography>
      <Typography className={classes.content}>
        {t('for_your_security_reasons_we_have_logged_you_out_sessions')}
        {' '}
        <br />
        {t('if_this_was_not_you_please_contact')}
      </Typography>
      <Box paddingTop="40px">
        <Typography className={classes.content}>
          {t('return_to')}
          {' '}
          <NavLink to={routes.auth.login}>{t('login')}</NavLink>
        </Typography>
      </Box>
    </div>
  );
};
export default InvalidSession;
