import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    body: {
      '& td': {
        fontFamily: typography.fontFamily,
      },
      padding: '80px 40px',
    },
    tradeDetails: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    referenceDetails: {
      borderTop: '1px solid rgba(112, 112, 112, 0.1)',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    paymentAmountWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '1px solid rgba(112, 112, 112, 0.1)',
      paddingTop: 18.5,
      fontWeight: typography.fontWeightBold,
    },
    paymentDetailsHeader: {
      marginBottom: 31.5,
    },
  }),
  { name: 'BodyDetails' },
);

export default useStyles;
