import FacilityTermsTable from 'components/Molecules/Credit/FacilityTermsTable';
import FacilityUtilisation from 'components/Molecules/Credit/FacilityUtilisation';
import TermUtilisation from 'components/Molecules/Credit/TermUtilisation';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import t from 'utils/translationHelper';

import { CreditFacilityDto, CreditFacilityTermDto } from '@alpha/credit-dtos';
import { DashboardUserAccountPreferencesDto, DashboardWidget } from '@alpha/profile-dtos';
import { Box } from '@alpha/ui-lib/ui/external';

import useWidgetPermission from '../useWidgetPermission';

import BalanceWidget from './Widgets/BalanceWidget';
import HedgingProgramWidget from './Widgets/HedgingProgramWidget';
import LiveRatesWidget from './Widgets/LiveRatesWidget';
import MtmWidget from './Widgets/MtmWidget';
import OutstandingActionWidget from './Widgets/OutstandingActionsWidget';
import useStyles from './index.styles';

import 'react-loading-skeleton/dist/skeleton.css';

interface IWidgetPanelProps {
  dashboardSettings?: DashboardUserAccountPreferencesDto;
  viewablePages?: string[];
  handleRemoveWidget?: (widget: DashboardWidget) => void;
}

const WidgetPanel: React.FC<IWidgetPanelProps> = (
  {
    dashboardSettings, viewablePages, handleRemoveWidget,
  }: IWidgetPanelProps,
) => {
  const classes = useStyles();

  const { isWidgetEnabled, isViewableForUser } = useWidgetPermission(
    viewablePages,
  );
  const facilityMode = useSelector<TStore, string | undefined>(
    (state: TStore) => state.creditFacility.facilityMode,
  );
  const creditFacility = useSelector<TStore, CreditFacilityDto | undefined>(
    (state: TStore) => state.creditFacility.facility,
  );
  const termId = useSelector<TStore, string | undefined>(
    (state: TStore) => state.creditFacility.termId,
  );
  const termSummary = useSelector<TStore, CreditFacilityTermDto | undefined>(
    (state: TStore) => state.creditFacility.termSummary,
  );

  return (
    <Box className={classes.panel}>
      {dashboardSettings ? (
        <>
          {(isWidgetEnabled(dashboardSettings, DashboardWidget.CURRENCY_ACCOUNT_BALANCES)
            && isViewableForUser(DashboardWidget.CURRENCY_ACCOUNT_BALANCES))
            ? <BalanceWidget /> : null}

          {isWidgetEnabled(dashboardSettings,
            DashboardWidget.OUTSTANDING_ACTIONS) ? <OutstandingActionWidget /> : null}

          {termId && facilityMode === 'single'
            && (isWidgetEnabled(dashboardSettings, DashboardWidget.MTM)
              && isViewableForUser(DashboardWidget.FACILITY_TERMS))
            ? <MtmWidget isCreditPage handleRemoveWidget={handleRemoveWidget} shouldUseBrackets termId={termId} /> : null}

          {creditFacility?.facilityTerms?.length && facilityMode === 'multiple'
            && isWidgetEnabled(dashboardSettings, DashboardWidget.FACILITY_TERMS) && isViewableForUser(DashboardWidget.FACILITY_TERMS)
            ? <FacilityTermsTable span={2} shouldUseBrackets isDashboardWidget /> : null}

          {termId && termSummary?.id && facilityMode === 'single'
            && isWidgetEnabled(dashboardSettings, DashboardWidget.UTILISATION) && isViewableForUser(DashboardWidget.UTILISATION)
            ? <TermUtilisation term={termSummary} isDashboardWidget /> : null}

          {creditFacility?.facilityTerms?.length && facilityMode === 'multiple'
            && isWidgetEnabled(dashboardSettings, DashboardWidget.UTILISATION) && isViewableForUser(DashboardWidget.UTILISATION)
            ? <FacilityUtilisation facility={creditFacility} isDashboardWidget /> : null}

          {(isWidgetEnabled(dashboardSettings, DashboardWidget.HEDGING_PROGRAMS)
            && isViewableForUser(DashboardWidget.HEDGING_PROGRAMS))
            ? <HedgingProgramWidget /> : null}

          {(isWidgetEnabled(dashboardSettings, DashboardWidget.LIVE_RATES)
            && isViewableForUser(DashboardWidget.LIVE_RATES))
            ? <LiveRatesWidget /> : null}
        </>
      ) : (
        <Box className={`${classes.loading}`}>
          <span>
            {t('initialising_your_dashboard_just_a_moment')}
          </span>
          <Box className={classes.panel}>
            <Skeleton
              height={300}
              borderRadius="5px"
              baseColor="white"
            />
            <Skeleton
              height={300}
              borderRadius="5px"
              baseColor="white"
            />
            <Skeleton
              height={300}
              borderRadius="5px"
              baseColor="white"
            />
            <Box style={{ gridColumn: '1/4', gridAutoColumns: 'auto' }}>
              <Skeleton
                height={400}
                borderRadius="5px"
                baseColor="white"
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WidgetPanel;
