import React from 'react';
import { NavLink } from 'react-router-dom';

import { TradeDto, TradeStatus } from '@alpha/fx-dtos';
import { makeStyles } from '@alpha/ui-lib/theme';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import formatIsoDate from '../../../utils/formatIsoDate';
import mapTradeTypeToFriendlyName from '../../../utils/fxTrades/mapTradeTypeToFriendlyName';

export const useStyles = makeStyles(({ typography }) => ({

  associatedTrades: {
    border: '1px solid #eeeeee',
    borderRadius: '5px',
    padding: '20px',
    width: '100%',
    marginBottom: '8px',
  },
  tradeContractNumber: {
    color: '#1E8777',
    fontWeight: typography.fontWeightSemiBold,
    fontSize: '18px',
  },
  tradeInfo: {
    color: '#999999',
  },

}), { name: 'PaymentInfoDrawer' });

interface IProps {
  trade: TradeDto | undefined;
  loading: boolean;
  handlePaymentTradeContractNoteDownload?: (tradeId: string) => void;
}

const AssociatedTrade: React.FC<IProps> = ({
  trade,
  loading,
  handlePaymentTradeContractNoteDownload,
}: IProps) => {
  const styles = useStyles();


  return (
    <Box className={styles.associatedTrades}>
      {(loading || !trade) ? (
        <Loader
          testId="trade-loading"
          size={40}
        />
      ) : (
        <>
          <Box display="flex" flexDirection="row" justifyContent="space-between" flexGrow={1}>
            <NavLink to={`/app/trades/${trade.id}`}>
              <span className={styles.tradeContractNumber}>
                {trade?.contractNumber || 'TBC'}
              </span>
            </NavLink>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" flexGrow={1}>
            <span className={styles.tradeInfo}>
              {`${mapTradeTypeToFriendlyName(trade?.type).toUpperCase() || '-'} • ${formatIsoDate(trade?.date)}`}
            </span>

            {handlePaymentTradeContractNoteDownload
              && trade?.status !== TradeStatus.VERIFIED
              && trade?.status !== TradeStatus.SUBMITTED
              && (
                <APTooltip title={t('download_trade_contract')}>
                  <ActionButton
                    style={{ background: '#F7F7F7', color: '#212529' }}
                    onClick={() => {
                      if (handlePaymentTradeContractNoteDownload) {
                        handlePaymentTradeContractNoteDownload(trade.id);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload as IconProp} />
                  </ActionButton>
                </APTooltip>
              )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AssociatedTrade;
