import React, { useState } from 'react';
import { FormikProps } from 'formik';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { Input } from '@alpha/ui-lib/ui/Input';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash, faLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import hasCorrectChars from '../../../../utils/hasCorrectChars';
import hasLowerCase from '../../../../utils/hasLowerCase';
import hasNumber from '../../../../utils/hasNumber';
import hasSpecialChars from '../../../../utils/hasSpecialChars';
import hasUpperCase from '../../../../utils/hasUpperCase';
import passwordsMatch from '../../../../utils/passwordsMatch';
import useStyles from '../PersonalSettings.styles';

interface IChangePasswordForm {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface IProps {
  formik: FormikProps<IChangePasswordForm>;
  loading: boolean;
  setCurrentPasswordFocused: (newValue: boolean) => void;
  setNewPasswordFocused: (newValue: boolean) => void;
  setConfirmNewPasswordFocused: (newValue: boolean) => void;
}

const ChangePasswordForm: React.FC<IProps> = ({
  formik,
  loading,
  setCurrentPasswordFocused,
  setNewPasswordFocused,
  setConfirmNewPasswordFocused,
}) => {
  const classes = useStyles();


  const [passwordTypeCurrent, setPasswordTypeCurrent] = useState<'text' | 'password'>('password');
  const [passwordTypeNew, setPasswordTypeNew] = useState<'text' | 'password'>('password');
  const [passwordTypeConfirmNew, setPasswordTypeConfirmNew] = useState<'text' | 'password'>('password');

  const disableSubmitButton = !formik.values.confirmNewPassword
    || !formik.values.newPassword || !formik.values.password
    || !hasLowerCase(formik.values.newPassword) || !hasUpperCase(formik.values.newPassword)
    || !hasNumber(formik.values.newPassword) || !hasCorrectChars(formik.values.newPassword, 8)
    || !hasSpecialChars(formik.values.newPassword)
    || !passwordsMatch(formik.values.newPassword, formik.values.confirmNewPassword)
    || loading;

  return (
    <Box style={{ width: '450px', minWidth: '450px', marginRight: '20px' }}>
      <div className={classes.headerWithIcon}>
        <FontAwesomeIcon icon={faLock as IconProp} className={classes.lockIcon} />
        <Typography className={classes.boxHeaderText}>
          {t('change_password')}
        </Typography>
      </div>
      <Typography className={classes.boxText}>
        {t('current_password')}
      </Typography>
      <Input
        autoComplete="off"
        testId="input-password"
        id="password"
        name="password"
        className={classes.passwordInput}
        variant="filled"
        type={passwordTypeCurrent}
        onChange={formik.handleChange}
        onFocus={() => setCurrentPasswordFocused(true)}
        onBlur={(e) => {
          if (e.target.value === '') setCurrentPasswordFocused(false);
        }}
        value={formik.values.password}
      />
      <button
        type="button"
        tabIndex={-1}
        className={classes.eyeIconStyles}
        onClick={() => setPasswordTypeCurrent(passwordTypeCurrent === 'password' ? 'text' : 'password')}
      >
        <FontAwesomeIcon
          icon={(passwordTypeCurrent === 'password' ? faEye : faEyeSlash) as IconProp}
        />
      </button>

      <Typography className={classes.boxText}>
        {t('new_password')}
      </Typography>
      <Input
        autoComplete="off"
        testId="input-new-password"
        id="newPassword"
        name="newPassword"
        className={classes.passwordInput}
        variant="filled"
        type={passwordTypeNew}
        onChange={formik.handleChange}
        onFocus={() => setNewPasswordFocused(true)}
        onBlur={(e) => {
          if (e.target.value === '') setNewPasswordFocused(false);
        }}
        value={formik.values.newPassword}
      />
      <button
        type="button"
        tabIndex={-1}
        className={classes.eyeIconStyles}
        onClick={() => setPasswordTypeNew(passwordTypeNew === 'password' ? 'text' : 'password')}
      >
        <FontAwesomeIcon
          icon={(passwordTypeNew === 'password' ? faEye : faEyeSlash) as IconProp}
        />
      </button>

      <Typography className={classes.boxText}>
        {t('re-enter_new_password')}
      </Typography>
      <Input
        autoComplete="off"
        testId="input-confirm-new-password"
        id="confirmNewPassword"
        name="confirmNewPassword"
        className={classes.passwordInput}
        variant="filled"
        type={passwordTypeConfirmNew}
        onChange={formik.handleChange}
        onFocus={() => setConfirmNewPasswordFocused(true)}
        onBlur={(e) => {
          if (e.target.value === '') setConfirmNewPasswordFocused(false);
        }}
        value={formik.values.confirmNewPassword}
      />
      <button
        type="button"
        tabIndex={-1}
        className={classes.eyeIconStyles}
        onClick={() => setPasswordTypeConfirmNew(passwordTypeConfirmNew === 'password' ? 'text' : 'password')}
      >
        <FontAwesomeIcon
          icon={(passwordTypeConfirmNew === 'password' ? faEye : faEyeSlash) as IconProp}
        />
      </button>

      <ActionButton
        style={{ marginTop: '10px', borderRadius: '4px' }}
        disabled={disableSubmitButton}
        onClick={formik.submitForm}
      >
        {t('update_password')}
      </ActionButton>
    </Box>
  );
};

export default ChangePasswordForm;
