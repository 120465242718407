import React, { ChangeEvent } from 'react';

import {
  Chip, TextField, Typography,
} from '@alpha/ui-lib/ui/external';
import { Autocomplete } from '@alpha/ui-lib/ui/Select/AutocompleteSelect';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './MultiSelectDropDown.styles';

interface IProps {
  placeholder?: string;
  options: any[];
  disabled?: boolean;
  loading?: boolean;
  onClose?: (event: ChangeEvent<{}>) => void;
  onOpen?: (event: ChangeEvent<{}>) => void;
  setCurrentOptions?: React.Dispatch<React.SetStateAction<any[]>>;
  setTextValue?: React.Dispatch<React.SetStateAction<string>>;
  id?: string;
}

const MultiSelectDropDown: React.FC<IProps> = ({
  placeholder,
  options,
  disabled,
  loading,
  onClose,
  onOpen,
  setCurrentOptions,
  setTextValue,
  id,
}: IProps) => {
  const classes = useStyles();
  return (
    <Autocomplete
      id={id}
      onOpen={onOpen}
      onClose={onClose}
      loading={loading}
      disabled={disabled || false}
      popupIcon={<FontAwesomeIcon icon={faAngleDown as IconProp} />}
      className={classes.dropdown}
      onInputChange={(event, value) => { if (setTextValue) { setTextValue(value); } }}
      onChange={(event: ChangeEvent<{}>, value: any | null) => {
        if (setCurrentOptions) { setCurrentOptions(value); }
      }}
      disableCloseOnSelect
      multiple
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder || 'Select'}
          variant="filled"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            form: {
              autoComplete: 'new-off',
            },
            disableUnderline: true,
            'data-testid': 'multi-select',
            id: `${id}-input`,
          }}
        />
      )}
      renderOption={(option: any, { selected }: any) => (
        <div className={classes.labelWrapper}>
          <Typography className={classes.outputText}>{option.fullName}</Typography>
          {
            selected
            && (
            <FontAwesomeIcon
              className={classes.selectedCheck}
              icon={faCheckCircle as IconProp}
            />
            )
          }
        </div>
      )}
      renderTags={(_value: any[], getTagProps) => _value.map(
        (value: any, index: number) => (
          <div
            className={classes.chip}
          >
            <Chip
              label={value.fullName}
              {...getTagProps({ index })}
            />

          </div>
        ),
      )}
    />
  );
};

export default MultiSelectDropDown;
