import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    tradeContainer: {
      backgroundColor: '#F4F4F4',
      padding: '12px 17px 39px 20px',
      margin: '0px 35px 0px 25px',
    },
    detailContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '21px',
    },
    transfersTitle: {
      margin: '0px 35px 0px 25px',
      fontSize: '14px',
    },
    textStyle: {
      fontSize: 14,
      fontFamily: typography.fontFamilySecondary,
    },
    icon: {
      color: '#00B241',
      width: 26,
      height: 26,
      verticalAlign: 'middle',
    },
    heading: {
      fontSize: '22px',
      fontWeight: typography.fontWeightSemiBold,
      paddingLeft: '20px',
    },
    buttonContainer: {
      padding: '0px 24px 24px 24px',
      '& .MuiButton-root': {
        borderRadius: '5px',
        height: '40px',
      },
    }
  }),
  { name: 'TransferSpotDraftConfirmation' },
);

export default useStyles;
