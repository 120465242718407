import React from 'react';
import moment from 'moment';
import t from 'utils/translationHelper';

import { formatCurrency } from '../../../../../utils/currency.helpers';
import { cutString } from '../../ApprovalDrawer/ApprovalSummary';

interface LinkedPayments {
  beneficiaryName: string;
  amount: number;
  reference: string;
  purpose: string;
  date: string;
  currency: string;
}

type Props = {
  setFieldValue?: (field: string, value: any) => void;
  values: LinkedPayments[];
};

const LinkedPaymentsSummary: React.FC<Props> = ({ values, setFieldValue }) => {

  const rows = values.map((p) => ({
    beneficiaryName: p.beneficiaryName,
    amount: +p.amount!,
    reference: p.reference,
    purpose: p.purpose,
    date: p.date,
    currencyCode: p.currency,
  }));

  return (
    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <tr>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('beneficiary')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('amount')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('reference')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('purpose')}
        </td>
        <td style={{
          marginRight: '32px', fontWeight: 'bold', color: '#999999',
        }}
        >
          {t('date')}
        </td>
      </tr>
      <tr style={{ borderBottom: '1px solid rgba(112,112,112,0.3)', height: '8px' }} />
      <tr style={{ height: '16px' }} />
      {rows.map((r, i) => (
        <>
          {i > 0 && (
            <>
              <tr style={{ borderBottom: '1px solid rgba(112,112,112,0.3)', height: '8px' }} />
              <tr style={{ height: '8px' }} />
            </>
          )}
          <tr>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(r.beneficiaryName)}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(formatCurrency(r.currencyCode, r.amount))}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(r.reference)}</td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>
              {cutString((
                typeof r.purpose === 'string' ? r.purpose?.toString()
                  : r.purpose?.description))}
            </td>
            <td style={{ width: '20%', color: 'black', fontWeight: '500' }}>{cutString(moment(r.date!).format('DD/MM/YY').toString())}</td>
          </tr>
        </>
      ))}
    </table>
  );
};

export default LinkedPaymentsSummary;
