import { ChangeEvent, useEffect } from 'react';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import useSearch from 'hooks/useSearch';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import t from 'utils/translationHelper';

import { CurrencyAccountTransferStatus } from '@alpha/currency-accounts-dtos';

export enum CurrencyAccountTransferEvents {
  RefreshCurrencyAccountTransfersTable = 'RefreshCurrencyAccountTransfersTable',
}

export type TTransferSearchFilterTypes = {
  debitingCurrencies: string[],
  fundingCurrencies: string[],
  statuses: CurrencyAccountTransferStatus[],
  valueDateStart?: string,
  valueDateEnd?: string,
}

type TTransferSearchParams = {
  baseUrl: string,
  queryParams: {
    searchtext?: string,
    take: number,
    skip: number,
    sortby?: string,
    sortorder?: 'asc' | 'desc',
    fundingcurrency?: string,
    debitingcurrency?: string,
    type?: string,
    status?: string,
    valuedatefrom?: string,
    valuedateto?: string,
  } & Record<string, string | number>,
  body?: Record<string, unknown>,
}

export const createSearchParams = (
  skip: number,
  sortBy?: string,
  sortOrder: 'asc' | 'desc' = 'desc',
  searchText = '',
  filters?: TTransferSearchFilterTypes,
): TTransferSearchParams => {
  const searchParams: TTransferSearchParams = {
    baseUrl: '/ca/transfers/search',
    queryParams: {
      skip,
      take: 10,
      sortby: sortBy || '',
      sortorder: sortOrder,
    },
  };
  if (searchText) {
    searchParams.queryParams.searchtext = searchText;
  }
  if (filters) {
    const selectedStatuses = filters?.statuses;

    const uniqueTransferStatutses = Array.from(
      new Set<CurrencyAccountTransferStatus>(selectedStatuses),
    );

    if (filters?.debitingCurrencies) searchParams.queryParams.debitingcurrency = filters?.debitingCurrencies.join(',');
    if (filters?.fundingCurrencies) searchParams.queryParams.fundingcurrency = filters?.fundingCurrencies.join(',');
    if (uniqueTransferStatutses) searchParams.queryParams.status = uniqueTransferStatutses.join(',');
    if (filters?.valueDateStart) searchParams.queryParams.datefrom = filters?.valueDateStart;
    if (filters?.valueDateEnd) searchParams.queryParams.dateto = filters?.valueDateEnd;
  }
  return searchParams;
};

const useTransferTable = (
  filters?: TTransferSearchFilterTypes,
) => {
  const table = useSearch();
  const sb = useAlphaSnackbar();

  const searchParams = createSearchParams(
    table.skip,
    'submittedDate',
    'desc',
    table.searchText,
    filters,
  );

  const handleTableSortClick = (column: string) => {
    table.handleTableSortClick(
      searchParams, column,
    );
  };
  

  const columns: ITableColumn[] = [
    {
      header: t('transfer_id'), accessor: 'contractNumber', sortable: true, width: 120,
    },
    {
      header: t('date'), accessor: 'valueDate', sortable: true, width: 120,
    },
    {
      header: t('from'), accessor: 'fundingAccountName', sortable: true, width: 200, align: 'left',
    },
    {
      header: t('amount'), accessor: 'fundingAmount', sortable: true, width: 100, align: 'right',
    },
    {
      header: t('rate'), accessor: 'rate', sortable: false,
    },
    {
      header: t('to'), accessor: 'debitingAccountName', sortable: false, width: 200, align: 'left',
    },
    {
      header: t('transfer_ref'), accessor: 'reference', sortable: true,
    },
    {
      header: t('amount'), accessor: 'debitingAmount', sortable: true, width: 100, align: 'right',
    },
    {
      header: t('approvals'), accessor: 'approvals', sortable: false, width: 170,
    },
    {
      header: t('status'), accessor: 'transferStatus', sortable: true, width: 170,
    },
    {
      header: t('trade_link'), accessor: 'tradeLink', sortable: false, width: 120,
    },
    { header: t('action'), accessor: 'action', align: 'center' },
  ];

  useEffect(() => {
    table.handleInitialSearch(searchParams);
  }, [filters]);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    table.setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.skip = 0;
      searchParams.queryParams.searchtext = e.target.value;
    } else {
      delete searchParams.queryParams.searchtext;
    }
    await table.handleNewSearch(searchParams);
  };
  const reloadTableData = (): void => {
    table.handleNewSearch(searchParams);
  };

  return {
    columns,
    table,
    handleInputChange,
    reloadTableData,
    handleTableSortClick,
    searchParams,
  };
};

export default useTransferTable;
