import React from 'react';
import t from 'utils/translationHelper';

import { Breadcrumbs } from '@alpha/ui-lib/ui/Breadcrumbs/Breadcrumbs';
import { IBreadcrumbsProps } from '@alpha/ui-lib/ui/Breadcrumbs/Breadcrumbs.interfaces';
import { APSimplifiedDrawer } from '@alpha/ui-lib/ui/Drawer';
import { DrawerProps } from '@alpha/ui-lib/ui/external';
import { Stat } from '@alpha/ui-lib/ui/Stat/Stat';
import { Status } from '@alpha/ui-lib/ui/Status/Status';
import { TStatusVariants } from '@alpha/ui-lib/ui/Status/Status.interfaces';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider/StyledDivider';

import useStyles from './Drawer.styles';

type THeaderStatus = {
  variant: TStatusVariants;
  children: string;
};

interface IAPStyledSimplifiedDrawer extends DrawerProps {
  breadcrumbs?: IBreadcrumbsProps;
  headerTitle: JSX.Element | string;
  headerImage?: JSX.Element;
  headerSubtitle?: JSX.Element | string;
  headerStats?: {
    [key: string]: { value: any, alignRight?: boolean };
  };
  headerStatusWithLabel?: THeaderStatus;
  headerStatus?: THeaderStatus;
  headerIcon?: JSX.Element;
  headerButtons?: JSX.Element;
  headerPage?: string;
  children: JSX.Element;
  testId?: string;
  footer?: JSX.Element;
  noPadding?: boolean;
}

const Drawer: React.FC<IAPStyledSimplifiedDrawer> = (props: IAPStyledSimplifiedDrawer) => {

  const {
    breadcrumbs,
    headerTitle,
    headerImage,
    headerSubtitle,
    headerStats,
    headerStatusWithLabel,
    headerStatus,
    headerIcon,
    headerButtons,
    headerPage,
    children,
    footer,
    noPadding,
    ...rest
  } = props;
  const Styles = useStyles();
  const statEntries = headerStats && Object.entries(headerStats);

  return (
    <APSimplifiedDrawer
      {...rest}
      headerContent={(
        <div>
          <div className={Styles.section}>{breadcrumbs && <Breadcrumbs {...breadcrumbs} />}</div>
          <div className={Styles.headerWrapper}>
            {headerImage
              && (
                <div
                  className={Styles.headerImage}
                  style={{
                    backgroundImage: `url(${headerImage})`,
                  }}
                />
              )}
            <div>
              <div className={Styles.info}>
                <h2>
                  {headerTitle}
                  {headerIcon}
                </h2>
                <div className="infoPage">
                  <span>{headerPage}</span>
                </div>
              </div>
              <div className={Styles.subtitle}>
                <h4>{headerSubtitle}</h4>
              </div>
            </div>
          </div>
          <div className={Styles.statusAndButtons}>
            {headerStatusWithLabel || statEntries ? (
              <div className={Styles.stats}>
                <div>
                  <div className={Styles.statsStatus}>
                    {headerStatusWithLabel && (
                      <>
                        <Stat
                          title={t('status')}
                          value={headerStatusWithLabel.children}
                          variant="status"
                          statusVariant={headerStatusWithLabel.variant}
                        />
                        <StyledDivider vertical />
                      </>
                    )}
                  </div>
                  {statEntries && statEntries.map((entry, i) => {
                    const title = t(entry[0].replace('_', ' '));
                    const { value } = entry[1];
                    const { alignRight } = entry[1];
                    return <Stat key={i} title={title} value={title === 'Available Balance' ? <b>{value}</b> : value} alignRight={alignRight || false} />;
                  })}
                </div>
              </div>
            ) : null}
            {headerButtons
              && (
                <div className={Styles.buttons}>
                  {headerButtons}
                </div>
              )}
          </div>
          <div className={Styles.status}>{headerStatus && <Status {...headerStatus} />}</div>
        </div>
      )}
      noPadding={noPadding}
    >
      {children}
    </APSimplifiedDrawer>
  );
};

export default Drawer;
