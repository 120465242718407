import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 0,
      width: 50,
      height: 50,
    },
  }),
  { name: 'HeaderButtons' },
);

export default useStyles;
