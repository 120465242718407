import React from 'react';

import { createFilterOptions } from '@alpha/ui-lib/ui/Select';

import AutocompleteDropDown, {
  IOption,
} from '../../../../../../../components/AutocompleteDropDown/AutocompleteDropDown';

interface IProps {
  dropdownOptions: IOption[];
  currentOption: IOption;
  textFieldValue: string;
  setTextFieldValue: React.Dispatch<React.SetStateAction<string>>;
  setCurrentOption: React.Dispatch<React.SetStateAction<IOption>>;
  handleChange: (name: string, valueCode: string, option: IOption) => void;
  handleKeyUp: (event: any) => void;
  disabled?: boolean;
  dropdownHeight?: string;
}

const BeneficiaryDropdown: React.FC<IProps> = (props: IProps) => {
  const {
    dropdownOptions,
    currentOption,
    textFieldValue,
    setTextFieldValue,
    setCurrentOption,
    handleChange,
    handleKeyUp,
    disabled,
    dropdownHeight,
  } = props;

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => option.name + option.alternativeName,
  });

  return (
    <AutocompleteDropDown
      disabled={disabled}
      name="manualInput"
      hideFlag
      placeholder=" "
      options={dropdownOptions}
      currentOption={currentOption}
      setCurrentOption={setCurrentOption}
      setFieldValue={handleChange}
      handleKeyUp={handleKeyUp}
      textFieldValue={textFieldValue || ''}
      setTextFieldValue={setTextFieldValue}
      filterOptions={filterOptions}
      dropdownHeight={dropdownHeight}
    />
  );
};

export default BeneficiaryDropdown;
