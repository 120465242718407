import { createBeneficiaryParty } from 'components/Drawer/BeneficiaryDrawer/Body/Body';
import useAuthorization from 'hooks/useAuthorization';
import { TBeneficiaryApproved } from 'models/beneficiaries';
import { UserRole } from 'models/user';
import React, { useEffect, useState } from 'react';
import formatBankCode from 'utils/formatBankCode';
import formatIsoDate from 'utils/formatIsoDate';
import generateResidentAddress from 'utils/generateResidentAddress';
import t from 'utils/translationHelper';

import { BeneficiaryDto, BeneficiaryStatus } from '@alpha/bene-dtos';
import { TKeyValue } from '@alpha/ui-lib/ui/table';
import { StyledKeyValueTable } from '@alpha/ui-lib/ui/table/StyledKeyValueTable';

import { ISelectedBeneficiary } from '../../BeneInfoDrawer';

import { Loader } from '@alpha/ui-lib/ui/Loader';
import { IconButton } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle, faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import isEmpty from 'utils/isEmpty';
import { useStyles } from './Details.styles';

interface IDetailsProps {
  selectedBeneficiary: ISelectedBeneficiary | undefined;
  setCreateBeneficiaryOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  handleApprovedEditButtonClick?: (clickedBeneficiary: BeneficiaryDto) => void;
  setAddressOnly?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}
const Details: React.FC<IDetailsProps> = (props: IDetailsProps) => {

  const { selectedBeneficiary, setCreateBeneficiaryOpen, handleApprovedEditButtonClick, setAddressOnly } = props;
  const classes = useStyles();
  const { authorized: paymentFileInputter } = useAuthorization([[UserRole.PAYMENT_FILE_INPUTTER]]);
  const [loading, setLoading] = useState<boolean>(false);
  const [requiresAddress, setRequiresAddress] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const address = (() => {
    if (selectedBeneficiary?.approved) {
      const approvedBene = selectedBeneficiary?.beneficiary as TBeneficiaryApproved;
      return generateResidentAddress(
        approvedBene.addressLine1,
        approvedBene.city,
        approvedBene.countryCode,
      ) || '-';
    }
    const pendingBeneAddress = selectedBeneficiary?.beneficiary;
    return generateResidentAddress(
      pendingBeneAddress?.addressLine1,
      pendingBeneAddress?.city,
      pendingBeneAddress?.countryCode,
    ) || '-';
  })();

  const showApprovedInformation = () => {
    if (selectedBeneficiary?.approved) {
      return [{
        key:
          t('approved_by'),
        value: `${selectedBeneficiary.beneficiary?.lastApprover || '-'},
         ${formatIsoDate(selectedBeneficiary.beneficiary?.approvedByDate) || '-'}`,
      }];
    }
    return [];
  };

  const showRejectedInformation = () => {
    if (selectedBeneficiary?.beneficiary?.status === BeneficiaryStatus.CLIENT_REJECTED
      || selectedBeneficiary?.beneficiary?.status === BeneficiaryStatus.ALPHA_REJECTED) {
      return [{
        key: t('reject_by'),
        value: `${selectedBeneficiary?.beneficiary?.rejectedBy || '-'},
         ${formatIsoDate(selectedBeneficiary?.beneficiary?.rejectedByDate) || '-'}`,
      }];
    }

    return [];
  };

  const showAlphaUniqueId = () => {
    if (paymentFileInputter) {
      return [{
        key: t('alpha_unique_id'),
        value: selectedBeneficiary?.beneficiary?.id,
      }];
    }

    return [];
  };

  const getAvailablePaymentMethods = async (
    _bankCountryCode?: Readonly<string>,
    _currencyCode?: Readonly<string>,
  ) => {
    try {
      setLoading(true);
      const response = await BeneficiariesService.getCountryRequirements(_bankCountryCode && _currencyCode ?
        [{ countryCode: _bankCountryCode, currencyCode: _currencyCode }] : []);
      setRequiresAddress(response[0].requiresAddress || false);

    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || t('there_was_an_error_retrieving_the_available_payment'));
      logError({ action: 'Error retrieving the available payment methods for the selected bank country', error: e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedBeneficiary?.beneficiary?.bankCountryCode && selectedBeneficiary?.beneficiary?.currencyCode) {
      getAvailablePaymentMethods(selectedBeneficiary?.beneficiary?.bankCountryCode, selectedBeneficiary?.beneficiary?.currencyCode)
    }
  }, [selectedBeneficiary])

  const details: TKeyValue[] = [
    {
      key: t('beneficiary_type'),
      value: createBeneficiaryParty(selectedBeneficiary?.beneficiary?.type,
        selectedBeneficiary?.beneficiary?.party,
        selectedBeneficiary?.beneficiary?.partyOverride,
        selectedBeneficiary?.beneficiary?.partyOverrideApproved),
      boldValue: true,
    },
    ...requiresAddress &&
      (isEmpty(selectedBeneficiary?.beneficiary?.addressLine1) ||
        isEmpty(selectedBeneficiary?.beneficiary?.address) ||
        isEmpty(selectedBeneficiary?.beneficiary?.countryCode) ||
        isEmpty(selectedBeneficiary?.beneficiary?.city) ||
        isEmpty(selectedBeneficiary?.beneficiary?.state) ||
        isEmpty(selectedBeneficiary?.beneficiary?.zip)
      ) ? [
      {
        key: (
          <div style={{ color: '#CF752F' }}>
            <FontAwesomeIcon icon={faExclamationCircle} style={{ marginRight: '8px' }} />
            <span>{t('address')}</span>
            <div style={{ fontSize: '10px' }}>
              {t('this_field_is_missing_regulatory_data')}
            </div>
          </div>
        ),
        value: (
          <div style={{ color: '#CF752F' }}>
            -
            <IconButton
              onClick={() => {
                if (setCreateBeneficiaryOpen) {
                  setCreateBeneficiaryOpen(true)
                }
                if (handleApprovedEditButtonClick) {
                  handleApprovedEditButtonClick(selectedBeneficiary?.beneficiary)
                }
                if (setAddressOnly) {
                  setAddressOnly(true)
                }
              }}
              className={
                classes.editButton
              }
            >
              <FontAwesomeIcon
                icon={faPen as IconProp}
                size="sm"
              />
            </IconButton>
          </div>
        ),
        boldValue: true,
      }] : [{
        key: t('address'),
        value: generateResidentAddress(
          selectedBeneficiary?.beneficiary?.addressLine1,
          selectedBeneficiary?.beneficiary?.city,
          selectedBeneficiary?.beneficiary?.countryCode
        ),
        boldValue: true,
      }],
    { key: t('bank_names'), value: selectedBeneficiary?.beneficiary?.bankName || '-', datadogMask: true },
    { key: t('swift/bic'), value: selectedBeneficiary?.beneficiary?.swift || '-', datadogMask: true },
    {
      key: t('iban'),
      value: selectedBeneficiary?.beneficiary?.iban
        ? selectedBeneficiary.beneficiary.iban && selectedBeneficiary.beneficiary.iban.toUpperCase()
        : '-',
      datadogMask: true,
    },
    { key: t('account_number'), value: selectedBeneficiary?.beneficiary?.accountNumber || '-', datadogMask: true },
    {
      key: selectedBeneficiary?.beneficiary?.nationalBankCodeType || t('national_bank_code'),
      value: selectedBeneficiary?.beneficiary?.nationalBankCode ? formatBankCode(selectedBeneficiary.beneficiary.nationalBankCode, selectedBeneficiary.beneficiary.bankCountryCode) : '-',
      datadogMask: true,
    },
    { key: t('correspondent_account_number'), value: selectedBeneficiary?.beneficiary?.correspondentAccountNumber || '-', datadogMask: true },
    { key: t('correspondent_swift'), value: selectedBeneficiary?.beneficiary?.correspondentSwift || '-', datadogMask: true },
    { key: t('further_to_account_number'), value: selectedBeneficiary?.beneficiary?.furtherToAccountNumber || '-', datadogMask: true },
    {
      key: t('further_to_swift'),
      value:
        selectedBeneficiary?.beneficiary?.furtherToSwift || '-',
      datadogMask: true,
    },
  ];

  const nonEmptyDetails = details.filter((item) => item.value !== '-');

  const info: TKeyValue[] = [
    {
      key: t('uploaded_by'),
      value: `${selectedBeneficiary?.beneficiary?.uploadedBy || '-'
        }, ${formatIsoDate(selectedBeneficiary?.beneficiary?.uploadedByDate) || '-'}`,
    },
    ...showApprovedInformation(),
    ...showRejectedInformation(),
    ...showAlphaUniqueId(),
  ];

  return (
    <div>
      {loading ? (<Loader testId={''} style={{ marginTop: '50px', marginBottom: '50px' }}></Loader>) : (<StyledKeyValueTable testId="beneficiary-detail" data={nonEmptyDetails} className={classes.beneDetails} />)}
      <StyledKeyValueTable title={t('submission_information')} testId="beneficiary-info" data={info} />
    </div>
  );
};

export default Details;
