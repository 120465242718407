const useOutstandingActionCache = () => {
  function saveResponse<T>(key: string, result: T):void {
    if (localStorage) {
      localStorage.setItem(key, JSON.stringify(result));
    }
  }
  function getResponse<T>(key: string): T | null {
    if (!localStorage) {
      return null;
    }
    const json = localStorage.getItem(key);
    if (json) { return JSON.parse(json) as T; } return null;
  }
  return {
    saveResponse,
    getResponse,
  };
};

export default useOutstandingActionCache;
