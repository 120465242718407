/* eslint-disable no-await-in-loop */
import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import timer from 'utils/timer';
import t from 'utils/translationHelper';

import { UserAcknowledgmentRequest, UserAcknowledgmentType } from '@alpha/auth-dtos';
import {
  BeneficiaryDto, BeneficiaryParty, BeneficiaryStatus,
  CopResponse,
  CurrencyList, ValidateBeneficiaryRequest,
  ValidateBeneficiaryResponse,
} from '@alpha/bene-dtos';

import PaymentContext from 'components/Molecules/Payments/Beneficiaries/AddPayment/paymentContext';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useAuthorization from 'hooks/useAuthorization';
import { useForm } from 'hooks/useForm';
import useLog from 'hooks/useLog';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { TCountries } from 'models/countries';
import { UserRole } from 'models/user';
import AuthService from 'services/Auth/auth.service';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import { initiateBeneficiary } from 'store/authy/actions';

import { initialValues, validation } from './Form/formData';
import { FeatureFlagName } from 'services/Config/config.service';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';

// eslint-disable-next-line max-lines-per-function
export const useCreateBeneficiary = (
  selectedBeneficiary: BeneficiaryDto | undefined,
  handleDrawerClose: () => void,
  setValidatedBeneId: Dispatch<SetStateAction<string>>,
  updateDrawer?: boolean,
  refetch?: any,
) => {
  const [currencyList, setCurrencies] = useState<CurrencyList>({
    currencies: [],
    topCurrencies: [],
  });

  const { isFlagActive } = useGetFeatureFlags();

  const [requiresAddress, setRequiresAddress] = useState<boolean>(false);
  const [requiresCorrespondentBankDetails, setRequiresCorrespondentBankDetails] = useState<boolean>(false);
  const [countries, setCountries] = useState<TCountries>([]);
  const [formValidation, setFormValidation] = useState(validation);
  const [modalOpen, setModalOpen] = useState(false);
  const [COPmodalOpen, setCOPModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const paymentContext = useContext(PaymentContext);
  const [error, setError] = useState<string>('');
  const [validateBatchId, setValidateBatchId] = useState<string>();
  const [responseCode, setResponseCode] = useState<number>();
  const [validatedBene, setValidatedBene] = useState<ValidateBeneficiaryResponse>();

  const refetchWithWait = async () => {
    if (responseCode === 200 && refetch) {
      await timer(1000);
      await refetch();
    }
  };

  useEffect(() => {
    refetchWithWait();
  }, [responseCode]);

  const dispatch = useDispatch();
  const { getAccountDetailsFromLocalStorage } = useSwitchAccount();
  const { logEvent, logError } = useLog();

  const beneficiaryApproverOwn = useAuthorization([
    [UserRole.BENEFICAIRY_APPROVER_OWN],
  ]);
  const form = useForm(initialValues, formValidation, handleFormSubmission);
  const sb = useAlphaSnackbar();

  const firstParty = form.values.party === BeneficiaryParty.FIRST;

  useEffect(() => {
    Promise.all([getCurrencies(), getCountries()]);
    if (selectedBeneficiary) {
      form.setValues(selectedBeneficiary);
    }
  }, []);

  useEffect(() => {
    const {
      name,
      street,
      city,
      state,
      postalCode,
      countryCode,
      type,
    } = getAccountDetailsFromLocalStorage();

    form.setFieldValue('countryCode', '');

    if (firstParty && requiresAddress && !updateDrawer) {
      form.setFieldValue('name', name);
      form.setFieldValue('addressLine1', street);
      form.setFieldValue('addressLine2', '');
      form.setFieldValue('city', city);
      form.setFieldValue('state', state);
      form.setFieldValue('zip', postalCode);
      form.setFieldValue('countryCode', countryCode || form.values.bankCountryCode);
    } else {
      form.setFieldValue('name', selectedBeneficiary?.name || '');
      form.setFieldValue('addressLine1', selectedBeneficiary?.addressLine1 || '');
      form.setFieldValue('addressLine2', selectedBeneficiary?.addressLine2 || '');
      form.setFieldValue('city', selectedBeneficiary?.city || '');
      form.setFieldValue('state', selectedBeneficiary?.state || '');
      form.setFieldValue('zip', selectedBeneficiary?.zip || '');
      form.setFieldValue('countryCode', selectedBeneficiary?.countryCode || countryCode || form.values.bankCountryCode || '');
    }
    if (firstParty) {
      form.setFieldValue('type', type?.toUpperCase());
      form.setFieldValue('countryCode', selectedBeneficiary?.countryCode || countryCode || '');
    } else {
      form.setFieldValue('countryCode', selectedBeneficiary?.countryCode || form.values.bankCountryCode || '');
    }
    if (form.values.name) {
      form.setFieldValue('name', form.values.name);
    }
  }, [form.values.party, requiresAddress]);

  async function getCurrencies() {
    try {
      setCurrencies(await BeneficiariesService.getCurrencies());
    } catch (e) {
      sb.trigger(e.response?.data?.error || t('there_was_an_issue_retrieving_currencies'));
      logError({ action: 'Error getting beneficiary currencies.' });
    }
  }

  async function getCountries() {
    try {
      setCountries(await BeneficiariesService.getCountries());
    } catch (e) {
      sb.trigger(e.response?.data?.error || error?.message || t('there_was_an_issue_retrieving_countries'));
      logError({ action: 'Error getting beneficiary countries.' });
    }
  }

  async function handleFormSubmission(formValues: ValidateBeneficiaryRequest) {
    try {
      setLoading(true);
      if (!updateDrawer) {
        if (paymentContext) {
          try {
            await validateManualPaymentBeneficiary(formValues);
            sb.trigger(`${t('new_beneficiary')} ${formValues.name} ${t('created')}`, 'success');
            logEvent({ action: 'Successfully created beneficiary' });
            if (handleDrawerClose) { handleDrawerClose(); }
          } catch (e) {
            const errorMessage = (e.response?.data?.errors?.length
              && (e.response?.data?.errors[0])) || e.response?.data?.error || e.message || t('there_was_an_error_submitting_your_bene');
            setError(errorMessage);
            sb.trigger(`${t('please_check_again')} ${errorMessage}`);
            logError({ action: 'Error creating beneficiary.', error: e });
          }
          setLoading(false);
          return;
        }
        const bene = await BeneficiariesService.validateBeneficiary(formValues);
        const {
          id, status,
          applyFinancialErrored, errors, confirmationOfPayeeResponse
        } = bene;
        setValidatedBeneId(id);
        setValidatedBene(bene);
        await parseBatchStatus(
          status,
          id,
          errors,
          applyFinancialErrored,
          confirmationOfPayeeResponse,
          formValues.userIgnoresApplyFinancialErrors);
      } else {
        try {
          const code = await BeneficiariesService.putUpdateBeneficiary(formValues.id, formValues);
          setResponseCode(code);
          sb.trigger(t('updated_beneficiary'), 'success');
          logEvent({ action: 'Successfully updated beneficiary' });
          if (handleDrawerClose) { handleDrawerClose(); }
        } catch (e) {
          const errorMessage = (e.response?.data?.errors?.length
            && (e.response?.data?.errors[0])) || e.response?.data?.error || e.message || t('there_was_an_error_submitting_your_bene');
          setError(errorMessage);
          sb.trigger(`${t('please_check_again')} ${errorMessage}`);
          logError({ action: 'Error updating beneficiary.', error: e });
        }
        setLoading(false);
        return;
      }
    } catch (e) {
      const errorMessage = (e.response?.data?.errors?.length
        && (e.response?.data?.errors[0])) || e.response?.data?.error || e.message || 'There was an error submitting your beneficiary';
      setError(errorMessage);
      sb.trigger(`${t('please_check_again')} ${errorMessage}`);
      logError({ action: 'Error creating beneficiary.', error: e });
    } finally {
      setLoading(false);
    }
  }

  const handleApproveAFCreateBeneficiary = (detail?: object) => {
    try {
      const acknowledgementRequest: UserAcknowledgmentRequest = {
        itemId: validateBatchId || form.values.name || '',
        type: UserAcknowledgmentType.BENEFICIARY,
        content: {
          error,
          message: 'Beneficiary validation error',
          detail: JSON.stringify(detail) || '',
        },
      };
      AuthService.postUserAcknowlegement(acknowledgementRequest);
      logEvent({ action: 'User Acknowledged beneficiary validation error', detail: detail });
    } catch (e) {
      logError({ action: 'Error acknowledging beneficiary validation', error: e });
    }

    form.setFieldValue('userIgnoresApplyFinancialErrors', true);
    form.handleSubmit();
    setModalOpen(false);
    setCOPModalOpen(false);
  };

  async function validateManualPaymentBeneficiary(formValues: ValidateBeneficiaryRequest) {
    const validatedBeneficiary = await BeneficiariesService.validateBeneficiary(formValues);
    setValidateBatchId(validatedBeneficiary.id);
    setValidatedBeneId(validatedBeneficiary.id);

    if (isFlagActive(FeatureFlagName.COP) &&
      validatedBeneficiary.confirmationOfPayeeResponse
      && !formValues.userIgnoresApplyFinancialErrors) {
      setCOPModalOpen(true);
      return;
    }

    if (validatedBeneficiary.status === BeneficiaryStatus.INVALID) {
      getInvalidReason(validatedBeneficiary.errors,
        validatedBeneficiary.applyFinancialErrored);
    }

    if (validatedBeneficiary && validatedBeneficiary.status === BeneficiaryStatus.VALIDATED) {
      paymentContext?.setFieldValue('beneficiary', {
        id: validatedBeneficiary.id,
        ...formValues,
      });
    }

    setValidatedBene(validatedBeneficiary);
  }

  const parseBatchStatus = async (
    batchStatus: BeneficiaryStatus,
    _batchId: string,
    errors?: string[],
    applyFinancialErrored?: boolean,
    confirmationOfPayeeResponse?: CopResponse,
    userIgnoresApplyFinancialErrors?: boolean,
  ): Promise<void> => {
    setValidateBatchId(_batchId);

    if (isFlagActive(FeatureFlagName.COP) && confirmationOfPayeeResponse && !userIgnoresApplyFinancialErrors) {
      setCOPModalOpen(true);
      return;
    }

    switch (batchStatus) {
      case 'INVALID':
        await getInvalidReason(errors!, applyFinancialErrored!);
        break;
      case 'VALIDATED':
        validated(_batchId);
        break;
      default:
        throw Error(`Error, batchStatus: ${batchStatus} is not recognised`);
    }
  };

  const validated = (_batchId: string) => {
    dispatch(initiateBeneficiary({
      approverOwn: beneficiaryApproverOwn.authorized, batchId: _batchId, submission: true, type: 'BENEFICIARY',
    }));
  };

  const getInvalidReason = (
    errors: string[],
    applyFinancialErrored: boolean,
  ): void => {
    if (applyFinancialErrored) {
      setModalOpen(true);
    }
    if (errors && errors.length > 0) {
      throw Error(errors.map((err) => `${err} \n`).join(''));
    }
  };

  return {
    form,
    countries,
    currencyList,
    modalOpen,
    COPmodalOpen,
    error,
    loading,
    validatedBene,
    requiresAddress,
    requiresCorrespondentBankDetails,
    formValidation,
    setModalOpen,
    setCOPModalOpen,
    setRequiresAddress,
    setRequiresCorrespondentBankDetails,
    setFormValidation,
    handleFormSubmission,
    handleApproveAFCreateBeneficiary,
  };
};

export default useCreateBeneficiary;
