import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles((theme) => ({
  progressBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '360px',
    width: '90%',
    margin: 'auto',
    marginTop: '12px',
    border: `1px dashed ${theme.palette.grey[300]}`,
    borderRadius: 10,
    backgroundColor: theme.palette.grey[100],
    '& > div.middleContent': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& img': {
      height: '90px',
      width: '90px',
      marginBottom: '12px',
    },
  },
  circularProgressBackground: {
    padding: '20px',
    background: 'rgba(9,131,117, 0.12)',
    borderRadius: '50%',
  },
}), { name: 'UploadProgress' });

export default useStyles;
