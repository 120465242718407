import React, { useEffect, useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import { CurrencyPair } from 'components/International';
import BackdropLoader from 'components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable from 'components/Table/EmptyTable';
import t from 'utils/translationHelper';
import { ITableColumn } from 'services/DatabaseServices/marker.interface';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapPaymentStatusDisplay from 'utils/payments/mapPaymentStatusDisplay';

import { PaymentBatchDto, PaymentDto } from '@alpha/payments-dtos';
import { Button } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';

import NoOfApprovers from '../../../FxTradeTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import PaymentActionBar from '../../PaymentActionBar/PaymentActionBar';

import useStyles from './BatchPaymentsTable.styles';

interface IProps {
  payments: PaymentDto[],
  paymentBatch: PaymentBatchDto | undefined,
  loading: boolean,
  selectedIds: string[],
  setSelectedIds: (ids: string[]) => void,
  handleBeneficiaryButton: (beneficiaryId: string) => void,
  setRejectedPaymentIds: (rejectedIds: string[]) => void,
  setOpenApproveModal: (open: boolean) => void,
  setOpenRejectModal: (open: boolean) => void,
  setPaymentsToApprove: (payments: PaymentDto[]) => void,
  setPaymentsToReject: (payments: PaymentDto[]) => void,
}

const BatchPaymentsTable: React.FC<IProps> = (props: IProps) => {

  const columns: ITableColumn[] = [
    { header: t('payment_id'), accessor: 'contractNumber' },
    { header: t('beneficiary_name'), accessor: 'beneficiaryName' },
    { header: t('payment_currency'), accessor: 'debitingCurrency' },
    { header: t('value_date'), accessor: 'valueDate' },
    { header: t('payment_ref'), accessor: 'reference' },
    { header: t('amount'), accessor: 'instructedAmount' },
    { header: t('approvals'), accessor: 'approvals' },
    { header: t('status'), accessor: 'paymentStatus', width: 200 },
  ];
  const {
    payments,
    paymentBatch,
    loading,
    selectedIds,
    setSelectedIds,
    handleBeneficiaryButton,
    setRejectedPaymentIds,
    setOpenApproveModal,
    setOpenRejectModal,
    setPaymentsToApprove,
    setPaymentsToReject,
  } = props;

  const [multiSelectedPayments, setMultiSelectedPayments] = useState<PaymentDto[]>([]);

  const classes = useStyles();

  const handleClearButtonClick = () => {
    setSelectedIds([]);
  };

  const mapPaymentObjectWithSelectedIds = (selectedPaymentIds: string[]): void => {
    const resultPayments: PaymentDto[] = multiSelectedPayments?.filter(
      (payment) => selectedPaymentIds?.includes(payment.id),
    ) || [];

    const multiSelectedPaymentsIds = multiSelectedPayments?.map((payment) => payment.id);
    const newlySelectedIds = selectedPaymentIds?.filter(
      (paymentId) => !multiSelectedPaymentsIds?.includes(paymentId),
    );

    const paymentData: PaymentDto[] = payments || [];
    const newlySelected: PaymentDto[] = paymentData.filter(
      (item) => newlySelectedIds.includes(item.id),
    );

    resultPayments.push(...newlySelected);

    setMultiSelectedPayments(resultPayments);
    setPaymentsToApprove(resultPayments);
    setPaymentsToReject(resultPayments);
  };

  const formattedTableData = payments.map((payment: PaymentDto) => ({
    ...payment,
    debitingCurrency: (payment.debitingCurrency && (
      <CurrencyPair currencyCode={payment.debitingCurrency} />
    )) || '-',
    contractNumber: payment.contractNumber || '-',
    instructedAmount: <span className={classes.amount}>{[formatNumber(payment.amount, 2), payment.debitingCurrency].join(' ')}</span>,
    valueDate: formatIsoDate(payment.valueDate),
    beneficiaryName: (
      <Button
        disableRipple
        className={classes.tableButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (handleBeneficiaryButton) { handleBeneficiaryButton(payment.beneficiaryId); }
        }}
      >
        {payment.beneficiaryName || '-'}
      </Button>),
    approvals: (payment
      && (
        <NoOfApprovers
          uploadedBy={payment.uploadedBy}
          uploadedDate={payment.uploadedDate}
          noOfApprovals={payment.approvalInfo.approvals?.length}
          approvedBy={payment.approvalInfo.approvals || []}
          approvalsRequired={payment.approvalInfo.approvalsRequired}
        />
      )
    ) || '-',
    paymentStatus: (
      <Status className={classes.status} variant={mapPaymentStatusDisplay(payment.status).variant}>{t(mapPaymentStatusDisplay(payment.status).text) || '-'}</Status>),
    paymentStatusStr: payment.status,
    paymentDto: payment,
  }));

  const showActionBar = multiSelectedPayments && multiSelectedPayments.length > 0;

  useEffect(() => {
    mapPaymentObjectWithSelectedIds(selectedIds);
  }, [selectedIds]);

  if (loading) return <BackdropLoader testId="batch-payments-loader" />;

  if (payments.length) {
    return (
      <div className={classes.tableWrapper}>
        <StyledGenericTable
          testId="batch-payments-table"
          columns={columns}
          data={formattedTableData}
          multiSelectable
          keyColumn="id"
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          totalRowNumber={formattedTableData.length}
        />
        <PaymentActionBar
          selectedPayments={multiSelectedPayments}
          handleClearButtonClick={handleClearButtonClick}
          handleOpenApprovalDrawer={() => setOpenApproveModal(true)}
          handleRejectModalOpen={() => setOpenRejectModal(true)}
          show={showActionBar}
        />
      </div>
    );
  }

  return (
    <EmptyTable
      title="No Batch Payments"
      subtitle="There are no payments associated with this batch."
      icon={clipboardCross}
    />
  );
};

export default BatchPaymentsTable;
