import { AxiosResponse } from 'axios';

import { CurrencyAccountRequest, CurrencyAccountTransferStatus, CurrencyDto, CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';

import {
  TCurrencyResponse,
  TCurrencyTab,
  TReportGenerationPayload,
  TReportGenerationResponse,
  TStatementsSummary,
} from 'models/currencyAccounts';
import instance from '../Axios/instance';

export default class CurrencyAccountsService {
  public static async getCurrencyTabs(): Promise<TCurrencyTab[]> {
    const response: AxiosResponse<TCurrencyTab[]> = await instance.get(
      '/ca/account-currencies',
    );
    return response.data;
  }

  public static async getCurrencyStats(
    currencyCode: string,
  ): Promise<TCurrencyResponse> {
    return this.getCurrencyStatsAsync(currencyCode);
  }

  public static async getCurrencyStatementsSummary(
    currenctAccountId: string,
    startDate: string,
    endDate: string,
  ): Promise<TStatementsSummary> {
    return this.getCurrencyStatementsSummaryAsync(
      currenctAccountId,
      startDate,
      endDate,
    );
  }

  public static async getMarginStatementsSummary(
    marginAccountId: string,
    startDate: string,
    endDate: string,
  ): Promise<TStatementsSummary> {
    return this.getMarginStatementsSummaryAsync(
      marginAccountId,
      startDate,
      endDate,
    );
  }

  public static async postReportGeneration(
    payload: TReportGenerationPayload,
  ): Promise<string> {
    return (await this.postReportGenerationAsync(payload)).executionArn;
  }

  public static async postAccountLetterReportGeneration(
    payload: TReportGenerationPayload,
  ): Promise<string> {
    return (await this.postAccountLetterReportGenerationAsync(payload)).executionArn;
  }

  private static async getCurrencyStatsAsync(
    currencyCode: string,
  ): Promise<TCurrencyResponse> {
    const response: AxiosResponse<TCurrencyResponse> = await instance.get(
      `/ca/currency-accounts?currencyCode=${currencyCode}`,
    );
    return response.data;
  }

  private static async getCurrencyStatementsSummaryAsync(
    currenctAccountId: string,
    startDate: string,
    endDate: string,
  ): Promise<TStatementsSummary> {
    const response: AxiosResponse<TStatementsSummary> = await instance.get(
      `/ca/currency-accounts/${currenctAccountId}/summary-currency?startdate=${startDate}&enddate=${endDate}`,
    );
    return response.data;
  }

  private static async getMarginStatementsSummaryAsync(
    marginAccountId: string,
    startDate: string,
    endDate: string,
  ): Promise<TStatementsSummary> {
    const response: AxiosResponse<TStatementsSummary> = await instance.get(
      `/ca/currency-accounts/${marginAccountId}/summary-margin?startdate=${startDate}&enddate=${endDate}`,
    );
    return response.data;
  }

  private static async postReportGenerationAsync(
    payload: TReportGenerationPayload,
  ): Promise<TReportGenerationResponse> {
    const {
      type, id, startDate, endDate,
    } = payload;
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/currency-accounts/${id}/ledgers?type=${type}&startdate=${startDate}&enddate=${endDate}`,
    );
    return response.data;
  }

  private static async postAccountLetterReportGenerationAsync(
    payload: TReportGenerationPayload,
  ): Promise<TReportGenerationResponse> {
    const {
      type, id,
    } = payload;
    const response: AxiosResponse<TReportGenerationResponse> = await instance.get(
      `/reports/currency-accounts/${id}/account-letter?type=${type}`,
    );
    return response.data;
  }

  // Retrieves all currencies available to hold currency accounts in
  public static async getAvailableCurrencies(): Promise<CurrencyDto[]> {
    const response: AxiosResponse<CurrencyDto[]> = await instance.get('/ca/available-currencies');
    return response.data;
  }

  public static async postCurrencyAccountRequest(
    accountRequest: CurrencyAccountRequest,
    skipDupeCheck: boolean,
  ):
  Promise<{ pending: boolean, success: boolean, message?: string }> {
    const queryString = skipDupeCheck ? '/ca/currency-accounts?force=true' : '/ca/currency-accounts';
    const response = await instance.post(
      queryString,
      accountRequest,
    );
    return response.data;
  }
}
