/* eslint-disable react/destructuring-assignment */
import React from 'react';
import moment from 'moment';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table/StyledGenericTable';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';

import useStyles from '../index.styles';

type Props = {
  values: BeneficiaryDto[];
};

const baseColumns = [{
  header: t('beneficiary_name'),
  accessor: 'beneficiaryName',
}, {
  header: t('friendly_name'),
  accessor: 'friendlyName',
},
{
  header: t('beneficiary_type'),
  accessor: 'party',
},
{
  header: t('country'),
  accessor: 'country',
},
{
  header: t('currency'),
  accessor: 'currencyCode',
},
{
  header: t('bank_details'),
  accessor: 'accountNumber',
},
{
  header: t('date_uploaded'),
  accessor: 'uploadedDate',
},
];

const mapBeneType = (type: string): string => {
  if (type === 'First') {
    return '1st Party';
  }
  if (type === 'Third') {
    return '3rd Party';
  }
  return '';
};

export function cutString(value: string | undefined, limit = 10): JSX.Element {
  if (!value) return <></>;
  if (value.length > limit) {
    return (
      <APTooltip title={value} placement="bottom-start">
        <div>{`${value.substr(0, limit)}...`}</div>
      </APTooltip>
    );
  }

  return <>{value}</>;
}
const ApprovalSummary: React.FC<Props> = ({ values }) => {
  const styles = useStyles();

  return (
    <div className={styles.tableWrapper}>
      <StyledGenericTable
        testId="approval-table"
        columns={baseColumns}
        data={values?.map((b: BeneficiaryDto) => ({
          beneficiaryName: b.name,
          friendlyName: b.friendlyName,
          party: mapBeneType(b.party),
          country: <div>
            <Flag code={b.bankCountryCode} size="md" />
            <span style={{ marginLeft: '10px' }}>
              {b.bankCountryCode}
            </span>
          </div>,
          currencyCode: <div>
            {' '}
            <Flag code={b.currencyCode} size="md" />
            <span style={{ marginLeft: '10px' }}>
              {b.currencyCode}
            </span>
                        </div>,
          accountNumber: cutString(b.accountNumber),
          uploadedDate: cutString(moment(b.uploadedDate!).format('DD/MM/YY').toString()),
        }))}
      />
    </div>
  );
};

export default ApprovalSummary;
