import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    rejectButton: {
      background: '#B9565633',
      '&:hover': {
        background: '#B9565666',
        color: '#B95656FF',
      },
    },
  }),
  { name: 'RejectButton' },
);

export default useStyles;
