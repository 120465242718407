import clsx from 'clsx';
import React from 'react';

import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';

import { PaymentPurposeDto } from '@alpha/payments-dtos';
import { useStyles } from './Item.styles';

interface IProps {
  item: PaymentPurposeDto;
}
const Data: React.FC<IProps> = (props: IProps) => {
  const { item } = props;
  const styles = useStyles();

  return (
    <Box className={clsx(styles.root, styles.item)}>
      <APTooltip title={`${item.description || '-'}`}>
        <Box className={styles.root}>
          <Typography variant="subtitle1" className={styles.description}>
            {item.description || '-'}
          </Typography>
        </Box>
      </APTooltip>
    </Box>
  );
};

interface IStickyProps {
  group: string;
}
const Sticky: React.FC<IStickyProps> = (props: IStickyProps) => {
  const { group } = props;
  const styles = useStyles();

  return (
    <Box className={clsx(styles.root, styles.sticky)}>
      <Typography variant="subtitle1">{group}</Typography>
    </Box>
  );
};

export const Item = {
  Data,
  Sticky,
};

export default Data;
