import { DashboardUserAccountPreferencesDto, DashboardWidget } from '@alpha/profile-dtos';

const useWidgetPermission = (
  viewablePages?: string[],
) => {
  const mapWidgetNameToPageName = (widget: DashboardWidget):string => {
    switch (widget) {
      case DashboardWidget.CURRENCY_ACCOUNT_BALANCES:
        return 'ACCOUNTS';
      case DashboardWidget.MTM:
      case DashboardWidget.FACILITIES:
        return 'HEDGING_SUMMARY';
      case DashboardWidget.HEDGING_PROGRAMS:
        return 'HEDGING_PROGRAMS';
      case DashboardWidget.LIVE_RATES:
        return 'MARKET_INFORMATION';
      case DashboardWidget.UTILISATION:
      case DashboardWidget.FACILITY_TERMS:
        return 'CREDIT_FACILITY';
      default:
        return '';
    }
  };

  function isWidgetEnabled(
    settings: DashboardUserAccountPreferencesDto | undefined,
    widgetName: string,
  ):boolean {
    if (!settings || !settings.widgets) return false;
    return settings.widgets.map((item) => (item.toString())).includes(widgetName);
  }

  function isViewableForUser(widget: DashboardWidget) {
    if (widget === DashboardWidget.CURRENCY_ACCOUNT_BALANCES
      || widget === DashboardWidget.OUTSTANDING_ACTIONS) {
      return true;
    }
    return viewablePages?.includes(mapWidgetNameToPageName(widget));
  }

  return {
    isWidgetEnabled,
    isViewableForUser,
  };
};

export default useWidgetPermission;
