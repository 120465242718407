import React from 'react';

import { DrawdownDto } from '@alpha/fx-dtos';

import HeaderButtons from './HeaderButtons/HeaderButtons';
import HeaderText from './HeaderText/HeaderText';
import useStyles from './styles';

interface IProps {
  selectedDrawdown: DrawdownDto | undefined;
}

const Header: React.FC<IProps> = ({
  selectedDrawdown,
}: IProps) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <HeaderText
        selectedDrawdown={selectedDrawdown}
      />
      <HeaderButtons drawdownId={selectedDrawdown?.id} />
    </div>
  );
};

export default Header;
