import React, { Dispatch, useState } from 'react';
import { FormikProps } from 'formik';

import { Input } from '@alpha/ui-lib/ui/Input';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import AutocompleteDropDown, { IOption } from '../../../../components/AutocompleteDropDown/AutocompleteDropDown';
import { TCountries } from '../../../../models/countries';
import { TIncomingUserDataFormType } from '../formData';
import t from 'utils/translationHelper';

import useStyles from './UserDetails.styles';

interface IProps {
  userRequestForm: FormikProps<TIncomingUserDataFormType>
  phoneCode: IOption;
  setPhoneCode: Dispatch<React.SetStateAction<IOption>>
  countries: TCountries
}

const UserDetails: React.FC<IProps> = (props: IProps) => {
  const {
    userRequestForm,
    phoneCode,
    setPhoneCode,
    countries,
  } = props;
  const [phoneCodeTextField, setPhoneCodeTextField] = useState<string>('UK');


  const classes = useStyles();
  return (
    <>
      <div className={classes.input}>
        <Typography variant="subtitle1">{t('first_name')}</Typography>
        <Input
          fullWidth
          variant="filled"
          name="firstName"
          id="firstName"
          testId="first-name-input"
          placeholder=""
          onChange={userRequestForm.handleChange}
          onBlur={userRequestForm.handleBlur}
          value={userRequestForm.values.firstName || ''}
          error={userRequestForm.touched.firstName
            && Boolean(userRequestForm.errors.firstName)}
          helperText={userRequestForm.touched.firstName
            && userRequestForm.errors.firstName && t(userRequestForm.errors.firstName)}
          type="text"
          className={classes.inputField}
        />
      </div>
      <div className={classes.input}>
        <Typography variant="subtitle1">{t('last_name')}</Typography>
        <Input
          fullWidth
          variant="filled"
          name="lastName"
          id="lastName"
          testId="last-name-input"
          placeholder=""
          onChange={userRequestForm.handleChange}
          onBlur={userRequestForm.handleBlur}
          value={userRequestForm.values.lastName || ''}
          error={userRequestForm.touched.lastName
            && Boolean(userRequestForm.errors.lastName)}
          helperText={userRequestForm.touched.lastName
            && userRequestForm.errors.lastName && t(userRequestForm.errors.lastName)}
          type="text"
          className={classes.inputField}
        />
      </div>
      <div className={classes.phoneInputContainer}>
        <Typography variant="subtitle1">{t('mobile')}</Typography>
        <div className={classes.phoneInput}>
          <AutocompleteDropDown
            name="phoneCountryCode"
            testId="phoneCountryCodeDropdown"
            options={countries}
            setFieldValue={(name, code, options) => {
              setPhoneCode(options);
            }}
            value={phoneCode || ''}
            currentOption={phoneCode}
            setCurrentOption={setPhoneCode}
            textFieldValue={phoneCodeTextField}
            setTextFieldValue={setPhoneCodeTextField}
            type="CODE-NAME"
            size="medium"
          />
          <Input
            fullWidth
            variant="filled"
            name="phoneNumberWithoutExt"
            id="phoneNumberWithoutExt"
            testId="phone-number-input"
            placeholder=""
            onChange={userRequestForm.handleChange}
            onBlur={userRequestForm.handleBlur}
            value={userRequestForm.values.phoneNumberWithoutExt || ''}
            error={userRequestForm.touched.phoneNumberWithoutExt
              && Boolean(userRequestForm.errors.phoneNumberWithoutExt)}
            helperText={userRequestForm.touched.phoneNumberWithoutExt
              && userRequestForm.errors.phoneNumberWithoutExt && t(userRequestForm.errors.phoneNumberWithoutExt)}
            type="text"
          />
        </div>
      </div>
      <div className={classes.input}>
        <Typography variant="subtitle1">{t('email')}</Typography>
        <Input
          fullWidth
          variant="filled"
          name="email"
          id="email"
          testId="email-input"
          placeholder=""
          onChange={userRequestForm.handleChange}
          onBlur={userRequestForm.handleBlur}
          value={userRequestForm.values.email || ''}
          error={userRequestForm.touched.email
            && Boolean(userRequestForm.errors.email)}
          helperText={userRequestForm.touched.email
            && userRequestForm.errors.email && t(userRequestForm.errors.email)}
          type="text"
          className={classes.inputField}
        />
      </div>

    </>
  );
};

export default UserDetails;
