import { useEffect, useState } from 'react';

import { MarketOrderDto } from '@alpha/fx-dtos';

import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useLog from '../../../hooks/useLog';
import FXTradeService from '../../../services/FXTrade/fxTrade.service';

const useMarketOrdersTable = (
) => {
  const [marketOrders, setMarketOrders] = useState<MarketOrderDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const populateMarketOrders = async () => {
    try {
      setLoading(true);
      setMarketOrders(await FXTradeService.getMarketOrderData());
    } catch (error) {
      sb.trigger('There was an error retrieving your Market Orders information');
      logError({ action: 'Error loading Market order information', error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    populateMarketOrders();
  }, []);

  return {
    marketOrders,
    loading,
  };
};

export default useMarketOrdersTable;
