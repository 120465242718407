/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import t from 'utils/translationHelper';

import { AccountConfigurationFXDto, ProgramPeriodDto } from '@alpha/fx-dtos';
import { Box } from '@alpha/ui-lib/ui/external';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { StyledStatSummary } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary';

import useSwitchAccount from '../../hooks/useSwitchAccount';
import { TProgramImage } from '../../services/HedgingPrograms/hedgingPrograms.interfaces';
import browserHistory from '../../services/history/browserHistory';
import { formatCurrency, formatNumber } from '../../utils/currency.helpers';

import EditForecastModal from './EditForecastModal/EditForecastModal';
import ExternalTrades from './ExternalTrades/ExternalTrades';
import HedgingProgramChart from './HedgingProgramChart/HedgingProgramChart';
import HedgingProgramsCtas from './HedgingProgramsCtas/HedgingProgramsCtas';
import HedgingProgramsSelect from './HedgingProgramsSelect/HedgingProgramsSelect';
import HedgingProgramSummary from './HedgingSummary/HedgingSummary';
import TradeBreakdownModal from './TradeBreakdownModal/TradeBreakdownModal';
import UploadTradeModal from './UploadTradeModal/UploadTradeModal';
import useStyles from './index.styles';
import useHedgingPrograms from './useHedgingPrograms';

const HedgingPrograms: React.FC = () => {
  const [programId, setProgramId] = useState<string | undefined>();
  const [selectedPeriod, setSelectedPeriod] = useState<ProgramPeriodDto>();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openTradesBreakdownModal, setOpenTradesBreakdownModal] = useState(false);
  const [openExternalTradeModal, setOpenExternalTradeModal] = useState<boolean>(false);
  const [programImages, setProgramImages] = useState<TProgramImage[]>([]);
  const [
    accountConfiguration,
    setAccountConfiguration,
  ] = useState<AccountConfigurationFXDto | undefined>();

  const {
    handleCloseTradeModal,
    checkExternalTradeDate,
    externalTradeForm,
    getProgramPeriodsData,
    program,
    periods,
    loading,
    submittingTrade,
    getExternalTradesData,
    getAccountConfiguration,
    externalTrades,
    handleReloadExternalTradesTable,
    checkUrlForProgramId,
    periodsToShowInChart,
  } = useHedgingPrograms(
    setOpenExternalTradeModal,
    programId,
    setProgramId,
    setAccountConfiguration,
  );

  const styles = useStyles();
  const { homePageUrl } = useSwitchAccount();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];

  useEffect(() => {
    checkUrlForProgramId();
    getAccountConfiguration();
  }, []);

  useEffect(() => {
    if (programId) {
      getProgramPeriodsData(programId);
      externalTradeForm.setFieldValue('programId', programId);
      setProgramImages([]);
      browserHistory.push(`?id=${programId}`);
    }
  }, [programId]);

  useEffect(() => {
    if (program) {
      getExternalTradesData(program);
      checkExternalTradeDate();
    }
  }, [program]);

  const programStartDate = program?.activeStartDate ?? program?.startDate;
  const programEndDate = program?.activeEndDate ?? program?.endDate;

  const statData = [{
    key: t('program_name'),
    value: program?.programName || '-',
  },
  {
    key: t('date_range'),
    value:
  <>
    {programStartDate ? moment(programStartDate).format('DD/MM/YYYY') : ''}
    {' '}
    -
    {' '}
    {programEndDate ? moment(programEndDate).format('DD/MM/YYYY') : ''}
  </>,
  },
  {
    key: t('total_forecast'),
    value: program?.currencyCode && program?.totalForecast ? `${formatCurrency(program.currencyCode, program?.totalForecast)}` : '-',
  },
  {
    key: t('total_percentage_hedged'),
    value: program?.totalPercentageHedged ? `${program?.totalPercentageHedged}%` : '-',
  },
  {
    key: t('total_weighted_avg_rate'),
    value: program?.weightedAverageRate ? formatNumber(program?.weightedAverageRate, 6) : '-',
  }];

  return (
    <APMainLayout
      title={t('hedging_programs')}
      height="167px"
      breadCrumbs={breadcrumbs}
      sticky
      pageTabs={(
        <HedgingProgramsSelect
          programId={programId}
          setProgramId={setProgramId}
        />
      )}
      rightAlignedCta={(
        <HedgingProgramsCtas
          componentTestId="programs-ctas"
          programId={programId}
          programName={program?.programName}
          addExternalTradesAllowed={accountConfiguration?.externalTradesAllowed || false}
          handleAddTrade={() => setOpenExternalTradeModal(true)}
          programImages={programImages}
        />
      )}
    >
      <Box className={styles.root}>
        <StyledStatSummary
          data={statData}
        />

        {program && periods.length > 0 && (
          <Box className={styles.summaryContainer}>
            <HedgingProgramChart
              program={program}
              periods={periodsToShowInChart}
              key={program?.id}
              loading={loading}
              handleEditForecast={(id: string) => {
                const matchedPeriod = periods.find((period) => period.id === id);
                setSelectedPeriod(matchedPeriod);
                setOpenEditModal(true);
              }}
              handleBarClick={(index, datasetIndex) => {
                if (periods && datasetIndex === 0) { // where 0 is the total bar
                  const clickedPeriod = periods[index];
                  setSelectedPeriod(clickedPeriod);
                  setOpenTradesBreakdownModal(true);
                }
              }}
              setProgramImages={setProgramImages}
            />
          </Box>
        )}

        <Box className={styles.tableContainer}>
          <HedgingProgramSummary
            program={program}
            periods={periods}
            handleRowClick={
              (id: string) => {
                const matchedPeriod = periods.find((period) => period.id === id);
                setSelectedPeriod(matchedPeriod);
                setOpenTradesBreakdownModal(true);
              }
            }
            handleEditForecastClick={(clickedPeriod) => {
              setSelectedPeriod(clickedPeriod);
              setOpenEditModal(true);
            }}
          />
        </Box>

        {program && externalTrades && (
          <Box className={styles.tableContainer}>
            <ExternalTrades
              program={program}
              trades={externalTrades}
              addExternalTradesAllowed={accountConfiguration?.externalTradesAllowed || false}
              handleReloadExternalTradesTable={handleReloadExternalTradesTable}
              handleAddTrade={() => setOpenExternalTradeModal(true)}
              handleReloadPeriodsData={() => {
                if (programId) getProgramPeriodsData(programId, false);
              }}
            />
          </Box>
        )}

      </Box>
      {program && (
        <UploadTradeModal
          program={program}
          open={openExternalTradeModal}
          handleClose={handleCloseTradeModal}
          externalTradeForm={externalTradeForm}
          submittingTrade={submittingTrade}
        />
      )}

      <div>
        <EditForecastModal
          open={openEditModal}
          handleClose={() => {
            if (programId) {
              setOpenEditModal(false);
              setSelectedPeriod(undefined);
              getProgramPeriodsData(programId, false);
            }
          }}
          program={program}
          period={selectedPeriod}
        />
        <TradeBreakdownModal
          program={program}
          period={selectedPeriod}
          open={openTradesBreakdownModal}
          handleClose={() => {
            setOpenTradesBreakdownModal(false);
          }}
        />
      </div>
    </APMainLayout>
  );
};
export default HedgingPrograms;
