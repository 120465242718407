import * as React from 'react';

import { IATDraftResponse } from '@alpha/iat-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import t from 'utils/translationHelper';

import useAuthorization from '../../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../../models/user';

interface IButtonsProps {
  iatDraftResponse: IATDraftResponse;
  handleOpenIATDrawer: (iatDraftResponse: IATDraftResponse) => void
  handleOpenIATRejectModal: (iatDraftResponse: IATDraftResponse) => void
}
const Buttons: React.FC<IButtonsProps> = ({
  iatDraftResponse, handleOpenIATDrawer, handleOpenIATRejectModal,
}: IButtonsProps) => {

  const canBookFx = useAuthorization([[UserRole.FX_IAT]]).authorized;

  if (iatDraftResponse.currentUserCanApprove) {
    return (
      <>
        <ActionButton
          id="rejectButton"
          style={{ background: '#F7F7F7', color: '#212529' }}
          className="rejectButton"
          onClick={
            () => handleOpenIATRejectModal(iatDraftResponse)
          }
        >
          {t('reject^')}
        </ActionButton>
        <ActionButton id="approveButton" onClick={() => handleOpenIATDrawer(iatDraftResponse)}>
          {t('approve^')}
        </ActionButton>
      </>
    );
  }

  if (iatDraftResponse.status === 'APPROVED') {
    if (iatDraftResponse.requiresFx && canBookFx) {
      return (
        <ActionButton id="requestLiveRatesButton" onClick={() => handleOpenIATDrawer(iatDraftResponse)}>
          {t('request_live_rates')}
        </ActionButton>
      );
    } if (!iatDraftResponse.requiresFx) {
      return (
        <ActionButton id="releasePaymentsButton" onClick={() => handleOpenIATDrawer(iatDraftResponse)}>
          {t('release_payments')}
        </ActionButton>
      );
    }
  }

  return (
    <ActionButton
      id="viewButton"
      style={{ background: '#F7F7F7', color: '#212529' }}
      onClick={() => handleOpenIATDrawer(iatDraftResponse)}
    >
      {t('view')}
    </ActionButton>
  );
};

export default Buttons;
