import React, { createRef, useState } from 'react';
import uploadFailIcon from 'assets/upload_fail_icon.png';
import uploadIcon from 'assets/upload_icon.png';
import clsx from 'clsx';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import { useDispatch } from 'react-redux';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import PaymentsService from 'services/Payments/payments.service';
import { actions as beneActions } from 'store/beneficiaries/beneficiaries.reducer';
import { actions as paymentActions } from 'store/payments/payments.reducer';
import t from 'utils/translationHelper';

import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { faFileCsv, faFileExcel } from '@fortawesome/pro-solid-svg-icons';

import DragAndDrop from './DragAndDrop/DragAndDrop';
import DownloadTemplate from './DownloadTemplate';
import useStyles from './UploadFile.styles';

export const availableExt = ['csv', 'xlsx'];

interface IProps {
  showGuide: boolean
  beneBatchUpload?: boolean
  batchName?: string
}

const UploadFile: React.FC<IProps> = (props: IProps) => {
  const {
    showGuide, beneBatchUpload, batchName,
  } = props;
  const styles = useStyles();
  const snackbar = useAlphaSnackbar();
  const inputRef = createRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(false);

  const uploadFileToS3 = async (file: File) => {
    try {
      // set batchStatus to 'REQUESTED' before BE does - to show loading screen
      if (beneBatchUpload) {
        dispatch(beneActions.updateBatchStatus('REQUESTED'));
        dispatch(beneActions.closeUploadModal({ uploadModal: true }));
      } else {
        dispatch(paymentActions.updateBatchStatus('REQUESTED'));
      }

      const fileArray = file.name.split('.');
      const fileExtension = fileArray[fileArray.length - 1];

      const { batchId, url } = !beneBatchUpload ? await PaymentsService.getPresignedUrl(
        {
          fileName: file.name,
          fileExtension: fileExtension as 'csv' | 'xlsx',
        },
      ) : await BeneficiariesService.getPresignedUrl(
        {
          fileName: batchName,
          fileExtension: fileExtension as 'csv' | 'xlsx',
        },
      );

      if (beneBatchUpload) {
        dispatch(beneActions.updateBatchId(batchId));
      } else {
        dispatch(paymentActions.updateBatchId(batchId));
      }

      if (beneBatchUpload) {
        await BeneficiariesService.putBeneficiaryFile(url, file);
      } else {
        await PaymentsService.putPaymentFile(url, file);
      }
      //  Start the polling now
      if (beneBatchUpload) {
        dispatch(beneActions.closeUploadModal({ uploadModal: false }));
      } else {
        dispatch(paymentActions.triggerBatchStatus());
      }
    } catch (error) {
      snackbar.trigger(
        `${t('there_was_an_error_uploading_your_file')} (${error.message})`,
      );
      // set batchStatus back to 'undefined' in case of failure
      // clears batchStatus state so that user can reupload a new batch on 'Create Payments' drawer
      if (beneBatchUpload) {
        dispatch(beneActions.updateBatchStatus(undefined));
      } else {
        dispatch(paymentActions.updateBatchStatus(undefined));
      }
    }
  };

  const sendFileHandler = async (file: File) => {
    try {
      const fileArray = file.name.split('.');
      // Validate
      if (availableExt.includes(fileArray[fileArray.length - 1])) {
        await uploadFileToS3(file);
      } else {
        snackbar.trigger(
          `${t('the_chosen_file_type_not_supported')} 
        ${availableExt.join(', ')}.`,
        );
      }
    } catch (e) {
      setErrors(true);
    }
  };

  const inputHandlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      sendFileHandler(e.target.files[0]);
    }
  };

  return (
    <div className={styles.root}>
      <Box
        className={clsx(
          styles.dragnDropBoxContainer,
          showGuide ? 'small' : 'large',
        )}
      >
        <DragAndDrop onDropHandler={sendFileHandler} beneFlow={beneBatchUpload}>
          <div className="middleContent">
            <img src={errors ? uploadFailIcon : uploadIcon} alt="upload-icon" />
            <Typography className={clsx(styles.dragnDropText, errors ? 'unHappyPath' : 'happyPath')}>{errors ? t('upload_failed') : t('drag_and_drop') }</Typography>
            <Typography
              onClick={() => { inputRef.current?.click(); }}
              className={styles.underlinedGreenText}
            >
              {errors ? t('click_to_try_again')
                : t('or_click_to_browse')}
            </Typography>
          </div>
        </DragAndDrop>
      </Box>
      <Box
        className={clsx(
          styles.guideBoxContainer,
          showGuide ? 'display' : 'hidden',
        )}
      >
        <Typography className={styles.headerText}>{t('file_upload_process')}</Typography>
        <Typography className={styles.subHeaderText}>
          <span className={styles.headerText}>1. </span>
          {t('download_template')}
        </Typography>
        <Typography className={styles.bodyText}>
          {t('download_template_and_input_payments')}
        </Typography>
        <Box className={styles.templatesContainer}>
          <DownloadTemplate icon={faFileExcel} fileType=".xlsx" filePath="/static/files/ALPHA_PAYMENTS_TEMPLATE.xlsx" />
          <DownloadTemplate icon={faFileCsv} fileType=".csv" filePath="/static/files/ALPHA_PAYMENTS_TEMPLATE.csv" />
        </Box>
        <Typography className={styles.bodyGreenText}>
          {t('already_have_the_file?_skip_this_step')}
        </Typography>
        <Typography className={styles.subHeaderText}>
          <span className={styles.headerText}>2. </span>
          {t('upload_file')}
        </Typography>
        <Typography className={styles.bodyText}>
          {t('then_simply_drag_and_drop_file_to_validate_payments')}
        </Typography>
      </Box>

      <input
        data-testid="input"
        ref={inputRef}
        type="file"
        name="file"
        accept=".csv, .xlsx"
        hidden
        onChange={inputHandlerChange}
      />
    </div>
  );
};

export default UploadFile;
