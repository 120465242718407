import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography, palette }) => ({
    root: {
      padding: '22px',
      backgroundColor: '#FFFFFF',
    },
    header: {
      fontWeight: typography.fontWeightSemiBold,
      marginBottom: '12px',
    },
    emptyTable: {
      height: '341px',
      position: 'relative',
      marginBottom: '41px',
    },
    amount: {
      fontWeight: 600,
    },
    rate: {
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    closeOutsTable: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0px 0px 12px 0px',
    },
  }), { name: 'CloseOuts' },
);

export default useStyles;
