import React from 'react';
import WelcomeLoader from 'components/Organisms/Onboarding/WelcomeLoader';
import routes from 'routes.path';
import browserHistory from 'services/history/browserHistory';

interface IProps {
  name: string,
}

const Welcome: React.FC<IProps> = ({ name }: IProps) => {
  setTimeout(() => { browserHistory.push(routes.base); }, 4000);
  return (
    <WelcomeLoader completedOnboarding name={name} />
  );
};

export default Welcome;
