import { ISnackbarOptions } from '@alpha/ui-lib/ui/Snackbar';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TNotification = ISnackbarOptions & { message: string };
export type TNotificationsStore = {
  data: TNotification[];
};

const initialState: TNotificationsStore = {
  data: [],
};

export const notificationsSlice = createSlice({
  initialState,
  name: 'notifications',
  reducers: {
    enqueueSnackbar: (state, action: PayloadAction<TNotification>): TNotificationsStore => ({
      ...state,
      data: [...state.data, action.payload],
    }),
    closeSnackbar: (state, action: PayloadAction<string>): TNotificationsStore => ({
      ...state,
      data: state.data.map((notification) => (
        notification.key === action.payload
          ? { ...notification, dismissed: true }
          : notification)),
    }),
    removeSnackbar: (state, action: PayloadAction<string | number>): TNotificationsStore => ({
      ...state,
      data: state.data.filter((notification) => notification.key !== action.payload),
    }),
  },
});

export const { actions } = notificationsSlice;
