import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AccountDto } from '@alpha/auth-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';

import { TStore } from '../../../../store';
import { TAuthyState } from '../../../../store/authy/reducer';

import Body from './Body/index';
import Header from './Header/index';
import useShareBeneficiary from './useShareBeneficiary';

export interface IBeneShareDrawer {
  accountBeneId: string;
  availableAccounts: AccountDto[];
  handleBeneficiaryShareButtonClick?: () => void;
}

export const BeneShareDrawer: React.FC<IBeneShareDrawer> = (
  {
    accountBeneId,
    availableAccounts,
    handleBeneficiaryShareButtonClick,
  }: IBeneShareDrawer,
) => {
  const shareBeneficiary = useShareBeneficiary(accountBeneId, availableAccounts);

  const authy = useSelector<TStore, TAuthyState>((store) => store.authy);

  useEffect(() => {
    if (authy.status === 'SUCCESS' && authy.type?.type === 'BENEFICIARY_BATCH') {
      if (handleBeneficiaryShareButtonClick) {
        handleBeneficiaryShareButtonClick();
      }
    }
  }, [authy.status]);

  return (
    <BaseDrawer.Drawer
      data-testid="beneficiary-share-drawer"
      open={Boolean(accountBeneId)}
      onClose={handleBeneficiaryShareButtonClick}
      anchor="right"
    >
      <Header
        handleShareKeyUp={shareBeneficiary.handleShareKeyUp}
        totalAcccounts={shareBeneficiary.availableAccounts.length}
      />
      <Body
        {...shareBeneficiary}
        availableAccounts={shareBeneficiary.availableAccounts}
        searchTerm={shareBeneficiary.searchTerm.current}
      />
    </BaseDrawer.Drawer>
  );
};
