import React from 'react';
import clsx from 'clsx';

import { Box } from '@alpha/ui-lib/ui/external';
import { ILoaderProps, Loader } from '@alpha/ui-lib/ui/Loader';

import useStyles from './BackdropLoader.styles';

interface IProps extends ILoaderProps {
  positionFixed?: boolean;
  style?: React.CSSProperties;
}

const BackdropLoader: React.FC<IProps> = ({ ...props }: IProps) => {
  const styling = useStyles();

  return (
    <Box
      className={clsx([styling.root, props.className])}
      style={
        props.positionFixed
          ? { ...props.style, position: 'fixed' }
          : { ...props.style }
      }
    >
      <Loader {...props} />
    </Box>
  );
};

export default BackdropLoader;
