import { useEffect, useState } from 'react';

import { TradeDto } from '@alpha/fx-dtos';
import { PaymentBatchDto, PaymentDto } from '@alpha/payments-dtos';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import { TSearchParams } from '../../../../../hooks/useSearch';
import PaymentsService from '../../../../../services/Payments/payments.service';
import SearchService from '../../../../../services/Search/SearchService';

const usePaymentBatchDetail = (
  batchId: string,
) => {
  const [payments, setPayments] = useState<PaymentDto[]>([]);
  const [paymentBatch, setPaymentBatch] = useState<PaymentBatchDto>();
  const [trades, setTrades] = useState<TradeDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentsLoading, setPaymentsLoading] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const loadBatchData = async (paymentBatchId: string) => {
    try {
      setLoading(true);
      const batchData = await PaymentsService.getBatch(paymentBatchId);
      setPaymentBatch(batchData);
    } catch (error) {
      sb.trigger(error?.message || 'Failed to load payment batch.');
      logError({ action: 'Error loading payment batch', error });
    } finally {
      setLoading(false);
    }
  };

  const loadPaymentsData = async (paymentBatchId: string) => {
    const searchParams: TSearchParams = {
      baseUrl: '/payments/search',
      queryParams: {
        skip: 0,
        take: 1000,
        sortby: 'contractNumber',
        sortorder: 'asc',
        batchid: paymentBatchId,
      },
    };

    try {
      setPaymentsLoading(true);
      const result: {
        total: number,
        records: PaymentDto[]
      } = await SearchService.GetTableData(searchParams);
      setPayments(result.records);
    } catch (error) {
      sb.trigger(error?.message || 'Failed to load payments.');
      logError({ action: 'Error loading payments', error });
    } finally {
      setPaymentsLoading(false);
    }
  };

  const loadTradesData = async (tradeIds: string[]) => {
    const searchParams: TSearchParams = {
      baseUrl: 'fx/trades/search',
      queryParams: {
        skip: 0,
        take: 1000,
        sortby: 'contractNumber',
        sortorder: 'asc',
        tradeids: tradeIds.join(','),
      },
    };

    try {
      setLoading(true);
      const result: {
        total: number,
        records: TradeDto[]
      } = await SearchService.GetTableData(searchParams);
      setTrades(result.records);
    } catch (error) {
      sb.trigger(error?.message || 'Failed to load trades.');
      logError({ action: 'Error loading trades', error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBatchData(batchId);
    loadPaymentsData(batchId);
  }, [batchId]);

  useEffect(() => {
    const tradeIds: string[] = [];
    payments.forEach((p) => {
      if (p.tradeId) tradeIds.push(p.tradeId);
    });
    const uniqueTradeIds = Array.from(new Set<string>(tradeIds));

    if (tradeIds.length) loadTradesData(uniqueTradeIds);
  }, [payments]);

  return {
    paymentBatch,
    payments,
    trades,
    loading,
    paymentsLoading,
    loadPaymentsData,
  };
};

export default usePaymentBatchDetail;
