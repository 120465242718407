import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquarePhoneFlip } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import APModal from '../../../Modals/APModal/APModal';

import useStyles from './index.styles';

type Props = {
  open: boolean;
  handleClose?: () => void;
}

const RequestDrawdownModal: React.FC<Props> = ({
  open, handleClose,
}: Props) => {

  const styles = useStyles();
  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div className={styles.modalBox}>
          <FontAwesomeIcon icon={faSquarePhoneFlip as IconProp} color="#4CAF50" size="2x" style={{ paddingTop: '35px' }} />
          <div>
            <Typography className={styles.modalHeading}>
              {t('please_call_us_to_request_drawdown')}
            </Typography>
            <Typography className={styles.modalSubtitle}>
              {t('contact_us_on')}
              <strong> {t('phone_number')} </strong>
              {t('to_request_a_drawdown_for_this_trade')}
            </Typography>
          </div>
          <div style={{ width: '100%', paddingTop: '43px' }}>
            <ActionButton size="large" style={{ width: '237px', maxHeight: '41px', borderRadius: '5px' }} onClick={handleClose}>{t('acknowledge')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default RequestDrawdownModal;
