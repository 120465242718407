import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IATService from 'services/IAT/IAT.service';
import t from 'utils/translationHelper';

import { TradeVerificationDto } from '@alpha/fx-dtos';
import { VerificationMethod } from '@alpha/payments-dtos';
import { MfaMethod } from '@alpha/profile-dtos';

import { updatePollingIdToLocalStorage } from '../components/AuthyComponent/PushNotification';
import AuthyService from '../services/Authy/Authy.service';
import FXTradeService from '../services/FXTrade/fxTrade.service';
import { TStore } from '../store';
import * as authyActions from '../store/authy/actions';
import {
  actions, AuthyPageStates, TAuthyDrawdownType,
  TAuthyPaymentsType, TAuthyState, TAuthyTradeType, TAuthyTransferType,
} from '../store/authy/reducer';

import useAlphaSnackbar from './useAlphaSnackbar';
import useLog from './useLog';
import useProfile from './useProfile';

// eslint-disable-next-line max-lines-per-function
const useAuthy = () => {
  const snackbar = useAlphaSnackbar();

  const reduxState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const { logError } = useLog();

  const dispatch = useDispatch();

  const { userProfile } = useProfile();

  const getPreferredMfaMethod = (method?: VerificationMethod): VerificationMethod | undefined => {
    const defaultMethod = userProfile?.mfaMethod;
    if (defaultMethod === MfaMethod.METHOD_AUTHY && method !== VerificationMethod.SMS) {
      return VerificationMethod.PUSH_NOTIFICATION;
    }
    if (defaultMethod === MfaMethod.METHOD_SMS
      && method !== VerificationMethod.PUSH_NOTIFICATION) {
      return VerificationMethod.SMS;
    }
    return method;
  };

  const getPreferredOption = (option?: string, method?: VerificationMethod): string | undefined => {
    const defaultMethod = userProfile?.mfaMethod;
    if ((option === 'sms' || defaultMethod === MfaMethod.METHOD_SMS) && !method) {
      return 'sms';
    }
    if (method === VerificationMethod.PUSH_NOTIFICATION) {
      return '';
    }
    if (method === VerificationMethod.SMS) {
      return 'sms';
    }
    return option;
  };

  const handlePaymentMfa = async (
    paymentIds: string[],
    verificationMethod?: VerificationMethod,
    firstPartyFlow?: boolean,
  ) => {
    const {
      message,
      approvalRequestId,
      method,
      dynamicLinkingId,
    } = await AuthyService.getPaymentsPhoneNumber(
      paymentIds, verificationMethod, firstPartyFlow,
    );

    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateApprovalRequestId({ approvalRequestId, dynamicLinkingId }));
    dispatch(actions.updateAuthyPageState(
      method === VerificationMethod.PUSH_NOTIFICATION ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  const handleApprovePaymentMfa = async (
    paymentIds: string[],
    verificationMethod?: VerificationMethod,
  ) => {
    const {
      message,
      approvalRequestId,
      dynamicLinkingId,
      method,
    } = await AuthyService.getPaymentsApprovalPhoneNumber(
      paymentIds, verificationMethod,
    );
    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateApprovalRequestId({ approvalRequestId, dynamicLinkingId }));
    dispatch(actions.updateAuthyPageState(
      method === VerificationMethod.PUSH_NOTIFICATION ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  const handleBeneficiaryMfa = async (mfaOptionChosen?: string) => {
    const { message, method } = await AuthyService.requestPhoneNumber(mfaOptionChosen);
    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateAuthyPageState(
      method === 'PUSH' ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  const handleDrawdownMfa = async (mfaOptionChosen?: string) => {
    const { message, method } = await AuthyService.requestPhoneNumber(mfaOptionChosen);

    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateAuthyPageState(
      method === 'PUSH' ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  const handleDrawdownMfaWithPayments = async (
    paymentIds: string[],
    verificationMethod?: VerificationMethod,
    firstPartyFlow?: boolean,
  ) => {
    const {
      message,
      approvalRequestId,
      method,
      dynamicLinkingId,
    } = await AuthyService.getPaymentsPhoneNumber(
      paymentIds, verificationMethod, firstPartyFlow,
    );

    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateApprovalRequestId({ approvalRequestId, dynamicLinkingId }));
    dispatch(actions.updateAuthyPageState(
      method === VerificationMethod.PUSH_NOTIFICATION ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  const handleTradeMfa = async (tradeId: string, mfaOptionChosen?: VerificationMethod) => {
    const verificationResponse: TradeVerificationDto = await FXTradeService.getTradeVerification(
      tradeId, mfaOptionChosen,
    );

    dispatch(actions.updatePhoneNumber(verificationResponse.message));
    dispatch(actions.updateAuthyPageState(
      verificationResponse.method
        === VerificationMethod.PUSH_NOTIFICATION ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
    dispatch(actions.updateApprovalRequestId({
      approvalRequestId: verificationResponse.approvalRequestId || '',
      dynamicLinkingId: verificationResponse.dynamicLinkingId || '',
    }));
  };

  const handleApproveTradePADMfa = async (
    id: string,
    verificationMethod?: VerificationMethod,
  ) => {
    const {
      message,
      approvalRequestId,
      dynamicLinkingId,
      method,
    } = await FXTradeService.getTradePADVerification(
      {
        tradeId: id,
        method: verificationMethod,
      },
    );

    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateApprovalRequestId({ approvalRequestId, dynamicLinkingId }));
    dispatch(actions.updateAuthyPageState(
      method === VerificationMethod.PUSH_NOTIFICATION ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  const handleApproveDrawdownPADMfa = async (
    id: string,
    verificationMethod?: VerificationMethod,
  ) => {
    const {
      message,
      approvalRequestId,
      dynamicLinkingId,
      method,
    } = await FXTradeService.getDrawdownPADVerification(
      {
        drawdownId: id,
        method: verificationMethod,
      },
    );

    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateApprovalRequestId({ approvalRequestId, dynamicLinkingId }));
    dispatch(actions.updateAuthyPageState(
      method === VerificationMethod.PUSH_NOTIFICATION ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  const handleApproveTransferMfa = async (
    id: string,
    verificationMethod?: VerificationMethod,
  ) => {
    const {
      message,
      approvalRequestId,
      dynamicLinkingId,
      method,
    } = await IATService.getTransferVerification(
      {
        transferId: id,
        method: verificationMethod,
      },
    );

    dispatch(actions.updatePhoneNumber(message));
    dispatch(actions.updateApprovalRequestId({ approvalRequestId, dynamicLinkingId }));
    dispatch(actions.updateAuthyPageState(
      method === VerificationMethod.PUSH_NOTIFICATION ? AuthyPageStates.AUTHY : AuthyPageStates.SMS,
    ));
  };

  useEffect(() => {
    updatePollingIdToLocalStorage(reduxState?.approvalRequestId || '');
  }, [reduxState]);

  const triggerMfa = async (
    mfaOptionChosen = '',
    verificationMethod?: VerificationMethod,
  ): Promise<void> => {
    const { type } = reduxState;
    const preferredMethod = getPreferredMfaMethod(verificationMethod);
    const preferredOptionMfa = getPreferredOption(mfaOptionChosen, verificationMethod);
    try {
      if (type?.type === 'PAYMENTS' && (type as TAuthyPaymentsType).paymentIds) {
        const { firstPartyFlow } = type as TAuthyPaymentsType;
        await handlePaymentMfa((type as TAuthyPaymentsType).paymentIds, preferredMethod, firstPartyFlow);
      } else if (type?.type === 'PAYMENT_APPROVE' && (type as TAuthyPaymentsType).paymentIds) {
        await handleApprovePaymentMfa((type as TAuthyPaymentsType).paymentIds, preferredMethod);
      } else if (type?.type === 'BENEFICIARY' || type?.type === 'BENEFICIARY_BATCH' || type?.type === 'BENEFICIARY_BATCH_UPLOAD') {
        await handleBeneficiaryMfa(preferredOptionMfa);
      } else if (type?.type === 'DRAWDOWN') {
        const { paymentIds } = type as TAuthyDrawdownType;
        const { firstPartyDrawdown } = type as TAuthyDrawdownType;
        if (paymentIds.length > 0) {
          await handleDrawdownMfaWithPayments(paymentIds, preferredMethod, firstPartyDrawdown);
        } else {
          await handleDrawdownMfa(preferredOptionMfa);
        }
      } else if (type?.type === 'TRADE') {
        const authType = type as TAuthyTradeType;
        await handleTradeMfa(authType.id, preferredMethod);
      } else if (type?.type === 'TRADE_PAD_APPROVE') {
        const authType = type as TAuthyTradeType;
        await handleApproveTradePADMfa(authType.id, preferredMethod);
      } else if (type?.type === 'DRAWDOWN_PAD_APPROVE') {
        const authType = type as TAuthyDrawdownType;
        await handleApproveDrawdownPADMfa(authType.drawdownId, preferredMethod);
      } else if (type?.type === 'TRANSFER_APPROVE') {
        const authType = type as TAuthyTransferType;
        await handleApproveTransferMfa(authType.transferId, preferredMethod);
      } else {
        throw new Error('Authy Type not supported');
      }
    } catch (e) {
      logError({ action: 'Error on trigger mfa', e });
      snackbar.trigger(t(e.response?.data?.message) || t('error_default'));
      clearAuthy();
    }
  };

  const clearAuthy = (): void => {
    dispatch(actions.reset());
    updatePollingIdToLocalStorage('');
  };

  const triggerSubmit = (code: number,
    approvalRequestId?: string,
    dynamicLinkingId?: string): void => {
    dispatch(authyActions.submit({ totp: code, approvalRequestId, dynamicLinkingId }));
  };

  return {
    reduxState,
    phoneNumber: reduxState.phoneNumber,
    status: reduxState.status,
    clearAuthy,
    dispatch,
    triggerMfa,
    triggerSubmit,
  };
};

export default useAuthy;
