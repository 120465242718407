import { User } from '@alpha/profile-dtos';

import instance from '../Axios/instance';

export class ProfileService {
  public static async getUserProfile(accountId?: string): Promise<User> {
    const config = accountId ? {
      headers: {
        'account-id': accountId,
      },
    } : {};
    const response = await instance.get('/profiles/user-profile', config);
    return response.data;
  }
}

export default ProfileService;
