import { PaymentPurposeDto } from '@alpha/payments-dtos';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { Box } from '@alpha/ui-lib/ui/external';
import React from 'react';
import { useStyles } from './RenderValue.styles';

interface IProps {
  selectedPaymentPurpose: PaymentPurposeDto,
}
const RenderValue: React.FC<IProps> = ({ selectedPaymentPurpose }: IProps) => {
  const styles = useStyles();
  const { description } = selectedPaymentPurpose;

  return (
    <Box className={styles.root}>
      <Typography variant="subtitle1" className={styles.description}>{description}</Typography>
    </Box>
  );
};

export default RenderValue;
