import React from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import t from 'utils/translationHelper';

import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import APPagination from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination';
import { IAPPagination } from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination.interfaces';

import BackdropLoader from '../../Molecules/Loaders/BackdropLoader/BackdropLoader';
import EmptyTable, { IEmptyTable } from '../../Table/EmptyTable';
import Table, { TTableProps } from '../../Table/Table';

interface IProps {
  table: TTableProps;
  pagination: IAPPagination;
  loading: boolean;
  emptyTable?: IEmptyTable;
  showSelectAllCheckbox?: boolean;
  hideSelect?: boolean;
}

const SearchTable: React.FC<IProps> = ({
  table, pagination, loading, emptyTable,
}: IProps) => {
  if (table?.data?.length) {
    return (
      <>
        <Table
          {...table}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {table?.totalRowNumber && (

            <Box display="block" textAlign="left">
              {!loading ? (
                <>
                  {t('showing')}
                  {' '}
                  {table?.skip + 1}
                  {' - '}
                  {table?.skip + table?.data?.length}
                  {' '}
                  {t('of')}
                  {' '}
                  {table?.totalRowNumber}
                </>
              ) : `${t('loading')}...`}
            </Box>

          )}
          <Box display="block" textAlign="right">
            <APPagination
              {...pagination}
              prevString={t('prev^')}
              nextString={t('next^')}
            />
          </Box>
        </div>
        {loading && <BackdropLoader testId="backdrop-loader" />}
      </>
    );
  }

  if (!table?.data?.length && loading) {
    return <Loader testId="backdrop-loader" />;
  }

  return (
    <EmptyTable
      {...emptyTable}
      icon={clipboardCross}
    />
  );
};

export default SearchTable;
