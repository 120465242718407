import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import Warning from '../../../../../assets/warning.svg';
import APModal from '../../../../../components/Modals/APModal/APModal';
import routes from '../../../../../routes.path';
import browserHistory from '../../../../../services/history/browserHistory';
import t from 'utils/translationHelper';

import useStyles from './CancelBatchModal.styles';

type Props = {
  open: boolean;
  handleClose?: () => void;
}

const CancelBatchModal: React.FC<Props> = ({
  open, handleClose,
}: Props) => {
  const styles = useStyles();


  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div className={styles.innerContainer}>
          <img src={Warning} className={styles.warningStyle} alt="Warning" />
          <div className={styles.textContainer}>
            <Typography className={styles.headerText}>
              {t('are_you_sure_you_want_to_cancel_this_payment_upload')}
            </Typography>
            <Typography>{t('this_action_cannot_be_reversed')}</Typography>
          </div>
          <div className={styles.buttonContainer}>
            <ActionButton
              style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }}
              size="medium"
              variant="outlined"
              onClick={handleClose}
            >
              {t('exit')}
            </ActionButton>
            <ActionButton size="medium" className={styles.cancelBatchButton} onClick={() => browserHistory.push(routes.transactions.paymentUpload)}>{t('cancel_batch')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default CancelBatchModal;
