import React, { ReactNode } from 'react';

import { SingleStat } from '@alpha/ui-lib/ui/StatSummary/SingleStat';

import useWidgetStyles from '../index.styles';

import useStyles from './CreditSummary.styles';

export type TStatItem = {
  header: string | ReactNode;
  value: string | ReactNode;
  helpMessage?: string | ReactNode;
};

interface IProps {
  summaries: TStatItem[];
}

const CreditSummary: React.FC<IProps> = ({ summaries }) => {
  const classes = useStyles();
  const widgetClasses = useWidgetStyles();

  return (
    <div
      className={widgetClasses.termHeader}
      style={{
        gridColumn: 'span 3',
        gridAutoColumns: 'auto',
      }}
    >
      <div className={classes.statsContainer}>
        {summaries?.map((item: TStatItem) => (
          <SingleStat
            statHeader={<div style={{ minWidth: '120px' }}>{item.header}</div>}
            value={item.value}
            helpMessage={item.helpMessage}
          />
        ))}
      </div>
    </div>
  );
};

export default CreditSummary;
