import React from 'react';

import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledKeyValueTable, TKeyValue } from '@alpha/ui-lib/ui/table';
import t from 'utils/translationHelper';

import useStyles from './PaymentDetails.styles';

interface IProps {
  beneficiaryDetails: TKeyValue[];
  referenceDetails: TKeyValue[];
  valueDateDetails: TKeyValue[];
  amountDetails: TKeyValue[];
}

const PaymentDetails: React.FC<IProps> = (props: IProps) => {

  const classes = useStyles();
  const {
    beneficiaryDetails,
    referenceDetails,
    valueDateDetails,
    amountDetails,
  } = props;

  return (
    <>
      <Box className={classes.paymentStatusWrapper}>
        <Typography className={classes.text}>{t('payment_status')}</Typography>
        <Status variant="success">{t('complete')}</Status>
      </Box>
      <Box className={classes.tableDetails}>
        <StyledKeyValueTable
          testId="statements-drawer-beneficiary"
          data={beneficiaryDetails}
        />
      </Box>
      <Box className={classes.tableDetails}>
        <StyledKeyValueTable
          testId="statements-drawer-reference"
          data={referenceDetails}
        />
      </Box>
      <Box className={classes.tableDetails}>
        <StyledKeyValueTable
          testId="statements-drawer-date"
          data={valueDateDetails}
        />
      </Box>
      <Box className={classes.amountDetails}>
        <StyledKeyValueTable
          testId="statements-drawer-amount"
          data={amountDetails}
        />
      </Box>
    </>
  );
};

export default PaymentDetails;
