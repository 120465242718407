import React from 'react';
import Alert from 'components/Alert';
import { formatCurrency } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import { CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';
import { Typography } from '@alpha/ui-lib/ui/external';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';

import useStyles from './index.styles';

interface ITransferConfirmDetailsProps {
  bookedTransfer: CurrencyAccountTransferDto
}

const TransferConfirmDetails: React.FC<ITransferConfirmDetailsProps> = ({ bookedTransfer }) => {

  const styles = useStyles();
  return (
    <div className={styles.linkedPayments}>
      <div className={styles.transferDivider}>
        <StyledDivider />
      </div>
      <div className={styles.transfersTitle}>
        <strong>{t('inter_account_transfer_details')}</strong>
      </div>
      <div className={styles.tradeContainer}>
        <div className={styles.detailContainer}>
          <Typography className={styles.textStyle}>{t('credit_account_name')}</Typography>
          <Typography className={styles.textStyle}>
            <strong>{`${bookedTransfer?.debitingCurrencyAccountName}` || '-'}</strong>
            {` - ${bookedTransfer?.debitingCurrencyAccountName || '-'}`}
          </Typography>
        </div>
        <div className={styles.detailContainer}>
          <Typography className={styles.textStyle}>{t('amount')}</Typography>
          <Typography className={styles.textStyle}>
            <strong>{formatCurrency(bookedTransfer?.instructedCurrency, bookedTransfer?.instructedAmount) || '-'}</strong>
          </Typography>
        </div>
        <div className={styles.detailContainer}>
          <Typography className={styles.textStyle}>{t('reference')}</Typography>
          <Typography className={styles.textStyle}>
            <strong>{bookedTransfer?.reference}</strong>
          </Typography>
        </div>
      </div>
      <div className={styles.remitContainer}>
        <Alert
          variant="info"
          text={t('trade_is_dependant_on_the_transfer_please_ensure_authorised_signatory')}
        />
      </div>
    </div>
  );
};

export default TransferConfirmDetails;
