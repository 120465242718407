import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';

import { Box, Collapse, Typography } from '@alpha/ui-lib/ui/external';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';

import hasCorrectChars from '../../../../utils/hasCorrectChars';
import hasLowerCase from '../../../../utils/hasLowerCase';
import hasNumber from '../../../../utils/hasNumber';
import hasSpecialChars from '../../../../utils/hasSpecialChars';
import hasUpperCase from '../../../../utils/hasUpperCase';
import passwordsMatch from '../../../../utils/passwordsMatch';
import useStyles from '../PersonalSettings.styles';
import t from 'utils/translationHelper';

import ChangePasswordForm from './ChangePasswordForm';

interface IChangePasswordForm {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface IProps {
  formik: FormikProps<IChangePasswordForm>;
  loading: boolean;
  expandPasswordWidget: boolean;
  setExpandPasswordWidget: (value: boolean) => void;
}

const ChangePassword: React.FC<IProps> = ({
  formik,
  loading,
  expandPasswordWidget,
  setExpandPasswordWidget,
}) => {

  const classes = useStyles();
  const [currentPasswordFocused, setCurrentPasswordFocused] = useState<boolean>(false);
  const [newPasswordFocused, setNewPasswordFocused] = useState<boolean>(false);
  const [confirmNewPasswordFocused, setConfirmNewPasswordFocused] = useState<boolean>(false);

  useEffect(() => setExpandPasswordWidget(currentPasswordFocused
    || newPasswordFocused
    || confirmNewPasswordFocused),
    [currentPasswordFocused, newPasswordFocused, confirmNewPasswordFocused]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ChangePasswordForm
          formik={formik}
          loading={loading}
          setCurrentPasswordFocused={setCurrentPasswordFocused}
          setNewPasswordFocused={setNewPasswordFocused}
          setConfirmNewPasswordFocused={setConfirmNewPasswordFocused}
        />
        <Collapse in={expandPasswordWidget}>
          <Box className={classes.expandWidgetBox}>
            <StyledDivider vertical />
            <Box className={classes.passwordCriteriaContainer}>
              <Typography className={classes.passwordHeaderText} style={{ marginBottom: '20px' }}>
                {t('password_criteria')}
              </Typography>
              <ul className={classes.list}>
                <li className={clsx(classes.bulletListItem,
                  {
                    contains: hasLowerCase(formik.values.newPassword),
                  })}
                >
                  {t('one_lowercase_character')}
                </li>
                <li className={clsx(classes.bulletListItem,
                  {
                    contains: hasUpperCase(formik.values.newPassword),
                  })}
                >
                  {t('one_uppercase_character')}
                </li>
                <li className={clsx(classes.bulletListItem,
                  {
                    contains: hasNumber(formik.values.newPassword),
                  })}
                >
                  {t('one_number')}
                </li>
                <li className={clsx(classes.bulletListItem,
                  {
                    contains: hasCorrectChars(formik.values.newPassword, 8),
                  })}
                >
                  {t('8_characters_minimum')}
                </li>
                <li className={clsx(classes.bulletListItem,
                  {
                    contains: hasSpecialChars(formik.values.newPassword),
                  })}
                >
                  {t('one_special_character')}
                </li>
                <li className={clsx(classes.bulletListItem,
                  {
                    contains: passwordsMatch(
                      formik.values.newPassword,
                      formik.values.confirmNewPassword,
                    ),
                  })}
                >
                  {t('passwords_match')}
                </li>
              </ul>
            </Box>
          </Box>
        </Collapse>
      </div>
    </form>
  );
};

export default ChangePassword;
