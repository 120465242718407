import { useContext, useState } from 'react';
import t from 'utils/translationHelper';
import environmentVars from 'env.variables';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { MfaMethod } from '@alpha/profile-dtos';
import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';

import { AuthContext } from '../context/AuthContext';
import { useOnboardingContext } from '../domain/Auth/Onboarding';
import { ChallengeName, TSignIn } from '../models/auth';
import { TUser } from '../models/user';
import routes from '../routes.path';
import AuthService from '../services/Auth/auth.service';
import history from '../services/history/browserHistory';
import { TStore } from '../store';
import { actions as creditActions } from '../store/creditFacility/reducer';
import { actions } from '../store/user/user.reducer';

const useAuth = () => {
  const [userObject, setUserObject] = useState<TSignIn | undefined>();
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const { handleSetUserObject } = useContext(AuthContext);
  const onboarding = useOnboardingContext();

  const userInfo: TUser | undefined = useSelector<TStore, TUser | undefined>(
    (state: TStore) => state.user.user,
  );
  const [preferredMethod, setPreferredMethod] = useState<MfaMethod>();
  const dispatch = useDispatch();
  const { closeSnackbar } = originalUseSnackbar() || {};

  const checkAuth = async (): Promise<void> => {
    try {
      const response = await AuthService.currentUserInfo();
      if (response) {
        if (!isEqual(userInfo, response)) {
          dispatch(actions.updateUserDetails(response));
        }
        setLoggedIn(true);
      } else {
        throw Error('Not logged in');
      }
    } catch (e) {
      dispatch(actions.clearUserDetails());
      dispatch(creditActions.clearCreditFacility());
      setLoggedIn(false);
    }
  };

  const handleSignOut = async () => {
    await AuthService.signOut();
    closeSnackbar();
    dispatch(actions.clearUserDetails());
    dispatch(creditActions.clearCreditFacility());
  };

  const challengeRedirect = async (
    signInResponse: TSignIn,
    callback?: Function,
    challengeMethod?: MfaMethod,
  ): Promise<void> => {
    let hasErrored: boolean | null = null;
    const challengeStep = signInResponse?.challengeParam?.CHALLENGE_STEP;
    switch (signInResponse.challengeName) {
      case ChallengeName.NEW_PASSWORD_REQUIRED:
        handleSetUserObject(signInResponse);
        history.push(routes.auth.onboarding.newPassword);
        return;
      case ChallengeName.MFA_SETUP:
        history.push(routes.auth.onboarding.setupMfa);
        return;
      case ChallengeName.CUSTOM_CHALLENGE: {
        if (challengeStep === 'METHOD_REQUEST') {
          const preferredChallengeMethod = signInResponse?.challengeParam?.PREFERRED_METHOD
            ?? (challengeMethod ?? MfaMethod.METHOD_AUTHY);

          dispatch(actions.updatePreferredMfaMethod(preferredChallengeMethod));
          setPreferredMethod(preferredChallengeMethod);

          handleSetUserObject(signInResponse);
          if (onboarding.loaderPercentage) {
            sessionStorage.setItem(`onboarded-${environmentVars.ENVIRONMENT}`, 'true');
            history.push(routes.auth.onboarding.setupMfa, {
              selectDefault: true,
            });
            return;
          }

          const res = await AuthService.sendCustomChallengeAnswer(
            signInResponse, preferredChallengeMethod,
          );
          await challengeRedirect(res as TSignIn, callback, preferredChallengeMethod);
          return;
        }

        hasErrored = signInResponse.challengeParam.CODE_DELIVERY_ERROR !== undefined;

        let successfulMessage = `${t('verification_code_sent_to')} ${signInResponse.challengeParam.CODE_DELIVERY_DESTINATION}`;

        if (challengeStep === 'CODE_REQUEST') {
          if (challengeMethod && preferredMethod !== challengeMethod) {
            dispatch(actions.updatePreferredMfaMethod(challengeMethod));
          }
        }

        switch (challengeMethod) {
          case MfaMethod.METHOD_AUTHY:
            successfulMessage = `${t('please_check_authy_for_verification_code')} (${signInResponse.challengeParam.CODE_DELIVERY_DESTINATION})`;
            break;
          case MfaMethod.METHOD_CALL:
            successfulMessage = `${t('verification_call_initiated_to')} ${signInResponse.challengeParam.CODE_DELIVERY_DESTINATION}`;
            break;
          case MfaMethod.METHOD_SMS:
          default:
            successfulMessage = `${t('verification_code_sent_to')} ${signInResponse.challengeParam.CODE_DELIVERY_DESTINATION}`;
            break;
        }

        callback!(
          hasErrored
            ? signInResponse.challengeParam.CODE_DELIVERY_ERROR
            : successfulMessage, {
            variant: hasErrored
              ? 'error'
              : 'info',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            preventDuplicate: true,
          },
        );

        handleSetUserObject(signInResponse);
        history.push(onboarding.loaderPercentage
          ? routes.auth.onboarding.verifyMfa : routes.auth.totp);
        return;
      }
      default:
        throw Error(`Unhandled Challenge name: ${signInResponse.challengeName}`);
    }
  };

  return {
    userInfo,
    loggedIn,
    userObject,
    checkAuth,
    challengeRedirect,
    handleSignOut,
    setUserObject,
  };
};

export default useAuth;
