import { createContext } from 'react';

import { TSignIn } from '../models/auth';

export type TAuthContext = {
  userObject: TSignIn | undefined;
  handleSetUserObject: (userObject: TSignIn) => void;
};

export const AuthContext = createContext<TAuthContext>({
  userObject: undefined,
  handleSetUserObject: () => {},
});
