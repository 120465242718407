import t from 'utils/translationHelper';
import * as yup from 'yup';

import { BeneficiaryParty, BeneficiaryType, ValidateBeneficiaryRequest } from '@alpha/bene-dtos';

export const initialValues: ValidateBeneficiaryRequest = {
  name: '',
  emailAddresses: [],
  accountNumber: '',
  addressLine1: '',
  addressLine2: '',
  bankCountryCode: '',
  countryCode: '',
  city: '',
  correspondentAccountNumber: '',
  correspondentSwift: '',
  currencyCode: '',
  friendlyName: '',
  furtherToAccountNumber: '',
  furtherToSwift: '',
  iban: '',
  nationalBankCode: '',
  state: '',
  swift: '',
  zip: '',
  type: BeneficiaryType.ENTITY,
  party: undefined,
  userIgnoresApplyFinancialErrors: false,
};

export const validation = {
  name: yup
    .string()
    .matches(/^[A-Za-z0-9/+()?:\-‘’',. ]*$/, t('special_characters_are_not_allowed'))
    .min(3, t('must_be_3_characters_or_more'))
    .max(70, t('must_be_70_characters_or_less'))
    .required(t('account_holder_is_required')),
  friendlyName: yup
    .string()
    .matches(/^[A-Za-z0-9/+()?:\-‘’',. ]*$/, t('special_characters_are_not_allowed')),
  addressLine1: yup
    .string()
    .matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
    .min(3, t('must_be_3_characters_or_more'))
    .max(255, t('must_be_255_characters_or_less')),
  addressLine2: yup.string().matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed')),
  city: yup
    .string()
    .matches(/^[A-Za-z0-9/.,?:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
    .min(3, t('must_be_3_characters_or_more'))
    .max(85, t('must_be_85_characters_or_less')),
  zip: yup.string().matches(/^[A-Za-z0-9 ]*$/, t('special_characters_are_not_allowed'))
    .max(20, t('must_be_20_characters_or_less')),
  countryCode: yup
    .string()
    .matches(/^[A-Za-z0-9 ]*$/, t('special_characters_are_not_allowed'))
    .max(80, t('must_be_80_characters_or_less')),
  state: yup
    .string()
    .matches(/^[A-Za-z0-9/.,?:()+‘’' -]*$/, t('special_characters_are_not_allowed'))
    .max(80, t('must_be_80_characters_or_less')),
  bankCountryCode: yup
    .string()
    .required(t('please_select_bank_country')),
  currencyCode: yup
    .string()
    .required(t('please_select_currency_code')),
  emailAddresses: yup.array().of(yup.string().email(t('invalid_email_address'))),
  party: yup
    .string().oneOf([BeneficiaryParty.FIRST, BeneficiaryParty.THIRD])
    .required(t('please_select_1st_or_3rd_party')),
};

export default validation;
