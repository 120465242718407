import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  icon: {
    padding: '0px 2px',
    color: '#2A7BB7',
  },
  title: {
    color: '#FFFFFF',
    fontSize: '12px',
  },
  topSubtitle: {
    color: '#FFFFFF',
    opacity: '56%',
    marginTop: '9px',
  },
  subtitle: {
    color: '#FFFFFF',
    opacity: '56%',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '24px',
  },
  tooltipContainer: {
    minWidth: '238px',
    paddingBottom: '20px',
  },
  statusDefault: {
    marginRight: '9px',
    opacity: '93%',
    backgroundColor: '#DEDEDE',
    fontSize: '14px',
    color: '#333333',
  },
  statusSuccess: {
    marginRight: '9px',
    backgroundColor: '#E3F2E7',
    fontSize: '14px',
    color: '#55B171',
  },
}), { name: 'PadInfoTooltip' });

export default useStyles;
