import React from 'react';

import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { StyledStatSummary, TStyledStatSummaryData } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary';

import Alert from '../../../../../components/Alert';
import t from 'utils/translationHelper';

import useStyles from './PaymentBatchFunding.styles';

interface IFundingOptionAccountBalanceProps {
  currencyAccountDetails: TStyledStatSummaryData[];
  insufficientFunds?: boolean;
}

const FundingOptionAccountBalance: React.FC<IFundingOptionAccountBalanceProps> = (
  {
    currencyAccountDetails,
    insufficientFunds,
  }: IFundingOptionAccountBalanceProps,
) => {
  const styles = useStyles();


  return (
    <Box className={styles.radioButtonsContainer}>
      <div className={styles.boxHeading}>
        <Typography className={styles.boxHeaderText}>
          {t('fund_via_currency_account')}
        </Typography>
      </div>
      <Typography className={styles.boxText}>
        {t('funds_will_be_drawn_on_approval_of_payments')}
      </Typography>
      <StyledStatSummary data={currencyAccountDetails} />

      {insufficientFunds
        && (
          <Alert
            className={styles.boxAlert}
            text={t('insufficient_funds')}
            subText={t('funds_will_need_to_be_on_your_currency_account_before_we_can_release_the_payments')}
            variant="warning"
          />
        )}
    </Box>
  );
};

export default FundingOptionAccountBalance;
