import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  (theme) => ({
    modalHeader: {
      fontSize: 16,
      padding: '12px 24px 24px 24px',
    },
    modalWrapper: {
      maxWidth: '1800px',
      width: '40vw',
      minWidth: '400px',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 290px)',
      minHeight: '160px',
      padding: '20px',
    },
    semiBold: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    submitButton: {
      borderRadius: '5px',
      marginLeft: '6px',
      paddingLeft: '14px',
      paddingRight: '14px',
    },
  }),
  { name: 'EditForecastModal' },
);

export default useStyles;
