import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ palette: { primary } }) => ({
    container: {
      position: 'fixed',
      width: '100%',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.9)',
      zIndex: 1800,
    },
    wrapper: {
      position: 'absolute',
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center',
      '& svg': {
        width: '59px !important',
        height: '59px',
      },
      '& h1': {
        textTransform: 'capitalize',
        fontSize: '20px',
        marginTop: '16px',
        color: 'white',
      },
    },
    red: {
      color: 'red',
    },
    green: {
      color: primary.main,
    },
  }),
  { name: 'Success' },
);

export default useStyles;
