import { AxiosResponse } from 'axios';
import { OptionTradeDto } from 'models/optionTrades';

import instance from '../Axios/instance';

export class OptionTradeService {
  public static async getOptionTrade(tradeId: string):Promise<OptionTradeDto> {
    const response: AxiosResponse<OptionTradeDto> = await instance.get(`fx/options-trades-read/${tradeId}?includeStrips=true&includeLogs=true`);
    return response.data;
  }

  public static async getTermSheetDownloadUrl(tradeId: string):Promise<string> {
    const response: AxiosResponse<{downloadUrl: string}> = await instance.get(`/fx/options/trade-confirmation/${tradeId}`);
    return response.data.downloadUrl;
  }
}

export default OptionTradeService;
