import React from 'react';
import clsx from 'clsx';

import { Button } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faShieldAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import IconBadge, { IIconBadgeProps } from '../Atoms/IconBadge';
import BackdropLoader from '../Molecules/Loaders/BackdropLoader/BackdropLoader';

import useStyles from './styles';

interface IProps {
  icon: IIconBadgeProps,
  content: {
    title: string;
    body: string;
  },
  displaySecureBadge?: boolean;
  loading?: boolean;
  disabled?: boolean;
  handleOnClick: () => void;
}

export const AuthBackupMethodCard: React.FC<IProps> = (
  {
    icon, content, displaySecureBadge, loading, disabled, handleOnClick,
  }: IProps,
) => {

  const styles = useStyles();
  return (
    <Button disabled={disabled} className={styles.container} onClick={handleOnClick}>
      {disabled
        && (
          <div className={styles.loadingState} />
        )}
      <IconBadge {...icon} />
      <div className={styles.contentWrapper}>
        {
          loading
          && (
            <BackdropLoader testId="backdrop-loader" />
          )
        }
        <div className={styles.title}>
          <Typography variant="h3">
            {content.title}
          </Typography>
        </div>
        <div className={styles.body}>
          <Typography variant="body1">
            {content.body}
          </Typography>
        </div>
      </div>
      {
        displaySecureBadge
        && (
          <div className={clsx(styles.badge, disabled && styles.cursor)}>
            <FontAwesomeIcon icon={faShieldAlt as IconProp} />
            <Typography variant="subtitle1">{t('most_secure')}</Typography>
          </div>
        )
      }
    </Button>
  );
};

export default AuthBackupMethodCard;
