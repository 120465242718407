import { makeStyles } from '@alpha/ui-lib/ui/external';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    borderTop: '1px solid #d0d0d0',
    minHeight: '130px',
    position: 'fixed',
    bottom: 0,
    left: '34px',
    width: '100%',
    padding: '40px 20px 40px 20px;',
    display: 'flex',
    justifyContent: 'space-between',
    '& .divider': {
      minHeight: '10px',
      margin: '0px 8px',
    },
    transition: 'opacity 400ms, transform 400ms',
    transform: 'translateY(0)',
    opacity: '1',

    '&.hide': {
      transform: 'translateY(100%)',
      opacity: '0',

    },
  },
  leftContent: {
    marginLeft: '20px', display: 'flex',
  },
  rightContent: {
    marginRight: '20px',
    '& button:nth-child(2)': {
      marginLeft: '12px',
      '@media (max-width: 600px)': {
        marginLeft: 'auto',
        marginTop: '12px',
        display: 'block',

      },
    },
  },
  clearButton: {
    color: '#1E8777',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    '& span div': {
      letterSpacing: 0,
    },
    '@media (max-width: 600px)': {
      height: '48px',
    },
  },
  selectedPayments: {
    lineHeight: '48px',
  },
  rejectButton: {
    background: '#fffffff',
    border: '#B95656 solid 1px',
    '&:hover': {
      background: '#B9565633',
      color: '#B95656FF',
    },
  },
  approveButton: {
    marginLeft: '12px',
    backgroundColor: '#1E8777',
    border: '1px solid #1E8777',
    marginRight: '45px',
  },
}, { name: 'BeneActionBar' });

export default useStyles;
