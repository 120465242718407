import React, { useState } from 'react';
import Big from 'big.js';
import moment from 'moment';
import t from 'utils/translationHelper';
import { useMutation } from 'react-query';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { DrawdownRequest, DrawdownValidationDto, TradeDto } from '@alpha/fx-dtos';
import { ManualPaymentRequest, PaymentOrigin, PaymentStatus } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useForm from '../../../../../hooks/useForm';
import useLog from '../../../../../hooks/useLog';
import instance from '../../../../../services/Axios/instance';
import DrawerBackdropLoader from '../../../Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import { FormikPaymentType } from '../../../Payments/CreatePaymentDrawer/formData';
import { FormikValueProps } from '../formData';
import Summary from '../Summary';
import { Section } from '..';

import validation, { initialValues } from './AddPayment/formData';
import PaymentIncompleteWarningModal from './AddPayment/PaymentIncompleteWarningModal';
import AddPayment from './AddPayment';

type ValidatePaymentParams = {
  data: FormikPaymentType,
  form: DrawdownRequest,
  buyCurrencyAccount?: CurrencyAccountDto,
  sellCurrencyAccount?: CurrencyAccountDto,
  validatedDrawdown?: DrawdownValidationDto
};

type ValidatePaymentResponse = {
  errors: string[],
  id: string,
  status: PaymentStatus,
}

type Props = {
  trade?: TradeDto;
  handleSetSection: (_section: Section) => void;
  drawdownForm?: DrawdownRequest;
  buyCurrencyAccount?: CurrencyAccountDto;
  sellCurrencyAccount?: CurrencyAccountDto;
  validatedDrawdown?: DrawdownValidationDto;
  setOpenPaymentClearWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  setWarningModalConfirmationAction?: React.Dispatch<React.SetStateAction<'BACK_TO_DRAWDOWN' | 'CLOSE_DRAWER'>>,
} & FormikValueProps;

const validatePayment = async ({
  data, form, buyCurrencyAccount, sellCurrencyAccount, validatedDrawdown,
}: ValidatePaymentParams) => {
  const response = await instance.post<ManualPaymentRequest, any>('/payments/validate', {
    beneficiaryId: data.beneficiary!.id,
    emailAddresses: data.emailAddresses,
    amount: +data.amount!,
    fundingCurrency: form.sellCurrencyCode,
    debitingCurrency: form.buyCurrencyCode,
    debitingAccountId: buyCurrencyAccount?.id || '',
    fundingAccountId: sellCurrencyAccount?.id || '',
    paymentDate: moment(data.date!).format('DD/MM/yyyy'),
    reference: data.reference || '',
    purpose: typeof data.purpose === 'object' ? data.purpose.code : data.purpose,
    drawdownId: validatedDrawdown?.id,
    tradeId: validatedDrawdown?.tradeId,
    paymentOrigin: PaymentOrigin.DRAWDOWN,
  });
  return response.data;
};

const Beneficiaries: React.FC<Props> = ({
  trade,
  values,
  setFieldValue,
  drawdownForm,
  handleSetSection,
  buyCurrencyAccount,
  sellCurrencyAccount,
  validatedDrawdown,
  setOpenPaymentClearWarning,
  setWarningModalConfirmationAction,
}) => {

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const sb = useAlphaSnackbar();
  const { logError, logEvent } = useLog();
  const validatePaymentMutations = useMutation(validatePayment, {
    onSuccess: (data: ValidatePaymentResponse) => {
      if (data.status === PaymentStatus.INVALID) {
        sb.trigger(data.errors.length ? `${t('validation_error')} ${data.errors[0]}` : t('there_was_an_error_validating_your_payment'));
        logError({ action: 'Error validating drawdown payments', error: data.errors });
      } else if (data.status === PaymentStatus.VALIDATED) {
        const payment = { ...singlePaymentForm.values, id: data.id };
        setFieldValue('payments', values.payments ? [...values.payments, payment] : [payment]);
        singlePaymentForm.resetForm();
        singlePaymentForm.setFieldValue('date', drawdownForm?.valueDate);
        sb.trigger(t('payment_added'), 'success');
        logEvent({ action: 'Successflully added drawdown payment' });
        if (singlePaymentForm.values.singlePayment) {
          handleSetSection('Submit');
        }
      }
    },
  });

  const checkDrawdownHasEnoughBalance = (
    amount = 0,
    payments?: FormikPaymentType[],
    validatedDrawdownData?: DrawdownValidationDto,
  ): boolean => {
    const total = new Big(0);
    if (payments) {
      for (let i = 0; i < payments.length; i += 1) {
        total.plus(payments[i].amount || 0);
      }
    }
    if (validatedDrawdownData) {
      const enoughBalance = total.plus(amount).lte(validatedDrawdownData?.buyAmount);
      return enoughBalance;
    }
    return false;
  };
  const singlePaymentForm = useForm(initialValues, validation, (formValues: FormikPaymentType) => {
    if (checkDrawdownHasEnoughBalance(
      formValues.amount,
      values.payments,
      validatedDrawdown,
    )) {
      validatePaymentMutations.mutate({
        data: formValues,
        form: drawdownForm!,
        buyCurrencyAccount,
        sellCurrencyAccount,
        validatedDrawdown,
      });
    } else {
      sb.trigger(t('there_is_not_enough_drawdown_balance_for_this_payment'), 'error');
      logError({ action: 'Not enough drawdown balance for payment' });
    }
  }, true);
  const tabTitles: string[] = [t('add_payment'), `${t('summary')} (${values.payments?.length || 0})`];
  const {
    amount, emailAddresses, beneficiary, purpose, reference,
  } = singlePaymentForm.values;
  const isTouched = Boolean(amount || emailAddresses.length || beneficiary || purpose || reference);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <StyledTabsWrapper
            testId="payment-tabs-wrapper"
            tabTitles={tabTitles}
            tabIndex={tabIndex}
            handleChange={(selectedTabIndex: number) => setTabIndex(selectedTabIndex)}
          />
          <div style={{
            position: 'absolute', transform: 'translateY(-8px)', width: '100%', left: 0,
          }}
          >
            <BaseDrawer.LineBreak />
          </div>
          <div style={{ marginTop: '24px' }}>
            {
              tabIndex === 0 && (
                <AddPayment
                  setFieldValue={setFieldValue}
                  payments={values}
                  singlePaymentForm={singlePaymentForm}
                  drawdownForm={drawdownForm}
                  buyCurrencyAccount={buyCurrencyAccount}
                />
              )
            }
            {
              tabIndex === 1 && (
                <Summary values={values} setFieldValue={setFieldValue} />
              )
            }
          </div>
        </div>
        <div style={{
          display: 'flex', flexDirection: 'row', padding: '92px 0 42px 0', justifyContent: 'space-between',
        }}
        >
          <div style={{
            position: 'absolute', transform: 'translateY(-42px)', width: '100%', left: 0,
          }}
          >
            <BaseDrawer.LineBreak />
          </div>
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              if (values.payments
                && values.payments?.length > 0
                && setOpenPaymentClearWarning
              ) {
                if (setWarningModalConfirmationAction) setWarningModalConfirmationAction('BACK_TO_DRAWDOWN');
                setOpenPaymentClearWarning(true);
              } else {
                handleSetSection('Drawdown');
              }
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft as IconProp} />
            {' '}
            {t('back_to_drawdown')}
          </ActionButton>
          <ActionButton
            disabled={!values.payments?.length && !singlePaymentForm.isValid}
            onClick={() => {
              if (!isTouched) {
                handleSetSection('Submit');
              } else if (!singlePaymentForm.isValid && values.payments?.length) {
                setOpenModal(true);
              } else {
                singlePaymentForm.setFieldValue('singlePayment', true);
                singlePaymentForm.submitForm();
              }
            }}
            size="medium"
          >

            {t('continue_to_confirmation')}
            {' '}
            <FontAwesomeIcon icon={faAngleRight as IconProp} />
          </ActionButton>
        </div>
      </div>
      <PaymentIncompleteWarningModal handleClose={() => setOpenModal(false)} open={openModal} handleSubmit={() => handleSetSection('Submit')} />
      <DrawerBackdropLoader display={validatePaymentMutations.isLoading} text={t('validating_your_payment')} />
    </>
  );
};

export default Beneficiaries;
