import { PaymentStatus } from '@alpha/payments-dtos';

export const flowToBatchStatusMapping: any = {
  uploader: [
    PaymentStatus.UPLOADED,
    PaymentStatus.VALIDATED,
    PaymentStatus.INVALID,
  ],
  approver: [
    PaymentStatus.SUBMITTED,
    PaymentStatus.CLIENT_APPROVED,
    PaymentStatus.CLIENT_REJECTED,
  ],
  postCompletion: [
    PaymentStatus.PROCESSING,
    PaymentStatus.COMPLETED,
    PaymentStatus.AWAITING_MONEY_IN,
    PaymentStatus.AWAITING_RELEASE],
};

export const getClientFriendlyBatchStatus = (backendBatchStatus: PaymentStatus | string) => {
  switch (true) {
    case flowToBatchStatusMapping.uploader.includes(backendBatchStatus):
      return 'Saved';
    case flowToBatchStatusMapping.approver.includes(backendBatchStatus):
      return 'Pending Approval';
    case backendBatchStatus === PaymentStatus.PROCESSING:
      return 'In Progress';
    default:
      return backendBatchStatus;
  }
};

export const getBatchStatusColorVariant = (backendBatchStatus: PaymentStatus | string) => {
  switch (true) {
    case backendBatchStatus === 'PENDING FX':
      return 'orange';
    case backendBatchStatus === 'RELEASED':
      return 'success';
    default:
      return 'default';
  }
};

export default {
  flowToBatchStatusMapping,
  getClientFriendlyBatchStatus,
};
