import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import usePositionValuationTable from 'domain/CreditFacility/PositionValuationTable/usePositionValuationTable';
import t from 'utils/translationHelper';

import { TermMTMDto } from '@alpha/credit-dtos';
import { Alert } from '@alpha/ui-lib/ui/Alert';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import Search from '../../../components/Organisms/Search';
import SearchTable from '../../../components/Organisms/SearchTable';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import { formatNumber } from '../../../utils/currency.helpers';
import useStyles from '../PositionValuationTable/index.styles';

interface IOptionsPositionValuationTable {
  mtmDate: string;
  emptyTitle?: string;
  emptySubtitle?: string;
  testId?: string;
  buyCurrencies?: string[];
  sellCurrencies?: string[];
  termsIds?: string[];
  setSearchTextForReport?: React.Dispatch<React.SetStateAction<string | undefined>>;
  fullWidth?: boolean;
  termId?: string;
  alertText?: string;
  shouldUseBrackets: boolean;
  selectedCurrency?: string;
}

const OptionsPositionValuationTable: React.FC<IOptionsPositionValuationTable> = (
  {
    mtmDate,
    emptyTitle,
    emptySubtitle,
    testId,
    buyCurrencies = [],
    sellCurrencies = [],
    termsIds = [],
    setSearchTextForReport,
    fullWidth,
    termId,
    alertText,
    shouldUseBrackets = false,
    selectedCurrency,
  },
) => {
  const [currentSortBy, setCurrentSortBy] = useState<string>('tradeDate');
  const [currentSortOrder, setCurrentSortOrder] = useState<'asc' | 'desc'>('desc');
  const [currentData, setCurrentData] = useState<TermMTMDto[]>([]);
  const [allOptions, setAllOptions] = useState<TermMTMDto[]>([]);
  const {
    table,
    handleInputChange,
    handleTableSortClick,
  } = usePositionValuationTable(
    mtmDate,
    setSearchTextForReport,
    currentSortBy,
    currentSortOrder,
    buyCurrencies,
    sellCurrencies,
    termId,
    termsIds,
    selectedCurrency,
  );

  const classes = useStyles();
  const termMtms = table?.items?.items?.records as TermMTMDto[];

  const columns: ITableColumn[] = [
    { header: t('trade_details'), accessor: 'tradeDetails' },
    { header: t('currency_pair'), accessor: 'currencyPair' },
    { header: t('Total_Exposure_Remaining'), accessor: 'tradeSizeRemaining' },
    {
      header: t('mtm_value'), accessor: 'mtmValue', align: 'right',
    },
    {
      header: `${selectedCurrency} ${t('rate')}`, accessor: 'rate', align: 'right',
    },
    {
      header: `${t('mtm_value')} (${selectedCurrency})`, accessor: 'mtmAmountFacility', align: 'right',
    },
  ];

  useEffect(() => {
    if (termMtms) {
      const optionsMtms = termMtms?.filter((termMtm) => (termMtm.optionsStripId));
      setAllOptions(optionsMtms);
      const data = optionsMtms.slice(0, 10);
      setCurrentData(data);
    }
  }, [termMtms]);

  const generateTableData = (options: TermMTMDto[]) => options?.map(((optionMtm) => ({
    ...optionMtm,
    tradeDetails: <span className={classes.optionsTradeDetail}>
      {optionMtm.optionsTradeProductName}
      <span style={{ fontWeight: 400 }}>{optionMtm.optionsTradeName}</span>
    </span> || '-',
    currencyPair: optionMtm.currencyPairCode || '-',
    tradeSizeRemaining: <span className={classes.tableLink}>
      {`${formatNumber(optionMtm.utilisationAmount, 2, shouldUseBrackets)} ${optionMtm.utilisationCurrencyCode.toLocaleUpperCase()}`}
    </span> || '-',
    mtmValue: optionMtm.mtmAmountGbp && optionMtm.mtmCurrencyCode
      ? (
        <span className={classes.tableLink}>
          {`${formatNumber(optionMtm.mtmAmountGbp, 2, shouldUseBrackets)} ${optionMtm.mtmCurrencyCode.toUpperCase()}`}
        </span>
      )
      : '-',
    rate: optionMtm.gbpFacilityRate
      ? <span className={classes.rateLink}>{optionMtm.gbpFacilityRate.toFixed(4)}</span>
      : '-',
    mtmAmountFacility: <span className={classes.tableLink}>
      {`${formatNumber(optionMtm.mtmAmountFPFacility, 2, shouldUseBrackets)} ${selectedCurrency?.toUpperCase()}`}
    </span> || '-',
  })));

  return (
    <div data-testid="options-position-valuation-loader-component" className={clsx(classes.tableWrapper, fullWidth && 'fullWidth')}>
      {
        table.loading
        && <BackdropLoader testId="loader" />
      }
      <div style={{ display: 'flex', flexGrow: '1', alignItems: 'center' }}>
        <Typography variant="h6" style={{ fontWeight: 600 }}>{t('options_position_valuation_by_trade')}</Typography>
        <div className={classes.verticalLine} />
        <Search
          testId="search-trades"
          value={table.searchText}
          totalItems={table?.items?.items?.totalOptions || 0}
          placeholder={t('search_by_trade_option_name')}
          onChange={handleInputChange}
        />
        {alertText && (
          <Alert
            text={alertText}
            variant="info"
            className={classes.averageRateAlert}
          />
        )}
      </div>
      <SearchTable
        table={{
          columns,
          data: generateTableData(currentData),
          activeColumn: table.sortBy,
          sortOrder: table.sortOrder,
          skip: table.skip,
          dataTestId: testId || '',
          keyColumn: 'id',
          totalRowNumber: table?.items?.totalOptions || 0,
          handleTableSortClick: (column: string) => {
            handleTableSortClick(
              column,
            );
            setCurrentSortBy(column);
            let newOrder: 'asc' | 'desc';
            if (column === currentSortBy) {
              newOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';
            } else {
              newOrder = 'desc';
            }
            setCurrentSortOrder(newOrder);
          },
        }}
        loading={table.loading}
        pagination={{
          handleNext: () => setCurrentData(allOptions.slice(0, currentData.length + 10)),
          hasNext: Boolean(allOptions?.length > currentData.length),
          type: 'NEXT',
        }}
        emptyTable={{
          title: emptyTitle || t('no_options_valuation_trades'),
          subtitle: emptySubtitle || t('you_dont_have_options_position_valuation_trades'),
          className: classes.emptyTable,
        }}
      />
    </div>
  );
};

export default OptionsPositionValuationTable;
