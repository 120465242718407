import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ typography, palette }) => ({
    dropdownWrapperSelected: {
      position: 'relative',
      '& .MuiInputBase-root': {
        paddingLeft: '45px !important',
      },
    },
    dropdown: (props: { dropdownHeight?: string }) => ({
      backgroundColor: 'transparent',
      '& .MuiFormControl-root': {
        '& .MuiInputBase-root': {
          backgroundColor: palette.grey[100],
          borderRadius: '5px',
          '&::before': {
            borderBottom: 'none',
          },
          '&::after': {
            display: 'none',
          },
          height: props.dropdownHeight ? props.dropdownHeight : 'auto',
          padding: '15px 65px 15px 13px',
          ' & .MuiInputBase-input': {
            fontSize: '16px',
            padding: 0,
            height: 'auto',
          },
          '& .MuiAutocomplete-popupIndicator': {
            padding: '4px',
            width: '25px'
          }
        },
      },
    }),
    divider: {
      display: 'flex',
      width: '100%',
      borderBottom: '1px solid',
      borderColor: palette.grey[100],
    },
    hideFlag: {
      '& .MuiInputBase-root': {
        paddingLeft: '16px !important',
      },
    },
    output: {
      '&.hideFlag': {
        display: 'none',
      },
    },
    optionIcon: {
      '&.hideFlag': {
        display: 'none',
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '27px',
      transform: 'translate(-50%,-50%)',
    },
    outputText: {
      fontSize: '16px',
      paddingLeft: '5px',
      paddingBottom: '5px',
    },
    labelWrapper: {
      display: 'flex',
      alignitems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    extraInfoWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    paymentInfo: {
      textAlign: 'left',
      margin: 'auto',
      fontWeight: typography.fontWeightLight,
      color: 'rgb(51, 51, 51, 0.5)',
      marginRight: '8px',
      paddingLeft: '5px',
      marginLeft: '5px',
      paddingBottom: '5px',
    },
    selectedCheck: {
      color: palette.primary.main,
      marginLeft: 'auto',
      alignSelf: 'center',
    },
  }),
  { name: 'AutocompleteDropDown' },
);

export default useStyles;
