import { OptionTradeDto } from 'models/optionTrades';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TOptionTradeStore = {
  trade: OptionTradeDto;
  tradeId: string;
  tradeLoading: boolean,
}

const initialState: TOptionTradeStore = {
  trade: {} as OptionTradeDto,
  tradeId: '',
  tradeLoading: false,
};

export const optionTradeSlice = createSlice({
  name: 'optionTrade',
  initialState,
  reducers: {
    loadTrade: (state, action: PayloadAction<{ tradeId: string }>) => ({ ...state }),
    setOptionTrade: (state, action: PayloadAction<{
      trade: OptionTradeDto
    }>): TOptionTradeStore => (
      {
        ...state,
        ...action.payload,
      }),
    setTradeId: (
      state,
      action: PayloadAction<{ tradeId: string }>,
    ): TOptionTradeStore => ({
      ...state,
      ...action.payload,
    }),
    setTradeLoading: (
      state,
      action: PayloadAction<{ tradeLoading: boolean }>,
    ): TOptionTradeStore => ({
      ...state,
      ...action.payload,
    }),
    clearOptionTrade: () => initialState,
  },
});

export const { actions } = optionTradeSlice;
