import React, { useState } from 'react';
import APModal from 'components/Modals/APModal/APModal';
import { FileType } from 'hooks/useReportsPolling';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { Box, Typography } from '@alpha/ui-lib/ui/external';

import useStyles from './index.styles';

type Props = {
  open: boolean;
  selectedFileType: FileType;
  handleClose: () => void;
  handleReportGeneration: (selectedFileType: FileType, termId?: string, includeExternalTrades?: boolean) => void;
  termId?: string;
}

const ExternalTradesModal: React.FC<Props> = ({
  open,
  selectedFileType,
  handleClose,
  handleReportGeneration,
  termId,
}: Props) => {

  const styles = useStyles();
  const [includeExternal, setIncludeExternal] = useState<boolean>(false);

  return (
    <APModal.Container
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <APModal.Wrapper className={styles.container}>
        <Box className={styles.headerBox}>
          <Typography className={styles.headerText}>{t('include_external_trades?')}</Typography>
        </Box>
        <div className={styles.modalContent}>
          <div style={{ marginBottom: '24px' }}>
            <Typography className={styles.modalText}>
              {`${t('external_trades_are_not_included_in_the_report_by_default')}`}
            </Typography>
          </div>
          <div className={styles.checkbox}>
            <Checkbox
              testId="include-external-trades"
              label={t('include_external_trades')}
              checked={includeExternal}
              onChange={(event) => { setIncludeExternal(event.target.checked); }}
            />
          </div>
        </div>
        <div className={styles.footerBox}>
          <ActionButton
            size="medium"
            variant="outlined"
            style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }}
            onClick={() => {
              handleClose();
              setIncludeExternal(false);
            }}
          >
            {t('cancel')}
          </ActionButton>
          <ActionButton
            size="medium"
            style={{ width: '200px' }}
            onClick={() => {
              handleReportGeneration(selectedFileType, termId, includeExternal);
              handleClose();
              setIncludeExternal(false);
            }}
          >
            {t('download_report')}
          </ActionButton>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default ExternalTradesModal;
