import React, { useEffect, useState } from 'react';

import Rate from '../../../../components/InterAccountTransfer/Rate/Rate';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import InterAccountTransferService from '../../../../services/InterAccountTransfer/interAccountTransfer.service';
import useInterAccountTransferFormContext from '../../useInterAccountTransferFormContext';

export enum rateType {
  INDICATIVE = 'indicative',
  LIVE = 'live',
  EXPIRED = 'expired'
}

const DisplayIndicativeRate: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { form } = useInterAccountTransferFormContext();
  const snackbar = useAlphaSnackbar();
  const creditingCurrencyCode = form.values.creditingAccount.currencyAccount?.currencyCode;
  const debitingCurrencyCode = form.values.debitingAccount.currencyAccount?.currencyCode;
  const { indicativeRate } = form.values;

  const fxRequired: boolean = debitingCurrencyCode !== creditingCurrencyCode;

  const callIndicativeRate = async (
    _debitingCurrencyCode: string,
    _creditingCurrencyCode: string,
    isCancelled: boolean,
  ) => {
    try {
      setLoading(true);
      const responseRate = await InterAccountTransferService.getRate(
        _debitingCurrencyCode,
        _creditingCurrencyCode,
      );

      if (form.values.fixedSide === creditingCurrencyCode
        && form.values.creditingAccount.amount) {
        form.setFieldValue(
          'debitingAccount.amount',
          InterAccountTransferService.calculateAmount(
            'CREDITING',
            form.values.creditingAccount.amount,
            responseRate.rate,
          ),
        );
      } else if (form.values.fixedSide === debitingCurrencyCode
        && form.values.debitingAccount.amount) {
        form.setFieldValue(
          'creditingAccount.amount',
          InterAccountTransferService.calculateAmount(
            'DEBITING',
            form.values.debitingAccount.amount || 0,
            responseRate.rate,
          ),
        );
      }

      if (isCancelled) return;

      form.setFieldValue('indicativeRate', responseRate, false);
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'There was an error retrieving your indicative rate';
      snackbar.trigger(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (
      debitingCurrencyCode
      && creditingCurrencyCode
      && debitingCurrencyCode !== creditingCurrencyCode
    ) {
      callIndicativeRate(
        debitingCurrencyCode,
        creditingCurrencyCode,
        isCancelled,
      );
    }

    if (!fxRequired) {
      form.setFieldValue(
        'creditingAccount.amount',
        form.values.debitingAccount.amount,
      );
      form.setFieldValue(
        'indicativeRate', undefined,
      );
    }
    return () => {
      isCancelled = true;
    };
  }, [debitingCurrencyCode, creditingCurrencyCode]);

  if (fxRequired) {
    return (
      <Rate
        type="indicative"
        debitingCurrency={debitingCurrencyCode}
        creditingCurrency={creditingCurrencyCode}
        rate={indicativeRate}
        loading={loading}
      />
    );
  }

  return null;
};

export default DisplayIndicativeRate;
