import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  accountSettingsPageMainOverride: {
    minWidth: '1400px',
  },
  container: {
    paddingTop: '20px',
    paddingBottom: '65px',
    backgroundColor: '#fafafa',
    minHeight: 'calc(100vh - 290px)',
    minWidth: '100%',
  },
  header: {
    letterSpacing: -0.5,
  },
  root: {
    paddingTop: '12px',
    paddingBottom: '30px',
    backgroundColor: '#fafafa',
    minHeight: 'calc(100vh - 310px)',
    '& .MuiTableCell-head, .MuiTableCell-body': {
      'padding-right': '20px !important',
    },
    '& .MuiTableCell-head:last-of-type, .MuiTableCell-body:last-of-type': {
      'text-align': 'right',
      'padding-right': '0 !important',
    },
  },
  detailsContainer: {
    width: '40%',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    padding: '20px',
    marginTop: '20px',
    borderRadius: '5px',
    marginRight: '20px',
  },
  passwordContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    padding: '30px',
    marginTop: '20px',
    borderRadius: '5px',
  },
  icon: {
    transition: 'all 0.1s linear',
    '&.up': {
      transform: 'rotate(180deg)',
    },
  },
  accountTitle: {
    fontSize: '20px',
    color: '#333333',
    display: 'inline-block',
    margin: '0',
    height: '20px',
  },
  accountDropDownButton: {
    background: 'transparent',
    color: '#1E8777',
    fontSize: '20px',
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
    },
  },
}), { name: 'AccountSettings' });

export default useStyles;
