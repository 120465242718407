import React from 'react';
import clsx from 'clsx';

import { PaymentDto } from '@alpha/payments-dtos/lib/responses/PaymentDto';
import { Box, Typography } from '@alpha/ui-lib/ui/external';

import useStyles from './HeaderText.styles';

interface IProps {
  selectedPayment: PaymentDto | undefined;
}

const HeaderText: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const {
    selectedPayment,
  } = props;
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Typography className={clsx(classes.title, classes.lightWeight)}>
          {selectedPayment?.contractNumber || '-'}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeaderText;
