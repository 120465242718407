import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import t from 'utils/translationHelper';

import { PaymentBatchStatus, PaymentFundingMethod } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/external';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { StyledStatSummary } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from '../../../../components/Alert';
import routes from '../../../../routes.path';
import browserHistory from '../../../../services/history/browserHistory';
import { TStore } from '../../../../store';
import { TAuthyState } from '../../../../store/authy/reducer';
import mapPaymentBatchStatusDisplay from '../../../../utils/payments/mapPaymentBatchStatusDisplay';

import CancelBatchModal from './CancelBatchModal/CancelBatchModal';
import PaymentBatchFunding from './PaymentBatchFunding/PaymentBatchFunding';
import RejectedPayments from './RejectedPayments/RejectedPayments';
import RightAlignedCta from './RightAlignedCta/RightAlignedCta';
import SubmitPaymentsModal from './SubmitPaymentsModal/SubmitPaymentsModal';
import ValidPayments from './ValidPayments/ValidPayments';
import useStyles from './UploadSummary.styles';
import useSubmitBatchPayments from './useSubmitBatchPayments';
import useUploadSummary from './useUploadSummary';

// eslint-disable-next-line max-lines-per-function
const UploadSummary: React.FC = () => {
  const styles = useStyles();
  const { batchId } = useParams<{ batchId: string }>();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const {
    rejectedPayments,
    validPayments,
    erroredPayments,
    getBatchSummary,
    batchStatsSummary,
    paymentsStatsSummary,
    loadRejectedPaymentsData,
    loadValidPaymentsData,
    loadErroredPaymentsData,
    batchUploadResult,
    openCancelBatchModal,
    setOpenCancelBatchModal,
    setTextValue,
    handlePaymentApprovers,
    approvers,
    dropdownLoading,
    selectedApprovers,
    setSelectedApprovers,
    submitApproverEmails,
    rejectedLoading,
    validLoading,
    erroredLoading,
    pollValidPayments,
    pollInvalidPayments,
    pollErroredPayments,
  } = useUploadSummary({ batchId });

  const {
    openSubmitPaymentsModal,
    setOpenSubmitPaymentsModal,
    handleSubmission,
    selectedFundingMethod,
    setSelectedFundingMethod,
    selectedForward,
    setSelectedForward,
    fundingCurrencyCode,
    setFundingCurrencyCode,
    paymentSubmitting,
    setPaymentSubmitting,
    selectedDrawdownFundingMethod,
    setSelectedDrawdownFundingMethod,
    isValidFxLimit,
    setIsValidFxLimit,
  } = useSubmitBatchPayments(
    {
      currencyCode: batchUploadResult?.currencyCode,
      totalValidAmount: batchUploadResult?.totalValidAmount,
      validPayments,
      batchId,
    },
  );

  useEffect(() => {
    if (batchId) {
      loadRejectedPaymentsData();
      loadValidPaymentsData();
      loadErroredPaymentsData();
      handlePaymentApprovers();
      getBatchSummary.mutate(batchId);
    }
  }, [batchId]);

  useEffect(() => {
    if (batchUploadResult) {
      pollValidPayments(5);
      pollInvalidPayments(5);
      pollErroredPayments(5);
    }
  }, [batchUploadResult]);

  interface IPaymentExtraInfo {
    submitResult: ISubmitResult;
  }
  interface ISubmitResult {
    tradeSubmissionSuccess: boolean;
    tradeId: string;
    tradeSubmissionFriendlyErrorMessage: string;
  }

  useEffect(() => {
    if (
      (authyState.type?.type === 'PAYMENTS' || authyState.type?.type === 'DRAWDOWN')
      && authyState.status === 'SUCCESS'
    ) {
      const paymentExtraInfo = ((authyState.extraInfo) as IPaymentExtraInfo | undefined);
      if (typeof paymentExtraInfo === 'object' && paymentExtraInfo && 'submitResult' in paymentExtraInfo) {
        const submitResult = paymentExtraInfo.submitResult as ISubmitResult;
        const searchString = submitResult.tradeId ? `?booktrade=${submitResult.tradeId}` : undefined;
        browserHistory.push({ pathname: routes.transactions.base, search: searchString });
      } else {
        browserHistory.push({ pathname: routes.transactions.base });
      }
      if (selectedApprovers.length > 0) { submitApproverEmails(); }
      setPaymentSubmitting(false);
    }
    if (
      (authyState.type?.type === 'PAYMENTS' || authyState.type?.type === 'DRAWDOWN')
      && (authyState.status === 'ERROR' || authyState.status === 'INITIATED')
    ) {
      setPaymentSubmitting(false);
    }
  }, [authyState.status, authyState.type]);

  const isFundingMethodValid = (): boolean => {
    if (selectedFundingMethod === PaymentFundingMethod.DRAWDOWN) {
      return Boolean(selectedDrawdownFundingMethod) && Boolean(fundingCurrencyCode);
    }
    return Boolean(fundingCurrencyCode);
  };

  return (
    <APMainLayout
      title={(
        <div className={styles.titleContainer}>
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
            testId="summary-back-btn"
            onClick={() => { setOpenCancelBatchModal(true); }}
            className={styles.returnButton}
          >
            {t('payment_upload')}
          </ActionButton>
          <div className={styles.subtitleContainer}>
            <Typography className={styles.uploadSummaryTitle}>
              {`${batchUploadResult?.fileName || '-'}`}
            </Typography>
            <div className={styles.verticalLine} />
            <Status variant={mapPaymentBatchStatusDisplay(
              getBatchSummary.data?.status || PaymentBatchStatus.INVALID,
            ).variant}
            >
              {t(mapPaymentBatchStatusDisplay(
                batchUploadResult?.status || PaymentBatchStatus.INVALID,
              ).text)}
            </Status>
          </div>
        </div>
      )}
      pageTabs={(
        <StyledTabsWrapper
          className={styles.tabs}
          testId="upload-summary-tabs"
          tabTitles={[t('summary')]}
          tabIndex={0}
          handleChange={() => { }}
        />
      )}
      rightAlignedCta={(
        <RightAlignedCta
          numberOfValidPayments={batchUploadResult?.validRecords || 0}
          submitPayments={handleSubmission}
          cancelUpload={() => { setOpenCancelBatchModal(true); }}
          fundingMethodValid={isFundingMethodValid()}
          isSubmitting={paymentSubmitting}
          isValidFxLimit={isValidFxLimit}
          isInvalid={!!batchUploadResult?.erroredRecords || !!batchUploadResult?.invalidRecords}
        />
      )}
    >
      <div className={styles.contentWrapper}>
        {!!batchUploadResult?.erroredRecords || !!batchUploadResult?.invalidRecords ? (
          <Alert
            className={styles.alert}
            variant="error"
            text={t('if_you_want_to_process_these_payments_review_errors')}
          />
        ) : null}
        <div className={styles.statsContainers}>
          <StyledStatSummary style={{ maxWidth: 700 }} data={batchStatsSummary} />
          <StyledStatSummary style={{ maxWidth: 700 }} data={paymentsStatsSummary} />
        </div>
        {batchUploadResult && batchUploadResult?.validRecords > 0 && (
          <PaymentBatchFunding
            currencyCode={batchUploadResult?.currencyCode}
            totalValidAmount={batchUploadResult?.totalValidAmount}
            selectedFundingMethod={selectedFundingMethod}
            setSelectedFundingMethod={setSelectedFundingMethod}
            selectedForward={selectedForward}
            setSelectedForward={setSelectedForward}
            fundingCurrencyCode={fundingCurrencyCode}
            setFundingCurrencyCode={setFundingCurrencyCode}
            selectedDrawdownFundingMethod={selectedDrawdownFundingMethod}
            setSelectedDrawdownFundingMethod={setSelectedDrawdownFundingMethod}
            isValidFxLimit={isValidFxLimit}
            setIsValidFxLimit={setIsValidFxLimit}
          />
        )}
        {batchUploadResult
          && (batchUploadResult?.invalidRecords > 0 || batchUploadResult?.erroredRecords > 0)
          && (
            <>
              <Alert
                text={t('some_of_your_payments_have_not_passed_validation')}
                variant="warning"
                subText={t('please_review_the_payments_before_continuing')}
                className={styles.invalidWarning}
              />
              <RejectedPayments
                payments={[...rejectedPayments, ...erroredPayments]}
                loading={rejectedLoading || erroredLoading}
              />
            </>
          )}
        {batchUploadResult && batchUploadResult?.validRecords > 0 && (
          <ValidPayments
            payments={validPayments}
            batchSummary={batchUploadResult}
            loading={validLoading}
          />
        )}
        <CancelBatchModal
          open={openCancelBatchModal}
          handleClose={() => { setOpenCancelBatchModal(false); }}
        />
        <SubmitPaymentsModal
          open={openSubmitPaymentsModal}
          handleClose={() => { setOpenSubmitPaymentsModal(false); }}
          handleSubmitPayments={handleSubmission}
          setCurrentOptions={setSelectedApprovers}
          setTextValue={setTextValue}
          approvers={approvers}
          dropdownLoading={dropdownLoading}
        />
      </div>
    </APMainLayout>
  );
};

export default UploadSummary;
