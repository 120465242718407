import React from 'react';
import moment from 'moment';

import { PaymentBatchDto } from '@alpha/payments-dtos';
import { StyledStatSummary } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary/StyledStatSummary';
import t from 'utils/translationHelper';

import { CurrencyPair } from '../../../../../../components/International';
import { formatNumber, getCurrencySymbol } from '../../../../../../utils/currency.helpers';

import useStyles from './PaymentBatchSummary.styles';

interface IProps {
  paymentBatch: PaymentBatchDto | undefined;
  validPaymentNumber: number;
}

const PaymentBatchSummary: React.FC<IProps> = ({
  paymentBatch,
  validPaymentNumber,
}: IProps) => {

  const classes = useStyles();
  const statDataLeft = [
    {
      key: t('batch_id'),
      value: paymentBatch?.friendlyId || '-',
    },
    {
      key: t('input_date'),
      value: paymentBatch?.uploadedDate ? moment(paymentBatch?.uploadedDate).format('DD/MM/YYYY') : '',
    },
    {
      key: t('uploaded_by'),
      value: paymentBatch?.uploadedBy || '-',
    },
  ];
  const statDataRight = [
    {
      key: t('currency'),
      value: paymentBatch?.currencyCode
        ? (<CurrencyPair currencyCode={paymentBatch?.currencyCode} />)
        : '-',
    },
    {
      key: t('total_payment_amount'),
      value: paymentBatch?.totalValidAmount
        ? `${getCurrencySymbol(paymentBatch?.currencyCode)}${formatNumber(paymentBatch?.totalValidAmount, 2)}`
        : '-',
    },
    {
      key: t('no_of_valid_payments'),
      value: `${validPaymentNumber || '-'}`,
    },
  ];

  return (
    <div className={classes.contentWrapper}>
      <StyledStatSummary data={statDataLeft} style={{ marginRight: '20px' }} />
      <StyledStatSummary data={statDataRight} />
    </div>
  );
};
export default PaymentBatchSummary;
