import {
  call, putResolve, takeLatest,
} from 'redux-saga/effects';
import CreditService from 'services/Credit/credit.service';
import { apiRequest } from 'store/requestSaga';

import { datadogLogs } from '@datadog/browser-logs';

import env from '../../env.variables';

import { actions } from './reducer';

async function getTermSummary(termId: string) {
  const response = await CreditService.getFacilityTermSummary(termId);
  return response;
}

export function* getCreditFacilitySummarySaga() {
  yield putResolve(actions.setFacilityLoading({ facilityLoading: true }));
  try {
    const result = yield call(apiRequest, {
      config: {
        method: 'GET',
        url: `${env.REACT_APP_API_URL}credit/facility/summary`,
      },
      name: 'creditFacilitySummary',
    });
    if (result) {
      yield putResolve(actions.setCreditFacility({ facility: result.data }));
      if (result.data?.facilityTerms?.length === 1) {
        yield putResolve(actions.setFacilityMode({ facilityMode: 'single' }));
        yield putResolve(actions.setTermId({ termId: result.data.facilityTerms[0].id }));
        const termSummary = yield call(getTermSummary, result.data.facilityTerms[0].id);
        if (termSummary) {
          yield putResolve(actions.setTermSummary({ termSummary }));
        }
      } else if (result.data?.facilityTerms?.length > 1) {
        yield putResolve(actions.setFacilityMode({ facilityMode: 'multiple' }));
      }
    }
  } catch (e) {
    datadogLogs.logger.error(e);
  } finally {
    yield putResolve(actions.setFacilityLoading({ facilityLoading: false }));
  }
}

export function* getCreditFacilityTermSummarySaga(action: ReturnType<typeof actions.loadTerm>) {
  yield putResolve(actions.setTermLoading({ termLoading: true }));
  try {
    const result = yield call(getTermSummary, action.payload.termId);
    if (result) {
      yield putResolve(actions.setTermSummary({ termSummary: result }));
    }
  } catch (e) {
    datadogLogs.logger.error(e);
  } finally {
    yield putResolve(actions.setTermLoading({ termLoading: false }));
  }
}

export function* watchGetCreditFacilitySummarySaga() {
  yield takeLatest(actions.loadCreditFacility.type, getCreditFacilitySummarySaga);
  yield takeLatest(actions.loadTerm.type, getCreditFacilityTermSummarySaga);
}
