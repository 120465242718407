import React from 'react';
import Authorization from 'hocs/Authorization';
import { UserRole } from 'models/user';
import t from 'utils/translationHelper';

import { BeneficiaryBatchStatus, BeneficiaryBatchSummaryDto } from '@alpha/bene-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  batchBeneficiarySummary: BeneficiaryBatchSummaryDto;
  hideSubmit: boolean;
  handleDeleteUpload?: () => void;
  submitValidBeneficiaries?: () => void;
}

const RightAlignedCta: React.FC<IProps> = ({
  batchBeneficiarySummary,
  hideSubmit,
  handleDeleteUpload,
  submitValidBeneficiaries,
}: IProps) => (
  <Box flex="column">
    <>
      <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
        <ActionButton
          testId="delete-batch-beneficiary-btn"
          size="medium"
          style={{ marginRight: '10px', background: 'rgba(166, 43, 35, 0.10)', color: '#A62B23' }}
          startIcon={<FontAwesomeIcon icon={faTrashAlt as IconProp} />}
          onClick={handleDeleteUpload}
          disabled={batchBeneficiarySummary?.status === BeneficiaryBatchStatus.COMPLETED}
        >
          {t('delete_upload')}
        </ActionButton>
      </Authorization>
      <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
        <ActionButton
          testId="submit-batch-beneficiary-btn"
          size="medium"
          onClick={submitValidBeneficiaries}
          disabled={hideSubmit}
        >
          {t('submit_valid_beneficiaries')}
          {' '}
          <span>{`(${batchBeneficiarySummary.validRecords})`}</span>
        </ActionButton>
      </Authorization>
    </>
  </Box>
);
export default RightAlignedCta;
