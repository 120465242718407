import React, { useEffect, useState } from 'react';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';
import { initiateBeneficiary } from 'store/authy/actions';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button/ActionButton';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs/StyledTabsWrapper';

import ApprovalSummary from './ApprovalSummary';
import useStyles from './index.styles';

type Props = {
  open: boolean,
  selectedBenes: BeneficiaryDto[],
  onClose: () => void,
  canApproveOwn: boolean,
}

const ApprovalDrawer: React.FC<Props> = ({
  open, selectedBenes, onClose, canApproveOwn,
}) => {
  const styles = useStyles();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const sb = useAlphaSnackbar();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const nonFinalApprovers = selectedBenes.filter(
    (sp) => (sp.approvalInfo.approvalsRequired - sp.approvalInfo.approvals.length) > 1,
  );
  const finalApprovers = selectedBenes.filter(
    (sp) => (sp.approvalInfo.approvalsRequired - sp.approvalInfo.approvals.length) === 1,
  );

  const tabTitles: string[] = [`${t('further_approval_required')} (${nonFinalApprovers.length})`, `${t('fully_approved')} (${finalApprovers.length})`];

  const onDrawerClose = () => {
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (!nonFinalApprovers.length) {
      setTabIndex(1);
    }
  }, [selectedBenes]);

  const handleApprovals = async (): Promise<void> => {
    try {
      dispatch(initiateBeneficiary({
        approverOwn: canApproveOwn, batchId: selectedBenes.map((s) => s.id), submission: false, type: 'BENEFICIARY',
      }));
      onClose();
    } catch (e) {
      sb.trigger(t('bene_approval_unsuccessful'));
      onClose();
    }
  };

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        className={styles.container}
      >
        <BaseDrawer.Header
          headerTitle={t('bene_approval')}
          headerSubtitle={`${selectedBenes.length} ${selectedBenes.length === 1 ? t('beneficiary') : t('beneficiaries')}`}
        />
        <BaseDrawer.Body>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 'calc(100vh - 139px)',
          }}
          >
            <div>
              <StyledTabsWrapper
                testId="payment-tabs-wrapper"
                tabTitles={tabTitles}
                tabIndex={tabIndex}
                handleChange={(selectedTabIndex: number) => setTabIndex(selectedTabIndex)}
              />
              <BaseDrawer.LineBreak />
              <div style={{ marginTop: '24px' }}>
                {tabIndex === 0 ? (<ApprovalSummary values={nonFinalApprovers} />) : null}
                {tabIndex === 1 ? (<ApprovalSummary values={finalApprovers} />) : null}
              </div>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', padding: '92px 0 42px 0', justifyContent: 'space-between',
            }}
            >
              <div style={{
                position: 'absolute', transform: 'translateY(-42px)', width: '100%', left: 0,
              }}
              >
                <BaseDrawer.LineBreak />
              </div>
              <ActionButton
                size="medium"
                style={{
                  background: '#F7F7F7', color: '#212529', marginRight: '16px',
                }}
                onClick={onDrawerClose}
              >
                {t('cancel')}
              </ActionButton>
              <ActionButton fullWidth size="medium" onClick={handleApprovals} disabled={loading} loading={loading}>
                {selectedBenes.length > 1 ? `${t('confirm_NUMBER_approvals1')} ${selectedBenes.length} ${t('confirm_NUMBER_approvals2')}` : t('confirm_approval')}
              </ActionButton>
            </div>
          </div>
        </BaseDrawer.Body>
      </BaseDrawer.Drawer>
      {/* <InvalidApprovalModal
        handleClose={() => {
          setFailedPayments([]);
          setLoading(false);
        }}
        handleAcknowledge={() => {
          setFailedPayments([]);
          setLoading(false);
          onClose();
        }}
        open={Boolean(failedPayments.length)}
        payments={failedPayments}
      /> */}
    </>
  );
};

export default ApprovalDrawer;
