import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography, palette }) => ({
  contentWrapper: {
    paddingTop: '12px',
    paddingBottom: '100px',
    backgroundColor: '#fafafa',
    minHeight: 'calc(100vh - 310px)',
  },
  mainContainer: {
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.03)',
    padding: '30px 40px 30px 40px',
    marginTop: '20px',
    borderRadius: '5px',
  },
  boxHeaderText: {
    marginBottom: '20px',
    fontSize: '16px',
    alignSelf: 'left',
    fontWeight: typography.fontWeightBold,
  },
  boxHeading: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    backgroundColor: palette.grey[100],
    borderRadius: '5px',
    padding: '5px',
    marginRight: '10px',
  },
  collapseIcon: {
    transition: 'all 0.1s linear',
    '&.right': {
      transform: 'rotate(-90deg)',
    },
  },
}), { name: 'PaymentBatchDetail' });

export default useStyles;
