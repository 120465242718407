import React from 'react';
import clsx from 'clsx';
import t from 'utils/translationHelper';

import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { rateType } from '../../../domain/InterAccountTransfer/DebitingAndCreditingDrawer/DisplayIndicativeRate/DisplayIndicativeRate';
import setInverseRate from '../../../utils/setInverseRate';
import InverseFxRateTooltip from '../../Molecules/InverseFxRateTooltip/InverseFxRateTooltip';

import { useStyles } from './Rate.styles';

interface IProps {
  loading?: boolean;
  type: 'live' | 'indicative' | 'expired';
  rate?: number;
  debitingCurrency: string | undefined;
  creditingCurrency: string | undefined;
  fixedDecimals?: number;
}

const Rate: React.FC<IProps> = (props: IProps) => {
  const {
    type, rate, debitingCurrency, creditingCurrency, loading,
    fixedDecimals = 4,
  } = props;

  const styles = useStyles();

  if (!debitingCurrency || !creditingCurrency) return null;

  const handleRateStyling = () => {
    if (loading) return styles.loading;
    if (type === rateType.LIVE) return styles.live;
    if (type === rateType.EXPIRED) return styles.expired;
    return styles.indicative;
  };

  return (
    <Box className={styles.root}>
      <InverseFxRateTooltip
        text={(
          <div
            className={clsx(
              styles.rateFont,
              styles.coloredBackground,
              handleRateStyling(),
            )}
          >
            {!loading ? `${t(type).toUpperCase()}` : t('loading^')}
            <FontAwesomeIcon className={styles.icon} icon={faInfoCircle as IconProp} />
          </div>
        )}
      />
      <Box className={styles.rate}>
        <Box className={styles.amount}>
          <Typography
            variant="h2"
            className={clsx(
              styles.bold,
              type === rateType.LIVE
              && styles.liveRate,
            )}
          >
            {
              (loading || rate === undefined)
                ? (
                  <Loader testId="loader" size="20px" />
                )
                : rate.toFixed(fixedDecimals)
            }
          </Typography>
        </Box>
      </Box>
      {
        rate !== undefined
        && (
          <Box className={styles.inverseRateWrapper}>
            <Typography variant="h2" className={clsx(styles.inverseRate)}>
              {(loading || rate === undefined) ? '-' : setInverseRate(rate, fixedDecimals)}
            </Typography>
          </Box>
        )
      }
    </Box>
  );
};

export default Rate;
