import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { DrawdownDto } from '@alpha/fx-dtos';
import { PaymentBatchDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { DrawdownStatSummary } from '@alpha/ui-lib/ui/StatSummary/DrawdownStatSummary';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faEye } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAlphaSnackbar from '../../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../../hooks/useLog';
import routes from '../../../../../../routes.path';
import browserHistory from '../../../../../../services/history/browserHistory';
import PaymentsService from '../../../../../../services/Payments/payments.service';
import { formatNumber } from '../../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../../utils/formatIsoDate';
import mapDrawdownDtoToSummary from '../../../../../../utils/mapDrawdownDtoToSummary';
import PadEligible from '../../TradeDetail/PadEligible/PadEligible';

import useStyles from './Body.styles';

interface IProps {
  selectedDrawdown: DrawdownDto | undefined;
  handleDrawdownPaymentContractNoteDownload?: (drawdownPaymentId: string) => void;
}

const Body: React.FC<IProps> = (props: IProps) => {
  const { selectedDrawdown, handleDrawdownPaymentContractNoteDownload } = props;
  const [paymentBatch, setPaymentBatch] = useState<PaymentBatchDto>();
  const [batchLoading, setBatchLoading] = useState<boolean>(false);
  const paymentBatchId = selectedDrawdown?.payments[0]?.batchId;

  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const getPaymentBatchDetails = async (): Promise<void> => {
    if (paymentBatchId) {
      try {
        setBatchLoading(true);
        setPaymentBatch(await PaymentsService.getPaymentBatch(paymentBatchId));
      } catch (error) {
        sb.trigger(error?.message || t('there_was_an_error_retrieving_batch_details_for_your_drawdown'));
        logError({ action: 'Error loading batch details for drawdown', error });
      } finally {
        setBatchLoading(false);
      }
    }
  };

  useEffect(() => {
    getPaymentBatchDetails();
  }, [paymentBatchId]);

  if (paymentBatchId) {
    return (
      <>
        {batchLoading ? <Loader testId="loader" size="20px" />
          : (
            <div className={styles.drawerBody}>
              <div>
                <Typography className={styles.bodyHeader}>{t('drawdown_details')}</Typography>
                <DrawdownStatSummary data={mapDrawdownDtoToSummary(selectedDrawdown)} />
                {selectedDrawdown?.padEligible && (
                  <PadEligible padEligibleItem={selectedDrawdown} drawerStyle isTrade={false} />
                )}
                <>
                  <h3>
                    {t('associated_payment_batch')}
                  </h3>
                  <Box className={styles.associatedPayments}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" flexGrow={1}>
                      <span className={styles.paymentContractNumber}>
                        {paymentBatch?.friendlyId}
                      </span>
                      <span className={styles.paymentAmount}>
                        {`${formatNumber(paymentBatch?.totalValidAmount || 0, 2)} ${paymentBatch?.currencyCode}`}
                      </span>
                    </Box>
                    {`${t('number_of_payments')}: ${selectedDrawdown?.payments.length || '-'}`}
                    <Box display="flex" flexDirection="row" justifyContent="space-between" flexGrow={1}>
                      <span className={styles.paymentInfo}>
                        {`${formatIsoDate(paymentBatch?.uploadedDate)}`}
                      </span>
                      <APTooltip title={t('view_payment_batch_details')}>
                        <ActionButton
                          style={{ background: '#F7F7F7', color: '#212529' }}
                          onClick={() => {
                            browserHistory.push(routes.transactions.payments.replace(':batchId', paymentBatchId));
                          }}
                        >
                          <FontAwesomeIcon icon={faEye as IconProp} />
                        </ActionButton>
                      </APTooltip>
                    </Box>
                  </Box>
                </>
              </div>
            </div>
          )}
      </>
    );
  }
  return (
    <div className={styles.drawerBody}>
      <div>
        <Typography className={styles.bodyHeader}>{t('drawdown_details')}</Typography>
        <DrawdownStatSummary data={mapDrawdownDtoToSummary(selectedDrawdown)} />
        {selectedDrawdown?.padEligible && (
          <PadEligible
            padEligibleItem={selectedDrawdown}
            drawerStyle
            isTrade={false}
          />
        )}
        {selectedDrawdown?.payments
          && selectedDrawdown?.payments?.length > 0 && (
            <>
              <h3>
                {t('associated_payments')}
                {' '}
                (
                {selectedDrawdown?.payments?.length}
                )
              </h3>

              {selectedDrawdown?.payments.map((payment) => (
                <Box className={styles.associatedPayments}>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" flexGrow={1}>
                    <span className={styles.paymentContractNumber}>
                      {payment.contractNumber}
                    </span>
                    <span className={styles.paymentAmount}>
                      {`${formatNumber(payment.amount, 2)} ${payment.currency}`}
                    </span>
                  </Box>
                  {`${t('beneficiary')}: ${payment.beneficiaryName || '-'}`}
                  <Box display="flex" flexDirection="row" justifyContent="space-between" flexGrow={1}>
                    <span className={styles.paymentInfo}>
                      {`${payment.reference || '-'} • ${formatIsoDate(payment.valueDate)}`}
                    </span>
                    <APTooltip title={t('download_payment_summary')}>
                      <ActionButton
                        style={{ background: '#F7F7F7', color: '#212529' }}
                        onClick={() => {
                          if (handleDrawdownPaymentContractNoteDownload) {
                            handleDrawdownPaymentContractNoteDownload(payment.id);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faDownload as IconProp} />
                      </ActionButton>
                    </APTooltip>
                  </Box>
                </Box>
              ))}
            </>
          )}
      </div>
    </div>
  );
};

export default Body;
