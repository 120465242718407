import React from 'react';

import { Box } from '@alpha/ui-lib/ui/external';
import { useTranslation, Trans } from 'react-i18next';
import { ITableColumn } from '../../../../../services/DatabaseServices/marker.interface';

const TransComp: React.FC<any> = () => {

  return (
    <Trans t={t}>Funding Account</Trans>
  )
}


export const preProcessingColumns: ITableColumn[] = [
  {
    header: 'Beneficiary',
    accessor: 'beneficiaryName',
  },
  {
    header: <TransComp />,
    accessor: 'fundingAccount',
  },
  {
    header: 'Debit Account',
    accessor: 'debitAccount',
  },
  {
    header: 'Purpose Of Payment',
    accessor: 'purposeOfPayment',
  },
  {
    header: 'Payment Ref',
    accessor: 'paymentRef',
  },
  {
    header: <Box textAlign="left">Payment Date</Box>,
    accessor: 'paymentDate',
  },
  {
    header: <Box textAlign="left">Payment Currency</Box>,
    accessor: 'paymentCurrency',
  },
  {
    header: <Box textAlign="right">Amount</Box>,
    accessor: 'amount',
  },
];

export const postProcessingColumns: ITableColumn[] = [
  {
    header: 'Beneficiary',
    accessor: 'beneficiaryName',
  },
  {
    header: 'Purpose Of Payment',
    accessor: 'purposeOfPayment',
  },
  {
    header: 'Payment Ref',
    accessor: 'paymentRef',
  },
  {
    header: 'Payment Date',
    accessor: 'paymentDate',
  },
  {
    header: <Box textAlign="right">Fixed Amount</Box>,
    accessor: 'fixedAmount',
  },
  {
    header: <Box textAlign="right">Payment Amount</Box>,
    accessor: 'paymentAmount',
  },
  {
    header: <Box textAlign="right">Payment Status</Box>,
    accessor: 'paymentStatus',
  },
  {
    header: '',
    accessor: 'downloadButton',
  },
];

export const getColumns = (postApproval: boolean) => (
  postApproval
    ? postProcessingColumns : preProcessingColumns
);
