import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ palette }) => ({
  dragnDropBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '360px',
    border: `1px dashed ${palette.grey[300]}`,
    borderRadius: 10,
    '& > div.middleContent': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& img': {
      height: '90px',
      width: '90px',
      marginBottom: '12px',
    },
    '&.normal': {
      backgroundColor: palette.grey[100],
    },
    '&.dragEnter': {
      backgroundColor: palette.grey[200],
    },
    '&.benes': {
      width: '100%',
    },
    '&.payments': {
      width: '90%',
    },
  },
}), { name: 'DragAndDrop' });

export default useStyles;
