import queryString from 'querystring';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import t from 'utils/translationHelper';

import { BeneficiaryDto, BeneficiaryStatus } from '@alpha/bene-dtos';

import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useLog from '../../../hooks/useLog';
import useSearch, { TSearchParams } from '../../../hooks/useSearch';
import { TBeneficiaryApproved, TBeneficiaryPending } from '../../../models/beneficiaries';
import BeneficiariesService from '../../../services/Beneficiaries/beneficiaries.service';
import { TStore } from '../../../store';
import { TAuthyState } from '../../../store/authy/reducer';

import { ModalType } from './Body';

type BeneStatusType = 'approved' | 'pending' | 'rejected' | 'draft';

const createSearchParams = (
  beneStatus: BeneStatusType,
  skip: number,
): TSearchParams => {
  const statusQuery = {
    approved: BeneficiaryStatus.CLIENT_APPROVED,
    pending: [BeneficiaryStatus.SUBMITTED, BeneficiaryStatus.REQUESTED, BeneficiaryStatus.UPLOADED].join(','),
    rejected: [BeneficiaryStatus.ALPHA_REJECTED, BeneficiaryStatus.CLIENT_REJECTED].join(','),
    draft: BeneficiaryStatus.VALIDATED,
  };

  const searchParams: TSearchParams = {
    baseUrl: '/bene/beneficiaries',
    queryParams: {
      skip: 0,
      take: 10,
      sortby: 'uploadedByDate',
      sortorder: 'desc',
      status: statusQuery[beneStatus],
    },
  };

  return searchParams;
};

const parseBeneStatusFromTabIndex = (
  tabIndex: number,
): BeneStatusType => {
  switch (tabIndex) {
    case 0:
      return 'approved';
    case 1:
    default:
      return 'pending';
    case 2:
      return 'rejected';
    case 3:
      return 'draft';
  }
};

const useBody = (selectedTabIndex: number) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<ModalType | false>(false);
  const [rejectLoading, setRejectLoading] = useState<boolean>(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<{
    beneficiary: TBeneficiaryApproved | TBeneficiaryPending;
    approved: boolean;
  }>();

  const [beneToDelete, setBeneToDelete] = useState<{
    beneficiary: TBeneficiaryApproved | TBeneficiaryPending;
    approved: boolean;
  }>();

  useEffect(() => {
    setBeneToDelete(selectedBeneficiary);
  }, [selectedBeneficiary])

  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const { logEvent, logError } = useLog();
  const location = useLocation();
  const sb = useAlphaSnackbar();
  const tableSearch = useSearch();

  const searchParams: TSearchParams = createSearchParams(
    parseBeneStatusFromTabIndex(selectedTabIndex),
    tableSearch.skip,
  );

  const getBeneficiary = (
    beneficiary: TBeneficiaryApproved | TBeneficiaryPending,
  ) => tableSearch.items?.items.records
    .find((record: any) => record.id === beneficiary.id);

  const calculateTabFromUrl = () => {
    const parsedQueryString = queryString.decode(location.search.replace('?', ''));
    switch (parsedQueryString.tab) {
      case 'approved':
        return 0;
      case 'pending':
        return 1;
      case 'rejected':
        return 2;
      case 'draft':
        return 3;
      case 'batch':
        return 4;
      default:
        return 0;
    }
  };

  const handleApprovedTableRowClick = (
    beneficiary: BeneficiaryDto,
    approved: boolean,
  ) => {
    setSelectedBeneficiary({
      beneficiary: getBeneficiary(beneficiary) as BeneficiaryDto,
      approved,
    });
  };

  const handlePendingTableRowClick = (
    beneficiary: BeneficiaryDto,
    approved: boolean,
  ) => {
    setSelectedBeneficiary({
      beneficiary: getBeneficiary(beneficiary) as BeneficiaryDto,
      approved,
    });
  };

  const handleRejectTableRowClick = (
    beneficiary: TBeneficiaryPending,
    approved: boolean,
  ) => {
    setSelectedBeneficiary({
      beneficiary: getBeneficiary(beneficiary) as TBeneficiaryPending,
      approved,
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOnRejectionClick = async () => {
    setLoading(true);
    try {
      await BeneficiariesService.rejectBeneficiary(
        selectedBeneficiary!.beneficiary,
      );
      setTimeout(async () => {
        await tableSearch.handleInitialSearch(searchParams);
      }, 2000);
      setModalOpen(false);
      setSelectedBeneficiary(undefined);
      sb.trigger(t('beneficiary_successfully_rejected'), 'success');
      logEvent({ action: 'Successfully rejected beneficiary' });
    } catch (e) {
      sb.trigger(e.message || e.toString() || t('something_went_wrong_rejecting_your_bene'));
      logError({ action: 'Error rejecting beneficiary', error: e });
    } finally {
      setLoading(false);
    }
  };

  const handleOnDeleteClick = async () => {
    setLoading(true);
    try {
      if (!beneToDelete) throw Error();

      await BeneficiariesService.deleteBeneficiary(
        beneToDelete.beneficiary as TBeneficiaryApproved,
      );

      // Reload Items
      setTimeout(async () => {
        await tableSearch.handleInitialSearch(searchParams);
      }, 2000);

      setModalOpen(false);
      setSelectedBeneficiary(undefined);
      sb.trigger(t('bene_successfully_deleted'), 'success');
      logEvent({ action: 'Successfully deleted beneficiary' });
    } catch (e) {
      sb.trigger(e.message || e.toString() || t('something_went_wrong_deleteting_your_bene'));
      logError({ action: 'Error deleting beneficiary', error: e });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    tableSearch.setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.searchtext = e.target.value;
      searchParams.queryParams.skip = 0;
    }
    await tableSearch.handleNewSearch(searchParams);
  };

  return {
    loading,
    modalOpen,
    rejectLoading,
    authyState,
    location,
    searchParams,
    tableSearch,
    selectedBeneficiary,
    beneToDelete,
    setBeneToDelete,
    setSelectedBeneficiary,
    calculateTabFromUrl,
    setModalOpen,
    handleOnDeleteClick,
    handleApprovedTableRowClick,
    handleOnRejectionClick,
    handleCloseModal,
    handlePendingTableRowClick,
    handleRejectTableRowClick,
    handleInputChange,
  };
};

export default useBody;
