import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ palette }) => ({
    cutOffTime: {
      display: 'flex',
      alignItems: 'center',
    },
    crossIcon: {
      fontSize: 12,
      color: 'rgb(185, 86, 86)',
      marginRight: 10,
    },
    tickIcon: {
      fontSize: 12,
      color: palette.primary.main,
      marginRight: 10,
    },
    timeZone: {
      fontSize: 11,
      textTransform: 'uppercase',
    },
  }), { name: 'CutOffDetails' },
);

export default useStyles;
