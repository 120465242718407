import React from 'react';

import {
  PaymentStatus,
} from '@alpha/payments-dtos';
import { Box } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status/Status';
import { TStatusVariants } from '@alpha/ui-lib/ui/Status/Status.interfaces';

import { flowToBatchStatusMapping } from '../../../../../utils/batchStatus.helpers';

import useStyles from './TitleStatus.styles';

interface IProps {
  noOfApprovals: number | undefined;
  approvalsRequired: number | undefined;
  paymentStatus: PaymentStatus;
  requiresFx: boolean;
}

const TitleStatus: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const {
    noOfApprovals, approvalsRequired, paymentStatus, requiresFx,
  } = props;

  const getClientFriendlyBatchStatus = (
    backendBatchStatus: PaymentStatus,
  ) => {
    switch (true) {
      case backendBatchStatus === PaymentStatus.PROCESSING:
        return 'In Progress';
      case backendBatchStatus === PaymentStatus.COMPLETED:
        return 'complete';
      case backendBatchStatus === PaymentStatus.CLIENT_APPROVED && requiresFx:
        return 'pending fx';
      case backendBatchStatus === PaymentStatus.CLIENT_REJECTED:
        return 'rejected';
      case flowToBatchStatusMapping.uploader.includes(backendBatchStatus):
        return 'Draft';
      case flowToBatchStatusMapping.approver.includes(backendBatchStatus):
        if (backendBatchStatus === PaymentStatus.CLIENT_REJECTED) {
          return 'rejected';
        }
        if (
          requiresFx
          && approvalsRequired
          && noOfApprovals === approvalsRequired
        ) {
          return 'awaiting funding';
        }
        if (approvalsRequired && noOfApprovals === approvalsRequired) {
          return 'awaiting release';
        }
        return 'pending approval';
      default:
        return backendBatchStatus;
    }
  };

  const handleApprovalStatusVariant = (): TStatusVariants => {
    if (approvalsRequired && noOfApprovals === approvalsRequired) {
      return 'success';
    }
    return 'default';
  };

  const handleBatchStatusVariant = (
    backendBatchStatus: PaymentStatus,
  ) => {
    switch (true) {
      case backendBatchStatus === PaymentStatus.CLIENT_APPROVED && requiresFx:
        return 'orange';
      case backendBatchStatus === PaymentStatus.CLIENT_REJECTED:
        return 'error';
      case backendBatchStatus === PaymentStatus.PROCESSING:
        return 'warning';
      case backendBatchStatus === PaymentStatus.COMPLETED:
        return 'success';
      case flowToBatchStatusMapping.approver.includes(backendBatchStatus):
        return 'info';
      default:
        return 'default';
    }
  };

  return (
    <Box className={classes.titleStatusWrapper}>
      {flowToBatchStatusMapping.approver.includes(paymentStatus) && (
        <Status
          className={classes.titleStatus}
          variant={handleApprovalStatusVariant()}
        >
          {`${noOfApprovals}/${approvalsRequired}`}
        </Status>
      )}
      <Status
        className={classes.titleStatus}
        variant={handleBatchStatusVariant(paymentStatus)}
      >
        {getClientFriendlyBatchStatus(paymentStatus)}
      </Status>
    </Box>
  );
};

export default TitleStatus;
