import React from 'react';
import t from 'utils/translationHelper';

import { Approval } from '@alpha/currency-accounts-types';
import { Status } from '@alpha/ui-lib/ui/Status/Status';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';

import useStyles from '../../FxTradeTable.styles';

import ApproverContent from './ApproverContent/ApproverContent';

interface IProps {
  noOfApprovals?: number;
  approvalsRequired?: number;
  uploadedDate?: string;
  uploadedBy?: string;
  approvedBy?: Approval[];
}

const statusMessage = (noOfApprovals?: number, approvalsRequired?: number): string => {

  if (approvalsRequired) {
    if (noOfApprovals) {
      return `${noOfApprovals}/${approvalsRequired}`;
    }
    return `0/${approvalsRequired}`;
  }
  return t('na');
};

const NoOfApprovers: React.FC<IProps> = (props: IProps) => {
  const {
    noOfApprovals, approvalsRequired, uploadedBy, uploadedDate, approvedBy,
  } = props;
  const classes = useStyles();


  return (
    <div className={classes.noOfApprovers}>
      <APTooltip
        data-testid="no-of-approvals"
        title={
          uploadedBy
            && uploadedDate
            && approvedBy
            ? (
              <ApproverContent
                uploadedBy={uploadedBy}
                uploadedDate={uploadedDate}
                approvedBy={approvedBy}
              />
            ) : t('unable_to_retrieve_this_information')
        }
      >
        <div>
          <Status
            variant={approvalsRequired && noOfApprovals === approvalsRequired ? 'success' : 'default'}
          >
            {statusMessage(noOfApprovals, approvalsRequired)}
          </Status>
        </div>
      </APTooltip>
    </div>
  );
};

export default NoOfApprovers;
