import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import useAuthorization from 'hooks/useAuthorization';
import { UserRole } from 'models/user';
import { formatNumber, getCurrencySymbol } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import t from 'utils/translationHelper';

import {
  ActionButton,
} from '@alpha/ui-lib/ui/button';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { StyledKeyValueTable } from '@alpha/ui-lib/ui/table';

import { TransferConfirmationData } from '../IATDrawerContainer';

import useStyles from './index.styles';

interface IProps {
  values: TransferConfirmationData,
  header?: string;
  handleFormSubmit: () => Promise<void>;
  handleSetSection: (input: string) => void;
  sendEmailNotification: boolean;
  setSendEmailNotification: Dispatch<SetStateAction<boolean>>;
  authyStateOpen: boolean;
}

const IATConfirmation: React.FC<IProps> = (
  {
    values,
    header,
    handleFormSubmit,
    handleSetSection,
    sendEmailNotification,
    setSendEmailNotification,
    authyStateOpen,
  }: IProps,
) => {
  const { authorized } = useAuthorization([[UserRole.IAT_APPROVER_OWN]]);

  const styles = useStyles();
  const mappedData = [
    { key: t('credit_account_name'), value: values.fundingCurrencyAccount.accountName || '-' },
    {
      key: t('amount_currency'), value: `${getCurrencySymbol(values.instructedCurrencyCode)}${formatNumber(values.instructedAmount, 2)}` || 0, boldValue: true,
    },
    { key: t('date'), value: formatIsoDate(values.valueDate) || '-' },
    { key: t('reference'), value: values.reference || '-' },
  ];
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

  useEffect(() => {
    if (authyStateOpen === true) {
      setIsLoadingSubmission(true);
    } else {
      setIsLoadingSubmission(false);
    }
  }, [authyStateOpen]);

  return (
    <>
      <BaseDrawer.Header headerTitle={header || t('inter_account_transfer')} headerSubtitle="" style={{ fontSize: '22px!important' }} />
      <div style={{ margin: '30px 0' }}>
        <BaseDrawer.LineBreak />
      </div>
      <BaseDrawer.Body>
        <Box className={styles.drawerBody}>
          <div>
            {t('inter_transfer_details')}
            <Box className={styles.IATDetailsContainer}>
              <StyledKeyValueTable
                testId="IAT-confirmation-table"
                data={mappedData}
                style={{ borderBottom: 'none' }}
              />
            </Box>
            <div style={{ margin: '30px 0', width: '100%' }}>
              <BaseDrawer.LineBreak />
            </div>
            <Box>
              {!authorized && (
                <div style={{
                  position: 'relative', marginTop: 'auto',
                }}
                >
                  <Checkbox
                    testId="send-email-notifcation-transfer"
                    label={<Typography variant="subtitle1">{t('send_an_email_notification_to_all_approvers')}</Typography>}
                    checked={sendEmailNotification}
                    onChange={(event) => { setSendEmailNotification(event.target.checked); }}
                  />
                </div>
              )}
            </Box>
          </div>
          <Box className={styles.buttonsContainer}>
            <ActionButton
              size="medium"
              style={{ background: '#F7F7F7', color: '#212529' }}
              onClick={() => {
                handleSetSection('InputTransfer');
              }}
            >
              {t('back_to_transfers')}
            </ActionButton>
            <ActionButton
              onClick={() => {
                handleFormSubmit();
                setIsLoadingSubmission(true);
              }}
              size="medium"
              disabled={isLoadingSubmission}
            >
              {authorized ? t('confirm_and_send_transfer') : t('submit_for_approval')}
            </ActionButton>
          </Box>
        </Box>
      </BaseDrawer.Body>
    </>
  );
};

export default IATConfirmation;
