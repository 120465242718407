import { ReactText } from 'react';
import {
  call, delay, put, takeEvery,
} from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';

import { PageType } from '../../hooks/useReportsPolling';
import { TReportLinkResponse } from '../../models/currencyAccounts';
import instance from '../../services/Axios/instance';

import { Actions } from './actions';
import { actions } from './reducer';

const getReportLink = async ({
  executionArn,
}: any): Promise<TReportLinkResponse | undefined> => {
  const response = await instance.get(
    `/reports/${executionArn}`,
  );
  return response.data;
};

const getSynchronousReport = async ({
  downloadLink,
}: { downloadLink: string }): Promise<string> => {
  const response = await instance.get(downloadLink);
  return response.data;
};

export function* pollReportGeneration({
  payload,
}: PayloadAction<{ executionArn: string; id: string, snackId: ReactText, pageType: PageType }>) {
  try {
    const response: TReportLinkResponse = yield call<any>(getReportLink, {
      executionArn: payload.executionArn,
    });
    const { completed, error, downloadLink } = response;
    if (completed === false) {
      yield delay(2000);
      yield put({ type: Actions.INITIATE_POLLING, payload });
    } else if (completed) {
      if (downloadLink) {
        yield put(actions.enqueue({ id: payload.snackId, downloadLink, status: 'COMPLETED' }));
      } else if (error) {
        yield put(actions.enqueue({ id: payload.snackId, status: 'ERROR' }));
      }
    }
  } catch (e) {
    yield put(actions.enqueue({ id: payload.snackId, status: 'ERROR' }));
  }
}

export function* pollSynchronousReportGeneration({
  payload,
}: PayloadAction<{
  id: string,
  downloadLink: string,
  snackId: ReactText,
}>) {
  try {
    const { downloadLink } = yield call<any>(getSynchronousReport, {
      downloadLink: payload.downloadLink,
    });
    if (downloadLink) {
      yield put(actions.enqueue({ id: payload.snackId, downloadLink, status: 'COMPLETED' }));
    } else {
      throw Error();
    }
  } catch {
    yield put(actions.enqueue({ id: payload.snackId, status: 'ERROR' }));
  }
}

export function* generatePdfSaga() {
  yield takeEvery(Actions.INITIATE_POLLING, pollReportGeneration);
  yield takeEvery(Actions.INITIATE_SYNCHRONOUS_DOWNLOAD, pollSynchronousReportGeneration);
}
