import React, { ReactNode, SyntheticEvent } from 'react';

import useLog from '../../../hooks/useLog';

interface IGuideVideoProps {
  name:string;
  url: string;
  poster: string;
  trackUrl?: string;
  children?: ReactNode;
}

const GuideVideo: React.FC<IGuideVideoProps> = ({
  children,
  name,
  url,
  poster,
  trackUrl,
}:IGuideVideoProps) => {
  const { logEvent } = useLog();

  const onPlayHandler = (): void => {
    logEvent({ action: 'Play user guide video', detail: { name } });
  };

  const onPauseHandler = (event: SyntheticEvent<HTMLMediaElement>): void => {
    const second = (event.target as HTMLMediaElement).currentTime || undefined;
    logEvent({ action: 'Paused user guide video', detail: { name, second } });
  };

  const onSeekedHandler = (event: SyntheticEvent<HTMLMediaElement>): void => {
    const second = (event.target as HTMLMediaElement).currentTime || undefined;
    logEvent({ action: 'Seeked user guide video', detail: { name, second } });
  };

  return (
    <video
      controls
      width="100%"
      preload="none"
      poster={poster}
      onPlay={onPlayHandler}
      onPause={onPauseHandler}
      onSeeked={onSeekedHandler}
      data-video-name={name}
    >
      <track
        label="English"
        kind="captions"
        srcLang="en"
        src={trackUrl}
        default
      />
      <source src={url} type="video/mp4" />
      {children}
    </video>
  );
};

export default GuideVideo;
