/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import IATInfoDrawer from 'components/Drawer/IATDrawer/IATInfo/IATInfo';
import { cutString } from 'components/Molecules/Payments/ApprovalDrawer/ApprovalSummary';
import RejectTransferModal from 'components/Molecules/Transfer/RejectTransferModal';
import Search, { TFilterChipData } from 'components/Organisms/Search';
import SearchTable from 'components/Organisms/SearchTable';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import { useAuthorization } from 'hooks/useAuthorization';
import { startCase } from 'lodash';
import { UserRole } from 'models/user';
import t from 'utils/translationHelper';
import { useDispatch, useSelector } from 'react-redux';
import browserHistory from 'services/history/browserHistory';
import { TStore } from 'store';
import { initiateTransfer } from 'store/authy/actions';
import { TAuthyState } from 'store/authy/reducer';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapTransferStatusDisplay from 'utils/transfers/mapTransferStatusToDisplay';

import { Approver } from '@alpha/auth-dtos';
import { CurrencyAccountTransferDto, CurrencyAccountTransferStatus } from '@alpha/currency-accounts-dtos';
import { Button } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { Status } from '@alpha/ui-lib/ui/Status';

import TransferActionDropDown from '../FxTradeTable/DisplayTable/ActionDropDown/TransferActionDropDown';
import NoOfApprovers from '../FxTradeTable/DisplayTable/IATNoOfApprovers/NoOfApprovers';
import TradeLink from '../FxTradeTable/DisplayTable/TradeLink/TradeLink';
import { TFilter } from '../FxTradeTable/FxTradeTable';

import useStyles from './TransferTable.styles';
import useTransferTable, { TTransferSearchFilterTypes } from './useTransferTable';

interface ITransferTable {
  emptyTitle?: string,
  emptySubtitle?: string,
  testId?: string,
  filters?: TFilter[],
  filtersByField?: TTransferSearchFilterTypes,
  handleRemoveFilterItem?(item: TFilter): void;
}

const TransferTable: React.FC<ITransferTable> = ({
  emptyTitle, emptySubtitle, testId,
  filters,
  filtersByField,
  handleRemoveFilterItem,
}: ITransferTable) => {

  const classes = useStyles();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const {
    table,
    handleInputChange,
    handleTableSortClick,
    columns,
    reloadTableData,
    searchParams,
  } = useTransferTable(filtersByField);

  const [filterChips, setFilterChips] = useState<TFilterChipData[]>([]);
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState<CurrencyAccountTransferDto>();
  const [transferToReject, setTransferToReject] = useState<CurrencyAccountTransferDto>();
  const [currentOptions, setCurrentOptions] = useState<Approver[]>([]);
  const [textValue, setTextValue] = useState('');
  const [approvingTransfer, setApprovingTransfer] = useState<CurrencyAccountTransferDto>();
  const dispatch = useDispatch();

  const { authorized: isIATApproverOwn } = useAuthorization(
    [[UserRole.IAT_APPROVER_OWN]],
  );

  const sb = useAlphaSnackbar();

  const handleTransferClick = (transfer: CurrencyAccountTransferDto) => {
    setSelectedTransfer(transfer);
  };

  const handleApproveTransfer = (transfer: CurrencyAccountTransferDto) => {
    if (transfer) {
      dispatch(initiateTransfer({
        type: 'TRANSFER_APPROVE',
        approverOwn: isIATApproverOwn,
        transferId: transfer.id,
      }));
    }
    setApprovingTransfer(transfer);
  };

  const handleRejectTransfer = (transfer: CurrencyAccountTransferDto) => {
    setTransferToReject(transfer);
  };

  const handleTradeLinkClick = (tradeId: string) => {
    browserHistory.push(`/app/trades/${tradeId}`);
  };

  const handleRemoveFilter = (item: TFilterChipData): void => {
    const { field, value } = item;
    if (handleRemoveFilterItem) { handleRemoveFilterItem({ field, value }); }
  };

  const mapFiltersToDisplay = (filtersToMap:
    TFilter[]): TFilterChipData[] => filtersToMap.map((filter) => {
      let filterValue = filter.value as string;
      switch (filter.field) {
        case 'transferStatus':
          filterValue = startCase(mapTransferStatusDisplay(
            filter.value as CurrencyAccountTransferStatus,
          ).text.toLowerCase());
          break;
        case 'transferValueStartDate':
        case 'transferValueEndDate':
          filterValue = formatIsoDate(filter.value);
          break;
        default:
          filterValue = filter.value;
          break;
      }

      return {
        label: (
          <span>
            <b>
              {t(startCase(filter.field))}
              :
            </b>
            {' '}
            {t(filterValue)}
          </span>),
        value: filter.value,
        field: filter.field,
      };
    });

  useEffect(() => {
    setFilterChips(mapFiltersToDisplay(filters || []));
  }, [filters]);

  useEffect(() => {
    if (
      authyState.type?.type === 'TRANSFER_APPROVE'
      && authyState.status === 'SUCCESS'
    ) {
      if (approvingTransfer) sb.trigger(t('your_transfer_was_successfully_approved'), 'success');
      setTimeout(() => {
        reloadTableData();
        setSelectedTransfer(undefined);
      }, 1000);
    }
  }, [authyState.status, authyState.type, browserHistory.push, selectedTransfer]);

  const generateTableData = () => (table?.items?.items?.records as CurrencyAccountTransferDto[])?.map((transfer) => {
    const debitingAmount = transfer.debitingAmount || transfer.trade?.buyAmount;
    const fundingAmount = transfer.fundingAmount || transfer.trade?.soldAmount;
    return {
      ...transfer,
      contractNumber: (
        <Button
          className={classes.transferTableButton}
          disableRipple
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            if (handleTransferClick) { handleTransferClick(transfer); }
          }}
        >
          {transfer?.contractNumber || '-'}
        </Button>),
      transferStatus: (
        transfer.transferStatus && (
          <Status
            className={classes.status}
            variant={mapTransferStatusDisplay(transfer.transferStatus).variant}
          >
            {t(mapTransferStatusDisplay(transfer.transferStatus).text) || '-'}
          </Status>
        )),
      reference: (transfer.reference ? cutString(transfer.reference, 50) : '-'),
      approvals: (transfer && transfer.approvalInfo
        && (
          <NoOfApprovers
            uploadedBy={transfer.submittedBy}
            uploadedDate={transfer.submittedDate}
            noOfApprovals={transfer.approvalInfo.approvals?.length}
            approvedBy={transfer.approvalInfo.approvals || []}
            approvalsRequired={transfer.approvalInfo?.approvalsRequired?.reduce((sum, curr) => sum + curr.number, 0)}
          />
        )
      ) || '-',
      debitingAccountName: (
        <div className={classes.currencyAccount}>
          <div className={classes.currencyAccountName}>
            {transfer.debitingCurrencyAccountName}
          </div>
          <div className={classes.currencyFlag}>
            <Flag code={transfer.debitingCurrencyCode} size="sm" />
          </div>
          <div className={classes.currencyCode}>
            {' '}
            {transfer.debitingCurrencyCode}
          </div>
        </div>),
      fundingAccountName: (
        <div className={classes.currencyAccount}>
          <div className={classes.currencyAccountName}>
            {transfer.fundingCurrencyAccountName}
          </div>
          <div className={classes.currencyFlag}>
            <Flag code={transfer.fundingCurrencyCode} size="sm" />
          </div>
          <div className={classes.currencyCode}>
            {' '}
            {transfer.fundingCurrencyCode}
          </div>
        </div>
      ),
      rate: (
        <span className={classes.tableLink}>
          {transfer.trade?.rate ? transfer.trade?.rate?.toFixed(4) : t('na')}
        </span>),
      valueDate: formatIsoDate(transfer.valueDate),
      tradeLink: (transfer.tradeId
        && (
          <TradeLink
            tradeContractNumber={transfer.trade?.contractNumber || 'TBD'}
            tradeSellCurrency={transfer.trade?.soldCurrencyCode || ''}
            tradeId={transfer.tradeId}
            handleTradeLinkClick={handleTradeLinkClick}
          />
        )
      ) || '',
      action: (
        <div className={classes.action}>
          <TransferActionDropDown
            transfer={transfer}
            setDisplayMultiSelect={setDisplayMultiSelect}
            displayMultiSelect={displayMultiSelect}
            handleReloadTransferTable={reloadTableData}
            setSelectedTransfer={setSelectedTransfer}
            handleApproveTransfer={handleApproveTransfer}
            handleRejectTransfer={handleRejectTransfer}
            currentOptions={currentOptions}
            setCurrentOptions={setCurrentOptions}
            setTextValue={setTextValue}
          />
        </div>
      ),
      debitingAmount: (
        <div className={classes.amount}>
          <div>{debitingAmount ? formatNumber(debitingAmount || 0, 2) : t('na')}</div>
        </div>),
      fundingAmount: (
        <div className={classes.amount}>
          <div>{fundingAmount ? formatNumber(fundingAmount || 0, 2) : t('na')}</div>
        </div>),
    };
  });

  return (
    <div data-testid="trades-loader-component" className={classes.tableWrapper}>
      <div style={{ display: 'flex', flexGrow: '1' }}>
        <Search
          testId="search-transfers"
          value={table.searchText}
          totalItems={table.items?.items.total || 0}
          placeholder={t('search_by_transfer_id_or_reference')}
          onChange={handleInputChange}
          filters={filterChips}
          loading={table.loading}
          handleRemoveFilter={handleRemoveFilter}
          className={classes.transferSearch}
        />
      </div>
      <SearchTable
        table={{
          columns,
          data: generateTableData(),
          activeColumn: table.sortBy,
          sortOrder: table.sortOrder,
          dataTestId: testId || '',
          keyColumn: 'id',
          totalRowNumber: table.items?.total || 0,
          handleTableSortClick,
          skip: table.skip,
        }}
        loading={table.loading}
        pagination={{
          handleNext: () => table.handleNextPage(searchParams),
          handlePrevious: () => table.handlePreviousPage(searchParams),
          hasNext: Boolean(table.items?.hasNext),
          hasPrevious: Boolean(table.items?.hasPrevious),
        }}
        emptyTable={{
          title: emptyTitle || t('no_transfers'),
          subtitle: emptySubtitle || t('you_currently_do_not_have_any_matching_transfers'),
        }}
      />
      {selectedTransfer ? (
        <IATInfoDrawer
          open={Boolean(selectedTransfer)}
          selectedTransfer={selectedTransfer}
          handleDrawerClose={() => {
            setSelectedTransfer(undefined);
          }}
        />
      ) : null}
      <RejectTransferModal
        open={Boolean(transferToReject)}
        handleClose={() => { setTransferToReject(undefined); }}
        handleRejected={(rejectedIds) => {
          setTimeout(() => {
            reloadTableData();
          }, 1000);
        }}
        transfer={transferToReject}
      />
    </div>
  );
};

export default TransferTable;
