/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import pushNotificationIcon from 'assets/pushNotification.png';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import { useDispatch } from 'react-redux';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import IATService from 'services/IAT/IAT.service';
import PaymentsService from 'services/Payments/payments.service';
import { actions, TAuthyType } from 'store/authy/reducer';
import t from 'utils/translationHelper';

import { makeStyles } from '@alpha/ui-lib/theme';
import { Typography } from '@alpha/ui-lib/ui/Typography';

const useStyles = makeStyles(
  () => ({
    container: {
      marginBottom: '32px',
      '& > img': {
        marginBottom: '60px',
        width: '70px',
        height: 'auto',
      },
      '& br': {
        lineHeight: '24px',
      },
    },
    footer: {},
  }),
  { name: 'Success' },
);
interface IProps {
  authyType: TAuthyType
  approvalRequestId: string
  dynamicLinkingId: string
  paymentIds: string[]
  approveOwn?: boolean
  drawdownId?: string
  tradeId?: string
  transferId?: string
  firstPartyFlow?: boolean;
}

export const updatePollingIdToLocalStorage = (id: string): void => localStorage.setItem('alpha-polling-id', id);
const getPollingIdFromLocalStorage = (): string | null => localStorage.getItem('alpha-polling-id');

// eslint-disable-next-line max-lines-per-function
const PushNotification: React.FC<IProps> = ({
  authyType,
  paymentIds,
  approvalRequestId,
  dynamicLinkingId,
  approveOwn = false,
  drawdownId,
  tradeId,
  transferId,
  firstPartyFlow,
}: IProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const approveEndpoint = async (): Promise<any> => {
    switch (authyType) {
      case 'PAYMENT_APPROVE': {
        const response = await PaymentsService.approvePayment(
          { paymentIds, approvalRequestId, dynamicLinkingId },
        );

        if (response.unapprovablePayments) {
          dispatch(actions.updateAuthyExtraInfo(response));
        }

        return response;
      }

      case 'DRAWDOWN': {
        const approveResponse = await FXTradeService.postSubmitDrawdownAndApprovePayments({
          drawdownId: drawdownId || '',
          paymentIds,
          dynamicLinkingId,
          approvalRequestId,
          softToken: false,
        }, approveOwn, firstPartyFlow);

        if (approveResponse?.success || !approveResponse?.pending) {
          dispatch(actions.updateAuthyExtraInfo(approveResponse));
        }

        return approveResponse;
      }
      case 'TRADE': {
        if (tradeId) {
          const response = await FXTradeService.submitTrade(
            {
              tradeId,
              approvalRequestId,
              dynamicLinkingId,
              softToken: false,
            },
          );

          if (response?.success || !response?.pending) {
            dispatch(actions.updateAuthyExtraInfo(response));
          }

          return response;
        }
        return null;
      }
      case 'PAYMENTS': {
        const response = await PaymentsService.postSubmitPayment(
          {
            paymentIds, approvalRequestId, dynamicLinkingId, softToken: false,
          },
          firstPartyFlow,
        );

        dispatch(actions.updateAuthyExtraInfo(response));
        if (response && approveOwn && response.success && !response.pending) {
          await PaymentsService.approvePayment({ paymentIds, approvalRequestId, dynamicLinkingId }, firstPartyFlow);
        }
        return response;
      }
      case 'TRADE_PAD_APPROVE': {
        if (tradeId) {
          const response = await FXTradeService.putSubmitTradePADApproval(
            tradeId,
            {
              approvalRequestId,
              dynamicLinkingId,
              softToken: false,
            },
          );

          if (response?.success || !response?.pending) {
            dispatch(actions.updateAuthyExtraInfo(response));
          }

          return response;
        }
        return null;
      }
      case 'DRAWDOWN_PAD_APPROVE': {
        if (drawdownId) {
          const response = await FXTradeService.putSubmitDrawdownPADApproval(
            drawdownId,
            {
              approvalRequestId,
              dynamicLinkingId,
              softToken: false,
            },
          );

          if (response?.success || !response?.pending) {
            dispatch(actions.updateAuthyExtraInfo(response));
          }
          return response;
        }
        return null;
      }
      case 'TRANSFER_APPROVE': {
        if (transferId) {
          const response = await IATService.approveTransfer(
            {
              approvalRequestId,
              dynamicLinkingId,
              softToken: false,
            },
            transferId,
          );

          if (response?.success || !response?.pending) {
            dispatch(actions.updateAuthyExtraInfo(response));
          }
          return response;
        }
        return null;
      }
      default:
        throw new Error('Unsupported Auth type');
    }
  };

  const getApproveEndpointStatus = async (pollingId: string) => {
    let errorCount = 0;
    let loopCount;
    const maxErrorCount = 3;
    const maxLoopCount = 50;

    for (loopCount = 1; loopCount < maxLoopCount; loopCount += 1) {
      try {
        const { success, message, pending } = await approveEndpoint();
        if (success) {
          dispatch(actions.updateStatus('SUCCESS'));
          setTimeout(() => {
            dispatch(actions.reset());
          }, 1000);
          break;
        } else if (!pending) {
          sb.trigger(t(message) || t('error_approving_via_authy'));
          logError({ action: 'Error approving via Authy' });
          dispatch(actions.updateStatus('ERROR'));
          setTimeout(() => dispatch(actions.reset()), 5000);
          break;
        }
      } catch (error) {
        errorCount += 1;
        sb.trigger(error?.message || error.response?.data?.error || t('error_approving_via_authy'));
        logError({ action: 'Error approving via Authy', error });
      }
      const activePollId = getPollingIdFromLocalStorage();
      if (activePollId !== pollingId) {
        break;
      }

      if (errorCount === maxErrorCount) {
        dispatch(actions.reset());
        break;
      }
      await new Promise<number>((resolve) => setTimeout(resolve, 3500));
    }

    if (loopCount === maxLoopCount) {
      sb.trigger(t('approval_time_out'));
      logError({ action: 'Approval timed out' });
      dispatch(actions.updateStatus('ERROR'));
      setTimeout(() => dispatch(actions.reset()), 5000);
    }
  };

  useEffect(() => {
    updatePollingIdToLocalStorage(approvalRequestId);
    getApproveEndpointStatus(approvalRequestId);
  }, [approvalRequestId]);

  return (
    <div className={styles.container}>
      <img src={pushNotificationIcon} alt="Push Notification Icon" />
      <Typography variant="subtitle1">
        {t('a_push_notification_has_been_sent_to_your_device')}
        <br />
        {t('approve_the_push_notification_on_your_device_to_continue')}
        <br />
        <br />
        {t('it_might_take_a_few_seconds_for_your_approval_to_get_updated')}
      </Typography>
    </div>
  );
};

export default PushNotification;
