import React, { useEffect, useState } from 'react';
import Authorization from 'hocs/Authorization';
import useAuthorization from 'hooks/useAuthorization';
import useLog from 'hooks/useLog';
import { UserRole } from 'models/user';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import t from 'utils/translationHelper';

import { FeatureFlag } from '@alpha/feature-flags';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Button } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from '../index.styles';

type ButtonMenuOptions = {
  content: string,
  icon: IconProp,
  onClick: () => void,
  disableHoverEffect?: boolean,
  disableClick?: boolean,
  underline?: boolean,
}

interface PaymentButtonProps {
  menuOptions: {
    paymentFileMenuOptions: ButtonMenuOptions[],
    IATMenuOptions: ButtonMenuOptions[],
    newPaymentMenuOptions: ButtonMenuOptions[],
  }
  setOpenIATDrawer: React.Dispatch<React.SetStateAction<boolean>>,
  roles?: string[],
}

const PaymentButton: React.FC<PaymentButtonProps> = ({
  menuOptions,
  setOpenIATDrawer,
  roles,
}) => {
  const { paymentFileMenuOptions, IATMenuOptions, newPaymentMenuOptions } = menuOptions;
  const { logEvent } = useLog();
  const flags = useSelector<TStore, FeatureFlag[]>((state) => state.featureFlags.flags);
  const [IATAuthorised, setIATAuthorised] = useState(false);
  const { authorized: isPaymentFileInputter } = useAuthorization(
    [[UserRole.PAYMENT_FILE_INPUTTER]],
  );

  const classes = useStyles();
  const [openPaymentMenu, setOpenPaymentMenu] = useState<null | HTMLElement>(null);

  const setIfAuthorisedForIAT = (userRoles: string[]) => {
    setIATAuthorised(userRoles.includes(UserRole.IAT_INPUTTER));
  };

  useEffect(() => {
    if (roles && flags) setIfAuthorisedForIAT(roles);
  }, [roles, flags]);

  return (
    <>
      {isPaymentFileInputter ? (
        <div className={classes.paymentUploadActionButtonWrapper}>
          <APMenu
            open={Boolean(openPaymentMenu)}
            anchorEl={openPaymentMenu}
            setOpen={setOpenPaymentMenu}
            disableBackdropClick={false}
            hideIcon
            buttonProps={{
              disableFocusRipple: true,
              onMouseDownCapture: (e) => { // Do not want to override onClick
                logEvent({ action: 'Quick action - New Payment (Payment File Inputter)' });
              },
            }}
            buttonContent={(
              <>
                <div>
                  <FontAwesomeIcon icon={faMoneyBill as IconProp} />
                </div>
                {' '}
                <div>{t('new_payment~')}</div>
              </>
            )}
          >
            {!IATAuthorised ? paymentFileMenuOptions.map((
              item,
            ) => <MenuDropdownItem setOpen={setOpenPaymentMenu}>{item}</MenuDropdownItem>)
              : [...paymentFileMenuOptions, ...IATMenuOptions]
                .map((item) => (
                  <MenuDropdownItem setOpen={setOpenPaymentMenu}>
                    {item}
                  </MenuDropdownItem>
                ))}
          </APMenu>
        </div>
      )
        : (
          <Authorization requiredRoles={[[UserRole.PAYMENTS_INPUTTER]]}>
            <div className={classes.paymentUploadActionButtonWrapper}>
              <APMenu
                open={Boolean(openPaymentMenu)}
                anchorEl={openPaymentMenu}
                setOpen={setOpenPaymentMenu}
                disableBackdropClick={false}
                hideIcon
                buttonProps={{
                  disableFocusRipple: true,
                  onMouseDownCapture: (e) => { // Do not want to override onClick
                    logEvent({ action: 'Quick action - New Payment' });
                  },
                }}
                buttonContent={(
                  <>
                    <div>
                      <FontAwesomeIcon icon={faMoneyBill as IconProp} />
                    </div>
                    {' '}
                    <div>{t('new_payment')}</div>
                  </>
                )}
              >
                {!IATAuthorised ? newPaymentMenuOptions.map((
                  item,
                ) => <MenuDropdownItem setOpen={setOpenPaymentMenu}>{item}</MenuDropdownItem>)
                  : [...newPaymentMenuOptions, ...IATMenuOptions]
                    .map((item) => (
                      <MenuDropdownItem setOpen={setOpenPaymentMenu}>
                        {item}
                      </MenuDropdownItem>
                    ))}
              </APMenu>
            </div>
          </Authorization>
        )}
      {!isPaymentFileInputter && !UserRole.PAYMENTS_INPUTTER && IATAuthorised && (
        <Button
          disableFocusRipple
          className={classes.actionButton}
          onClick={() => {
            setOpenIATDrawer(true);
            logEvent({ action: 'Quick action - Click Internal Account Transfer' });
          }}
        >
          {' '}
          <FontAwesomeIcon icon={faMoneyBill as IconProp} />
          <br />
          {t('new_payment')}
        </Button>
      )}
    </>
  );
};

export default PaymentButton;
