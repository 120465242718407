import React from 'react';

import { BeneficiaryDto, BeneficiaryStatus } from '@alpha/bene-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';

import Authorization from '../../../../../hocs/Authorization';
import useBeneficiary from '../../../../../hooks/useBeneficiary';
import { UserRole } from '../../../../../models/user';
import { ModalType } from '../../Body';

import ActionButtons from './ActionButtons/ActionButtons';
import useStyles from './Body.styles';
import Details from './Details/Details';

interface IBodyProps {
  selectedBeneficiary:
  | {
    beneficiary: BeneficiaryDto | undefined;
    approved: boolean;
  } | undefined;
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>
  setCreateBeneficiaryOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  handleApprovedEditButtonClick?: (clickedBeneficiary: BeneficiaryDto) => void
  setAddressOnly?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}
const Body: React.FC<IBodyProps> = ({
  selectedBeneficiary,
  setModalOpen,
  setCreateBeneficiaryOpen,
  handleApprovedEditButtonClick,
  setAddressOnly,
}: IBodyProps) => {
  const classes = useStyles();
  const { approveBeneficiary } = useBeneficiary();
  return (
    <div className={classes.drawerBody}>
      <BaseDrawer.Body>
        <Details
          selectedBeneficiary={selectedBeneficiary}
          setCreateBeneficiaryOpen={setCreateBeneficiaryOpen}
          handleApprovedEditButtonClick={handleApprovedEditButtonClick}
          setAddressOnly={setAddressOnly}
        />
      </BaseDrawer.Body>
      {selectedBeneficiary
        && selectedBeneficiary.beneficiary
        && !selectedBeneficiary.approved
        && selectedBeneficiary.beneficiary.status !== BeneficiaryStatus.CLIENT_REJECTED
        && selectedBeneficiary.beneficiary.status !== BeneficiaryStatus.ALPHA_REJECTED
        && selectedBeneficiary.beneficiary.submittedById && (
          <Authorization
            requiredRoles={[[UserRole.BENEFICIARY_APPROVER, UserRole.BENEFICAIRY_APPROVER_OWN]]}
            resourceCreatorUserId={selectedBeneficiary.beneficiary?.submittedById}
          >
            <ActionButtons
              setModalOpen={setModalOpen}
              selectedBeneficiary={selectedBeneficiary}
              initiateApproveBeneficiary={(approverOwn: boolean) => {
                if (selectedBeneficiary?.beneficiary) {
                  approveBeneficiary({ approverOwn, batchId: selectedBeneficiary?.beneficiary?.id, type: 'BENEFICIARY' });
                }
              }}
            />
          </Authorization>
        )}
    </div>
  );
};

export default Body;
