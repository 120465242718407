/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { CurrencyPair } from 'components/International';
import SearchTable from 'components/Organisms/SearchTable';
import useAuthorization from 'hooks/useAuthorization';
import { TSearchBaseResponse, TSearchParams } from 'hooks/useSearch';
import { UserRole } from 'models/user';
import { useSelector } from 'react-redux';
import { ITableColumn, ITableData } from 'services/DatabaseServices/marker.interface';
import browserHistory from 'services/history/browserHistory';
import { TStore } from 'store';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapPaymentStatusDisplay from 'utils/payments/mapPaymentStatusDisplay';
import { shouldShowApprovalButtons } from 'utils/payments/shouldShowApprovalButtons';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';
import { Button } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';

import ActionDropDown from '../../FxTradeTable/DisplayTable/ActionDropDown/ActionDropDown';
import NoOfApprovers from '../../FxTradeTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import TradeLink from '../../FxTradeTable/DisplayTable/TradeLink/TradeLink';

import useStyles from './PaymentTable.styles';

interface IPaymentTable {
  tableRawData: TSearchBaseResponse | undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  loading: boolean;
  sortBy?: string;
  skip: number;
  sortOrder?: 'asc' | 'desc' | undefined,
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handleTableRowClick?: (e: PaymentDto) => void;
  handleTableSortClick?: (column: string) => void;
  emptyTitle?: string;
  emptySubTitle?: string;
  testId?: string,
  handleBeneficiaryButton?: (beneficiaryId: string) => void;
  selectedIds?: string[];
  setSelectedIds?: React.Dispatch<string[]>;
  setSelectedPayment?: React.Dispatch<React.SetStateAction<PaymentDto | undefined>>;
  handleReloadTable?: () => void;
  handleApprovePayments?: (payments: PaymentDto[]) => void;
  handleRejectPayments?: (payments: PaymentDto[]) => void;

}
const PaymentTable: React.FC<IPaymentTable> = (props: IPaymentTable) => {
  const {
    tableRawData,
    hasPrevious,
    hasNext,
    loading,
    sortOrder,
    sortBy,
    skip,
    handleTableRowClick,
    handleNextPage,
    handlePreviousPage,
    handleTableSortClick,
    emptyTitle,
    emptySubTitle,
    testId,
    handleBeneficiaryButton,
    setSelectedIds,
    selectedIds,
    setSelectedPayment,
    handleReloadTable,
    handleApprovePayments,
    handleRejectPayments,
  } = props;

  const classes = useStyles();
  const { authorized: canApproveOwn } = useAuthorization(
    [[UserRole.PAYMENTS_APPROVER_OWN]],
  );

  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);
  const canApproveOthers = useAuthorization([[UserRole.PAYMENTS_APPROVER]]);
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);
  const [currentOptions, setCurrentOptions] = useState<Approver[]>([]);
  const [textValue, setTextValue] = useState('');

  const handleBatchNumberClick = (e: PaymentDto) => {
    const paymentBatchId = e.batchId;
    if (paymentBatchId) {
      browserHistory.push(`/app/payments/${paymentBatchId}`);
    }
  };

  const displayColumns = () => {
    const commonColumns = [
      {
        header: t('payment_id'), accessor: 'contractNumber', sortable: true, width: 190,
      },
      {
        header: t('batch_id'), accessor: 'friendlyBatchId', sortable: true, width: 150,
      },
      { header: t('beneficiary_name'), accessor: 'beneficiaryName', sortable: true },
      {
        header: t('payment_currency'), accessor: 'debitingCurrency', sortable: true, width: 200,
      },
      {
        header: t('value_date_IT_'), accessor: 'valueDate', sortable: true, width: 150,
      },
      { header: t('payment_ref'), accessor: 'reference', sortable: true },
      {
        header: t('amount'), accessor: 'instructedAmount', sortable: true, align: 'right',
      },
      { header: t('approvals'), accessor: 'approvals', width: 20 },
      {
        header: t('status'), accessor: 'paymentStatus', sortable: true, width: 200,
      },
      {
        header: t('trade_link'), accessor: 'tradeLink', sortable: false, width: 120,
      },
      { header: t('action'), accessor: 'action', align: 'right' },
    ];

    return [
      ...commonColumns,
    ];
  };

  const generateTableData = (tableData: TSearchBaseResponse | undefined): ITableData[] => (
    tableData?.records as PaymentDto[]
  )?.map((payment) => {
    const tradeContractNumber = payment.tradeContractNumber ?? 'TBC';

    return {
      ...payment,
      debitingCurrency: (payment.debitingCurrency && (
        <CurrencyPair currencyCode={payment.debitingCurrency} />
      )) || '-',
      action: (
        <ActionDropDown
          payment={payment}
          setDisplayMultiSelect={setDisplayMultiSelect}
          displayMultiSelect={displayMultiSelect}
          setSelectedPayment={setSelectedPayment}
          handleReloadPaymentTable={handleReloadTable}
          handleApprovePayments={handleApprovePayments}
          handleRejectPayments={handleRejectPayments}
          currentOptions={currentOptions}
          setCurrentOptions={setCurrentOptions}
          setTextValue={setTextValue}
        />
      ),
      contractNumber: payment.contractNumber || '-',
      friendlyBatchId: (
        <Button
          disableRipple
          className={classes.tableButton}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            if (handleBatchNumberClick) { handleBatchNumberClick(payment); }
          }}
        >
          {payment.friendlyBatchId || '-'}
        </Button>),
      instructedAmount: <span className={classes.amount}>{[formatNumber(payment.amount, 2), payment.debitingCurrency].join(' ')}</span>,
      valueDate: formatIsoDate(payment.valueDate),
      beneficiaryName: (
        <Button
          disableRipple
          className={classes.tableButton}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            if (handleBeneficiaryButton) { handleBeneficiaryButton(payment.beneficiaryId); }
          }}
        >
          {payment.beneficiaryName || '-'}
        </Button>),
      approvals: (payment
        && (
          <NoOfApprovers
            uploadedBy={payment.uploadedBy}
            uploadedDate={payment.uploadedDate}
            noOfApprovals={payment.approvalInfo.approvals?.length}
            approvedBy={payment.approvalInfo.approvals || []}
            approvalsRequired={payment.approvalInfo.approvalsRequired}
          />
        )
      ) || '-',
      paymentStatus: (
        <Status className={classes.status} variant={mapPaymentStatusDisplay(payment.status).variant}>{t(mapPaymentStatusDisplay(payment.status).text) || '-'}</Status>),
      paymentStatusStr: payment.status,
      paymentDto: payment,
      tradeLink: (payment.tradeId && tradeContractNumber && payment.tradeSellCurrency
        && (
          <TradeLink
            tradeContractNumber={tradeContractNumber}
            tradeSellCurrency={payment.tradeSellCurrency}
            tradeId={payment.tradeId}
            handleTradeLinkClick={handleTradeLinkClick}
          />
        )
      ) || '',
    };
  });

  const handleTradeLinkClick = (tradeId: string) => {
    browserHistory.push(`/app/trades/${tradeId}`);
  };

  const compareTypeFunction = (
    firstItem: any,
    item: any,
  ): boolean => {
    if (firstItem.paymentStatusStr === item.paymentStatusStr) {
      if (item.paymentStatusStr === PaymentStatus.SUBMITTED) {
        const canApproveFirstItem = shouldShowApprovalButtons(
          canApproveOthers.authorized,
          canApproveOwn,
          [firstItem.paymentDto as PaymentDto],
          userId!,
          true,
        );
        const canApproveSelectedItem = shouldShowApprovalButtons(
          canApproveOthers.authorized,
          canApproveOwn,
          [item.paymentDto as PaymentDto],
          userId!,
          true,
        );
        return canApproveFirstItem === canApproveSelectedItem;
      }
      return true;
    }
    return false;
  };

  return (
    <SearchTable
      table={{
        columns: displayColumns() as ITableColumn[],
        data: generateTableData(tableRawData),
        clickable: true,
        handleTableRowClick,
        handleTableSortClick,
        activeColumn: sortBy,
        sortOrder,
        dataTestId: testId || '',
        multiSelectable: true,
        keyColumn: 'id',
        totalRowNumber: tableRawData?.total,
        canOnlySelectSameTypeBaseOnFunction: compareTypeFunction,
        checkboxDisabledMessage: t('you_can_only_select_payments_with_same_status'),
        setSelectedIds,
        selectedIds,
        skip,
      }}
      loading={loading}
      pagination={{
        handleNext: handleNextPage as any,
        handlePrevious: handlePreviousPage as any,
        hasNext,
        hasPrevious,
      }}
      emptyTable={{
        title: emptyTitle || t('no_payments'),
        subtitle: emptySubTitle || t('you_currently_do_not_have_any_payments'),
      }}
    />
  );
};
export default PaymentTable;
