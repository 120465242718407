import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles((theme) => ({
  table: {
    '& td:not(:nth-child(1)), & th:not(:nth-child(1))': { paddingLeft: '10px !important', paddingRight: '4px !important' },
    '& td:nth-child(1), & tr:not(:nth-child(1)) th:nth-child(1)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(2), & tr:not(:nth-child(1)) th:nth-child(2)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(5), & tr:not(:nth-child(1)) th:nth-child(5)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(8), & tr:not(:nth-child(1)) th:nth-child(8)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(12), & tr:not(:nth-child(1)) th:nth-child(12)': { borderRight: '#eee solid 1px !important' },
    '& td:nth-child(15), & tr:not(:nth-child(1)) th:nth-child(15)': { borderRight: '#eee solid 1px !important' },
    '& thead tr:nth-child(1) th:not(:last-child)': { borderRight: '#eee solid 1px !important' },

    borderCollapse: 'separate',
    '& thead': {
      position: 'sticky',
      top: '217px',
      marginTop: '-117px',
      zIndex: '100',
    },

    '& thead tr th': {
      backgroundColor: '#ffffff !important',
    },
  },
  padDisabledTable: {
    '& td:nth-child(12), & tr:not(:nth-child(1)) th:nth-child(12)': { borderRight: 'none !important' },
    '& td:nth-child(11), & tr:not(:nth-child(1)) th:nth-child(11)': { borderRight: '#eee solid 1px !important' },
  },
  greenText: {
    fontWeight: theme.typography.fontWeightSemiBold,
    color: 'rgb(9, 131, 117)',
    cursor: 'pointer',
  },
  crossedOut: {
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.grey[400],
    cursor: 'pointer',
    textDecoration: 'line-through',
  },
}), { name: 'TeamPermissionsMatrixTable' });

export default useStyles;
