import React from 'react';
import clsx from 'clsx';

import { Box, Divider, Typography } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { Status } from '@alpha/ui-lib/ui/Status';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';

import { TData } from '../Dropdown';

import { useStyles } from './Item.styles';

interface IProps {
  item: TData;
}
const Data: React.FC<IProps> = (props: IProps) => {
  const { item } = props;
  const styles = useStyles();
  const bankInfo = item.iban
    || item.swift
    || item.accountNumber
    || item.sortCode
    || item.reference
    || '-';

  return (
    <Box className={clsx(styles.root, styles.item, item.externalAccount && styles.isExternal)}>
      <APTooltip title={`${item.clientAccountName || '-'}       ${item.friendlyName || '-'}       ${bankInfo}`}>
        <Box className={styles.root}>
          <Typography variant="subtitle1" className={styles.clientAccountName}>
            {item.clientAccountName || '-'}
            {' '}
          </Typography>
          <Divider orientation="vertical" variant="middle" light style={{ height: '24px' }} />
          <Typography variant="subtitle1" className={styles.friendlyName}>
            {item.friendlyName || '-'}
          </Typography>

          <Divider orientation="vertical" variant="middle" light style={{ height: '24px' }} />
          <Typography variant="subtitle1" className={styles.bankInfo} data-dd-privacy="mask">
            {bankInfo}
          </Typography>
        </Box>
      </APTooltip>
      {
        item.externalAccount ? (
          <div className={styles.externalBadge}>
            <Status variant="info">External</Status>
          </div>
        ) : null
      }
    </Box>
  );
};

interface IStickyProps {
  currencyCode: string;
}
const Sticky: React.FC<IStickyProps> = (props: IStickyProps) => {
  const { currencyCode } = props;
  const styles = useStyles();

  return (
    <Box className={clsx(styles.root, styles.sticky)}>
      <Box className={styles.imageHolder}>
        <Flag code={currencyCode} size="md" />
      </Box>
      <Typography variant="subtitle1">{currencyCode}</Typography>
    </Box>
  );
};

export const Item = {
  Data,
  Sticky,
};

export default Data;
