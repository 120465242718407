import { makeStyles } from '@alpha/ui-lib/theme';

import Background from '../../assets/login.jpg';
import GreenTick from '../../assets/tick.svg';

const useStyles = makeStyles(
  ({ typography, palette }) => ({
    '@global': {
      a: {
        textDecoration: 'none',
        color: '#00A09B',
      },
    },
    logo: {
      marginBottom: '40px',
    },
    container: {
      background: `url(${Background}) bottom center`,
      backgroundSize: 'cover',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'row',
      justifyContent: 'center',
      width: '100%',
      padding: '0 15%',
      height: '100vh',
      '& .MuiFormControl-root': {
        marginBottom: '18px',
      },
    },
    wrapper: {
      height: '600px',
      '& .MuiButton-root': {
        height: '50px',
        marginTop: '14px',
        marginBottom: '24px',
      },
      position: 'relative',
      '& form': {
        position: 'absolute',
        width: '455px',
      },
    },
    header: {
      display: 'block',
      fontFamily: '\'Source Sans Pro\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
      fontSize: '22px',
      fontWeight: typography.fontWeightLight,
      color: '#FFFFFF',
    },
    headerContainer: {
      marginBottom: '32px',
    },
    subHeader: {
      display: 'block',
      fontFamily: '\'Source Sans Pro\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
      fontSize: '14px',
      fontWeight: typography.fontWeightRegular,
      color: '#FFFFFF',
      marginBottom: '8px',
    },
    noMargin: {
      marginBottom: '0px',
    },
    subHeaderMarginTop: {
      marginTop: '32px',
    },
    subHeader2: {
      display: 'block',
      fontFamily: '\'Source Sans Pro\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
      fontSize: '14px',
      fontWeight: typography.fontWeightRegular,
      color: '#FFFFFF',
      marginBottom: '8px',
      marginTop: '32px',
      opacity: '50%',
    },
    copywrite: {
      color: 'rgb(255,255,255,0.5)',
      display: 'inline-block',
      position: 'absolute',
      top: '93%',
      left: '25%',
      transform: 'translate(-50%, -50%)',

    },
    list: {
      fontFamily: typography.fontFamily,
      fontSize: 14,
      fontWeight: typography.fontWeightRegular,
      color: 'rgba(255, 255, 255, 0.4)',
      paddingLeft: 16,
      marginTop: 0,
      marginBottom: 0,
      '& li:not(:last-child)': {
        marginBottom: 5,
      },
    },
    contains: {
      color: 'white',
      '&::marker': {
        background: `url(${GreenTick})`,
        color: palette.primary.main,
      },
    },
    bottomText: {
      fontFamily: '\'Source Sans Pro\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
      fontSize: '14px',
      fontWeight: typography.fontWeightRegular,
      color: '#FFFFFF',
    },
  }),
  { name: 'Auth' },
);

export default useStyles;
