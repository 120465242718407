import React from 'react';

import { useAuthorization } from '../hooks/useAuthorization';
import { UserRoles } from '../models/user';

interface IAuthorizationProps {
  requiredRoles: UserRoles[];
  children: JSX.Element | JSX.Element[];
  fallback?: JSX.Element;
  resourceCreatorUserId?: string;
}
const Authorization: React.FC<IAuthorizationProps> = (props: IAuthorizationProps) => {
  const {
    requiredRoles, resourceCreatorUserId, children, fallback,
  } = props;
  const { authorized } = useAuthorization(requiredRoles, resourceCreatorUserId);

  if (authorized) {
    return <>{children}</>;
  } if (fallback) {
    return <>{fallback}</>;
  }

  return <></>;
};

export default Authorization;
