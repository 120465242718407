import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({

  headingContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '4px',
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    display: 'flex',
    padding: '8px 16px 8px 21px',
    borderRadius: '5px',
    marginBottom: '18px',
    overflowWrap: 'anywhere',
    justifyContent: 'start',
    alignContent: 'center',
    '&.error': {
      backgroundColor: 'rgba(253,237,237)',
      '& .text': {
        color: 'rgba(95, 33, 32)',
        fontSize: '13px',
        alignSelf: 'center',
        fontWeight: typography.fontWeightSemiBold,
      },
      '& .subText': {
        color: 'rgba(34, 34, 34)',
        fontSize: '14px',
        alignSelf: 'center',
        fontWeight: typography.fontWeightRegular,
        opacity: '70%',
      },
      '& .icon': {
        color: 'rgba(239, 83, 80)',
        alignSelf: 'center',
        marginRight: '12px',
      },
    },
    '&.warning': {
      backgroundColor: 'rgba(255,244,229)',
      '& .text': {
        color: 'rgba(102, 60, 0)',
        fontSize: '13px',
        alignSelf: 'center',
        fontWeight: typography.fontWeightSemiBold,
      },
      '& .subText': {
        color: 'rgba(102, 60, 0)',
        fontSize: '13px',
        alignSelf: 'center',
        fontWeight: typography.fontWeightRegular,
        opacity: '70%',
      },
      '& .icon': {
        color: '#663C00',
        alignSelf: 'center',
        marginRight: '12px',
      },
    },
    '&.info': {
      backgroundColor: '#E9F5FE',
      '& .text': {
        color: '#0C3C61',
        fontSize: '13px',
        alignSelf: 'center',
        fontWeight: typography.fontWeightSemiBold,
      },
      '& .subText': {
        color: 'rgba(34, 34, 34)',
        fontSize: '14px',
        alignSelf: 'center',
        fontWeight: typography.fontWeightRegular,
        opacity: '70%',
      },
      '& .icon': {
        color: '#0C3C61',
        alignSelf: 'center',
        marginRight: '12px',
      },
    },
    '&.success': {
      backgroundColor: 'rgba(237, 247, 237)',
      '& .text': {
        color: 'rgba(30, 70, 32)',
        fontSize: '13px',
        alignSelf: 'center',
        fontWeight: typography.fontWeightSemiBold,
      },
      '& .subText': {
        color: 'rgba(34, 34, 34)',
        fontSize: '14px',
        alignSelf: 'center',
        fontWeight: typography.fontWeightRegular,
        opacity: '70%',
      },
      '& .icon': {
        color: 'rgba(76, 175, 80)',
        alignSelf: 'center',
        marginRight: '12px',
      },
    },
  },
  closeButton: {
    height: '16px',
    borderRadius: 0,
    color: '#3B82F6',
    marginLeft: '12px',
    alignSelf: 'center',
    padding: '0',
  },
}), { name: 'Alert' });

export default useStyles;
