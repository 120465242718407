import React from 'react';
import t from 'utils/translationHelper';

import { Typography } from '@alpha/ui-lib/ui/Typography';

import { CurrencyAccountsTable, CurrencyTotals } from '..';
import { CurrencyHeader, CurrencyPair } from '../../../components/International';
import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import { TCurrencyAccount, TCurrencyAccountDrawer } from '../../../models/currencyAccounts';
import useStyles from '../CurrencyAccounts.styles';
import MarginAccountsTable from '../MarginAccountsTable';

import HedgingCashAccountsTable from '../HedgingCashAccountsTable/HedgingCashAccountsTable';
import useCurrencyStats from './useCurrencyStats';

interface IProps {
  currencyCode: string;
  currency: string;
  handleAccountTableRowClick: (account: TCurrencyAccount) => void;
  handleOpenDrawer: (_drawer: TCurrencyAccountDrawer) => void
}

const CurrencyStats: React.FC<IProps> = (props: IProps) => {

  const {
    currencyCode, currency, handleAccountTableRowClick, handleOpenDrawer,
  } = props;
  const classes = useStyles();
  const { stats, loading } = useCurrencyStats(currencyCode);

  if (!stats) return null;

  if (stats.currencyCode !== currencyCode || loading) return <BackdropLoader testId="currencyaccount-loader" />;

  return (
    <>
      {
        stats.response.currencyAccounts.length > 1
        && (
          <div className={classes.stats} data-testid="currency-accounts-stats-wrapper">
            <CurrencyHeader currencyCode={currencyCode} currency={currency} />
            <CurrencyTotals currencyCode={currencyCode} totals={stats.response.totals} />
          </div>
        )
      }

      <div style={{
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '20px 0 20px 0',
        borderBottom: '1px solid #d2d6de',
      }}
      >
        <CurrencyPair currencyCode={currencyCode} displayCode={false} size="md" />
        <Typography variant="h3">
          {currencyCode}
          {' '}
          {t('accounts')}
          {' '}
          (
          {stats.response.currencyAccounts.length}
          )
        </Typography>
      </div>
      <CurrencyAccountsTable
        currencyCode={currencyCode}
        currencyAccounts={stats.response.currencyAccounts}
        handleAccountTableRowClick={handleAccountTableRowClick}
        handleOpenDrawer={handleOpenDrawer}
      />
      {stats.response.marginAccounts.length > 0 && (
        <>
          <div style={{
            fontWeight: 'bolder',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '20px 0 20px 0',
            borderBottom: '1px solid #d2d6de',
          }}
          >
            <CurrencyPair currencyCode={currencyCode} displayCode={false} size="md" />
            <Typography variant="h3">
              {t('margin')}
              {' '}
              (
              {stats.response.marginAccounts.length}
              )
            </Typography>
          </div>
          <MarginAccountsTable
            marginAccounts={stats.response.marginAccounts}
            handleOpenDrawer={handleOpenDrawer}
          />
        </>
      )}
      {stats.response.hedgingCashAccounts?.length > 0 && (
        <>
          <div style={{
            fontWeight: 'bolder',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '20px 0 20px 0',
            borderBottom: '1px solid #d2d6de',
          }}
          >
            <CurrencyPair currencyCode={currencyCode} displayCode={false} size="md" />
            <Typography variant="h3">
              {t('hedging_cash_balance')}
              {' '}
              (
              {stats.response.hedgingCashAccounts?.length}
              )
            </Typography>
          </div>
          <HedgingCashAccountsTable
            currencyCode={currencyCode}
            currencyAccounts={stats.response.hedgingCashAccounts}
            handleAccountTableRowClick={handleAccountTableRowClick}
            handleOpenDrawer={handleOpenDrawer}
          />
        </>
      )}
    </>
  );
};

export default CurrencyStats;
