import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
  },
  sessionHeading: {
    fontSize: '16px',
    color: '#363636',
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: '20px',
  },
  greenText: {
    fontWeight: theme.typography.fontWeightSemiBold,
    color: 'rgb(9, 131, 117)',
  },
  emptyTable: {
    position: 'relative',
    top: '0',
    transform: 'none',
    left: '0',
  },
  underlinedGreenText: {
    textDecoration: 'underline',
    fontSize: '15px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    marginBottom: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },

}), { name: 'UploadHistory' });

export default useStyles;
