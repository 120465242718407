/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react';
import AccountSettingsService from 'services/AccountSettings/accountSettings.service';
import { FeatureFlagName } from 'services/Config/config.service';
import t from 'utils/translationHelper';

import { UserAccountDto } from '@alpha/auth-dtos';
import { EmailNotificationType } from '@alpha/notifications-dtos';
import { IStyledGenericTableProps } from '@alpha/ui-lib/ui/table';

import { UserRole } from '../models/user';

import useAuthorization from './useAuthorization';
import useGetFeatureFlags from './useGetFeatureFlags';

type TPortalPermissionColumns = {
  role: UserRole;
  editable: boolean;
  headerText?: string;
  forPadOnly?: boolean;
  caption?: string;
  key?: string;
  width?: number;
  hidden?: boolean;
}

type TPortalNotificationColumns = {
  type: EmailNotificationType;
  name: string;
  editable: boolean;
  headerText?: string;
  forPadOnly?: boolean;
  caption?: string;
  key?: string;
  hidden?: boolean;
}

const useAccountSettings = () => {
  const { authorized: iatInputterAuthorized } = useAuthorization([[UserRole.IAT_INPUTTER]]);
  const { authorized: iatApproverAuthorized } = useAuthorization([[UserRole.IAT_APPROVER]]);
  const [userAccounts, setUserAccounts] = useState<UserAccountDto[]>([]);
  const { isFlagActive } = useGetFeatureFlags();

  const hasPermission = (role: UserRole, accountId?: string): boolean => {
    if (!accountId) {
      return false;
    }

    const userAccount = userAccounts.find((ua) => ua.id === accountId);

    if (!userAccount) {
      return false;
    }

    return userAccount.roles.includes(role);
  };

  const loadUserAccounts = async () => {
    try {
      const response = await AccountSettingsService.getUserAccountRoles();
      if (response) {
        setUserAccounts(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadUserAccounts();
  }, []);

  const portalPermissionColumns: TPortalPermissionColumns[] = [
    {
      headerText: t('reporting_read_only'),
      role: UserRole.READ_ONLY,
      editable: false,
      width: 150,
    },
    {
      role: UserRole.BENEFICIARY_INPUTTER,
      headerText: t('inputter'),
      editable: true,
    },
    {
      role: UserRole.BENEFICIARY_APPROVER,
      headerText: t('approver'),
      editable: true,
    },
    {
      role: UserRole.BENEFICAIRY_APPROVER_OWN,
      headerText: t('approver_own'),
      editable: true,
    },
    {
      role: UserRole.PAYMENTS_INPUTTER,
      headerText: t('inputter'),
      editable: true,
    },
    {
      role: UserRole.PAYMENTS_APPROVER,
      headerText: t('approver'),
      editable: false,
      caption: t('please_contact_alpha_fx_to_edit_this_permission'),
    },
    {
      role: UserRole.PAYMENTS_APPROVER_OWN,
      headerText: t('approver_own'),
      editable: false,
      caption: t('please_contact_alpha_fx_to_edit_this_permission'),
      width: 150,
    },
    {
      role: UserRole.DRAWDOWN_INPUTTER,
      headerText: t('drawdown_inputter'),
      editable: false,
      caption: t('please_contact_alpha_fx_to_edit_this_permission'),
    },
    {
      role: UserRole.SPOT_INPUTTER,
      headerText: t('spot_inputter'),
      editable: true,
    },
    {
      role: UserRole.SPOT,
      headerText: t('spot'),
      editable: false,
      caption: t('please_contact_alpha_fx_to_edit_this_permission'),
    },
    {
      role: UserRole.PAD_APPROVER,
      headerText: t('pad_approver'),
      editable: false,
      forPadOnly: true,
      caption: t('please_contact_alpha_fx_to_edit_this_permission'),
      width: 150,
    },
    {
      role: UserRole.IAT_INPUTTER,
      headerText: t('inputter'),
      editable: true,
      hidden: iatInputterAuthorized || iatApproverAuthorized,
    },
    {
      role: UserRole.IAT_APPROVER,
      headerText: t('approver'),
      editable: false,
      caption: t('please_contact_alpha_fx_to_edit_this_permission'),
      hidden: iatInputterAuthorized || iatApproverAuthorized,
    },
    {
      role: UserRole.IAT_APPROVER_OWN,
      headerText: t('approver_own'),
      editable: false,
      caption: t('please_contact_alpha_fx_to_edit_this_permission'),
      hidden: iatInputterAuthorized || iatApproverAuthorized,
      width: 150,
    },
  ];

  const portalPermissionColumnGroups: IStyledGenericTableProps['columnGroups'] = [
    {
      header: '',
      align: 'center',
      span: 1,
    },
    {
      header: t('basic'),
      align: 'center',
      span: 1,
    }, {
      header: t('beneficiary'),
      align: 'center',
      span: 3,
    }, {
      header: t('payment'),
      align: 'center',
      span: 3,
    }, {
      header: t('trade'),
      align: 'center',
      span: 4,
    },
    {
      header: t('transfers'),
      align: 'center',
      span: 3,
    },
    {
      header: '',
      align: 'center',
      span: 1,
    }];

  const portalPermissionColumnGroupsWithoutPad: IStyledGenericTableProps['columnGroups'] = [
    {
      header: '',
      align: 'center',
      span: 1,
    },
    {
      header: t('basic'),
      align: 'center',
      span: 1,
    }, {
      header: t('beneficiary'),
      align: 'center',
      span: 3,
    }, {
      header: t('payment'),
      align: 'center',
      span: 3,
    }, {
      header: t('trade'),
      align: 'center',
      span: 3,
    }, {
      header: t('transfers'),
      align: 'center',
      span: 3,
    },
    {
      header: '',
      align: 'center',
      span: 1,
    }];

  const portalNotificationColumns: TPortalNotificationColumns[] = [
    {
      type: EmailNotificationType.TRADE_CONFIRMATIONS,
      name: 'Trade Confirmations',
      headerText: t('trade'),
      editable: true,
    },
    {
      type: EmailNotificationType.DRAW_DOWN_CONFIRMATIONS,
      name: 'Drawdown Confirmations',
      headerText: t('drawdown'),
      editable: true,
    },
    {
      type: EmailNotificationType.PAYMENT_CONFIRMATIONS,
      name: 'Payment Confirmations',
      headerText: t('payment'),
      editable: true,
    },
    {
      type: EmailNotificationType.PAYMENT_INITIATED_CONFIRMATION,
      name: 'Payment Initiated Confirmations',
      headerText: t('payment_initiated'),
      editable: true,
    },
    {
      type: EmailNotificationType.TRANSFER_CONFIRMATIONS,
      name: 'Transfer Initiated Confirmations',
      headerText: t('transfer_initiated'),
      editable: true,
      hidden: !isFlagActive(FeatureFlagName.IAT_COMMUNICATION_SETTINGS),
    },
    {
      type: EmailNotificationType.MONTHLY_TRADE_NOTIFICATIONS,
      headerText: t('monthly_trade'),
      name: 'Monthly Trade Summary',
      editable: true,
    },

  ];

  const portalNotificationColumnGroups: IStyledGenericTableProps['columnGroups'] = [{
    header: '',
    align: 'center',
    span: 1,
  }, {
    header: t('confirmations'),
    align: 'center',
    span: isFlagActive(FeatureFlagName.IAT_COMMUNICATION_SETTINGS) ? 5 : 4,
  }, {
    header: t('summary'),
    align: 'center',
    span: 3,
  }];

  return {
    portalPermissionColumns,
    portalNotificationColumns,
    hasPermission,
    userAccounts,
    portalPermissionColumnGroups,
    portalPermissionColumnGroupsWithoutPad,
    portalNotificationColumnGroups,
  };
};

export default useAccountSettings;
