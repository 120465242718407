import React from 'react';

import {
  PaymentDto,
  PaymentStatus,
} from '@alpha/payments-dtos';
import { Box } from '@alpha/ui-lib/ui/external';
import t from 'utils/translationHelper';

import Title from '../../../../components/Title/Title';
import { flowToBatchStatusMapping } from '../../../../utils/batchStatus.helpers';
import isPaymentBatchDraftDto from '../../../../utils/payments/isPaymentBatchDraftDto.helper';

import TitleStatus from './TitleStatus/TitleStatus';
import useStyles from './TitleContainer.styles';

interface IProps {
  paymentDetails: PaymentDto;
  isCrossCurrency: boolean;
}

const TitleContainer: React.FC<IProps> = (props: IProps) => {

  const classes = useStyles();
  const { paymentDetails, isCrossCurrency } = props;

  const getTitleProps = (
    backendBatchStatus: PaymentStatus,
  ): { title: string; check?: boolean } => {
    switch (true) {
      case paymentDetails.status === PaymentStatus.COMPLETED:
        return {
          title: t('payments_completed'),
          check: true,
        };
      case flowToBatchStatusMapping.approver.includes(backendBatchStatus):
        return {
          title: t('batch_summary~'),
          check: false,
        };
      case flowToBatchStatusMapping.postCompletion.includes(backendBatchStatus):
        return isCrossCurrency
          ? {
            title: t('fx_booked_your_payments_are_being_processed'),
            check: true,
          }
          : {
            title: t('your_payments_are_being_processed'),
            check: true,
          };
      default:
        return {
          title: t('batch_summary~'),
        };
    }
  };

  return (
    <Box className={classes.titleContent}>
      <Title
        {...getTitleProps(paymentDetails.status)}
        batchFileName=""
      />
      <TitleStatus
        requiresFx={false}
        paymentStatus={paymentDetails.status}
        noOfApprovals={undefined}
        approvalsRequired={undefined}
      />
    </Box>
  );
};

export default TitleContainer;
