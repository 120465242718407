import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useAuthy from '../../hooks/useAuthy';
import { actions } from '../../store/authy/reducer';

import Modal from './Modal/Modal';

const Authy: React.FC = () => {
  const authy = useAuthy();
  const dispatch = useDispatch();

  const setAuthyClose = () => {
    dispatch(actions.closeAuthyPage());
    authy.clearAuthy();
  };

  useEffect(() => {
    if (
      authy.reduxState.open
      && authy.reduxState.status === 'INITIATED'
      && !authy.reduxState.phoneNumber
    ) {
      authy.triggerMfa();
    }

    if (
      authy.reduxState.open
      && authy.reduxState.status === 'SUCCESS'
    ) {
      setTimeout(() => setAuthyClose(), 4000);
    }
  }, [authy.reduxState.status]);
  return (
    <>
      {authy.reduxState.open
        ? (
          <Modal
            open={authy.reduxState.open}
            status={authy.status}
            onClose={authy.clearAuthy}
            authyPhoneNumber={authy.phoneNumber}
            handleComplete={authy.triggerSubmit}
          />
        )
        : <></>}
    </>
  );
};

export default memo(Authy);
