import React from 'react';

import { Box } from '@alpha/ui-lib/ui/external';

import useStyles from './FooterWrapper.styles';

interface IProps {
    children: JSX.Element | JSX.Element[]
}

const FooterWrapper: React.FC<IProps> = ({ children }: IProps) => {
  const styles = useStyles();
  return (
    <Box className={styles.root}>
      <hr className={styles.hr} />
      { children }
    </Box>
  );
};

export default FooterWrapper;
