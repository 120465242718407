import React from 'react';
import { Route, Switch } from 'react-router-dom';

import routes from '../../routes.path';

import Dashboard from './Dashboard/Dashboard';
import { BatchSummary } from './BatchSummary';

const Transactions: React.FC = () => (
  <Switch>
    <Route
      exact
      path={
        [
          routes.transactions.fxtrades,
          routes.transactions.base,
          routes.transactions.transfer,
          routes.transactions.optionTrades,
        ]
      }
    >
      <Dashboard />
    </Route>

    <Route
      exact
      path={routes.transactions.uploadSummary}
      component={BatchSummary}
    />

  </Switch>
);

export default Transactions;
