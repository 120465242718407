import { AxiosResponse } from 'axios';

import { ProgramPeriodUpdateRequest, TradeDto } from '@alpha/fx-dtos';

import { FileType } from '../../hooks/useReportsPolling';
import { TReportGenerationResponse } from '../../models/currencyAccounts';
import instance from '../Axios/instance';

import { TProgramData, TProgramImage, TProgramPeriodsData } from './hedgingPrograms.interfaces';

type TFxSearchParams = {
  queryParams: {
    searchtext?: string,
    take: number,
    skip: number,
    sortby?: string,
    sortorder?: 'asc' | 'desc',
    buycurrency?: string,
    soldcurrency?: string,
    type?: string,
    status?: string,
    tradedatefrom?: string,
    tradedateto?: string,
    valuedatefrom?: string,
    valuedateto?: string,
  } & Record<string, string | number>,
  body?: Record<string, unknown>,
}

export type TSearchBaseResponse = {
  total: number;
  records: unknown[];
}

export class HedgingProgramsService {
  public static async getHedgingProgramsData(): Promise<TProgramData[]> {
    const response = await instance.get('fx/programs');
    return response.data;
  }

  public static async getHedgingProgramPeriodsData(id: string): Promise<TProgramPeriodsData> {
    const response = await instance.get(`fx/programs/${id}`);
    return response.data;
  }

  public static async putUpdatePeriodForecast({ programId, periodId, forecastAmount }
    : {
      programId: string,
      periodId: string,
      forecastAmount: number
    }): Promise<any> {
    const response = await instance.put(
      `/fx/programs/${programId}/periods/${periodId}`,
      { forecast: forecastAmount } as ProgramPeriodUpdateRequest,
    );
    return response.data;
  }

  public static async getPeriodTradesData(searchParams: TFxSearchParams): Promise<TradeDto[]> {
    const response: AxiosResponse = await instance({
      method: 'GET',
      url: '/fx/trades/search',
      params: { ...searchParams.queryParams },
      data: searchParams.body,
    });

    return response.data?.records;
  }

  public static async postHedgingProgramReportGeneration(
    programId: string,
    type: FileType,
    programImages: TProgramImage[],
  ): Promise<string> {
    const response: AxiosResponse<TReportGenerationResponse> = await instance.post(
      `/reports/programs/${programId}?type=${type}`, { images: programImages },
    );
    return response.data.executionArn;
  }
}

export default HedgingProgramsService;
