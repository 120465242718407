import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    menuCustom: {
      '& .MuiPopover-paper': {
        width: '430px;',
      },
      '& .MuiInput-underline:before': {
        border: '0',
      },
      '& .MuiList-root li': {
        padding: '4px 12px 4px 12px',
      },
    },
    filterButtonContainer: {
      '& Button.MuiButtonBase-root': {
        minWidth: '90px',
        borderRadius: '10px',
        textTransform: 'none',
        marginLeft: '8px',
        fontSize: '13px',
        width: 'fit-content',
        '& .MuiButton-label span': {
          fontSize: '13px',
        },
      },
    },
    alphaInput: {
      width: '100%',
      '& .MuiInputBase-root': {
        background: 'rgba(230,230,230, 0.3)',
        borderRadius: '5px',
        border: 'none',
        padding: '8px !important',
      },
      '& label': {
        margin: '6px',
      },
    },
  }),
  { name: 'MtmFilter' },
);

export default useStyles;
