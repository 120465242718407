import { DrawdownStatus } from '@alpha/fx-dtos';

export const mapDrawdownStatusDisplay = (
  drawdownStatus: DrawdownStatus,
): {variant:'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined, text:
    string} => {
  switch (drawdownStatus) {
    case DrawdownStatus.PROCESSING:
      return { variant: 'info', text: 'PROCESSING' };
    case DrawdownStatus.AWAITING_FUNDS:
      return { variant: 'orange', text: 'AWAITING FUNDS' };
    case DrawdownStatus.COMPLETED:
      return { variant: 'success', text: 'COMPLETED' };
    case DrawdownStatus.CANCELLED:
      return { variant: 'error', text: 'CANCELLED' };
    default:
      return { variant: 'info', text: '-' };
  }
};

export default mapDrawdownStatusDisplay;
