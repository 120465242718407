import { ITick } from '@alpha/ui-lib/ui/Chart/APHorizontalBarChart';

import { formatCurrency } from './currency.helpers';

interface BarChartTicks {
  ticksToDisplay: ITick[];
  minTickValue: number;
  maxTickValue: number;
  vmPercentage?: number;
}

const createBarChartTicks = (plots: { percentage: number; amount: number; }[], currencyCode: string, vmPercentage?: number): BarChartTicks => {
  const tmpTickArray: ITick[] = [];
  let minTickValue = 0;

  plots?.forEach((element, i) => {
    const tick: ITick = {
      value: element.percentage,
      percentage: `${element.percentage}%`,
      amount: (vmPercentage && element.percentage === -vmPercentage) // Only show when tick amount = vm
        ? formatCurrency(currencyCode, Math.abs(element.amount)) : '',
    };

    tmpTickArray.push(tick);
    if (element.percentage < minTickValue) {
      minTickValue = element.percentage;
    }
    if (minTickValue === 0) {
      minTickValue = -0.5;
    }
  });
  const maxTickValue = Math.abs(minTickValue);
  const ticksToDisplay = tmpTickArray;

  return {
    ticksToDisplay, minTickValue, maxTickValue,
  };
};

export default createBarChartTicks;
