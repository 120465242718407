import React from 'react';

import { makeStyles } from '@alpha/ui-lib/theme';
import { Box, LinearProgress as MuiLineaerProgress, LinearProgressProps } from '@alpha/ui-lib/ui/external';

interface IProps extends LinearProgressProps { }

const useStyles = makeStyles(() => ({
  linearProgress: {
    '& .MuiLinearProgress-root': {
      height: '8px',
      borderRadius: '5px',
    },
  },
}), { name: 'linearProgress' });

const LinearProgress: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  return (
    <Box className={styles.linearProgress}>
      <MuiLineaerProgress {...props} />
    </Box>
  );
};

export default LinearProgress;
