import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    bodyWrapper: {
      overflowX: 'hidden',
      padding: 30,
    },
    noOfAccounts: {
      fontSize: 14,
      color: 'rgba(51, 51, 51, 0.93)',
      paddingBottom: 20,
    },
  }), { name: 'SwitchAccountDrawerBody' },
);

export default useStyles;
