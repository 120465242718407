import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    borderBottom: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    padding: {
      padding: '70px 55px 25px 55px',
    },
    SemiBold: {
      fontWeight: typography.fontWeightSemiBold,
    },
    title: {
      fontSize: 22,
      letterSpacing: -0.5,
    },
    subtitle: {
      fontSize: 11,
      textTransform: 'uppercase',
      letterSpacing: 1.5,
      color: '#818181',
    },
    flag: {
      marginRight: 27,
    },
  }),
  { name: 'HeaderText' },
);

export default useStyles;
