import React from 'react';

import { Button } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';

import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../../../hooks/useReportsPolling';

import useStyles from './HeaderButtons.styles';

interface IProps {
  drawdownId: string | undefined;
}

const HeaderButtons: React.FC<IProps> = ({ drawdownId }: IProps) => {
  const classes = useStyles();


  const { executeReportGeneration } = useReportsPolling(PageType.FX);

  const handleReportGeneration = async (type: FileType, downloadType: DownloadType) => {
    if (drawdownId) {
      await executeReportGeneration(
        type, drawdownId, undefined, undefined, downloadType,
      );
    }
  };

  return (
    <div>
      <APTooltip title={t('download_drawdown_contract_note')}>
        <Button
          data-testid="drawdown-download-contract-note"
          onClick={() => handleReportGeneration(FileType.PDF, DownloadType.Drawdown)}
          className={classes.button}
        >
          <FontAwesomeIcon icon={faDownload as IconProp} />
        </Button>
      </APTooltip>
    </div>
  );
};

export default HeaderButtons;
