import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import clsx from 'clsx';

import { Box } from '@alpha/ui-lib/ui/external';

import useStyles from './DragAndDrop.styles';

let dragCounter = 0; // avoid blinking on draging

interface IDragAndDrop {
  onDropHandler: (file: File) => void;
  onClick?: () => void;
  children?: any;
  beneFlow?: boolean;
}

const DragAndDrop: FC<IDragAndDrop> = (props: IDragAndDrop) => {
  const {
    onDropHandler, onClick, children, beneFlow,
  } = props;
  const classes = useStyles();
  const [isDragEnter, setDragEnter] = useState(false);

  useEffect(() => {
    dragCounter = 0;
  }, []);

  const onDragEnter = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCounter += 1;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragEnter(true);
      }
    },
    [setDragEnter],
  );

  const onDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onDragStart = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.clearData();
  }, []);

  const onDragLeave = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCounter -= 1;
      if (dragCounter > 0) return;
      setDragEnter(false);
    },
    [setDragEnter],
  );

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragEnter(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        onDropHandler(file);

        dragCounter = 0;
      }
    },
    [onDropHandler],
  );

  return (
    <Box
      className={clsx(
        classes.dragnDropBoxContainer,
        isDragEnter ? 'dragEnter' : 'normal',
        beneFlow ? 'benes' : 'payments',
      )}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={onClick}
      onDragStart={onDragStart}
    >
      {children}
    </Box>

  );
};

export default DragAndDrop;
