import React from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import useReportsPolling, { DownloadType, FileType, PageType } from 'hooks/useReportsPolling';
import t from 'utils/translationHelper';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapPaymentStatusDisplay from 'utils/payments/mapPaymentStatusDisplay';

import { Approval } from '@alpha/bene-dtos';
import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Button, Divider } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table/StyledGenericTable';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NoOfApprovers from '../../DisplayTable/NoOfApprovers/NoOfApprovers';
import ExportMenu from '../ExportMenu/ExportMenu';

import useStyles from './Payments.styles';

interface IProps {
  tradeId: string,
  payments: PaymentDto[];
  handlePaymentContractNumberClick?: (payment: PaymentDto) => void;
  handleBeneficiaryClick?: (beneficiaryId: string) => void;
  showExportButton: boolean,
}

const Payments: React.FC<IProps> = ({
  tradeId,
  payments,
  handlePaymentContractNumberClick,
  handleBeneficiaryClick,
  showExportButton,
}: IProps) => {

  const classes = useStyles();
  const isEmpty = payments.length === 0;

  const { executeReportGeneration } = useReportsPolling(PageType.Payments);
  const handleDownloadButton = async (paymentId: string) => {
    if (paymentId) {
      await executeReportGeneration(
        FileType.PDF, paymentId, undefined, undefined, DownloadType.Summary,
      );
    }
  };

  const creatTableData = () => payments.map((column: PaymentDto) => ({
    ...column,
    amount: <span className={classes.amount}>{[formatNumber(column.amount, 2), column.debitingCurrency].join(' ')}</span>,
    action: column?.status !== PaymentStatus.UPLOADED && column?.status !== PaymentStatus.VALIDATED && (<ActionButton size="small" testId="payment-download-btn" style={{ background: '#F7F7F7', color: '#212529' }} onClick={() => { handleDownloadButton(column.id); }}><FontAwesomeIcon icon={faDownload as IconProp} /></ActionButton>),
    status: (<Status className={classes.status} variant={mapPaymentStatusDisplay(column.status).variant}>{t(mapPaymentStatusDisplay(column.status).text) || '-'}</Status>),
    valueDate: formatIsoDate(column.valueDate) || '-',
    date: formatIsoDate(column.date) || '-',
    contractNumber: (
      <Button
        disableRipple
        className={classes.tableButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (handlePaymentContractNumberClick) {
            handlePaymentContractNumberClick(column);
          }
        }}
      >
        {column.contractNumber || '-'}
      </Button>
    ),
    beneficiaryName: (
      <Button
        disableRipple
        className={classes.tableButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (handleBeneficiaryClick) {
            handleBeneficiaryClick(column.beneficiaryId);
          }
        }}
      >
        {column.beneficiaryName || '-'}
      </Button>
    ),
    approvals: (column
      && (
        <NoOfApprovers
          uploadedBy={column.uploadedBy}
          uploadedDate={column.uploadedDate}
          noOfApprovals={column.approvalInfo.approvals?.length}
          approvedBy={column.approvalInfo.approvals
            ? column.approvalInfo.approvals?.map((approval) => ({
              userId: approval.userId,
              date: approval.date,
              fullName: approval.fullName,
            } as unknown as Approval))
            : []}
          approvalsRequired={column.approvalInfo.approvalsRequired}
        />
      )
    ) || '-',
    reference: column.reference,
    fundingCurrency: column.fundingCurrency,
  }));

  const columns = [
    {
      header: t('payment_id'),
      accessor: 'contractNumber',
    },
    {
      header: t('transaction_date'),
      accessor: 'valueDate',
    },
    {
      header: t('payment_cyy'),
      accessor: 'debitingCurrency',
    },
    {
      header: t('amount'),
      accessor: 'amount',
    },
    {
      header: t('beneficiary_name'),
      accessor: 'beneficiaryName',
    },
    {
      header: t('payment_ref'),
      accessor: 'reference',
    },
    {
      header: t('approvals'),
      accessor: 'approvals',
    },
    {
      header: t('status'),
      accessor: 'status',
    },
    {
      header: '',
      accessor: 'action',
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.paymentTable}>
        <Typography variant="subtitle1" className={classes.header}>
          {t('payments')}
          {' '}
          (
          {payments.length}
          )
        </Typography>
        {showExportButton && (
          <ExportMenu
            downloadType={DownloadType.TradeWithPayments}
            tradeId={tradeId}
          />
        )}
      </div>
      <Divider />
      {isEmpty ? (
        <Box className={classes.emptyTable}>
          <EmptyTable
            title={t('no_payments')}
            subtitle={t('you_do_not_have_any_payments_currently')}
            icon={clipboardCross}
          />
        </Box>
      ) : (
        <StyledGenericTable
          testId="trade-payment-table"
          columns={columns}
          data={creatTableData()}
        />
      )}
    </div>
  );
};

export default Payments;
