/* eslint-disable react/destructuring-assignment */
import React from 'react';
import moment from 'moment';
import NoOfApprovers from 'domain/Transactions/Dashboard/FxTradeTable/DisplayTable/IATNoOfApprovers/NoOfApprovers';
import { formatCurrency } from 'utils/currency.helpers';
import { CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import t from 'utils/translationHelper';

type Props = {
  values: CurrencyAccountTransferDto;
};

const ApprovalSummary: React.FC<Props> = ({ values }) => {

  const transferData = {
    fundingCurrencyAccountName: values.fundingCurrencyAccountName,
    amount: values.instructedAmount,
    reference: values.reference,
    date: values.valueDate,
    fromCurrencyCode: values.fundingCurrencyCode,
    toCurrencyCode: values.fundingCurrencyCode,
    approvalInfo: values.approvalInfo,
    uploadedBy: values.submittedBy,
    uploadedDate: values.submittedDate,
  };

  const columns: IStyledGenericTableProps['columns'] = [
    {
      header: t('crediting_account'),
      accessor: 'fundingCurrencyAccountName',
      width: 20,
    },
    {
      header: t('amount'),
      accessor: 'amount',
      width: 20,
    },
    {
      header: t('reference'),
      accessor: 'reference',
      width: 20,
    },
    {
      header: t('date'),
      accessor: 'date',
      width: 20,
    },
    {
      header: t('approvals_required'),
      accessor: 'noOfApprovers',
      width: 20,
    }
  ];

  const data: IStyledGenericTableProps['data'] = [transferData].map((transfer) => (
    {
      fundingCurrencyAccountName: transfer.fundingCurrencyAccountName || '-',
      amount: formatCurrency(transfer.toCurrencyCode, transfer.amount),
      reference: transfer.reference,
      date: moment(transfer.date!).format('DD/MM/YY').toString(),
      noOfApprovers: (<NoOfApprovers
        uploadedBy={transfer.uploadedBy}
        uploadedDate={transfer.uploadedDate}
        noOfApprovals={transfer.approvalInfo?.approvals.length}
        approvedBy={transfer.approvalInfo?.approvals || []}
        approvalsRequired={transfer.approvalInfo?.approvalsRequired.reduce((sum, curr) => sum + curr.number, 0)}
      />)
    }),
  );

  return (
    <StyledGenericTable
      testId="approval-summary-table"
      columns={columns as any}
      data={data}
    />
  );
};

export default ApprovalSummary;
