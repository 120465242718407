import React, { memo } from 'react';
import t from 'utils/translationHelper';

import {
  PaymentDto,
  PaymentStatus,
} from '@alpha/payments-dtos';

import useAuthorization from '../../../../hooks/useAuthorization';
import { UserRole } from '../../../../models/user';
import isPaymentBatchDraftDto from '../../../../utils/payments/isPaymentBatchDraftDto.helper';

import ApproveRejectContainer from './ApproveRejectFooterContainer/ApproveRejectFooterContainer';
import BatchSubmissionFooterContainer from './BatchSubmissionFooterContainer/BatchSubmissionFooterContainer';
import BatchSubmittedFooter from './BatchSubmittedFooter/BatchSubmittedFooter';
import BookFxFooterContainer from './BookFxFooterContainer/BookFxFooterContainer';

interface IProps {
  batchDetails: PaymentDto;
}
const FooterContainer: React.FC<IProps> = (props: IProps) => {
  const { batchDetails } = props;
  const fxBooker = useAuthorization([[UserRole.FX_IAT]]);


  const isFinalApprover = (_batchDetails: PaymentDto) => {
    if (
      _batchDetails.approvalInfo.approvalsRequired
      && _batchDetails.approvalInfo.approvals !== undefined
      && (_batchDetails.approvalInfo.approvalsRequired
        - _batchDetails.approvalInfo.approvals.length) === 1
    ) {
      return true;
    }
    return false;
  };

  if (isPaymentBatchDraftDto(batchDetails)) {
    switch (batchDetails.status) {
      case PaymentStatus.SUBMITTED:
        return (
          <ApproveRejectContainer
            batchId={batchDetails.batchId}
            batchName={t('payment_batch')}
            uploadedById={batchDetails.uploadedById}
            canApprove // TODO
            requiresFx={false} // TODO remove
            isFinalApprover={isFinalApprover(batchDetails)}
          />
        );
      case PaymentStatus.CLIENT_APPROVED:
        if (fxBooker.authorized) {
          return <BookFxFooterContainer batchId={batchDetails.batchId} />;
        }
        return <BatchSubmittedFooter />;
      case PaymentStatus.VALIDATED:
        return <BatchSubmissionFooterContainer batchDetails={batchDetails} />;
      default:
        return <BatchSubmittedFooter />;
    }
  }

  return <BatchSubmittedFooter />;
};

export default memo(FooterContainer);
