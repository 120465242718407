import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useAccountSettings from 'hooks/useAccountSettings';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import t from 'utils/translationHelper';
import { FeatureFlagName } from 'services/Config/config.service';

import { AccountUserEmailSettingsDto, EmailNotificationType } from '@alpha/notifications-dtos';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';

import useStyles from './PersonalNotificationMatrixTable.styles';

interface IPersonalNotificationMatrixProps {
  userNotificationSettings: AccountUserEmailSettingsDto[];
  setUserNotificationSettings?: React.Dispatch<React.SetStateAction<AccountUserEmailSettingsDto[]>>,
  readonly?: boolean;
}

const PersonalNotificationMatrixTable: React.FC<IPersonalNotificationMatrixProps> = (
  {
    userNotificationSettings, setUserNotificationSettings, readonly = false,
  }: IPersonalNotificationMatrixProps,
) => {
  const {
    portalNotificationColumns,
    portalNotificationColumnGroups: columnGroups,
  } = useAccountSettings();
  const [data, setData] = useState<any[]>([]);
  const { isFlagActive } = useGetFeatureFlags();

  const classes = useStyles();

  const accountColumn = [{
    header: t('account'),
    accessor: 'account',
    width: 200,
  }];

  const notificationColumns = portalNotificationColumns.filter((c) => !c.hidden).map((column) => ({
    header: column.headerText ?? column.name,
    accessor: column.type as string,
    width: 130,
  }));

  const columns = accountColumn.concat(notificationColumns);

  const getCheckbox = (
    settings: AccountUserEmailSettingsDto,
    name: string,
    type: EmailNotificationType,
    value?: boolean,
    setValue?: React.Dispatch<React.SetStateAction<boolean>>,
    disabled = false,
  ) => (
    <Checkbox
      testId={`send-email-notifcation-${name}`}
      label=""
      checked={value}
      disabled={disabled}
      onChange={(event) => {
        if (event.target.checked) {
          settings.enabled.push(type);
          const index = settings.disabled.indexOf(type);
          settings.disabled.splice(index, 1);
        } else {
          settings.disabled.push(type);
          const index = settings.enabled.indexOf(type);
          settings.enabled.splice(index, 1);
        }

        const tableData = mapAccountSettingsToData(userNotificationSettings);
        setData(tableData);
        if (setUserNotificationSettings) {
          setUserNotificationSettings([...userNotificationSettings]);
        }
      }}
    />
  );

  const mapAccountSettingsToData = (settings: AccountUserEmailSettingsDto[]) => settings.map(
    (setting) => {
      const result: {
        [key: string]: any,
      } = {
        account: <span className={classes.greenText}>{setting.accountName || '-'}</span>,
      };

      for (let i = 0; i < portalNotificationColumns.length; i += 1) {
        const col = portalNotificationColumns[i];
        const accessor = col.type as string;
        result[accessor] = getCheckbox(
          setting,
          col.type,
          col.type,
          setting.enabled.includes(col.type),
          undefined,
          !col.editable || readonly,
        );
      }
      return result;
    },
  );

  useEffect(() => {
    const tableData = mapAccountSettingsToData(userNotificationSettings);
    setData(tableData);
  }, [userNotificationSettings, readonly]);

  if (data.length > 0) {
    return (
      <StyledGenericTable
        testId="preference-table"
        columns={columns as any}
        data={data}
        className={clsx(classes.table, isFlagActive(FeatureFlagName.IAT_COMMUNICATION_SETTINGS) && classes.tableWithIAT)}
        columnGroups={columnGroups}
      />
    );
  }
  return (
    <Loader testId="permission-table-loading" />
  );
};

export default PersonalNotificationMatrixTable;
