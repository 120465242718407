import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ palette, typography }) => ({
    widget: {
      padding: '20px',
      background: '#ffffff',
      borderRadius: '10px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
      '@media (max-width: 400px)': {
        marginTop: '10px',
        gridColumn: '1/1 !important',
      },
      fontSize: '16px',
      fontWeight: typography.fontWeightSemiBold,
      minWidth: '390px',
      height: '394px',
    },
    balancesContainer: {
      fontWeight: typography.fontWeightSemiBold,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    title: {
      fontFamily: typography.fontFamily,
      fontSize: '16px',
      fontWeight: typography.fontWeightSemiBold,
      padding: '10px',
      paddingLeft: '0px',
    },
    accountsContainer: {
      overflowY: 'auto',
      maxHeight: '220px',
      padding: '10px',
      '& table > thead:nth-of-type(1)': {
        display: 'none',
      },
    },
    accountsCount: {
      marginLeft: '25px',
      color: '#949494',
      '& span': {
        paddingLeft: '5px',
      },
      '& svg': {
        paddingRight: '10px',
      },
    },
    currency: {
      color: palette.primary.light,
      fontWeight: typography.fontWeightSemiBold,
      paddingLeft: '6px',
    },
    currencyFlag: {
      display: 'flex',
      alignItems: 'center',
      borderRight: '1px solid #eee',
      width: '70px',
    },
    account: {
      paddingLeft: '10px',
    },
    tooltipTitle: {
      paddingBottom: '10px',
      display: 'block',
    },
    marginAccounts: {
      marginLeft: '25px',
      display: 'flex',
      flexDirection: 'column',
      '& td:nth-child(1)': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    marginValues: {
      display: 'flex',
      flexDirection: 'column',
      '& td:nth-child(1)': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    emptyTable: {
      '& .image': {
        height: '120px',
        width: '120px',
        '& img': {
          height: '60',
          width: '60px',
        },
      },
    },
  }), { name: 'BalanceWidget' },
);

export default useStyles;
