import React from 'react';
import t from 'utils/translationHelper';

import { Typography } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import i18n from 'i18n/config';
import useStyles from './InverseFxRateTooltip.styles';

interface IProps {
  text: string | JSX.Element;
  displayInfoCircleIcon?: boolean;
  placement?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
}

const InverseFxRateTooltip = (props: IProps) => {
  const classes = useStyles();

  const {
    text,
    placement,
    displayInfoCircleIcon,
  } = props;

  return (
    <APTooltip
      placement={placement || 'bottom'}
      title={(
        <div className={i18n.language === 'de' ? classes.tooltipText : ''}>
          {t('the_first_rate_shown_is_market_convention')}
        </div>
      )}
    >
      <div className={classes.key}>
        <Typography variant="body1">{text}</Typography>
        {
          displayInfoCircleIcon
          && <FontAwesomeIcon className={classes.icon} icon={faInfoCircle as IconProp} />
        }
      </div>
    </APTooltip>
  );
};

export default InverseFxRateTooltip;
