import React, { FC, memo } from 'react';

import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { Input } from '@alpha/ui-lib/ui/Input';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { t } from 'i18next';
import useStyles from './index.styles';

interface IProps {
  handleShareKeyUp?: (e: any) => void;
  totalAcccounts: number,
}
const Header: FC<IProps> = ({ handleShareKeyUp, totalAcccounts }: IProps) => {
  const classes = useStyles();

  const subtitle = 'Only entities which you have permission to share beneficiaries with will be displayed below.';

  return (
    <div className={classes.header}>
      <div className={classes.headerText} data-testid="info-share-bene">
        <Typography className={classes.pretitle}>
          {t('beneficiary_share')}
        </Typography>
        <b className={classes.title}>
          {t('select_which_entites_to_share_with')}
        </b>
        <Typography className={classes.subtitle}>
          {subtitle}
        </Typography>
      </div>
      <Box className={classes.inputWrapper}>
        <Input
          onKeyUp={handleShareKeyUp}
          fullWidth
          testId="input"
          InputProps={{
            startAdornment: <FontAwesomeIcon
              className={classes.icon}
              icon={faSearch as IconProp}
            />,
          }}
          variant="filled"
          placeholder=''
          disabled={!totalAcccounts}
        />
      </Box>
    </div>
  );
};

export default memo(Header);
