import React from 'react';
import clsx from 'clsx';

import { PaymentDto } from '@alpha/payments-dtos';
import { Box, Typography } from '@alpha/ui-lib/ui/external';

import useStyles from './HeaderText.styles';

const HeaderText: React.FC<{selectedPayment?: PaymentDto}> = ({ selectedPayment }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Typography className={clsx(classes.title, classes.lightWeight)}>
          {selectedPayment?.contractNumber || '-'}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeaderText;
