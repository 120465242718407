import { makeStyles } from '@alpha/ui-lib/ui/external';

const useStyles = makeStyles({
  section: {
    '& > ol': {
      fontSize: '12px',
      marginbottom: '10px',
    },
    '& h3': {
      color: '#1E8777',
      fontWeight: '600',
      fontSize: '12px',
      margin: '0 0 4px 0',
      letterSpacing: '1.8px',
      textTransform: 'uppercase',
    },
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h2': {
      fontSize: '22px',
      fontWeight: '300',
      color: '#222222',
      margin: '0',
    },
    '& svg': {
      marginLeft: '22px',
      color: '#797979',
      width: '28px',
      height: '22px',
      top: '-1px',
      position: 'relative',
    },
    '& .infoPage': {
      '& span': {
        fontSize: '13px',
        color: '#535353',
        fontWeight: '300',
        position: 'relative',
      },
    },
  },
  headerImage: {
    marginRight: 20,
    width: '47px',
    height: '47px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '100%',
    border: '1px solid #eee',
  },
  status: {
    marginTop: '20px',
  },
  subtitle: {
    '& > h4': {
      margin: '3px 0 0 0',
      fontSize: '11px',
      color: 'rgba(52, 52, 52, 0.5)',
      fontWeight: 600,
      letterSpacing: '1.65px',
      textTransform: 'uppercase',
    },
  },
  stats: {
    margin: '40px 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      marginRight: 5,
      '& > div:not(:first-child)': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& > div > p:nth-of-type(2)': {
        fontSize: '22px',
      },
      '& > div:not(:last-child)': {
        marginRight: '34px',
      },
    },

  },
  statusAndButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttons: {
    marginLeft: 20,
    flexGrow: 1,
    alignSelf: 'flex-end',
    display: 'flex',
    height: '60px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      alignSelf: 'flex-end',
      margin: 0,
      marginRight: '10px',
    },
  },
  statsStatus: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '0px !important',
    '& > div:nth-of-type(1) > p': {
      textAlign: 'left',
    },
  },
});

export default useStyles;
