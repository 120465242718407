import React from 'react';
import t from 'utils/translationHelper';
import { useMutation } from 'react-query';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import Warning from '../../../../assets/warning.svg';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import instance from '../../../../services/Axios/instance';
import APModal from '../../../Modals/APModal/APModal';

import useStyles from './index.styles';

type Props = {
  paymentIds: string[];
  open: boolean;
  handleClose: () => void;
  handleReloadPaymentTable?: () => void;
}

const DeleteDraftPaymentModal: React.FC<Props> = ({
  open, paymentIds, handleClose, handleReloadPaymentTable,
}: Props) => {

  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const { logEvent, logError } = useLog();

  const deleteMutation = useMutation((_paymentIds: string[]) => instance.post('/payments/delete-drafts', { paymentIds: _paymentIds }), {
    onSuccess: () => {
      sb.trigger(t('successfully_deleted_payment'), 'info');
      logEvent({ action: 'Successfully deleted payment' });
      handleClose();
      setTimeout(() => {
        if (handleReloadPaymentTable) handleReloadPaymentTable();
      }, 1000);
    },
    onError: (error) => {
      sb.trigger(t('error_deleting_draft_payment'), 'error');
      logError({ action: 'Error deleting draft payment', error });
    },
  });
  return (
    <APModal.Container open={open} onClose={handleClose}>
      <APModal.Wrapper className={styles.container}>
        <div className={styles.innerContainer}>
          <img src={Warning} className={styles.warningStyle} alt="Warning" />
          <div style={{ marginBottom: '48px' }}>
            <Typography className={styles.headerText}>
              {t('are_you_sure_you_want_to_remove')}
            </Typography>
            <Typography>{t('this_action_cannot_be_reversed')}</Typography>
          </div>
          <div style={{ width: '100%' }}>
            <ActionButton size="medium" style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }} onClick={handleClose}>{t('cancel')}</ActionButton>
            <ActionButton size="medium" style={{ width: '170px' }} loading={deleteMutation.isLoading} onClick={() => deleteMutation.mutate(paymentIds)}>{t('delete_draft')}</ActionButton>
          </div>
        </div>
      </APModal.Wrapper>
    </APModal.Container>
  );
};

export default DeleteDraftPaymentModal;
