import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    titleStatusWrapper: {
      marginLeft: 40,
      fontWeight: typography.fontWeightSemiBold,
      display: 'flex',
      alignItems: 'center',
    },
    titleStatus: {
      marginRight: 8,
    },
    icon: {
      marginRight: 5,
    },
  }), { name: 'TitleStatus' },
);

export default useStyles;
