import { CreditFacilityTermStatus } from '@alpha/credit-dtos';

export const mapFacilityTermStatus = (
  status: CreditFacilityTermStatus,
): {
  variant: 'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined, text:
  string
} => {
  switch (status) {
    case CreditFacilityTermStatus.ACTIVE:
      return { variant: 'success', text: 'ACTIVE' };
    case CreditFacilityTermStatus.INACTIVE:
      return { variant: 'default', text: 'INACTIVE' };
    case CreditFacilityTermStatus.PENDING:
      return { variant: 'default', text: 'PENDING' };
    case CreditFacilityTermStatus.REJECTED:
      return { variant: 'default', text: 'REJECTED' };
    case CreditFacilityTermStatus.CANCELLED:
      return { variant: 'default', text: 'CANCELLED' };
    default:
      return { variant: 'default', text: 'N/A' };
  }
};
