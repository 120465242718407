import clipboardCross from 'assets/clipboardCross.svg';
import EmptyTable from 'components/Table/EmptyTable';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { TBeneficiaryApproved, TBeneficiaryPending } from 'models/beneficiaries';
import React, {
  useContext, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import { TStore } from 'store';
import { TAuthyState } from 'store/authy/reducer';
import formatIsoDate from 'utils/formatIsoDate';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { BeneficiaryDto, BeneficiaryStatus } from '@alpha/bene-dtos';
import { Box, Button } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { datadogLogs } from '@datadog/browser-logs';

import { OutstandingActionsContext } from '..';
import BeneficiaryActionDropDown from '../../../../../Beneficiaries/Body/BeneficiaryActionDropdown/BeneficiaryActionDropDown';
import { BeneInfoDrawer } from '../../../../../Beneficiaries/Body/BeneInfoDrawer/BeneInfoDrawer';
import { ModalType } from '../../../../../Beneficiaries/Body/Body';
import DeleteOrRejectModal from '../../../../../Beneficiaries/Body/DeleteOrRejectModal';
import NoOfApprovers from '../../../../../Transactions/Dashboard/FxTradeTable/DisplayTable/NoOfApprovers/NoOfApprovers';
import useStyles from '../index.styles';
import useOutstandingActionCache from '../useOutstandingActionCache';

// eslint-disable-next-line max-lines-per-function
const BeneficiaryApprovals: React.FC = () => {

  const classes = useStyles();
  const { logError, logEvent } = useLog();
  const sb = useAlphaSnackbar();
  const { pendingBenesCount, setPendingBenesCount } = useContext(OutstandingActionsContext);
  const [displayApproversMultiSelect, setDisplayApproversMultiSelect] = useState(false);
  const [currentApproversOptions, setCurrentApproversOptions] = useState<Approver[]>([]);
  const [rejectModalOpen, setRejectModalOpen] = useState<ModalType | false>(false);
  const { currentAccount } = useSwitchAccount();
  const CACHE_KEY = `${currentAccount.id}-pending-beneficiaries`;
  const SHOW_LIMIT = 5;
  const [
    pendingApprovalBeneficiaries,
    setPendingApprovalBeneficiaries,
  ] = useState<BeneficiaryDto[]>([]);
  const [beneficiary, setBeneficiary] = useState<BeneficiaryDto>();

  const { saveResponse, getResponse } = useOutstandingActionCache();
  const handleBeneficiaryButton = (bene: BeneficiaryDto | undefined) => {
    setBeneficiary(bene);
  };

  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  const loadPendingBeneficiaries = async () => {
    try {
      const {
        total,
        records: pendingBenes,
      } = await BeneficiariesService.getPendingApprovalBeneficiaries();
      setPendingApprovalBeneficiaries(pendingBenes);
      if (setPendingBenesCount) { setPendingBenesCount(total); }
      saveResponse(CACHE_KEY, pendingBenes);
      saveResponse(`${CACHE_KEY}_count`, total);
    } catch (e) {
      datadogLogs.logger.error(e);
    }
  };

  const handleOnDeleteClick = async () => {
    try {
      if (!beneficiary) throw Error();

      await BeneficiariesService.deleteBeneficiary(
        beneficiary as TBeneficiaryApproved,
      );

      // Reload Items
      setTimeout(async () => {
        loadPendingBeneficiaries();
      }, 1000);

      setRejectModalOpen(false);
      setBeneficiary(undefined);
      sb.trigger(t('beneficiary_successfully_deleted'), 'success');
      logEvent({ action: 'Successfully deleted beneficiary' });
    } catch (e) {
      sb.trigger(e.message || e.toString() || t('something_went_wrong_deleting_your_beneficiary'));
      logError({ action: 'Error deleting beneficiary', error: e });
    }
  };

  const handleOnRejectedClick = async () => {
    try {
      if (!beneficiary) throw Error();

      await BeneficiariesService.rejectBeneficiary(
        beneficiary as TBeneficiaryPending,
      );

      // Reload Items
      setTimeout(async () => {
        loadPendingBeneficiaries();
      }, 2000);

      setRejectModalOpen(false);
      setBeneficiary(undefined);
      sb.trigger(t('beneficiary_successfully_rejected'), 'success');
      logEvent({ action: 'Successfully rejected beneficiary' });
    } catch (e) {
      sb.trigger(e.message || e.toString() || t('something_went_wrong_rejecting_your_beneficiary'));
      logError({ action: 'Error rejecting beneficiary', error: e });
    }
  };

  useEffect(() => {
    try {
      const cachedBenes = getResponse<BeneficiaryDto[]>(CACHE_KEY);
      const cachedCount = getResponse<number>(`${CACHE_KEY}_count`);
      if (cachedBenes) {
        setPendingApprovalBeneficiaries(cachedBenes as BeneficiaryDto[]);
      }
      if (cachedCount) {
        if (setPendingBenesCount) { setPendingBenesCount(cachedCount); }
      }
    } finally {
      loadPendingBeneficiaries();
    }
  }, []);

  // Handle reload data after actions
  useEffect(() => {
    if (
      authyState.type?.type === 'BENEFICIARY'
    ) {
      setTimeout(() => {
        loadPendingBeneficiaries();
        setBeneficiary(undefined);
      }, 1500);
    }
  }, [authyState.status, authyState.type]);

  const handleBeneficiaryShareButtonClick = () => { };

  const columns: IStyledGenericTableProps['columns'] = [{
    header: t('beneficiary_name'),
    accessor: 'name',
  },
  {
    header: t('country'),
    accessor: 'bankCountryCode',
    align: 'left',
  },
  {
    header: t('currency'),
    accessor: 'currencyCode',
    align: 'right',
  },
  {
    header: t('bank_details'),
    accessor: 'bankDetails',
    align: 'right',
  },
  {
    header: t('upload_date'),
    accessor: 'uploadedByDate',
    align: 'left',
  },
  {
    header: t('approvers'),
    accessor: 'approvals',
    align: 'left',
  },
  {
    header: t('actions'),
    accessor: 'actions',
    align: 'left',
  }];

  const generateData = (): IStyledGenericTableProps['data'] => pendingApprovalBeneficiaries.slice(0, SHOW_LIMIT).map(
    (bene) => ({
      ...bene,
      name: (
        <Button
          disableRipple
          className={classes.tableButton}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            handleBeneficiaryButton(bene);
          }}
        >
          {bene.name}
        </Button>),
      approvals: (bene
        && (
          <NoOfApprovers
            uploadedBy={bene.uploadedBy}
            uploadedDate={bene.uploadedByDate}
            noOfApprovals={bene.approvalInfo?.approvals?.length || 0}
            approvedBy={bene.approvalInfo?.approvals || []}
            approvalsRequired={bene.approvalInfo?.approvalsRequired || 0}
          />
        )
      ) || '-',
      uploadedByDate: formatIsoDate(bene.uploadedByDate),
      bankDetails: <div data-dd-privacy="mask">{(bene.iban && bene.iban.toUpperCase()) || bene.accountNumber || '-'}</div>,
      actions: (
        <BeneficiaryActionDropDown
          beneficiary={bene}
          displayMultiSelect={displayApproversMultiSelect}
          currentOptions={currentApproversOptions}
          setDisplayMultiSelect={setDisplayApproversMultiSelect}
          setCurrentOptions={setCurrentApproversOptions}
          handleReviewButton={handleBeneficiaryButton}
          tabTitle='pending'
        />
      ),
      currencyCode: <span className={classes.beneCurrency}><Flag code={bene.currencyCode} showCodeLabel /></span>,
      bankCountryCode: <span className={classes.beneCurrency}><Flag code={bene.bankCountryCode} showCodeLabel /></span>,
    }
    ),
  );

  const beneData = generateData();
  return (
    <Box style={{
      overflow: 'auto',
      height: '230px',
    }}
    >
      {beneData.length > 0 ? (
        <>
          <StyledGenericTable
            testId="pending-beneficiaries-table"
            columns={columns}
            data={beneData}
            className={classes.stickyHeaderTable}
          />

          {pendingBenesCount && pendingBenesCount > SHOW_LIMIT ? (
            <div className={classes.showAllMessage}>
              <span>
                {t('showing_top')}
                {' '}
                {SHOW_LIMIT}
                {' '}
                {t('items_please_click_to_view_all_items')}
              </span>
            </div>
          ) : null}
        </>
      ) : (
        <EmptyTable
          title={t('no_pending_benficary_approvals')}
          subtitle={t('beneficiary_awaiting_approval_display')}
          className={classes.emptyTable}
          icon={clipboardCross}
        />
      )}

      <BeneInfoDrawer
        selectedBeneficiary={{
          beneficiary,
          approved: beneficiary?.status === BeneficiaryStatus.CLIENT_APPROVED,
        }}
        beneDrawerOpen={Boolean(beneficiary)}
        closeDrawer={() => setBeneficiary(undefined)}
        handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
        setModalOpen={setRejectModalOpen}
        canShareBeneficiary={false}
      />
      {beneficiary && (
        <DeleteOrRejectModal
          modalType="reject"
          loading={false}
          modalOpen={Boolean(rejectModalOpen)}
          handleCloseModal={() => { setRejectModalOpen(false); }}
          handleOnDeleteClick={handleOnDeleteClick}
          handleOnRejectionClick={handleOnRejectedClick}
        />
      )}
    </Box>
  );
};
export default BeneficiaryApprovals;
