import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';

type TSnackbarVariant = 'info' | 'error' | 'success' | 'warning';

const useAlphaSnackbar = () => {
  const snackbar = originalUseSnackbar();
  const trigger = (
    message: any,
    type: TSnackbarVariant = 'error',
    options?: any,
  ): void => {
    snackbar.enqueueSnackbar(message, {
      variant: type,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      preventDuplicate: true,
      key: message,
      style: { whiteSpace: 'pre-wrap' as any, borderRadius: '4px' },
      persist: false,
      ...options,
    });
  };

  const close = (key: string) => {
    snackbar.closeSnackbar(key);
  };

  return {
    trigger,
    close,
  };
};

export default useAlphaSnackbar;
