import { BeneficiaryCopMatchStatus, CopResponse, ValidateBeneficiaryRequest } from "@alpha/bene-dtos";
import { TKeyValue } from "@alpha/ui-lib/ui/table";
import React from "react";
import { ReactNode } from "react";
import { Trans } from "react-i18next";
import t from "utils/translationHelper";
import { faCheck, faTimesCircle, faMinusCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { renderToString } from "react-dom/server";


type CopMatchType = "Full Match" | "Close Match" | "No Match" | "Unavailable";
type COPDataType = {
  applyFinance: {
    valid: boolean,
    errorMessage?: string,
  },
  accountDetail: {
    matchType: CopMatchType,
    closeMatchMessage: ReactNode,
    closeMatchMessagePlainText: string,
    closeMatchDetail: TKeyValue[],
    matchDetail: TKeyValue[],
  }
}

const copMessage = (copResponse?: CopResponse, values?: ValidateBeneficiaryRequest) => {
  const enteredName = values?.name;
  const furtherToAccountNumber = values?.furtherToAccountNumber;
  if (!copResponse) return null;

  if (copResponse.Status === BeneficiaryCopMatchStatus.INVALID_SECONDARY_REFERENCE
    && !!furtherToAccountNumber
  ) {
    return <Trans
      components={{ strong: <strong /> }}
      children={`cop_InvalidSecondaryReference_incorrect_message`}
    />
  }

  if (copResponse.Status === BeneficiaryCopMatchStatus.NEAR_MATCH) {
    return <Trans
      components={{ strong: <strong /> }}
      children={`cop_NearMatch_message`}
      values={{ name: enteredName ?? "", correct_name: copResponse.Name ?? "" }}
    />
  }

  if (copResponse.Status === BeneficiaryCopMatchStatus.NEAR_MATCH_WRONG_ACCOUNT_TYPE) {
    const correctName = copResponse.Name ?? "";
    return <Trans
      components={{ strong: <strong /> }}
      children={`cop_NearMatchWrongAccountType_message`}
      values={{ name: enteredName ?? "", correct_name: correctName }}
    />
  }

  return <Trans
    components={{ strong: <strong /> }}
    children={`cop_${copResponse.Status}_message`} />;
}

const matchedIcon = (matched: boolean | undefined) => {
  if (matched === undefined) return <FontAwesomeIcon icon={faMinusCircle} color='#c4c4c4' style={{ marginLeft: '16px' }} />
  return matched ? <FontAwesomeIcon icon={faCheck} color='#51A869' style={{ marginLeft: '16px' }} /> : <FontAwesomeIcon icon={faTimesCircle} color='#A62B23' style={{ marginLeft: '16px' }} />
}

const mapMatchType = (type?: BeneficiaryCopMatchStatus): CopMatchType => {
  if (!type) { return "Unavailable" }
  if (type === BeneficiaryCopMatchStatus.MATCH) {
    return "Full Match";
  }
  else if ([
    BeneficiaryCopMatchStatus.MATCH_WRONG_ACCOUNT_TYPE,
    BeneficiaryCopMatchStatus.ACCOUNT_SWITCHED,
    BeneficiaryCopMatchStatus.NEAR_MATCH_WRONG_ACCOUNT_TYPE,
    BeneficiaryCopMatchStatus.NEAR_MATCH,
  ].includes(type)) {
    return "Close Match";
  } else if ([BeneficiaryCopMatchStatus.NO_MATCH].includes(type)) {
    return "No Match";
  } else {
    return "Unavailable";
  }
};

const mapFieldStatus = (status: BeneficiaryCopMatchStatus, AFValid?: boolean) => {
  if (mapMatchType(status) === "Unavailable") {
    return {
      name: undefined,
      type: undefined,
      nationalBankCode: undefined,
      accountNumber: undefined,
      swift: undefined,
      iban: undefined
    }
  }

  const bankDetailStatus = (
    // Either in these status, or having no match but passing AF validation
    [BeneficiaryCopMatchStatus.MATCH,
    BeneficiaryCopMatchStatus.MATCH_WRONG_ACCOUNT_TYPE,
    BeneficiaryCopMatchStatus.NEAR_MATCH,
    BeneficiaryCopMatchStatus.NEAR_MATCH_WRONG_ACCOUNT_TYPE].includes(status) ||
    (status === BeneficiaryCopMatchStatus.NO_MATCH && AFValid));

  return {
    name: [
      BeneficiaryCopMatchStatus.MATCH,
      BeneficiaryCopMatchStatus.MATCH_WRONG_ACCOUNT_TYPE,
      BeneficiaryCopMatchStatus.ACCOUNT_SWITCHED].includes(status),
    type: [
      BeneficiaryCopMatchStatus.MATCH,
      BeneficiaryCopMatchStatus.NEAR_MATCH].includes(status) ||
      (status === BeneficiaryCopMatchStatus.NO_MATCH && AFValid),
    nationalBankCode: bankDetailStatus,
    accountNumber: bankDetailStatus,
    swift: bankDetailStatus,
    iban: bankDetailStatus
  }
}

const getBeneType = (type: string, another?: boolean) => {
  if (another) {
    return type === 'INDIVIDUAL' ? t('Business') : t('Individual');
  } else {
    return type === 'INDIVIDUAL' ? t('Individual') : t('Business');
  }
}

const getCloseMatchTable = (COPResponse: CopResponse, formValues: ValidateBeneficiaryRequest) => {
  const closeMatchDetail: TKeyValue[] = [];

  if (COPResponse?.Name && COPResponse.Status === BeneficiaryCopMatchStatus.NEAR_MATCH) {
    closeMatchDetail.push({ key: t("Name Entered"), value: <>{formValues.name}</> });
    closeMatchDetail.push({ key: t("Name Found"), value: <b>{COPResponse?.Name}</b> });
  }

  if (COPResponse?.Status === BeneficiaryCopMatchStatus.MATCH_WRONG_ACCOUNT_TYPE) {
    closeMatchDetail.push({ key: t("Beneficiary Type Entered"), value: <>{getBeneType(formValues.type ?? '')}</> });
    closeMatchDetail.push({ key: t("Beneficiary Type Found"), value: <b>{getBeneType(formValues.type ?? '', true)}</b> });
  }

  if (COPResponse?.Status === BeneficiaryCopMatchStatus.NEAR_MATCH_WRONG_ACCOUNT_TYPE) {
    closeMatchDetail.push({ key: t("Name Entered"), value: <>{formValues.name}</> });
    closeMatchDetail.push({ key: t("Name Found"), value: <><b>{COPResponse?.Name}</b></> });
  }
  return closeMatchDetail;
}

const getMatchedTable = (COPResponse: CopResponse, formValues: ValidateBeneficiaryRequest, AFErrored?: boolean) => {
  const bankFields: TKeyValue[] = [];
  const fieldStatus = mapFieldStatus(COPResponse?.Status ?? BeneficiaryCopMatchStatus.NOT_AVAILABLE, !AFErrored);

  if (COPResponse?.Status && ![BeneficiaryCopMatchStatus.NEAR_MATCH, BeneficiaryCopMatchStatus.NEAR_MATCH_WRONG_ACCOUNT_TYPE].includes(COPResponse?.Status)) {
    bankFields.push({
      key: t("Name Entered"),
      value: <>{formValues?.name} {matchedIcon(fieldStatus.name)}</>,
    });
  }

  if (formValues?.type && COPResponse?.Status &&
    ![BeneficiaryCopMatchStatus.MATCH_WRONG_ACCOUNT_TYPE].includes(COPResponse?.Status)) {
    bankFields.push(
      {
        key: t("beneficiary_type"),
        value: <>{getBeneType(formValues?.type)} {matchedIcon(fieldStatus.type)}</>,
      }
    )
  }

  if (formValues?.nationalBankCode) {
    bankFields.push(
      {
        key: t("Sort Code"),
        value: <>{formValues?.nationalBankCode} {matchedIcon(fieldStatus.nationalBankCode)}</>,
      }
    )
  }
  if (formValues?.swift) {
    bankFields.push(
      {
        key: t('Swift'),
        value: <>{formValues?.swift} {matchedIcon(fieldStatus.swift)}</>,
      }
    )
  }
  if (formValues?.iban) {
    bankFields.push(
      {
        key: t("iban"),
        value: <>{formValues?.iban} {matchedIcon(fieldStatus.iban)}</>,
      }
    )
  }

  if (formValues?.accountNumber) {
    bankFields.push(
      {
        key: t('account_number'),
        value: <>{formValues?.accountNumber} {matchedIcon(fieldStatus.accountNumber)}</>,
      }
    )
  }

  return bankFields;
}

const usePayeeConfirmation = (
  formValues: ValidateBeneficiaryRequest,
  AFErrored?: boolean,
  AFErrors?: string[],
  COPResponse?: CopResponse,
) => {
  const closeMatchDetail = COPResponse ? getCloseMatchTable(COPResponse, formValues) : [];
  const matchedTable = COPResponse ? getMatchedTable(COPResponse, formValues, AFErrored) : [];
  const closeMatchMessage = copMessage(COPResponse, formValues);

  const COPData = {
    applyFinance: {
      valid: !(AFErrored),
      errorMessage: AFErrors?.map((err) => `${err}`).join('\n'),
    },
    accountDetail: {
      matchType: mapMatchType(COPResponse?.Status),
      closeMatchMessage: closeMatchMessage,
      closeMatchMessagePlainText: renderToString(closeMatchMessage ?? <></>) ?? '',
      closeMatchDetail: closeMatchDetail,
      matchDetail: [
        ...matchedTable
      ],
    }
  } as COPDataType;
  return { COPData };
};

export default usePayeeConfirmation;
