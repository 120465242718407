import APModal from 'components/Modals/APModal/APModal';
import React from 'react';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import useStyles from './index.styles';

type Props = {
  open: boolean;
  handleClose: () => void;
}

const InfoModal: React.FC<Props> = (
  {
    open, handleClose,
  }: Props,
) => {
  const styles = useStyles();

  return (
    <APModal.Container open={open} onClose={handleClose}>
      <>
        <APModal.Header
          onClose={() => {
            handleClose();
          }}
          showCloseButton
        >
          <Typography className={styles.modalHeader}>
            {t('understanding_the_mtm_summary_graph')}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={styles.container}>
          <div className={styles.inputContainer}>
            {t('the_mtm_summary_graph_is_used_to_visually_identify_your_current_mtm_position')}
            <br />
            <span className={styles.greyText}>{t('below_is_a_breakdown_of_the_colours_used_to_visualise_this_information')}</span>

            <div className={styles.graphInfo}>
              <div aria-label="greenRectangle" className={styles.colorLegend} style={{ background: '#1E8777' }} />
              <div className={styles.textWrapper}>
                <p className={styles.title}>{t('green_is_used_to_show_your_mtm_is_in_a_positive_position')}</p>
              </div>
            </div>

            <div className={styles.graphInfo}>
              <div aria-label="amberRectangle" className={styles.colorLegend} style={{ background: '#FFC107' }} />
              <div className={styles.textWrapper}>
                <p className={styles.title}>
                  {t('amber_is_used_to_show_mtm_is_in_negative_position')}
                </p>
              </div>
            </div>

            <div className={styles.graphInfo}>
              <div aria-label="redRectangle" className={styles.colorLegend} style={{ background: '#D55E5E' }} />
              <div className={styles.textWrapper}>
                <p className={styles.title}>
                  {t('red_is_used_to_show_your_mtm_is_in_a_negative_position')}
                </p>
              </div>
            </div>

          </div>
          <Box className={styles.footer}>
            <ActionButton
              style={{
                borderRadius: '4px', textTransform: 'none', background: '#F7F7F7', color: '#212529',
              }}
              onClick={handleClose}
            >
              {t('close')}
            </ActionButton>
          </Box>
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default InfoModal;
