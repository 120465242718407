/* eslint-disable max-lines-per-function */
import AvailableWidgets from 'models/dashboard';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import t from 'utils/translationHelper';

import {
  DashboardUserAccountPreferencesDto,
  DashboardWidget,
} from '@alpha/profile-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useLog from '../../../hooks/useLog';
import DashboardService from '../../../services/Dashboard/dashboard.service';
import useWidgetPermission from '../useWidgetPermission';

import useStyles from './RightAlignedCta.styles';

interface IRightAlignedCtaProps {
  dashboardSettings?: DashboardUserAccountPreferencesDto;
  setDashboardSettings?: React.Dispatch<
    React.SetStateAction<DashboardUserAccountPreferencesDto | undefined>
  >;
  viewablePages?: string[];
}

const RightAlignedCta = ({
  dashboardSettings,
  setDashboardSettings,
  viewablePages,
}: IRightAlignedCtaProps) => {
  const classes = useStyles();
  const { logEvent, logError } = useLog();
  const sb = useAlphaSnackbar();
  const [openDashboardSettings, setOpenDashboardSettings] = useState<null | HTMLElement>(null);
  const [editSettings, setEditSettings] = useState<DashboardUserAccountPreferencesDto>();
  const [menuLiveItem, setMenuLiveItems] = useState<any[]>([]);
  const [saving, setSaving] = useState(false);
  const facilityMode = useSelector<TStore, string | undefined>(
    (state: TStore) => state.creditFacility.facilityMode,
  );

  const { isWidgetEnabled, isViewableForUser } = useWidgetPermission(viewablePages);

  const saveSettings = async () => {
    if (editSettings) {
      try {
        logEvent({
          action: 'Dashboard - Save Settings',
          detail: { widgets: editSettings.widgets },
        });
        await DashboardService.postUpdateUserDashboardSettings(editSettings);
        if (setDashboardSettings) {
          setDashboardSettings(editSettings);
          logEvent({ action: 'Dashboard - Save Settings Success' });
          sb.trigger(t('saved'), 'success');
        }
      } catch (e) {
        sb.trigger(t('error_saving_dashboard_settings'), 'error');
        logError({ action: 'Dashboard - Save Settings Error', error: e });
        setEditSettings(dashboardSettings);
      } finally {
        setSaving(false);
      }
    }
    setOpenDashboardSettings(null);
  };

  const editDashboardMenuItems = () => {
    const menuItems = [
      {
        content: <div className={classes.title}>{t('available_widgets')}</div>,
        disableHoverEffect: true,
        disableClick: true,
        arrow: false,
        key: 'title',
        underline: true,
      },
    ];

    const updateCheckbox = (key: DashboardWidget, checked: boolean): void => {
      if (!editSettings || !editSettings.widgets) return;
      const widgets = [...editSettings.widgets];

      if (checked) {
        if (widgets.indexOf(key) === -1) {
          widgets.push(key);
        }
      } else {
        widgets.splice(widgets.indexOf(key), 1);
      }
      const copyEditSettings = { ...editSettings };
      copyEditSettings.widgets = widgets;
      setEditSettings(copyEditSettings);
    };

    Object.keys(AvailableWidgets).forEach((key) => {
      const widget = (AvailableWidgets as any)[key];

      if (
        widget === AvailableWidgets.CURRENCY_ACCOUNT_BALANCES
        || widget === AvailableWidgets.OUTSTANDING_ACTIONS
        || (facilityMode === 'multiple'
          && widget === AvailableWidgets.FACILITY_TERMS)
        || (facilityMode === 'single' && widget === AvailableWidgets.MTM)
      ) {
        menuItems.push({
          content: (
            <Checkbox
              testId={widget}
              label={t(widget)}
              disabled
              checked={isWidgetEnabled(editSettings, key)}
            />
          ),
          disableHoverEffect: true,
          disableClick: true,
          arrow: false,
          key: widget.toString(),
          underline: false,
        });
      } else if (isViewableForUser(key as DashboardWidget)) {
        menuItems.push({
          content: (
            <Checkbox
              disabled={saving}
              testId={widget}
              label={t(widget)}
              checked={isWidgetEnabled(editSettings, key)}
              onChange={(_, checked) => {
                updateCheckbox(key as DashboardWidget, checked);
              }}
            />
          ),
          disableHoverEffect: true,
          disableClick: true,
          arrow: false,
          key: widget.toString(),
          underline: false,
        });
      }
    });
    menuItems.push({
      content: (
        <div className={classes.buttonContainer}>
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              setOpenDashboardSettings(null);
              logEvent({ action: 'Dashboard - Edit Cancel' });
              setEditSettings(dashboardSettings);
            }}
          >
            {t('cancel')}
          </ActionButton>
          <ActionButton
            loading={saving}
            disabled={saving}
            onClick={() => {
              setSaving(true);
              saveSettings();
            }}
          >
            {t('apply')}
          </ActionButton>
        </div>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      key: 'action-buttons',
      underline: false,
    });
    return menuItems;
  };

  useEffect(() => {
    setMenuLiveItems(editDashboardMenuItems());
  }, [editSettings, editSettings?.widgets]);

  useEffect(() => {
    setEditSettings({
      ...dashboardSettings,
    } as DashboardUserAccountPreferencesDto);
    if (openDashboardSettings) {
      logEvent({ action: 'Dashboard - Edit Dashboard' });
    }
  }, [openDashboardSettings, dashboardSettings]);

  return (
    <div className={classes.menuButton}>
      <APMenu
        buttonTitle="Edit Your Dashboard"
        open={Boolean(openDashboardSettings)}
        setOpen={setOpenDashboardSettings}
        anchorEl={openDashboardSettings}
        buttonContent={(
          <div>
            {saving ? (
              <Loader
                testId="edit-dashboard-loading"
                size={10}
                className={classes.loading}
              />
            ) : (
              <FontAwesomeIcon
                icon={faScrewdriverWrench}
                className={classes.editButtonIcon}
              />
            )}
            <div>
              <span>{saving ? 'Saving settings' : t('edit_dashboard')}</span>
            </div>
          </div>
        )}
        menuProps={{
          className: classes.editDashboardMenu,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
      >
        {menuLiveItem.map((item) => (
          <MenuDropdownItem key={item.key} setOpen={setOpenDashboardSettings}>
            {item}
          </MenuDropdownItem>
        ))}
      </APMenu>
    </div>
  );
};

export default RightAlignedCta;
