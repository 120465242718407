import React, { useEffect } from 'react';
import Alert from 'components/Alert';
import APModal from 'components/Modals/APModal/APModal';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useAuthorization from 'hooks/useAuthorization';
import { UserRole } from 'models/user';
import moment from 'moment';
import t from 'utils/translationHelper';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';
import * as authyActions from 'store/authy/actions';
import { TAuthyState } from 'store/authy/reducer';
import { formatNumber } from 'utils/currency.helpers';
import mapTradeTypeToFriendlyName from 'utils/fxTrades/mapTradeTypeToFriendlyName';

import { TradeDto } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Button, Typography } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { StyledStatSummary } from '@alpha/ui-lib/ui/StatSummary/StyledStatSummary/StyledStatSummary';
import { Status } from '@alpha/ui-lib/ui/Status/Status';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './TradePadAuthoriseModal.styles';

const TradePadAuthoriseModal = ({
  trade, open, handleClose,
}: {
  trade: TradeDto | undefined;
  handleClose: () => void;
  open: boolean;
}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const authorisedPadApprover = useAuthorization([[UserRole.PAD_APPROVER]]);
  const sb = useAlphaSnackbar();
  const flagSize = 'md';

  const handleAuthorisePad = (tradeToBeAuthorised: TradeDto) => {
    if (!authorisedPadApprover) {
      sb.trigger(t('you_do_not_have_rights_to_authorise_pad'));
      return;
    }
    dispatch(
      authyActions.initiateTradePADApprove({
        type: 'TRADE_PAD_APPROVE',
        id: tradeToBeAuthorised.id,
      }),
    );
  };

  useEffect(() => {
    if (
      authyState.type?.type === 'TRADE_PAD_APPROVE'
      && authyState.status === 'SUCCESS'
    ) {
      handleClose();
      sb.trigger(`${t('pad_authorised_for_trade')} ${trade?.contractNumber}.`, 'success');
    }
  }, [authyState.status, authyState.type]);

  if (!trade) return <></>;

  const tradeSummaryData = [{
    key: t('trade_type'),
    value: mapTradeTypeToFriendlyName(trade?.type) || '-',
  },
  {
    key: t('currency_pair'),
    value: [trade.soldCurrencyCode, trade.buyCurrencyCode].join('/'),
  },
  {
    key: t('trade_date'),
    value: trade?.date ? moment(trade?.date).format('DD/MM/YYYY') : '-',
  },
  {
    key: t('value_date'),
    value: trade?.valueDate ? moment(trade?.valueDate).format('DD/MM/YYYY') : '-',
  },

  ];
  const tradeBalanceSummaryData = [
    {
      key: t('sell_amount'),
      value: (
        <>
          <span className={classes.flag}>
            <Flag size={flagSize} code={trade?.soldCurrencyCode} />
          </span>
          {' '}
          {formatNumber(trade?.soldAmount || 0, 2)}
          {' '}
          {trade?.soldCurrencyCode}
        </>),
    },
    {
      key: t('rate'),
      value: (
        <span className={classes.greenText}>
          {trade?.rate ? formatNumber(trade?.rate || 0, 4) : '-'}
        </span>
      ),
    },
    {
      key: t('buy_amount'),
      value: (
        <>
          <span className={classes.flag}>
            <Flag size={flagSize} code={trade?.buyCurrencyCode} />
          </span>
          {' '}
          {formatNumber(trade?.buyAmount || 0, 2)}
          {' '}
          {trade?.buyCurrencyCode}
        </>),
    },
    {
      key: t('remaining_buy_balance'),
      value: (
        <>
          <span className={classes.flag}>
            <Flag size={flagSize} code={trade?.buyCurrencyCode} />
          </span>
          {' '}
          {formatNumber(trade?.buyBalance || 0, 2)}
          {' '}
          {trade?.buyCurrencyCode}
        </>),
    },
    {
      key: t('remaining_sell_balance'),
      value: (
        <>
          <span className={classes.flag}>
            <Flag size={flagSize} code={trade?.soldCurrencyCode} />
          </span>
          {' '}
          {formatNumber(trade?.sellBalance || 0, 2)}
          {' '}
          {trade?.soldCurrencyCode}
        </>),
    },
  ];

  return (
    <APModal.Container
      open={open}
      onClose={handleClose}
    >
      <>
        <APModal.Header onClose={handleClose}>
          <Typography className={classes.modalHeader}>
            <Status variant="default">{t('pad')}</Status>
            <span style={{ paddingLeft: '8px' }} />
            {' '}
            {t('pad_approval')}
            {' '}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={classes.modalWrapper}>
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Box flexGrow={1} flexDirection="column" minHeight="300px" marginTop="20px" gridGap="20px" display="flex">
              <span>{t('this_trade_is_eligible_to_be_approved_for_pad')}</span>
              <Typography variant="h6">{t('trade_details')}</Typography>
              <StyledStatSummary data={tradeSummaryData} />
              <StyledStatSummary data={tradeBalanceSummaryData} />
              <Typography variant="h6">{t('are_you_sure_you_want_to_approve_trade_for_pad')}</Typography>
              <Typography>
                {t('funds_will_be_drawn_from_your_associated_approval_account')}
              </Typography>
              <Alert text={t('please_note_2fa_is_required_and_will_need_to_be_completed_at_the_next_step_to_approve')} variant="info" />
            </Box>
            <Box marginLeft="auto" marginTop="24px" gridGap="8px" display="flex">
              <ActionButton
                size="medium"
                style={{ background: '#F7F7F7', color: '#212529' }}
                className={classes.cancelBtn}
                onClick={handleClose}
              >
                {t('cancel')}
              </ActionButton>
              <Button
                className={classes.authoriseBtn}
                onClick={() => {
                  handleAuthorisePad(trade);
                }}
              >
                <FontAwesomeIcon icon={faLockKeyhole as IconProp} color="#FFFFFF" />
                <span className={classes.buttonText}>{t('authorise_for_pad')}</span>
              </Button>
            </Box>
          </Box>
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default TradePadAuthoriseModal;
