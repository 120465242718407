import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '20px',
    padding: '0 8px !important',
  },
  modalContainer: {
    height: '441px',
    width: '600px',
    borderRadius: '10px',
  },
  modalHeader: {
    padding: '12px 24px 24px 30px',
    fontWeight: 600,
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',

  },
  inputContainer: {
    padding: '0px 20px 10px 25px',
  },
  footer: {
    position: 'relative',
    borderTop: '1px solid rgba(112, 112, 112, .1)',
  },
  btn: {
    justifyContent: 'flex-end',
    display: 'flex',
    padding: '20px 20px 0px 0px',
  },
  multilineContainer: {
    marginTop: '30px',
    marginBottom: '20px',
  },
}), { name: 'AmendmentRequestModal' });

export default useStyles;
