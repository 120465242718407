/* eslint-disable max-len */
import i18n from 'i18n/config';
import React from 'react';
import t from 'utils/translationHelper';

import { Alert } from '@alpha/ui-lib/ui/Alert';
import { Card } from '@alpha/ui-lib/ui/layout/Card';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';

import useSwitchAccount from '../../hooks/useSwitchAccount';

import useStyles from './MarketOrders.styles';
import MarketOrdersTable from './MarketOrdersTable/MarketOrdersTable';

const MarketOrders: React.FC = () => {
  const { homePageUrl } = useSwitchAccount();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];
  const styles = useStyles();
  return (
    <APMainLayout
      height="167px"
      title={t('market_orders')}
      breadCrumbs={breadcrumbs}
      sticky
    >
      <div style={{
        minHeight: 'calc(100vh - 310px)',
        paddingTop: '12px',
        paddingBottom: '30px',
        backgroundColor: '#fafafa',
      }}
      >
        <Card>
          <div className={styles[i18n.language as keyof typeof styles]}>
            <Alert
              variant="info"
              text={`${t('note_to_edit_or_cancel_orders')} ${t('phone_number')}.`}
            />
          </div>
          <MarketOrdersTable />
        </Card>
      </div>
    </APMainLayout>
  );
};

export default MarketOrders;
