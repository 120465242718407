import { AxiosRequestConfig } from 'axios';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import {
  PaymentDateOptionsDto,
  PaymentDto,
  PaymentPurposeDto,
  PaymentStatus,
} from '@alpha/payments-dtos';

import { TCurrencyPair } from './currencies';

export type TDate = PaymentDateOptionsDto;

export type TChosenPaymentPurpose = {
  required: boolean;
  option: PaymentPurposeDto;
};

export type TPayment = {
  reference: string;
  date: string;
  debitingAccountId?: string;
  debitingAccountCurrencyCode?: string;
  fixedSide?: string;
  purposeOfPaymentCode?: string;
  paymentAmount: number;
  beneficiary?: BeneficiaryDto;
};

export type TBeneficiaryDraft = {
  batchLineNumber: number;
  batchId: string;
  uploadedDate: string;
  accountId: string;
  bankCountryCode: string;
  iban: string;
  name: string;
  id: string;
  uploadedBy: string;
  errors: [];
  thirdPartyValidationRecommendations: [];
  status: PaymentStatus;
  swift: string;
};

export type TPaymentAction =
  | 'View'
  | 'Export'
  | 'Approve'
  | 'Discard'
  | 'Remove';

export type TPurposeOfPayment = string;

export type TBankDetailsField = {
  MinLength: number;
  MaxLength: number;
  RegEx: string;
  Example: string;
  FriendlyName: string;
  Name: string;
};

export type TBankDetails = TBankDetailsField[];

export type TBankDetailsValidationField = {
  Name: string;
  Error: string | null;
  Value: string | null;
};

export type TBankDetailsData = {
  [key: string]: string;
};

export type TBankDetailsValidation = TBankDetailsValidationField[];

// anything below this point is part of the refactor

export type TBatchId = {
  batchId: string;
};

export type TPendingPaymentParams = {
  batchId: string;
  currencyPair?: TCurrencyPair | null;
};

export type TPendingPayment = {
  paymentId: string;
  batchId: string;
  accountId: string;
  beneficiaryId?: string;
  beneficiaryClientName: string;
  beneficiaryClientBankCountry: string;
  beneficiaryClientIban?: string;
  beneficiaryAlphaIban?: string;
  beneficiaryClientAccountNumber?: number | null;
  beneficiaryAlphaAccountNumber?: number;
  beneficiaryClientSwift?: string;
  beneficiaryAlphaSwift?: string;
  beneficiaryClientNationalBankCode?: number | null;
  beneficiaryAlphaNationalBankCode?: number;
  beneficiaryClientCorrespondentAccountNumber?: number | null;
  beneficiaryClientCorrespondentSwift?: string;
  beneficiaryClientEmailAddress?: string;
  sellCurrencyCode: string;
  sellAmount?: number;
  buyCurrencyCode: string;
  buyAmount: number;
  purpose?: string;
  reference?: string;
  date?: string;
  settlementRequiredBy?: string;
  paymentStatus: PaymentStatus;
  batchLineNumber?: number;
  afBankName?: string;
};

export interface IGetPresignedUrlInstanceParams extends AxiosRequestConfig {
  // data: CreateBatchRequest;
  data: any;
}

// export type TBatchUploadLinkDto = BatchUploadLinkDto;
export type TBatchUploadLinkDto = any;
export type TPaymentDraftDto = PaymentDto;

export enum PaymentRouteEnum {
  BASE = 'base',
  FX_TRADES = 'fx_trades',
}

export type TPaymentSubmissionResponse = { pending: boolean, success: boolean, submitResult?: {
  tradeSubmissionSuccess: boolean,
  tradeId: string,
  tradeSubmissionFriendlyErrorMessage: string,
}}
