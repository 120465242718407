import React from 'react';
import MultiSelectSection from 'domain/Beneficiaries/Body/BeneficiaryActionDropdown/MultiSelectSection/MultiSelectSection';
import useActionDropdown from 'hooks/useActionDropDown';
import useAuthorization from 'hooks/useAuthorization';
import useReportsPolling, { DownloadType, FileType, PageType } from 'hooks/useReportsPolling';
import { UserRole } from 'models/user';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { shouldShowTransferApprovalButtons } from 'utils/transfers/shouldShowTransferApprovalButtons';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { CurrencyAccountTransferDto, CurrencyAccountTransferStatus } from '@alpha/currency-accounts-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import useStyles from './ActionDropDown.styles';

interface IProps {
  transfer?: CurrencyAccountTransferDto;
  displayMultiSelect: boolean,
  setDisplayMultiSelect: (val: boolean) => void,
  setSelectedTransfer?: React.Dispatch<
    React.SetStateAction<CurrencyAccountTransferDto | undefined>>,
  handleReloadTransferTable?: () => void;
  handleApproveTransfer?: (transfer: CurrencyAccountTransferDto) => void;
  handleRejectTransfer?: (transfer: CurrencyAccountTransferDto) => void;
  setCurrentOptions?: React.Dispatch<React.SetStateAction<Approver[]>>,
  setTextValue?: React.Dispatch<React.SetStateAction<string>>,
  currentOptions?: Approver[];
}

const TransferActionDropDown: React.FC<IProps> = ({
  displayMultiSelect,
  setDisplayMultiSelect,
  transfer,
  setSelectedTransfer,
  handleReloadTransferTable,
  handleApproveTransfer,
  handleRejectTransfer,
  currentOptions,
  setCurrentOptions,
  setTextValue,
}) => {
  const classes = useStyles();

  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);
  const { authorized: canApproveOwn } = useAuthorization(
    [[UserRole.IAT_APPROVER_OWN]],
  );
  const { authorized: canApproveOther } = useAuthorization(
    [[UserRole.IAT_APPROVER]],
  );
  const { authorized: authorizedIATInputter } = useAuthorization([[UserRole.IAT_INPUTTER]]);

  const { executeReportGeneration } = useReportsPolling(PageType.Transfers);
  const handleReportGeneration = async (
    type: FileType, downloadType: DownloadType,
  ) => {
    if (transfer) {
      await executeReportGeneration(type, transfer.id, undefined, undefined, downloadType, type);
    }
  };

  const {
    open,
    loading,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    setOpen,
    handleRenderDropdown,
    approvers,
    submitApproverEmails,
  } = useActionDropdown({
    currentOptions,
  });

  const menuItems = [];
  if (transfer && transfer.transferStatus === CurrencyAccountTransferStatus.SUBMITTED
    && authorizedIATInputter && currentOptions && setCurrentOptions && setTextValue) {
    menuItems.push({
      key: 'reminder',
      content: (
        <MultiSelectSection
          setMenuCloseCount={setMenuCloseCount}
          menuCloseCount={menuCloseCount}
          setOpen={setOpen}
          displayMultiSelect={displayMultiSelect}
          loading={loading}
          id={transfer?.id}
          approvers={approvers}
          currentOptions={currentOptions}
          setCurrentOptions={setCurrentOptions}
          setTextValue={setTextValue}
          setDisplayMultiSelect={setDisplayMultiSelect}
          submitApproverEmails={submitApproverEmails}
          type="Transfer"
        />
      ),
      onClick: () => handleRenderDropdown(
        displayMultiSelect,
        transfer.id,
        'Transfer',
        setDisplayMultiSelect,
      ),
      disableClick: true,
      icon: !displayMultiSelect ? faEnvelope : undefined,
      disableHoverEffect: displayMultiSelect,
      underline: true,
    });
  }

  if (transfer && userId
    && shouldShowTransferApprovalButtons(
      canApproveOther, canApproveOwn, [transfer], userId, true,
    )) {
    menuItems.push({
      key: 'approve',
      content: (
        <Typography data-testid={`approve-transfer-info-action-${transfer?.id}`}>
          {t('approve_transfer')}
        </Typography>
      ),
      icon: displayIcon('Approve'),
      onClick: () => {
        if (handleApproveTransfer && transfer) handleApproveTransfer(transfer);
      },
      underline: true,
    });
  }

  if (transfer && userId
    && shouldShowTransferApprovalButtons(
      canApproveOther, canApproveOwn, [transfer], userId, false,
    )) {
    menuItems.push({
      key: 'reject',
      content: (
        <Typography data-testid={`reject-transfer-info-action-${transfer?.id}`}>
          {t('reject_transfer~')}
        </Typography>
      ),
      icon: displayIcon('Reject'),
      onClick: () => {
        if (handleRejectTransfer && transfer) handleRejectTransfer(transfer);
      },
      underline: true,
    });
  }

  if (transfer) {
    menuItems.push({
      key: 'view',
      content: (
        <Typography data-testid={`view-transfer-info-action-${transfer?.id}`}>
          {t('view_transfer_information')}
        </Typography>
      ),
      icon: displayIcon('View'),
      onClick: () => {
        if (setSelectedTransfer) setSelectedTransfer(transfer);
      },
      underline: true,
    });

    menuItems.push({
      key: 'download',
      content: (
        <Typography data-testid={`download-transfer-action-${transfer?.id}`}>
          {t('download_transfer_summary')}
        </Typography>
      ),
      icon: displayIcon('Download'),
      onClick: () => handleReportGeneration(
        FileType.PDF,
        DownloadType.TransferSummary,
      ),
      underline: false,
    });
  }

  const handleClose = (): void => {
    if (setDisplayMultiSelect) { setDisplayMultiSelect(false); }
    setMenuCloseCount(0);
  };

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box data-testid="action-dropdown" className={classes.wrapper}>
        <APMenu
          actions
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          disableHoverEffect
          onClose={handleClose}
          buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
          buttonTitle=""
          // @ts-ignore
          icon={faEllipsisH}
        >
          {
            menuItems.map((item) => (
              <MenuDropdownItem
                setOpen={setOpen}
                key={item.key}
              >
                {item}
              </MenuDropdownItem>
            ))
          }
        </APMenu>
      </Box>
    </ClickAwayListener>
  );
};

export default TransferActionDropDown;
