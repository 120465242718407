import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import t from 'utils/translationHelper';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PaymentBatchStatus, PaymentDto, PaymentStatus } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import {
  Box, Collapse, IconButton,
  Typography,
} from '@alpha/ui-lib/ui/external';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft, faChevronDown,
  faLink, faMoneyBill,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BackdropLoader from '../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import ApprovePaymentModal from '../../../../../components/Molecules/Payments/ApprovePaymentModal/index';
import RejectPaymentModal from '../../../../../components/Molecules/Payments/RejectPaymentModal/index';
import useAuthorization from '../../../../../hooks/useAuthorization';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../hooks/useReportsPolling';
import useSwitchAccount from '../../../../../hooks/useSwitchAccount';
import { UserRole } from '../../../../../models/user';
import routes from '../../../../../routes.path';
import browserHistory from '../../../../../services/history/browserHistory';
import { TStore } from '../../../../../store';
import { TAuthyState } from '../../../../../store/authy/reducer';
import BeneficiaryInfoDrawer from '../../../BatchSummary/PaymentSummary/BeneficiaryInfoDrawer/BeneficiaryInfoDrawer';

import BatchPaymentsTable from './BatchPaymentsTable/BatchPaymentsTable';
import LinkedTradesTable from './LinkedTradesTable/LinkedTradesTable';
import PaymentBatchSummary from './PaymentBatchSummary/PaymentBatchSummary';
import RightAlignedCta from './RightAlignedCta/RightAlignedCta';
import useStyles from './PaymentBatchDetail.styles';
import useBatchPaymentsTable from './usePaymentBatchDetail';

// eslint-disable-next-line max-lines-per-function
const PaymentBatchDetail: React.FC = () => {

  const { homePageUrl } = useSwitchAccount();
  const breadcrumbs = [
    { text: t('home'), url: homePageUrl },
    { text: t('transactions'), url: routes.transactions.base },
    { text: t('payments'), url: routes.transactions.base },
  ];

  const { authorized: paymentsApproverOwn } = useAuthorization([[UserRole.PAYMENTS_APPROVER_OWN]]);
  const { authorized: paymentsApprover } = useAuthorization([[UserRole.PAYMENTS_APPROVER]]);
  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [openTradeLink, setOpenTradeLink] = useState(true);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState<string>();
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [paymentsToApprove, setPaymentsToApprove] = useState<PaymentDto[]>([]);
  const [paymentsToReject, setPaymentsToReject] = useState<PaymentDto[]>([]);
  const [eligibleToApprovePayments, setEligibleToApprovePayments] = useState<PaymentDto[]>([]);
  const [eligibleToRejectPayments, setEligibleToRejectPayments] = useState<PaymentDto[]>([]);
  const [rejectedPaymentIds, setRejectedPaymentIds] = useState<string[]>([]);

  const { batchId } = useParams<{ batchId: string }>();
  const classes = useStyles();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  const {
    paymentBatch, payments, trades, loading, paymentsLoading, loadPaymentsData,
  } = useBatchPaymentsTable(batchId);

  const showContractNoteButton = paymentBatch?.status === PaymentBatchStatus.VALIDATED
    && payments.some((p) => [
      PaymentStatus.AWAITING_MONEY_IN,
      PaymentStatus.AWAITING_RELEASE,
      PaymentStatus.CLIENT_APPROVED,
      PaymentStatus.COMPLETED,
      PaymentStatus.PROCESSING,
    ].includes(p.status));

  const { executeReportGeneration } = useReportsPolling(PageType.Payments);
  const handleReportGeneration = async (fileType: FileType) => {
    await executeReportGeneration(
      fileType,
      batchId,
      undefined,
      undefined,
      DownloadType.Batch,
      fileType,
    );
  };

  const handleExportButtonClick = () => { handleReportGeneration(FileType.PDF); };

  useEffect(() => {
    const submittedPayments = payments.filter((p) => p.status === PaymentStatus.SUBMITTED);
    const submittedNotApprovedByUserPayments = payments.filter(
      (p) => p.status === PaymentStatus.SUBMITTED
        && !p.approvalInfo.approvals.some((a) => a.userId === userId),
    );

    setPaymentsToApprove(submittedNotApprovedByUserPayments);
    setEligibleToApprovePayments(submittedNotApprovedByUserPayments);

    setPaymentsToReject(submittedPayments);
    setEligibleToRejectPayments(submittedPayments);
  }, [payments, userId]);

  useEffect(() => {
    if (
      authyState.type?.type === 'PAYMENTS'
    ) {
      setTimeout(() => {
        setSelectedIds([]);
      }, 1000);
    } else if (authyState.type?.type === 'PAYMENT_APPROVE' && authyState.status === 'SUCCESS') {
      setTimeout(() => {
        loadPaymentsData(batchId);
        setSelectedIds([]);
      }, 1000);
    }
  }, [authyState.status, authyState.type, browserHistory.push]);

  useEffect(() => {
    setTimeout(() => {
      if (rejectedPaymentIds && rejectedPaymentIds.length > 0) {
        loadPaymentsData(batchId);
        setSelectedIds([]);
      }
    }, 1000);
  }, [rejectedPaymentIds]);

  const canApproveOwnBatch = paymentsApproverOwn && paymentBatch?.uploadedById === userId;
  const canApproveBatch = paymentsApprover && paymentBatch?.uploadedById !== userId;

  const validPayments = payments.filter((payment) => ![PaymentStatus.INVALID, PaymentStatus.ERRORED].includes(payment.status));

  return (
    <APMainLayout
      title={(
        <>
          <ActionButton
            startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
            testId="trade-back-btn"
            size="medium"
            onClick={() => browserHistory.push(routes.transactions.base)}
            style={{
              minWidth: '33px',
              minHeight: '33px',
              height: '33px',
              marginTop: '-5px',
              background: '#F7F7F7',
              color: '#212529',
            }}
          />
          {paymentBatch?.friendlyId || 'Payment Batch'}
        </>
      )}
      breadCrumbs={breadcrumbs}
      pageTabs={(
        <StyledTabsWrapper
          testId="trade-tabs"
          tabTitles={[t('summary')]}
          tabIndex={0}
          handleChange={() => { }}
        />
      )}
      rightAlignedCta={loading ? (<></>)
        : (
          <RightAlignedCta
            handleRejectBatch={() => {
              setPaymentsToReject(eligibleToRejectPayments);
              setOpenRejectModal(true);
            }}
            handleApproveBatch={() => {
              setPaymentsToApprove(eligibleToApprovePayments);
              setOpenApproveModal(true);
            }}
            handleContractDownload={handleExportButtonClick}
            showContractNoteButton={showContractNoteButton}
            showRejectBatchButton={(canApproveOwnBatch || canApproveBatch)
              && eligibleToRejectPayments.length > 0}
            showApproveBatchButton={(canApproveOwnBatch || canApproveBatch)
              && eligibleToApprovePayments.length > 0}
            requiredRole={paymentBatch?.uploadedById === userId
              ? UserRole.PAYMENTS_APPROVER_OWN
              : UserRole.PAYMENTS_APPROVER}
          />
        )}
    >
      <div className={classes.contentWrapper}>
        {!loading || <BackdropLoader testId="loader-payment-batch" />}
        <PaymentBatchSummary
          paymentBatch={paymentBatch}
          validPaymentNumber={validPayments.length}
        />
        {trades.length > 0 && (
          <Box className={classes.mainContainer}>
            <div className={classes.boxHeading}>
              <IconButton
                onClick={() => setOpenTradeLink(!openTradeLink)}
                style={{ height: '5px', borderRadius: 0, marginRight: '5px' }}
              >
                <FontAwesomeIcon
                  className={clsx(classes.collapseIcon, { right: !openTradeLink })}
                  icon={faChevronDown as IconProp}
                  size="sm"
                />
              </IconButton>
              <FontAwesomeIcon size="sm" icon={faLink as IconProp} className={classes.icon} />
              <Typography className={classes.boxHeaderText}>
                {t('linked_trades')}
              </Typography>
            </div>
            <Collapse in={openTradeLink}>
              <LinkedTradesTable
                trades={trades}
                loading={loading}
              />
            </Collapse>
          </Box>
        )}
        <Box className={classes.mainContainer}>
          <div className={classes.boxHeading}>
            <FontAwesomeIcon size="sm" icon={faMoneyBill as IconProp} className={classes.icon} />
            <Typography className={classes.boxHeaderText}>
              {`${t('all_payments')} (${validPayments?.length || 0})`}
            </Typography>
          </div>
          <BatchPaymentsTable
            payments={validPayments}
            paymentBatch={paymentBatch}
            loading={paymentsLoading}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            handleBeneficiaryButton={setSelectedBeneficiaryId}
            setRejectedPaymentIds={setRejectedPaymentIds}
            setOpenApproveModal={setOpenApproveModal}
            setOpenRejectModal={setOpenRejectModal}
            setPaymentsToApprove={setPaymentsToApprove}
            setPaymentsToReject={setPaymentsToReject}
          />
        </Box>
      </div>
      <BeneficiaryInfoDrawer
        paymentSummaryBeneficiaryId={selectedBeneficiaryId}
        onClose={() => setSelectedBeneficiaryId(undefined)}
        open={Boolean(selectedBeneficiaryId)}
        setPaymentSummaryBeneficiaryId={setSelectedBeneficiaryId}
      />
      <ApprovePaymentModal
        payments={paymentsToApprove}
        batch={paymentBatch}
        open={openApproveModal}
        handleClose={() => {
          setSelectedIds([]);
          setOpenApproveModal(false);
          loadPaymentsData(batchId);
        }}
      />
      <RejectPaymentModal
        open={openRejectModal}
        handleClose={() => {
          setSelectedIds([]);
          setOpenRejectModal(false);
        }}
        payments={paymentsToReject}
        handleRejected={(rejectedIds) => {
          setRejectedPaymentIds(rejectedIds);
        }}
      />
    </APMainLayout>
  );
};

export default PaymentBatchDetail;
