import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Select } from '@alpha/ui-lib/ui/Select';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, Collapse, MenuItem } from '@alpha/ui-lib/ui/external';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import i18n from 'i18n/config';
import { isEqual } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import MassReportingService from 'services/MassReporting/massReporting.service';
import t from 'utils/translationHelper';
import useStyles from './ExportManagerWidget.styles';

type exportType = {
  recordType?: string,
  reportFormat?: string,
  dateFrom?: Moment | null,
  dateTo?: Moment | null,
  date?: Moment | null,
  entityIds?: string[],
}

interface IProps {
  loading: boolean;
  pollExports: Function;
  setCurrentPage: Function;
  apiData?: { name: string, id: string }[];
}

const ExportManagerWidget: React.FC<IProps> = ({ loading, pollExports, setCurrentPage, apiData }) => {
  const [values, setValues] = useState<exportType>({ entityIds: [] })
  const [expanded, setExpanded] = useState<boolean>(false)
  const classes = useStyles();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [disabledPDF, setDisabledPDF] = useState<boolean>(false);
  const { logEvent, logError } = useLog();
  const sb = useAlphaSnackbar();
  const handleChange = (attributeName: string, newValue: string) => {
    if (attributeName === 'recordType') {
      setValues({
        ...values,
        dateFrom: null,
        dateTo: null,
        date: null,
        recordType: newValue
      } as exportType)
    } else {
      setValues({ ...values, [attributeName]: newValue } as exportType)
    }
  }
 
  useEffect(() => {
    if (values?.recordType) {
      if (values?.recordType === 'statements') {
        setDisabled(!Boolean(values?.entityIds?.length && values?.reportFormat && values?.dateFrom && values?.dateTo))
        setDisabledPDF(false);
      } else if (values?.recordType === 'mtm') {
        setDisabled(!Boolean(values?.entityIds?.length && values?.reportFormat && values?.date))
        setDisabledPDF(false);
      } else if (values?.recordType === 'single_statements_list') {
        if (values?.reportFormat !== 'excel') {
          setValues({
            ...values,
            reportFormat: 'excel',
          })
        }
        setDisabled(!Boolean(values?.entityIds?.length && values?.reportFormat && values?.dateFrom && values?.dateTo))
        setDisabledPDF(true);
      }
    }
    if (!isEqual({ ...values }, { entityIds: [] })) {
      setExpanded(true);
    }
  }, [values]);

  const handleSubmit = async (values: exportType) => {
    const mapValuesToDto: any = {
      type: values.recordType?.toUpperCase(),
      format: values.reportFormat?.toUpperCase(),
      accountIds: values?.entityIds,
      dates: null,
    }
    if (values.date) {
      mapValuesToDto.date = values.date.toDate()
    } if (values.dateFrom && values.dateTo) {
      mapValuesToDto.dates = {
        from: values.dateFrom.toISOString(),
        to: values.dateTo.toISOString()
      }
    }
    try {
      await MassReportingService.postMassReporting(mapValuesToDto);
      logEvent({ action: 'Mass reporting request sent' });
      sb.trigger(t('saved'), 'success');
      setCurrentPage(0);
      pollExports(5000, 0);
      setValues({ entityIds: [] })
      setDisabled(true)
      setExpanded(false)
    } catch (e) {
      sb.trigger(t('error_something_went_wrong'), 'error');
      logError({ action: 'Error posting initiate report', error: e });
      setDisabled(false)
    }
  }

  const bottomRowFields = {
    statements: (
      <div className={classes.inputRow}>
        <div className={classes.labelledInput} style={{ marginRight: '16px' }}>
          <div style={{ marginBottom: '4px' }}>
            {t('date_from')}
          </div>
          <APDatePicker
            label=""
            language={i18n.language}
            onChange={(date) => {
              handleChange('dateFrom', date);
            }}
            value={values?.dateFrom}
            maxDate={moment().format('YYYY-MM-DD')}
            autoOk
            className='smallDate'
          />
        </div>
        <div className={classes.labelledInput}>
          <div style={{ marginBottom: '4px' }}>
            {t('date_to')}
          </div>
          <APDatePicker
            label=""
            language={i18n.language}
            onChange={(date) => {
              handleChange('dateTo', date);
            }}
            value={values?.dateTo}
            maxDate={moment().format('YYYY-MM-DD')}
            autoOk
            className='smallDate'
          />
        </div>
      </div>
    ),
    mtm: (
      <div className={classes.inputRow}>
        <div className={classes.labelledInput}>
          <div style={{ marginBottom: '4px' }}>
            {t('date')}
          </div>
          <APDatePicker
            label=""
            language={i18n.language}
            onChange={(date) => {
              handleChange('date', date);
            }}
            value={values?.date}
            maxDate={moment().format('YYYY-MM-DD')}
            autoOk
            className='largeDate'
          />
        </div>
      </div>
    ),
    single_statements_list: (
      <div className={classes.inputRow}>
        <div className={classes.labelledInput} style={{ marginRight: '16px' }}>
          <div style={{ marginBottom: '4px' }}>
            {t('date_from')}
          </div>
          <APDatePicker
            label=""
            language={i18n.language}
            onChange={(date) => {
              handleChange('dateFrom', date);
            }}
            value={values?.dateFrom}
            maxDate={moment().format('YYYY-MM-DD')}
            autoOk
            className='smallDate'
          />
        </div>
        <div className={classes.labelledInput}>
          <div style={{ marginBottom: '4px' }}>
            {t('date_to')}
          </div>
          <APDatePicker
            label=""
            language={i18n.language}
            onChange={(date) => {
              handleChange('dateTo', date);
            }}
            value={values?.dateTo}
            maxDate={moment().format('YYYY-MM-DD')}
            autoOk
            className='smallDate'
          />
        </div>
      </div>
    ),
  }

  return (
    <Box className={clsx(classes.widget, expanded ? classes.expandedWidget : classes.collapsedWidget)}>
      <div style={{ display: 'flex', flexDirection: 'row', minWidth: '639px' }}>
        <div>
          <div>
            <div className={classes.titleContainer}>
              <div className={classes.iconContainer}>
                <FontAwesomeIcon icon={faDownload} />
              </div>
              <div className={classes.titleText}>
                {t('export_manager')}
              </div>
            </div>
            <div className={classes.subtitle}>
              {t('here_you_can_export_reports_across_all_your_avalible_entities')}
            </div>
          </div>
          <div className={classes.inputRow}>
            <div className={classes.labelledInput}>
              <div>
                {t('record_type')}
              </div>
              <Select
                onChange={(e) => handleChange('recordType', e.target.value as string)}
                fullWidth
                disableUnderline
                displayEmpty
                className={clsx(classes.select, classes.longSelect)}
                value={values?.recordType ? values?.recordType : 'placeholder'}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                style={{ marginRight: '16px' }}
              >
                {[
                  <MenuItem value="placeholder" disabled style={{ display: 'none' }}>
                    Select
                  </MenuItem>,
                  <MenuItem value="single_statements_list">
                    {t('single_statements_list')}
                  </MenuItem>,
                  <MenuItem value="statements">
                    {t('statements')}
                  </MenuItem>,
                  <MenuItem value="mtm">
                    MTM {/*  SAME IN ALL LANGUAGES */}
                  </MenuItem>
                ]}
              </Select>
            </div>
            <div className={classes.labelledInput}>
              <div>
                {t('report_format')}
              </div>
              <Select
                onChange={(e) => handleChange('reportFormat', e.target.value as string)}
                disableUnderline
                className={clsx(classes.select, classes.shortSelect)}
                value={values?.reportFormat ? values?.reportFormat : 'placeholder'}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}

              >
                {[
                  <MenuItem value="placeholder" disabled style={{ display: 'none' }}>
                    {t('select')}
                  </MenuItem>,
                  <MenuItem value="pdf" disabled={disabledPDF}>
                    {t('pdf')}
                  </MenuItem>,
                  <MenuItem value="excel">
                    Excel {/*  SAME IN ALL LANGUAGES */}
                  </MenuItem>,
                ]}
              </Select>
            </div>
          </div>
          <div>
            {values?.recordType ? bottomRowFields[values.recordType] : null}
          </div>
        </div>
        <Collapse in={expanded}>
          <div style={{ width: '400px', display: 'flex' }}>
            <div className={classes.divider} />
            {loading || !expanded ? (
              <div style={{ width: '100%', height: '232px' }}>
                <Loader testId={'entities-loader'} style={{}} />
              </div>
            )
              : (
                <div className={classes.selectEntitesContainer}>
                  <div className={classes.titleContainer}>
                    {t('select_entities_to_include')}
                  </div>
                  <div className={classes.entitesContainer}>
                    {apiData.map((r, index) => (
                      <div className={classes.entityNameRow}>
                        <div className={classes.entityName}>
                          {r.name}
                        </div>
                        <div className={classes.checkbox}>
                          <Checkbox
                            value={values.entityIds.includes(r.id)}
                            testId={`entity-checkbox-${index}`}
                            onChange={() => {
                              if (values.entityIds.includes(r.id)) {
                                const arrayWithoutId = values.entityIds.filter((id) => (id !== r.id))
                                setValues({ ...values, entityIds: arrayWithoutId })
                              } else {
                                setValues({ ...values, entityIds: [...values.entityIds, r.id] })
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    <ActionButton
                      style={{ float: 'right', marginTop: '24px' }}
                      onClick={() => [setDisabled(true), handleSubmit(values)]}
                      disabled={disabled}
                    >
                      {t('initiate_report_export')}
                      <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '8px' }} />
                    </ActionButton>
                  </div>
                </div>
              )}
          </div>
        </Collapse>
      </div>
    </Box>
  )
};

export default ExportManagerWidget;
