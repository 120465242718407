/* eslint-disable max-lines-per-function */
import clsx from 'clsx';
import useLog from 'hooks/useLog';
import moment from 'moment';
import React, { useState } from 'react';
import browserHistory from 'services/history/browserHistory';
import t from 'utils/translationHelper';

import { TermMTMDto } from '@alpha/credit-dtos';
import { TradeMTMDto } from '@alpha/fx-dtos';
import { Button } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import { CurrencyPair } from '../../../components/International';
import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import Search from '../../../components/Organisms/Search';
import SearchTable from '../../../components/Organisms/SearchTable';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import { formatNumber } from '../../../utils/currency.helpers';
import formatIsoDate from '../../../utils/formatIsoDate';
import MtmFilter from '../MtmFilter';

import useStyles from './index.styles';
import usePositionValuationTable from './usePositionValuationTable';

interface IPositionValuationTable {
  mtmDate: string;
  emptyTitle?: string;
  emptySubtitle?: string;
  testId?: string;
  buyCurrencies?: string[];
  sellCurrencies?: string[];
  termsIds?: string[];
  setBuyCurrencies?: React.Dispatch<React.SetStateAction<string[]>>;
  setSoldCurrencies?: React.Dispatch<React.SetStateAction<string[]>>;
  setTermsIds?: React.Dispatch<React.SetStateAction<string[]>>;
  setSearchTextForReport?: React.Dispatch<React.SetStateAction<string | undefined>>;
  fullWidth?: boolean;
  termId?: string;
  enableFilter?: boolean;
  shouldUseBrackets: boolean;
  title?: string;
  isCredit?: boolean;
  selectedCurrency?: string;
}

const PositionValuationTable: React.FC<IPositionValuationTable> = (
  {
    mtmDate,
    emptyTitle,
    emptySubtitle,
    testId,
    buyCurrencies = [],
    sellCurrencies = [],
    termsIds = [],
    setBuyCurrencies,
    setSoldCurrencies,
    setTermsIds,
    setSearchTextForReport,
    fullWidth,
    termId,
    enableFilter = true,
    shouldUseBrackets = false,
    title,
    isCredit,
    selectedCurrency,
  },
) => {
  const [currentSortBy, setCurrentSortBy] = useState<string>('tradeDate');
  const [currentSortOrder, setCurrentSortOrder] = useState<'asc' | 'desc'>('desc');
  const {
    table,
    searchParams,
    handleInputChange,
    handleTableSortClick,
  } = usePositionValuationTable(
    mtmDate,
    setSearchTextForReport,
    currentSortBy,
    currentSortOrder,
    buyCurrencies,
    sellCurrencies,
    termId,
    termsIds,
    selectedCurrency,
  );
  const { logEvent } = useLog();

  const classes = useStyles();

  const handleMtms = () => {
    if (isCredit) {
      const res = table?.items?.items?.records as TermMTMDto[];
      return res?.filter((termMtm) => (!termMtm.optionsStripId));
    }
    return table?.items?.items?.records as TradeMTMDto[];
  };

  const tradeMtms = handleMtms();

  const columns: ITableColumn[] = [
    { header: t('trade_date'), accessor: 'tradeDate', sortable: true },
    { header: t('trade_number'), accessor: 'contractNumber', sortable: true },
    { header: t('value_date'), accessor: 'valueDate', sortable: true },
    { header: t('sold_ccy'), accessor: 'soldCurrencyCode', sortable: true },
    {
      header: t('sold_amount'), accessor: 'soldAmount', align: 'right', sortable: true,
    },
    {
      header: t('rate'), accessor: 'rate', align: 'right', sortable: true, width: 100,
    },
    {
      header: t('buy_ccy'), accessor: 'buyCurrencyCode', sortable: true, width: 120,
    },
    {
      header: t('buy_amount'), accessor: 'buyAmount', align: 'right', sortable: true, width: 140,
    },
    {
      header: t('reporting_rate'), accessor: 'reportingRate', align: 'right', sortable: true,
    },
    {
      header: t('mtm_value'), accessor: 'mtmValue', align: 'right', sortable: true,
    },
    {
      header: `${selectedCurrency} ${t('rate')}`, accessor: 'fcMTMRate', align: 'right',
    },
    {
      header: `${t('mtm_value')} (${selectedCurrency})`, accessor: 'fcMTMValue', align: 'right',
    },
  ];

  const generateTableData = () => (
    tradeMtms as TradeMTMDto[]
  )?.map(((tradeMtm: TradeMTMDto) => ({
    ...tradeMtm,
    tradeDate: formatIsoDate(tradeMtm.tradeDate) || '-',
    contractNumber:
      <Button
        disableRipple
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          logEvent({ action: 'Clicked on trade number in forwards position table' });
          browserHistory.push(`/app/trades/${tradeMtm.id.slice(0, -11)}`);
        }}
        className={classes.tradeLink}
      >
        {tradeMtm.contractNumber}
      </Button> || '-',
    valueDate: formatIsoDate(tradeMtm.valueDate) || '-',
    soldCurrencyCode: <CurrencyPair currencyCode={tradeMtm.soldCurrencyCode} /> || '-',
    soldAmount: <span className={classes.amount}>
      {[formatNumber(tradeMtm.soldAmount, 2, shouldUseBrackets), tradeMtm.soldCurrencyCode].join(' ')}
    </span> || '-',
    rate: <span className={classes.rateLink}>{tradeMtm.rate?.toFixed(4)}</span> || '-',
    buyCurrencyCode: <CurrencyPair currencyCode={tradeMtm.buyCurrencyCode} /> || '-',
    buyAmount: <span className={classes.amount}>{[formatNumber(tradeMtm.buyAmount, 2), tradeMtm.buyCurrencyCode].join(' ')}</span> || '-',
    reportingRate: tradeMtm.reportingRate ? (
      <APTooltip
        placement="bottom"
        arrow
        title={`The rate is from ${tradeMtm.rateSource ? tradeMtm.rateSource : ''} 
      ${tradeMtm.rateDateTime ? moment(tradeMtm.rateDateTime).format('hh:mma DD/MM/YYYY') : ''}`}
      >
        <span className={classes.rateLink}>{tradeMtm.reportingRate.toFixed(4)}</span>
      </APTooltip>
    ) : '-',
    mtmValue: <span className={classes.tableLink}>
      {[formatNumber(tradeMtm.mtmValue, 2, shouldUseBrackets), tradeMtm.mtmCurrencyCode].join(' ')}
    </span> || '-',
    fcMTMRate: tradeMtm.facilityCurrencyMTMRate
      ? <span className={classes.rateLink}>{tradeMtm.facilityCurrencyMTMRate.toFixed(4)}</span>
      : '-',
    fcMTMValue: tradeMtm.facilityCurrencyMTMValue && tradeMtm.facilityCurrencyMTMCurrencyCode
      ? (
        <span className={classes.tableLink}>
          {[formatNumber(tradeMtm.facilityCurrencyMTMValue, 2, shouldUseBrackets), tradeMtm.facilityCurrencyMTMCurrencyCode].join(' ')}
        </span>
      )
      : '-',
  })));

  return (
    <div data-testid="position-valuation-loader-component" className={clsx(classes.tableWrapper, fullWidth && 'fullWidth')}>
      {
        table.loading
        && <BackdropLoader testId="loader" />
      }
      <div style={{ display: 'flex', flexGrow: '1', alignItems: 'center' }}>
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          {title}
          {' '}
          {t('position_valuation_by_trade')}
        </Typography>
        <div className={classes.verticalLine} />
        <Search
          testId="search-trades"
          value={table.searchText}
          totalItems={table?.items?.items?.totalForwards || table?.items?.items?.total || 0}
          placeholder={t('search_by_trade_id_or_reference')}
          onChange={handleInputChange}
        />
        {enableFilter && (
          <MtmFilter
            mtmDate={mtmDate}
            termId={termId}
            setBuyCurrencies={setBuyCurrencies}
            setSellCurrencies={setSoldCurrencies}
            buyCurrencies={buyCurrencies}
            sellCurrencies={sellCurrencies}
            setTermsIds={setTermsIds}
            termsIds={termsIds}
          />
        )}
      </div>
      <SearchTable
        table={{
          columns,
          data: generateTableData(),
          activeColumn: table.sortBy,
          sortOrder: table.sortOrder,
          skip: table.skip,
          dataTestId: testId || '',
          keyColumn: 'id',
          totalRowNumber: isCredit ? table?.items?.totalForwards : table?.items?.total || 0,
          handleTableSortClick: (column: string) => {
            logEvent({ action: `Clicked on sort on ${title} position valuation by trade` });
            handleTableSortClick(
              column,
            );
            setCurrentSortBy(column);
            let newOrder: 'asc' | 'desc';
            if (column === currentSortBy) {
              newOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';
            } else {
              newOrder = 'desc';
            }
            setCurrentSortOrder(newOrder);
          },
        }}
        loading={table.loading}
        pagination={{
          handleNext: () => table.handleNextPage(searchParams),
          handlePrevious: () => table.handlePreviousPage(searchParams),
          hasNext: isCredit
            ? Boolean(table?.items?.totalForwards - (searchParams.queryParams.skip + searchParams.queryParams.take) > 0)
            : Boolean(table?.items?.total - (searchParams.queryParams.skip + searchParams.queryParams.take) > 0),
          hasPrevious: Boolean(table.items?.hasPrevious),
        }}
        emptyTable={{
          title: emptyTitle || t('no_position_valuation_trades'),
          subtitle: emptySubtitle || t('you_currently_do_not_have_any_position_valuation_trades'),
          className: classes.emptyTable,
        }}
      />
    </div>
  );
};

export default PositionValuationTable;
