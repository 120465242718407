import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    tableDetails: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    amountDetails: {
      '& td': {
        fontWeight: typography.fontWeightBold,
      },
    },
    paymentStatusWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
      paddingBottom: 11.5,
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: typography.fontWeightRegular,
      fontFamily: typography.fontFamily,
    },
  }), { name: 'PaymentDetails' },
);

export default useStyles;
