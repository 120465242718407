import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    verticalLine: {
      display: 'block',
      height: '100%',
      minHeight: '48px',
      border: '1px solid #F7F7F7',
      margin: '0 8px',
    },
    tableWrapper: {
      '&.fullWidth': {
        gridColumn: 'span 3 / auto',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.06)',
        padding: '20px',
      },
      '& .MuiTableHead-root': {
        borderTop: '#EAEAEA solid 1px',
      },
      '& .MuiTableCell-root': {
        'padding-right': '30px !important',
      },
      '@media (max-width: 1200px)': {
        '& .MuiTableCell-root': {
          'padding-right': '20px !important',
        },
        '& th.MuiTableHead-root, & td.MuiTableCell-root': {
          fontSize: '12px',
          '& span': {
            fontSize: '11px',
          },
        },
      },
      '& td': {
        padding: '10px 0px !important',
      },
      '& th': {
        paddingTop: '20px !important',
      },
    },
    emptyTable: {
      position: 'relative',
      margin: '80px',
      top: 'auto',
      left: 'auto',
      transform: 'none',
    },
    tableLink: {
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
    },
    optionsTradeDetail: {
      display: 'grid',
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
    },
    rateLink: {
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
    },
    tradeLink: {
      fontSize: 14,
      fontWeight: typography.fontWeightSemiBold,
      color: 'rgb(9, 131, 117)',
      textDecoration: 'underline',
      marginLeft: '-7px'
    },
    amount: {
      fontWeight: 600,
    },
    status: {
      minWidth: '130px',
      textAlign: 'center',
    },
    menuCustom: {
      '& .APFilterMenu-filterMenuIconWrapper': {
        height: '44px',
      },
      '& .MuiPopover-paper': {
        width: '430px;',
      },
      '& .MuiInput-underline:before': {
        border: '0',
      },
      '& .MuiList-root li': {
        padding: '4px 12px 4px 12px',
      },
    },
    alphaInput: {
      width: '100%',
      '& .MuiInputBase-root': {
        background: 'rgba(230,230,230, 0.3)',
        borderRadius: '5px',
        border: 'none',
        padding: '8px !important',
      },
      '& label': {
        margin: '6px',
      },
    },
  }),
  { name: 'PositionValuationTable' },
);

export default useStyles;
