import React, { ReactText, useEffect, useState } from 'react';
import t from 'utils/translationHelper';
import { useDispatch, useSelector } from 'react-redux';
import CreditService from 'services/Credit/credit.service';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import IATService from 'services/IAT/IAT.service';

import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';

import SnackMessage from '../components/SnackMessage/SnackMessage';
import {
  TReportGenerationPayload,
} from '../models/currencyAccounts';
import AccountSettingsService from '../services/AccountSettings/accountSettings.service';
import BeneficiariesService from '../services/Beneficiaries/beneficiaries.service';
import CurrencyAccountsService from '../services/CurrencyAccounts/currencyAccounts.service';
import { TProgramImage } from '../services/HedgingPrograms/hedgingPrograms.interfaces';
import HedgingProgramsService from '../services/HedgingPrograms/hedgingPrograms.service';
import PaymentsService from '../services/Payments/payments.service';
import { TStore } from '../store';
import { initiatePolling } from '../store/generateReport/actions';
import { TDownloadQueue } from '../store/generateReport/reducer';

export enum PageType {
  Payments = 'payments',
  Transfers = 'transfers',
  Statements = 'statements',
  CurrencyAccount = 'currencyAccount',
  FX = 'fx',
  Beneficiaries = 'beneficiaries',
  Programs = 'programs',
  AuditReport = 'audit report',
  OptionTrades = 'term sheet', // check
}

export type TTradeSummaryParams = {
  type: FileType;
  sortby?: string;
  sortorder?: string;
  buycurrency?: string;
  soldcurrency?: string;
  tradetype?: string;
  status?: string;
  searchtext?: string;
  tradedatefrom?: string;
  tradedateto?: string;
  valuedateto?: string;
  valuedatefrom?: string;
}

export enum DownloadType {
  Batch = 'batch',
  Single = 'single',
  PaymentBatchErrors = 'payment batch errors',
  Fx = 'fx',
  AccountLetter = 'accountLetter',
  Summary = 'summary',
  ContractNote = 'contractNote',
  Drawdown = 'drawdown',
  TradeWithDrawdowns = 'trade with drawdowns',
  TradeWithPayments = 'trade with payments',
  Mtm = 'mtm',
  Beneficiaries = 'beneficiaries',
  TransferSummary = 'transfer summary',
  TermMtm = 'term mtm',
  TermSheet = 'term sheet'
}

export enum FileType {
  PDF = 'pdf',
  EXCEL = 'excel',
}

// eslint-disable-next-line max-lines-per-function
const useReportsPolling = (pageType: PageType) => {
  const dispatch = useDispatch();


  const [download, setDownload] = useState<{
    id: string,
    executionArn: string,
    key: ReactText,
  }>();
  const downloadQueue = useSelector<TStore, TDownloadQueue>(
    (store) => store.generatePdf.downloadQueue,
  );
  const { enqueueSnackbar } = originalUseSnackbar();

  useEffect(() => {
    if (download && downloadQueue.findIndex((dl) => dl.id === download.key) === -1) {
      dispatch(
        initiatePolling(download.id, download.executionArn, download.key, pageType),
      );
    }
  }, [download?.key]);

  const createStatementsPayload = (
    type: FileType,
    startDate: string | undefined,
    endDate: string | undefined,
    id: string,
  ): TReportGenerationPayload => ({
    type,
    startDate,
    endDate,
    id,
  });

  const handleReportMessage = (
    downloadType: DownloadType | undefined,
    fileType: FileType | undefined,
  ): string => {
    if (pageType === PageType.Payments) {
      switch (downloadType) {
        case (DownloadType.Batch): {
          return t('batch_summary');
        }
        case (DownloadType.Single): {
          return t('payment_confirmation');
        }
        case (DownloadType.Fx): {
          return t('fx_trade');
        }
        case (DownloadType.Summary): {
          return t('payment_summary');
        }
        case (DownloadType.ContractNote): {
          return t('trade_contract_note');
        }
        case (DownloadType.TransferSummary): {
          return t('transfer_summary');
        }
        default: {
          console.log('Download type not recognised');
        }
      }
    }
    if (pageType === PageType.Statements) {
      if (fileType === FileType.PDF) {
        return t('pdf_statement');
      }
      if (fileType === FileType.EXCEL) {
        return t('excel_statement');
      }
    }
    if (pageType === PageType.FX) {
      switch (downloadType) {
        case (DownloadType.Mtm): {
          if (fileType === FileType.PDF) {
            return `${t('hedging_summary_pdf_report')} `;
          }
          if (fileType === FileType.EXCEL) {
            return t('hedging_summary_excel_report');
          }
          break;
        }
        case (DownloadType.TermMtm): {
          if (fileType === FileType.PDF) {
            return `${t('hedging_summary_pdf_report')} `;
          }
          if (fileType === FileType.EXCEL) {
            return t('hedging_summary_excel_report');
          }
          break;
        }
        case (DownloadType.Summary): {
          if (fileType === FileType.PDF) {
            return t('trade_summary_pdf');
          }
          if (fileType === FileType.EXCEL) {
            return t('trade_summary_excel');
          }
          break;
        }

        default: {
          if (fileType === FileType.PDF) {
            return t('pdf_drawdown_table');
          }
          if (fileType === FileType.EXCEL) {
            return t('excel_drawdown_table');
          }
        }
      }
    }
    if (pageType === PageType.Beneficiaries) {
      switch (downloadType) {
        case (DownloadType.Beneficiaries): {
          if (fileType === FileType.EXCEL) {
            return t('beneficiaries_excel_report');
          }
          break;
        }
        default: {
          if (fileType === FileType.EXCEL) {
            return t('beneficiaries_excel_report');
          }
        }
      }
    }
    if (pageType === PageType.Programs) {
      if (fileType === FileType.PDF) {
        return t('hedging_program_pdf');
      }
      if (fileType === FileType.EXCEL) {
        return t('hedging_program_excel');
      }
    }
    if (pageType === PageType.Transfers) {
      if (downloadType === DownloadType.TransferSummary
        && fileType === FileType.PDF) {
        return t('transfer_summary_pdf');
      }
    }
    return t('pdf');
  };

  type TMtmReportParamType = {
    mtmdate: string;
    mtmcurrency: string;
    includeexternaltrades: string;
    searchtext?: string;
    soldcurrency?: string[];
    buycurrency?: string[];
  }

  type TTermMtmReportParamType = {
    mtmdate: string;
    mtmcurrency: string;
    termId?: string;
    searchtext?: string;
    soldcurrency?: string[];
    buycurrency?: string[];
    includeOptions?: boolean,
    isFacilityLevel?: boolean,
    includeexternaltrades?: boolean;
  }

  const handleReportEndpoint = async (
    type: FileType,
    id: string,
    startDate?: string,
    endDate?: string,
    downloadType?: DownloadType,
    mtmParams?: TMtmReportParamType | TTermMtmReportParamType,
    tradeParams?: TTradeSummaryParams,
    programImages?: TProgramImage[],
  ): Promise<string> => {
    let encodedExecutionArn = '';
    if (pageType === PageType.Statements) {
      const payload = createStatementsPayload(
        type,
        startDate,
        endDate,
        id,
      );
      encodedExecutionArn = await CurrencyAccountsService.postReportGeneration(
        payload,
      );
    }
    if (pageType === PageType.Payments) {
      switch (downloadType) {
        case DownloadType.Batch: {
          encodedExecutionArn = await PaymentsService.postReportBatchGeneration(
            type, id,
          );
          break;
        }
        case DownloadType.Single: {
          encodedExecutionArn = await PaymentsService.postReportSingleGeneration(
            type, id,
          );
          break;
        }
        case DownloadType.Fx: {
          encodedExecutionArn = await PaymentsService.postReportFxGeneration(
            type, id,
          );
          break;
        }
        case DownloadType.Summary: {
          encodedExecutionArn = await PaymentsService.postPaymentSummaryGeneration(
            type, id,
          );
          break;
        }
        case DownloadType.ContractNote: {
          encodedExecutionArn = await PaymentsService.postTradeContactNoteGeneration(
            type, id,
          );
          break;
        }
        default: {
          console.log('Download type not recognised');
        }
      }
    }
    if (pageType === PageType.CurrencyAccount) {
      encodedExecutionArn = await CurrencyAccountsService.postAccountLetterReportGeneration({
        type, id,
      });
    }
    if (pageType === PageType.FX) {
      switch (downloadType) {
        case DownloadType.Drawdown: {
          encodedExecutionArn = await FXTradeService.postDrawdownSummaryGeneration(
            type, id,
          );
          break;
        }
        case DownloadType.TradeWithDrawdowns: {
          encodedExecutionArn = await FXTradeService.postTradeSummaryWithDrawdownsGeneration(
            type, id,
          );
          break;
        }
        case DownloadType.TradeWithPayments: {
          encodedExecutionArn = await FXTradeService.postTradeSummaryWithPaymentsGeneration(
            type, id,
          );
          break;
        }
        case DownloadType.Mtm: {
          if (mtmParams) {
            const {
              mtmdate,
              mtmcurrency,
              searchtext,
              soldcurrency,
              buycurrency,
              includeexternaltrades,
            } = mtmParams;
            encodedExecutionArn = await FXTradeService.postMtmReportGeneration(
              type,
              mtmdate,
              mtmcurrency,
              includeexternaltrades,
              searchtext,
              soldcurrency,
              buycurrency,
            );
          }
          break;
        }
        case DownloadType.TermMtm: {
          if (mtmParams) {
            const {
              mtmdate,
              mtmcurrency,
              termId,
              searchtext,
              soldcurrency,
              buycurrency,
              includeOptions,
              isFacilityLevel,
              includeexternaltrades,
            } = mtmParams;
            encodedExecutionArn = await CreditService.postTermMtmReportGeneration(
              type,
              mtmdate,
              mtmcurrency,
              termId,
              searchtext,
              soldcurrency,
              buycurrency,
              includeOptions,
              isFacilityLevel,
              includeexternaltrades,
            );
          }
          break;
        }
        case DownloadType.Summary: {
          if (tradeParams) {
            encodedExecutionArn = await FXTradeService.postTradeSummaryGeneration(tradeParams);
          }
          break;
        }
        default: {
          console.log('Download type not recognised');
        }
      }
    }
    if (pageType === PageType.Beneficiaries) {
      encodedExecutionArn = await BeneficiariesService.postBeneficiariesGeneration(type);
    }
    if (pageType === PageType.Transfers) {
      if (downloadType === DownloadType.TransferSummary) {
        encodedExecutionArn = await IATService.getTransferSummaryGeneration(type, id);
      }
    }
    if (pageType === PageType.Programs) {
      if (programImages) {
        encodedExecutionArn = await HedgingProgramsService.postHedgingProgramReportGeneration(
          id, type, programImages,
        );
      }
    }
    if (pageType === PageType.AuditReport) {
      encodedExecutionArn = await AccountSettingsService.getAccountAuditReport(
        id, type,
      );
    }
    return encodedExecutionArn;
  };

  const executeReportGeneration = async (
    type: FileType,
    id?: string,
    startDate?: string,
    endDate?: string,
    downloadType?: DownloadType,
    fileType?: FileType,
    mtmParams?: TMtmReportParamType | TTermMtmReportParamType,
    tradeParams?: TTradeSummaryParams,
    programImages?: TProgramImage[],
  ): Promise<void> => {
    try {
      const executionArn = await handleReportEndpoint(
        type, id, startDate, endDate, downloadType, mtmParams, tradeParams, programImages,
      );
      enqueueSnackbar(null, {
        autoHideDuration: 60000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (key) => {
          setDownload({
            id, executionArn, key,
          });
          return (
            <SnackMessage
              reportType={type}
              reportMessage={handleReportMessage(downloadType, fileType)}
              id={key}
            />
          );
        },
      });
    } catch (e) {
      enqueueSnackbar(null, {
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        content: (key) => (
          <SnackMessage
            reportType={type}
            reportMessage={handleReportMessage(downloadType, fileType)}
            id={key}
            error
          />
        ),
      });
    }
  };

  return {
    executeReportGeneration,
  };
};

export default useReportsPolling;
