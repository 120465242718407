import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    button: {
      minWidth: 0,
      width: 50,
      height: 50,
      marginLeft: 'auto',
      alignSelf: 'flex-end',
    },
    header: {
      padding: '70px 35px 35px 50px',
      marginBottom: 45,
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    title: {
      fontSize: 22,
      fontWeight: typography.fontWeightSemiBold,
    },
    subtitle: {
      fontSize: 11,
      fontWeight: typography.fontWeightSemiBold,
      textTransform: 'uppercase',
      color: 'rgba(52, 52, 52, 0.5)',
      letterSpacing: 1.5,
    },
    headerText: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 40,
    },
    headerStats: {
      display: 'flex',
      flexDirection: 'row',
    },
    countryCurrency: {
      '& > div': {
        marginRight: 35,
      },
    },
    deleteButton: {
      minWidth: 0,
      width: 50,
      height: 50,
      marginLeft: 'auto',
      alignSelf: 'flex-end',
      '&:hover': {
        color: '#B95656',
        background: '#B9565633',
      },
    },
  }),
  { name: 'BeneInfoDrawerHeader' },
);

export default useStyles;
