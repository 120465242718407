import React, { useEffect, useMemo, useState } from 'react';

import { AccountType } from '@alpha/currency-accounts-dtos';
import { Stat } from '@alpha/ui-lib/ui/Stat';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import t from 'utils/translationHelper';

import { CurrencyHeader } from '../../../components/International';
import { TStatementsSummary } from '../../../models/currencyAccounts';
import routes from '../../../routes.path';
import CurrencyAccountsService from '../../../services/CurrencyAccounts/currencyAccounts.service';
import browserHistory from '../../../services/history/browserHistory';
import { formatCurrency } from '../../../utils/currency.helpers';
import formatIsoDate from '../../../utils/formatIsoDate';

import useStyles from './StatementsSummary.styles';

export interface IProps {
  componentTestId?: string;
  accountId?: string,
  startDate: string,
  endDate: string
}

export const StatementsSummary: React.FC<IProps> = (props: IProps) => {
  const [statementsSummary, setStatementsSummary] = useState<TStatementsSummary>();
  const [accountType, setAccountType] = useState<AccountType>();
  const classes = useStyles();


  const {
    componentTestId,
    accountId,
    startDate,
    endDate,
  } = props;
  useEffect(() => {
    getStatementSummary();
  }, [
    accountId, startDate, endDate,
  ]);

  const getStatementSummary = async () => {
    try {
      if (accountId && startDate && endDate) {
        const summary = await CurrencyAccountsService.getCurrencyStatementsSummary(
          accountId,
          startDate,
          endDate,
        );
        setStatementsSummary(summary);
        setAccountType(AccountType.CURRENCY);
      }
    } catch (error) {
      if (error.response?.status === 404 && accountId) {
        try {
          const summary = await CurrencyAccountsService.getMarginStatementsSummary(
            accountId,
            startDate,
            endDate,
          );
          setStatementsSummary(summary);
          setAccountType(AccountType.MARGIN);
        } catch (e) {
          browserHistory.push(`${routes.noStatements}`);
        }
      } else {
        browserHistory.push(`${routes.noStatements}`);
      }
    }
  };

  const dateString = useMemo(() => (
    `${formatIsoDate(startDate)} - ${formatIsoDate(endDate)}`
  ), [startDate, endDate]);

  const balanceLabel = statementsSummary?.isBalanceClosed
    ? 'Closing Balance'
    : t('current_balance');

  if (!statementsSummary) {
    return <div data-testid={componentTestId} />;
  }

  return (
    <div data-testid={componentTestId}>
      <div className={classes.statementCurrencySummary}>
        <div className={classes.statementCurrencySummaryLeft}>
          <CurrencyHeader
            currencyCode={statementsSummary.currencyCode}
            currency={statementsSummary.currency}
            account={statementsSummary.friendlyName}
          />
          <Stat title={t('date')} value={dateString} />
        </div>
        <div className={classes.statementCurrencySummaryRight}>
          <Stat
            title={t('opening_balance')}
            value={formatCurrency(
              statementsSummary.currencyCode,
              statementsSummary.openingBalance,
            )}
            alignRight
          />
          <Stat
            title={accountType === AccountType.MARGIN ? 'Margin received' : t('money_in')}
            value={formatCurrency(statementsSummary.currencyCode, statementsSummary.moneyIn)}
            alignRight
          />
          <Stat
            title={accountType === AccountType.MARGIN ? 'Margin released' : t('money_out')}
            value={formatCurrency(statementsSummary.currencyCode, statementsSummary.moneyOut)}
            alignRight
          />
          <Stat
            title={balanceLabel}
            value={formatCurrency(
              statementsSummary.currencyCode,
              statementsSummary.balance,
            )}
            alignRight
          />
        </div>
      </div>
      <StyledDivider />
    </div>
  );
};

export default StatementsSummary;
