import {
  call, putResolve, takeLatest,
} from 'redux-saga/effects';
import { apiRequest } from 'store/requestSaga';

import { datadogLogs } from '@datadog/browser-logs';

import env from '../../env.variables';

import { actions } from './reducer';

export function* watchGetFeatureFlagsSaga() {
  yield takeLatest('GET_FEATURE_FLAGS_REQUEST', getFeatureFlagsSaga);
}

export function* getFeatureFlagsSaga() {
  try {
    const result = yield call(apiRequest, {
      config: {
        method: 'GET',
        url: `${env.REACT_APP_API_URL}feature-flags`,
      },
      name: 'featureFlags',
    });
    yield putResolve(actions.setFeatureFlags({ flags: result.data }));
    localStorage.setItem('feature-flags', JSON.stringify(result.data));
  } catch (e) {
    datadogLogs.logger.error(e);
  }
}
