/* eslint-disable max-lines-per-function */
import clsx from 'clsx';
import Alert from 'components/Alert';
import InputAndLabel from 'components/Inputs/Beneficiary/InputAndLabel';
import Dropdown from 'components/InterAccountTransfer/Dropdown/Dropdown';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useAuthorization from 'hooks/useAuthorization';
import useForm from 'hooks/useForm';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import useLog from 'hooks/useLog';
import { UserRole } from 'models/user';
import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import { FeatureFlagName } from 'services/Config/config.service';
import PaymentsService from 'services/Payments/payments.service';
import StatementsService from 'services/Statements/statements.service';
import { formatNumber, getCurrencySymbol } from 'utils/currency.helpers';
import { mapCurrencyAccountToData } from 'utils/mapDropdown';
import replaceCommaIfString from 'utils/replaceCommaIfString';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import {
  Box, Input, InputLabel, MenuItem, Select, Typography,
} from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { Loader } from '@alpha/ui-lib/ui/Loader';

import { TransferConfirmationData } from '../IATDrawerContainer';

import { initialValues, TTransferRequestForm } from './formData';
import useStyles from './IATDrawerForm.styles';

type Props = {
  onClose: () => void,
  setDrawerState: Dispatch<SetStateAction<string>>,
  setIATRequestValues: Dispatch<SetStateAction<TransferConfirmationData | undefined>>,
  submitTransfer: () => void,
  defaultFromAccountId?: string,
}

const IATDrawerForm: React.FC<Props> = ({
  onClose, setDrawerState, setIATRequestValues, submitTransfer, defaultFromAccountId,
}) => {

  const styles = useStyles();
  const { logError } = useLog();
  const sb = useAlphaSnackbar();
  const { authorized: spotBooker } = useAuthorization([[UserRole.SPOT]]);
  const { authorized: spotInputter } = useAuthorization([[UserRole.SPOT_INPUTTER]]);
  const [isCrossCurrency, setIsCrossCurrency] = useState(false);
  const [maxPaymentLimit, setMaxPaymentLimit] = useState<number>(1000000);

  const { isFlagActive } = useGetFeatureFlags();

  const checkCcy = () => (isCrossCurrency ? isFlagActive(FeatureFlagName.IAT_CROSS_CURRENCY) : true);

  const newValidation = {
    instructedAmount: yup
      .number().transform(replaceCommaIfString)
      .min(1, t('amount_too_small'))
      .typeError(t('amount_must_be_a_number'))
      .required(t('please_enter_a_valid_amount'))
      .max(maxPaymentLimit, t('amount_is_above_the_maximum_payment_limit')),
    debitingCurrencyAccountId: yup.string().required(t('cannot_transfer_to_the_same_account')),
    fundingCurrencyAccountId: yup
      .string()
      .notOneOf([yup.ref('debitingCurrencyAccountId'), null], t('cannot_transfer_to_the_same_account'))
      .required(t('please_select_an_account'))
      .test('ccy', t('not_allowed_cross-currency_transfer'), checkCcy),
    reference: yup.string().optional().nullable().matches(/^[A-Za-z0-9.,?/:()+‘’' -]*$/, t('special_characters_are_not_allowed')),
  };

  const createConfirmationData = (): TransferConfirmationData => {
    const formValues = form.values;
    const newValues = {
      fundingCurrencyAccountId: formValues.fundingCurrencyAccountId,
      debitingCurrencyAccountId: formValues.debitingCurrencyAccountId,
      debitingAccountId: formValues.fundingCurrencyAccount?.accountId, // intentionally swapped
      instructedAmount: Number(formValues.instructedAmount),
      instructedCurrencyCode: formValues.instructedCurrency,
      reference: formValues?.reference,
      valueDate: formValues.valueDate,
      debitingCurrencyAccount: formValues.debitingCurrencyAccount,
      fundingCurrencyAccount: formValues.fundingCurrencyAccount,
    };
    return newValues;
  };

  const defaultFormValues = defaultFromAccountId ? { ...initialValues, debitingCurrencyAccountId: defaultFromAccountId } : initialValues;

  const form = useForm<TTransferRequestForm>(
    defaultFormValues,
    newValidation,
    submitTransfer,
    true,
    false,
  );

  const { data, error, isLoading } = useQuery('getCurrencyAccounts', () => StatementsService.getStatementAccounts());

  const accountPaymentConfigQuery = useQuery('getAccountConfig', () => PaymentsService.getAccountConfiguration(
    form.values?.instructedCurrency || '',
    (isCrossCurrency
      && isFlagActive(FeatureFlagName.IAT_CROSS_CURRENCY)
      ? form.values.debitingCurrencyAccount?.currencyCode
      : form.values.fundingCurrencyAccount?.currencyCode) || '',
  ), {
    enabled: false,
    onError: () => {
      sb.trigger(t('could_not_load_account_configurations'));
      logError({ action: 'Error loading account configurations' });
    },
  });

  const setAccountValues = async (type: string, account: CurrencyAccountDto, isCrossCcy = false) => {
    form.setFieldValue(type, account);
    form.setFieldValue(`${type}Id`, account?.id, true);
    form.setFieldTouched(`${type}Id`, true);
    if (isCrossCcy && !isFlagActive(FeatureFlagName.IAT_CROSS_CURRENCY)) {
      form.setFieldTouched('fundingCurrencyAccountId', true);
      form.setFieldError('fundingCurrencyAccountId', t('not_allowed_cross-currency_transfer'));
    }
  };

  const checkIfAllowedCrossCurrency = async (hasSpotRole: boolean, debitingCurrency?: string, fundingCurrency?: string) => {
    if (hasSpotRole && debitingCurrency && fundingCurrency) {
      if (debitingCurrency !== fundingCurrency) {
        setIsCrossCurrency(true);
      } else {
        setIsCrossCurrency(false);
      }
    } else if (debitingCurrency && fundingCurrency) {
      setIsCrossCurrency(false);
    }
  };

  const handleSetDebitingAccount = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const account = data?.currencyAccounts.find((ca) => ca.id === e.target.value);
    const hasSpotRole = spotBooker || spotInputter;
    const type = 'debitingCurrencyAccount';
    if (account) {
      setAccountValues(type, account);
      form.setFieldValue('instructedCurrency', account?.currencyCode);
      checkIfAllowedCrossCurrency(hasSpotRole, account.currencyCode, form.values.fundingCurrencyAccount.currencyCode);
      if (form.values.fundingCurrencyAccountId && form.values.fundingCurrencyAccount.currencyCode !== account.currencyCode) {
        setAccountValues(type, account, true);
      } else {
        setAccountValues(type, account);
      }
    }
  };

  const handleSetFundingAccount = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const account = [
      ...data?.currencyAccounts,
      ...data?.whitelistedAccounts,
    ].find((ca) => ca.id === e.target.value);
    const hasSpotRole = spotBooker || spotInputter;
    const type = 'fundingCurrencyAccount';
    if (account) {
      checkIfAllowedCrossCurrency(hasSpotRole, form.values.debitingCurrencyAccount.currencyCode, account.currencyCode);
      if (!form.values.debitingCurrencyAccountId) form.setFieldValue('instructedCurrency', account.currencyCode);
      if (form.values.debitingCurrencyAccountId && form.values.debitingCurrencyAccount.currencyCode !== account.currencyCode) {
        setAccountValues(type, account, true);
      } else {
        setAccountValues(type, account);
      }
    }
  };

  const handleAmountChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    form.handleChange(event);
    const valueNum = parseFloat(event.target.value.toString().replace(/,/g, '')) || 0.00;
    form.setFieldTouched('instructedAmount');
    form.setFieldValue('instructedAmount', valueNum.toFixed(2), true);
  };

  const handleFormSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = createConfirmationData();
    setIATRequestValues(body);
    if (!isCrossCurrency) {
      setDrawerState('ConfirmTransfer');
    } else if (isCrossCurrency && isFlagActive(FeatureFlagName.IAT_CROSS_CURRENCY)) {
      form.handleSubmit();
    }
  };

  const switchCurrencies = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown | string }>) => {
    const currency = event.target.value;
    const originalDebitAccount = form.values.debitingCurrencyAccount;
    const originalFundingAccount = form.values.fundingCurrencyAccount;
    if (currency === originalDebitAccount?.currencyCode) {
      form.setFieldValue('instructedCurrency', originalDebitAccount?.currencyCode);
    } else {
      form.setFieldValue('instructedCurrency', originalFundingAccount?.currencyCode);
    }
  };

  const getRemainingBalance = (balance: number, instructedAmount: number) => formatNumber(balance - instructedAmount, 2);

  useEffect(() => {
    if (error) {
      sb.trigger(t('could_not_load_currency_accounts'), 'error');
      logError({ action: 'Error loading Currency Accounts', error });
    }
  }, [error]);

  useEffect(() => {
    setMaxPaymentLimit(accountPaymentConfigQuery.data?.maximumPayment?.buyAmount
      || form.values.debitingCurrencyAccount.availableBalance);
  }, [accountPaymentConfigQuery.data?.maximumPayment?.buyAmount, form.values.debitingCurrencyAccount]);

  useEffect(() => {
    if (defaultFromAccountId && data?.currencyAccounts) {
      const defaultAccount = data.currencyAccounts.find((ca) => ca.id === defaultFromAccountId);
      if (defaultAccount) {
        setAccountValues('debitingCurrencyAccount', defaultAccount);
        form.setFieldValue('instructedCurrency', defaultAccount?.currencyCode);
      }
    }
  }, [defaultFromAccountId, data?.currencyAccounts]);

  return (
    <>
      <BaseDrawer.Header style={{ paddingTop: '60px', lineHeight: '30' }} headerTitle={t('inter_account_transfer')} headerSubtitle="" />
      <div style={{ margin: '30px 0' }}>
        <BaseDrawer.LineBreak />
      </div>
      <BaseDrawer.Body className={styles.drawerBody}>
        {data?.currencyAccounts && !isLoading ? (
          <form onSubmit={handleFormSubmit}>
            <Typography variant="subtitle1" style={{ paddingBottom: '15px' }}>
              {t('please_select_the_account_you_wish_to_send_funds_from')}
              .
            </Typography>
            <Dropdown
              data={mapCurrencyAccountToData(data.currencyAccounts, true)}
              handleChange={(e) => handleSetDebitingAccount(e)}
              helperText=""
              placeholderText={t('please_select_a_currency_account')}
              data-testid="debiting-account"
              selectName="debiting-account"
              selectValue={form.values?.debitingCurrencyAccountId || 'placeholder'}
              hideBalance={false}
            />
            <p>{form.touched.debitingCurrencyAccountId && form.errors.debitingCurrencyAccountId}</p>
            <Typography variant="subtitle1" style={{ paddingBottom: '15px' }}>
              {t('please_select_the_account_you_wish_to_send_funds_to')}
              .
            </Typography>
            <Dropdown
              data={mapCurrencyAccountToData(data.currencyAccounts, true).concat(
                mapCurrencyAccountToData(data.whitelistedAccounts, true, true),
              )}
              handleChange={(e) => handleSetFundingAccount(e)}
              helperText=""
              placeholderText={t('please_select_a_currency_account')}
              data-testid="crediting-account"
              selectName="crediting-account"
              selectValue={form.values?.fundingCurrencyAccountId || 'placeholder'}
              hideBalance={false}
            />
            <p className={styles.red}>
              {form.touched.fundingCurrencyAccountId
                && (form.errors.fundingCurrencyAccountId || form.errors.instructedCurrency)}
            </p>
            <InputLabel htmlFor="funding-single-currency" style={{ display: 'flex' }}>
              <span style={{ fontSize: '14px', paddingRight: '5px' }}>
                {t('i_will_send')}
              </span>
              {' '}
              {form.values?.debitingCurrencyAccount?.currencyCode && !isCrossCurrency && (
                <Flag
                  code={form.values.debitingCurrencyAccount.currencyCode}
                  size="md"
                />
              )}
            </InputLabel>
            <div className={styles.currencyToggleContainer}>
              <Box display="flex" alignItems="left" justifyContent="spaceBetween">
                {isCrossCurrency && isFlagActive(FeatureFlagName.IAT_CROSS_CURRENCY) ? (
                  <div className={styles.ccyContainer}>
                    <Select
                      data-testid="fixed-currency-select-transfer"
                      onChange={switchCurrencies}
                      value={form.values.instructedCurrency}
                      disableUnderline
                      className={styles.dropdown}
                    >
                      {[
                        <MenuItem value={form.values.debitingCurrencyAccount.currencyCode}>
                          {form.values.debitingCurrencyAccount.currencyCode}
                        </MenuItem>,
                        <MenuItem value={form.values.fundingCurrencyAccount.currencyCode}>
                          {form.values.fundingCurrencyAccount.currencyCode}
                        </MenuItem>,
                      ]}
                    </Select>
                  </div>
                ) : null}
                <NumberFormat
                  thousandSeparator=","
                  className={clsx(styles.red, styles.amountInput)}
                  id="funding-currency"
                  name="funding-currency"
                  value={form.values?.instructedAmount || ''}
                  decimalScale={2}
                  fixedDecimalScale
                  customInput={Input}
                  startAdornment={getCurrencySymbol(form.values?.instructedCurrency)}
                  type="text"
                  disableUnderline
                  autoComplete="off"
                  placeholder="0.00"
                  inputProps={{
                    'data-testid': 'funding-currency-input',
                  }}
                  onChange={handleAmountChange}
                />
              </Box>
              {form.touched.instructedAmount && form.errors.instructedAmount ? (
                <p className={styles.red}>
                  {form.touched.instructedAmount && form.errors.instructedAmount}
                </p>
              ) : null}
              {isCrossCurrency && isFlagActive(FeatureFlagName.IAT_CROSS_CURRENCY)
                ? <p className={styles.fxInfo}>{t('fx_will_be_calculated_in_the_next_step_with_an_associated_spot_trade')}</p>
                : null}
              {!isCrossCurrency ? (
                <Box className={styles.balanceAfter}>
                  <Typography variant="subtitle1">
                    {t('balance_after')}
                    :
                    <span className={styles.red}>
                      {`${getCurrencySymbol(form.values?.instructedCurrency)}${form.values.debitingCurrencyAccount?.availableBalance
                        ? getRemainingBalance(form.values?.debitingCurrencyAccount?.availableBalance, form.values?.instructedAmount) : '0.00'}`}
                    </span>
                  </Typography>
                </Box>
              ) : null}
            </div>
            <div style={{ marginTop: '16px' }}>
              <InputAndLabel
                label={t('reference_for_transfer')}
                name="reference"
                id="reference"
                testId="reference"
                onChange={(e) => {
                  form.setFieldTouched('reference', true);
                  form.handleChange(e);
                }}
                onBlur={form.handleBlur}
                value={form.values.reference}
                error={form.touched.reference && Boolean(form.errors.reference)}
                helperText={form.touched.reference && form.errors.reference}
              />
            </div>
            <div className={styles.alertContainer}>
              <Alert
                variant="info"
                text={<strong>{t('please_note')}</strong>}
                subText={t('this_inter_account_transfer_will_be_settled')}
              />
            </div>
            <div className={styles.buttonsContainer}>
              <ActionButton
                style={{ background: '#F7F7F7', color: '#212529' }}
                onClick={onClose}
              >
                {t('cancel')}
              </ActionButton>
              {!isCrossCurrency
                ? <ActionButton disabled={!form.isValid} type="submit" style={{ width: '80%' }}>{t('next')}</ActionButton>
                : <ActionButton disabled={!isFlagActive(FeatureFlagName.IAT_CROSS_CURRENCY) || !form.isValid} type="submit" style={{ width: '80%' }}>{t('confirm_and_send_transfer')}</ActionButton>}
            </div>
          </form>
        ) : <Loader testId="IAT-drawer-loader" />}
      </BaseDrawer.Body>
    </>
  );
};

export default IATDrawerForm;
