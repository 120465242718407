import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    drawerContainer: {
      width: '1000px !important',
    },
    title: {
      color: '#2C2C2C',
      fontSize: '18px',
      fontWeight: typography.fontWeightBold,
      paddingRight: '9px',
      alignSelf: 'center',
    },
    unreadSubtitle: {
      color: '#1473E6',
      fontSize: '12px',
      borderLeft: '1px solid rgba(112, 112, 112, .1)',
      paddingLeft: '9px',
      alignSelf: 'center',
    },
    unreadSubtitleButton: {
      color: '#1473E6',
    },
    noNewSubtitle: {
      color: '#9A9A9A',
      fontSize: '12px',
      borderLeft: '1px solid rgba(112, 112, 112, .1)',
      paddingLeft: '9px',
      alignSelf: 'center',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: '84px',
      padding: '37px 0 26px 37px',
      borderBottom: '1px solid rgba(112, 112, 112, .1)',
    },
    emptyPlaceholder: {
      color: '#9a9a9a',
      margin: 'auto',
    },
    emptyImage: {
      height: '168px',
      width: '168px',
      backgroundColor: 'rgba(225, 225, 225, 0.2)',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '23px',
      '& > img': {
        height: '90px',
        display: 'block',
        opacity: 0.5,
        padding: '6px 0 0 6px',
      },
    },
  }),
  { name: 'NotificationDrawer' },
);

export default useStyles;
