import React, { useState } from 'react';

import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faFileExcel, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import t from 'utils/translationHelper';

import useReportsPolling, { FileType, PageType } from '../../../hooks/useReportsPolling';
import { TProgramImage } from '../../../services/HedgingPrograms/hedgingPrograms.interfaces';

import useStyles from './ExportMenu.styles';

interface IExportMenuProps {
  programId: string;
  disabled?: boolean;
  programImages: TProgramImage[];
}
const ExportMenu: React.FC<IExportMenuProps> = (props: IExportMenuProps) => {
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const {
    programId, disabled, programImages,
  } = props;

  const styles = useStyles();
  const { executeReportGeneration } = useReportsPolling(PageType.Programs);
  const handleReportGeneration = async (fileType: FileType) => {
    await executeReportGeneration(
      fileType,
      programId,
      undefined,
      undefined,
      undefined,
      fileType,
      undefined,
      undefined,
      programImages,
    );
  };
  const menuItems = [
    {
      content: t('export_pdf'),
      icon: faFilePdf,
      onClick: () => {
        handleReportGeneration(FileType.PDF);
      },
      underline: true,
    },
    {
      content: t('export_excel'),
      icon: faFileExcel,
      onClick: () => handleReportGeneration(FileType.EXCEL),
    },
  ];
  return (
    <div className={styles.menuContainer}>
      <APMenu
        open={Boolean(open)}
        setOpen={setOpen}
        anchorEl={open}
        buttonProps={{ disabled }}
        buttonTitle={t('export')}

      >
        {
          menuItems.map((item) => <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>)
        }
      </APMenu>
    </div>
  );
};

export default ExportMenu;
