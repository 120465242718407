import React from 'react';

import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';

import HeaderButtons from './HeaderButtons/HeaderButtons';
import HeaderText from './HeaderText/HeaderText';
import useStyles from './styles';

interface IProps {
  selectedPayment: PaymentDto | undefined;
  viewOnly?: boolean,
}

const Header: React.FC<IProps> = ({
  selectedPayment,
  viewOnly,
}: IProps) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <HeaderText
        selectedPayment={selectedPayment}
      />
      {
        !viewOnly
          && (
          <HeaderButtons
            paymentId={selectedPayment?.id || '-'}
            showDownloadButton={
              !(selectedPayment?.status === PaymentStatus.VALIDATED
                || selectedPayment?.status === PaymentStatus.UPLOADED)
          }
          />
          )
      }
    </div>
  );
};

export default Header;
