import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(
  ({ typography }) => ({
    container: {
      minWidth: '637px',
      '& .stepper': {
        paddingBottom: '30px',
        margin: '0 56px',
        '& h6': {
          color: '#a0a0a0',
          fontSize: '14px',
          marginTop: '16px',
        },
        '& .active': {
          color: '#1E8777',
        },
      },
    },
    title: {
      fontSize: '18px',
      color: '#2C2C2C',
      textAlign: 'center',
      fontWeight: typography.fontWeightMedium,
      marginBottom: '33px',
    },
    headerContainer: {
      margin: '0 -56px',
      borderBottom: '1px solid rgba(112, 112, 112, .1)',
    },
    inputField: {
      marginTop: '5px',
    },
    input: {
      marginTop: '24px',
    },
    nextButton: {
      marginTop: '50px',
      display: 'flex',
      flexDirectyion: 'row',
      justifyContent: 'flex-end',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '87px',
    },
    entitiesSubtitle: {
      fontSize: '14px',
      color: '#222222',
      marginTop: '40px',
    },
  }),
  { name: 'NewUserRequestModal' },
);

export default useStyles;
