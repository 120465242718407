import React, { useState } from 'react';

import { AccountDto } from '@alpha/auth-dtos';
import { Box } from '@alpha/ui-lib/ui/external';
import t from 'utils/translationHelper';

import DrawerBackdropLoader from '../../../../Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import Account from '../Account/Account';

import useStyles from './Body.styles';

interface IProps {
  accounts: AccountDto[];
  filterAccounts: string;
  setFilterAccounts?: (val: string) => void;
  onClose?: (event: any) => void;
}

const Body: React.FC<IProps> = (props: IProps) => {
  const {
    accounts, onClose, filterAccounts, setFilterAccounts,
  } = props;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  return (
    <Box className={classes.bodyWrapper}>
      {loading ? (
        <DrawerBackdropLoader
          display={loading}
          text={t('switching_account_please_wait')}
        />
      ) : (
        <>
          <Box className={classes.noOfAccounts}>
            {t('number_of_entities')}
            {' '}
            <b>{accounts.length}</b>
          </Box>
          {accounts.map((account: AccountDto) => (
            <Account
              key={account.id}
              setFilterAccounts={setFilterAccounts}
              onClose={onClose}
              filterAccounts={filterAccounts}
              account={account}
              setLoading={setLoading}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default Body;
