import React, { useState } from 'react';
import IATDrawerContainer from 'components/Drawer/IATDrawer/IATDrawerContainer';
import useSwitchAccount from 'hooks/useSwitchAccount';
import t from 'utils/translationHelper';

import { TradeDto } from '@alpha/fx-dtos';
import { IATDraftResponse } from '@alpha/iat-dtos';
import { Loader } from '@alpha/ui-lib/ui/Loader';

import clipboardCross from '../../../assets/clipboardCross.svg';
import BackdropLoader from '../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import CreatePaymentDrawer from '../../../components/Molecules/Payments/CreatePaymentDrawer';
import CreateSpotDrawer from '../../../components/Molecules/Trade/CreateSpotDrawer';
import EmptyTable from '../../../components/Table/EmptyTable';
import { TCurrencyTab } from '../../../models/currencyAccounts';
import AccountInfoDrawer from '../AccountInfoDrawer/AccountInfoDrawer';
import useStyles from '../CurrencyAccounts.styles';
import CurrencyStats from '../CurrencyStats';

import useBody from './useBody';

interface IProps {
  tabs: TCurrencyTab[];
  loading: boolean;
  selectedTabIndex: number;
}

const Body: React.FC<IProps> = (props: IProps) => {
  const { tabs, loading, selectedTabIndex } = props;
  const classes = useStyles();
  const [
    selectedDraftIAT,
    setSelectedDraftIAT,
  ] = useState<IATDraftResponse>();

  const {
    drawer, selectedAccount, handleAccountTableRowClick, handleOpenDrawer,
  } = useBody(selectedTabIndex, tabs);
  const { isEMoneyDisabled } = useSwitchAccount();

  const [tradeDraft,
    setTradeDraft] = useState<TradeDto>();
  const [createSpotDrawerOpen,
    setCreateSpotDrawerOpen] = useState(false);

  if (loading) {
    return (
      <Loader testId="backdrop-loader" />
    );
  }
  if (tabs.length === 0) {
    return (
      <EmptyTable title="No Accounts found" icon={clipboardCross} />
    );
  }
  return (
    <div className={classes.container}>
      <CurrencyStats
        currencyCode={tabs[selectedTabIndex].currencyCode}
        currency={tabs[selectedTabIndex].currency}
        handleAccountTableRowClick={handleAccountTableRowClick}
        handleOpenDrawer={handleOpenDrawer}
      />
      <AccountInfoDrawer
        open={drawer === 'PendingTransfers' || drawer === 'AccountDrawer'}
        tabIndex={drawer === 'AccountDrawer' ? 0 : 1}
        handleOpenDrawer={handleOpenDrawer}
        selectedAccount={selectedAccount}
        setSelectedDraftIAT={setSelectedDraftIAT}
        selectedDraftIAT={selectedDraftIAT}
      />
      <CreatePaymentDrawer
        open={drawer === 'CreatePayment'}
        onClose={() => handleOpenDrawer(undefined)}
        currencyAccountId={selectedAccount?.account.id}
        triggerSpotOpen={{
          setOpen: setCreateSpotDrawerOpen,
          setTradeDraft,
        }}
      />
      <CreateSpotDrawer
        open={createSpotDrawerOpen}
        onClose={() => {
          setCreateSpotDrawerOpen(false);
        }}
        tradeDraft={tradeDraft}
        heading={isEMoneyDisabled() ? t('first_party_spot') : t('new_spot_trade')}
      />
      <IATDrawerContainer
        open={drawer === 'IAT'}
        onClose={() => handleOpenDrawer(undefined)}
        openFromCurrencyAccountId={selectedAccount?.account.id}
      />
    </div>
  );
};

export default Body;
