import React, { memo } from 'react';
import clsx from 'clsx';
import { groupBy } from 'lodash';
import t from 'utils/translationHelper';

import { AccountType } from '@alpha/currency-accounts-dtos';
import { InputLabel, MenuItem, Select } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import { Item } from './Item/Item';
import RenderValue from './RenderValue/RenderValue';
import { useStyles } from './Dropdown.styles';

export type TData = {
  id: string;
  friendlyName: string;
  balance: number;
  accountType: AccountType;
  currencyCode: string;
  externalAccount?: boolean;
  iban?: string;
  swift?: string;
  sortCode?: string;
  accountNumber?: string;
  reference?: string;
  clientAccountName: string;
};
interface IProps {
  data: TData[];
  helperText?: string | string[] | false;
  selectValue?: string;
  selectName?: string;
  placeholderText?: string;
  disabled?: boolean;
  hideBalance?: boolean;
  testId?: string;
  handleChange?: (event: any) => void;
  handleBlur?: (event: any) => void;
}
const Dropdown: React.FC<IProps> = (props: IProps) => {
  const {
    data,
    selectValue,
    selectName,
    helperText,
    placeholderText,
    disabled,
    hideBalance,
    testId,
    handleChange,
    handleBlur,
  } = props;
  const styles = useStyles();


  const dataGroupedByCurrency = groupBy(
    data,
    (dt: TData) => dt.currencyCode,
  );

  const dropdownItems = Object.keys(dataGroupedByCurrency).map(
    (currencyCode: string) => {
      const elements: JSX.Element[] = [
        <Item.Sticky currencyCode={currencyCode} />,
      ];
      const dataRows = dataGroupedByCurrency[currencyCode].map(
        (dropdownItem) => (
          <MenuItem
            key={dropdownItem.id}
            value={dropdownItem.id}
            style={{ padding: 0, backgroundColor: 'transparent' }}
          >
            <Item.Data item={dropdownItem} />
          </MenuItem>
        ),
      );
      return elements.concat(dataRows);
    },
  );

  const renderValue: Function | undefined = (
    values: string,
    _data: TData[],
  ) => {
    const selectedAccount = _data.find(
      (dt) => dt.id === values,
    );
    if (!selectedAccount) return undefined;
    return () => (
      <RenderValue selectedAccount={selectedAccount} hideBalance={hideBalance || selectedAccount.externalAccount} />
    );
  };

  const showValueOrPlaceHolder = selectValue || 'placeholder';
  const renderedPlaceHolderText = placeholderText || t('select_account');
  return (
    <div className={styles.dropdownContainer}>
      <Select
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        value={showValueOrPlaceHolder}
        name={selectName}
        id={testId || 'dropdown'}
        renderValue={renderValue(showValueOrPlaceHolder, data)}
        disableUnderline
        fullWidth
        className={styles.dropdown}
        MenuProps={{
          className: styles.dropdownList,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {[
          <MenuItem value="placeholder">
            <Typography variant="subtitle1" className={styles.placeholder}>
              {renderedPlaceHolderText}
            </Typography>
          </MenuItem>,
          ...dropdownItems,
        ]}
      </Select>
      <InputLabel className={clsx(styles.error, styles.red)}>
        {helperText}
      </InputLabel>
    </div>
  );
};

export default memo(Dropdown);
