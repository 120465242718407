import React from 'react';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';

import { ModalType } from '../../../../Body';

import useStyles from './RejectButtons.styles';

interface IRejectBeneficiaryProps {
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>;
}

const RejectBeneficiary: React.FC<IRejectBeneficiaryProps> = (props: IRejectBeneficiaryProps) => {
  const { setModalOpen } = props;
  const classes = useStyles();

  return (
    <ActionButton
      style={{ background: '#F7F7F7', color: '#212529' }}
      testId="reject-bene-btn"
      onClick={() => setModalOpen('reject')}
      className={classes.rejectButton}
    >
      {t('reject_beneficiary')}
    </ActionButton>
  );
};

export default RejectBeneficiary;
