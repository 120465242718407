import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';

import Authorization from '../../../../../../hocs/Authorization';
import { useAuthorization } from '../../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../../models/user';
import { TStore } from '../../../../../../store';
import { ModalType } from '../../../Body';
import { ISelectedBeneficiary } from '../../BeneInfoDrawer';

import ApproveButton from './ApproveButton/ApproveButton';
import RejectButton from './RejectButton/RejectButton';
import useStyles from './ActionButtons.styles';

interface IActionButtonsProps {
  selectedBeneficiary: ISelectedBeneficiary;
  initiateApproveBeneficiary: (
    own: boolean,
    __batchId?: string
  ) => void;
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>;
}
const ActionButtons: React.FC<IActionButtonsProps> = ({
  selectedBeneficiary,
  initiateApproveBeneficiary,
  setModalOpen,
}: IActionButtonsProps) => {
  const classes = useStyles();
  const beneficiaryApproverOwn = useAuthorization([
    [UserRole.BENEFICAIRY_APPROVER_OWN],
  ]);
  const beneficiaryApprover = useAuthorization([
    [UserRole.BENEFICIARY_APPROVER],
  ]);

  const approverButton = () => {
    if (
      beneficiaryApproverOwn.authorized
      && selectedBeneficiary.beneficiary?.uploadedBy === user?.Username
    ) {
      return (
        <>
          <RejectButton setModalOpen={setModalOpen} />
          <ApproveButton
            initiateApproveBeneficiary={initiateApproveBeneficiary}
            own
          />
        </>
      );
    }
    if (
      beneficiaryApprover.authorized
      && selectedBeneficiary.beneficiary?.uploadedBy !== user?.Username
    ) {
      return (
        <>
          <RejectButton setModalOpen={setModalOpen} />
          <ApproveButton
            initiateApproveBeneficiary={initiateApproveBeneficiary}
            own={false}
          />
        </>
      );
    }
    return <></>;
  };

  let user = useSelector((state: TStore) => state.user.user);
  return (
    <div className={clsx(classes.ctas, 'noPadding')}>
      <StyledDivider />
      <div>
        <Authorization
          requiredRoles={[
            [UserRole.BENEFICIARY_APPROVER, UserRole.BENEFICAIRY_APPROVER_OWN],
          ]}
          resourceCreatorUserId={selectedBeneficiary?.beneficiary?.uploadedBy}
        >
          {approverButton()}
        </Authorization>
      </div>
    </div>
  );
};

export default ActionButtons;
