import { CurrencyAccountTransferStatus } from '@alpha/currency-accounts-dtos';
import { PaymentStatus } from '@alpha/payments-dtos';

export const mapPaymentStatusDisplay = (
  paymentStatus: PaymentStatus | CurrencyAccountTransferStatus,
): {
  variant: 'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined, text:
    string
} => {
  switch (paymentStatus) {
    case PaymentStatus.COMPLETED:
      return { variant: 'success', text: 'COMPLETED' };
    case PaymentStatus.CLIENT_APPROVED:
      return { variant: 'success', text: 'APPROVED' };
    case PaymentStatus.INVALID:
      return { variant: 'error', text: 'INVALID' };
    case PaymentStatus.CLIENT_REJECTED:
      return { variant: 'error', text: 'REJECTED' };
    case PaymentStatus.CANCELLED:
      return { variant: 'error', text: 'CANCELLED' };
    case PaymentStatus.SENT:
      return { variant: 'info', text: 'SENT' };
    case PaymentStatus.SUBMITTED:
    default:
      return { variant: 'info', text: 'PENDING_APPROVAL' };
    case PaymentStatus.VALIDATED:
      return { variant: 'default', text: 'DRAFT' };
    case PaymentStatus.UPLOADED:
      return { variant: 'default', text: 'DRAFT' };
    case PaymentStatus.PROCESSING:
      return { variant: 'info', text: 'PROCESSING' };
    case PaymentStatus.AWAITING_MONEY_IN:
      return { variant: 'orange', text: 'AWAITING MONEY IN' };
    case PaymentStatus.AWAITING_RELEASE:
      return { variant: 'orange', text: 'AWAITING RELEASE' };
  }
};

export default mapPaymentStatusDisplay;
