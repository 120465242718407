import React, { useEffect, useState } from 'react';
import { TStatItem } from 'components/Molecules/Credit/CreditSummary/CreditSummary';
import MarginHeldAmounts from 'components/Molecules/Credit/MarginHeldAmounts/MarginHeldAmounts';
import moment from 'moment';
import t from 'utils/translationHelper';
import { formatNumber, getCurrencySymbol } from 'utils/currency.helpers';

import { CreditFacilityDto, CreditFacilityTermDto } from '@alpha/credit-dtos';
import { Flag } from '@alpha/ui-lib/ui/Flag';

import useStyles from './index.styles';

type TUseFacilityTermReturnType = {
  termSummaries: TStatItem[],
  bespokeItems: TStatItem[],
}

const useFacilityTerm = (facility?: CreditFacilityDto, termSummary?: CreditFacilityTermDto): TUseFacilityTermReturnType => {

  const styles = useStyles();
  const [termSummaries, setTermSummaries] = useState<TStatItem[]>([]);
  const [bespokeItems, setBespokeItems] = useState<TStatItem[]>([]);

  const mapTermToBespokeItems = (term: CreditFacilityTermDto) => {
    const bespokeTerms = [];

    if (term.bespokeTerms.noMarginCall) {
      bespokeTerms.push({
        header: t('no_margin_call'),
        value: t('active'),
      });
    }

    if (term.bespokeTerms.marginDiscretionWindow !== undefined) {
      bespokeTerms.push({
        header: t('margin_discretion_window'),
        value: term.bespokeTerms.marginDiscretionWindow ? t('active') : t('inactive'),
      });
    }

    if (term.bespokeTerms.afMargin) {
      bespokeTerms.push({
        header: 'AF Margin',
        value: term.bespokeTerms.marginDiscretionWindow ? `${formatNumber(term.bespokeTerms.afMargin, 2, true)}%` : '-',
        helpMessage: <>
          {t('adverse_fluctuation_margin')}
          {' '}
          <br />
          {' '}
          {t('this_is_the_percentage_we_will_magin_call')}
        </>,
      });
    }

    if (term.bespokeTerms.marginIncrement) {
      bespokeTerms.push({
        header: t('increment'),
        value: term.bespokeTerms.marginDiscretionWindow ? `${formatNumber(term.bespokeTerms.marginIncrement, 2, true)}%` : '-',
      });
    }

    if (term.bespokeTerms.mdwStartDate) {
      bespokeTerms.push({
        header: t('start_date'),
        value: term.bespokeTerms.marginDiscretionWindow ? moment(term.bespokeTerms.mdwStartDate).format('DD/MM/yyyy') : '-',
      });
    }

    if (term.bespokeTerms.mdwEndDate) {
      bespokeTerms.push({
        header: t('end_date'),
        value: term.bespokeTerms.marginDiscretionWindow ? moment(term.bespokeTerms.mdwEndDate).format('DD/MM/yyyy') : '-',
      });
    }
    setBespokeItems(bespokeTerms);
  };

  const mapTermToTermSummary = (term: CreditFacilityTermDto) => {
    const marginHeldDetails = term?.marginHeldAmounts?.length ? <MarginHeldAmounts amounts={term.marginHeldAmounts} /> : null;
    const summaries = [
      {
        header: t('facility_currency'),
        value: (<span className={styles.flag}>
          <Flag code={term.currencyCode} size="md" showCodeLabel />
          {' '}
        </span>),
        helpMessage: t('all_bespoke_terms_converted_to_base_currency_for_summary_format'),
      },
      {
        header: t('term_limit'),
        value: `${getCurrencySymbol(term.currencyCode)}${formatNumber(term?.facilityLimit, 2, true)}`,
      },
      {
        header: t('utilised'),
        value: `${getCurrencySymbol(term.currencyCode)}${formatNumber(term.utilisationAmount, 2, true)}`,
      },
      {
        header: t('margin_held'),
        value: `${getCurrencySymbol(term.currencyCode)}${formatNumber(term?.marginHeld || 0, 2, true)}`,
        helpMessage: marginHeldDetails,
      },
      {
        header: t('IM'),
        value: term?.initialMargin || term?.initialMargin === 0 ? `${term.initialMargin}%` : '-',
      },
      {
        header: t('VM'),
        value: term?.variationMargin || term?.variationMargin === 0 ? `${term.variationMargin}%` : '-',
      },
      {
        header: t('increment'),
        value: term?.marginIncrement || term?.marginIncrement === 0 ? `${term.marginIncrement}%` : '-',
      },
      {
        header: t('tennor_months'),
        value: term?.tenor ? `${term.tenor}` : '-',
      },
      {
        header: t('mtm_valuation'),
        value: term.currencyCode && term.mtmAmount ? `${getCurrencySymbol(term.currencyCode)}${formatNumber(term.mtmAmount, 2, true)}` : '-',
      },
    ];

    if (facility?.facilityTerms?.length === 1) { // Single term only
      if (facility?.marginCallDelay) {
        summaries.push({
          header: t('margin_call_delay'),
          value: facility.marginCallDelay ? `${facility.marginCallDelay} Days` : '-',
        });
      }

      if (facility?.marginCallCap) {
        summaries.push({
          header: t('margin_call_cap'),
          value: facility.marginCallCap ? `${getCurrencySymbol(facility.currencyCode)}${formatNumber(facility.marginCallCap, 2, true)}` : '-',
        });
      }
    }
    setTermSummaries(summaries);
  };

  useEffect(() => {
    if (termSummary?.bespokeTerms) {
      mapTermToBespokeItems(termSummary);
    }
    if (termSummary?.currencyCode) {
      mapTermToTermSummary(termSummary);
    }
  }, [facility, termSummary]);

  return {
    termSummaries,
    bespokeItems,
  };
};

export default useFacilityTerm;
