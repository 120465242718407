import React, { useMemo } from 'react';

import { Input } from '@alpha/ui-lib/ui/Input';
import { Box } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'utils/translationHelper';
import useStyles from './Header.styles';

interface IProps {
  handleKeyUp: (event: any) => void;
}

const Header: React.FC<IProps> = (props: IProps) => {
  const { handleKeyUp } = props;

  const classes = useStyles();
  return (
    <Box className={classes.headerWrapper}>
      <Box className={classes.breadcrumbs}>
        {t('switch_entity')}
      </Box>
      <Box className={classes.title}>{t('choose_which_entity_to_view')}</Box>
      <Box className={classes.inputWrapper}>
        <Input
          onKeyUp={handleKeyUp}
          fullWidth
          testId="input"
          InputProps={useMemo(
            () => ({
              startAdornment: <FontAwesomeIcon
                className={classes.icon}
                icon={faSearch as IconProp}
              />,
            }),
            [],
          )}
          variant="filled"
          placeholder={t('search_entity')}
        />
      </Box>
    </Box>
  );
};

export default Header;
