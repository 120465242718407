import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  dropdownContainer: {
    position: 'relative',
  },
  dropdown: {
    borderRadius: '5px',
    background: '#F7F7F7',
    opacity: '100%',
    width: '353px',
    height: '44px',
    color: '#343434',
    fontSize: '14px',
    '& .MuiSelect-select.MuiSelect-select': {
      background: '#F7F7F7',
      paddingLeft: '16px',
    },
  },
  placeholder: {
    color: '#34343473',
    fontWeight: 400,
  },
  menuItem: {
    '&:hover': {
      background: '#F7F7F7',
    },
  },
}), { name: 'ForwardContractSelectDropdown' });
export default useStyles;
