import React from 'react';

import { Box, Typography } from '@alpha/ui-lib/ui/external';

import { TStatementData } from '../../../../models/statements';

import useStyles from './Header.styles';

import t from 'utils/translationHelper';


interface IHeader {
  statement: TStatementData;
}

const Header: React.FC<IHeader> = ({ statement }: IHeader) => {

  const classes = useStyles();

  return (
    <Box className={classes.headerContent}>
      <Box className={classes.titleWrapper}>
        <Typography className={classes.headerTitle}>
          {`${statement.type === 'debit' ? t('debit_confirmation') : t('credit_confirmation')}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
