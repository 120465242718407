/* eslint-disable react/jsx-indent */
import React, { memo } from 'react';
import t from 'utils/translationHelper';

import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';
import { Box, Button } from '@alpha/ui-lib/ui/external';
import APPagination from '@alpha/ui-lib/ui/Pagination/APPagination/APPagination';
import { Status, TStatusVariants } from '@alpha/ui-lib/ui/Status';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Table as GenericTable } from '../../../../../components/Table/Table';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../hooks/useReportsPolling';
import { TSearchParams } from '../../../../../hooks/useSearch';
import { formatNumber } from '../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import { ISelectedAccount } from '../../../../CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';

import { getColumns } from './columns';
import useStyles from './Table.styles';

interface IProps {
  data: Array<PaymentDto>;
  isSameCurrency: boolean;
  postApproval: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  handleNextPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  handlePreviousPage: (searchParams: TSearchParams, skipAmount?: number) => Promise<void>;
  setBeneficiary: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setSelectedCurrencyAccount: React.Dispatch<React.SetStateAction<ISelectedAccount | undefined>>
}

const getPaymentStatusVariant = (status: PaymentStatus): TStatusVariants | undefined => {
  switch (status) {
    case PaymentStatus.CLIENT_REJECTED:
    case PaymentStatus.CANCELLED:
    case PaymentStatus.INVALID:
      return 'error';
    case PaymentStatus.COMPLETED:
      return 'success';
    case PaymentStatus.AWAITING_MONEY_IN:
    case PaymentStatus.AWAITING_RELEASE:
    case PaymentStatus.PROCESSING:
      return 'orange';
    default:
      return 'info';
  }
};

const Table: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  const {
    data,
    hasNext,
    hasPrevious,
    postApproval,
    isSameCurrency,
    handleNextPage,
    handlePreviousPage,
    setBeneficiary,
    setSelectedCurrencyAccount,
  } = props;


  const { executeReportGeneration } = useReportsPolling(PageType.Payments);

  const handleReportGeneration = async (type: FileType, id: string, downloadType: DownloadType) => {
    await executeReportGeneration(type, id, undefined, undefined, downloadType);
  };

  const tableData = data.map(
    (item: PaymentDto) => ({
      beneficiaryName:
        <Button
          disableRipple
          className={styles.drawerButton}
          onClick={() => {
            setBeneficiary(item.beneficiaryId);
          }}
        >
          {item.beneficiaryName}
        </Button>,
      purposeOfPayment: item.purpose,
      paymentRef: item.reference,
      paymentDate: formatIsoDate(item.date),
      paymentAmount: (
        <Box textAlign="right" fontWeight="bold">
          {formatNumber(item.amount, 2)}
          {' '}
          {item.debitingCurrency}
        </Box>
      ),
      fixedAmount: (
        <Box textAlign="right" fontWeight="bold">
          {formatNumber(item.amount, 2)}
          {' '}
          {item.debitingCurrency}
        </Box>
      ),
      paymentStatus: (
        <Box textAlign="right">
          <Status
            variant={getPaymentStatusVariant(item.status)}
          >
            {item.status}
          </Status>
        </Box>
      ),
      downloadButton: (
        <Box className={styles.columnDownloadButton}>
          <APTooltip title={`Download ${!isSameCurrency ? 'FX' : ''} ticket`}>
            <Button
              className={styles.downloadButton}
              size="large"
              onClick={() => handleReportGeneration(FileType.PDF, item.id, DownloadType.Single)}
            >
              <FontAwesomeIcon className={styles.downloadIcon} icon={faDownload as IconProp} />
            </Button>
          </APTooltip>
        </Box>
      ),
    }),
  );

  return (
    <>
      <div className={styles.genericTableWrapper}>
        <GenericTable
          columns={getColumns(postApproval)}
          data={tableData}
          skip={0}
        />
      </div>
      <Box className={styles.paginationWrapper}>
        <APPagination
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          handleNext={handleNextPage as any} // need to update props here on sb
          handlePrevious={handlePreviousPage as any} // need to update props here on sb
          nextString={t('next^')}
          prevString={t('prev^')}
        />
      </Box>
    </>
  );
};
export default memo(Table);
