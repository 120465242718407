import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: {
      marginTop: '30px',
      display: 'flex',
      flexDirection: 'column',
      '& .referenceAndAmount, .transferAccounts, .approvalInfoAndButtons, .approvalInfo': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      },
      '& .transferAccounts': {
        padding: '24px 0',
        justifyContent: 'flex-start',
        '& svg': {
          margin: '0 32px',
          fontSize: '16px',
          color: '#818181',
        },
      },
      '& .approvalInfoAndButtons': {
        paddingBottom: '16px',
        '& .approvalInfo': {
          '& .info': {
            borderRight: '1px solid rgba(112, 112, 112, 0.1)',
            paddingRight: '24px',
            marginRight: '24px',
            width: '225px',
            wordWrap: 'break-word',
          },
        },
        '& .buttons': {
          display: 'flex',
          marginLeft: '16px',
          '& button': {
            minWidth: '100px',
          },
          '& .rejectButton': {
            marginRight: '6px',
            backgroundColor: 'rgba(185, 86, 86, 0.2)',
            transition: '0.5s ease-in-out',
          },
          '& .rejectButton:hover': {
            color: 'white',
            backgroundColor: 'rgb(185, 86, 86)',
          },
        },
      },
    },
    borderBottom: {
      borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
    },
    mainText: {
      fontSize: '16px',
      fontWeight: 600,
    },
    lightText: {
      color: '#818181',
      fontSize: '12px',
      fontWeight: 600,
    },
  }),
  { name: 'AccountsTable' },
);

export default useStyles;
