import React, { useState } from 'react';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { Typography } from '@alpha/ui-lib/ui/external';

import DropDownWrapper from './DropDownWrapper/DropDownWrapper';

interface IProps {
  currentOptions: Approver[],
  loading: boolean;
  approvers: Approver[],
  displayMultiSelect: boolean,
  menuCloseCount: number,
  setMenuCloseCount?: React.Dispatch<React.SetStateAction<number>>
  setOpen?: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  setCurrentOptions?: React.Dispatch<React.SetStateAction<Approver[]>>,
  setTextValue?: React.Dispatch<React.SetStateAction<string>>,
  setDisplayMultiSelect?: (val: boolean) => void,
  submitApproverEmails: (id: string, type: string) => Promise<boolean>,
  type: 'Payment' | 'Transfer' | 'Beneficiary',
  id?: string,
}

const MultiSelectSection = ({
  id,
  approvers,
  currentOptions,
  loading,
  displayMultiSelect,
  menuCloseCount,
  setMenuCloseCount,
  setOpen,
  setCurrentOptions,
  setTextValue,
  setDisplayMultiSelect,
  submitApproverEmails,
  type,
}: IProps): React.ReactNode => {
  const [awaitingSubmit, setAwaitingSubmit] = useState<boolean>(false);


  const filterEligibleApprovers = (): Approver[] => approvers.filter(
    (approver: Approver) => approver.eligible,
  );

  const handleSubmit = async (): Promise<void> => {
    if (!id) {
      return;
    }
    setAwaitingSubmit(true);
    const success = await submitApproverEmails(id, type);
    if (success) {
      if (setDisplayMultiSelect) { setDisplayMultiSelect(false); }
      if (setOpen) { setOpen(null); }
    }
    setAwaitingSubmit(false);
    if (setCurrentOptions) { setCurrentOptions([]); }
  };

  return (
    <>
      {
        !displayMultiSelect
          ? (
            <Typography>
              {t('send_email_reminder_to_approvers')}
            </Typography>
          )
          : (
            <DropDownWrapper
              setMenuCloseCount={setMenuCloseCount}
              menuCloseCount={menuCloseCount}
              loading={loading}
              currentOptions={currentOptions}
              awaitingSubmit={awaitingSubmit}
              handleSubmit={handleSubmit}
              eligibleApprovers={filterEligibleApprovers}
              setTextValue={setTextValue}
              setCurrentOptions={setCurrentOptions}
              id={id}
            />
          )
      }

    </>
  );
};

export default MultiSelectSection;
