import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useAuthorization from 'hooks/useAuthorization';
import useGetFeatureFlags from 'hooks/useGetFeatureFlags';
import { UserRole } from 'models/user';
import t from 'utils/translationHelper';
import { FeatureFlagName } from 'services/Config/config.service';

import { EmailNotificationType, UsersEmailSettingsDto } from '@alpha/notifications-dtos';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';

import useAccountSettings from '../../../../hooks/useAccountSettings';

import useStyles from './TeamNotificationMatrixTable.styles';

interface ITeamNotificationMatrixProps {
  teamNotificationSettings: UsersEmailSettingsDto[];
  setTeamNotificationSettings?: React.Dispatch<React.SetStateAction<UsersEmailSettingsDto[]>>,
  readonly?: boolean;
}

const TeamNotificationMatrixTable: React.FC<ITeamNotificationMatrixProps> = (
  {
    teamNotificationSettings, setTeamNotificationSettings, readonly = false,
  }: ITeamNotificationMatrixProps,
) => {
  const { isFlagActive } = useGetFeatureFlags();
  const classes = useStyles();

  const {
    portalNotificationColumns,
    portalNotificationColumnGroups: columnGroups,
  } = useAccountSettings();
  const [data, setData] = useState<any[]>([]);

  const { authorized: iatInputterAuthorized } = useAuthorization([[UserRole.IAT_INPUTTER]]);
  const { authorized: iatApproverAuthorized } = useAuthorization([[UserRole.IAT_APPROVER]]);

  const accountColumn = [{
    header: t('user'),
    accessor: 'user',
    width: 130,
  }];

  const notificationColumns = portalNotificationColumns.filter((column) => !column.hidden).map((column) => ({
    header: column.headerText ?? column.name,
    accessor: column.type as string,
    width: 130,
  }));

  const columns = accountColumn.concat(notificationColumns);

  const getCheckbox = (
    settings: UsersEmailSettingsDto,
    name: string,
    type: EmailNotificationType,
    value?: boolean,
    setValue?: React.Dispatch<React.SetStateAction<boolean>>,
    disabled = false,
  ) => (
    <Checkbox
      testId={`send-email-notifcation-${name}`}
      label=""
      checked={value}
      disabled={disabled}
      onChange={(event) => {
        if (event.target.checked) {
          settings.enabled.push(type);
          const index = settings.disabled.indexOf(type);
          settings.disabled.splice(index, 1);
        } else {
          settings.disabled.push(type);
          const index = settings.enabled.indexOf(type);
          settings.enabled.splice(index, 1);
        }

        const tableData = mapAccountSettingsToData(teamNotificationSettings);
        setData(tableData);
        if (setTeamNotificationSettings) {
          setTeamNotificationSettings([...teamNotificationSettings]);
        }
      }}
    />
  );

  const mapAccountSettingsToData = (settings: UsersEmailSettingsDto[]) => {
    // Put locked users at the bottom per business requirement
    const sortedSettings = [
      ...settings.filter((s) => !s.userLocked),
      ...settings.filter((s) => s.userLocked),
    ];

    return sortedSettings.map(
      (setting) => {
        const result: {
          [key: string]: any,
        } = {
          user: (
            <APTooltip title={setting.userLocked ? 'This user’s access has been revoked' : setting.userEmail}>
              <span className={setting.userLocked ? classes.crossedOut : classes.greenText}>
                {setting.userName}
              </span>
            </APTooltip>),
        };

        for (let i = 0; i < portalNotificationColumns.length; i += 1) {
          const col = portalNotificationColumns[i];
          const accessor = col.type as string;

          result[accessor] = getCheckbox(
            setting,
            col.type,
            col.type,
            setting.enabled.includes(col.type),
            undefined,
            !col.editable || readonly || setting.userLocked,
          );
        }

        return result;
      },
    );
  };

  useEffect(() => {
    const tableData = mapAccountSettingsToData(teamNotificationSettings);
    setData(tableData);
  }, [teamNotificationSettings, readonly]);

  if (data.length > 0) {
    return (
      <StyledGenericTable
        testId="team-preference-table"
        columns={columns as any}
        data={data}
        className={clsx(classes.table, isFlagActive(FeatureFlagName.IAT_COMMUNICATION_SETTINGS) && (iatInputterAuthorized || iatApproverAuthorized) && classes.tableWithIAT)}
        columnGroups={columnGroups}
      />
    );
  }
  return (
    <Loader testId="notification-table-loading" />
  );
};

export default TeamNotificationMatrixTable;
