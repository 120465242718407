import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';

import useAccountSettings from '../../../hooks/useAccountSettings';
import { UserRole } from '../../../models/user';

interface IProps {
  handleOpenNewUserModal: () => void;
  tab: number;
  selectedAccount?: AccountDto;
}

const RightAlignedCta: React.FC<IProps> = ({
  handleOpenNewUserModal,
  tab,
  selectedAccount,
}: IProps) => {
  const [canAddUser, setCanAddUser] = useState(false);

  const { hasPermission, userAccounts } = useAccountSettings();
  useEffect(() => {
    if (selectedAccount) {
      setCanAddUser(hasPermission(UserRole.USER_INPUTTER, selectedAccount.id));
    }
  }, [selectedAccount, userAccounts]);

  return (
    <Box flex="column">
      {tab === 1 && canAddUser && (
        <ActionButton
          data-testId="new-user-btn"
          size="large"
          rounded
          onClick={handleOpenNewUserModal}
        >
          {t('add_user')}
        </ActionButton>
      )}
    </Box>
  );
};

export default RightAlignedCta;
