export interface ICountryMapping {
  [countryCode: string]: { seperatorInsertionIndex: number, separator: string}
}

export const countryMapping: ICountryMapping = {
  GBP: { seperatorInsertionIndex: 2, separator: '-' },
  AUD: { seperatorInsertionIndex: 3, separator: ' ' },
  ZAR: { seperatorInsertionIndex: 3, separator: ' ' },
};

const insertSeperator = (bankCode: string, index: number, seperator: string) => {
  const newBankCode = bankCode.split('');
  let seperatorsAdded = 0;
  let i = index;
  while (seperatorsAdded < 2 && i < bankCode.length) {
    newBankCode.splice(i, 0, seperator);
    seperatorsAdded += 1;
    i += (index + 1);
  }

  return newBankCode.join('');
};

export const formatBankCode = (bankCode: string, currencyCode: keyof ICountryMapping): string => {
  if (currencyCode in countryMapping) {
    return insertSeperator(bankCode, countryMapping[currencyCode].seperatorInsertionIndex,
      countryMapping[currencyCode].separator);
  }
  return bankCode;
};
export default formatBankCode;
