import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    menuContainer: {
      '& button': {
        backgroundColor: '#F4F4F4',
        borderColor: '#F4F4F4',
        borderRadius: '5px',
      },
    },
  }),
  { name: 'ExportMenu' },
);

export default useStyles;
