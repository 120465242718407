import { FormikProps } from 'formik';
import i18n from 'i18n/config';
import moment from 'moment';
import React, { memo } from 'react';
import t from 'utils/translationHelper';

import { ExternalTradeRequest, ProgramDto } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';
import {
  Box, MenuItem, Select,
} from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import InputAndLabel from '../../../components/Inputs/Beneficiary/InputAndLabel';
import APModal from '../../../components/Modals/APModal/APModal';
import { splitCurrencyPair } from '../../../utils/currency.helpers';

import useStyles from './UploadTradeModal.styles';

interface IProps {
  open: boolean;
  program: ProgramDto;
  handleClose: () => void;
  externalTradeForm: FormikProps<ExternalTradeRequest>
  submittingTrade: boolean;
}

const UploadTradeModal: React.FC<IProps> = (props: IProps) => {
  const {
    open,
    program,
    handleClose,
    externalTradeForm,
    submittingTrade,
  } = props;

  const styles = useStyles();
  const generateMenuOptions = (): JSX.Element[] => {
    const currencies: string[] = splitCurrencyPair(program.currencyPairCode);
    if (currencies.length > 0) {
      return currencies
        .map((ccy) => <MenuItem value={ccy}>{ccy}</MenuItem>);
    }
    return [];
  };

  const handleDropdownChange = (event: any) => {
    const selectedCurrency = event.target.value;
    if (selectedCurrency) {
      externalTradeForm.setFieldValue('fixedCurrencyCode', selectedCurrency);
      externalTradeForm.setFieldTouched('fixedCurrencyCode', true);
    }
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const amount = parseFloat(event.target.value.replace(/,/g, ''));
    externalTradeForm.setFieldValue('fixedAmount', amount, true);
    externalTradeForm.setFieldTouched('fixedAmount', true, true);
  };

  const handleDateChange = (_date: Date) => {
    if (_date) {
      externalTradeForm.setFieldValue('valueDate', moment(_date).format('YYYY-MM-DD'), false);
    }
  };

  return (
    <APModal.Container
      open={open}
      onClose={handleClose}
      className={styles.container}
      disableBackdropClick
    >
      <>
        <APModal.Header
          onClose={handleClose}
          showCloseButton={!submittingTrade}
        >
          <Typography className={styles.modalHeader}>
            {`${t('add_trade_to')} ${program.programName}`}
          </Typography>
        </APModal.Header>
        <APModal.Wrapper className={styles.modalWrapper}>
          {submittingTrade ? (
            <Box textAlign="center">
              <Loader
                testId="loader"
                size="50px"
                style={{ margin: 'auto', marginTop: '150px', marginBottom: '20px' }}
              />
              {t('submitting')}
              ...
            </Box>
          )
            : (
              <>
                <Box className={styles.body}>
                  <div className={styles.amountContainer}>
                    <div>
                      <Typography variant="subtitle1" className="label">
                        {t('fixed_currency')}
                      </Typography>
                      <Select
                        data-testid="fixed-currency-select"
                        onChange={handleDropdownChange}
                        value={externalTradeForm.values.fixedCurrencyCode || 'placeholder'}
                        disableUnderline
                        fullWidth
                        className={styles.fixedCcyDropdown}
                      >
                        {[
                          generateMenuOptions(),
                        ]}
                      </Select>
                    </div>
                    <div className={styles.fixedAmountStyle}>
                      <InputAndLabel
                        label={t('amount')}
                        name="amount"
                        id="amount"
                        type="amount"
                        testId="externalTradeAmount"
                        onChange={handleAmountChange}
                        onBlur={externalTradeForm.handleBlur}
                        value={externalTradeForm.values.fixedAmount}
                        error={externalTradeForm.touched.fixedAmount
                          && Boolean(externalTradeForm.errors.fixedAmount)}
                        helperText={externalTradeForm.touched.fixedAmount
                          && externalTradeForm.errors.fixedAmount}
                        className={styles.fixedAmountStyle}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '20px', width: '602px' }}>
                    <Typography variant="h6" style={{ paddingBottom: '5px' }}>{t('value_date')}</Typography>
                    <APDatePicker
                      style={{ width: '602px' }}
                      language={i18n.language}
                      label=""
                      onChange={handleDateChange}
                      value={externalTradeForm.values.valueDate}
                      placeholder={t('value_date')}
                      minDate={moment(program.startDate).format('YYYY-MM-DD')}
                      maxDate={moment(program.endDate).format('YYYY-MM-DD')}
                    />
                  </div>
                  <div style={{ marginTop: '20px', width: '100%', marginBottom: '20px' }}>
                    <InputAndLabel
                      label={t('rate')}
                      name="rate"
                      id="rate"
                      testId="rate"
                      onChange={externalTradeForm.handleChange}
                      onBlur={externalTradeForm.handleBlur}
                      value={externalTradeForm.values.rate}
                      error={externalTradeForm.touched.rate
                        && Boolean(externalTradeForm.errors.rate)}
                      helperText={externalTradeForm.touched.rate
                        && externalTradeForm.errors.rate}
                    />
                  </div>
                  <div style={{ marginTop: '20px', width: '100%', marginBottom: '20px' }}>
                    <InputAndLabel
                      label={t('reference')}
                      name="reference"
                      id="reference"
                      testId="reference"
                      onChange={externalTradeForm.handleChange}
                      onBlur={externalTradeForm.handleBlur}
                      value={externalTradeForm.values.reference}
                      error={externalTradeForm.touched.reference
                        && Boolean(externalTradeForm.errors.reference)}
                      helperText={externalTradeForm.touched.reference
                        && externalTradeForm.errors.reference}
                    />
                  </div>
                </Box>
                <Box className={styles.buttonsContainer}>
                  <ActionButton style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }} onClick={handleClose}>
                    {t('cancel')}
                  </ActionButton>
                  <ActionButton
                    testId="add-external-trade-button"
                    onClick={() => { externalTradeForm.handleSubmit(); }}
                    style={{ borderRadius: '5px' }}
                  >
                    {t('confirm')}
                  </ActionButton>
                </Box>

              </>
            )}
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default memo(UploadTradeModal);
