import { useEffect, useState } from 'react';

import { UserRole, UserRoles } from '../models/user';

import useProfile from './useProfile';

export const useAuthorization = (
  requiredRoles?: UserRoles[],
  resourceCreatorUserId?: string,
) => {
  const { userProfile } = useProfile();
  const [authorized, setAuthorized] = useState<boolean>(false);

  const isAuthorized = () => {
    // loop through requiredRoles
    requiredRoles![0].forEach((requiredRole) => {
      // if authorised === false, check to see if
      // loggedIn userRoles contains the requiredRole & set authorization to true if true
      if (!authorized) {
        if (userProfile?.roles.includes(requiredRole)) {
          switch (requiredRole) {
            case UserRole.BENEFICIARY_APPROVER:
              if (userProfile?.id !== resourceCreatorUserId) {
                // only allow authorization if loggedin user =/= resource creator
                setAuthorized(true);
              }
              break;
            case UserRole.BENEFICAIRY_APPROVER_OWN:
              if (resourceCreatorUserId === undefined || userProfile?.id === resourceCreatorUserId) {
                // only allow authorization if loggedin user === resource creator
                setAuthorized(true);
              }
              break;
            default:
              // case of BENEFICAIRY_APPROVER_OWN fits here, regardless of what the userId
              // or resourceCreatorUserId if user has that role they are able to approve all.
              setAuthorized(true);
              break;
          }
        }
      }
    });
  };

  useEffect(() => {
    if (requiredRoles) {
      isAuthorized();
    }
  }, [userProfile?.roles]);

  return {
    authorized,
    roles: userProfile?.roles || [],
  };
};

export default useAuthorization;
