import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import t from 'utils/translationHelper';

import { PaymentBatchDto } from '@alpha/payments-dtos';
import { Box } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader/Loader';
import { IStyledGenericTableProps, StyledGenericTable } from '@alpha/ui-lib/ui/table';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import clipboardCross from '../../../../assets/clipboardCross.svg';
import EmptyTable from '../../../../components/Table/EmptyTable';
import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import PaymentsService from '../../../../services/Payments/payments.service';

import UploadHistoryActionDropdown from './UploadHistoryActionDropdown/UploadHistoryActionDropdown';
import useStyles from './UploadHistory.styles';

const UploadHistory: FC = () => {

  const [paymentHistoryRecords, setPaymentHistoryRecords] = useState<PaymentBatchDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const showLimit = 5;

  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const columns: IStyledGenericTableProps['columns'] = [{
    header: t('file_name'),
    accessor: 'fileName',
    width: 400,
  },
  {
    header: t('file_type'),
    accessor: 'fileType',
    width: 200,
  },
  {
    header: t('upload_date'),
    accessor: 'uploadedDate',
    width: 200,
  },
  {
    header: t('action'),
    accessor: 'actions',
    align: 'right',
  },
  ];

  const tableData = () => paymentHistoryRecords.map((uploadRecord) => {
    const fileArray = uploadRecord.fileName.split('.');
    const fileExtension = fileArray[fileArray.length - 1].toLowerCase() as 'csv' | 'xlsx';

    const fileExt = fileExtension === 'csv' || fileExtension === 'xlsx' ? fileExtension : undefined;

    return {
      ...uploadRecord,
      fileName: <span className={styles.greenText}>{uploadRecord.fileName}</span>,
      fileType: fileExt || 'unknown',
      uploadedDate: moment(uploadRecord.uploadedDate).format('DD/MM/YYYY'),
      actions: (
        <UploadHistoryActionDropdown paymentUploadRecord={uploadRecord} />
      ),
    };
  });

  const loadUploadHistory = async () => {
    try {
      setLoading(true);
      const paymentUploadHistory = await PaymentsService.getBatchPaymentsUploadHistory();
      setPaymentHistoryRecords(paymentUploadHistory);
    } catch (error) {
      sb.trigger(error?.message || t('error_loading_upload_history'));
      logError({ action: 'Error loading upload history', error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUploadHistory();
  }, []);

  return (
    <Box className={styles.root}>
      <Typography className={styles.sessionHeading}>
        {t('upload_history')}
        {' '}
        {!loading && `(${(showAll || paymentHistoryRecords.length <= showLimit ? paymentHistoryRecords.length : `${t('showing')} ${showLimit} ${t('of')} ${paymentHistoryRecords.length}`)})`}
      </Typography>
      {loading && (
        <Box style={{ minHeight: '100px', padding: '50px' }}>
          <Loader testId="loader-upload-history" />
        </Box>
      )}

      {!loading
        && (
          paymentHistoryRecords.length > 0 ? (
            <>
              <StyledGenericTable testId="" columns={columns} data={showAll ? tableData() : tableData().splice(0, showLimit)} />
              {paymentHistoryRecords.length > showLimit && !showAll && (
                <Box textAlign="center" margin="24px">
                  <Typography
                    className={styles.underlinedGreenText}
                    onClick={() => setShowAll(true)}
                  >
                    {t('show_all_upload_history')}
                  </Typography>
                </Box>
              )}
            </>
          )
            : (
              <EmptyTable
                icon={clipboardCross}
                title={[t('no_upload_history')]}
                subtitle={t('you_have_no_payments_yet')}
                className={styles.emptyTable}
              />
            )
        )}
    </Box>
  );
};
export default UploadHistory;
