/* eslint-disable max-lines-per-function */
import React, { Dispatch } from 'react';
import browserHistory from 'services/history/browserHistory';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { BeneficiaryDto } from '@alpha/bene-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import {
  faEdit, faEllipsisH, faShare, faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';

import Authorization from '../../../../hocs/Authorization';
import useActionDropdown from '../../../../hooks/useActionDropDown';
import { UserRole } from '../../../../models/user';

import MultiSelectSection from './MultiSelectSection/MultiSelectSection';
import useStyles from './BeneficiaryActionDropDown.style';

interface IProps {
  tabTitle: string;
  beneficiary?: BeneficiaryDto;
  displayMultiSelect?: boolean,
  currentOptions?: Approver[],
  setDisplayMultiSelect?: (val: boolean) => void,
  setCurrentOptions?: React.Dispatch<React.SetStateAction<Approver[]>>,
  setTextValue?: Dispatch<React.SetStateAction<string>>,
  handleReviewButton?: (param: BeneficiaryDto) => void,
  setSelectedBeneficiary?: Dispatch<React.SetStateAction<any>>,
  handleBeneficiaryShareButtonClick?: (_accountBeneId?: string | undefined) => void,
  setDeleteModalOpen?: React.Dispatch<React.SetStateAction<false | 'reject' | 'delete'>>;
  handleApprovedEditButtonClick?: (clickedBeneficiary: BeneficiaryDto) => void
}

const BeneficiaryActionDropDown: React.FC<IProps> = ({
  beneficiary,
  displayMultiSelect,
  currentOptions,
  setDisplayMultiSelect,
  setCurrentOptions,
  setTextValue,
  handleReviewButton,
  setSelectedBeneficiary,
  tabTitle,
  handleBeneficiaryShareButtonClick,
  setDeleteModalOpen,
  handleApprovedEditButtonClick,
}: IProps) => {
  const classes = useStyles();

  const {
    open,
    approvers,
    loading,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    submitApproverEmails,
    handleRenderDropdown,
    setOpen,
  } = useActionDropdown({
    currentOptions,
  });

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    if (setDisplayMultiSelect) { setDisplayMultiSelect(false); }
    setMenuCloseCount(0);
  };

  const menuItems = (beneficiary: BeneficiaryDto) => ({
    // PENDING
    pending: [{
      content: (
        <Typography data-testid={`review-bene-action-${beneficiary.id}`}>
          {t('review')}
        </Typography>
      ),
      icon: displayIcon('View'),
      onClick: () => handleReviewButton && handleReviewButton(beneficiary),
      underline: true,
      isReview: true,
    },
    {
      content: (
        <MultiSelectSection
          setMenuCloseCount={setMenuCloseCount}
          menuCloseCount={menuCloseCount}
          setOpen={setOpen}
          displayMultiSelect={displayMultiSelect}
          loading={loading}
          id={beneficiary.id}
          approvers={approvers}
          currentOptions={currentOptions}
          setCurrentOptions={setCurrentOptions}
          setTextValue={setTextValue}
          setDisplayMultiSelect={setDisplayMultiSelect}
          submitApproverEmails={submitApproverEmails}
          type="Beneficiary"
        />
      ),
      onClick: () => handleRenderDropdown(
        displayMultiSelect,
        beneficiary.id,
        'Beneficiary',
        setDisplayMultiSelect,
      ),
      disableClick: true,
      icon: !displayMultiSelect ? faEnvelope : undefined,
      disableHoverEffect: displayMultiSelect,
    }],
    // APPROVED
    approved: [
      {
        content: (
          <Typography data-testid={`review-bene-action-${beneficiary.id}`}>
            {t('update_information')}
          </Typography>
        ),
        icon: faEdit,
        onClick: () => {
          if (handleApprovedEditButtonClick) {
            handleApprovedEditButtonClick(beneficiary);
          }
        },
        underline: true,
      },
      {
        content: (
          <Typography data-testid={`review-bene-action-${beneficiary.id}`}>
            {t('share_beneficiary')}
          </Typography>
        ),
        icon: faShare,
        onClick: () => {
          if (handleBeneficiaryShareButtonClick) {
            handleBeneficiaryShareButtonClick(
              beneficiary.id,
            );
          }
        },
        underline: true,
      },
      {
        content: (
          <Typography data-testid={`review-bene-action-${beneficiary.id}`}>
            {t('delete_beneficiary')}
          </Typography>
        ),
        icon: faTrashAlt,
        onClick: () => {
          if (setDeleteModalOpen) {
            setDeleteModalOpen('delete');
          }
        },
        underline: true,
      },
    ],
    // BATCH
    batch: [{
      content: (
        <Typography data-testid={`review-bene-action-${beneficiary.id}`}>
          {t('view_job')}
        </Typography>
      ),
      icon: displayIcon('View'),
      onClick: () => {
        if (setSelectedBeneficiary) {
          browserHistory.push(`/app/beneficiaries/upload-summary/${beneficiary.id}`);
        }
      },
    }],
  });

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        data-testid="action-bene-dropdown"
        className={classes.wrapper}
        onClick={(e) => {
          e.stopPropagation();
          if (setSelectedBeneficiary && tabTitle === 'approved') {
            setSelectedBeneficiary({
              beneficiary,
              approved: tabTitle === 'approved',
            });
          }
        }}
      >
        <APMenu
          actions
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          disableHoverEffect={displayMultiSelect}
          onClose={handleClose}
          buttonProps={{
            style: {
              height: 30,
              width: 30,
              minWidth: 0,
            },
          }}
          buttonTitle=""
          // @ts-ignore
          icon={faEllipsisH}
        >

          {
            menuItems(beneficiary)[tabTitle].map((item) => {
              if (item.isReview && beneficiary.submittedById) {
                return (
                  <Authorization
                    requiredRoles={[[UserRole.BENEFICIARY_APPROVER,
                      UserRole.BENEFICAIRY_APPROVER_OWN]]}
                    resourceCreatorUserId={beneficiary.submittedById}
                    fallback={<>-</>}
                  >
                    <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>
                  </Authorization>
                );
              }
              return <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>;
            })
          }
        </APMenu>
      </Box>
    </ClickAwayListener>
  );
};

export default BeneficiaryActionDropDown;
