import React, { memo } from 'react';
import Authorization from 'hocs/Authorization';
import { TCurrencyAccountDrawer } from 'models/currencyAccounts';
import { UserRole } from 'models/user';
import t from 'utils/translationHelper';
import { Link } from 'react-router-dom';

import { AccountType } from '@alpha/currency-accounts-dtos';
import { LangPreference } from '@alpha/profile-dtos';
import { Button } from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFileAlt, faInfoCircle, faMoneyBill, faRightLeft,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './Buttons.styles';

interface IButtonProps {
  handleOpenDrawer?: (_drawer: TCurrencyAccountDrawer) => void;
  accountId: string;
  hideAccountInfoBtn?: boolean;
  accountType: AccountType;
  accountName?: string;
  hideCreatePaymentBtn?: boolean;
  hideIATBtn?: boolean;
}

const Buttons: React.FC<IButtonProps> = (props: IButtonProps) => {
  const {
    handleOpenDrawer,
    accountId, hideAccountInfoBtn, accountType, accountName, hideCreatePaymentBtn,
    hideIATBtn,
  } = props;
  const classes = useStyles();

  const LangPreference = localStorage.getItem('i18nextLng');

  return (
    <>
      {!hideAccountInfoBtn && (
        <APTooltip title={t('account_information')}>
          <Button
            className={classes.button}
            onClick={handleOpenDrawer && (() => handleOpenDrawer('AccountDrawer'))}
            data-testid="account-info-btn"
          >

            <FontAwesomeIcon size="sm" icon={faInfoCircle as IconProp} />
            <span className={classes.buttonText}>
              {t('information')}
            </span>
          </Button>
        </APTooltip>
      )}
      <APTooltip title={t('statements')}>
        <Link
          to={`/app/statements/${accountId}?at=${accountType}`}
          data-testid="account-statements-link"
        >
          <Button className={classes.button} data-testid="account-statements-btn">
            <FontAwesomeIcon size="sm" icon={faFileAlt as IconProp} />
            <span className={classes.buttonText}>{t('statements')}</span>
          </Button>
        </Link>
      </APTooltip>

      {!hideCreatePaymentBtn && (
        <Authorization requiredRoles={[[UserRole.PAYMENTS_INPUTTER]]}>
          <APTooltip title={LangPreference === 'de'
            ? `${t('create_payments_from_DE1')} ${accountName} ${t('create_payments_from_DE2')}`
            : `${t('create_payments_from')} ${accountName}`}
          >
            <Button
              className={classes.button}
              onClick={handleOpenDrawer && (() => handleOpenDrawer('CreatePayment'))}
              data-testid="account-payment-btn"
            >
              <FontAwesomeIcon size="sm" icon={faMoneyBill as IconProp} />
              <span className={classes.buttonText}>{t('create_payment')}</span>
            </Button>
          </APTooltip>
        </Authorization>
      )}

      {!hideIATBtn ? (
        <Authorization requiredRoles={[[UserRole.IAT_INPUTTER]]}>
          <APTooltip title={t('inter_account_balance_transfer')}>
            <Button
              className={classes.button}
              data-testid="currency-account-transfer-btn"
              onClick={() => {
                if (handleOpenDrawer) {
                  handleOpenDrawer('IAT');
                }
              }}
            >
              <FontAwesomeIcon size="sm" icon={faRightLeft} />
              <span className={classes.buttonText}>{t('internal_transfer')}</span>
            </Button>
          </APTooltip>
        </Authorization>
      )
        : null}
    </>
  );
};

export default memo(Buttons);
