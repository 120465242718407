import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';

import { MfaMethod } from '@alpha/profile-dtos';
import { Box } from '@alpha/ui-lib/ui/external';

import Alert from '../../components/Alert';
import useAuth from '../../hooks/useAuth';
import useProfile from '../../hooks/useProfile';
import useSwitchAccount from '../../hooks/useSwitchAccount';
import { actions } from '../../store/user/user.reducer';
import t from 'utils/translationHelper';

import useStyles from './index.styles';
import QuickActions from './QuickActions';
import useDashboard from './useDashboard';
import WidgetPanel from './WidgetPanel';

const Dashboard: React.FC = () => {
  const classes = useStyles();


  const { dashboardSettings, setDashboardSettings, handleRemoveWidget } = useDashboard();
  const { userProfile, updateUserProfile } = useProfile();
  const [viewablePages, setViewablePages] = useState<string[]>([]);
  const [displayAlert, setDisplayAlert] = useState(false);
  const { loggedIn } = useAuth();
  const {
    currentPagesConfiguration,
    getPortalPagesConfiguration,
  } = useSwitchAccount();
  const dispatch = useDispatch();

  const preferredAuthMethod = useSelector<TStore, MfaMethod | undefined>((store) => store.user.preferredAuthMethod);
  const currentMfaMethod = userProfile?.mfaMethod;

  const handleOnClose = () => {
    setDisplayAlert(false);
    dispatch(actions.clearPreferredMfaMethod());
  };

  useEffect(() => {
    if (loggedIn) {
      updateUserProfile();
    }
    if (preferredAuthMethod !== undefined
      && currentMfaMethod !== undefined
      && (currentMfaMethod !== preferredAuthMethod)) {
      setDisplayAlert(true);
    }
  }, [currentMfaMethod, preferredAuthMethod]);

  useEffect(() => {
    (async () => {
      await getPortalPagesConfiguration();
      setViewablePages(currentPagesConfiguration()?.viewablePages as string[]);
    })();
  }, []);

  return (
    <Box className={classes.container}>
      <QuickActions
        dashboardSettings={dashboardSettings}
        setDashboardSettings={setDashboardSettings}
        viewablePages={viewablePages}
      />
      {displayAlert ? (
        <div className={classes.alertContainer}>
          <Alert
            showCloseButton
            onClose={handleOnClose}
            className={classes.alert}
            variant="info"
            text={t('we_noticed_you_used_a_different_method_to_login')}
          />
        </div>
      ) : null}
      <WidgetPanel
        dashboardSettings={dashboardSettings}
        viewablePages={viewablePages}
        handleRemoveWidget={handleRemoveWidget}
      />
    </Box>
  );
};

export default Dashboard;
