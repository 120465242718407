import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from 'services/history/browserHistory';
import t from 'utils/translationHelper';

import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';

import useSwitchAccount from 'hooks/useSwitchAccount';
import routes from '../../../../routes.path';

interface IProps {
  onTabChange?: (index: number) => void;
}

const Tabs = ({ onTabChange }: IProps) => {
  const tabTitles: string[] = [t('fx_trades'), t('payments')];
  const { isEMoneyDisabled } = useSwitchAccount();
  if (!isEMoneyDisabled()) {
    tabTitles.splice(1, 0, t('option_trades'));
    tabTitles.push(t('inter_account_transfers'));
  }

  const [tabIndex, setTabIndex] = useState<number>(0);
  const location = useLocation();

  const setTabIndexBasedOnLocation = (): void => {
    if (!isEMoneyDisabled()) {
      if (location.pathname.includes(routes.transactions.fxtrades)) {
        setTabIndex(0);
        if (onTabChange) { onTabChange(0); }
      } else if (location.pathname.includes(routes.transactions.optionTrades)) {
        setTabIndex(1);
        if (onTabChange) {
          onTabChange(1);
        }
      } else if (location.pathname.includes(routes.transactions.transfer)) {
        setTabIndex(3);
        if (onTabChange) { onTabChange(3); }
      } else if (location.pathname.includes(routes.transactions.base)) {
        setTabIndex(2);
        if (onTabChange) { onTabChange(2); }
      } else {
        history.push(routes.transactions.base);
      }
    } else {
      if (location.pathname.includes(routes.transactions.fxtrades)) {
        setTabIndex(0);
        if (onTabChange) { onTabChange(0); }
      } else if (location.pathname.includes(routes.transactions.base)) {
        setTabIndex(1);
        if (onTabChange) { onTabChange(1); }
      } else {
        history.push(routes.transactions.base);
      }
    }
  };

  useEffect(() => setTabIndexBasedOnLocation(), [
    location.pathname,
    tabIndex,
  ]);

  const handleSetSelectedTabIndex = (selectedTabIndex: number): void => {
    if (isEMoneyDisabled()) {
      switch (selectedTabIndex) {
        case 0:
          return history.push(routes.transactions.fxtrades);
        case 1:
          return history.push(routes.transactions.base);
        default:
          return history.push(routes.transactions.base);
      }
    } else {
      switch (selectedTabIndex) {
        case 0:
          return history.push(routes.transactions.fxtrades);
        case 1:
          return history.push(routes.transactions.optionTrades);
        case 2:
          return history.push(routes.transactions.base);
        case 3:
          return history.push(routes.transactions.transfer);
        default:
          return history.push(routes.transactions.base);
      }
    }
  };
  return (
    <StyledTabsWrapper
      testId="transactions-tabs"
      tabTitles={tabTitles}
      tabIndex={tabIndex}
      handleChange={(selectedTabIndex: number) => handleSetSelectedTabIndex(selectedTabIndex)}
    />
  );
};

export default Tabs;
