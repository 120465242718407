import React from 'react';
import { TStatItem } from 'components/Molecules/Credit/CreditSummary/CreditSummary';
import moment from 'moment';
import t from 'utils/translationHelper';

import { BeneficiaryBatchStatus, BeneficiaryBatchSummaryDto } from '@alpha/bene-dtos';
import { CircularProgress } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faCircleXmark, faDoNotEnter,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './BatchBeneficariesStyles.styles';

const useBeneBatchSummary = (beneBatchSummary: BeneficiaryBatchSummaryDto): TStatItem[] | undefined => {
  const styles = useStyles();

  if (beneBatchSummary) {
    const result = [
      {
        header: <span className={styles.statHeader}>{t('batch_id')}</span>,
        value: <span className={styles.statValue}>{beneBatchSummary?.friendlyId}</span>,
      },
      {
        header: <span className={styles.statHeader}>{t('file_type')}</span>,
        value: <span className={styles.statValue}>{beneBatchSummary?.fileType}</span>,
      },
      {
        header: <span className={styles.statHeader}>{t('upload_date')}</span>,
        value: <span className={styles.statValue}>{beneBatchSummary?.uploadDate ? moment(beneBatchSummary?.uploadDate).format('DD/MM/YYYY') : '-'}</span>,
      },
      {
        header: <span className={styles.statHeader}>{t('total_benes')}</span>,
        value: <span className={styles.statValue}>{beneBatchSummary?.processedRecords < beneBatchSummary.noOfRecords && beneBatchSummary.status !== BeneficiaryBatchStatus.COMPLETED ? <CircularProgress size={14} className={styles.spinner} /> : beneBatchSummary.processedRecords}</span>,
      },
      {
        header: <span className={styles.statHeader}>
          <FontAwesomeIcon
            className={styles.tickIcon}
            icon={faCheckCircle as IconProp}
          />
          {t('valid_benes')}
        </span>,
        value: <span className={styles.statValue}>{beneBatchSummary?.processedRecords < beneBatchSummary.noOfRecords && beneBatchSummary.status !== BeneficiaryBatchStatus.COMPLETED ? <CircularProgress size={14} className={styles.spinner} /> : beneBatchSummary.validRecords}</span>,
      },
      {
        header: <span className={styles.statHeader}>
          <FontAwesomeIcon
            className={styles.warningIcon}
            icon={faDoNotEnter as IconProp}
          />
          {t('warning_benes')}
        </span>,
        value: <span className={styles.statValue}>{beneBatchSummary?.processedRecords < beneBatchSummary.noOfRecords && beneBatchSummary.status !== BeneficiaryBatchStatus.COMPLETED ? <CircularProgress size={14} className={styles.spinner} /> : beneBatchSummary.invalidRecords}</span>,
      },
      {
        header: <span className={styles.statHeader}>
          <FontAwesomeIcon
            className={styles.rejectedIcon}
            icon={faCircleXmark as IconProp}
          />
          {t('rejected_benes')}
        </span>,
        value: <span className={styles.statValue}>{beneBatchSummary?.processedRecords < beneBatchSummary.noOfRecords && beneBatchSummary.status !== BeneficiaryBatchStatus.COMPLETED ? <CircularProgress size={14} className={styles.spinner} /> : beneBatchSummary.erroredRecords}</span>,
      },
    ];

    return result;
  }
};

export default useBeneBatchSummary;
