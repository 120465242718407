import React from 'react';
import useActionDropdown, { TDropdownIcon } from 'hooks/useActionDropDown';
import useLog from 'hooks/useLog';
import { useHistory } from 'react-router-dom';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import {
  Box, ClickAwayListener, Typography,
} from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import useStyles from './index.style';

interface IProps {
  displayMultiSelect: boolean,
  currentOptions: Approver[],
  setDisplayMultiSelect?: (val: boolean) => void,
  menuItems: Map<string, string>,
  pageUrl?: string,
}

const ActionDropDown: React.FC<IProps> = ({
  displayMultiSelect,
  currentOptions,
  setDisplayMultiSelect,
  menuItems,
  pageUrl,

}: IProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { logEvent } = useLog();

  const {
    open,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    setOpen,
  } = useActionDropdown({
    currentOptions,
  });

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    if (setDisplayMultiSelect) { setDisplayMultiSelect(false); }
    setMenuCloseCount(0);
  };

  const handleAction = async (action: string): Promise<void> => {
    logEvent({ action: 'Clicked on actions' });
    if (action.toLocaleLowerCase().includes('view')) {
      logEvent({ action: 'Clicked on view' });
      history.push(pageUrl);
    }
  };

  const menuItemsToDisplay = [];

  let count = 0;
  menuItems.forEach((key, value) => {
    menuItemsToDisplay.push(
      {
        content: (
          <Typography data-testid="actionDropDown-items">
            {t(value)}
          </Typography>
        ),
        icon: displayIcon(key as TDropdownIcon),
        onClick: () => handleAction(value),
        underline: count !== menuItems.size - 1,
      },
    );
    count += 1;
  });

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box data-testid="action-dropdown" className={classes.wrapper}>
        <APMenu
          open={Boolean(open)}
          setOpen={setOpen}
          anchorEl={open}
          disableBackdropClick={menuCloseCount === 1}
          disableHoverEffect={displayMultiSelect}
          onClose={handleClose}
          buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
          buttonTitle=""
          // @ts-ignore
          icon={faEllipsisH}
          actions
        >
          {
            menuItemsToDisplay.map((item) => (
              <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>
            ))
          }
        </APMenu>
      </Box>
    </ClickAwayListener>
  );
};

export default ActionDropDown;
