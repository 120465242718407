import { useEffect, useState } from 'react';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import { MfaMethod } from '@alpha/profile-dtos';
import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';

import { TSignIn } from '../models/auth';
import AuthService from '../services/Auth/auth.service';

import useForm from './useForm';
import useHandleLogin from './useHandleLogin';
import useLog from './useLog';

type TChangePassword = {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};

const initialValues: TChangePassword = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
};

const validation = {
  confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), undefined], t('new_passwords_must_match')).required(),
};

const useChangePassword = () => {
  const snackbar = originalUseSnackbar();
  const [changePasswordLoading, setChangePasswordLoading] = useState<boolean>(false);
  const [signInDetails] = useState<TSignIn>();
  const formik = useForm<TChangePassword>(
    initialValues,
    validation,
    submitChangePassword,
  );

  const { logError, logEvent } = useLog();

  const {
    auth,
  } = useHandleLogin();

  useEffect(() => {
    (async () => {
      if (signInDetails) {
        snackbar.enqueueSnackbar(t('password_changed_successfully_redirecting'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
        });

        logEvent({ action: 'Password changed successfully' });

        setTimeout(async () => auth.challengeRedirect(
          signInDetails, snackbar.closeSnackbar, MfaMethod.METHOD_AUTHY,
        ), 300);
      }
    })();
  }, [signInDetails]);

  const handleChangePassword = async (
    password: string,
    newPassword: string,
  ) => {
    try {
      await AuthService.changePassword(password, newPassword);
      await AuthService.sendPasswordChangeEmail();
      formik.setFieldValue('password', '');
      formik.setFieldValue('newPassword', '');
      formik.setFieldValue('confirmNewPassword', '');
      formik.setFieldTouched('password', false);
      formik.setFieldTouched('newPassword', false);
      formik.setFieldTouched('confirmNewPassword', false);
      snackbar.enqueueSnackbar(t('password_changed_successfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
    } catch (e: any) {
      const errorMessage = e?.response?.data?.error;
      snackbar.enqueueSnackbar(t(errorMessage) ?? e.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
      logError({ action: `Error changing password - ${e.message}`, error: e });
    }
  };

  async function submitChangePassword(values: TChangePassword) {
    try {
      setChangePasswordLoading(true);
      await handleChangePassword(
        values.password,
        values.newPassword,
      );
    } catch (e) {
      snackbar.enqueueSnackbar(t(e.message), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
    }
    setChangePasswordLoading(false);
  }

  return {
    formik,
    changePasswordLoading,
  };
};

export default useChangePassword;
