import React from 'react';
import t from 'utils/translationHelper';

import { BeneficiaryParty, BeneficiaryType } from '@alpha/bene-dtos';
import { Typography } from '@alpha/ui-lib/ui/external';
import { StyledKeyValueTable, TKeyValue } from '@alpha/ui-lib/ui/table';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import formatBankCode from '../../../../utils/formatBankCode';
import generateResidentAddress from '../../../../utils/generateResidentAddress';
import { TBeneficiaryDrawerBeneficiary } from '../BeneficiaryDrawer';

import useStyles from './Body.styles';

interface IProps {
  beneficiary: TBeneficiaryDrawerBeneficiary | undefined;
}

export const createBeneficiaryType = (beneficiaryType: BeneficiaryType | undefined): string => {
  if (beneficiaryType) {
    return beneficiaryType === BeneficiaryType.ENTITY ? 'Business' : 'Individual';
  }

  return '-';
};

export const createBeneficiaryParty = (beneficiaryType: BeneficiaryType | undefined,
  beneficiaryParty: BeneficiaryParty | undefined,
  partyOverride?: boolean,
  partyOverrideApproved?: boolean): string | JSX.Element => {
  const beneType = createBeneficiaryType(beneficiaryType);


  if (!beneficiaryParty && !beneficiaryType) return '-';

  if (partyOverride && partyOverrideApproved === null) {
    return (
      <APTooltip title="Currently under Internal review">
        <Typography style={{ color: '#CF752F', fontWeight: 600 }}>
          {t('1party')}
          {' '}
          (
          {t(beneType)}
          )
          {' '}
          <FontAwesomeIcon icon={faInfoCircle as IconProp} size="sm" />
        </Typography>
      </APTooltip>
    );
  }
  return beneficiaryParty === BeneficiaryParty.FIRST ? `${t('1party')} (${t(beneType)})` : `${t('3party')} (${t(beneType)})`;
};

const Body: React.FC<IProps> = (props: IProps) => {
  const { beneficiary } = props;
  const styles = useStyles();


  const createDetails = (beneficiary: TBeneficiaryDrawerBeneficiary): TKeyValue[] => [
    {
      key: t('beneficiary_type'),
      value: t(createBeneficiaryType(beneficiary.type)),
      boldValue: true,
    },
    {
      key: t('address'),
      value: generateResidentAddress(beneficiary.addressLine1, beneficiary.city, beneficiary.countryCode) || '-',
      boldValue: true,
    },
    {
      key: t('bank_names'),
      value: beneficiary && beneficiary.bankName ? beneficiary.bankName : '-',
    },
    {
      key: t('swift/bic'),
      value: beneficiary && beneficiary.swift ? beneficiary.swift : '-',
    },
    {
      key: t('iban'),
      value:
        beneficiary && beneficiary.iban
          ? beneficiary.iban && beneficiary.iban.toUpperCase()
          : '-',
    },
    {
      key: t('account_number'),
      value:
        beneficiary && beneficiary.accountNumber
          ? beneficiary.accountNumber
          : '-',
    },
    {
      key: beneficiary.nationalBankCodeType || t('national_bank_code'),
      value:
        beneficiary && beneficiary.nationalBankCode
          ? formatBankCode(beneficiary.nationalBankCode, beneficiary.bankCountryCode)
          : '-',
    },
    {
      key: t('correspondent_account_number'),
      value:
        beneficiary && beneficiary.correspondentAccountNumber
          ? beneficiary.correspondentAccountNumber
          : '-',
    },
    {
      key: t('correspondent_swift'),
      value:
        beneficiary && beneficiary.correspondentSwift
          ? beneficiary.correspondentSwift
          : '-',
    },
    {
      key: t('further_to_account_number'),
      value:
        beneficiary && beneficiary.furtherToAccountNumber
          ? beneficiary.furtherToAccountNumber
          : '-',
    },
    {
      key: t('further_to_swift'),
      value:
        beneficiary && beneficiary.furtherToSwift
          ? beneficiary.furtherToSwift
          : '-',
    },
  ].filter((item) => item.value !== '-');

  if (!beneficiary) return null;

  return (
    <div className={styles.drawerBody}>
      <StyledKeyValueTable
        testId="payment-beneficiary-drawer"
        data={createDetails(beneficiary)}
      />
    </div>
  );
};

export default Body;
