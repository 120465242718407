/* eslint-disable camelcase */
export enum OptionTradeStatus {
    EXPIRED = 'Expired',
    ACTIVE = 'Active',
    TERMINATED = 'Terminated',
}

export enum OptionsStripsStatus {
    EXPIRED = 'Expired',
    ACTIVE = 'Active',
    EXERCISED = 'Exercised',
    CANCELLED = 'Cancelled',
}

export type OptionsStripDto = {
    id: string;
    name: string;
    individualNumber: string;
    status: string;
    notionalCurrency: string;
    barrierStart: string;
    barrierEnd: string;
    deliveryDate: string;
    paymentDate: string;
    expiryDate: string;
    observationDate: string;
    notionalAmount: string;
    committedAmount: string;
    leveragedAmount: string;
    digitalAmount: string;
    partialAmount: string;
    strikeRate: string;
    capLevel: string;
    plusRate: string;
    resetRate: string;
    trackingLevel: string;
    barrierLevel: string;
    kiBarrier: string;
    koBarrier: string;
};

export type OptionsLayoutDto = {
    id: number;
    name: string;
    columns: {
        id: number;
        columnName: string;
        accessor: string;
        type: 'string' | 'decimal' | 'date' | 'number';
    }[];
};

export type OptionTradeDto = {
    id: string;
    productName: string;
    currencyPair: string;
    accountId: string;
    dealtCurrency: string;
    dealtAmount: number;
    dealtBalance: number;
    buyOrSell: string;
    name: string;
    status: OptionTradeStatus;
    tradeDate: string;
    stripFrequency: string;
    stripsRemaining: number;
    targetRemaining: number;
    originalTarget: number;
    nextExpiry: string;
    finalExpiry: string;
    type: string;
    targetType: string;
    direction: string;
    lastUpdatedAt?: string;
    targetLog?: TargetLogDto[];
    originalTradeTicketId?: string;
    layouts: OptionsLayoutDto;
    optionsStrips: OptionsStripDto[];
}

export enum StripTradeStatus {
    EXPIRED = 'EXPIRED',
    EXERCISED = 'EXERCISED',
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED'
}

export type TargetLogDto = {
    stripId: string,
    fixingDate: string,
    strike: number,
    fixingReference: number,
    prevTargetValue: number,
    deduction: number,
    newTargetValue: number,
    originalTargetLog: boolean
}

export default OptionTradeStatus;
