import React from 'react';
import t from 'utils/translationHelper';

import { LedgerDto } from '@alpha/currency-accounts-dtos';
import { Button } from '@alpha/ui-lib/ui/external';

import Search from '../../../components/Organisms/Search';
import SearchTable from '../../../components/Organisms/SearchTable';
import { ITableColumn } from '../../../services/DatabaseServices/marker.interface';
import { formatNumber } from '../../../utils/currency.helpers';
import formatIsoDate from '../../../utils/formatIsoDate';

import useStyles from './StatementsTable.styles';
import useStatementsTable from './useStatementsTable';

export const componentTestIds = {
  DisplayTable: 'display-loader-component',
};
export interface IStatementTable {
  startDate: string,
  endDate: string,
  componentTestId?: string,
  currencyAccountId?: string,
  handleSetStatement: (clickedTableRow: LedgerDto) => Promise<void>
}

const StatementsTable: React.FC<IStatementTable> = (
  props: IStatementTable,
) => {
  const {
    startDate,
    endDate,
    componentTestId,
    currencyAccountId,
    handleSetStatement,
  } = props;
  const classes = useStyles();

  const columns: ITableColumn[] = [
    { header: t('date'), accessor: 'ledgerTimestamp', sortable: true },
    { header: t('id'), accessor: 'name' },
    { header: t('details'), accessor: 'details' },
    { header: t('reference'), accessor: 'reference', sortable: true },
    { header: t('credit'), accessor: 'creditAmount' },
    { header: t('debit'), accessor: 'debitAmount' },
    { header: t('balance'), accessor: 'runningBalance', align: 'right' },
  ];

  const {
    table,
    handleInputChange,
    handleTableRowClick,
    searchParams,
    generateEmptyTableText,
  } = useStatementsTable(startDate, endDate, handleSetStatement, currencyAccountId);

  const handleIdRendering = (friendlyId: string): JSX.Element | string => {
    if (friendlyId.match('L0')) {
      return friendlyId;
    } return (
      <Button
        disableRipple
        className={classes.button}
      >
        {friendlyId}
      </Button>
    );
  };

  const createTableData = () => (
    table.items?.items?.records as LedgerDto[] || [])
    .map((column: LedgerDto) => ({
      ...column,
      name: column.friendlyId ? (
        handleIdRendering(column.friendlyId)
      ) : '-',
      creditAmount: column.creditAmount ? (
        <span className={classes.creditAmount}>
          {formatNumber(column.creditAmount, 2)}
        </span>
      ) : (
        '-'
      ),
      debitAmount: column.debitAmount ? (
        <span className={classes.debitAmount}>
          {formatNumber(column.debitAmount, 2)}
        </span>
      ) : (
        '-'
      ),
      runningBalance: (
        <span className={classes.runningBalance}>
          {formatNumber(column.runningBalance || 0, 2)}
        </span>
      ),
      ledgerTimestamp: formatIsoDate(column.date),
    }));
  return (
    <div data-testid={componentTestId} className={classes.tableWrapper}>
      <div style={{
        display: 'flex',
        width: '400px',
      }}
      >
        <Search
          testId="search-statements"
          value={table.searchText}
          totalItems={table.items?.items.total || 0}
          placeholder={t('search_by_statement_id_or_reference')}
          onChange={handleInputChange}
        />
      </div>
      <SearchTable
        table={{
          columns,
          data: createTableData(),
          clickable: true,
          handleTableRowClick,
          handleTableSortClick: (column) => {
            table.handleTableSortClick(searchParams, column);
          },
          activeColumn: table.sortBy,
          sortOrder: table.sortOrder,
          totalRowNumber: table.items?.total || 0,
          skip: table.skip,
        }}
        loading={!table.items || table.loading}
        pagination={{
          handleNext: () => table.handleNextPage(searchParams),
          handlePrevious: () => table.handlePreviousPage(searchParams),
          hasNext: Boolean(table.items?.hasNext),
          hasPrevious: Boolean(table.items?.hasPrevious),
        }}
        emptyTable={{ ...generateEmptyTableText(currencyAccountId) }}
      />
    </div>
  );
};

export default StatementsTable;
